import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ReactComponent as FavouriteIcon } from '../../../assets/icons/favorite.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import {
    callPresentationTracker,
    getCollectionPresentationInfo,
    getFavouritePresentationInfo
} from '../../services/CommonService'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader, useLoader } from '../../redux_toolkit/Loader'
import LoginModal from '../common/LoginModal'
import {
    doFavourite,
    undoFavourite
} from '../../services/v3/Favourite/FavouriteService'
import { ReactComponent as FavouritedIcon } from '../../../assets/icons/favorited.svg'
import ForgetPassword from '../../modules/login-journey/ForgetPassword'
import AddToFavourite from '../common/AddToFavourite'
import { DoFavouriteActions } from '../../redux_toolkit/V3/DoFavourite'
import { SET_SELECTED_FAVOURITE } from '../../redux_toolkit/@types/action.types'
import { getUserPreference, STATIC_SET_CONSTANTS } from '../../helpers/Util'

const CollectionPresentation = ({
    onClose,
    isRequestFromRange = false,
    productIds = [],
    pageSource,
    hideFavBtn = false
}) => {
    const [isShowForgotPassword, setIsShowForgotPassword] = useState(false)
    const [presentationInfo, setPresentationInfo] = useState({})
    const [showFavourite, setShowFavourite] = useState(false)
    const [collectionInfo, setCollectionInfo] = useState([])
    const [isShowLogin, setIsShowLogin] = useState(false)
    const [trackId, setTrackId] = useState(null)
    const dispatch = useDispatch()
    const { id } = useParams()
    const loader = useLoader()

    const getCollectionInfo = async () => {
        try {
            dispatch(openLoader())
            const data = await getCollectionPresentationInfo(
                `${id}/presentation-info/public?fromPhotography=false`
            )
            setPresentationInfo({
                collectionId: id,
                presentationName: data.collection,
                market: data.market,
                season: data.season,
                brand: data.brand,
                middleDocument: data.middleDocument,
                endDocument: data.endDocument,
                coverDocument: data.coverDocument
            })
            setCollectionInfo(data?.productInfoForPresentationList)
            setIsShowLogin(false)
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const getFavouriteInfo = async () => {
        try {
            dispatch(openLoader())
            let params = id && id !== 'my-favourite' ? `?folderId=${id}` : ''
            const data = await getFavouritePresentationInfo(params)
            setPresentationInfo({
                presentationName: data?.presentationName,
                brand: data?.brand,
                middleDocument: data?.middleDocument,
                endDocument: data?.endDocument,
                coverDocument: data?.coverDocument
            })
            setCollectionInfo(data?.productInfoForPresentationList)
            setIsShowLogin(false)
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const getProductInfos = async () => {
        try {
            dispatch(openLoader())
            const data = await getCollectionPresentationInfo(
                `presentation-info/buyer?productIds=${productIds.join(',')}`
            )
            setCollectionInfo(data?.productInfoForPresentationList)
            setIsShowLogin(false)
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const collectionPresentationTrackId = async () => {
        try {
            const data = await callPresentationTracker()
            setTrackId(data)
        } catch (e) {
            // eslint-disable-next-line no-empty,no-console
            console.log(e)
        }
    }

    useEffect(() => {
        if (isRequestFromRange) {
            setPresentationInfo({ presentationName: 'Range' })
            if (productIds.length > 0) getProductInfos()
        } else {
            if (pageSource === 'FAVOURITE') {
                getFavouriteInfo()
            } else {
                collectionPresentationTrackId()
                getCollectionInfo()
            }
        }
    }, [])

    const isTokenExist = () => {
        const token = localStorage.getItem('token')
        return !!token
    }

    const toggleIsFavourite = async (product) => {
        try {
            const id = product.id
            const bool = !product.isLiked
            dispatch(openLoader())
            if (bool) {
                let folderId = getUserPreference()?.defaultFavouriteFolder?.id
                let params = `?productIds=${id}`
                if (folderId) {
                    params += `&folderId=${folderId}`
                }
                await doFavourite(params)
            } else {
                await undoFavourite(id)
            }
            const cloneCollection = collectionInfo?.map((item) => {
                if (item.id === id) {
                    item.isLiked = bool
                }
                return item
            })
            setCollectionInfo(cloneCollection)
            if (bool) {
                dispatch(
                    DoFavouriteActions[SET_SELECTED_FAVOURITE]({
                        id: product.id,
                        referenceNumber: product.referenceNumber,
                        categoryResponse: { name: product.category },
                        mainFabricType: product.mainFabric,
                        designDocuments: [{ docUrl: product.frontImageUrl }]
                    })
                )
                setShowFavourite(bool)
            }
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const toggleFavourite = async (product) => {
        if (!isTokenExist()) {
            setIsShowLogin(true)
        } else {
            await toggleIsFavourite(product)
        }
    }

    return (
        <>
            <div>
                <div className='design-view-modal presentation-play-modal'>
                    <div className='modal-content'>
                        {collectionInfo?.length === 0 && !loader ? (
                            <div className='no-presentaion-available'>
                                <div
                                    className='alert alert-secondary text-center'
                                    role='alert'
                                >
                                    No presentation available
                                </div>
                            </div>
                        ) : (
                            <div className='modal-body'>
                                <div className='presentation-title sticky top-0 text-center p-4'>
                                    <p className='text-sm sm:text-base xl:text-xl black'>
                                        {presentationInfo?.presentationName}
                                    </p>
                                    {onClose && (
                                        <span
                                            className='absolute right-6 top-3 cursor-pointer'
                                            onClick={onClose}
                                        >
                                            <CloseIcon />
                                        </span>
                                    )}
                                </div>
                                <div className='modal-artboards scroll-y-label'>
                                    {!isRequestFromRange && (
                                        <section
                                            className={`into-section-v2 ${
                                                presentationInfo?.brand
                                                    ?.length < 8
                                                    ? 'large'
                                                    : ''
                                            } ${
                                                !presentationInfo?.brand
                                                    ? 'no-brand'
                                                    : ''
                                            }`}
                                        >
                                            <h1 className='brand-name-lebel'>
                                                {presentationInfo?.brand}
                                            </h1>
                                            <div className='intro-cover'>
                                                {presentationInfo?.coverDocument
                                                    ?.docUrl ? (
                                                    <img
                                                        src={
                                                            presentationInfo
                                                                ?.coverDocument
                                                                ?.docUrl
                                                        }
                                                        alt='cover'
                                                        className='cover-photo'
                                                    />
                                                ) : (
                                                    <div className='cover-photo-container' />
                                                )}

                                                <div className='presentation-edit-category flex justify-between'>
                                                    <div className='cat-info'>
                                                        {presentationInfo?.market &&
                                                            presentationInfo?.season && (
                                                                <p>
                                                                    {
                                                                        presentationInfo?.market
                                                                    }
                                                                    :{' '}
                                                                    <span>
                                                                        {' '}
                                                                        {
                                                                            presentationInfo?.season
                                                                        }
                                                                    </span>
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                            <img
                                                src='/images/nitex-presentation-logo.svg'
                                                alt='logo'
                                                className='bottom-logo'
                                            />
                                        </section>
                                    )}
                                    {presentationInfo?.middleDocument
                                        ?.docUrl && (
                                        <div className='single-design-view-page'>
                                            <div className='presentation-view-container'>
                                                <div className='full-page-photo'>
                                                    <img
                                                        src={
                                                            presentationInfo
                                                                ?.middleDocument
                                                                ?.docUrl
                                                        }
                                                        alt='cover'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {collectionInfo?.map((item) => (
                                        <div
                                            className='single-design-view-page'
                                            key={item.id}
                                        >
                                            <div className='single-artboard-title flex items-center justify-between mx-6 pt-4'>
                                                <div>
                                                    {item?.setPackStyleFlag && (
                                                        <>
                                                            <span
                                                                className={`status ${
                                                                    item?.setPackStyleFlag ===
                                                                    STATIC_SET_CONSTANTS.PACK_PRODUCT
                                                                        ? 'pack'
                                                                        : item?.setPackStyleFlag ===
                                                                          STATIC_SET_CONSTANTS.SET_MEMBER
                                                                        ? 'set'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {item?.setPackStyleFlag ===
                                                                STATIC_SET_CONSTANTS.PACK_PRODUCT
                                                                    ? 'Pack'
                                                                    : item?.setPackStyleFlag ===
                                                                      STATIC_SET_CONSTANTS.SET_MEMBER
                                                                    ? 'Set'
                                                                    : ''}
                                                            </span>
                                                            <span className='regular-12 weight-500 ml-2'>
                                                                {
                                                                    item?.setPackName
                                                                }
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                                <div className='initial-costing'>
                                                    {!hideFavBtn && (
                                                        <button
                                                            className='button secondary'
                                                            onClick={() =>
                                                                toggleFavourite(
                                                                    item
                                                                )
                                                            }
                                                        >
                                                            <span className='mr-2'>
                                                                Add to fav
                                                            </span>

                                                            {!item?.isLiked && (
                                                                <FavouriteIcon />
                                                            )}
                                                            {item?.isLiked && (
                                                                <FavouritedIcon />
                                                            )}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='presentation-view-container'>
                                                <div className='images-view-section'>
                                                    <div className='one-third full-photo'>
                                                        <img
                                                            src={
                                                                item.frontImageUrl
                                                            }
                                                            alt='front'
                                                        />
                                                    </div>
                                                    <div className='one-third full-photo'>
                                                        <img
                                                            src={
                                                                item.backImageUrl
                                                            }
                                                            alt='back'
                                                        />
                                                    </div>
                                                    <div className='one-third fabric-photo'>
                                                        <div className='single-fabric'>
                                                            <img
                                                                src={
                                                                    item.fabricImageUrl
                                                                }
                                                                alt='fabric'
                                                            />
                                                        </div>
                                                        <div className='single-fabric'>
                                                            <img
                                                                src={
                                                                    item.keyDetailsImageUrl
                                                                }
                                                                alt='keyDetails'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='single-design-details'>
                                                    <div className='design-details-heading'>
                                                        <p>
                                                            <span className='bold'>
                                                                NITEX
                                                            </span>{' '}
                                                            DESIGNLAB
                                                        </p>

                                                        <p>
                                                            STYLE:{' '}
                                                            <span className='bold'>
                                                                {
                                                                    item?.referenceNumber
                                                                }
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className='design-details-body'>
                                                        <div className='item-details'>
                                                            <p>
                                                                <span>
                                                                    ITEM:
                                                                </span>
                                                                <span className='bold'>
                                                                    {
                                                                        item.styleName
                                                                    }
                                                                </span>
                                                            </p>
                                                            {item?.fabric2 ? (
                                                                <>
                                                                    <div className='flex'>
                                                                        <p>
                                                                            <span>
                                                                                Fabric
                                                                                1:
                                                                            </span>
                                                                            <span className='bold'>
                                                                                {
                                                                                    item.fabric1
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                        <p className='ml-3'>
                                                                            <span className='weight-label'>
                                                                                Weight:
                                                                            </span>
                                                                            <span className='bold'>
                                                                                {
                                                                                    item.weight1
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                    <div className='flex'>
                                                                        <p>
                                                                            <span>
                                                                                Fabric
                                                                                2:
                                                                            </span>
                                                                            <span className='bold'>
                                                                                {
                                                                                    item.fabric2
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                        <p className='ml-3'>
                                                                            <span className='weight-label'>
                                                                                Weight:
                                                                            </span>
                                                                            <span className='bold'>
                                                                                {
                                                                                    item.weight2
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <p>
                                                                        <span>
                                                                            Fabric
                                                                            1:
                                                                        </span>
                                                                        <span className='bold'>
                                                                            {
                                                                                item.fabric1
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span>
                                                                            Weight:
                                                                        </span>
                                                                        <span className='bold'>
                                                                            {
                                                                                item.weight1
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {presentationInfo?.endDocument?.docUrl && (
                                        <div className='single-design-view-page'>
                                            <div className='presentation-view-container'>
                                                <div className='full-page-photo'>
                                                    <img
                                                        src={
                                                            presentationInfo
                                                                ?.endDocument
                                                                ?.docUrl
                                                        }
                                                        alt='cover'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!isRequestFromRange && (
                                        <section className='last-section-v2'>
                                            <div className='main-slogan'>
                                                <h3 className='create'>
                                                    CREATE
                                                </h3>
                                                <h3 className='innovate'>
                                                    INNOVATE
                                                </h3>
                                                <h3 className='collaborate'>
                                                    COLLABORATE
                                                </h3>
                                                <h3 className='protect'>
                                                    PROTECT
                                                </h3>
                                            </div>
                                            <div className='productionlab left'>
                                                <img
                                                    src='/images/production-lab.svg'
                                                    alt='production lab'
                                                />
                                            </div>
                                            <div className='productionlab right'>
                                                <img
                                                    src='/images/production-lab.svg'
                                                    alt='production lab'
                                                />
                                            </div>
                                            <div className='website'>
                                                <Link
                                                    to='https://nitex.com/'
                                                    target='_blank'
                                                >
                                                    NITEX.COM
                                                </Link>
                                            </div>
                                        </section>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {showFavourite && (
                    <AddToFavourite
                        isVisible={showFavourite}
                        setIsVisible={setShowFavourite}
                    />
                )}
            </div>
            {isShowLogin && (
                <LoginModal
                    trackId={trackId}
                    callback={getCollectionInfo}
                    openForgotPassword={() => {
                        setIsShowForgotPassword(true)
                        setIsShowLogin(false)
                    }}
                />
            )}
            {isShowForgotPassword && !isShowLogin && (
                <ForgetPassword
                    onClose={() => setIsShowForgotPassword(false)}
                />
            )}
        </>
    )
}

export default CollectionPresentation
