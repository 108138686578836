import Http from '../../helpers/Http'
import { toast } from 'react-toastify'

const getAllTnaProductList = async (orderId) => {
    try {
        const { data } = await Http.GET('getInitialAllOrder', '/' + orderId)
        return data
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

const getTemplateDays = async () => {
    try {
        const { data } = await Http.GET('getTnaTemplateDay');
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const selectedTna = async (orderId, rfqId, templateName = '') => {
    try {
        const { data } =
            templateName != ''
                ? await Http.GET(
                    'tnaPlanningOrder',
                    '/' + orderId + '/rfq/' + rfqId + '?templateName=' + templateName
                )
                : await Http.GET('tnaPlanningOrder', '/' + orderId + '/rfq/' + rfqId);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const updateDuration = async (orderId, rfqId, reqBody) => {
    try {
        const { data } = await Http.PUT(
            'tnaPlanningOrder',
            reqBody,
            `/${orderId}/rfq/${rfqId}/update-duration`
        );
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};
const updateDeliveryDate = async (orderId, rfqId, reqBody) => {
    try {
        const { data } = await Http.PUT(
            'tnaPlanningOrder',
            reqBody,
            `/${orderId}/rfq/${rfqId}/update-delivery-date`
        );
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const updateNotApplicable = async (orderId, rfqId, reqBody) => {
    try {
        const { data } = await Http.PUT(
            'tnaPlanningOrder',
            reqBody,
            `/${orderId}/rfq/${rfqId}/update-not-applicable`
        );
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const updateTask = async (orderId, rfqId, reqBody) => {
    try {
        const { data } = await Http.POST('tnaPlanningOrder', reqBody, `/${orderId}/rfq/${rfqId}/add-task`);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const duplicateTask = async (orderId, rfqId, plannedRfqId) => {
    try {
        const { data } = await Http.POST(
            'tnaPlanningOrder',
            '',
            `/${orderId}/rfq/${rfqId}/duplicate/${plannedRfqId}`
        );
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const submitTna = async (orderId, rfqId) => {
    try {
        const { data } = await Http.POST('submitTna', '', `/${orderId}/rfq/${rfqId}/create-tna`);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const getAllStylePlanning = async (orderId) => {
    try {
        const { data } = await Http.GET('getRunningAllOrder', '/' + orderId);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const getRunningTnaStyleDetails = async (orderId, rfqId) => {
    try {
        const { data } = await Http.GET('getRunningTnaStyleDetails', `/${orderId}/rfq/${rfqId}`);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const subtaskCreate = async (stepId, body) => {
    try {
        const { data } = await Http.POST('stepUrl', body, `/${stepId}/add-sub-task`);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const subtaskUpdate = async (subTaskId, body) => {
    try {
        const { data } = await Http.PUT('subTaskAction', body, subTaskId);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const qtyUpdate = async (stepId, quantity) => {
    try {
        const { data } = await Http.PUT('stepUrl', '', `/${stepId}/production-quantity?quantity=${quantity}`);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const taskEndDateUpdate = async (body, taskId) => {
    try {
        const { data } = await Http.PUT('task', body, taskId);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const getChannels = async (stepId) => {
    try {
        const { data } = await Http.GET('stepUrl', `/${stepId}`);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const onSubmitPoQuantity = async (id, quantity) => {
    try {
        const { data } = await Http.PUT('stepUrl', '', `/${id}/production-target?quantity=${quantity}`);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const onDeletePoQuantity = async (stepId, quantityId) => {
    try {
        const { data } = await Http.DELETE('stepUrl', '', `/${stepId}/production-quantity/${quantityId}`);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const onDleteTask = async (id) => {
    try {
        const { data } = await Http.DELETE('subTaskAction', {}, id);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const fetchPostComments = async (paramsData) => {
    let params = `${paramsData.orderId}/product/${paramsData.ProductId}?page=${
        paramsData.page
    }&size=${10}&sort=id,desc`;
    if (paramsData?.deliverableId) {
        params += `&deliverableId=${paramsData.deliverableId}`;
    }
    try {
        const { data } = await Http.GET('orderTimelineAction', params);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const onApproveTask = async (taskId) => {
    try {
        const { data } = await Http.POST('approveTask', {}, taskId);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const getOrderDashboardInfoByOrderId = async (orderId) => {
    try {
        const { data } = await Http.GET(
            'getOrderDashboardReports',
            `?orderIdList=${orderId}&size=1000,&sort=id,desc`
        );
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const onGetDeliverableDetails = async (deliverableId) => {
    try {
        const { data } = await Http.GET('getDeliverableDetails', deliverableId);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

export default {
    getAllTnaProductList,
    getTemplateDays,
    selectedTna,
    updateDuration,
    updateDeliveryDate,
    updateNotApplicable,
    updateTask,
    duplicateTask,
    submitTna,
    getAllStylePlanning,
    getRunningTnaStyleDetails,
    subtaskCreate,
    subtaskUpdate,
    qtyUpdate,
    taskEndDateUpdate,
    getChannels,
    onSubmitPoQuantity,
    onDeletePoQuantity,
    onDleteTask,
    fetchPostComments,
    onApproveTask,
    getOrderDashboardInfoByOrderId,
    onGetDeliverableDetails
};
