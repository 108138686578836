import React from 'react'
import { isSetItem } from '../../helpers/Util'

const DesignImages = ({ designInfo, callback }) => {
    const renderStyleDesignDocuments = () => {
        if (!isSetItem(designInfo)) {
            return (
                <>
                    {designInfo?.designDocuments?.[0]?.docUrl && (
                        <img
                            src={designInfo?.designDocuments?.[0]?.docUrl}
                            alt='design'
                            className='design-image front'
                        />
                    )}

                    {designInfo?.backImageDocument?.docUrl && (
                        <img
                            src={designInfo?.backImageDocument?.docUrl}
                            alt='design'
                            className='design-image back'
                        />
                    )}
                </>
            )
        }
    }

    return (
        <div
            className={`style-photo cursor-pointer ${
                isSetItem(designInfo) ? 'set-style' : ''
            }`}
            onClick={callback}
        >
            {!isSetItem(designInfo) && renderStyleDesignDocuments()}
            {isSetItem(designInfo) &&
                designInfo?.designDocuments?.map((item) => (
                    <img
                        key={`set_image_${item.id}`}
                        src={item?.docUrl}
                        alt='design'
                        className='design-image front'
                    />
                ))}
        </div>
    )
}

export default DesignImages
