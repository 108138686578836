import React, { useEffect, useRef, useState } from 'react'
import SelectComponent from '../../common/SelectComponent'
import SliderWrapper from '../../common/SliderWrapper'
import { ReactComponent as IconRightArrow } from '../../../assets/icons/old/rightArrow.svg'
import MaterialBasedProduct from '../../common/MaterialBasedProduct'
import { useDispatch } from 'react-redux'
import MaterialThunks from '../../redux_toolkit/Home/MaterialThunks'
import { GET_MATERIAL_LIST_BY_FABRIC_TYPE } from '../../redux_toolkit/@types/thunk.types'
import { materialActions, useMaterialSelector } from '../../redux_toolkit/Home'
import {
    SET_SELECTED_FABRIC,
    SET_SELECTED_MATERIAL_ID
} from '../../redux_toolkit/@types/action.types'
import Http from '../../helpers/Http'
import { toast } from 'react-toastify'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { useLocation, useNavigate } from 'react-router-dom'
import { isPageReachBottom } from '../../helpers/Util'

const fabricOptions = [
    { label: 'Premium Fabric Base', value: 'PREMIUM' },
    { label: 'Seasonal Fabric Base', value: 'SEASONAL' },
    { label: 'Core Fabric Base', value: 'CORE' }
]

const FabricWiseProduct = () => {
    const [products, setProducts] = useState([])
    const [unUsedTags, setUnUsedTags] = useState([])
    const dispatch = useDispatch()
    const materials = useMaterialSelector()
    const navigate = useNavigate()
    const myStateRef = useRef({})
    const materialIdRef = useRef(null)
    const location = useLocation()

    useEffect(() => {
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if (materials?.data.length > 0) {
            let tags = []
            let uTags = []
            for (let material of materials.data) {
                let tagList = material.tagResponseList
                if (tagList.length > 0) {
                    uTags.push(tagList[0].text)
                    for (let i = 1; i < tagList.length; i++) {
                        if (!uTags.includes(tagList[i].text)) {
                            tags.push(tagList[i].text)
                        }
                    }
                }
            }
            setUnUsedTags(tags)
        }
    }, [materials])

    useEffect(() => {
        let postData = {
            params: '?sort=id,desc&size=100',
            fabricType: materials.fabricType
        }
        if (location.pathname !== '/products/materialId') {
            dispatch(MaterialThunks[GET_MATERIAL_LIST_BY_FABRIC_TYPE](postData))
        } else if (materials?.data?.length < 1) {
            dispatch(MaterialThunks[GET_MATERIAL_LIST_BY_FABRIC_TYPE](postData))
        }
    }, [])

    const handleFabricChange = (option) => {
        setProducts([])
        fetchProductByMaterialId(null)
        dispatch({
            type: materialActions[SET_SELECTED_FABRIC],
            payload: option
        })
        let postData = {
            params: '?sort=id,desc&size=100',
            fabricType: option
        }
        dispatch(MaterialThunks[GET_MATERIAL_LIST_BY_FABRIC_TYPE](postData))
    }

    const fetchProductByMaterialId = (materialId) => {
        dispatch({
            type: materialActions[SET_SELECTED_MATERIAL_ID],
            payload: materialId
        })
    }

    const fetchProducts = (page = 0) => {
        dispatch(openLoader())
        Http.GET(
            'fetchProductByMaterials',
            `?materialId=${materialIdRef.current}&sort=id,desc&size=10&page=${page}`
        )
            .then(({ data }) => {
                if (data.currentPage > 0) {
                    data.data = [...myStateRef.current.data, ...data.data]
                }
                myStateRef.current = data
                setProducts(data.data)
                dispatch(closeLoader())
            })
            .catch((error) => {
                dispatch(closeLoader())
                toast.error(error.response.data.message)
            })
    }

    const handleScroll = () => {
        if (isPageReachBottom() && Object.keys(myStateRef.current).length > 0) {
            let { totalHits, currentPage, productResponseList } =
                myStateRef.current
            if (totalHits > 0 && totalHits !== productResponseList.length) {
                fetchProducts(currentPage + 1)
            }
        }
    }

    useEffect(() => {
        if (materials?.selectedMaterialId) {
            materialIdRef.current = materials.selectedMaterialId
            fetchProducts(0)
        }
    }, [materials.selectedMaterialId])

    const getFirstTag = (tagResponseList) => {
        let value = ''
        if (tagResponseList && tagResponseList.length > 0) {
            value = tagResponseList[0].text
        }
        return value
    }

    const renderSliderContent = () => {
        if (materials?.data.length > 0) {
            return materials.data.map((item) => {
                return (
                    <div
                        key={`materials_${item.materialId}`}
                        onClick={() =>
                            fetchProductByMaterialId(item.materialId)
                        }
                    >
                        <div
                            className={
                                materials?.selectedMaterialId ===
                                item.materialId
                                    ? 'active bg-white p-3 m-3 relative item cursor-pointer'
                                    : 'bg-white p-3 m-3 relative item cursor-pointer'
                            }
                        >
                            <div className='h-[278px]'>
                                <img
                                    src={item?.documentPath}
                                    alt=''
                                    className='object-cover w-full h-full'
                                />
                            </div>
                            <div className='flex py-4 pb-3 uppercase'>
                                <span>{item?.fabricType}</span>
                                {item?.construction && (
                                    <span className='dot mt-2.5' />
                                )}
                                <span>{item?.construction}</span>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    const modifyFavourite = (productId) => {
        let cloneProducts = [...products]
        cloneProducts.forEach((item) => {
            if (item.id === productId) {
                item.liked = !item.liked
            }
        })
        setProducts(cloneProducts)
    }

    const renderMaterialBasedProducts = () => {
        if (products?.length > 0) {
            let productList =
                location.pathname !== '/products/materialId'
                    ? products.slice(0, 7)
                    : products
            return (
                <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 xl:!grid-cols-4 gap-5'>
                    {productList.map((product) => (
                        <MaterialBasedProduct
                            key={`products_${product.id}`}
                            product={product}
                            modifyFavourite={modifyFavourite}
                        />
                    ))}
                    {products.length > 8 &&
                        location.pathname !== '/products/materialId' && (
                            <div
                                className='see-all flex items-center justify-center bg-primaryColor hover:bg-black h-[300px] sm:h-[330px] 5xl:h-[456px] cursor-pointer'
                                onClick={() => navigate('/products/materialId')}
                            >
                                <div className='text-white-shade-100 text-[40px] text-center flex flex-col items-center'>
                                    <div>See All</div>
                                    <IconRightArrow />
                                </div>
                            </div>
                        )}
                </div>
            )
        }
    }

    return (
        <>
            <div>
                <div className='max-w-[434px] mb-8'>
                    <div className='input-group'>
                        <SelectComponent
                            options={fabricOptions}
                            onChange={handleFabricChange}
                            selectedItem={materials?.fabricType}
                            isSearchable={false}
                        />
                    </div>
                </div>
            </div>

            <div className='flex flex-col sm:flex-row justify-between mb-12 gap-6'>
                <p className='text-base text-primaryColor sm:max-w-[50%]'>
                    Specially designed for you that introduces a unique
                    combination of <strong>luxury</strong> and{' '}
                    <strong>tradition</strong>. The feel and the experience of
                    the collections are top-notch.
                </p>
                <div className='sm:max-w-[40%]'>
                    <div className='flex flex-wrap justify-end gap-3'>
                        {unUsedTags.map((item, index) => {
                            return (
                                <span
                                    key={`tags_${item}_${index}`}
                                    className='text-base uppercase text-primaryColor px-4 rounded-full border border-primaryColor inline-block'
                                >
                                    {item}
                                </span>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className='kint-carasoul overflow-hidden mb-6'>
                {materials.data.length > 0 && (
                    <SliderWrapper
                        showSlides={6}
                        slideScroll={6}
                        count={materials.data.length}
                    >
                        {renderSliderContent()}
                    </SliderWrapper>
                )}
            </div>
            {renderMaterialBasedProducts()}
        </>
    )
}

export default FabricWiseProduct
