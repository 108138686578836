import React, { useEffect, useRef, useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as FavouriteIcon } from '../../../assets/icons/favorite.svg'
import { ReactComponent as PlayIcon } from '../../../assets/icons/play.svg'
import { ReactComponent as CollectionFillIcon } from '../../../assets/icons/collection-fill.svg'
import { ReactComponent as CollectionUnFillIcon } from '../../../assets/icons/collection-unfill.svg'
import { ReactComponent as NarrowViewSelected } from '../../../assets/icons/narrow-view-selected.svg'
import BreadCrumb from '../common/BreadCrumb'
import { useDispatch } from 'react-redux'
import {
    fetchCollectionInfo,
    likeSharedCollection,
    unlikeSharedCollection
} from '../../services/v3/SharedCollection/SharedCollection'
import { useParams } from 'react-router-dom'
import { closeLoader, openLoader, useLoader } from '../../redux_toolkit/Loader'
import { ReactComponent as FavouritedIcon } from '../../../assets/icons/favorited.svg'
import { ReactComponent as ChatIcon } from '../../../assets/icons/chat.svg'
import { ReactComponent as SelectedCardViewIcon } from '../../../assets/icons/grid-view-active.svg'
import CollectionTags from '../common/CollectionTags'
import CollectionDetailsThunk from '../../redux_toolkit/V3/CollectionDetails/CollectionDetailsThunk'
import {
    GET_ALL_PRODUCTS_FROM_COLLECTION,
    GET_FAVOURITE_COUNT,
    TOGGLE_PRODUCT_AS_FAVOURITE
} from '../../redux_toolkit/@types/thunk.types'
import {
    CollectionDetailsAction,
    useCollectionDetails
} from '../../redux_toolkit/V3/CollectionDetails'
import {
    getDaysAgo,
    getUserPreference,
    isPageReachBottom,
    onGetLocalFromUtc
} from '../../helpers/Util'
import DesignCardView from '../common/DesignCardView'
import {
    doFavourite,
    undoFavourite
} from '../../services/v3/Favourite/FavouriteService'
import { setSystemUserPreferences } from '../../services/CommonService'
import AddPeople from '../common/AddPeople'
import PresentationViewModal from '../common/PresentationViewModal'
import AddToFavourite from '../common/AddToFavourite'
import { DoFavouriteActions } from '../../redux_toolkit/V3/DoFavourite'
import { SET_SELECTED_FAVOURITE } from '../../redux_toolkit/@types/action.types'
import { FavouriteThunks } from '../../redux_toolkit/Favourite/FavouriteThunks'
import { toast } from 'react-toastify'
import { useGlobalState } from '../../redux_toolkit/V3/Common'
import ConversationRightSidePanel from '../common/ConversationRightSidePanel'
import useDocumentTitle from '../../hooks/userDocumentTitle'

const CollectionDetails = () => {
    const [isShowPresentation, setIsShowPresentation] = useState(false)
    const [openConversation, setOpenConversation] = useState(false)
    const [collectionInfo, setCollectionInfo] = useState(null)
    const [showFavourite, setShowFavourite] = useState(false)
    const [viewType, setViewType] = useState(null)
    const [products, setProducts] = useState([])
    const collectionDetails = useCollectionDetails()
    const trackId = useGlobalState()?.productTrackId
    const isLoading = useRef(null)
    const globalLoader = useLoader()
    const dispatch = useDispatch()
    const { id } = useParams()
    const scrollRef = useRef()

    useDocumentTitle(`Nitex - ${collectionInfo?.name || ''}`)

    useEffect(() => {
        let type =
            getUserPreference()?.collectionDetailsViewType || 'LARGE_VIEW'
        setViewType(type)
    }, [])

    const setIsDataLoading = (data) => {
        isLoading.current = data
    }

    useEffect(() => {
        setIsDataLoading(globalLoader)
    }, [globalLoader])

    const generateParams = (pageNumber) => {
        return `${id}?page=${pageNumber}&size=20`
    }

    const fetchCollectionInfoOnly = async () => {
        const data = await fetchCollectionInfo(id)
        setCollectionInfo(data)
    }

    const fetchCollectionDetails = async () => {
        try {
            dispatch(openLoader())
            await fetchCollectionInfoOnly()
            await dispatch(
                CollectionDetailsThunk[GET_ALL_PRODUCTS_FROM_COLLECTION](
                    generateParams(0)
                )
            )
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    useEffect(() => {
        fetchCollectionDetails()
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [id])

    const handleScroll = () => {
        if (isPageReachBottom()) {
            let { totalElements, currentPage, data } = scrollRef.current
            if (
                totalElements > 0 &&
                totalElements !== data.length &&
                !isLoading.current
            ) {
                dispatch(
                    CollectionDetailsThunk[GET_ALL_PRODUCTS_FROM_COLLECTION](
                        generateParams(currentPage + 1)
                    )
                )
            }
        }
    }

    useEffect(() => {
        setProducts(collectionDetails?.data)
        scrollRef.current = collectionDetails
    }, [collectionDetails])

    const toggleIsFavourite = async (bool, designInfo) => {
        try {
            dispatch(openLoader())
            if (bool) {
                let folderId = getUserPreference()?.defaultFavouriteFolder?.id
                let params = `?productIds=${designInfo.id}`
                if (trackId) params += `&tid=${trackId}`
                if (folderId) {
                    params += `&folderId=${folderId}`
                }
                await doFavourite(params)
                await dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]())
            } else {
                await undoFavourite(designInfo.id)
            }
            await dispatch(
                CollectionDetailsAction[TOGGLE_PRODUCT_AS_FAVOURITE](
                    designInfo.id
                )
            )
            if (bool) {
                dispatch(DoFavouriteActions[SET_SELECTED_FAVOURITE](designInfo))
                setShowFavourite(bool)
            }
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const toggleCollectionFavourite = async () => {
        try {
            dispatch(openLoader())
            if (!collectionInfo.isFavorite) {
                const data = await likeSharedCollection(id)
                await dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]())
                toast.success(data.message)
            } else {
                await unlikeSharedCollection(id)
            }
            await fetchCollectionDetails()
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const renderProducts = () => {
        return products?.map((item) => {
            return (
                <React.Fragment key={`collection_product_${item.id}`}>
                    <DesignCardView
                        designInfo={item}
                        triggerIsFavourite={toggleIsFavourite}
                        requestFrom='COLLECTION_DETAILS'
                    />
                </React.Fragment>
            )
        })
    }

    const toggleCollectionViewType = async (type) => {
        try {
            dispatch(openLoader())
            await setSystemUserPreferences({
                collectionDetailsViewType: type
            })
            setViewType(type)
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const memberList = collectionInfo?.membersList?.filter((item) =>
        ['BUYER', 'ACCOUNT_MANAGER', 'PROJECT_MANAGER'].includes(
            item.primaryUserType
        )
    )

    return (
        <>
            <div className='flex justify-between py-3 pr-4'>
                <BreadCrumb isShowBackButton={true} />
            </div>
            <div className='page-content'>
                <div className='collection status mb-6'>
                    <CollectionTags
                        item={{
                            tags: collectionInfo?.tagResponseList || []
                        }}
                    />
                </div>
                <div className='flex flex-wrap justify-between'>
                    <div className='w-full lg:w-auto'>
                        <div className='mb-4 lg:mb-6'>
                            <h2 className='text-lg black weight-500'>
                                {collectionInfo?.name}{' '}
                                {collectionInfo?.noOfStyles && (
                                    <span className='regular-12 gray_200 mr-2'>
                                        ({collectionInfo?.noOfStyles})
                                    </span>
                                )}{' '}
                                {collectionInfo !== null && (
                                    <span onClick={toggleCollectionFavourite}>
                                        {!collectionInfo?.isFavorite && (
                                            <FavouriteIcon />
                                        )}
                                        {collectionInfo?.isFavorite && (
                                            <FavouritedIcon />
                                        )}
                                    </span>
                                )}
                            </h2>
                            <p className='regular-14 gray_300'>
                                {collectionInfo?.sharedBy?.name}{' '}
                                {collectionInfo?.sharedAt &&
                                collectionInfo?.sharedBy?.name
                                    ? '|'
                                    : ''}{' '}
                                {collectionInfo?.sharedAt && (
                                    <span>
                                        Shared{' '}
                                        {getDaysAgo(
                                            onGetLocalFromUtc(
                                                collectionInfo?.sharedAt
                                            )
                                        )}
                                    </span>
                                )}
                            </p>
                            <p className='regular-14 black mt-2'>
                                {collectionInfo?.description}
                            </p>
                        </div>
                        <div className='actions mb-4'>
                            {/* <button
                                className='button secondary'
                                onClick={() => setIsShowPresentation(true)}
                            >
                                <span className='mr-2'>Presentation view</span>
                                <PlayIcon />
                            </button>
                            <button
                                className='button icon-only boder-0'
                                onClick={toggleCollectionViewType}
                            >
                                {renderViewTypeWiseIcon()}
                            </button> */}

                            <div className='actions mb-3 sm:mb-0'>
                                <button
                                    className='button !p-0 min-w-[44px]'
                                    onClick={() =>
                                        toggleCollectionViewType('LARGE_VIEW')
                                    }
                                >
                                    <Tooltip
                                        title='Card view'
                                        placement={'top'}
                                        arrow
                                    >
                                        {viewType === 'LARGE_VIEW' ? (
                                            <SelectedCardViewIcon className='!w-[44px] !h-[44px]' />
                                        ) : (
                                            <CollectionUnFillIcon className='!min-w-[24px] !min-h-[24px]' />
                                        )}
                                    </Tooltip>
                                </button>

                                <button
                                    className='button mx-2 !p-0 min-w-[44px]'
                                    onClick={() =>
                                        toggleCollectionViewType('NARROW_VIEW')
                                    }
                                >
                                    <Tooltip
                                        title='Image view'
                                        placement={'top'}
                                        arrow
                                    >
                                        {viewType === 'NARROW_VIEW' ? (
                                            <NarrowViewSelected className='!w-[44px] !h-[44px]' />
                                        ) : (
                                            <CollectionFillIcon className='!min-w-[24px] !min-h-[24px]' />
                                        )}
                                    </Tooltip>
                                </button>

                                <button
                                    className='button !p-0 ml-2'
                                    onClick={() => setIsShowPresentation(true)}
                                >
                                    <Tooltip
                                        title='Presentation view'
                                        placement={'top'}
                                        arrow
                                    >
                                        <PlayIcon className='!w-[24px] !h-[24px]' />
                                    </Tooltip>
                                </button>
                            </div>
                        </div>
                    </div>
                    <AddPeople
                        members={memberList}
                        requestFrom='COLLECTION'
                        callback={fetchCollectionInfoOnly}
                    />
                </div>
                <div
                    className={`design-list-container relative pt-5 ${
                        viewType === 'NARROW_VIEW' ? 'image-view' : ''
                    }`}
                >
                    {renderProducts()}
                    <AddToFavourite
                        isVisible={showFavourite}
                        setIsVisible={setShowFavourite}
                    />
                </div>
            </div>

            <button
                onClick={() => setOpenConversation(true)}
                className='button primary w-[55px] h-[55px] flex items-center justify-center icon-only fixed right-6 bottom-8 !rounded-full'
            >
                <ChatIcon />
            </button>

            {isShowPresentation && (
                <PresentationViewModal
                    onClose={() => setIsShowPresentation(false)}
                    collectionName={collectionInfo?.name}
                />
            )}
            {openConversation && (
                <ConversationRightSidePanel
                    type='COLLECTION_COMMENT'
                    conversationsType='COLLECTION_CONVERSATION'
                    id={id}
                    openModal={openConversation}
                    onGoBack={() => setOpenConversation(false)}
                    onCloseModal={() => {
                        setOpenConversation(false)
                    }}
                />
            )}
        </>
    )
}

export default CollectionDetails
