import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { collectionReducer } from './CollectionReducers';

const initialState = {
    activeTab: '',
    collections: {},
    filters: {}
}

const collectionSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: collectionReducer
})

export const collectionActionTypes = collectionSlice.actions

export const useCollections = () => useSelector(state => state.collections)

export default collectionSlice.reducer
