import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import SearchReducer from './SearchReducer'

const initialState = {
    searchResults: [],
    croppedImage: null,
    activeImage: null,
    images: []
}

const Search = createSlice({
    name: 'searchDesigns',
    initialState,
    reducers: SearchReducer
})

export const SearchActions = Search.actions;

export const useSearchResults = () => useSelector(state => state.searchDesigns.searchResults)
export const useSearchedImages = () => useSelector(state => state.searchDesigns.images)
export const useActiveSearchImage = () => useSelector(state => state.searchDesigns.activeImage)
export const useCroppedImage = () => useSelector(state => state.searchDesigns.croppedImage)

export default Search.reducer
