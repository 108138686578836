import {
    SET_QUOTE_DETAILS,
    SET_QUOTE_LIST,
    SET_QUOTE_LIST_PAGE_ACTIVE_TAB,
    SET_SELECT_PRODUCT_FROM_QUOTE,
    CLEAR_SELECTED_PRODUCT_FROM_QUOTE,
    SET_ALL_PRODUCT_FROM_QUOTE,
    CLEAR_QUOTE_DETAILS
} from '../@types/action.types'

export const quoteReducer = {
    [SET_QUOTE_LIST_PAGE_ACTIVE_TAB]: (state, action) => {
        state.activeTab = action.payload
    },
    [SET_QUOTE_LIST]: (state, action) => {
        let quotes = []
        if (action.payload.merge) {
            quotes = [...state.quotes.data, ...action.payload.data]
        } else {
            quotes = [...action.payload.data]
        }
        action.payload.data = quotes
        state.quotes = action.payload
    },
    [SET_QUOTE_DETAILS]: (state, action) => {
        state.quote = action.payload
    },
    [SET_SELECT_PRODUCT_FROM_QUOTE]: (state, { payload }) => {
        if (payload.checked) {
            state.selectedProducts.push(payload.value)
        } else {
            state.selectedProducts = state.selectedProducts.filter(
                (item) => item !== payload.value
            )
        }
    },
    [CLEAR_SELECTED_PRODUCT_FROM_QUOTE]: (state, action) => {
        state.selectedProducts = []
    },

    [SET_ALL_PRODUCT_FROM_QUOTE]: (state, { payload }) => {
        if (payload.checked) {
            state.selectedProducts = [...payload.items]
        } else {
            state.selectedProducts = []
        }
    },

    [CLEAR_QUOTE_DETAILS]: (state, action) => {
        state.quote = null
    }
}
