import React, { useEffect, useRef, useState } from 'react'
import FilterComponent from '../filter/FilterComponent'
import { useDispatch } from 'react-redux'
import {
    GET_ALL_EXPLORE_DESIGNS,
    GET_FAVOURITE_COUNT,
    SELECT_DESIGN_FOR_FAVOURITE,
    UNSELECT_DESIGN_FOR_FAVOURITE
} from '../../redux_toolkit/@types/thunk.types'
import { ExploreDesignThunks } from '../../redux_toolkit/V3/DesignLab/ExploreDesignThunks'
import { useExploreDesigns } from '../../redux_toolkit/V3/DesignLab/ExploreDesignSlice'
import DesignCardView from '../common/DesignCardView'
import ScrollTop from '../common/ScrollTop'
import {
    getUserPreference,
    isPageReachBottom,
    setBreadCrumbRoutes,
    getTotalDashboardCount
} from '../../helpers/Util'
import { closeLoader, openLoader, useLoader } from '../../redux_toolkit/Loader'
import { FilterActionTypes, useFilter } from '../../redux_toolkit/V3/Filter'
import {
    exploreDesignFields,
    generateExploreDesignPageParams
} from '../filter/filterUtil'
import AddToFavourite from '../common/AddToFavourite'
import { ReactComponent as CollectionsIcon } from '../../../assets/icons/collections.svg'
import { useNavigate, Link } from 'react-router-dom'
import {
    ADD_FILTER_FIELDS,
    CLEAR_FILTER_FIELDS,
    SET_ALL_DESIGN_LOOK,
    SET_CATEGORY_LIST,
    SET_DEPARTMENTS_LIST,
    SET_FABRIC_LIST,
    SET_SEASON_LIST
} from '../../redux_toolkit/@types/action.types'
import { DoFavouriteThunk } from '../../redux_toolkit/V3/DoFavourite/DoFavouriteThunk'
import BuildRangeRightSidePanel from '../range/BuildRangeRightSidePanel'
import { FavouriteThunks } from '../../redux_toolkit/Favourite/FavouriteThunks'
import {
    fetchFilterListByBuyer,
    setSystemUserPreferences
} from '../../services/CommonService'
import { fetchCurrentBuyerRequest } from '../../services/v3/Dashboard/Dashborad'
import { FilterOptionTypes } from '../../redux_toolkit/V3/Filter/FilterOptionReducers'
import { useGlobalState } from '../../redux_toolkit/V3/Common'
import { ReactComponent as CollectionFillIcon } from '../../../assets/icons/collection-fill.svg'
import { ReactComponent as CollectionUnFillIcon } from '../../../assets/icons/collection-unfill.svg'
import { LOADER_COLOR } from '../../constant'
import useDocumentTitle from '../../hooks/userDocumentTitle'

const ExploreDesign = () => {
    const [total, setToal] = useState(null)
    const [showMoreDesign, setShowMoreDesign] = useState(true)
    const [showFavourite, setShowFavourite] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [viewType, setViewType] = useState(null)
    const [designs, setDesigns] = useState([])
    const [backgroundColor, setBackgroundColor] = useState('#068BEE')
    const [textdColor, setTextColor] = useState('#FFF')
    const [linkColor, setLinkColor] = useState('#FFF')
    const trackId = useGlobalState()?.productTrackId
    const exploreDesigns = useExploreDesigns()
    const isLoading = useRef(null)
    const globalLoader = useLoader()
    const filterStore = useFilter()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const scrollRef = useRef()
    useDocumentTitle('Nitex - Design Lab')

    useEffect(() => {
        onFetchBuyerRequest()
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            setBackgroundColor('#F5F5F5')
            setLinkColor(LOADER_COLOR)
            setTextColor('#21242B')
        }, 3000)
        return () => clearTimeout(timer)
    }, [])

    const onFetchBuyerRequest = async () => {
        const data = await fetchCurrentBuyerRequest()
        setToal(getTotalDashboardCount(data))
    }

    useEffect(() => {
        let type = getUserPreference()?.exploreDesignViewType || 'LARGE_VIEW'
        setViewType(type)
    }, [])

    const setIsDataLoading = (data) => {
        isLoading.current = data
    }

    const fetchFilterFields = async () => {
        const data = await fetchFilterListByBuyer('?isExploreDesignPage=true')
        for (let item in exploreDesignFields) {
            if (item === 'DESIGN_LOOK')
                dispatch({
                    type: FilterOptionTypes[SET_ALL_DESIGN_LOOK],
                    payload: data?.designLookList?.map((item) => ({
                        id: item.constant,
                        name: item.name
                    }))
                })
            if (item === 'CATEGORY')
                dispatch({
                    type: FilterOptionTypes[SET_CATEGORY_LIST],
                    payload: data.categoryList
                })
            if (item === 'SEASON')
                dispatch({
                    type: FilterOptionTypes[SET_SEASON_LIST],
                    payload: data?.seasonList?.map((item) => ({
                        id: item.constant,
                        name: item.name
                    }))
                })
            if (item === 'FABRIC')
                dispatch({
                    type: FilterOptionTypes[SET_FABRIC_LIST],
                    payload: data?.fabricTypeList?.map((item) => ({
                        id: item.constant,
                        name: item.name
                    }))
                })
            if (item === 'DEPARTMENT' && data?.departmentList?.length > 0)
                dispatch({
                    type: FilterOptionTypes[SET_DEPARTMENTS_LIST],
                    payload: data?.departmentList
                })
        }
    }

    useEffect(() => {
        fetchFilterFields()
        return () => {
            dispatch(FilterActionTypes[CLEAR_FILTER_FIELDS]())
        }
    }, [])

    useEffect(() => {
        setIsDataLoading(globalLoader)
    }, [globalLoader])

    const generateParams = (pageNumber) => {
        let size = 20
        if (pageNumber === 0) size = 40
        if (pageNumber === 1) pageNumber = 2
        return `?page=${pageNumber}&size=${size}${generateExploreDesignPageParams(
            filterStore
        )}`
    }

    const fetchExploreDesigns = (pageNumber) => {
        dispatch(
            ExploreDesignThunks[GET_ALL_EXPLORE_DESIGNS](
                generateParams(pageNumber),
                filterStore?.isMoreExplore
            )
        )
    }

    useEffect(() => {
        fetchExploreDesigns(0)
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [filterStore])

    const handleScroll = () => {
        if (isPageReachBottom()) {
            let { totalElements, currentPage, data } = scrollRef.current
            if (
                totalElements > 0 &&
                totalElements !== data.length &&
                !isLoading.current
            ) {
                fetchExploreDesigns(currentPage + 1)
            }
        }
    }

    useEffect(() => {
        setDesigns(exploreDesigns?.data)
        scrollRef.current = exploreDesigns
    }, [exploreDesigns])

    const toggleIsFavourite = async (bool, designInfo, isShowFav = true) => {
        if (bool === true) {
            await dispatch(
                DoFavouriteThunk[SELECT_DESIGN_FOR_FAVOURITE](
                    designInfo,
                    trackId
                )
            )
        } else {
            await dispatch(
                DoFavouriteThunk[UNSELECT_DESIGN_FOR_FAVOURITE](designInfo)
            )
        }
        await dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]())
        if (isShowFav) setShowFavourite(bool)
    }

    const renderDesigns = () => {
        return designs?.map((item, i) => {
            return (
                <React.Fragment key={`single_item_${i}`}>
                    <DesignCardView
                        designInfo={item}
                        triggerIsFavourite={toggleIsFavourite}
                    />
                </React.Fragment>
            )
        })
    }

    const renderExploreMore = () => {
        if (!scrollRef.current) return
        let { totalPages, currentPage } = scrollRef.current
        if (currentPage + 1 === totalPages && showMoreDesign) {
            return (
                <div className='more-design mt-16 mb-8 d-flex justify-center items-center'>
                    <p className='regular-16 mr-6'>
                        Do you want to explore more designs?
                    </p>
                    <button
                        className='button primary mr-3'
                        onClick={() => {
                            dispatch(
                                FilterActionTypes[ADD_FILTER_FIELDS]({
                                    data: { isMoreExplore: true }
                                })
                            )
                        }}
                    >
                        Yes
                    </button>
                    <button
                        className='button secondary'
                        onClick={() => setShowMoreDesign(false)}
                    >
                        No
                    </button>
                </div>
            )
        }
    }

    const routeCollectionPage = () => {
        dispatch(FilterActionTypes[CLEAR_FILTER_FIELDS]())
        let newRoute = '/shared-collection'
        let visitingRoutes = [
            {
                key: '/design-lab',
                value: 'Design Lab'
            },
            {
                key: newRoute,
                value: 'Collections'
            }
        ]

        setBreadCrumbRoutes(visitingRoutes)
        navigate(newRoute)
    }

    const onCloseModal = () => {
        setOpenModal(false)
    }

    const toggleDesignViewType = async () => {
        try {
            let type =
                getUserPreference()?.exploreDesignViewType === 'NARROW_VIEW'
                    ? 'LARGE_VIEW'
                    : 'NARROW_VIEW'
            dispatch(openLoader())
            await setSystemUserPreferences({
                exploreDesignViewType: type
            })
            setViewType(type)
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const renderViewTypeWiseIcon = () => {
        let type =
            getUserPreference()?.exploreDesignViewType === 'NARROW_VIEW'
                ? 'LARGE_VIEW'
                : 'NARROW_VIEW'
        if (type !== 'NARROW_VIEW') {
            return <CollectionFillIcon />
        } else {
            return <CollectionUnFillIcon />
        }
    }

    const getCount = (type, title) => {
        if (total?.[type]?.RUNNING > 0) {
            return total?.[type]?.RUNNING > 1
                ? `${total?.[type]?.RUNNING} ${title.toLowerCase()}s`
                : `${total?.[type]?.RUNNING} ${title.toLowerCase()}`
        } else return null
    }

    const quoteCount = getCount('quotes', 'quote')
    const sampleCount = getCount('sample', 'sample')
    const orderCount = getCount('order', 'order')

    const counts = [quoteCount, sampleCount, orderCount]
        .filter(Boolean)
        .join(', ')

    const renderLink = () => (
        <Link
            style={{ color: linkColor }}
            to='/dashboard?active=QUOTES&status=RUNNING'
        >
            See all
        </Link>
    )

    const renderText = `You currently have ${counts}. `

    const renderTopBar = () => {
        return (
            <div
                style={{ backgroundColor: backgroundColor }}
                className={'bg text-center py-2 px-4'}
            >
                <p style={{ color: textdColor }} className='text-sm'>
                    {renderText}
                    {renderLink()}
                </p>
            </div>
        )
    }

    const getNumberCount = () => {
        let isValid = false
        if (total?.['quotes']?.RUNNING > 0) {
            isValid = true
        }
        if (total?.['sample']?.RUNNING > 0) {
            isValid = true
        }
        if (total?.['order']?.RUNNING > 0) {
            isValid = true
        }
        return isValid
    }

    return (
        <>
            {total && getNumberCount() && renderTopBar()}
            <FilterComponent
                requiredFields={exploreDesignFields}
                filteredDataLength={designs?.length}
            >
                <button
                    className='button icon-only boder-0'
                    onClick={toggleDesignViewType}
                >
                    {renderViewTypeWiseIcon()}
                </button>

                <button
                    className='button secondary round sm mr-2 flex items-center'
                    onClick={routeCollectionPage}
                >
                    <span className='mr-2'>Collections</span>
                    <CollectionsIcon />
                </button>
                <button
                    className='button primary round sm'
                    onClick={() => setOpenModal(true)}
                >
                    <span>Build your range</span>
                </button>
            </FilterComponent>
            <div className='page-content relative px-4'>
                <AddToFavourite
                    isVisible={showFavourite}
                    setIsVisible={setShowFavourite}
                />
                <div
                    className={`design-list-container ${
                        viewType === 'NARROW_VIEW' ? 'image-view' : ''
                    }`}
                >
                    {renderDesigns()}
                </div>
                {designs?.length === 0 && (
                    <div className='epmpty-state py-40 text-center m-auto'>
                        <h3 className='text-xl weight-700 gray_400'>
                            No results found
                        </h3>
                        <p className='text-base gray_400'>
                            Please try a different filter
                        </p>
                    </div>
                )}
            </div>
            {!filterStore?.isMoreExplore && renderExploreMore()}
            <ScrollTop />
            {openModal && (
                <BuildRangeRightSidePanel
                    openModal={openModal}
                    onCloseModal={onCloseModal}
                />
            )}
        </>
    )
}

export default ExploreDesign
