import { GET_TEAM_MEMBERS } from '../@types/thunk.types'
import { closeLoader, openLoader } from '../Loader'
import Http from '../../helpers/Http'
import { toast } from 'react-toastify'
import { TeamActionTypes } from './index'
import { SET_TEAM_MEMBER_LIST } from '../@types/action.types'

export const TeamThunks = {
    [GET_TEAM_MEMBERS]: (url, bool) => async (dispatch) => {
        dispatch(openLoader())
        Http.GET(url,'', true).then((response) => {
            dispatch({
                type: TeamActionTypes[SET_TEAM_MEMBER_LIST],
                payload: { data: response.data, bool: bool }
            })
            dispatch(closeLoader())
        }).catch((error) => {
            dispatch(closeLoader())
            toast.error(error.response.data.message)
        })
    }
}
