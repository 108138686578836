import React from 'react'
import { FilterActionTypes, useFilter } from '../../../redux_toolkit/V3/Filter'
import { useDispatch } from 'react-redux'
import { SET_FILTER_FIELD } from '../../../redux_toolkit/@types/action.types'

const SortBy = () => {
    const filter = useFilter()
    const dispatch = useDispatch()

    const handleCheckbox = async (e) => {
        await dispatch(FilterActionTypes[SET_FILTER_FIELD](e.target))
    }

    const isOrderChecked = (item) => {
        if (filter?.sortBy) {
            return filter?.sortBy?.indexOf(item) !== -1
        } else {
            return false
        }
    }

    return (
        <div className='border-r border-b last:border-r-none border-white-shade-100 py-6 px-10'>
            <span className='text-primaryColor-shade-100'>Sort by</span>
            <div className='mt-6 space-y-8'>
                <div className='flex items-start'>
                    <span>
                        <input
                            type='radio'
                            name='sortBy'
                            id='NewestFirst'
                            value='id,desc'
                            onChange={handleCheckbox}
                            checked={isOrderChecked('id,desc')}
                        />
                    </span>
                    <label
                        htmlFor='NewestFirst'
                        className='align-middle pl-4 inline-block mt-[-3px]'
                    >
                        Newest First
                    </label>
                </div>
                <div className='flex items-start'>
                    <span>
                        <input
                            type='radio'
                            name='sortBy'
                            id='OldestFirst'
                            value='id,asc'
                            onChange={handleCheckbox}
                            checked={isOrderChecked('id,asc')}
                        />
                    </span>
                    <label
                        htmlFor='OldestFirst'
                        className='align-middle pl-4 inline-block mt-[-3px]'
                    >
                        Oldest First
                    </label>
                </div>
            </div>
        </div>
    )
}

export default SortBy
