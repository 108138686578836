import React, { useState } from 'react'
import { ReactComponent as ArrowLeftIcon } from '../../../icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../../icons/arrow-right.svg'
import { ReactComponent as ChatIcon } from '../../../icons/chat.svg'
import { ReactComponent as PlusSquareIcon } from '../../../icons/plus-square.svg'
import { ReactComponent as ApprovedIcon } from '../../../icons/approved.svg'
import { ReactComponent as RecostIcon } from '../../../icons/recost.svg'
import { ReactComponent as WarningIcon } from '../../../icons/warning.svg'
import ModalContainer from '../../../../app/common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../icons/close.svg'
import { ReactComponent as UploadIcon } from '../../../icons/upload.svg'
import { ReactComponent as DeleteIcon } from '../../../icons/delete.svg'
import { NavLink } from 'react-router-dom'

const QuoteDetails = () => {
    const [showPlaceOrderModal, setShowPlaceOrderModal] = useState(false)
    const [showCongratulationModal, setShowCongratulationModal] =
        useState(false)

    const handleClose = () => {
        setShowPlaceOrderModal(false)
        setShowCongratulationModal(false)
    }
    return (
        <>
            <div className='py-3'>
                <div className='flex align-items-center'>
                    <button className='button link mr-6'>
                        <ArrowLeftIcon />
                        <span className='black'>Back</span>
                    </button>
                    <div className='breadcrumb'>
                        <p className='regular-14 black'>
                            <span className='blue'>Favorites </span> /{' '}
                            <span className='regular-12'>
                                My Winter Collection
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className='body-content'>
                <div className='quote-status mt-2'>
                    <span className='status blue-1 mr-2'>Quote Received</span>
                    <span className='status purple-1 mr-2'>Buyer Design</span>
                </div>
                <div className='flex justify-between'>
                    <div className='quote-content'>
                        <h2 className='regular-18 weight-500 mt-2'>
                            Men’s Semi-formal Winter 2024{' '}
                            <span className='regular-12 gray_200'>(2)</span>
                        </h2>
                        <h4 className='regular-14 gray_300 my-2'>
                            Q2210-A0024
                        </h4>
                        <p className='regular-12 max-w-[640px]'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse ultricies mauris odio. Maecenas
                            congue nisl eu suscipit aliquet. Phasellus rhoncus
                            varius ultricies. Maecenas sollicitudin lectus at mi
                            facilisis auctor.
                        </p>
                        <button className='button link mt-6'>
                            Download costing sheet
                        </button>
                    </div>
                    <div className='team-members'>
                        <div className='flex items-center'>
                            <div className='member-photos flex'>
                                <img src='/images/user.svg' alt='member' />
                                <img src='/images/user.svg' alt='member' />
                                <img src='/images/user.svg' alt='member' />
                            </div>
                            <button className='button secondary ml-2'>
                                Add People
                            </button>
                        </div>
                    </div>
                </div>

                <div className='costing-table mt-6'>
                    <table className='table quote-list-table'>
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='all'
                                        value=''
                                    />
                                </th>
                                <th>Style</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Target Price</th>
                                <th>Offer price</th>
                                <th>Action</th>
                                <th>Conversation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Mix, Twill, 49% Tencel 49% Cotton 2%
                                    Spandex, GSM 200
                                </td>
                                <td>456</td>
                                <td>$120.56</td>
                                <td>$112.56</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button primary regular-12 round mr-2'>
                                            Approve
                                        </button>
                                        <button className='button secondary regular-12 round'>
                                            Re-cost
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Mix, Twill, 49% Tencel 49% Cotton 2%
                                    Spandex, GSM 200
                                </td>
                                <td>456</td>
                                <td>$120.56</td>
                                <td>$112.56</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button
                                            className='button primary round'
                                            disabled
                                        >
                                            <span className='mr-2'>
                                                Approved
                                            </span>
                                            <ApprovedIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                        <span className='count absolute'>
                                            10
                                        </span>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Mix, Twill, 49% Tencel 49% Cotton 2%
                                    Spandex, GSM 200
                                </td>
                                <td>456</td>
                                <td>$120.56</td>
                                <td>$112.56</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button pill round'>
                                            <span className='mr-2'>
                                                Re-cost Requested
                                            </span>
                                            <RecostIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                        <span className='count absolute'>
                                            10
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='tab-view-table quote-tab-view-table py-4'>
                    <div className='select-all mb-2'>
                        <div className='flex items-center'>
                            <input
                                type='checkbox'
                                name='categories'
                                id='all'
                                value=''
                            />
                            <label className='text-base blue ml-2'>
                                Select all
                            </label>
                        </div>
                    </div>
                    <div className='all-rows'>
                        <div className='single-row'>
                            <table className='table'>
                                <tr>
                                    <td rowSpan='7'>
                                        <input
                                            type='checkbox'
                                            name='categories'
                                            id='all'
                                            value=''
                                        />
                                    </td>
                                    <td className='heading'>Style</td>
                                    <td>
                                        <div className='flex items-center'>
                                            <img
                                                src='/images/single-design.png'
                                                alt='style'
                                                className='style-image w-11'
                                            />
                                            <p className='blue regular-14 cursor-pointer ml-2'>
                                                MT22-A0121
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Description</td>
                                    <td>
                                        Mix, Twill, 49% Tencel 49% Cotton 2%
                                        Spandex, GSM 200
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Quantity</td>
                                    <td>456</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Target Price</td>
                                    <td>$120.56</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Offer price</td>
                                    <td>$112.56</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Action</td>
                                    <td>
                                        {' '}
                                        <div className='flex items-center'>
                                            <button className='button primary regular-12 round mr-2'>
                                                Approve
                                            </button>
                                            <button className='button secondary regular-12 round'>
                                                Re-cost
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Conversation</td>
                                    <td>
                                        <button className='button secondary icon-only chat-btn'>
                                            <ChatIcon />
                                            <span className='count absolute'>
                                                10
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className='add-row'>
                            <button className='button link my-5'>
                                <PlusSquareIcon /> Add new style
                            </button>
                        </div>
                        <div className='single-row'>
                            <table className='table'>
                                <tr>
                                    <td rowSpan='7'>
                                        <input
                                            type='checkbox'
                                            name='categories'
                                            id='all'
                                            value=''
                                        />
                                    </td>
                                    <td className='heading'>Style</td>
                                    <td>
                                        <div className='flex items-center'>
                                            <img
                                                src='/images/single-design.png'
                                                alt='style'
                                                className='style-image w-11'
                                            />
                                            <p className='blue regular-14 cursor-pointer ml-2'>
                                                MT22-A0121
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Description</td>
                                    <td>
                                        Mix, Twill, 49% Tencel 49% Cotton 2%
                                        Spandex, GSM 200
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Quantity</td>
                                    <td>456</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Target Price</td>
                                    <td>$120.56</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Offer price</td>
                                    <td>$112.56</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Action</td>
                                    <td>
                                        {' '}
                                        <div className='flex items-center'>
                                            <button className='button primary regular-12 sm round mr-2'>
                                                Approve
                                            </button>
                                            <button className='button secondary regular-12 sm round'>
                                                Re-cost
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Conversation</td>
                                    <td>
                                        <button className='button secondary icon-only chat-btn'>
                                            <ChatIcon />
                                            <span className='count absolute'>
                                                10
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className='pagination mt-8 flex items-center justify-center'>
                <div className='flex items-center'>
                    <button className='button link mr-1'>
                        <ArrowLeftIcon />
                    </button>
                    <div className='page-numbers'>
                        <span className='active'>1</span>
                        <span>2</span>
                        <span>3</span>
                        <span>4</span>
                        <span>5</span>
                        <span className='dots'>...</span>
                        <span>10</span>
                        <span>20</span>
                    </div>
                    <button className='button link ml-1'>
                        <ArrowRightIcon />
                    </button>
                    <div className='page-sorting ml-4'>
                        <select>
                            <option>20</option>
                            <option>40</option>
                            <option>60</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* <div className='selected-item-count flex justify-center'>
                <div className='item-count-content flex items-center justify-between max-w-[900px] w-full'>
                    <div className='flex items-center'>
                        <p className='regular-18'>1 Item </p>
                        <span className='px-4'>|</span>
                        <button className='button link'>Select all</button>
                    </div>
                    <div>
                        <p className='regular-16 red flex items-center'>
                            <WarningIcon className='mr-1' /> You can place order
                            only for approved styles.
                        </p>
                    </div>
                    <div>
                        <button
                            className='button primary round mr-3'
                            onClick={() => setShowPlaceOrderModal(true)}
                        >
                            Place Order
                        </button>
                        <button className='button primary round'>Sample</button>
                    </div>
                </div>
            </div> */}

            {/* Place order modal */}
            {showPlaceOrderModal && (
                <ModalContainer
                    className='place-order-modal right-sidebar'
                    // onCloseModal={() => toggleModel(null)}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Place Order
                                </h3>
                                <p className='regular-12 gray_300'>
                                    Place your order
                                </p>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <div className='form-group'>
                                <label className='regular-14 gray_300 mb-2'>
                                    Number of style
                                </label>

                                <input type='date' name='date' />
                            </div>
                            <div className='form-group pt-3'>
                                <div className='file'>
                                    <input id='input-file' type='file' />
                                    <label
                                        htmlFor='input-file'
                                        className='w-full justify-between'
                                    >
                                        <span className='mr-4'>Upload P.O</span>
                                        <UploadIcon />
                                    </label>
                                </div>
                            </div>

                            <table className='table mt-8'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>File</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-center w-[72px] max-w-[72px]'>
                                            <span className='cursor-pointer'>
                                                <DeleteIcon />
                                            </span>
                                        </td>
                                        <td>
                                            <div class='flex items-center'>
                                                <span>
                                                    <img
                                                        class='cursor-pointer file-icon'
                                                        src='/images/file/pdf.svg'
                                                    />
                                                </span>
                                                <span class='text-base ml-2'>
                                                    <span className='regular-14 black'>
                                                        PO.pdf
                                                    </span>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <button
                                className='button primary mt-5'
                                onClick={() => setShowCongratulationModal(true)}
                            >
                                Place order
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}

            {showCongratulationModal && (
                <ModalContainer className='create-folder-modal'>
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Congratulations
                                </h3>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <p className='regular-18 black'>
                                Your order has been placed. You can check order
                                status on <NavLink to='#'>Order</NavLink>{' '}
                                Section
                            </p>
                        </div>
                        <div className='common-popup-footer'>
                            <button
                                className='button secondary mb-2'
                                onClick={handleClose}
                            >
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    )
}

export default QuoteDetails
