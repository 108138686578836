import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-round.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg'
import {
    fetchAllBrandDepartments,
    fetchMembersByDepartment
} from '../../services/CommonService'
import { removeMemberFromBrandTeam } from '../../services/v3/TeamMembers/TeamMembersService'
import AddPeople from '../../v3/common/AddPeople'
import {
    getUserInfo,
    capitalizeFirstAndLowercaseRest,
    changeDateFormat
} from '../../helpers/Util'
import { openLoader, closeLoader } from '../../redux_toolkit/Loader'

const TeamMembers = () => {
    const [allDepartments, setAllDepartments] = useState([])
    const [allMemberList, setAllMemberList] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const dispatch = useDispatch()
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const onSetAllDepartments = async (isInitialCall = false) => {
        const responseData = await fetchAllBrandDepartments()
        setAllDepartments(responseData)
        if (isInitialCall) {
            setSelectedDepartment(responseData[0])
        }
    }

    const onSetTeamMembers = async () => {
        if (selectedDepartment?.department) {
            const memberResponse = await fetchMembersByDepartment(
                selectedDepartment?.department
            )
            setAllMemberList(memberResponse)
        } else setAllMemberList([])
    }

    useEffect(() => {
        onSetAllDepartments(true)
    }, [])

    useEffect(() => {
        if (selectedDepartment) {
            onSetTeamMembers()
        }
    }, [selectedDepartment])

    const isShowRemove = (member) => {
        const userInfo = getUserInfo()
        return member.id !== userInfo.id && member?.teamId === userInfo?.teamId
    }
    const onRemoveUser = async (member) => {
        dispatch(openLoader())
        await removeMemberFromBrandTeam(member.id)
        await onSetTeamMembers()
        await onSetAllDepartments(false)
        dispatch(closeLoader())
    }

    const renderWebView = () => {
        return (
            <div className='team-members-table'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Team member</th>
                            <th>Team</th>
                            <th>Email</th>
                            <th>Added</th>
                            {selectedDepartment?.myDepartment && (
                                <th className='teamAction'>Action</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {allMemberList?.map((item) => (
                            <tr key={item?.id}>
                                <td>
                                    <div className='flex items-center'>
                                        <div className='profile mr-3'>
                                            <img
                                                src={
                                                    item?.profilePicDocument
                                                        ?.docUrl ||
                                                    '/images/user.svg'
                                                }
                                                alt='member'
                                                className='w-[32px] h-[32px] object-cover rounded-full'
                                            />
                                        </div>
                                        <div className='designation-info'>
                                            <h3 className='text-sm black weight-500'>
                                                {item?.name}
                                            </h3>
                                            <p className='regular-12 gray_300'>
                                                {item?.designation}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td>{item?.teamName} </td>
                                <td>{item?.email}</td>
                                <td>
                                    {changeDateFormat(
                                        item?.addedAt,
                                        '',
                                        'Do MMM, YYYY'
                                    )}
                                </td>
                                {selectedDepartment?.myDepartment && (
                                    <td>
                                        {isShowRemove(item) && (
                                            <span
                                                className='cursor-pointer'
                                                onClick={() =>
                                                    onRemoveUser(item)
                                                }
                                            >
                                                <DeleteIcon />
                                            </span>
                                        )}
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }

    const renderTabView = () => {
        return (
            <div className='tab-view-table team-members-tab-view-table py-4'>
                <div className='all-rows'>
                    {allMemberList?.map((item) => (
                        <div className='single-row mb-4' key={item?.id}>
                            <table className='table'>
                                <tr>
                                    <td className='heading'>Team member</td>
                                    <td>
                                        <div className='flex items-center'>
                                            <div className='profile mr-3'>
                                                <img
                                                    src={
                                                        item?.profilePicDocument
                                                            ?.docUrl ||
                                                        '/images/user.svg'
                                                    }
                                                    alt='member'
                                                    className='w-[32px] h-[32px] object-cover rounded-full'
                                                />
                                            </div>
                                            <div className='designation-info'>
                                                <h3 className='text-sm black weight-500'>
                                                    {item?.name}
                                                </h3>
                                                <p className='regular-12 gray_300'>
                                                    {item?.designation}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Team</td>
                                    <td>{item?.teamName} </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Email</td>
                                    <td>{item?.email}</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Added</td>
                                    <td>
                                        {changeDateFormat(
                                            item?.addedAt,
                                            '',
                                            'Do MMM, YYYY'
                                        )}
                                    </td>
                                </tr>
                                {selectedDepartment?.myDepartment && (
                                    <tr>
                                        <td className='heading'>Action</td>
                                        <td>
                                            {isShowRemove(item) && (
                                                <span
                                                    className='cursor-pointer'
                                                    onClick={() =>
                                                        onRemoveUser(item)
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <div className='page-content'>
            <div className='flex flex-wrap justify-between items-center py-3'>
                <div className='common-tab'>
                    <ul>
                        {allDepartments?.map((item) => (
                            <>
                                <li
                                    onClick={() => setSelectedDepartment(item)}
                                    className={`${
                                        selectedDepartment?.department ===
                                        item?.department
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    <span>
                                        {item?.myDepartment
                                            ? item?.department
                                                ? `My Department - ${capitalizeFirstAndLowercaseRest(
                                                      item?.department
                                                  )} (${item?.count})`
                                                : 'My Department'
                                            : `${capitalizeFirstAndLowercaseRest(
                                                  item?.department
                                              )} (${item?.count})`}
                                    </span>
                                </li>
                            </>
                        ))}
                    </ul>
                </div>
                {selectedDepartment?.myDepartment && (
                    <div className='mt-3 lg:mt-0'>
                        {/* <button className='button link ml-2 cursor-pointer'>
                        <PlusIcon /> Add team member
                    </button> */}
                        <AddPeople
                            members={allMemberList}
                            requestFrom='TEAM_MEMBERS'
                            callback={() => {
                                onSetTeamMembers()
                                onSetAllDepartments(false)
                            }}
                        />
                    </div>
                )}
            </div>
            {screenWidth < 768 ? renderTabView() : renderWebView()}
        </div>
    )
}

export default TeamMembers
