import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SliderWrapper = (props) => {
    let settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: props.showSlides || 6,
        slidesToScroll: props.slideScroll || 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1537,
                settings: {
                    slidesToShow: props.count > 3 ? 4 : props.count,
                    slidesToScroll: props.count > 3 ? 4 : props.count,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: props.count > 2 ? 3 : props.count,
                    slidesToScroll: props.count > 2 ? 3 : props.count,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return <Slider {...settings}>{props.children}</Slider>
}

export default SliderWrapper
