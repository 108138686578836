import {
    REMOVE_FAVOURITE_STYLE_BY_ID,
    SET_FAVOURITE_STYLES
} from '../@types/action.types'

export const FavouriteStyleReducers = {
    [SET_FAVOURITE_STYLES]: (state, { payload }) => {
        let styles = []
        if (payload.merge) {
            styles = [...state.response.data, ...payload.data.data]
        } else {
            styles = [...payload.data.data]
        }
        payload.data.data = styles
        state.response = payload.data
    },
    [REMOVE_FAVOURITE_STYLE_BY_ID]: (state, { payload }) => {
        state.response.data = state.response.data.filter(style => style.id !== payload)
    }
}
