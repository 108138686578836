
import {  SET_SAMPLE_DETAILS, SET_SAMPLE_LIST, SET_SAMPLE_LIST_PAGE_ACTIVE_TAB } from '../@types/action.types';
import {  GET_SAMPLE_DETAIL, GET_SAMPLE_LIST } from '../@types/thunk.types'
import { openLoader,closeLoader } from '../Loader';
import { toast } from 'react-toastify'
import { getSampleDetailById, getSampleList } from '../../services/Sample/SampleService';
import { SampleActionsType } from './SampleSlice';

const SampleThunks = {
    [GET_SAMPLE_LIST]:(params, activeStatus) =>  async (dispatch, getState) => {
        dispatch(openLoader())
        try{
            const response = await getSampleList(params);
            dispatch({
                type: SampleActionsType[SET_SAMPLE_LIST_PAGE_ACTIVE_TAB],
                payload: activeStatus

            })
            dispatch({
                type: SampleActionsType[SET_SAMPLE_LIST],
                payload:{ ...response.data, merge: response.data.currentPage > 0 }

            })
            dispatch(closeLoader())
            return getState().sample;
        } catch({ response }) {
            toast.error(response.data.message)
            dispatch(closeLoader())
        }

    },
    [GET_SAMPLE_DETAIL]:(id) =>  async (dispatch, getState) => {
        dispatch(openLoader())
        try{
            const response = await getSampleDetailById(id);
            dispatch({
                type:SampleActionsType[SET_SAMPLE_DETAILS],
                payload: response.data
            })
            dispatch(closeLoader())
            return getState().sample;
        } catch({ response }) {
            toast.error(response.data.message)
            dispatch(closeLoader())
        }

    }
}
export default SampleThunks;
