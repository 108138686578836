import React, { useEffect, useState } from 'react'
import { ReactComponent as ScrollTopIcon } from '../../../assets/icons/scroll-top.svg'

const ScrollTop = () => {
    const [showScrollToTop, setShowScrollToTop] = useState(false)

    const handleScroll = () => {
        const scrolled = document.documentElement.scrollTop
        if (scrolled > 300) {
            setShowScrollToTop(true)
        } else {
            setShowScrollToTop(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <button className='button scrollTop-btn' onClick={scrollTop}>
            {showScrollToTop && <ScrollTopIcon />}
        </button>
    )
}

export default ScrollTop
