import React, { useEffect, useState } from 'react'
import { ReactComponent as UsersIcon } from '../../../assets/icons/users.svg'
import { ReactComponent as InHouse } from '../../../assets/icons/inhouse.svg'
import { ReactComponent as MoreIcon } from '../../../assets/icons/more.svg'
import {
    changeDateFormat,
    convertTimeToLocal,
    setBreadCrumbRoutes
} from '../../helpers/Util'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { modifyFolderName } from '../../services/v3/Favourite/FavouriteService'
import DeleteFolderModal from '../common/DeleteFolderModal'
import { useNavigate } from 'react-router-dom'

const FolderCardView = ({ folderInfo, removeItem }) => {
    const [item, setItem] = useState(folderInfo)
    const [isShowRemoveModal, setIsShowRemoveModal] = useState(false)
    const [isShowMore, setIsShowMore] = useState(false)
    const [selectedId, setSelectedId] = useState(null)
    const [folderName, setFolderName] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        setFolderName(item.name)
    }, [item])

    const changeFolderName = async () => {
        if (folderName) {
            try {
                dispatch(openLoader())
                await modifyFolderName(item.id, folderName)
                setItem((prev) => ({ ...prev, name: folderName }))
                setSelectedId(null)
                dispatch(closeLoader())
            } catch (e) {
                dispatch(closeLoader())
            }
        }
    }

    const removeFolder = () => {
        removeItem(item.id)
        setIsShowRemoveModal(false)
    }

    const onClickCard = () => {
        let newRoute = `/favourites/${item.id || 'my-favourite'}`
        let visitingRoutes = [
            {
                key: '/favourites',
                value: 'Favourites'
            },
            {
                key: newRoute,
                value: item.name
            }
        ]

        setBreadCrumbRoutes(visitingRoutes)
        navigate(newRoute)
    }

    return (
        <>
            <div
                className='collection-card'
                onMouseLeave={() => setIsShowMore(false)}
            >
                <div className='product-img-container' onClick={onClickCard}>
                    <div className='prev-img'>
                        {item?.documents?.[0] && (
                            <img src={item.documents[0]} alt='style' />
                        )}
                        {!item?.documents?.[0] && (
                            <img
                                src='/images/default_product.svg'
                                alt='style'
                            />
                        )}
                    </div>
                    <div className='prev-img-thumb'>
                        <div className='single-first'>
                            {item?.documents?.[1] && (
                                <img src={item.documents[1]} alt='style' />
                            )}
                            {!item?.documents?.[1] && (
                                <img
                                    src='/images/default_product.svg'
                                    alt='style'
                                />
                            )}
                        </div>
                        <div className='single-second'>
                            {item?.documents?.[2] && (
                                <img src={item.documents[2]} alt='style' />
                            )}
                            {!item?.documents?.[2] && (
                                <img
                                    src='/images/default_product.svg'
                                    alt='style'
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className='collection-status'>
                    {item?.isShared === true && (
                        <span className='status pink-1 mr-2'>
                            <UsersIcon />
                        </span>
                    )}
                    {item?.fromBuyerSelection && (
                        <span className='status blue-1 mr-2'>
                            <InHouse />
                        </span>
                    )}
                    {item?.fromRange && item?.id && (
                        <span className='status purple-1 mr-2'>Range</span>
                    )}
                </div>
                <div className='more-options absolute right-4 top-3'>
                    {item?.id && (
                        <div className='common-dropdown from-right'>
                            <button
                                className='button secondary icon-only bg-white'
                                onClick={() => setIsShowMore((prev) => !prev)}
                            >
                                <MoreIcon />
                            </button>
                            {isShowMore && (
                                <div className='dropdown-box dropdown-menu scroll-y-label'>
                                    <div className='list-items scroll-y-label'>
                                        <div
                                            className='single-item'
                                            onClick={() =>
                                                setSelectedId(item.id)
                                            }
                                        >
                                            <p className='whitespace-nowrap'>
                                                Edit folder name
                                            </p>
                                        </div>
                                        <div
                                            className='single-item'
                                            onClick={() =>
                                                setIsShowRemoveModal(true)
                                            }
                                        >
                                            <p>Delete folder</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className='collection-content d-flex justify-between items-center'>
                    <div className='w-full'>
                        {selectedId === item?.id && (
                            <input
                                type='text'
                                value={folderName}
                                onChange={(e) => setFolderName(e.target.value)}
                                onBlur={changeFolderName}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        changeFolderName(e)
                                    }
                                }}
                                autoFocus={true}
                            />
                        )}
                        <h3 className='collection-title'>
                            {selectedId !== item?.id && (
                                <span>{item?.name}&nbsp;</span>
                            )}
                            {item.isNew && (
                                <span className='status blue-3'>
                                    New {item.numberOfNewProduct}
                                </span>
                            )}
                        </h3>
                        <div className='details-info'>
                            <ul className='common-list-component'>
                                <li>
                                    {convertTimeToLocal(
                                        changeDateFormat(
                                            item.createdAt?.split('T')[0],
                                            '',
                                            'DD/MM/YYYY'
                                        ),
                                        item.createdAt?.split('T')[1],
                                        'h:mm A'
                                    )}
                                </li>
                                <li>
                                    {changeDateFormat(
                                        item?.createdAt,
                                        '',
                                        'DD MMM, YY'
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {isShowRemoveModal && (
                    <DeleteFolderModal
                        isShowModal={isShowRemoveModal}
                        closeModal={() => setIsShowRemoveModal(false)}
                        removeFolder={removeFolder}
                    />
                )}
            </div>
        </>
    )
}

export default FolderCardView
