import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../redux_toolkit/Loader'
import Http from '../helpers/Http'

const useFetch = (url, id = '') => {
    const [responseData, setResponseData] = useState([])
    const [isLoading, setIsloading] = useState(false)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsloading(true)
        dispatch(openLoader())
        Http.GET(url, id ? id : '')
            .then((response) => {
                setResponseData(response.data)
            })
            .catch((err) => {
                setError(err)
            })
            .finally(() => {
                dispatch(closeLoader())
                setIsloading(false)
            })
    }, [url])
    return { responseData, isLoading, error }
}

export default useFetch
