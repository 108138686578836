import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { NotificationReducers } from './NotificationReducers'
import {
    ACTIVE_TAB_OBJ,
    FILTER_ACTION
} from '../../modules/notifications/new/util/Utils'

const initialState = {
    notifications: [],
    notificationIconActive: true,
    sort: 'id,desc',
    dataLoadedOnce: false,
    page: 0,
    size: 10,
    hasNext: true,
    unseenCount: 0,
    unseenCountObj: {},
    notificationTabName: ACTIVE_TAB_OBJ.all.value,
    notificationFilterName: FILTER_ACTION.all
}

const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: NotificationReducers
})

export const NotificationActionTypes = notificationSlice.actions

export const useNotificationSelector = () =>
    useSelector((state) => state.notifications)

export default notificationSlice.reducer
