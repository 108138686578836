import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as UploadIcon } from '../../../assets/images/upload.svg'
import { ReactComponent as Dlt } from '../../../assets/images/dlt.svg'
import { ReactComponent as ArrowRightWhite } from '../../../assets/images/arror-right-white.svg'
import { UPLOAD_MOODBOARDS } from '../../redux_toolkit/@types/thunk.types'
import MoodboardThunks from '../../redux_toolkit/Moodboard/MoodboardThunks'
import { getFileType, getIconByFileType } from '../../helpers/Util'

const UploadMoodboardModal = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const popupRef = useRef()
    const [selectedFile, setSelectedFile] = useState([])

    const handleMoodboardClick = (id) => {
        navigate(`/moodboard/${id}?uploaded=true`)
    }

    const renderImageIcon = (file) => {
        const fileType = getFileType(file.name)
        if (fileType === 'IMAGE')
            return (
                <img
                    src={file.base64Str}
                    alt=''
                    className='w-8 h-8 object-contain'
                />
            )
        return (
            <img
                src={getIconByFileType(fileType)}
                alt=''
                className='w-8 h-8 object-contain'
            />
        )
    }

    const onFileChange = async (e) => {
        let files = [...e.target.files]
        let modifiedFiles = files.map((element) => {
            let reader = new FileReader()
            reader.readAsDataURL(element)
            reader.onload = () => {
                element.base64Str = reader.result
            }
            return element
        })

        setTimeout(() => {
            setSelectedFile(modifiedFiles)
        }, 250)
    }

    const removeFile = (e, index) => {
        const files = [...selectedFile]
        files.splice(index, 1)
        setSelectedFile([...files])
    }

    const uploadMoodboards = async () => {
        let thunkResponse = await dispatch(
            MoodboardThunks[UPLOAD_MOODBOARDS](selectedFile)
        )
        popupRef.current.click()
        handleMoodboardClick(thunkResponse.response.data.id)
    }

    return (
        <div
            className='modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto'
            id='UploadMoodboard'
            tabIndex='-1'
            aria-labelledby='exampleModalCenterTitle'
            aria-modal='true'
            role='dialog'
        >
            <div className='modal-dialog max-w-[680px] modal-dialog-centered relative w-auto pointer-events-none'>
                <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current'>
                    <div className='modal-header flex flex-shrink-0 items-center justify-between bg-primaryColor-shade-300 p-4 pl-8'>
                        <div>
                            <h5
                                className='text-2xl font-bold leading-normal text-primaryColor uppercase'
                                id='exampleModalScrollableLabel'
                            >
                                Upload Moodboard
                            </h5>
                            <p className='text-xl'>To get a Collection</p>
                        </div>

                        <button
                            type='button'
                            className='btn-close box-content w-4 h-4 p-1 !mr-0.5 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        ></button>
                    </div>
                    <div className='modal-body relative m-6 mb-0 pb-10  border-b border-primaryColor-shade-200'>
                        <div className='space-y-10'>
                            {/*  this is the block for uploading data */}
                            <div className='input-group'>
                                <div className='file'>
                                    <input
                                        multiple
                                        id='input-file-moodboard'
                                        type='file'
                                        onChange={(e) => onFileChange(e)}
                                    />
                                    <label
                                        htmlFor='input-file-moodboard'
                                        className='w-full justify-between'
                                    >
                                        <span className='mr-4'>
                                            Browse Moodboard
                                        </span>
                                        <UploadIcon />
                                    </label>
                                </div>
                            </div>
                            <div className='input-group'>
                                {
                                    selectedFile?.length > 0 &&
                                    <label className='label'>Uploaded file</label>
                                }
                                <div className='space-y-3'>
                                    {selectedFile?.length > 0 &&
                                        selectedFile.map((file, index) => (
                                            <div
                                                key={index}
                                                className='text-base font-normal p-4 px-5 bg-white-shade-100 flex items-center justify-between'
                                            >
                                                <div className='flex items-center'>
                                                    <span>
                                                        {renderImageIcon(file)}
                                                    </span>
                                                    <span className='text-base ml-4'>
                                                        {file.name}{' '}
                                                    </span>
                                                </div>
                                                <span className='cursor-pointer'>
                                                    <Dlt
                                                        onClick={(e) =>
                                                            removeFile(index)
                                                        }
                                                    />
                                                </span>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer p-6 pt-10 flex gap-4'>
                        <button
                            type='button'
                            data-bs-dismiss='modal'
                            className='btn bg-transparent px-5 w-[135px] font-normal border border-primaryColor text-primaryColor px-8'
                        >
                            Close
                        </button>
                        <button
                            ref={popupRef}
                            type='button'
                            className='btn hidden'
                            data-bs-toggle='modal'
                            data-bs-target='#UploadMoodboard'
                        >
                            Activated Soon
                        </button>
                        <button
                            type='button'
                            className='btn flex flex-1 justify-between items-center'
                            onClick={(e) => uploadMoodboards(e)}
                            disabled={selectedFile?.length === 0}
                        >
                            <span>Proceed</span>
                            <span className='ml-2'>
                                <ArrowRightWhite />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadMoodboardModal
