import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import { toast } from 'react-toastify'
import moment from 'moment'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg'
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg'
import ModalContainer from '../../../app/common/modal/ModalContainer'
import { NavLink } from 'react-router-dom'
import FilePreviewer from '../../common/FilePreviewer'
import { closeLoader, openLoader, useLoader } from '../../redux_toolkit/Loader'
import Http from '../../helpers/Http'
import { getShortName, getUserInfo, onUploadFile } from '../../helpers/Util'

const PlaceOrder = ({
    details,
    handleClose,
    selectedProducts,
    collectionId,
    onSuccess
}) => {
    const [showCongratulationModal, setShowCongratulationModal] =
        useState(false)
    const [isShowPlaceOrder, setIsShowPlaceOrder] = useState(true)
    const [poDocIdList, setPoDocIdList] = useState([])
    const [etd, setEtd] = useState(null)
    const dispatch = useDispatch()
    const isLoading = useLoader()

    const onUploadPo = async (poData) => {
        dispatch(openLoader())
        await Http.POST('uploadDocument', poData)
            .then(({ data }) => {
                dispatch(closeLoader())
                if (data) {
                    setPoDocIdList((prev) => [
                        ...prev,
                        { name: data.name, id: data.id, docUrl: data.docUrl }
                    ])
                }
            })
            .catch(({ response }) => {
                dispatch(closeLoader())
                if (response && response.data && response.data.message) {
                    toast.error(response.data.message)
                } else {
                    toast.error('Request was unsuccessful.')
                }
            })
    }

    const onPlaceOrder = async () => {
        dispatch(openLoader())
        let body = {
            title: details?.quote?.title,
            buyerId: getUserInfo().id,
            collectionId,
            dueDate: etd,
            startDate: moment().format('YYYY-MM-DD'),
            productWiseQuantityPriceList: selectedProducts?.map((item) => ({
                productId: item,
                deliveryDate: etd
            })),
            poDocIdList: poDocIdList.map((item) => item.id)
        }
        const generateParams = () => {
            let params = ''
            const quoteDetails = details?.quote

            if (quoteDetails) {
                const { fromFav, folderId } = quoteDetails
                if (fromFav) {
                    params = `?fromFav=${true}`
                    if (folderId && folderId !== 'my-favourite') {
                        params += `&folderId=${folderId}`
                    }
                }
            }

            return params
        }

        await Http.POST('placeOrderFromCosting', body, generateParams())
            .then(({ data }) => {
                dispatch(closeLoader())
                setShowCongratulationModal(true)
                if (onSuccess) {
                    onSuccess(data?.payload?.data)
                }
                if (data.success) {
                    toast.success(data.message)
                } else {
                    toast.error(data.message)
                }
            })
            .catch(({ response }) => {
                dispatch(closeLoader())
                if (response.data && response.data.message) {
                    toast.error(response.data.message)
                } else {
                    toast.error('Something went wrong! Please try again.')
                }
            })
    }

    const onRemovePo = async (itemId) => {
        const updatedList = poDocIdList.filter((item) => item.id !== itemId)
        setPoDocIdList(updatedList)
    }

    return (
        <>
            {!showCongratulationModal && (
                <ModalContainer
                    className='place-order-modal right-sidebar'
                    onCloseModal={handleClose}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Place Order
                                </h3>
                                <p className='regular-12 gray_300'>
                                    Place your order
                                </p>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <div className='form-group'>
                                <label className='regular-14 gray_300 mb-2'>
                                    Expected ETD
                                </label>

                                <input
                                    type='date'
                                    className='form-field bg-white-shade-100'
                                    value={etd || ''}
                                    onChange={(e) => {
                                        setEtd(e.target.value)
                                    }}
                                />
                            </div>
                            <div className='form-group pt-3'>
                                <div className='file'>
                                    <input
                                        id='input-file'
                                        type='file'
                                        onChange={(e) =>
                                            onUploadFile(
                                                e,
                                                'PURCHASE_ORDER',
                                                onUploadPo,
                                                'MULTIPLE'
                                            )
                                        }
                                        onClick={(e) => (e.target.value = null)}
                                        name='po-upload'
                                        accept='*'
                                        multiple
                                    />

                                    <label
                                        htmlFor='input-file'
                                        className='w-full !flex justify-between'
                                    >
                                        <span className='mr-4'>Upload P.O</span>
                                        <UploadIcon />
                                    </label>
                                </div>
                            </div>
                            {poDocIdList?.length > 0 && (
                                <table className='table mt-8'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>File</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {poDocIdList?.map((item) => (
                                            <tr key={item?.id}>
                                                <td
                                                    className='text-center w-[72px] max-w-[72px]'
                                                    onClick={() =>
                                                        onRemovePo(item.id)
                                                    }
                                                >
                                                    <span className='cursor-pointer'>
                                                        <DeleteIcon />
                                                    </span>
                                                </td>
                                                <td>
                                                    <div className='flex items-center'>
                                                        <span>
                                                            <FilePreviewer
                                                                docUrl={
                                                                    item.docUrl
                                                                }
                                                            />
                                                        </span>
                                                        <span className='text-base ml-2'>
                                                            <span className='regular-14 black'>
                                                                <Tooltip
                                                                    title={
                                                                        item.name
                                                                    }
                                                                    placement='top'
                                                                    arrow
                                                                >
                                                                    <a
                                                                        href={
                                                                            item.docUrl
                                                                        }
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                    >
                                                                        {getShortName(
                                                                            item.name,
                                                                            15
                                                                        )}
                                                                    </a>
                                                                </Tooltip>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            <button
                                className='button primary mt-5'
                                disabled={poDocIdList?.length < 1 || isLoading}
                                onClick={() => onPlaceOrder()}
                            >
                                Place order
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}
            {showCongratulationModal && (
                <ModalContainer className='create-folder-modal'>
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Congratulations
                                </h3>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <p className='regular-18 black'>
                                Your order has been placed. You can check order
                                status on{' '}
                                <NavLink
                                    to='/dashboard?active=ORDER&status=PENDING'
                                    className='blue'
                                >
                                    Order
                                </NavLink>{' '}
                                Section
                            </p>
                        </div>
                        <div className='common-popup-footer'>
                            <button
                                className='button secondary mb-2'
                                onClick={handleClose}
                            >
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    )
}

export default PlaceOrder
