import {
    ADD_FILTER_FIELDS,
    CLEAR_FILTER_FIELD,
    CLEAR_FILTER_FIELDS,
    REMOVE_FILTER_FIELD
} from '../../@types/action.types'

export const FilterReducers = {
    [ADD_FILTER_FIELDS]: (state, { payload }) => {
        for (let item in payload.data) {
            state[item] = payload.data[item]
        }
    },
    [CLEAR_FILTER_FIELDS]: (state) => ({}),
    [CLEAR_FILTER_FIELD]: (state, { payload }) => {
        delete state[payload]
    },
    [REMOVE_FILTER_FIELD]: (state, { payload }) => {
        if (Array.isArray(state[payload.name])) {
            state[payload.name] = state[payload.name]?.filter(
                (item) => item.id !== payload.data
            )
        } else if (state[payload.name] instanceof Object) {
            delete state[payload.name]
        }
    }
}
