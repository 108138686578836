import { REMOVE_FIELD_FROM_FILTER, REMOVE_ALL_FILTER_FIELDS } from '../../@types/thunk.types'
import { FilterActionTypes } from './index'
import { CLEAR_FILTER_FIELDS, REMOVE_FILTER_FIELD } from '../../@types/action.types';

export const FilterThunksTypes = {
    // [REMOVE_FIELD_FROM_FILTER]: (data) => async (dispatch, getState) => {
    //     dispatch(FilterActionTypes[REMOVE_FILTER_FIELD](data))
    //     return getState().filter
    // },
    // [REMOVE_ALL_FILTER_FIELDS]: (data) => async (dispatch, getState) => {
    //     dispatch(FilterActionTypes[CLEAR_FILTER_FIELDS](data))
    //     return getState().filter
    // }
}
