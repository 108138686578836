import Http from '../../helpers/Http'
import { toast } from 'react-toastify'

export const getChannels = async (type, id) => {
    try {
        const { data } = await Http.GET(
            'fetchSubscriptionChannel',
            `?topic=${type}&entityId=${id}`
        )
        return data
    } catch ({ response }) {
        toast.error(response.data.error)
    }
}

export const getConversations = async (type, id, params) => {
    try {
        const { data } = await Http.GET_WITH_ID_PARAM(
            getListUrl(type),
            params,
            id
        )
        return data
    } catch ({ response }) {
        toast.error(response.data.error)
    }
}

export const addPostReply = async (id, postData) => {
    try {
        const { data } = await Http.POST('addPostReply', postData)
        return data
    } catch ({ response }) {
        toast.error(response.data.error)
    }
}

export const addNewConversation = async (id, postData) => {
    try {
        const { data } = await Http.POST('addNewPost', postData)
        return data
    } catch ({ response }) {
        toast.error(response.data.error)
    }
}

const getListUrl = (type) => {
    switch (type) {
        case 'COLLECTION_CONVERSATION':
            return 'fetchCollectionConversationList'
        case 'COSTING_CONVERSATION':
            return 'fetchQuoteConversationList'
        case 'SAMPLE_CONVERSATION':
            return 'fetchSampleConversationList'
        case 'PRODUCT_CONVERSATION':
            return 'fetchProductConversationList'
        default:
            return 'fetchCollectionConversationList'
    }
}

export const getTypeWiseRequestBody = (type, id, pageId) => {
    const POST_TYPE = 'PRODUCT_DEVELOPMENT_COMMENT'
    switch (type) {
        case 'PRODUCT_QUOTE_COMMENT':
            return {
                postType: POST_TYPE,
                postTag: type,
                quoteRequestId: parseInt(pageId),
                productId: id
            }
        case 'PRODUCT_SAMPLE_COMMENT':
            return {
                postType: POST_TYPE,
                postTag: type,
                sampleRequestId: parseInt(pageId),
                productId: id
            }
        case 'PRODUCT_DEVELOPMENT_COMMENT':
            return {
                postType: POST_TYPE,
                postTag: type,
                productId: id
            }
        case 'COLLECTION_COMMENT':
            return {
                postType: 'COLLECTION_COMMENT',
                postTag: type,
                collectionId: id
            }
        default:
            return {
                postType: POST_TYPE,
                postTag: type,
                productId: id
            }
    }
}

export const getSearchedUser = async (query) => {
    try {
        const { data } = await Http.GET('userSearch', `?search=${query}`)
        return data
    } catch ({ response }) {
        toast.error(response.data.error)
    }
}
