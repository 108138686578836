import React, { useRef } from 'react'
import { ReactComponent as Refresh } from '../../../assets/images/refresh.svg'
import { ReactComponent as OkWhite } from '../../../assets/images/ok-white.svg'
import SortBy from './core/SortBy'
import Season from './core/Season'
import Market from './core/Market'
import Category from './core/Category'
import { useDispatch } from 'react-redux'
import { FilterActionTypes, useFilter } from '../../redux_toolkit/V3/Filter'
import { CLEAR_FILTER_FIELDS, IS_FILTER_SUBMIT_BUTTON_CLICKED } from '../../redux_toolkit/@types/action.types';
import { CollectionThunks } from '../../redux_toolkit/Collection/CollectionThunks'
import { GET_COLLECTION_LIST, REMOVE_ALL_FILTER_FIELDS } from '../../redux_toolkit/@types/thunk.types';
import { useCollections } from '../../redux_toolkit/Collection'
import { generateCollectionFilterParams } from '../../helpers/Util'
import { useSearchParams } from 'react-router-dom'
import { FilterThunksTypes } from '../../redux_toolkit/V3/Filter/FilterThunks';

const CollectionFilter = () => {
    const dispatch = useDispatch()
    const collectionStore = useCollections()
    const filter = useFilter()
    const modalRef = useRef()
    const [searchParams, setSearchParams] = useSearchParams()
    const isFavourite = searchParams.get('favourite')

    const handleFilterReset = async () => {
        let data = await dispatch(FilterThunksTypes[REMOVE_ALL_FILTER_FIELDS]())
        dispatch(
            CollectionThunks[GET_COLLECTION_LIST](
                generateParams(0, data),
                collectionStore.activeTab
            )
        )
    }

    const generateParams = (page = 0, filterParams = filter) => {
        let params = `?size=15&page=${page}`
        if (!isFavourite) {
            params += `&memberType=${collectionStore.activeTab}`
        } else {
            params += '&isFavourite=true'
        }
        return generateCollectionFilterParams(filterParams, params)
    }

    const handleSubmit = () => {
        dispatch({
            type: FilterActionTypes[IS_FILTER_SUBMIT_BUTTON_CLICKED],
            payload: true
        })
        modalRef.current.click()
        dispatch(
            CollectionThunks[GET_COLLECTION_LIST](
                generateParams(0),
                collectionStore.activeTab
            )
        )
    }

    return (
        <div
            className='modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto'
            id='SortFilter'
            tabIndex='-1'
            aria-labelledby='exampleModalCenterTitle'
            aria-modal='true'
            role='dialog'
        >
            <div
                className='modal-dialog max-w-[1840px] mx-4 5xl:mx-auto modal-dialog-centered relative w-auto pointer-events-none'>
                <div
                    className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current'>
                    <div
                        className='modal-header flex flex-shrink-0 items-center justify-between bg-primaryColor-shade-300 p-4 pl-8'>
                        <h5
                            className='text-xl font-bold leading-normal text-primaryColor uppercase'
                            id='exampleModalScrollableLabel'
                        >
                            Sort & Filter
                        </h5>
                        <button
                            type='button'
                            className='btn-close box-content w-4 h-4 p-1 !mr-0.5 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        />
                    </div>
                    <div className='modal-body relative'>
                        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'>
                            <SortBy />
                            <Season />
                            <Market />
                            <Category />
                        </div>
                    </div>
                    <div className='modal-footer p-4 flex justify-end gap-4'>
                        <button
                            ref={modalRef}
                            type='button'
                            className='btn-close hidden box-content w-4 h-4 p-1 !mr-0.5 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        >
                            Close
                        </button>
                        <button
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            type='button'
                            className='btn bg-transparent p-2 px-3 4xl:px-5 font-normal border border-primaryColor text-primaryColor'
                            onClick={handleFilterReset}
                        >
                            <Refresh />
                        </button>
                        <button
                            type='button'
                            className='btn flex justify-between items-center'
                            onClick={handleSubmit}
                        >
                            <span>Apply</span>
                            <span className='ml-2'>
                                <OkWhite />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollectionFilter
