import Http from '../../../helpers/Http'
import { toast } from 'react-toastify'
import axios from 'axios'
import { BASE_URL } from '../../../constant'

export const fetchSharedCollection = async (params) => {
    try {
        const { data } = await Http.GET('searchCollectionByUser', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const likeSharedCollection = async (collectionId) => {
    try {
        const { data } = await axios.post(`${BASE_URL}/collection/${collectionId}/like`)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const unlikeSharedCollection = async (collectionId) => {
    try {
        const { data } = await axios.post(`${BASE_URL}/collection/${collectionId}/unlike`)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const fetchCollectionInfo = async (collectionId) => {
    try {
        const { data } = await axios.get(`${BASE_URL}/collection/${collectionId}/with-moodboard-details`)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}
