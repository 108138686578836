import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import ModalContainer from '../../common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/arrow-down.svg'

import { BASE_URL } from '../../constant'
import { authUserInfo, getUserInfo, isValidEmail } from '../../helpers/Util'
import Http from '../../helpers/Http'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader, useLoader } from '../../redux_toolkit/Loader'
import { useParams } from 'react-router-dom'
import { addMemberToFavourite } from '../../services/v3/Favourite/FavouriteService'
import { addMemberToQuote } from '../../services/Quote'
import { addMemberToSample } from '../../services/Sample/SampleService'
import {
    addMemberToBrandTeam,
    removeMemberFromBrandTeam,
    fetchAllBrandTeams
} from '../../services/v3/TeamMembers/TeamMembersService'
import axios from 'axios'

const AddPeople = ({ members = [], requestFrom = 'COLLECTION', callback }) => {
    const [showInviteModal, setShowInviteModal] = useState(false)
    const [showTeamModal, setShowTeamModal] = useState(false)
    const [peoples, setPeoples] = useState([])
    const [brandTeam, setBrandTeam] = useState([])
    const [errors, setErrors] = useState({})
    const [search, setSearch] = useState('')
    const [email, setEmail] = useState('')
    const { id, orderId } = useParams()
    const dispatch = useDispatch()
    const isLoading = useLoader()

    const fetchTeamMembers = async (userInput = '') => {
        try {
            const searchTerm = encodeURIComponent(userInput)

            let url = `${BASE_URL}/user/find-list-by-email-and-user-type?page=0&size=100`
            if (searchTerm) {
                url += `&email=${searchTerm}`
            }
            if (authUserInfo()?.brandResponse?.id) {
                url += `&brandId=${authUserInfo().brandResponse.id}`
            }
            const { data } = await Http.GET(url, '', true)
            return data
        } catch (e) {
            toast.error('Unable to fetch peoples!')
        }
    }

    const setBrandWisePeople = async (searchParam) => {
        dispatch(openLoader())
        const data = await fetchTeamMembers(searchParam)
        let memberList = data.filter(
            (item) =>
                members.find((member) => member.id === item.id) !== undefined
        )
        if (
            [
                'QUOTE',
                'SAMPLE',
                'ORDER',
                'FAVOURITE_DETAILS',
                'COLLECTION'
            ].includes(requestFrom)
        ) {
            let tmpMembers = members.filter(
                (item) =>
                    memberList.find((member) => member.id !== item.id) !==
                    undefined
            )
            memberList = [
                ...new Map(
                    [...tmpMembers, ...memberList].map((item) => [
                        item['id'],
                        item
                    ])
                ).values()
            ]
        }
        setPeoples(memberList)
        dispatch(closeLoader())
    }

    const generateParams = {
        COLLECTION: `&collectionId=${id}`,
        FAVOURITE_DETAILS: `${
            id && id !== 'my-favourite' ? `&folderId=${id}` : ''
        }`
    }

    const onSetBrandsTeam = async () => {
        setBrandTeam(await fetchAllBrandTeams(generateParams[requestFrom]))
    }

    useEffect(() => {
        onSetBrandsTeam()
    }, [])

    useEffect(() => {
        if (members.length > 0 && search === '') {
            setBrandWisePeople()
        }
    }, [members.length, search])

    const openInviteModal = () => {
        setEmail(search)
        setShowInviteModal(true)
    }

    const handleClose = () => {
        setSearch('')
        setEmail('')
        setShowTeamModal(false)
        setShowInviteModal(false)
    }

    const isExistingMember = (id) => {
        return members.find((item) => item.id === id) !== undefined
    }

    const addMemberToCollection = async (memberIds) => {
        try {
            let postData = {
                collectionId: id,
                userIds: memberIds
            }
            await Http.POST('addMember', postData)
        } catch (error) {
            const errorMessage =
                error?.response?.data?.message ||
                'Something went wrong! Please try again.'
            toast.error(errorMessage)
        }
    }

    const addMemberToFavouriteDetails = async (memberId) => {
        await addMemberToFavourite(id, memberId)
    }

    const addMemberToQuoteDetails = async (memberId) => {
        await addMemberToQuote(id, memberId)
    }

    const addMemberToSampleDetails = async (memberId) => {
        await addMemberToSample(id, memberId)
    }

    const addMemberToBrandTeamMembers = async (memberId) => {
        await addMemberToBrandTeam(memberId)
    }

    const addMemberToOrder = async (memberIds) => {
        try {
            const response = await Http.POST(
                'addMemberToOrder',
                memberIds,
                orderId
            )
            toast.success(response.message)
        } catch (error) {
            const errorMessage =
                error?.response?.data?.message ||
                'Something went wrong! Please try again.'
            toast.error(errorMessage)
        }
    }

    const handleAdd = async (memberId) => {
        try {
            dispatch(openLoader())
            switch (requestFrom) {
                case 'COLLECTION':
                    await addMemberToCollection(memberId)
                    break
                case 'FAVOURITE_DETAILS':
                    await addMemberToFavouriteDetails(memberId)
                    break
                case 'QUOTE':
                    await addMemberToQuoteDetails(memberId)
                    break
                case 'ORDER':
                    await addMemberToOrder(memberId)
                    break
                case 'SAMPLE':
                    await addMemberToSampleDetails(memberId)
                    break
                case 'TEAM_MEMBERS':
                    await addMemberToBrandTeamMembers(memberId)
                    break
                default:
                    break
            }
            if (callback) await callback()
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const handleMemberRemove = async (memberIds) => {
        try {
            dispatch(openLoader())
            switch (requestFrom) {
                case 'COLLECTION':
                    await Http.POST('removeMember', {
                        collectionId: id,
                        userIds: memberIds
                    })
                    break
                case 'FAVOURITE_DETAILS':
                    await axios.post(
                        `${BASE_URL}/user/favourites/folder/${id}/remove-member?memberIds=${memberIds}`
                    )
                    break
                case 'QUOTE':
                    await Http.DELETE(
                        'getQouteDetail',
                        {},
                        `/${id}/members?userIds=${memberIds}`
                    )
                    break
                case 'ORDER':
                    await Http.POST(
                        'removeMemberFromOrder',
                        memberIds,
                        `${orderId}`
                    )
                    break
                case 'SAMPLE':
                    await Http.DELETE(
                        'sampleMemberAction',
                        {},
                        `${id}/members?memberIds=${memberIds}`
                    )
                    break
                case 'TEAM_MEMBERS':
                    await removeMemberFromBrandTeam(memberIds)
                    break
                default:
                    break
            }
            if (callback) await callback()
            dispatch(closeLoader())
        } catch (error) {
            dispatch(closeLoader())
            if (error?.response?.data) {
                toast.error(error?.response?.data?.message)
            } else toast.error('Unable to add!')
        }
    }

    const isShowRemoveButton = (item) => {
        let isShow = isExistingMember(item.id) && item.id !== getUserInfo().id
        if (['SAMPLE', 'QUOTE', 'ORDER'].includes(requestFrom)) {
            isShow = isShow && ['BUYER'].includes(item.primaryUserType)
        }
        return isShow
    }

    const renderTeamMembers = () => {
        return peoples?.map((item) => {
            let memberImage = item?.profilePicDocument?.docUrl
            return (
                <div
                    className='single-member flex items-center justify-between mb-3'
                    key={`people_${item.id}`}
                >
                    <div className='flex items-center'>
                        <div className='profile mr-3'>
                            {!memberImage ? (
                                getFirstLetterOfName(item?.name)
                            ) : (
                                <img
                                    src={
                                        item.profilePicDocument?.docUrl ||
                                        '/images/user.svg'
                                    }
                                    alt='member'
                                    className='w-[32px] h-[32px] object-cover rounded-full'
                                />
                            )}
                        </div>
                        <div className='designation-info'>
                            <h3 className='regular-14'>
                                {item.name}&nbsp;
                                {item.designation && (
                                    <span className='filter-chip'>
                                        {item.designation}
                                    </span>
                                )}
                            </h3>
                            <p className='regular-12 gray_300'>{item.email}</p>
                        </div>
                    </div>
                    {search !== '' && !isExistingMember(item.id) && (
                        <div className='add'>
                            <button
                                disabled={isLoading}
                                className='button secondary size32'
                                onClick={() =>
                                    handleAdd(
                                        requestFrom === 'COLLECTION'
                                            ? [item?.id]
                                            : item?.id
                                    )
                                }
                            >
                                Add
                            </button>
                        </div>
                    )}
                    {isShowRemoveButton(item) && (
                        <div className='add'>
                            <button
                                disabled={isLoading}
                                className='button secondary size32'
                                onClick={() =>
                                    handleMemberRemove(
                                        ['COLLECTION', 'ORDER'].includes(
                                            requestFrom
                                        )
                                            ? [item?.id]
                                            : item?.id
                                    )
                                }
                            >
                                Remove
                            </button>
                        </div>
                    )}
                </div>
            )
        })
    }

    const handleSearch = async (e) => {
        try {
            await setSearch(e.target.value)
            dispatch(openLoader())
            if (e.target.value) {
                const data = await fetchTeamMembers(e.target.value)
                setPeoples(data)
            } else {
                await setBrandWisePeople(e.target.value)
            }
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const inviteMember = async (type) => {
        let postData = {
            [`${type}`]: orderId,
            invitationType: 'REGISTRATION',
            email: email
        }
        await Http.POST('inviteUser', postData)
    }

    const sendInvite = async () => {
        if (!isValidEmail(email)) {
            setErrors((prev) => ({
                ...prev,
                emailError: 'Invalid email'
            }))
        } else {
            setErrors({})
            try {
                dispatch(openLoader())
                switch (requestFrom) {
                    case 'COLLECTION':
                        await Http.POST('inviteUser', {
                            collectionId: id,
                            invitationType: 'REGISTRATION',
                            email: email
                        })
                        break
                    case 'FAVOURITE_DETAILS':
                        await Http.POST('inviteUser', {
                            invitationType: 'REGISTRATION',
                            favouriteFolderId:
                                id && id !== 'my-favourite' ? id : null,
                            email: email
                        })
                        break
                    case 'QUOTE':
                        await inviteMember('quoteRequestId')
                        break
                    case 'SAMPLE':
                        await inviteMember('sampleRequestId')
                        break
                    case 'ORDER':
                        await inviteMember('orderId')
                        break
                    case 'TEAM_MEMBERS':
                        await Http.POST('inviteUser', {
                            brandTeamId: getUserInfo()?.teamId,
                            invitationType: 'REGISTRATION',
                            email: email
                        })
                        break
                    default:
                        break
                }
                toast.success('Invitation sent successful!')
                dispatch(closeLoader())
                handleClose()
            } catch (e) {
                dispatch(closeLoader())
                toast.error(e.response.data.message)
            }
        }
    }

    const getFirstLetterOfName = (name) => {
        return (
            <div className='single-pepole profile-interaction w-[32px] h-[32px] rounded-full bg-primaryColor-shade-300 relative border border-white-shade-100 flex items-center justify-center cursor-pointer'>
                <span className='name-icon'>
                    {name?.toUpperCase()?.charAt(0)}
                </span>
            </div>
        )
    }

    const renderExistingMembers = (n = 3) => {
        return members?.slice(0, n).map((item) => {
            let memberImage = item?.profilePicDocument?.docUrl

            return !memberImage ? (
                getFirstLetterOfName(item?.name)
            ) : (
                <div className='single-pepole'>
                    <img
                        key={`existing_member_${item?.id}`}
                        src={memberImage}
                        alt='member'
                        className='w-full h-full object-cover rounded-full'
                    />
                </div>
            )
        })
    }

    const isShowAddRemove = (member) => {
        const userInfo = getUserInfo()
        return member.id !== userInfo.id
    }

    const searchItemsByName = () => {
        return brandTeam.filter(
            (item) => item.name && item.name.toLowerCase().includes(search)
        )
    }

    const searchResults = searchItemsByName()

    const isAdded = (memberId) => {
        const isMemberIdInArray = () =>
            peoples.some((item) => item.id === memberId)
        if (isMemberIdInArray()) {
            return true
        } else {
            return false
        }
    }

    const isAllAdded = (memberList) =>
        memberList?.every((item) => isAdded(item?.id))
    const getMemberIdsForAdd = (list = []) =>
        list?.filter((item) => !isAdded(item.id)).map((item) => item.id)
    const getMemberIdsForRemove = (list = []) =>
        list?.filter((item) => isAdded(item.id)).map((item) => item.id)

    const renderActionButton = (item, member) => {
        if (!isShowAddRemove(member)) {
            return null
        }
        const memberId = member?.id
        const handleButtonClick = () => {
            if (isAdded(memberId)) {
                handleMemberRemove(
                    ['COLLECTION', 'ORDER'].includes(requestFrom)
                        ? [memberId]
                        : memberId
                )
            } else {
                handleAdd(requestFrom === 'COLLECTION' ? [memberId] : memberId)
            }
        }

        const isNitexTeam = item?.name === 'Nitex Team'
        const shouldRenderButton = requestFrom === 'FAVOURITE_DETAILS'

        return shouldRenderButton ? (
            <div className='add'>
                <button
                    className='button secondary size32'
                    onClick={handleButtonClick}
                >
                    {isAdded(member?.id) ? 'Remove' : 'Add'}
                </button>
            </div>
        ) : (
            <div className='add'>
                {!isNitexTeam ? (
                    <button
                        className='button secondary size32'
                        onClick={handleButtonClick}
                    >
                        {isAdded(member?.id) ? 'Remove' : 'Add'}
                    </button>
                ) : !isAdded(member?.id) ? (
                    <button
                        className='button secondary size32'
                        onClick={handleButtonClick}
                    >
                        {isAdded(member?.id) ? null : 'Add'}
                    </button>
                ) : null}
            </div>
        )
    }

    const handleAllButtonClick = (item) => {
        const members = item?.members
        if (isAllAdded(members)) {
            handleMemberRemove(getMemberIdsForRemove(members))
        } else {
            handleAdd(getMemberIdsForAdd(members))
        }
    }

    const renderAllActionButton = (item) => {
        const shouldRenderButton =
            requestFrom === 'FAVOURITE_DETAILS' ||
            (item?.name !== 'Nitex Team' && item?.members)
        return shouldRenderButton ? (
            <div className='add flex'>
                <button
                    className='button secondary size32'
                    onClick={() => handleAllButtonClick(item)}
                >
                    {isAllAdded(item?.members) ? 'Remove' : 'Add'}
                </button>
            </div>
        ) : !isAllAdded(item?.members) ? (
            <div className='add flex'>
                <button
                    className='button secondary size32'
                    onClick={() => handleAllButtonClick(item)}
                >
                    {isAllAdded(item?.members) ? null : 'Add'}
                </button>
            </div>
        ) : null
    }

    const renderMemberDetails = () => {
        return (
            <div>
                <Accordion>
                    {searchResults
                        ?.filter((data) => data?.memberCount > 0)
                        ?.map((item, idx) => {
                            return (
                                <Accordion.Item key={item.id} eventKey={idx}>
                                    <Accordion.Header>
                                        <div className='flex justify-between items-center w-100'>
                                            <div className='flex items-center'>
                                                <button className='button secondary icon-only arrow'>
                                                    <DownArrowIcon />
                                                </button>
                                                <h3 className='regular-16 weight-500'>
                                                    {item?.name}
                                                    <span className='filter-chip'>
                                                        {item?.memberCount}
                                                    </span>
                                                </h3>
                                            </div>
                                            {renderAllActionButton(item)}
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='members-list'>
                                            {item?.members?.map((member) => {
                                                let memberImage =
                                                    member?.profilePicDocument
                                                        ?.docUrl
                                                return (
                                                    <div
                                                        className='single-member flex items-center justify-between mb-3'
                                                        key={member?.id}
                                                    >
                                                        <div className='flex items-center'>
                                                            <div className='profile mr-3'>
                                                                {!memberImage ? (
                                                                    getFirstLetterOfName(
                                                                        member?.name
                                                                    )
                                                                ) : (
                                                                    <img
                                                                        src={
                                                                            member
                                                                                ?.profilePicDocument
                                                                                ?.docUrl
                                                                        }
                                                                        alt='member'
                                                                        className='w-[32px] h-[32px] object-cover rounded-full'
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className='designation-info'>
                                                                <h3 className='regular-14'>
                                                                    {
                                                                        member?.name
                                                                    }{' '}
                                                                    <span className='filter-chip'>
                                                                        {
                                                                            member?.designation
                                                                        }
                                                                    </span>
                                                                </h3>
                                                                <p className='regular-12 gray_300'>
                                                                    {
                                                                        member?.email
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {renderActionButton(
                                                            item,
                                                            member
                                                        )}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                </Accordion>
                {search ? renderTeamMembersInSingleList() : null}
            </div>
        )
    }

    const renderTeamMembersInSingleList = () => (
        <div>
            {peoples?.length > 0 && (
                <p className='regular-14 weight-500 py-4'>
                    {peoples?.length}{' '}
                    {peoples?.length > 1 ? 'Members ' : 'Member '}
                    {search === '' ? 'added' : 'found'}
                </p>
            )}
            {peoples?.length !== 0 && (
                <div className='members-list scroll-y-label h-[255px] pr-1'>
                    {renderTeamMembers()}
                </div>
            )}
        </div>
    )

    return (
        <>
            {showTeamModal && (
                <ModalContainer
                    className='team-member-modal'
                    onCloseModal={() => setShowTeamModal(false)}
                >
                    {!showInviteModal && (
                        <div className='common-popup'>
                            <div className='common-popup-header d-flex justify-content-between'>
                                <div className='popup-tilte'>
                                    <h3 className='semibold-16 mb-0'>
                                        Add people
                                    </h3>
                                </div>
                                <div
                                    className='close-btn cursor-pointer'
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </div>
                            </div>

                            <div className='common-popup-body scroll-y-label p-4 !pt-0'>
                                <div className='search-box bg-white'>
                                    <div className='relative'>
                                        <div className='absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none'>
                                            <SearchIcon />
                                        </div>
                                        <input
                                            type='search'
                                            className='block w-full p-3 pr-10 text-sm'
                                            placeholder='Search'
                                            value={search}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                                <div className='members-details'>
                                    {requestFrom === 'TEAM_MEMBERS'
                                        ? renderTeamMembersInSingleList()
                                        : renderMemberDetails()}
                                </div>

                                {searchResults?.length === 0 &&
                                    peoples?.length === 0 && (
                                        <div className='empty-state text-center py-14'>
                                            <p className='regular-14 weight-500 gray_300'>
                                                No member found
                                            </p>
                                            <p className='regular-14 weight-500 gray_300'>
                                                Invite{' '}
                                                <span className='black'>
                                                    {search}
                                                </span>
                                            </p>
                                            <button
                                                className='button primary mt-5'
                                                onClick={openInviteModal}
                                            >
                                                Invite
                                            </button>
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}
                    {showInviteModal && (
                        <div className='common-popup'>
                            <div className='common-popup-header d-flex justify-content-between'>
                                <div className='popup-tilte'>
                                    <h3 className='semibold-16 mb-0'>
                                        Invite team member
                                    </h3>
                                </div>
                                <div
                                    className='close-btn cursor-pointer'
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </div>
                            </div>

                            <div className='common-popup-body p-4'>
                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Enter email
                                    </label>
                                    <input
                                        type='email'
                                        name='email'
                                        placeholder=''
                                        value={email}
                                        className={
                                            errors?.emailError ? 'required' : ''
                                        }
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='common-popup-footer !pt-0 !pb-6'>
                                <button
                                    className='button primary mr-3'
                                    onClick={sendInvite}
                                    disabled={isLoading}
                                >
                                    <span>Invite</span>
                                </button>
                            </div>
                        </div>
                    )}
                </ModalContainer>
            )}

            <div
                className='team-members cursor-pointer'
                onClick={() => setShowTeamModal(true)}
            >
                <div className='flex items-center'>
                    {requestFrom !== 'TEAM_MEMBERS' && (
                        <div className='member-photos flex'>
                            {members?.length > 3 && (
                                <span className='single-pepole w-[32px] h-[32px] text-[14px] font-semibold rounded-full bg-primaryColor-shade-300 relative border border-white-shade-100 flex items-center justify-center cursor-pointer'>
                                    +{members?.length - 3}
                                </span>
                            )}
                            {renderExistingMembers()}
                        </div>
                    )}
                    <button
                        className='button secondary ml-2'
                        onClick={() => setShowTeamModal(true)}
                    >
                        Add people
                    </button>
                </div>
            </div>
        </>
    )
}

export default AddPeople
