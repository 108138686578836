import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AskQuoteSample from './AskQuoteSample'

const EmptyDashboard = ({ requesType }) => {
    const [ishowModal, setIsShowMoadal] = useState(false)
    const navigate = useNavigate()
    return (
        <>
            <div className='tab-contents'>
                <div className='quote-sample-epmpty-state py-40 text-center max-w-[600px] m-auto'>
                    <h3 className='regular-20 weight-700 gray_400 mb-2'>
                        No {requesType} request found
                    </h3>
                    <div className='mt-6'>
                        <button
                            className='button primary sm'
                            onClick={() => setIsShowMoadal(true)}
                        >
                            Ask for quote/sample
                        </button>
                        <button
                            className='button secondary sm ml-3'
                            onClick={() => navigate('/design-lab')}
                        >
                            Visit Design Lab
                        </button>
                    </div>
                </div>
            </div>
            {ishowModal && (
                <AskQuoteSample onCloseModal={() => setIsShowMoadal(false)} />
            )}
        </>
    )
}

export default EmptyDashboard
