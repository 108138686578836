import React from 'react'
import Select from 'react-select'
import { customStylesSelect } from '../helpers/Util'

const SelectComponent = ({
    options,
    onChange,
    selectedItem,
    placeholder,
    onBlur,
    ref,
    isSearchable = true
}) => {
    return (
        <Select
            ref={ref}
            options={options}
            onChange={onChange}
            value={selectedItem}
            placeholder={placeholder}
            onBlur={onBlur}
            isSearchable={isSearchable}
            styles={customStylesSelect}
            // menuIsOpen={true}
        />
    )
}

export default SelectComponent
