import React from 'react'
import { ReactComponent as CloseIcon } from '../../../../assets/images/close.svg'
import { useFilter } from '../../../redux_toolkit/V3/Filter'
import { useDispatch } from 'react-redux'
import {
    generateCollectionFilterParams,
    generateFavouriteStylesFilterParams
} from '../../../helpers/Util'
import { CollectionThunks } from '../../../redux_toolkit/Collection/CollectionThunks'
import {
    GET_COLLECTION_LIST,
    GET_FAVOURITE_STYLES,
    GET_MOODBOARD_LIST,
    REMOVE_FIELD_FROM_FILTER
} from '../../../redux_toolkit/@types/thunk.types'
import { useCollections } from '../../../redux_toolkit/Collection'
import { FilterThunksTypes } from '../../../redux_toolkit/V3/Filter/FilterThunks'
import { FavouriteThunks } from '../../../redux_toolkit/Favourite/FavouriteThunks'
import { useSearchParams } from 'react-router-dom'
import MoodboardThunks from '../../../redux_toolkit/Moodboard/MoodboardThunks'

const FilterChip = ({ chipType = 'collection', removeFilter }) => {
    const filter = useFilter()
    const dispatch = useDispatch()
    const collectionStore = useCollections()
    const [searchParams, setSearchParams] = useSearchParams()
    const isFavourite = searchParams.get('favourite')

    const removeChip = async (type, value) => {
        await dispatch(
            FilterThunksTypes[REMOVE_FIELD_FROM_FILTER]({ type, value })
        ).then(async (response) => {
            if (chipType === 'collection') {
                let params = '?size=15&page=0'
                if (!isFavourite) {
                    params += `&memberType=${collectionStore.activeTab}`
                } else {
                    params += '&isFavourite=true'
                }
                await dispatch(
                    CollectionThunks[GET_COLLECTION_LIST](
                        generateCollectionFilterParams(response, params),
                        collectionStore.activeTab
                    )
                )
            } else if (chipType === 'favourite') {
                let params = '?size=15&page=0'
                dispatch(
                    FavouriteThunks[GET_FAVOURITE_STYLES](
                        generateFavouriteStylesFilterParams(response, params)
                    )
                )
            } else if (chipType === 'moodboard') {
                let params = generateCollectionFilterParams(
                    response,
                    '?size=20&page=0'
                )
                dispatch(MoodboardThunks[GET_MOODBOARD_LIST](params))
            }
        })
    }

    const renderChip = (key) => {
        return filter?.[key]?.map((item) => {
            if (item.show) {
                return (
                    <div className='tag-badge' key={`${key}_chip_${item.id}`}>
                        <span>{item.name}</span>
                        <span
                            className='ml-6 cursor-pointer'
                            onClick={() => removeChip(key, item.id)}
                        >
                            <CloseIcon />
                        </span>
                    </div>
                )
            }
        })
    }

    const renderSeasonChip = () => {
        return filter?.seasons?.map((item) => {
            if (item.show) {
                return (
                    <div
                        className='tag-badge'
                        key={`season_chip_${item.constant}`}
                    >
                        <span>{item.name}</span>
                        <span
                            className='ml-6 cursor-pointer'
                            onClick={() => removeChip('seasons', item.constant)}
                        >
                            <CloseIcon />
                        </span>
                    </div>
                )
            }
        })
    }

    return (
        <div className='flex items-center gap-2 mr-4 2xl:mx-4 overflow-x-auto custom-scroll xl:w-[55%] 2xl:w-[48%] 3xl:w-[52%] 4xl:w-[62%]'>
            {renderChip('categories')}
            {renderChip('markets')}
            {renderSeasonChip()}
        </div>
    )
}

export default FilterChip
