import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import ModalContainer from '../../common/modal/ModalContainer'
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg'
import { useDispatch } from 'react-redux'
import { useFilterOption } from '../../redux_toolkit/V3/Filter/FilterOptionReducers'
import { FilterActionTypes, useFilter } from '../../redux_toolkit/V3/Filter'
import {
    ADD_FILTER_FIELDS,
    CLEAR_FILTER_FIELD,
    CLEAR_FILTER_FIELDS,
    REMOVE_FILTER_FIELD
} from '../../redux_toolkit/@types/action.types'
import { filterTypes } from './filterUtil'
import { capitalizeFirstAndLowercaseRest } from '../../helpers/Util'

const FilterComponent = ({ children, requiredFields, filteredDataLength }) => {
    const [activeFilterType, setActiveFilterType] = useState('')
    const [secondOptionList, setSecondOptionList] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [filterOptions, setFilterOptions] = useState({})
    const [showFilter, setShowFilter] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const options = useFilterOption()
    const filterStore = useFilter()
    const dispatch = useDispatch()
    const onClickAction = (item, fieldName) => {
        if (!fieldName) return
        let selections = filterOptions[fieldName]
            ? filterOptions[fieldName]
            : []
        let isAlreadyExist = selections.find((obj) => obj.id === item.id)
        if (isAlreadyExist) {
            selections = selections.filter((obj) => obj.id !== item.id)
        } else {
            selections.push({ id: item.id, value: item.name })
        }
        setFilterOptions((prev) => ({
            ...prev,
            [fieldName]: selections
        }))
    }

    useEffect(() => {
        if (activeFilterType === filterTypes.CATEGORY) {
            setSelectedItem(options.categories?.[0])
            setSecondOptionList(
                options.categories?.[0]?.subCategoryResponseList
            )
        } else if (activeFilterType === filterTypes.SEASON) {
            setSecondOptionList(options.seasons)
        } else if (activeFilterType === filterTypes.AGE_GROUP) {
            setSecondOptionList(options.ageGroups)
        } else if (activeFilterType === filterTypes.DESIGN_LOOK) {
            setSecondOptionList(options.designLooks)
        } else if (activeFilterType === filterTypes.FABRIC) {
            setSecondOptionList(options.fabrics)
        } else if (activeFilterType === filterTypes.DEPARTMENT) {
            setSecondOptionList(options.departments)
        } else {
            setSecondOptionList([])
        }
    }, [activeFilterType])

    const renderFirstOptions = () => {
        if (![filterTypes.CATEGORY].includes(activeFilterType)) return
        let fields = options.categories

        return fields?.map((item, i) => {
            return (
                <p
                    className={`item regular-14 d-flex justify-between items-center cursor-pointer ${
                        item.id === selectedItem?.id ? 'active' : ''
                    }`}
                    key={`filter_option_${i}`}
                    onClick={() => {
                        setSearchTerm('')
                        setSelectedItem(item)
                        setSecondOptionList(item?.subCategoryResponseList || [])
                    }}
                >
                    <span className='mr-2'>
                        {capitalizeFirstAndLowercaseRest(item.name)}
                    </span>
                    {activeFilterType === filterTypes.CATEGORY &&
                        item.id === selectedItem?.id && <ArrowRightIcon />}
                </p>
            )
        })
    }

    const getFieldName = () => {
        let fieldName
        if (activeFilterType === filterTypes.CATEGORY) {
            fieldName = 'subCategories'
        } else if (activeFilterType === filterTypes.SEASON) {
            fieldName = 'seasons'
        } else if (activeFilterType === filterTypes.AGE_GROUP) {
            fieldName = 'ageGroups'
        } else if (activeFilterType === filterTypes.DESIGN_LOOK) {
            fieldName = 'designLooks'
        } else if (activeFilterType === filterTypes.FABRIC) {
            fieldName = 'fabrics'
        } else if (activeFilterType === filterTypes.DEPARTMENT) {
            fieldName = 'departments'
        }
        return fieldName
    }

    const isCheckboxChecked = (fieldName, item) => {
        if (filterOptions[fieldName]) {
            return (
                filterOptions[fieldName]?.find((obj) => obj.id === item.id) !==
                undefined
            )
        }
        return false
    }

    const isAllCheckboxSelected = () => {
        let fieldName = getFieldName()
        if (fieldName === 'subCategories') {
            let optionIds = filteredList?.map((item) => item.id)
            let selectedSubCategories = filterOptions['subCategories']?.filter(
                (item) => optionIds.includes(item.id)
            )
            return selectedSubCategories?.length === optionIds.length
        } else {
            return filterOptions[fieldName]?.length === secondOptionList.length
        }
    }

    const filteredList = secondOptionList?.filter(
        (item) => item.name && item.name.toLowerCase().includes(searchTerm)
    )

    const renderSecondSteps = () => {
        let fieldName = getFieldName()
        return filteredList?.map((item, i) => {
            if (!item.isPrimary) {
                return (
                    <div
                        className={`item d-flex justify-between items-center ${
                            isCheckboxChecked(fieldName, item) ? 'selected' : ''
                        }`}
                        key={`second_step_${item.id}`}
                    >
                        <div className='d-flex items-center'>
                            <input
                                type='checkbox'
                                name='categories'
                                id={`category_item_${i}`}
                                value={item.id}
                                checked={isCheckboxChecked(fieldName, item)}
                                onChange={() => onClickAction(item, fieldName)}
                            />
                            <label
                                htmlFor={`category_item_${i}`}
                                className='regular-14 ml-2'
                            >
                                {capitalizeFirstAndLowercaseRest(item.name)}
                            </label>
                        </div>
                    </div>
                )
            }
        })
    }

    const onClickAllCheckbox = (e) => {
        let { checked } = e.target
        let fieldName = getFieldName()
        if (['subCategories'].includes(fieldName)) {
            if (!checked) {
                let optionIds = secondOptionList?.map((item) => item.id)
                setFilterOptions((prev) => ({
                    ...prev,
                    [fieldName]: prev[fieldName].filter(
                        (item) => !optionIds.includes(item.id)
                    )
                }))
            } else {
                let existingIds = filterOptions[fieldName]?.map(
                    (item) => item.id
                )
                existingIds = existingIds ? existingIds : []

                let tmpOptions = filteredList?.filter(
                    (item) => !existingIds.includes(item.id)
                )
                tmpOptions = tmpOptions?.map((item) => ({
                    id: item.id,
                    value: item.name
                }))

                tmpOptions =
                    filterOptions[fieldName]?.length > 0
                        ? [...filterOptions[fieldName], ...tmpOptions]
                        : tmpOptions

                setFilterOptions((prev) => ({
                    ...prev,
                    [fieldName]: tmpOptions
                }))
            }
        } else {
            if (!checked) {
                setFilterOptions((prev) => ({
                    ...prev,
                    [fieldName]: []
                }))
            } else {
                let tmpSelection = secondOptionList?.map((item) => ({
                    id: item.id,
                    value: item.name
                }))
                setFilterOptions((prev) => ({
                    ...prev,
                    [fieldName]: tmpSelection
                }))
            }
        }
    }

    const clearState = () => {
        setSelectedItem(null)
        setActiveFilterType('')
        setSecondOptionList([])
        setFilterOptions({})
        setShowFilter(false)
    }

    const onClickApply = () => {
        dispatch(FilterActionTypes[ADD_FILTER_FIELDS]({ data: filterOptions }))
        clearState()
    }

    const clearFilter = () => {
        let fieldName = getFieldName()
        setFilterOptions((prev) => ({ ...prev, [fieldName]: undefined }))
        dispatch(FilterActionTypes[CLEAR_FILTER_FIELD](fieldName))
    }

    const renderFilterOptions = () => (
        <div className='common-popup'>
            <div className='common-popup-header d-flex justify-content-between'>
                <div className='popup-tilte'>
                    <h3 className='semibold-16 mb-0'>{activeFilterType}</h3>
                </div>
                <div className='close-btn cursor-pointer' onClick={clearState}>
                    <img src='/icons/close.svg' alt='close' />
                </div>
            </div>

            <div className='common-popup-body scroll-y-label'>
                <div className='filter-steps'>
                    {[filterTypes.CATEGORY].includes(activeFilterType) && (
                        <div className='single-step'>
                            {renderFirstOptions()}
                        </div>
                    )}
                    {secondOptionList?.length > 0 && (
                        <div
                            className={`single-step ${
                                activeFilterType === filterTypes.CATEGORY
                                    ? 'single-step-right'
                                    : ''
                            }`}
                        >
                            {activeFilterType === filterTypes.CATEGORY && (
                                <input
                                    type='text'
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                    value={searchTerm}
                                    placeholder='Search'
                                    autoFocus={true}
                                />
                            )}
                            {filteredList?.length > 0 && (
                                <div
                                    className={`item item1 d-flex justify-between items-center ${
                                        isAllCheckboxSelected()
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    <div className='d-flex items-center'>
                                        <input
                                            type='checkbox'
                                            name='categories'
                                            id='category_7'
                                            value=''
                                            onChange={onClickAllCheckbox}
                                            checked={isAllCheckboxSelected()}
                                        />
                                        <label
                                            htmlFor='category_7'
                                            className='regular-14 ml-2'
                                        >
                                            All
                                        </label>
                                    </div>
                                </div>
                            )}
                            {renderSecondSteps()}
                        </div>
                    )}
                </div>
            </div>
            <div className='common-popup-footer flex items-center justify-end'>
                <button className='button gray mr-3' onClick={clearFilter}>
                    <span className='text-xs'>Clear filter</span>
                </button>
                <button className='button primary sm' onClick={onClickApply}>
                    Apply
                </button>
            </div>
        </div>
    )

    const onClickFilterType = async (value) => {
        setSearchTerm('')
        setFilterOptions(JSON.parse(JSON.stringify(filterStore)))
        setActiveFilterType(value)
        setShowFilter(true)
    }

    const isFilterFieldSelected = (fieldName) => {
        return filterStore[fieldName]?.length > 0 ? 'active' : ''
    }

    const removeChip = (item) => {
        dispatch(
            FilterActionTypes[REMOVE_FILTER_FIELD]({
                name: item.fieldName,
                data: item.id
            })
        )
    }

    const getChipList = () => {
        let tmpChips = []
        for (let item in filterStore) {
            if (Array.isArray(filterStore[item])) {
                let tmpValues = filterStore[item]?.map((obj) => ({
                    ...obj,
                    fieldName: item,
                    label: obj.value
                }))
                tmpChips = tmpChips.concat(tmpValues)
            } else if (filterStore[item] instanceof Object) {
                if (filterStore[item].value) {
                    tmpChips.push({ ...filterStore[item], fieldName: item })
                }
            }
        }
        return tmpChips
    }

    const renderChips = () => {
        const tmpChips = getChipList()
        return tmpChips?.map((item, index) => {
            return (
                <div
                    className='filter-chip'
                    key={`filter_chip_${index}`}
                    onClick={() => removeChip(item)}
                >
                    <span>{capitalizeFirstAndLowercaseRest(item.label)}</span>
                    <CloseIcon />
                </div>
            )
        })
    }

    const isShowClearAll = () => {
        let isShow = false

        for (let item in filterStore) {
            if (
                Array.isArray(filterStore[item]) &&
                filterStore[item].length > 0
            ) {
                isShow = true
            }
            if (filterStore[item]?.value === true) {
                isShow = true
            }
        }
        return isShow
    }

    return (
        <>
            <div className='filter-top-header'>
                <div className='chips-and-action'>
                    {filteredDataLength > 0 && (
                        <div className='chips'>
                            {requiredFields?.NEW_IN && (
                                <span
                                    className={`chip ${
                                        filterStore.isNew?.value ? 'active' : ''
                                    }`}
                                    onClick={() => {
                                        dispatch(
                                            FilterActionTypes[
                                                ADD_FILTER_FIELDS
                                            ]({
                                                data: {
                                                    isNew: {
                                                        id: 'isNew',
                                                        value: !filterStore
                                                            .isNew?.value,
                                                        label: 'New In'
                                                    }
                                                }
                                            })
                                        )
                                    }}
                                >
                                    New in
                                </span>
                            )}
                            {requiredFields?.AGE_GROUP && (
                                <span
                                    className={`chip ${isFilterFieldSelected(
                                        'ageGroups'
                                    )}`}
                                    onClick={() =>
                                        onClickFilterType(filterTypes.AGE_GROUP)
                                    }
                                >
                                    Age group
                                </span>
                            )}
                            {requiredFields?.RECENTLY_SHARED && (
                                <span
                                    className={`chip ${
                                        filterStore.recentlyShared?.value
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        dispatch(
                                            FilterActionTypes[
                                                ADD_FILTER_FIELDS
                                            ]({
                                                data: {
                                                    recentlyShared: {
                                                        id: 'recently_shared',
                                                        value: !filterStore
                                                            .recentlyShared
                                                            ?.value,
                                                        label: 'Recently Shared'
                                                    }
                                                }
                                            })
                                        )
                                    }}
                                >
                                    Recently shared
                                </span>
                            )}
                            {requiredFields?.DESIGNERS_PICK && (
                                <span
                                    className={`chip ${
                                        filterStore.designersPick?.value
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        dispatch(
                                            FilterActionTypes[
                                                ADD_FILTER_FIELDS
                                            ]({
                                                data: {
                                                    designersPick: {
                                                        id: 'designers_pick',
                                                        value: !filterStore
                                                            .designersPick
                                                            ?.value,
                                                        label: 'Designer’s Pick'
                                                    }
                                                }
                                            })
                                        )
                                    }}
                                >
                                    Designer’s pick
                                </span>
                            )}
                            {requiredFields?.DESIGN_LOOK && (
                                <span
                                    className={`chip ${isFilterFieldSelected(
                                        'designLooks'
                                    )}`}
                                    onClick={() =>
                                        onClickFilterType(
                                            filterTypes.DESIGN_LOOK
                                        )
                                    }
                                >
                                    Design look
                                </span>
                            )}
                            {requiredFields?.CATEGORY && (
                                <span
                                    className={`chip ${isFilterFieldSelected(
                                        'subCategories'
                                    )}`}
                                    onClick={() =>
                                        onClickFilterType(filterTypes.CATEGORY)
                                    }
                                >
                                    Category
                                </span>
                            )}
                            {requiredFields?.SEASON && (
                                <span
                                    className={`chip ${isFilterFieldSelected(
                                        'seasons'
                                    )}`}
                                    onClick={() =>
                                        onClickFilterType(filterTypes.SEASON)
                                    }
                                >
                                    Season
                                </span>
                            )}
                            {requiredFields?.FABRIC && (
                                <span
                                    className={`chip ${isFilterFieldSelected(
                                        'fabrics'
                                    )}`}
                                    onClick={() =>
                                        onClickFilterType(filterTypes.FABRIC)
                                    }
                                >
                                    Fabric
                                </span>
                            )}

                            {requiredFields?.DEPARTMENT && (
                                <span
                                    className={`chip ${isFilterFieldSelected(
                                        'departments'
                                    )}`}
                                    onClick={() =>
                                        onClickFilterType(
                                            filterTypes.DEPARTMENT
                                        )
                                    }
                                >
                                    Department
                                </span>
                            )}
                        </div>
                    )}
                    <div className='actions'>{children}</div>
                </div>
                {getChipList().length > 0 && (
                    <div className='filtered-chips'>
                        {renderChips()}
                        {isShowClearAll() && (
                            <button
                                className='button link mr-3'
                                onClick={() => {
                                    dispatch(
                                        FilterActionTypes[CLEAR_FILTER_FIELDS]()
                                    )
                                }}
                            >
                                <span className='text-xs'>Clear all</span>
                            </button>
                        )}
                    </div>
                )}
            </div>
            {showFilter && (
                <ModalContainer
                    className='category-filter-modal'
                    onCloseModal={() => setShowFilter(false)}
                >
                    {renderFilterOptions()}
                </ModalContainer>
            )}
        </>
    )
}

export default FilterComponent
