import React, { useRef, useState } from 'react'
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg'
import Congrats from './Congrats'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { toast } from 'react-toastify'
import Http from '../../helpers/Http'
import { CollectionThunks } from '../../redux_toolkit/Collection/CollectionThunks'
import { GET_COLLECTION_COUNT } from '../../redux_toolkit/@types/thunk.types'
import { useNavigate } from 'react-router-dom'
import { collectionActionTypes } from '../../redux_toolkit/Collection'
import { ADD_NEWLY_CREATED_COLLECTION } from '../../redux_toolkit/@types/action.types'

const CreateCollection = ({
                              closeModal,
                              showCongrats = true,
                              callBack
                          }) => {
    const [collectionName, setCollectionName] = useState('')
    const [showForm, setShowForm] = useState(true)
    const [redirect, setRedirect] = useState(false)
    const [payload, setPayload] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const closeButtonRef = useRef()

    const clearModal = (value) => {
        setCollectionName('')
        closeModal()
        if (redirect) {
            if (value === 'view') {
                navigate(`/collection/${payload.id}`)
            } else {
                dispatch({
                    type: collectionActionTypes[ADD_NEWLY_CREATED_COLLECTION],
                    payload: payload
                })
            }
        }
    }

    const handleSaveCollection = async () => {
        await dispatch(openLoader())
        await Http.POST('addCollectionByName', {
            name: collectionName,
            privacy: 'CUSTOM'
        })
            .then((response) => {
                setPayload(response.data.payload)
                if (callBack) {
                    callBack(response.data.payload)
                    closeButtonRef.current.click()
                }
                dispatch(CollectionThunks[GET_COLLECTION_COUNT]())
                setRedirect(true)
                setShowForm(false)
                toast.success('Collection added successfully!')
                dispatch(closeLoader())
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                dispatch(closeLoader())
            })
    }

    return (
        <>
            <div
                className='modal show custom-modal-backdrop fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto'
                id='CreateCollection'
                tabIndex='-1'
                aria-labelledby='exampleModalCenterTitle'
                aria-modal='true'
                style={{ display: 'block' }}
                role='dialog'
                data-bs-focus='false'
            >
                {showForm && (
                    <div
                        className='modal-dialog max-w-[400px] modal-dialog-centered relative w-auto pointer-events-none'>
                        <div
                            className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current'>
                            <div
                                className='modal-header flex flex-shrink-0 items-center justify-between bg-primaryColor-shade-300 p-4'>
                                <h5
                                    className='text-xl font-bold leading-normal text-primaryColor uppercase'
                                    id='exampleModalScrollableLabel'
                                >
                                    Create Collection
                                </h5>
                                <button
                                    ref={closeButtonRef}
                                    onClick={clearModal}
                                    type='button'
                                    className='btn-close box-content w-4 h-4 p-1 !mr-0.5 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                />
                            </div>
                            <div className='modal-body relative p-4'>
                                <div className='space-y-4'>
                                    <div className='input-group'>
                                        <label htmlFor='text' className='label'>
                                            Collection Name
                                        </label>
                                        <input
                                            value={collectionName}
                                            onChange={(e) =>
                                                setCollectionName(
                                                    e.target.value
                                                )
                                            }
                                            type='text'
                                            className='form-field bg-primaryColor-shade-300'
                                            id='text'
                                            placeholder='e.g. summar 22'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer p-4'>
                                <button
                                    disabled={!collectionName}
                                    type='button'
                                    className='btn flex justify-between items-center max-w-[445px] w-full'
                                    onClick={handleSaveCollection}
                                >
                                    <span>Create</span>
                                    <span className='ml-2'>
                                        <PlusIcon />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {!showForm && showCongrats && (
                    <Congrats title={collectionName} clearModal={clearModal} />
                )}
            </div>
        </>
    )
}

export default CreateCollection
