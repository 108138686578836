import React, { useState } from 'react'
import ModalContainer from '../../common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { addNewFavouriteFolder } from '../../services/v3/Favourite/FavouriteService'

const AddFolderModal = ({ isShowModal, closeModal, fetchFolderList }) => {
    const [error, setError] = useState(false)
    const [folderName, setFolderName] = useState('')
    const dispatch = useDispatch()

    const handleCreate = async () => {
        if (folderName) {
            try {
                dispatch(openLoader())
                await addNewFavouriteFolder(`?name=${encodeURIComponent(folderName)}`)
                await fetchFolderList()
                dispatch(closeLoader())
                closeModal()
            } catch (e) {
                dispatch(closeLoader())
            }
        }else{
            setError(true)
        }
    }

    return (
        <>
            {isShowModal && (
                <ModalContainer
                    className='create-folder-modal'
                    onCloseModal={closeModal}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Create new folder
                                </h3>
                                <p className='regular-12 gray_300'>
                                    Create new folder in favorite
                                </p>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={closeModal}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <div className='form-group'>
                                <label className='regular-14 gray_300 mb-2'>
                                    Title
                                </label>

                                <input
                                    className={error ? 'required' : ''}
                                    type='text'
                                    name='title'
                                    placeholder=''
                                    value={folderName}
                                    onChange={e => setFolderName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='common-popup-footer'>
                            <button className='button primary mr-3' onClick={handleCreate}>
                                <span>Create</span>
                            </button>
                            <button
                                className='button secondary'
                                onClick={closeModal}
                            >
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    )
}
export default AddFolderModal;
