import { createSlice } from '@reduxjs/toolkit'
import { ExploreDesignReducer } from './ExploreDesignReducer'
import { useSelector } from 'react-redux';

const initialState = {
    response: {}
}

const ExploreDesignSlice = createSlice({
    name: 'exploreDesigns',
    initialState,
    reducers: ExploreDesignReducer
})


export const ExploreDesignAction = ExploreDesignSlice.actions

export const useExploreDesigns = () => useSelector(state => state.exploreDesigns.response)

export default ExploreDesignSlice.reducer
