import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { CollectionViewReducer } from './CollectionViewReducer'

const initialState = {
    selectedProducts: []
}

export const collectionViewSlice = createSlice({
    name: 'collection',
    initialState,
    reducers: CollectionViewReducer
})

export const CollectionViewTypes = collectionViewSlice.actions

export const useCollection = () => useSelector(state => state.collection)

export default collectionViewSlice.reducer
