import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { updateFavourite } from '../../services/v3/Favourite/FavouriteService'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg'
import {
    capitalizeFirstLetter,
    changeDateFormat,
    getQuoteStatusClass,
    getSampleActivityStatus,
    getColorWiseActivityStatusOfSample,
    isAllIdExistBetweenTwoList,
    isValidInput,
    getShortName
} from '../../helpers/Util'
import Tooltip from '@mui/material/Tooltip'
import DesignImages from '../common/DesignImages'
import PackSetOrTag from '../common/PackOrSetTag'

const TableView = ({
    favouriteData,
    setFavouriteData,
    categoryName = '',
    selectedIds,
    toggleSelection,
    toggleAllSelection,
    onClickDesign,
    isDisabled
}) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const prevTarget = useRef(null)

    const { id } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const onChangeItem = (e, item, type) => {
        let updatedData = [...favouriteData]
        const index = updatedData.findIndex(
            (dataItem) => dataItem.id === item.id
        )

        if (index !== -1) {
            if (type === 'quantity') {
                const currentRemarks = updatedData[index]['remarks'] || ''
                updatedData[index] = {
                    ...updatedData[index],
                    quantity: e.target.value,
                    remarks: currentRemarks
                }
            } else {
                const currentQuantity = updatedData[index]['quantity'] || null
                updatedData[index] = {
                    ...updatedData[index],
                    quantity: currentQuantity,
                    remarks: e.target.value
                }
            }
            setFavouriteData(updatedData)
        }
    }

    const onUpdateFavourite = async (item) => {
        try {
            dispatch(openLoader())
            const queryParams = []
            let apiParams = ''

            if (id && id !== 'my-favourite') {
                queryParams.push(`folderId=${id}`)
            }
            queryParams.push(`quantity=${item?.quantity}`)
            queryParams.push(`remarks=${item?.remarks}`)
            const queryString = queryParams.join('&')

            if (queryString) {
                apiParams += `${item?.id}?${queryString}`
            }
            await updateFavourite(apiParams)
            dispatch(closeLoader())
        } catch (error) {
            dispatch(closeLoader())
        }
    }

    const getOrderStatus = {
        PENDING: 'purple-3',
        RUNNING: 'teal-2',
        ON_SHIPMENT: 'blue-3',
        COMPLETED: 'green-3',
        CANCELLED: 'red-3'
    }

    const isAllChecked = () => {
        if (
            selectedIds?.length > 0 &&
            favouriteData.length === selectedIds.length
        ) {
            let allIds = favouriteData?.map((item) => item.id)
            return isAllIdExistBetweenTwoList(allIds, selectedIds)
        }
        return false
    }

    const renDerQuantity = (item, rowSpan = null) => {
        return (
            <td rowSpan={rowSpan} className='qty-column'>
                <input
                    type='number'
                    value={item?.quantity}
                    onChange={(e) => onChangeItem(e, item, 'quantity')}
                    onFocus={(event) =>
                        (prevTarget.current = event.target.value)
                    }
                    onBlur={(e) => {
                        if (!isValidInput(prevTarget, e)) {
                            prevTarget.current = null
                            return
                        }
                        onUpdateFavourite(item)
                    }}
                />
            </td>
        )
    }

    const renderRemarks = (item, rowSpan = null) => {
        return (
            <td rowSpan={rowSpan} className='remarks-column'>
                <textarea
                    className='h-11'
                    value={item?.remarks}
                    onChange={(e) => onChangeItem(e, item, 'remarks')}
                    onFocus={(event) =>
                        (prevTarget.current = event.target.value)
                    }
                    onBlur={(e) => {
                        if (!isValidInput(prevTarget, e)) {
                            prevTarget.current = null
                            return
                        }
                        onUpdateFavourite(item)
                    }}
                />
            </td>
        )
    }

    const renderCheckBox = (item) => {
        return (
            <input
                type='checkbox'
                name='categories'
                checked={selectedIds.includes(item.id)}
                onChange={() => toggleSelection(item.id)}
            />
        )
    }

    const renderProductImage = (item) => {
        const designDocuments = item?.isSet
            ? item?.setMemberDocUrls?.map((item) => ({
                  id: item,
                  docUrl: item
              }))
            : [{ id: item.docUrl, docUrl: item.docUrl }]
        return (
            <div className='flex items-center'>
                <div className='w-9 min-w-[36px]'>
                    <DesignImages
                        designInfo={{
                            designDocuments: designDocuments,
                            isSet: item?.isSet
                        }}
                    />
                </div>
                <div className='ml-2'>
                    {' '}
                    {item?.isPack && <PackSetOrTag tagName='Pack' />}
                    {item?.isSet && <PackSetOrTag tagName='Set' />}
                    <Tooltip
                        title={item?.referenceNumber}
                        placement={'top'}
                        arrow
                    >
                        <p
                            className='blue regular-14 cursor-pointer mt-1'
                            onClick={() => onClickDesign(item)}
                        >
                            {getShortName(item?.referenceNumber, 16)}
                        </p>
                    </Tooltip>
                </div>
                {isDisabled(item) && (
                    <div className='style-status'>
                        <p className='regular-16 red flex items-center ml-2'>
                            <WarningIcon className='mr-1' />
                        </p>{' '}
                    </div>
                )}
            </div>
        )
    }

    const renderStatusColor = (status) => {
        const getStatusText = (status) => {
            const statusMap = {
                OFFER_SENT: 'Quote Received',
                REQUEST_FOR_REVISION: 'Re-cost',
                APPROVED: 'Approved',
                PENDING: 'Requested',
                CANCELLED: 'Cancelled',
                REJECTED: 'Rejected'
            }
            return statusMap[status] || statusMap.PENDING
        }

        const statusText = getStatusText(status)
        const statusClass = `status ${
            getQuoteStatusClass[status] || getQuoteStatusClass.PENDING
        }`

        return <span className={statusClass}>{statusText}</span>
    }

    const renderQuoteStatus = (item) => {
        return (
            <>
                {item?.quoteStatus && renderStatusColor(item.quoteStatus)}
                <p className='text-sm gray_300 mt-1'>
                    {item?.offerPrice ? '$' + item?.offerPrice.toFixed(2) : ''}
                </p>
            </>
        )
    }

    const renderSampleStatus = (item) => {
        const sampleStatus =
            item?.sampleStatus === 'CANCELLED'
                ? item?.sampleStatus
                : item?.currentActivity
        return (
            <>
                {sampleStatus && (
                    <>
                        {getColorWiseActivityStatusOfSample(
                            sampleStatus === 'REQUESTED'
                                ? 'SAMPLE_REQUESTED'
                                : sampleStatus,
                            ''
                        )}
                    </>
                )}

                <p className='text-sm blue mb-1 mt-1'>
                    <Link to={`/samples/detail/${item?.sampleId}`}>
                        {item?.sampleRefNo}{' '}
                    </Link>
                </p>
            </>
        )
    }

    const renderOrderStatus = (item) => {
        return (
            <>
                {item?.orderStatus && (
                    <span
                        className={`status ${
                            getOrderStatus[item?.orderStatus]
                        }`}
                    >
                        {capitalizeFirstLetter(item?.orderStatus)}
                    </span>
                )}
                <p className='text-sm blue my-1'>
                    <Link to={`/orders/view/${item?.orderId}`}>
                        {item?.orderRefNos && item?.orderRefNos[0]}
                    </Link>
                </p>
                {item?.deliveryDate && (
                    <p className='text-sm gray_300'>
                        ETD:{' '}
                        {changeDateFormat(
                            item?.deliveryDate,
                            '',
                            'Do MMM, YYYY'
                        )}
                    </p>
                )}
            </>
        )
    }

    const renderTabView = () => {
        return (
            <div className='tab-view-table favourite-tab-view-table py-4 px-3'>
                <div className='select-all mb-2'>
                    <div className='flex items-center'>
                        <input
                            checked={isAllChecked()}
                            type='checkbox'
                            name='all'
                            onChange={toggleAllSelection}
                        />
                        <label className='text-base blue ml-2'>
                            Select all
                        </label>
                    </div>
                </div>
                <div className='all-rows'>
                    {favouriteData?.map((item) => {
                        return (
                            <div className='single-row mb-4' key={item.id}>
                                <table className='table'>
                                    <tr>
                                        <td rowSpan='8'>
                                            {renderCheckBox(item)}
                                        </td>
                                        <td className='heading'>Style</td>
                                        <td>{renderProductImage(item)}</td>
                                    </tr>
                                    <tr>
                                        <td className='heading'>Description</td>
                                        {!item?.isSet && (
                                            <td>{item?.description}</td>
                                        )}
                                        {item?.isSet && (
                                            <td>
                                                <ul>
                                                    {item?.setMemberDescriptions?.map(
                                                        (description, i) => (
                                                            <li
                                                                key={`set_description_${i}`}
                                                            >
                                                                {description}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className='heading'>Quantity</td>
                                        {renDerQuantity(item)}
                                    </tr>
                                    <tr>
                                        <td className='heading'>Quote</td>
                                        <td>
                                            {renderQuoteStatus(item)}
                                            <p className='text-sm blue mb-1'>
                                                <Link
                                                    to={`/quotes/detail/${item?.quoteId}`}
                                                >
                                                    {item?.quoteRefNo}
                                                </Link>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='heading'>Sample</td>
                                        <td>{renderSampleStatus(item)}</td>
                                    </tr>
                                    <tr>
                                        <td className='heading'>Order</td>
                                        <td>{renderOrderStatus(item)}</td>
                                    </tr>
                                    <tr>
                                        <td className='heading'>Remarks</td>
                                        {renderRemarks(item)}
                                    </tr>
                                </table>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderWebTableView = () => {
        const renderTableBody = () => {
            return favouriteData?.map((item, index) => {
                let rowSpan = item?.setMemberDescriptions?.length || null
                let tmpArray = Array.from(
                    {
                        length: item?.setMemberDescriptions?.length || 1
                    },
                    (_, i) => i + 1
                )
                return (
                    <React.Fragment key={`product_${index}`}>
                        {tmpArray.map((_, row) => {
                            return (
                                <tr key={`product_${item.id}_${row}`}>
                                    {row === 0 && (
                                        <>
                                            <td
                                                rowSpan={rowSpan}
                                                className='checkbox-column'
                                            >
                                                {renderCheckBox(item)}
                                            </td>
                                            <td
                                                rowSpan={rowSpan}
                                                className='relative style-column'
                                            >
                                                {renderProductImage(item)}
                                            </td>
                                            {!item?.isSet && (
                                                <td className='description-column'>
                                                    {item?.description}
                                                </td>
                                            )}
                                        </>
                                    )}

                                    {item?.isSet && (
                                        <td className='description-column'>
                                            {item?.setMemberDescriptions?.[row]}
                                        </td>
                                    )}
                                    {row === 0 && (
                                        <>
                                            {renDerQuantity(item, rowSpan)}
                                            <td
                                                rowSpan={rowSpan}
                                                className='quotes-column'
                                            >
                                                {renderQuoteStatus(item)}
                                                <p className='text-sm blue mb-1 mt-1'>
                                                    <Link
                                                        to={`/quotes/detail/${item?.quoteId}`}
                                                    >
                                                        {item?.quoteRefNo}
                                                    </Link>
                                                </p>
                                            </td>
                                            <td
                                                rowSpan={rowSpan}
                                                className='sample-column'
                                            >
                                                {renderSampleStatus(item)}
                                            </td>
                                            <td
                                                rowSpan={rowSpan}
                                                className='order-column'
                                            >
                                                {renderOrderStatus(item)}
                                            </td>
                                            {renderRemarks(item, rowSpan)}
                                        </>
                                    )}
                                </tr>
                            )
                        })}
                    </React.Fragment>
                )
            })
        }

        return (
            <div className='px-4'>
                <div className='mt-6'>
                    <div className='group mb-3'>
                        {categoryName && (
                            <h3 className='text-xl font-bold mb-3'>
                                {capitalizeFirstLetter(categoryName)}
                                &nbsp;({favouriteData?.length})
                            </h3>
                        )}
                        <table className='table favourite-list-table'>
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            checked={isAllChecked()}
                                            type='checkbox'
                                            name={categoryName}
                                            onChange={toggleAllSelection}
                                        />
                                    </th>
                                    <th>Style</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Quote</th>
                                    <th>Sample</th>
                                    <th>Order</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>{renderTableBody()}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>{screenWidth < 1200 ? renderTabView() : renderWebTableView()}</div>
    )
}

export default TableView
