import {
    ADD_ACTIVE_SEARCH_IMAGE,
    ADD_CROPPED_IMAGE,
    ADD_NEW_DESIGN,
    ADD_SEARCH_RESULTS,
    CLEAR_SEARCH,
    TOGGLE_SEARCH_AS_FAVOURITE
} from '../../@types/thunk.types'

const SearchReducer = {
    [ADD_NEW_DESIGN]: (state, { payload }) => {
        state.images = [...new Set([...payload.data, ...state.images])]
        state.activeImage = payload.data[0]
        state.croppedImage = payload.data[0]
    },
    [ADD_ACTIVE_SEARCH_IMAGE]: (state, { payload }) => {
        state.activeImage = payload.data
        state.croppedImage = payload.data
    },
    [ADD_CROPPED_IMAGE]: (state, { payload }) => {
        state.croppedImage = payload.data
    },
    [TOGGLE_SEARCH_AS_FAVOURITE]: (state, { payload }) => {
        state.searchResults.data = state.searchResults?.data?.map((item) => {
            if (item.id === payload) {
                item.liked = !item.liked
            }
            return item
        })
    },
    [ADD_SEARCH_RESULTS]: (state, { payload }) => {
        let styles = []
        if (payload.merge) {
            styles = [...state.searchResults.data, ...payload.data.data]
        } else {
            styles = [...payload.data.data]
        }
        payload.data.data = styles
        state.searchResults = payload.data
    },
    [CLEAR_SEARCH]: (state) => {
        state.searchResults = []
        state.croppedImage = null
        state.activeImage = null
        state.images = []
    }
}

export default SearchReducer
