import React from 'react'
import {
    getFileType,
    openNewWindow,
    onImageLoadError,
    getIconByFileType
} from '../helpers/Util'

const FilePreviewer = ({ docUrl, base64Data }) => {
    const renderFilePreview = (docUrl, base64Data) => {
        if (!docUrl && base64Data)
            return (
                <img
                    src={base64Data}
                    alt={getIconByFileType('FILE')}
                    className='w-7 h-7 object-contain'
                />
            )

        const fileType = getFileType(docUrl)
        if (fileType === 'IMAGE' || fileType === 'NO_FILE')
            return (
                <img
                    className='w-7 h-7 object-contain'
                    src={docUrl}
                    alt='Upload'
                    onError={onImageLoadError}
                    onClick={() => openNewWindow(docUrl)}
                />
            )
        else
            return (
                <img
                    className='cursor-pointer file-icon w-7 h-7 object-contain'
                    src={getIconByFileType(fileType)}
                    onClick={() => {
                        window.open(docUrl, '_blank').focus()
                    }}
                />
            )
    }

    return <>{renderFilePreview(docUrl, base64Data)}</>
}

export default FilePreviewer
