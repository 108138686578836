import React from 'react'

const Congrats = ({ title, clearModal }) => {
    return (
        <div
            className='modal-dialog max-w-[400px] overflow-hidden modal-dialog-centered relative w-auto pointer-events-none'>
            <div
                className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current'>
                <div className='modal-header flex flex-shrink-0 items-center justify-between p-6 pb-0'>
                    <h5
                        className='text-xl font-bold leading-normal text-primaryColor uppercase'
                        id='exampleModalScrollableLabel'
                    >
                        Congratulations!
                    </h5>
                    <button
                        onClick={() => clearModal('close')}
                        type='button'
                        className='btn-close box-content w-4 h-4 p-1 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                    />
                </div>
                <div className='modal-body relative p-6'>
                    <div className='space-y-4'>
                        <p className='text-base'>
                            <strong>‘{title}’</strong> collection has been
                            created.{' '}
                        </p>
                    </div>
                </div>
                <div className='modal-footer p-6'>
                    <button
                        type='button'
                        className='btn w-full'
                        onClick={() => clearModal('view')}
                        data-bs-toggle='modal'
                        data-bs-target='#exampleModalCenter'
                    >
                        View Collection
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Congrats
