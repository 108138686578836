import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ColorPicker from '../../common/ColorPicker'
import FabricFilter from './FabricFilter'
import MoodboardThunks from '../../redux_toolkit/Moodboard/MoodboardThunks'
import {
    ADD_FABRIC_TO_MOODBOARD,
    DELETE_COLOR_FROM_MOODBOARD,
    DELETE_FABRIC_FROM_MOODBOARD,
    DELETE_PRODUCT_IMAGE,
    GET_COLLECTION_COUNT,
    GET_MOODBOARD_ADDED_IMAGE,
    GET_MOODBOARD_BY_ID,
    GET_MOODBOARD_COLOR,
    GET_MOODBOARD_FABRICS,
    UPDATE_MOODBOARD,
    UPLOAD_MOODBOARD_IMAGES
} from '../../redux_toolkit/@types/thunk.types'
import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as FilterIcon } from '../../../assets/icons/old/Filter-24.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/old/close.svg'
import { ReactComponent as TickIcon } from '../../../assets/icons/old/tick.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/old/delete.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/old/edit.svg'
import { ReactComponent as LoaderIcon } from '../../../assets/icons/old/loder.svg'
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg'
import DemoImage_1 from '../../../assets/designs/moodboard/m1.png'
import DemoImage_2 from '../../../assets/designs/moodboard/m2.png'
import DemoImage_3 from '../../../assets/designs/moodboard/m3.png'
import DemoImage_4 from '../../../assets/designs/moodboard/m4.png'
import Http from '../../helpers/Http'
import { getShortName } from '../../helpers/Util'
import CollectionRequestConfirmation from './CollectionRequestConfirmation'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import {
    getMoodboardColorByID,
    getMoodboardImageByID,
    getMoodboardSimilarFabric
} from '../../services/Moodboard'
import { MoodboardActions } from '../../redux_toolkit/Moodboard'
import {
    SET_MOODBOARD_ADDED_IMAGE,
    SET_MOODBOARD_COLOR,
    SET_MOODBOARD_FABRICS
} from '../../redux_toolkit/@types/action.types'
import { CollectionThunks } from '../../redux_toolkit/Collection/CollectionThunks'

const MissonaryImages = {
    0: DemoImage_1,
    1: DemoImage_2,
    2: DemoImage_3,
    3: DemoImage_4
}

const MoodboardView = (props) => {
    const { id } = useParams()

    const selectedMoodboard = useSelector(
        (state) => state.moodboard.selectedMoodboard
    )

    const moodboardFabrics = useSelector(
        (state) => state.moodboard.moodboardFabrics
    )
    const navigate = useNavigate()

    const [selectedProductView, setSelectedProductView] = useState('moodboard')
    const [titleEdit, setTitleEdit] = useState(false)
    const [descriptionEdit, setDescriptionEdit] = useState(false)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [titleError, setTitleError] = useState(null)
    const [selectedFiles, setSelectedFiles] = useState([])
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [fabricFilterView, setFabricFilterView] = useState(false)
    const [colorLoading, setColorLoading] = useState(false)
    const [imageLoading, setImageLoading] = useState(false)

    const dispatch = useDispatch()
    const gotoCollectionRef = useRef()
    const [searchParams, setSearchParams] = useSearchParams()
    const uploaded = searchParams.get('uploaded')

    const onTitleEditButtonClick = (e) => {
        e.preventDefault()
        if (!title || title.length === 0) {
            setTitleError('Title is required')
            return
        }
        setTitleEdit(!titleEdit)
        if (titleEdit) {
            dispatch(
                MoodboardThunks[UPDATE_MOODBOARD]({
                    name: title,
                    description: description,
                    id: selectedMoodboard.id
                })
            )
        }
    }

    const onDescriptionEditButtonClick = (e) => {
        setDescriptionEdit(!descriptionEdit)
        if (descriptionEdit) {
            dispatch(
                MoodboardThunks[UPDATE_MOODBOARD]({
                    name: title,
                    description: description,
                    id: selectedMoodboard.id
                })
            )
        }
    }

    const requestForCollection = () => {
        Http.POST(
            'requestForCollection',
            {},
            selectedMoodboard.id + '/request-for-collection'
        ).then(() => {
            dispatch(CollectionThunks[GET_COLLECTION_COUNT]())
            dispatch(MoodboardThunks[GET_MOODBOARD_BY_ID](id))
            gotoCollectionRef.current.click()
        })
    }

    // this function is use to set data on image change
    const onFileChange = async (e) => {
        setSelectedFiles([...e.target.files])

        // console.log(selectedFile)
    }

    // to toggle colot picker
    const toggleColorPicker = () => {
        setShowColorPicker(!showColorPicker)
    }

    // used to upload product images
    const uploadProductImagesAsync = async () => {
        if (selectedFiles.length > 0) {
            dispatch(openLoader())
            await dispatch(
                MoodboardThunks[UPLOAD_MOODBOARD_IMAGES](selectedFiles, id)
            )
            await dispatch(MoodboardThunks[GET_MOODBOARD_BY_ID](id))
            dispatch(closeLoader())
        }
    }

    // to delete colos from moodboard
    const deleteColorFromMoodboard = async (colorId, moodboardID) => {
        await dispatch(
            MoodboardThunks[DELETE_COLOR_FROM_MOODBOARD](colorId, moodboardID)
        )
        // await dispatch(MoodboardThunks[GET_MOODBOARD_BY_ID](id))
    }

    // on clicking delete product image
    const onClickDeleteImage = async (id, imageId) => {
        dispatch(MoodboardThunks[DELETE_PRODUCT_IMAGE](id, imageId))
    }

    // to add a fabric to moodboard
    const addFabricToMoodboard = async (e, fabricId) => {
        dispatch(MoodboardThunks[ADD_FABRIC_TO_MOODBOARD](id, fabricId))
    }

    const removeFabricFromMoodboard = async (e, fabricId) => {
        dispatch(MoodboardThunks[DELETE_FABRIC_FROM_MOODBOARD](id, fabricId))
    }

    const saveTitleAndDescription = async () => {
        await dispatch(
            MoodboardThunks[UPDATE_MOODBOARD]({
                name: title,
                description: description,
                id: selectedMoodboard.id
            })
        )
        await dispatch(MoodboardThunks[GET_MOODBOARD_BY_ID](id))
        await setTitleEdit(false)
        await setDescriptionEdit(false)
    }

    const fabricNameCharCount = () => {
        if (screen.width && screen.width < 1445) return 20
        return 30
    }

    const renderActionButton = () => {
        if (!selectedMoodboard?.name) {
            return (
                <button
                    type='button'
                    className='btn float-right'
                    onClick={() => saveTitleAndDescription()}
                >
                    <span>Save</span>
                </button>
            )
        } else if (selectedMoodboard?.status === 'INITIALIZED') {
            return (
                <button
                    type='button'
                    className='btn float-right'
                    onClick={() => requestForCollection()}
                >
                    <span>
                        Ask for <strong>Collection</strong>
                    </span>
                </button>
            )
        } else {
            return (
                <button
                    type='button'
                    className='btn bg-white text-black float-right'
                    onClick={() => {
                        navigate(
                            '/collection/' + selectedMoodboard.collectionId
                        )
                    }}
                >
                    <span>
                        Go to <strong>Collection</strong>
                    </span>
                </button>
            )
        }
    }

    const renderColors = () => {
        if (colorLoading)
            return (
                <div className='w-full text-center'>
                    <LoaderIcon className='w-32 h-32' />
                </div>
            )
        return (
            <>
                <div className='moodboard-color-container'>
                    <div className='color-single-item'>
                        <div
                            className='add-color'
                            onClick={(e) => {
                                e.preventDefault()
                                toggleColorPicker()
                            }}
                        >
                            <span>
                                <PlusIcon />
                            </span>
                        </div>
                    </div>
                    {selectedMoodboard?.colorResponseList.length > 0 &&
                        selectedMoodboard.colorResponseList.map((color) => (
                            <div className='color-single-item' key={color.id}>
                                <div
                                    className='color-view'
                                    style={{
                                        background: color.hexCode
                                    }}
                                >
                                    <span className='close'>
                                        <CloseIcon
                                            onClick={(e) => {
                                                e.preventDefault()
                                                deleteColorFromMoodboard(
                                                    id,
                                                    color.id
                                                )
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className='color-info'>
                                    <div className='color-code'>
                                        {color.code}
                                    </div>
                                    <div className='color-name'>
                                        {color.name}
                                    </div>
                                </div>
                            </div>
                        ))}
                    {showColorPicker && (
                        <ColorPicker
                            toggleColorPicker={toggleColorPicker}
                            moodboardID={selectedMoodboard?.id}
                        />
                    )}
                </div>
            </>
        )
    }

    const renderImages = () => {
        if (selectedProductView === 'images' && imageLoading)
            return (
                <div className='w-full text-center pt-24'>
                    <LoaderIcon className='w-32 h-32' />
                </div>
            )

        return (
            <div className='moodboard-masonry-container'>
                {(selectedProductView === 'images' ||
                    (selectedMoodboard?.collectionId &&
                        selectedMoodboard?.moodboardImageList?.length ===
                            0)) && (
                    <div className='masonry-item add-item inline-block'>
                        <label
                            htmlFor='uploadMultiple'
                            className='w-full h-full block cursor-pointer'
                        >
                            <span className=''>
                                <PlusIcon />
                            </span>
                        </label>
                        <input
                            multiple
                            type='file'
                            id='uploadMultiple'
                            accept='image/*'
                            className='hidden'
                            onClick={(e) => (e.target.value = null)}
                            onChange={(e) => onFileChange(e)}
                        />
                    </div>
                )}
                {(selectedProductView === 'images' ||
                    (selectedMoodboard?.collectionId &&
                        selectedMoodboard?.moodboardImageList?.length === 0)) &&
                selectedMoodboard?.productImageList.length > 0
                    ? selectedMoodboard.productImageList.map((image) => (
                          <div
                              className='masonry-item'
                              key={image.id}
                              onClick={() =>
                                  getFabricSuggestionOnImageSelect(image.id)
                              }
                          >
                              <img src={image.docUrl} alt='product' />
                              <span className='delete'>
                                  <DeleteIcon
                                      onClick={(e) => {
                                          e.preventDefault()
                                          onClickDeleteImage(id, image.id)
                                      }}
                                  />
                              </span>
                          </div>
                      ))
                    : (selectedMoodboard?.moodboardImageList?.length === 0 ||
                          (selectedMoodboard?.productImageList.length === 0 &&
                              selectedProductView === 'images')) &&
                      [...Array(12)].map((item, idx) => (
                          <div className='masonry-item' key={idx}>
                              <img
                                  src={
                                      MissonaryImages[
                                          Math.floor(Math.random() * 4)
                                      ]
                                  }
                                  alt='product'
                              />
                          </div>
                      ))}
            </div>
        )
    }

    const onFabricFilterClick = (e) => {
        e.preventDefault()
        setFabricFilterView(!fabricFilterView)
    }

    const onTitleUpdate = (e) => {
        e.preventDefault()
        setTitle(e.target.value)
        if (e.target.value) setTitleError(null)
    }

    const getMoodboardColorOnCreate = async () => {
        setColorLoading(true)
        let data = await getMoodboardColorByID(id).finally(() => {
            setColorLoading(false)
        })
        dispatch({
            type: MoodboardActions[SET_MOODBOARD_COLOR],
            payload: data.data
        })
    }

    const getMoodBoardImageOnCreate = async () => {
        setImageLoading(true)
        let data = await getMoodboardImageByID(id).finally(() => {
            setImageLoading(false)
        })
        dispatch({
            type: MoodboardActions[SET_MOODBOARD_ADDED_IMAGE],
            payload: data.data
        })
    }

    const getFabricSuggestionOnImageSelect = async (documentId) => {
        dispatch(openLoader())
        let data = await getMoodboardSimilarFabric(id, documentId).finally(() =>
            dispatch(closeLoader())
        )
        dispatch({
            type: MoodboardActions[SET_MOODBOARD_FABRICS],
            payload: data.data
        })
    }

    useEffect(() => {
        if (uploaded) {
            getMoodboardColorOnCreate()
            getMoodBoardImageOnCreate()
        }
    }, [])

    useEffect(() => {
        uploadProductImagesAsync()
    }, [selectedFiles])

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(MoodboardThunks[GET_MOODBOARD_BY_ID](id))
    }, [])

    useEffect(() => {
        setTitle(selectedMoodboard?.name)
        setDescription(selectedMoodboard?.description)
    }, [selectedMoodboard?.name, selectedMoodboard?.description])

    useEffect(() => {
        dispatch(MoodboardThunks[GET_MOODBOARD_FABRICS]())
    }, [])

    const isFabricSelected = (materialId) => {
        let selectedFabric = selectedMoodboard?.materialResponseList?.find(
            (item) => item.id === materialId
        )
        return selectedFabric ? Object.keys(selectedFabric).length > 0 : false
    }

    return (
        <>
            <div className='body-container p-4'>
                <div className='common-moodboard-container'>
                    <div className='moodboard-information'>
                        <div className='left-half'>
                            <div className='title'>
                                {!titleEdit && selectedMoodboard?.name && (
                                    <div className='input-group'>
                                        <label htmlFor='name' className='label'>
                                            Title
                                        </label>
                                        <h4>
                                            {selectedMoodboard?.name}
                                            <span>
                                                <EditIcon
                                                    onClick={(e) =>
                                                        onTitleEditButtonClick(
                                                            e
                                                        )
                                                    }
                                                />
                                            </span>
                                        </h4>
                                    </div>
                                )}

                                {(titleEdit || !selectedMoodboard?.name) && (
                                    <div className='input-group'>
                                        <label htmlFor='name' className='label'>
                                            Title
                                        </label>
                                        <input
                                            onBlur={(e) =>
                                                onTitleEditButtonClick(e)
                                            }
                                            onChange={(e) => onTitleUpdate(e)}
                                            autoFocus
                                            type='text'
                                            className='form-field'
                                            id='name'
                                            placeholder='Enter Name'
                                            name='name'
                                            value={title || ''}
                                        ></input>
                                        {titleError && (
                                            <p className='error-text'>
                                                {titleError}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                            {!descriptionEdit && selectedMoodboard?.name && (
                                <div className='description'>
                                    <div className='input-group'>
                                        <label htmlFor='name' className='label'>
                                            Description
                                        </label>
                                        <p>
                                            {selectedMoodboard?.description}
                                            <span>
                                                <EditIcon
                                                    onClick={(e) =>
                                                        onDescriptionEditButtonClick(
                                                            e
                                                        )
                                                    }
                                                />
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            )}

                            {(descriptionEdit || !selectedMoodboard?.name) && (
                                <div className='description'>
                                    <div className='input-group'>
                                        <label htmlFor='name' className='label'>
                                            Description
                                        </label>
                                        <textarea
                                            onBlur={(e) =>
                                                onDescriptionEditButtonClick(e)
                                            }
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                            autoFocus={descriptionEdit}
                                            type='text'
                                            className='form-field'
                                            placeholder='Write Here ...'
                                            value={description || ''}
                                        ></textarea>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='right-half'>{renderActionButton()}</div>
                    </div>

                    {/* Moodboard and image layout */}
                    <div className='moodboard-and-images-layout'>
                        <div className='left-half'>
                            {selectedMoodboard?.moodboardImageList.length >
                                0 && (
                                <div className='moodboard-tab'>
                                    <ul>
                                        <li
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setSelectedProductView(
                                                    'moodboard'
                                                )
                                            }}
                                            className={`${
                                                selectedProductView ===
                                                'moodboard'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                        >
                                            Moodboard
                                        </li>
                                        <li
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setSelectedProductView('images')
                                            }}
                                            className={`${
                                                selectedProductView === 'images'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                        >
                                            Images
                                        </li>
                                    </ul>
                                </div>
                            )}
                            {/* Images layout */}
                            <div>
                                {selectedProductView === 'moodboard' &&
                                    selectedMoodboard?.moodboardImageList
                                        .length > 0 && (
                                        <div className='full-moodboard-image aspect-[1200/700]'>
                                            {selectedMoodboard
                                                ?.moodboardImageList.length >
                                                0 &&
                                                selectedMoodboard.moodboardImageList.map(
                                                    (image, index) => (
                                                        <img
                                                            key={index}
                                                            src={image.docUrl}
                                                            alt='product'
                                                            className='mb-4'
                                                        />
                                                    )
                                                )}
                                        </div>
                                    )}
                                {renderImages()}
                            </div>
                        </div>
                        <div className='right-half'>
                            {/* Moodboard colors */}
                            <h3 className='font-semibold text-base 5xl:text-xl mb-2'>
                                Colors
                            </h3>
                            {renderColors()}
                            {/* Moodboard fabrics */}
                            <div className='moodboard-fabric-container relative'>
                                <h3>Fabrics</h3>
                                {/* Selcted Fabrics */}
                                <div className='fabric-filter'>
                                    <p>
                                        Selected <span>Fabrics</span>
                                    </p>
                                    <span className='hidden'>
                                        <FilterIcon />
                                    </span>
                                </div>
                                <div className='fabric-all-items'>
                                    {selectedMoodboard?.materialResponseList
                                        .length > 0 &&
                                        selectedMoodboard.materialResponseList.map(
                                            (fabric, index) => (
                                                <div
                                                    key={fabric.id}
                                                    className='fabric-single-item'
                                                >
                                                    <div className='fabric-image'>
                                                        {fabric?.documentPath ? (
                                                            <img
                                                                src={
                                                                    fabric?.documentPath
                                                                }
                                                                alt='item'
                                                                className='material-image'
                                                            />
                                                        ) : (
                                                            <img
                                                                src='/icons/fabric-default.svg'
                                                                alt=''
                                                                className='material-image'
                                                            />
                                                        )}

                                                        <span className='close'>
                                                            <CloseIcon
                                                                onClick={(e) =>
                                                                    removeFabricFromMoodboard(
                                                                        e,
                                                                        fabric.id
                                                                    )
                                                                }
                                                            />
                                                        </span>
                                                    </div>
                                                    <Tooltip
                                                        title={fabric.name}
                                                        placement={'top'}
                                                        arrow
                                                    >
                                                        <p>
                                                            {getShortName(
                                                                fabric.name,
                                                                fabricNameCharCount()
                                                            )}
                                                        </p>
                                                    </Tooltip>
                                                </div>
                                            )
                                        )}
                                </div>

                                {/* Suggested Fabrics */}
                                {/* it will be now based on api calls */}
                                <div className='fabric-filter top-border'>
                                    <p>
                                        Suggested <span>Fabrics</span>
                                    </p>
                                    <span>
                                        <FilterIcon
                                            onClick={(e) =>
                                                onFabricFilterClick(e)
                                            }
                                        />
                                    </span>
                                </div>
                                <div className='fabric-all-items'>
                                    {moodboardFabrics?.length > 0 &&
                                        moodboardFabrics.map(
                                            (fabric, index) => {
                                                return (
                                                    <div
                                                        key={fabric.materialId}
                                                        className='fabric-single-item'
                                                    >
                                                        <div className='fabric-image'>
                                                            {fabric?.docUrl ? (
                                                                <img
                                                                    src={
                                                                        fabric?.docUrl
                                                                    }
                                                                    alt='item'
                                                                    className='material-image'
                                                                />
                                                            ) : (
                                                                <img
                                                                    src='/icons/fabric-default.svg'
                                                                    alt=''
                                                                    className='material-image'
                                                                />
                                                            )}

                                                            <span
                                                                className={
                                                                    isFabricSelected(
                                                                        fabric.materialId
                                                                    )
                                                                        ? 'select show-icon'
                                                                        : 'select'
                                                                }
                                                            >
                                                                <TickIcon
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        addFabricToMoodboard(
                                                                            e,
                                                                            fabric.materialId
                                                                        )
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                        <Tooltip
                                                            title={fabric.name}
                                                            placement={'top'}
                                                            arrow
                                                        >
                                                            <p>
                                                                {getShortName(
                                                                    fabric.name,
                                                                    fabricNameCharCount()
                                                                )}
                                                            </p>
                                                        </Tooltip>
                                                    </div>
                                                )
                                            }
                                        )}
                                </div>
                                {selectedMoodboard?.collectionId &&
                                    moodboardFabrics.length === 0 && (
                                        <div className='text-center mt-32'>
                                            <p>No suggestion found</p>
                                            <p>
                                                Please search with different
                                                parameters
                                            </p>
                                        </div>
                                    )}
                                {fabricFilterView && (
                                    <FabricFilter
                                        toClose={onFabricFilterClick}
                                    />
                                )}
                                <button
                                    data-bs-toggle='modal'
                                    data-bs-target='#CollectionRequestConfirmationAction'
                                    ref={gotoCollectionRef}
                                    hidden
                                ></button>
                                <CollectionRequestConfirmation
                                    collectionId={
                                        selectedMoodboard?.collectionId
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MoodboardView
