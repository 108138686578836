import Http from '../../helpers/Http'
import { toast } from 'react-toastify'

export const getQouteList = (params) => {
    return Http.GET('getQouteList', params)
}

export const getQouteDetail = (id) => {
    return Http.GET('getQouteDetail', `/${id}`)
}

export const getAllMarket = () => {
    return Http.GET('getAllMarkets')
}

export const getAllCategory = () => {
    return Http.GET('getAllCategory')
}

export const uploadStyleDoc = (reqBody) => {
    return Http.POST('addInspiration', reqBody)
}

export const sendQuoteRequest = (reqBody) => {
    return Http.POST('postQuoteRequest', reqBody)
}

export const getCollectionById = (id) => {
    return Http.GET('getCollectionForUser', `/${id}?minimumNoOfProduct=1`)
}

export const getProductsOfCollectionById = (id) => {
    return Http.GET('getProductsOfCollectionUser', `/${id}`)
}

export const askedForQuotation = (reqBody) => {
    return Http.POST('askForQuotation', reqBody)
}

export const quotationApproved = (id) => {
    return Http.POST('approveOffer', '', `/${id}/approve`)
}

export const requestForRevision = (id) => {
    return Http.POST('rquestForRevision', '', `/${id}/request-for-revision`)
}

export const postTargetPrice = (reqBody) => {
    return Http.POST('updateTargetPrice', reqBody)
}

export const getCostingSheet = (quoteId, quantityWiseIds) => {
    return Http.GET(
        'getQouteDetail',
        `/${quoteId}/download-cost-sheet?quantityWiseIds=${quantityWiseIds}`
    )
}

export const addNewVariant = (quoteId, quoteItemId) => {
    return Http.POST(
        'getQouteDetail',
        '',
        `/${quoteId}/item/${quoteItemId}/add-variant`
    )
}

export const removeDuplicateitem = (quoteId, quoteItemId) => {
    return Http.DELETE('getQouteDetail', '', `/${quoteId}/item/${quoteItemId}`)
}

export const updateVariant = (reqBody, quoteId) => {
    return Http.PUT('getQouteDetail', reqBody, `/${quoteId}/update-variant`)
}

export const addQuantityWiseCost = (reqBody) => {
    return Http.POST('addQuantityWiseCost', reqBody)
}

export const fetchQuoteTeamMembers = async (id) => {
    try {
        const { data } = await Http.GET('getQouteDetail', `/${id}/members`)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const addMemberToQuote = async (id, userId) => {
    try {
        const { data } = await Http.POST(
            'getQouteDetail',
            {},
            `/${id}/add-members?memberIds=${userId}`
        )
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}
