import React from 'react'
import { capitalizeFirstAndLowercaseRest } from '../../helpers/Util'

const SetStyleSubCategory = ({ product }) => {
    const renderSetStyleSubCategory = () => {
        let subCategoryList = product?.setMemberProducts?.map(
            (item) => item.subCategory
        )
        return [...new Set(subCategoryList)]?.map((item, index) => (
            <li key={`subCategory_${index}`}>
                {capitalizeFirstAndLowercaseRest(item)}
            </li>
        ))
    }

    return renderSetStyleSubCategory()
}

export default SetStyleSubCategory
