import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg'
import { ReactComponent as FilterIcon } from '../../../assets/images/filter.svg'
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg'
import { ReactComponent as ArrowRightWhite } from '../../../assets/images/arror-right-white.svg'
import { useDispatch } from 'react-redux'
import { useCollections } from '../../redux_toolkit/Collection'
import { CollectionThunks } from '../../redux_toolkit/Collection/CollectionThunks'
import {
    GET_CATEGORY_LIST,
    GET_COLLECTION_LIST,
    GET_MARKET_LIST,
    GET_SEASON_LIST
} from '../../redux_toolkit/@types/thunk.types'
import CardForCollection from '../../common/CardForCollection'
import {
    generateCollectionFilterParams,
    isPageReachBottom
} from '../../helpers/Util'
import BriefCollection from './BriefCollection'
import CreateCollection from './CreateCollection'
import CollectionFilter from '../../common/filter/CollectionFilter'
import { FilterOptionThunks } from '../../redux_toolkit/V3/Filter/FilterOptionThunks'
import FilterChip from '../../common/filter/core/FilterChip'
import { FilterActionTypes, useFilter } from '../../redux_toolkit/V3/Filter'
import { SET_FILTER_FIELD } from '../../redux_toolkit/@types/action.types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useLoader } from '../../redux_toolkit/Loader'

const Collections = () => {
    const dispatch = useDispatch()
    const collectionStore = useCollections()
    const myStateRef = useRef({})
    const filter = useFilter()
    const [showSearch, setShowSearch] = useState(false)
    const [openCreateCollection, setOpenCreateCollection] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const isFavourite = searchParams.get('favourite')
    const activeTab = searchParams.get('activeTab')
    const searchRef = useRef()
    const globalLoader = useLoader()
    const isLoading = useRef(null)

    const setIsDataLoading = (data) => {
        isLoading.current = data
    }

    useEffect(() => {
        setIsDataLoading(globalLoader)
    }, [globalLoader])

    useEffect(() => {
        dispatch(FilterOptionThunks[GET_SEASON_LIST]())
        dispatch(FilterOptionThunks[GET_MARKET_LIST]())
        dispatch(FilterOptionThunks[GET_CATEGORY_LIST]())
    }, [])

    const generateParams = (page = 0, activeTab) => {
        let params = `?size=15&page=${page}`
        if (!isFavourite) {
            params += `&memberType=${activeTab}`
        } else {
            params += '&isFavourite=true'
        }
        return generateCollectionFilterParams(filter, params)
    }

    const changeActiveTab = (value) => {
        dispatch(
            CollectionThunks[GET_COLLECTION_LIST](
                generateParams(0, value),
                value
            )
        )
    }

    useEffect(() => {
        if (filter.name !== undefined) {
            dispatch(
                CollectionThunks[GET_COLLECTION_LIST](
                    generateParams(0, collectionStore.activeTab),
                    collectionStore.activeTab
                )
            )
        }
    }, [filter.name])

    useEffect(() => {
        myStateRef.current = collectionStore
    }, [collectionStore.collections])

    useEffect(() => {
        let selectedTab = activeTab || collectionStore.activeTab
        changeActiveTab(selectedTab || 'SHARED')
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [searchParams])

    const handleScroll = () => {
        if (isPageReachBottom()) {
            let { totalElements, currentPage, data } =
                myStateRef.current.collections
            let activeTabName = myStateRef.current.activeTab
            if (
                totalElements > 0 &&
                totalElements !== data.length &&
                !isLoading.current
            ) {
                dispatch(
                    CollectionThunks[GET_COLLECTION_LIST](
                        generateParams(currentPage + 1, activeTabName)
                    )
                )
            }
        }
    }

    const handleSearch = async (e) => {
        let data = {
            name: e.target.name,
            value: e.target.value,
            type: e.target.type
        }
        await dispatch(FilterActionTypes[SET_FILTER_FIELD](data))
    }

    useEffect(() => {
        searchRef.current.focus()
    }, [showSearch])

    return (
        <>
            <div className='filter'>
                <div className='flex flex-wrap 2xl:flex-nowrap 2xl:flex-row 2xl:items-center justify-between gap-2 2xl:gap-6'>
                    <div className='text-base 4xl:text-xl text-white-shade-200 flex flex-col sm:flex-row items-center gap-4 4xl:gap-8 w-full 2xl:w-[38%] 3xl:w-[36%] 4xl:w-[35%] order-2 2xl:!order-1'>
                        {!isFavourite && (
                            <>
                                <div
                                    onClick={() => changeActiveTab('SHARED')}
                                    className={`flex items-center gap-3 cursor-pointer text-primaryColor ${
                                        collectionStore.activeTab === 'SHARED'
                                            ? 'font-bold'
                                            : ''
                                    }`}
                                >
                                    <span className='font-bold text-xl leading-none inline-block mb-2'>
                                        .
                                    </span>
                                    <span>NITEX Collection</span>
                                </div>
                                <div
                                    onClick={() => changeActiveTab('OWNER')}
                                    className={`flex items-center gap-3 cursor-pointer text-primaryColor ${
                                        collectionStore.activeTab === 'OWNER'
                                            ? 'font-bold'
                                            : ''
                                    }`}
                                >
                                    <span className='font-bold text-xl leading-none inline-block mb-2'>
                                        .
                                    </span>
                                    <span>My Collection</span>
                                </div>
                                <div
                                    onClick={() => changeActiveTab('REQUESTED')}
                                    className={`flex items-center gap-3 cursor-pointer text-primaryColor ${
                                        collectionStore.activeTab ===
                                        'REQUESTED'
                                            ? 'font-bold'
                                            : ''
                                    }`}
                                >
                                    <span className='font-bold text-xl leading-none inline-block mb-2'>
                                        .
                                    </span>
                                    <span>Requested Collection</span>
                                </div>
                            </>
                        )}
                    </div>
                    <div className='flex flex-col xl:flex-row justify-end ml-auto w-full 2xl:w-[60%] 3xl:w-[62%] 4xl:w-[65%] pb-0 order-1 2xl:!order-2'>
                        <FilterChip />
                        <div className='flex items-center xl:mt-0 ml-auto gap-2'>
                            <input
                                ref={searchRef}
                                type='text'
                                className={`form-field bg-transparent border border-primaryColor w-full lg:w-[220px] 4xl:w-[300px] ${
                                    showSearch ? '' : 'hidden'
                                }`}
                                id='text'
                                placeholder='Search ...'
                                name='name'
                                value={filter.name ? filter.name : ''}
                                onChange={handleSearch}
                            />
                            <button
                                type='button'
                                className='btn bg-transparent p-2 px-4 4xl:px-5 font-normal border border-primaryColor text-primaryColor'
                                onClick={() => setShowSearch((prev) => !prev)}
                            >
                                <SearchIcon />
                            </button>
                            <button
                                type='button'
                                className='btn bg-transparent p-2 px-4 4xl:px-5 font-normal border border-primaryColor text-primaryColor'
                                data-bs-toggle='modal'
                                data-bs-target='#SortFilter'
                            >
                                <FilterIcon />
                            </button>
                            <div className='h-[60px] w-[1px] bg-primaryColor-shade-200 mx-1 sm:mx-3' />
                            {!isFavourite && (
                                <>
                                    {collectionStore.activeTab === 'SHARED' && (
                                        <button
                                            type='button'
                                            data-bs-toggle='modal'
                                            data-bs-target='#BriefCollection'
                                            className='btn flex justify-between items-center whitespace-nowrap'
                                        >
                                            <span>Brief Collection</span>
                                            <span className='ml-2'>
                                                <PlusIcon />
                                            </span>
                                        </button>
                                    )}
                                    {collectionStore.activeTab === 'OWNER' && (
                                        <button
                                            type='button'
                                            onClick={() =>
                                                setOpenCreateCollection(true)
                                            }
                                            className='btn flex justify-between items-center'
                                        >
                                            <span className='whitespace-nowrap'>
                                                Create Collection
                                            </span>
                                            <span className='ml-2'>
                                                <PlusIcon />
                                            </span>
                                        </button>
                                    )}
                                    {collectionStore.activeTab ===
                                        'REQUESTED' && (
                                        <button
                                            onClick={() =>
                                                navigate('/moodboard')
                                            }
                                            type='button'
                                            className='btn flex justify-between items-center'
                                        >
                                            <span className='whitespace-nowrap'>
                                                Go to Moodboards
                                            </span>
                                            <span className='ml-2'>
                                                <ArrowRightWhite />
                                            </span>
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-3 5xl:mt-4'>
                {collectionStore?.collections?.data?.length > 0 && (
                    <CardForCollection
                        collections={collectionStore.collections.data}
                        isFavouriteEnable={isFavourite === 'true'}
                        cardType={
                            collectionStore.activeTab === 'REQUESTED'
                                ? 'REQUESTED_COLLECTION'
                                : 'COLLECTION'
                        }
                    />
                )}
            </div>
            {collectionStore?.collections?.data?.length < 1 && (
                <div className='w-full'>
                    <div className='flex items-center justify-center h-full pt-52 5xl:pt-64'>
                        <div className='max-w-[582px] text-center relative'>
                            <h1 className='text-4xl 5xl:text-5xl text-primaryColor font-bold  mb-4'>
                                No collection found!
                            </h1>
                        </div>
                    </div>
                </div>
            )}
            <BriefCollection />
            {openCreateCollection && (
                <CreateCollection
                    closeModal={() => setOpenCreateCollection(false)}
                />
            )}
            <CollectionFilter />
        </>
    )
}

export default Collections
