import { getQouteDetail, getQouteList } from '../../services/Quote'
import { SET_QUOTE_DETAILS, SET_QUOTE_LIST, SET_QUOTE_LIST_PAGE_ACTIVE_TAB } from '../@types/action.types';
import { GET_QUOTE_DETAIL, GET_QUOTE_LIST } from '../@types/thunk.types'
import { openLoader,closeLoader } from '../Loader';
import { QouteActions } from './QuoteSlice';
import { toast } from 'react-toastify'

const QuoteThunks = {
    [GET_QUOTE_LIST]:(params, activeStatus) =>  async (dispatch, getState) => {
        dispatch(openLoader())
        try{
            const response = await getQouteList(params);
            dispatch({
                type: QouteActions[SET_QUOTE_LIST_PAGE_ACTIVE_TAB],
                payload: activeStatus

            })
            dispatch({
                type: QouteActions[SET_QUOTE_LIST],
                payload:{ ...response.data, merge: response.data.currentPage > 0 }

            })
            dispatch(closeLoader())
            return getState().quote;
        } catch({ response }) {
            toast.error(response.data.message)
            dispatch(closeLoader())
        }

    },
    [GET_QUOTE_DETAIL]:(id) =>  async (dispatch, getState) => {
        dispatch(openLoader())
        try{
            const response = await getQouteDetail(id);
            dispatch({
                type:QouteActions[SET_QUOTE_DETAILS],
                payload: response.data
            })
            dispatch(closeLoader())
            return getState().quote;
        } catch({ response }) {
            toast.error(response.data.message)
            dispatch(closeLoader())
        }

    }
}
export default QuoteThunks;
