import React from 'react'
import ModalContainer from '../../common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'

const DeleteFolderModal = ({ isShowModal, closeModal, removeFolder }) => {
    return (
        <>
            {isShowModal && (
                <ModalContainer className='create-folder-modal' onCloseModal={closeModal}>
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Delete folder
                                </h3>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={closeModal}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <p className='regular-18 black'>
                                Do you want to delete this folder?
                            </p>
                        </div>
                        <div className='common-popup-footer'>
                            <button className='button primary mr-3' onClick={removeFolder}>
                                <span>Yes</span>
                            </button>
                            <button className='button secondary' onClick={closeModal}>
                                <span>No</span>
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    )
}

export default DeleteFolderModal
