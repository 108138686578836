import React, { useEffect, useRef, useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { useDispatch, useSelector } from 'react-redux'

import {
    ADD_COLOR_CODE,
    GET_COLOR_CODES
} from '../redux_toolkit/@types/thunk.types'

import { ReactComponent as CloseIcon } from '../../assets/icons/old/close.svg'
import { ReactComponent as IconSearch } from '../../assets/icons/old/search-white.svg'
import MoodboardThunks from '../redux_toolkit/Moodboard/MoodboardThunks'

const ColorPicker = (props) => {
    const dispatch = useDispatch()
    const modalRef = useRef()
    const toggleView = props.toggleColorPicker
    const moodboardID = props.moodboardID

    const [activeArray, setActiveArray] = useState([])
    const [searchString, setSearchString] = useState('')

    const colorCodes = useSelector((state) => state.moodboard.colorCodes)

    const onColorClick = (e, colorID) => {
        setActiveArray(activeArray.concat(colorID))
        dispatch(MoodboardThunks[ADD_COLOR_CODE](moodboardID, colorID))
    }

    const searchColor = (search) => {
        dispatch(MoodboardThunks[GET_COLOR_CODES](moodboardID, search))
    }

    useEffect(() => {
        dispatch(MoodboardThunks[GET_COLOR_CODES](moodboardID))
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            toggleView()
        }
    }

    return (
        <>
            {/* Color popup */}
            <div
                className='common-color-popup z-10 left-0 right-auto top-[110px] lg:right-full lg:left-auto lg:top-0'
                ref={modalRef}
            >
                <div className='color-popup-header'>
                    <h5>Add color</h5>
                    <span className='cursor-pointer'>
                        <CloseIcon
                            onClick={(e) => {
                                e.preventDefault()
                                toggleView()
                            }}
                        />
                    </span>
                </div>
                <div className='common-color-popup-body'>
                    <div className='search-color'>
                        <div className='search-by-name'>
                            <input
                                type='text'
                                className='form-field'
                                placeholder='Search Color'
                                onChange={(e) => {
                                    setSearchString(e.target.value)
                                    searchColor(e.target.value)
                                }}
                            />
                            <span>
                                <IconSearch
                                    onClick={(e) => {
                                        e.preventDefault()
                                        searchColor(searchString)
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                    <div className='all-available-colors overflow-x-hidden custom-scroll overflow-y-auto h-[370px]'>
                        <p>Search Result</p>
                        <div className='common-color-list'>
                            {colorCodes?.length > 0 &&
                                colorCodes.map((color, index) => {
                                    return (
                                        <Tooltip
                                            title={
                                                <div className='py-2 px-3'>
                                                    <p className='text-white text-base leading-4'>
                                                        {color.code}
                                                    </p>
                                                    <span className='text-primaryColor-shade-100'>
                                                        {' '}
                                                        {color.name}
                                                    </span>
                                                </div>
                                            }
                                            placement='bottom'
                                            arrow
                                            key={color.id}
                                        >
                                            <span
                                                onClick={(e) =>
                                                    onColorClick(e, color.id)
                                                }
                                                className={`color-item ${
                                                    activeArray.includes(
                                                        color.id
                                                    )
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                style={{
                                                    background: color.hexCode
                                                }}
                                            />
                                        </Tooltip>
                                    )
                                })}
                        </div>
                    </div>
                    {/* <button
                        className='btn select-btn'
                        onClick={(e) => {
                            e.preventDefault()
                            toggleView()
                        }}
                    >
                        Select
                    </button> */}
                </div>
            </div>
        </>
    )
}

export default ColorPicker
