import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as UploadIcon } from '../../../icons/upload-gray.svg'

const UploadModal = ({ showUploadModal, setShowUploadModal }) => {
    return (
        <>
            <Modal
                show={showUploadModal}
                onHide={() => setShowUploadModal(false)}
                className='upload-modal'
            >
                <div className='common-popup'>
                    <div className='common-popup-header'>
                        <div className='popup-tilte text-center'>
                            <h3 className='semibold-16 mb-0'>
                                Upload image for search
                            </h3>
                        </div>
                    </div>

                    <div className='common-popup-body p-4'>
                        <label
                            className='upload-container text-center'
                            htmlFor='uploadImage'
                        >
                            <div className='text-center'>
                                <UploadIcon />
                                <p className='regular-16 mt-2 gray_300 mb-1'>
                                    Drag & drop image to upload here
                                </p>
                                <p className='regular-16 blue'>Upload Image</p>
                            </div>
                        </label>
                        <input
                            type='file'
                            className='hidden'
                            id='uploadImage'
                            multiple
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default UploadModal
