import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TimelinePoDetails from './core/TimelinePoDetails'
import TimelineActivityLog from './core/TimelineActivityLog'
import TimelineProductionDetails from './core/TimelineProductionDetails'
import { useDispatch } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import AddComment from './core/AddComment'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { fetchOrderInfo } from '../../redux_toolkit/Timeline/TimelineThunks'
import useDocumentTitle from '../../hooks/userDocumentTitle'

const Timeline = () => {
    const [addComment, setAddComment] = useState(false)
    const dispatch = useDispatch()
    const params = useParams()
    const timelineStore = useSelector((store) => store.timeline)
    const { orderInfo } = timelineStore
    let [searchParams] = useSearchParams()

    useDocumentTitle(`Nitex - Order - ${orderInfo?.name || ''}`)

    const setLoader = (value) => {
        if (value === true) {
            dispatch(openLoader())
        } else {
            dispatch(closeLoader())
        }
    }

    const toggleAddComment = () => {
        setAddComment(!addComment)
    }

    const generateParams = () => {
        return `${params.orderId}?page=0&size=15`
    }

    useEffect(() => {
        const paramProductId = searchParams.get('productId')
        dispatch(
            fetchOrderInfo(
                params.orderId,
                generateParams(),
                true,
                parseInt(paramProductId)
            )
        ).finally(() => setLoader(false))

        //This timeline-fixed class is adding for the order timeline page. Don't remove this code
        let headerNode = document.getElementsByTagName('header')
        headerNode[0].classList.add('timeline-fixed')

        return () => {
            headerNode[0].classList.remove('timeline-fixed')
        }
    }, [])

    return (
        <>
            {addComment && (
                <AddComment
                    toggleAddComment={toggleAddComment}
                    openModal={addComment}
                    setLoader={setLoader}
                />
            )}
            <div className='buyer-timeline-container'>
                <div className='timeline-row'>
                    <TimelinePoDetails setLoader={setLoader} />
                    <TimelineActivityLog
                        toggleAddComment={toggleAddComment}
                        setLoader={setLoader}
                    />
                    {orderInfo &&
                        !['PENDING', 'INITIALIZED'].includes(
                            orderInfo?.status
                        ) && (
                            <TimelineProductionDetails setLoader={setLoader} />
                        )}
                </div>
            </div>
        </>
    )
}

export default Timeline
