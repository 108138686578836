import React from 'react'
import Breadcrumb from '../common/Breadcrumb'
import { ReactComponent as FavouriteIcon } from '../../../icons/favorite.svg'
import { ReactComponent as PlayIcon } from '../../../icons/play.svg'
import { ReactComponent as CollectionFillIcon } from '../../../icons/collection-fill.svg'

const RangeList = () => {
    return (
        <>
            <Breadcrumb />
            <div className='flex flex-wrap justify-between'>
                <div>
                    <div className='actions'>
                        <button className='button secondary'>
                            <span className='mr-2'>Presentation View</span>
                            <PlayIcon />
                        </button>
                        <button className='button secondary mx-3'>
                            <span className='mr-2'>Add to fav</span>
                            <FavouriteIcon />
                        </button>
                        <button className='button icon-only boder-0'>
                            <CollectionFillIcon />
                        </button>
                    </div>
                    <div className='list-summary d-flex items-center mt-5'>
                        <p className='regular-14'>Tee (20)</p>
                        <p className='regular-14 mx-6'>Polo Shirt (20)</p>
                        <button className='button link regular-14'>Edit</button>
                    </div>
                </div>
                <div>
                    <button className='button secondary'>
                        <span>Reshuffle</span>
                    </button>
                    <button className='button secondary mx-3'>
                        <span>Regenerate</span>
                    </button>
                </div>
            </div>
            <div className='design-list-container pt-5 image-view'>
                {/* Please toggle "image-view" class for image view */}
                {Array.from({ length: 24 }).map((_, i) => (
                    <div
                        className='single-card-item'
                        onClick={() => setShowZoomModal(true)}
                    >
                        <div className='style-photo'>
                            <img
                                src='/images/single-design.png'
                                alt='design'
                                className='design-image'
                            />
                        </div>
                        <div className='style-info'>
                            <div className='info'>
                                <p className='regular-18 weight-500'>
                                    Stripe polo shirt
                                </p>
                                <ul className='common-list-component mt-1'>
                                    <li className='regular-14'>TEE</li>
                                    <li className='regular-14'>Woven</li>
                                </ul>
                            </div>
                            <div className='favorite'>
                                <button className='button secondary icon-only'>
                                    <FavouriteIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default RangeList
