import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ModalContainer from '../../common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg'
import { ReactComponent as FavouriteIcon } from '../../../assets/icons/favorite.svg'
import { ReactComponent as FavouritedIcon } from '../../../assets/icons/favorited.svg'
import { ReactComponent as ChatIcon } from '../../../assets/icons/chat.svg'
import {
    fetchCollectionMembersByProductId,
    fetchDesignDocs,
    fetchDesignInfoById
} from '../../services/v3/DesignLab/DesignLab'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import {
    capitalizeFirstAndLowercaseRest,
    convertNumberToUSFormat,
    getFileType,
    getIconByFileType,
    getShortName
} from '../../helpers/Util'
import { toast } from 'react-toastify'
import Tooltip from '@mui/material/Tooltip'
import {
    addProductBulkImages,
    callProductTracker,
    fetchSetInfoById
} from '../../services/CommonService'
import { CommonActions } from '../../redux_toolkit/V3/Common'
import {
    ADD_PRODUCT_TRACK_ID,
    CLEAR_PRODUCT_TRACK_ID
} from '../../redux_toolkit/@types/thunk.types'
import AddToFavourite from './AddToFavourite'
import PackSetOrTag from './PackOrSetTag'
import SetStyleSubCategory from './SetStyleSubCategory'
import SetStyleMainFabricType from './SetStyleMainFabricType'

const DesignRightSidePanel = ({
    openModal,
    onCloseModal,
    styleId,
    toggleFavouriteModal,
    isShowConversation = true,
    isHideBottom = false,
    setCollectionMembers,
    toggleConversation,
    requestFrom,
    styleInfo = null
}) => {
    const [featureImageDocs, setFeatureImageDocs] = useState({})
    const [showFavourite, setShowFavourite] = useState(false)
    const [selectedZoom, setSelectedZoom] = useState(null)
    const [setStyleInfo, setSetStyleIfo] = useState(null)
    const [designInfo, setDesignInfo] = useState(null)
    const [designDocs, setDesignDocs] = useState([])
    const [designId, setDesignId] = useState(null)
    const dispatch = useDispatch()
    const { id } = useParams()

    useEffect(() => {
        if (styleInfo?.isSet) {
            ;(async () => {
                try {
                    dispatch(openLoader())
                    const data = await fetchSetInfoById(styleInfo.id)
                    setSetStyleIfo(data)
                    setDesignId(data?.setMemberProducts?.[0]?.id || null)
                    dispatch(closeLoader())
                } catch (e) {
                    dispatch(closeLoader())
                }
            })()
        } else {
            setDesignId(styleId)
        }
    }, [styleId])

    const generateParams = () => {
        let params = designId
        if (requestFrom === 'FAVOURITE') {
            params += '?fromFolder=true'
            if (id && id !== 'my-favourite') {
                params += `&folderId=${id}`
            }
        }
        return params
    }

    const fetchDesignInfos = async () => {
        try {
            dispatch(openLoader())
            const productInfo = await fetchDesignInfoById(generateParams())
            const members = await fetchCollectionMembersByProductId(designId)
            const productDocs = await fetchDesignDocs(designId)
            setDesignInfo(productInfo)
            if (setCollectionMembers) setCollectionMembers(members)
            setDesignDocs(productDocs.physicalSampleResponse)
            setFeatureImageDocs(productDocs.featureImageDocResponse)
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    useEffect(() => {
        ;(async () => {
            try {
                if (designId !== null) {
                    fetchDesignInfos()
                    const data = await callProductTracker(designId)
                    dispatch(CommonActions[ADD_PRODUCT_TRACK_ID](data))
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
        })()
        return () => {
            dispatch(CommonActions[CLEAR_PRODUCT_TRACK_ID]())
        }
    }, [designId])

    const handleZoomView = (imageType) => {
        if (selectedZoom === imageType) {
            setSelectedZoom(null)
        } else {
            setSelectedZoom(imageType)
        }
    }

    const handleFavourite = async (bool) => {
        try {
            await toggleFavouriteModal(bool, false)
            setShowFavourite(bool)
            setDesignInfo((prev) => ({ ...prev, liked: bool }))
        } catch (e) {
            toast.error('Something went wrong')
        }
    }

    const isShowPresentationImage = () => {
        if (
            designDocs?.front?.docUrl &&
            designDocs?.back?.docUrl &&
            designDocs?.embellishment?.docUrl &&
            designDocs?.fabric?.docUrl
        ) {
            return true
        }
    }

    const renderFileInfo = () => {
        return designInfo?.allNotImageDocuments?.map((file, index) => {
            const fileType = getFileType(file.docUrl)
            return (
                <tr
                    className='even:bg-white-shade-100'
                    key={`file_row_${index}`}
                >
                    <td className='text-base font-normal px-6 py-4 whitespace-nowrap'>
                        <div
                            className='flex items-center cursor-pointer'
                            onClick={() => window.open(file.docUrl, '_blank')}
                        >
                            <span>
                                {fileType === 'IMAGE' ? (
                                    <img
                                        className='w-7 h-7 object-contain'
                                        src={file?.docUrl}
                                        alt=''
                                    />
                                ) : (
                                    <span>
                                        <img
                                            className='w-7 h-7 object-contain'
                                            src={getIconByFileType(fileType)}
                                            alt=''
                                        />{' '}
                                    </span>
                                )}
                            </span>
                            <Tooltip title={file.name} placement={'top'} arrow>
                                <span className='regular-14 black ml-2'>
                                    {getShortName(file.name, 35)}
                                </span>
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    const onUpload = async (e) => {
        try {
            dispatch(closeLoader())
            let files = [...e.target.files]
            let allFileList = []
            await files.forEach((file) => {
                if (file) {
                    let data = {
                        name: file?.name,
                        docMimeType:
                            file?.type === ''
                                ? 'application/octet-stream'
                                : file?.type,
                        documentType: 'PRODUCT_NOT_IMAGE',
                        base64Str: ''
                    }
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => {
                        data.base64Str = reader.result
                        allFileList.push(data)
                    }
                    reader.onerror = function (error) {
                        toast.error(error)
                    }
                }
            })
            setTimeout(async () => {
                await addProductBulkImages(allFileList, designId)
                const productInfo = await fetchDesignInfoById(generateParams())
                setDesignInfo(productInfo)
                dispatch(closeLoader())
            }, 100)
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const renderQtyAndLeadInfo = () => {
        return (
            <div className='right flex w-full sm:w-auto '>
                {designInfo?.turnAroundTime && (
                    <div className='single-info w-1/2 sm:w-auto sm:mr-10'>
                        <p className='regular-14 gray_300'>Lead time</p>
                        <h3 className='regular-18 weight-700'>
                            {designInfo?.turnAroundTime} days
                        </h3>
                    </div>
                )}
                {designInfo?.minimumOrderQuantity && (
                    <div className='single-info w-1/2 sm:w-auto sm:mr-10'>
                        <p className='regular-14 gray_300'>MOQ</p>
                        <h3 className='regular-18 weight-700'>
                            {designInfo?.minimumOrderQuantity} Unit
                        </h3>
                    </div>
                )}
                {designInfo?.buyerApprovedPrice && (
                    <div className='single-info'>
                        <p className='regular-14 gray_300'>Price</p>
                        <h3 className='regular-18 weight-700'>
                            $
                            {convertNumberToUSFormat(
                                designInfo?.buyerApprovedPrice
                            )}
                        </h3>
                    </div>
                )}
            </div>
        )
    }

    const getSetDesignDocuments = (memberList) => {
        let designDocuments = []
        memberList.forEach((member) => {
            let memberDocuments = member.designDocuments?.map((item) => ({
                ...item,
                productId: member.id
            }))
            designDocuments = [...designDocuments, ...memberDocuments]
        })
        return designDocuments
    }

    const renderSetStyles = () => {
        if (styleInfo?.isSet) {
            return (
                <div className='flex justify-between flex-wrap sm:flex-nowrap mb-6'>
                    <div className='set-image-tab'>
                        {getSetDesignDocuments(
                            setStyleInfo?.setMemberProducts || []
                        )?.map((item) => {
                            return (
                                <img
                                    key={`set_style_image_${item.id}`}
                                    src={item.docUrl}
                                    alt='style'
                                    className={
                                        item.productId === designId
                                            ? 'active'
                                            : ''
                                    }
                                    onClick={() => setDesignId(item.productId)}
                                />
                            )
                        })}
                    </div>
                    {renderQtyAndLeadInfo()}
                </div>
            )
        }
    }

    return (
        <>
            {openModal && (
                <ModalContainer
                    className='style-zoom-modal right-sidebar'
                    onCloseModal={onCloseModal}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    {capitalizeFirstAndLowercaseRest(
                                        setStyleInfo?.isSet
                                            ? setStyleInfo?.name
                                            : designInfo?.name
                                    )}
                                    &nbsp;&nbsp;
                                    {!setStyleInfo?.isSet &&
                                        designInfo?.isPack && (
                                            <PackSetOrTag tagName='Pack' />
                                        )}
                                    {setStyleInfo?.isSet && (
                                        <PackSetOrTag tagName='Set' />
                                    )}
                                </h3>
                                <ul className='common-list-component  sm:mt-2'>
                                    {designInfo?.subCategory &&
                                        !setStyleInfo?.isSet && (
                                            <li>
                                                {capitalizeFirstAndLowercaseRest(
                                                    designInfo?.subCategory
                                                )}
                                            </li>
                                        )}
                                    {setStyleInfo?.isSet && (
                                        <SetStyleSubCategory
                                            product={setStyleInfo}
                                        />
                                    )}
                                    {designInfo?.mainFabricType &&
                                        !setStyleInfo?.isSet && (
                                            <li>
                                                {capitalizeFirstAndLowercaseRest(
                                                    designInfo?.mainFabricType
                                                )}
                                            </li>
                                        )}
                                    {setStyleInfo?.isSet && (
                                        <SetStyleMainFabricType
                                            product={setStyleInfo}
                                        />
                                    )}
                                </ul>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={onCloseModal}
                            >
                                <CloseIcon />
                            </div>
                        </div>
                        <div className='common-popup-body !py-0 sm:!pb-4'>
                            {renderSetStyles()}
                            <div className='flex justify-between flex-wrap sm:flex-nowrap'>
                                <div className='left d-flex w-full sm:w-auto mb-3 sm:mb-0'>
                                    {designInfo?.referenceNumber && (
                                        <div className='single-info w-1/2 sm:w-auto sm:mr-10'>
                                            <p className='regular-14 gray_300'>
                                                Design no
                                            </p>
                                            <h3 className='regular-18 weight-700'>
                                                {designInfo?.referenceNumber}
                                            </h3>
                                        </div>
                                    )}
                                    {designInfo?.fabricName && (
                                        <div className='single-info w-1/2 sm:w-auto sm:mr-10'>
                                            <p className='regular-14 gray_300'>
                                                Fabric
                                            </p>
                                            <h3 className='regular-18 weight-700'>
                                                {capitalizeFirstAndLowercaseRest(
                                                    designInfo?.fabricName
                                                )}
                                            </h3>
                                        </div>
                                    )}

                                    {designInfo?.productGroup && (
                                        <div className='single-info w-1/2 sm:w-auto sm:mr-10'>
                                            <p className='regular-14 gray_300'>
                                                Department
                                            </p>
                                            <h3 className='regular-18 weight-700'>
                                                {capitalizeFirstAndLowercaseRest(
                                                    designInfo?.productGroup
                                                )}
                                            </h3>
                                        </div>
                                    )}
                                </div>
                                {!styleInfo?.isSet && renderQtyAndLeadInfo()}
                            </div>

                            {isShowPresentationImage() && (
                                <div
                                    className={`style-and-fabric ${
                                        selectedZoom !== null ? 'zoom-view' : ''
                                    }`}
                                >
                                    {designDocs?.front?.docUrl &&
                                        (selectedZoom === null ||
                                            selectedZoom === 'FRONT') && (
                                            <div
                                                className='single-image front'
                                                onClick={() =>
                                                    handleZoomView('FRONT')
                                                }
                                            >
                                                <img
                                                    src={
                                                        designDocs?.front
                                                            ?.docUrl
                                                    }
                                                />
                                            </div>
                                        )}
                                    {designDocs?.back?.docUrl &&
                                        (selectedZoom === null ||
                                            selectedZoom === 'BACK') && (
                                            <div
                                                className='single-image back'
                                                onClick={() =>
                                                    handleZoomView('BACK')
                                                }
                                            >
                                                <img
                                                    src={
                                                        designDocs?.back?.docUrl
                                                    }
                                                />
                                            </div>
                                        )}
                                    {designDocs?.fabric?.docUrl &&
                                        (selectedZoom === null ||
                                            selectedZoom === 'FABRIC') && (
                                            <div
                                                className='single-image fabric'
                                                onClick={() =>
                                                    handleZoomView('FABRIC')
                                                }
                                            >
                                                <img
                                                    src={
                                                        designDocs?.fabric
                                                            ?.docUrl
                                                    }
                                                />
                                            </div>
                                        )}
                                    {designDocs?.embellishment?.docUrl &&
                                        (selectedZoom === null ||
                                            selectedZoom ===
                                                'EMBELLISHMENT') && (
                                            <div
                                                className='single-image fabric'
                                                onClick={() =>
                                                    handleZoomView(
                                                        'EMBELLISHMENT'
                                                    )
                                                }
                                            >
                                                <img
                                                    src={
                                                        designDocs
                                                            ?.embellishment
                                                            ?.docUrl
                                                    }
                                                />
                                            </div>
                                        )}
                                </div>
                            )}
                            {!isShowPresentationImage() &&
                                featureImageDocs?.docUrl && (
                                    <div className='style-and-fabric zoom-view'>
                                        <div className='single-image'>
                                            <img
                                                src={featureImageDocs?.docUrl}
                                            />
                                        </div>
                                    </div>
                                )}
                        </div>

                        {!isShowPresentationImage() &&
                            designInfo?.referenceNumber && (
                                <div className='common-popup-footer'>
                                    <table className='table'>
                                        <thead className='bg-white'>
                                            <tr>
                                                <th className='flex justify-between'>
                                                    File
                                                    <div>
                                                        <input
                                                            id='designFileUpload'
                                                            type='file'
                                                            onChange={onUpload}
                                                            onClick={(e) =>
                                                                (e.target.value =
                                                                    null)
                                                            }
                                                            name='designFileUpload'
                                                            accept='*'
                                                            multiple
                                                            className='hidden'
                                                        />

                                                        <label
                                                            htmlFor='designFileUpload'
                                                            className='upload-blue-button flex items-center cursor-pointer'
                                                        >
                                                            Upload{' '}
                                                            <UploadIcon className='ml-2 w-6 h-6' />
                                                        </label>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>{renderFileInfo()}</tbody>
                                    </table>
                                </div>
                            )}

                        {!isHideBottom && (
                            <div className='common-popup-footer'>
                                {isShowConversation ? (
                                    <button
                                        className='button secondary icon-only mr-3'
                                        onClick={() => {
                                            if (toggleConversation)
                                                toggleConversation(
                                                    designInfo?.id
                                                )
                                        }}
                                    >
                                        <ChatIcon />
                                    </button>
                                ) : (
                                    <button
                                        className='button secondary'
                                        onClick={() =>
                                            handleFavourite(!designInfo?.liked)
                                        }
                                    >
                                        <span className='mr-2'>Favourite</span>
                                        {designInfo?.liked && (
                                            <FavouritedIcon />
                                        )}
                                        {!designInfo?.liked && (
                                            <FavouriteIcon />
                                        )}
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                    {showFavourite && (
                        <AddToFavourite
                            isVisible={showFavourite}
                            setIsVisible={setShowFavourite}
                        />
                    )}
                </ModalContainer>
            )}
        </>
    )
}

export default DesignRightSidePanel
