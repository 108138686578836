import React, { useState } from 'react'
import { ReactComponent as ArrowDownIcon } from '../../../icons/arrow-down.svg'
import { ReactComponent as MoreIcon } from '../../../icons/more.svg'
import { ReactComponent as UsersIcon } from '../../../icons/users.svg'
import ModalContainer from '../../../../app/common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../icons/close.svg'

const FavouriteList = () => {
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(true)
    const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false)

    const handleClose = () => {
        setShowCreateFolderModal(false)
        setShowDeleteFolderModal(false)
    }
    return (
        <>
            <div className='d-flex justify-between items-center py-4'>
                <button className='button primary'>Create new folder</button>
                <div className='common-dropdown'>
                    <button className='button secondary d-flex items-center round'>
                        <p className='regular-14 '>Sort</p>
                        <ArrowDownIcon />
                    </button>
                    <div className='dropdown-box dropdown-menu scroll-y-label hidden'>
                        <div className='list-items scroll-y-label'>
                            <div className='single-item'>
                                <p>Item 1</p>
                            </div>
                            <div className='single-item'>
                                <p>Item 2</p>
                            </div>
                            <div className='single-item'>
                                <p>Item 3</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div></div>
            <div className='grid grid-cols-4 gap-x-6 gap-y-6 mb-4'>
                <div className='collection-card'>
                    <div className='product-img-container'>
                        <div className='prev-img'>
                            <img src='/images/design-list-5.png' alt='style' />
                        </div>
                        <div className='prev-img-thumb'>
                            <div className='single-first'>
                                <img
                                    src='/images/design-list-1.png'
                                    alt='style'
                                />
                            </div>
                            <div className='single-second'>
                                <img
                                    src='/images/single-design.png'
                                    alt='style'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='more-options absolute right-4 top-3'>
                        <div className='common-dropdown from-right'>
                            <button className='button secondary icon-only bg-white'>
                                <MoreIcon />
                            </button>
                            <div className='dropdown-box dropdown-menu scroll-y-label'>
                                <div className='list-items scroll-y-label'>
                                    <div className='single-item'>
                                        <p className='whitespace-nowrap'>
                                            Edit folder name
                                        </p>
                                    </div>
                                    <div className='single-item'>
                                        <p>Delete folder</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='collection-content d-flex justify-between items-center'>
                        <div className='w-full'>
                            <input type='text' />
                            <div className='details-info'>
                                <ul className='common-list-component'>
                                    <li>10:23 PM</li>
                                    <li>23 May, 2024</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {Array.from({ length: 12 }).map((_, i) => (
                    <div className='collection-card'>
                        <div className='product-img-container'>
                            <div className='prev-img'>
                                <img
                                    src='/images/design-list-5.png'
                                    alt='style'
                                />
                            </div>
                            <div className='prev-img-thumb'>
                                <div className='single-first'>
                                    <img
                                        src='/images/design-list-1.png'
                                        alt='style'
                                    />
                                </div>
                                <div className='single-second'>
                                    <img
                                        src='/images/single-design.png'
                                        alt='style'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='collection-status'>
                            <span className='status pink-1 mr-2'>
                                <UsersIcon />
                            </span>
                            <span className='status purple-1 mr-2'>Range</span>
                        </div>
                        <div className='more-options absolute right-4 top-3'>
                            <div className='common-dropdown from-right'>
                                <button className='button secondary icon-only bg-white'>
                                    <MoreIcon />
                                </button>
                                <div className='dropdown-box dropdown-menu scroll-y-label'>
                                    <div className='list-items scroll-y-label'>
                                        <div className='single-item'>
                                            <p className='whitespace-nowrap'>
                                                Edit folder name
                                            </p>
                                        </div>
                                        <div className='single-item'>
                                            <p>Delete folder</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='collection-content d-flex justify-between items-center'>
                            <div>
                                <h3 className='collection-title'>
                                    My Favorites{' '}
                                    <span className='status blue-3'>New 2</span>
                                </h3>
                                <div className='details-info'>
                                    <ul className='common-list-component'>
                                        <li>10:23 PM</li>
                                        <li>23 May, 2024</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {showCreateFolderModal && (
                <ModalContainer
                    className='create-folder-modal'
                    // onCloseModal={() => toggleModel(null)}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Create new folder
                                </h3>
                                <p className='regular-12 gray_300'>
                                    Create new folder in favorite
                                </p>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <div className='form-group'>
                                <label className='regular-14 gray_300 mb-2'>
                                    Title
                                </label>

                                <input
                                    type='text'
                                    name='title'
                                    placeholder=''
                                />
                            </div>
                        </div>
                        <div className='common-popup-footer'>
                            <button className='button primary mr-3'>
                                <span>Create</span>
                            </button>
                            <button className='button secondary'>
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}

            {showDeleteFolderModal && (
                <ModalContainer
                    className='create-folder-modal'
                    // onCloseModal={() => toggleModel(null)}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Delete folder
                                </h3>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <p className='regular-18 black'>
                                Do you want to delete this folder?
                            </p>
                        </div>
                        <div className='common-popup-footer'>
                            <button className='button primary mr-3'>
                                <span>Yes</span>
                            </button>
                            <button className='button secondary'>
                                <span>No</span>
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    )
}

export default FavouriteList
