import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { FilterReducers } from './FilterReducers'

const initialState = {}

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: FilterReducers
})

export const FilterActionTypes = filterSlice.actions

export const useFilter = () => useSelector(state => state.filter)

export default filterSlice.reducer
