export const exploreDesignFields = {
    NEW_IN: true,
    CATEGORY: true,
    SEASON: true,
    DESIGN_LOOK: true,
    DEPARTMENT: true,
    FABRIC: true
}

export const imageSearchFilterFields = {
    CATEGORY: true,
    SEASON: true,
    FABRIC: true
}

export const sharedCollectionFields = {
    RECENTLY_SHARED: true,
    DESIGNERS_PICK: true,
    CATEGORY: true,
    SEASON: true
}

export const filterTypes = {
    NEW_IN: 'New In',
    AGE_GROUP: 'Age Group',
    DESIGN_LOOK: 'Design Look',
    CATEGORY: 'Category',
    SEASON: 'Season',
    FABRIC: 'Fabric',
    DEPARTMENT: 'Department'
}

const processIds = (filters, item, str) => {
    let tmpIds = filters[item]?.map((obj) => obj.id)
    str += `${tmpIds.join(',')}`
    return str
}

export const generateExploreDesignPageParams = (filters) => {
    let params = ''
    for (let item in filters) {
        if (Array.isArray(filters[item]) && filters[item].length > 0) {
            if (item === 'subCategories') {
                params += '&subCategoryIds='
                params = processIds(filters, item, params)
            }
            if (item === 'seasons') {
                params += '&seasons='
                params = processIds(filters, item, params)
            }
            if (item === 'ageGroups') {
                params += '&ageGroups='
                params = processIds(filters, item, params)
            }
            if (item === 'designLooks') {
                params += '&designLooks='
                params = processIds(filters, item, params)
            }
            if (item === 'fabrics') {
                params += '&fabricTypes='
                params = processIds(filters, item, params)
            }
            if (item === 'departments') {
                params += '&marketIds='
                params = processIds(filters, item, params)
            }
        }
        if (item === 'isNew') {
            params += `&isNew=${filters[item].value}`
        }
        if (item === 'isMoreExplore') {
            params += `&isMoreExplore=${filters[item]}`
        }
    }
    return params
}

export const generateSharedCollectionPageParams = (filters) => {
    let params = ''
    for (let item in filters) {
        if (Array.isArray(filters[item])) {
            if (item === 'subCategories') {
                params += '&subCategoryIds='
                params = processIds(filters, item, params)
            }
            if (item === 'seasons') {
                params += '&season='
                params = processIds(filters, item, params)
            }
        }
        if (item === 'designersPick') {
            params += `&designersPick=${filters[item].value}`
        }
        if (item === 'recentlyShared') {
            params += `&recentlyShared=${filters[item].value}`
        }
    }
    return params
}
