import React from 'react'
import { useNavigate } from 'react-router-dom'
import LandingLeftImage from '../../../assets/images/home/landing-left-new.png'
import LandingRightImage from '../../../assets/images/home/landing-right-new.png'

const Homepage = () => {
    const navigate = useNavigate()
    return (
        <div className='landing-layout'>
            <div className='one-half landing-left'>
                <img src={LandingLeftImage} alt='banner' />
                <div className='banner-content'>
                    <h3>Design Lab</h3>
                    <p>
                        Get access to thousands of exclusive designs every day
                    </p>
                </div>
            </div>
            <div
                className='one-half landing-right'
                onClick={() =>
                    navigate('/dashboard?active=QUOTES&status=RUNNING')
                }
            >
                <img src={LandingRightImage} alt='banner' />
                <div className='banner-content'>
                    <h3>My Dashboard (9)</h3>
                    <p>
                        Stay tuned with your techpacks, quotes,
                        samples, and orders
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Homepage
