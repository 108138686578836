import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'quill-mention'
import 'quill-mention/dist/quill.mention.css'

const atValues = [
    { id: 1, value: 'Fredrik Sundqvist' },
    { id: 2, value: 'Patrik Sjölin' }
]

const Editor = () => {
    const [text, setText] = useState('<div contenteditable=\'false\'>Hector oscar Pacheco</div>')

    const modules = {
        toolbar: [],
        mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@', '#'],
            source: function(searchTerm, renderItem, mentionChar) {
                let values;
                if (mentionChar === '@') {
                    values = atValues;
                }
                if (searchTerm.length === 0) {
                    renderItem(values, searchTerm);
                } else {
                    const matches = [];
                    for (let i = 0; i < values.length; i++)
                        if (
                            ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
                        )
                            matches.push(values[i]);
                    renderItem(matches, searchTerm);
                }
            }
        }
    };

    const handleProcedureContentChange = (content) => {
        // console.log(content);
    };

    return (
        <ReactQuill
            theme='snow'
            modules={modules}
            value={text}
            onChange={handleProcedureContentChange}
        >
            <div className='my-editing-area' />
        </ReactQuill>
    );
}

export default Editor;
