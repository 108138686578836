import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { TeamReducers } from './TeamReducers'

const initialState = {}

const teamSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: TeamReducers
})

export const TeamActionTypes = teamSlice.actions

export const useTeams = () => useSelector(state => state.team)

export default teamSlice.reducer
