import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg'
import UploadModal from './UploadModal'
import BreadCrumb from './BreadCrumb'
import { imageSearchFilterFields } from '../filter/filterUtil'
import FilterComponent from '../filter/FilterComponent'
import CropperComponent from './CropperComponent'
import {
    SearchActions,
    useActiveSearchImage,
    useCroppedImage,
    useSearchedImages,
    useSearchResults
} from '../../redux_toolkit/V3/Search'
import { useDispatch } from 'react-redux'
import {
    ADD_ACTIVE_SEARCH_IMAGE,
    ADD_CROPPED_IMAGE,
    ADD_SEARCH_RESULTS,
    CLEAR_SEARCH,
    GET_FAVOURITE_COUNT,
    TOGGLE_SEARCH_AS_FAVOURITE
} from '../../redux_toolkit/@types/thunk.types'
import { closeLoader, openLoader, useLoader } from '../../redux_toolkit/Loader'
import DesignCardView from './DesignCardView'
import { FilterOptionTypes } from '../../redux_toolkit/V3/Filter/FilterOptionReducers'
import {
    CLEAR_FILTER_FIELDS,
    SET_CATEGORY_LIST,
    SET_DEPARTMENTS_LIST,
    SET_FABRIC_LIST,
    SET_SEASON_LIST,
    SET_SELECTED_FAVOURITE
} from '../../redux_toolkit/@types/action.types'
import { FilterActionTypes, useFilter } from '../../redux_toolkit/V3/Filter'
import AddToFavourite from './AddToFavourite'
import { getUserPreference, isPageReachBottom } from '../../helpers/Util'
import {
    doFavourite,
    undoFavourite
} from '../../services/v3/Favourite/FavouriteService'
import { FavouriteThunks } from '../../redux_toolkit/Favourite/FavouriteThunks'
import { DoFavouriteActions } from '../../redux_toolkit/V3/DoFavourite'
import { useSearchParams } from 'react-router-dom'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import SearchModal from './SearchModal'
import { doProductSearch } from '../../services/CommonService'
import axios from 'axios'
import { BASE_URL } from '../../constant'
import { useGlobalState } from '../../redux_toolkit/V3/Common'

const ProductSearch = () => {
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [showFavourite, setShowFavourite] = useState(false)
    const [isShowSearch, setIsShowSearch] = useState(false)
    const [showSkeleton, setShowSkeleton] = useState(false)
    const [designs, setDesigns] = useState([])
    const trackId = useGlobalState()?.productTrackId
    const activeSearchImage = useActiveSearchImage()
    const searchedImages = useSearchedImages()
    const searchResults = useSearchResults()
    const [searchParams] = useSearchParams()
    const isLoading = useRef(null)
    const croppedImage = useCroppedImage()
    const searchKey = searchParams.get('search')
    const type = searchParams.get('type')
    const globalLoader = useLoader()
    const filterStore = useFilter()
    const dispatch = useDispatch()
    const scrollRef = useRef()

    useEffect(() => {
        isLoading.current = globalLoader
    }, [globalLoader])

    useEffect(() => {
        if (type === 'text') {
            ;(async () => {
                try {
                    dispatch(openLoader())
                    const data = await doProductSearch(
                        `?page=0&size=20&text=${encodeURI(searchKey)}`
                    )
                    await dispatch(
                        SearchActions[ADD_SEARCH_RESULTS]({
                            merge: false,
                            data: data
                        })
                    )
                    dispatch(closeLoader())
                } catch (e) {
                    dispatch(closeLoader())
                }
            })()
        }
    }, [searchKey, type])

    useEffect(() => {
        if (type === 'text') {
            scrollRef.current = searchResults
            const filteredDesigns = handleFilter(searchResults?.data)
            setDesigns(filteredDesigns)
        }
    }, [searchResults])

    useEffect(() => {
        return () => {
            dispatch(SearchActions[CLEAR_SEARCH]())
            dispatch(FilterActionTypes[CLEAR_FILTER_FIELDS]())
        }
    }, [])

    useEffect(() => {
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [searchKey, type])

    const handleScroll = async () => {
        if (isPageReachBottom() && type === 'text') {
            let { totalElements, currentPage, data } = scrollRef.current
            if (
                totalElements > 0 &&
                totalElements !== data.length &&
                !isLoading.current
            ) {
                try {
                    dispatch(openLoader())
                    const data = await doProductSearch(
                        `?page=${currentPage + 1}&size=20&text=${encodeURI(
                            searchKey
                        )}`
                    )
                    dispatch(
                        SearchActions[ADD_SEARCH_RESULTS]({
                            merge: true,
                            data: data
                        })
                    )
                    dispatch(closeLoader())
                } catch (e) {
                    dispatch(closeLoader())
                }
            }
        }
    }

    const onChangeCropHandler = async (base64String) => {
        await dispatch(
            SearchActions[ADD_CROPPED_IMAGE]({
                data: {
                    ...activeSearchImage,
                    base64Str: base64String
                }
            })
        )
    }

    const handleFilter = (products = []) => {
        let filteredDesigns = products
        if (products?.length > 0) {
            if (filterStore?.seasons?.length > 0) {
                let seasons = filterStore.seasons.map((item) => item.id)
                filteredDesigns = filteredDesigns.filter((item) =>
                    seasons.includes(item.season)
                )
            }
            if (filterStore?.fabrics?.length > 0) {
                let fabrics = filterStore.fabrics.map((item) => item.id)
                filteredDesigns = filteredDesigns.filter((item) =>
                    fabrics.includes(item.mainFabricType)
                )
            }
            if (filterStore?.subCategories?.length > 0) {
                let subCategories = filterStore.subCategories.map(
                    (item) => item.id
                )
                filteredDesigns = filteredDesigns.filter((item) =>
                    subCategories.includes(item.subCategoryResponse.id)
                )
            }
        }
        return filteredDesigns
    }

    useEffect(() => {
        const filteredDesigns = handleFilter(searchResults?.data)
        setDesigns(filteredDesigns.map((item) => ({ ...item })))
    }, [filterStore])

    const renderPreviousImages = () => {
        const onClickImage = async (item) => {
            await dispatch(
                SearchActions[ADD_ACTIVE_SEARCH_IMAGE]({
                    data: item
                })
            )
        }

        return searchedImages?.map((item, i) => {
            return (
                <img
                    key={`search_image_${i}`}
                    src={item.docUrl}
                    alt='image'
                    className={
                        activeSearchImage?.docUrl === item.docUrl
                            ? 'active'
                            : ''
                    }
                    onClick={() => onClickImage(item)}
                />
            )
        })
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        if (croppedImage !== null && croppedImage?.base64Str) {
            ;(async () => {
                try {
                    let docMimeType = croppedImage.base64Str
                        .split(';base64,')[0]
                        .split('data:')[1]
                    await dispatch(FilterActionTypes[CLEAR_FILTER_FIELDS]())
                    setShowSkeleton(true)
                    const { data } = await axios.post(
                        `${BASE_URL}/product/image-search`,
                        {
                            searchImage: {
                                name: croppedImage.name,
                                base64Str: croppedImage.base64Str,
                                docMimeType: docMimeType,
                                documentType: 'PRODUCT_IMAGE_SEARCH_DATA'
                            },
                            originalProductId: croppedImage.id,
                            top: 50
                        },
                        {
                            cancelToken: cancelToken.token
                        }
                    )
                    dispatch(
                        SearchActions[ADD_SEARCH_RESULTS]({
                            merge: false,
                            data: {
                                data: [
                                    ...JSON.parse(
                                        JSON.stringify(data.productResponseList)
                                    )
                                ]
                            }
                        })
                    )
                    setDesigns(
                        data.productResponseList.map((item) => ({ ...item }))
                    )
                    dispatch({
                        type: FilterOptionTypes[SET_CATEGORY_LIST],
                        payload: data.categoryList
                    })
                    dispatch({
                        type: FilterOptionTypes[SET_SEASON_LIST],
                        payload: data?.seasonList?.map((item) => ({
                            id: item.constant,
                            name: item.name
                        }))
                    })

                    dispatch({
                        type: FilterOptionTypes[SET_FABRIC_LIST],
                        payload: data?.fabricTypeList?.map((item) => ({
                            id: item.constant,
                            name: item.name
                        }))
                    })
                    dispatch({
                        type: FilterOptionTypes[SET_DEPARTMENTS_LIST],
                        payload: data?.departmentList?.map((item) => ({
                            id: item.constant,
                            name: item.name
                        }))
                    })
                    setShowSkeleton(false)
                } catch (e) {
                    if (e?.response?.status === 500) {
                        setShowSkeleton(false)
                    } else {
                        setShowSkeleton(true)
                    }
                }
            })()
        }
        return () => {
            cancelToken.cancel()
        }
    }, [croppedImage])

    const toggleIsFavourite = async (bool, designInfo) => {
        try {
            dispatch(openLoader())
            if (bool) {
                let folderId = getUserPreference()?.defaultFavouriteFolder?.id
                let params = `?productIds=${designInfo.id}`
                if (folderId) {
                    params += `&folderId=${folderId}`
                }
                if (trackId) params += `&tid=${trackId}`
                await doFavourite(params)
                await dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]())
            } else {
                await undoFavourite(designInfo.id)
            }
            dispatch(SearchActions[TOGGLE_SEARCH_AS_FAVOURITE](designInfo.id))
            if (bool) {
                dispatch(DoFavouriteActions[SET_SELECTED_FAVOURITE](designInfo))
                setShowFavourite(bool)
            }
            if (type === 'image') {
                let cloneDesigns = designs
                    .map((item) => ({ ...item }))
                    .map((item) => {
                        if (item.id === designInfo.id) {
                            item.liked = !item.liked
                        }
                        return item
                    })
                setDesigns(cloneDesigns)
            }
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const renderDesigns = () => {
        return designs?.map((item, i) => {
            return (
                <React.Fragment key={`single_item_${i}`}>
                    <DesignCardView
                        designInfo={item}
                        triggerIsFavourite={toggleIsFavourite}
                    />
                </React.Fragment>
            )
        })
    }

    const renderSkeleton = () => {
        return Array.from(Array(6).keys()).map((item) => {
            return (
                <img
                    src='/icons/skeleton.gif'
                    alt='product-skeleton'
                    key={`skeleton_${item}`}
                    className='w-full'
                />
            )
        })
    }

    const renderTextEmptyState = () => {
        if (type === 'image' && croppedImage === null) {
            return (
                <div className=' mt-48'>
                    <p className='text-xl gray_400 text-center font-bold'>
                        Please Upload Image Again!
                    </p>
                    <p className='text-base gray_400 text-center'>
                        Your search result will show here.
                    </p>
                </div>
            )
        } else if (
            type === 'text' &&
            designs?.length == 0 &&
            searchKey !== null &&
            searchKey?.length > 0
        ) {
            return (
                <div className='epmpty-state py-40 text-center m-auto'>
                    <h3 className='text-xl weight-700 gray_400'>
                        No results found for {`"${searchKey}"`}
                    </h3>
                    <p className='text-base gray_400'>
                        Please try a different search phrase
                    </p>
                </div>
            )
        }
    }

    const renderImageEmptyState = () => {
        if (type === 'image' && designs?.length == 0 && croppedImage !== null) {
            return (
                <div className='epmpty-state py-40 text-center m-auto'>
                    <h3 className='text-xl weight-700 gray_400'>
                        No results found
                    </h3>
                    <p className='text-base gray_400'>
                        Please try a different Image search
                    </p>
                </div>
            )
        }
    }

    return (
        <>
            <div className='py-3'>
                <BreadCrumb isShowBackButton={false} />
            </div>
            <div className='page-content'>
                {type === 'image' && (
                    <div className='py-3 search-page-top-info'>
                        <div className='upload-with-filter flex items-start'>
                            <button
                                className='button primary sm mr-3 upload-image'
                                onClick={() => setShowUploadModal(true)}
                            >
                                <span className='mr-2'>Upload Image</span>
                                <UploadIcon />
                            </button>
                            <FilterComponent
                                requiredFields={imageSearchFilterFields}
                                filteredDataLength={designs?.length}
                            />
                        </div>
                    </div>
                )}
                <div
                    className={`search-image-container grid ${
                        type === 'image' ? 'grid-cols-1 sm:grid-cols-2' : ''
                    } gap-4`}
                >
                    {type === 'image' && croppedImage !== null && (
                        <div>
                            {activeSearchImage?.docUrl && (
                                <div className='crop-image-container'>
                                    <CropperComponent
                                        imageSrc={activeSearchImage.docUrl}
                                        className='w-full h-full object-contain bg-image'
                                        callback={onChangeCropHandler}
                                    />
                                </div>
                            )}
                            <div className='prev-search mt-5'>
                                <h4 className='text-base lg:text-xl font-bold mb-4'>
                                    Previous Search
                                </h4>
                                <div className='flex flex-wrap gap-3 lg:gap-4'>
                                    {renderPreviousImages()}
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        {type === 'image' && croppedImage !== null && (
                            <div className='mb-5'>
                                <h3 className='text-2xl 2xl:text-4xl font-bold mb-1'>
                                    Visual Matches
                                </h3>
                                <p className='text-sm 2xl:text-base gray_300'>
                                    Showing similar results to your photo.
                                </p>
                            </div>
                        )}
                        {type === 'text' && (
                            <div>
                                <button
                                    className='button secondary flex items-center mt-2'
                                    onClick={() => setIsShowSearch(true)}
                                >
                                    Search
                                    <SearchIcon className='ml-2' />
                                </button>
                                <div className='mb-5'>
                                    <h3 className='text-2xl 2xl:text-4xl font-bold mb-1 mt-4 2xl:mt-6'>
                                        Search
                                    </h3>
                                    <p className='text-sm 2xl:text-base gray_300'>
                                        Showing results for{' '}
                                        <span className='font-bold black'>{`"${searchKey}"`}</span>
                                    </p>
                                </div>
                            </div>
                        )}
                        <div
                            className={`grid ${
                                type === 'image'
                                    ? 'grid-cols-1 lg:grid-cols-2'
                                    : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'
                            } gap-4`}
                        >
                            {showSkeleton ? renderSkeleton() : renderDesigns()}
                        </div>
                        {renderImageEmptyState()}
                    </div>
                    {showFavourite && (
                        <AddToFavourite
                            isVisible={showFavourite}
                            setIsVisible={setShowFavourite}
                        />
                    )}
                </div>
                {renderTextEmptyState()}
            </div>
            {showUploadModal && (
                <UploadModal setShowUploadModal={setShowUploadModal} />
            )}
            {isShowSearch && (
                <SearchModal closeModal={() => setIsShowSearch(false)} />
            )}
        </>
    )
}

export default ProductSearch
