import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import AllDesignList from './AllDesignList'
import { useSelector } from 'react-redux'
import { checkSingular } from '../../../helpers/Util'
import PoAttachedFiles from './PoAttachedFiles'
import AddPeople from '../../../v3/common/AddPeople'
import BreadCrumb from '../../../v3/common/BreadCrumb'
import { useParams } from 'react-router-dom'
import { fetchOrderInfo } from '../../../redux_toolkit/Timeline/TimelineThunks'
import ModalContainer from '../../../../app/common/modal/ModalContainer'
import AllProductionList from '../core/AllProductionList'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg'

const TimelinePoDetails = ({ setLoader }) => {
    const [memberList, setMemberList] = useState([])
    const [isTaskClicked, setIsTaskClicked] = useState(null)
    const timelineStore = useSelector((store) => store.timeline)
    const { orderId } = useParams()
    const { orderInfo } = timelineStore
    const dispatch = useDispatch()

    useEffect(() => {
        if (timelineStore.orderInfo?.orderMemberList) {
            setMemberList(timelineStore.orderInfo.orderMemberList)
        }
    }, [timelineStore])

    const getMemberList = () => {
        return memberList
            ?.filter((item) =>
                ['BUYER', 'ACCOUNT_MANAGER', 'PROJECT_MANAGER'].includes(
                    item.primaryUserType
                )
            )
            ?.map((item) => {
                return {
                    id: item?.memberId,
                    name: item?.memberName,
                    profilePicDocument: {
                        docUrl: item?.memberImage
                    },
                    ...item
                }
            })
    }

    return (
        <>
            <div className='one-third all-designs-destails'>
                <div className='order-timeline-breadcrumb'>
                    <BreadCrumb isShowBackButton={true} />
                </div>
                <div className='design-info-with-po common-blocks'>
                    <div className='design-title-with-date'>
                        <div className='data-with-round-progress flex justify-between'>
                            <div className='style-text-view'>
                                <span className='order-number'>
                                    {orderInfo?.orderRefNumber}
                                </span>
                                <div className='untis-price'>
                                    <span>
                                        {/* {orderInfo?.orderQuantity} Units */}
                                        {checkSingular(
                                            'Unit',
                                            orderInfo?.orderQuantity
                                        )}
                                    </span>
                                    <span>
                                        {/* {orderInfo?.totalStyles} Styles */}
                                        {checkSingular(
                                            'Style',
                                            orderInfo?.totalStyles
                                        )}
                                    </span>
                                </div>
                                <p className='price regular-14 mt-2 mb-4'>
                                    Price :{' '}
                                    <span className='weight-500 black'>
                                        ${orderInfo?.orderValue}
                                    </span>
                                </p>
                            </div>
                            {!['PENDING', 'INITIALIZED'].includes(
                                orderInfo?.status
                            ) && (
                                <div className='round-progress-68'>
                                    <div
                                        className='status pending progress'
                                        data-percentage={
                                            orderInfo?.percentageOfCompleteness
                                        }
                                    >
                                        <span className='progress-left'>
                                            <span className='progress-bar' />
                                        </span>
                                        <span className='progress-right'>
                                            <span className='progress-bar' />
                                        </span>
                                        <div className='progress-value'>
                                            <div className='task-value'>
                                                {
                                                    orderInfo?.percentageOfCompleteness
                                                }
                                                %
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className='members flex items-center'>
                            <AddPeople
                                members={getMemberList()}
                                requestFrom='ORDER'
                                callback={() =>
                                    dispatch(
                                        fetchOrderInfo(orderId, null, false)
                                    )
                                }
                            />
                        </div>
                        <div className='all-po-list mt-2'>
                            <span
                                className='regular-14 blue cursor-pointer'
                                data-bs-toggle='modal'
                                data-bs-target='#poAttachedFiles'
                            >
                                Attached documents
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className='tab-design-task-buttons gap-4 sm:gap-6'
                    id='task-status-hidden-modal'
                >
                    <div
                        className='one-half'
                        onClick={() => setIsTaskClicked('STYLE')}
                    >
                        <p className='text-base sm:text-lg weight-500 blue'>
                            Styles (
                            {timelineStore?.orderInfo?.orderProductList.length})
                        </p>
                    </div>
                    <div
                        className='one-half'
                        onClick={() => setIsTaskClicked('TNA')}
                    >
                        <p className='text-base sm:text-lg weight-500 blue'>
                            Time & Action / Critical Dates
                        </p>
                    </div>
                </div>
                <div className='tab-none'>
                    <AllDesignList setLoader={setLoader} />
                </div>
            </div>
            <PoAttachedFiles />
            {isTaskClicked && (
                <ModalContainer
                    className=''
                    onCloseModal={() => setIsTaskClicked(false)}
                >
                    <div className='common-popup bg-white'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div></div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={() => setIsTaskClicked(false)}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        {isTaskClicked === 'TNA' ? (
                            <AllProductionList setLoader={setLoader} />
                        ) : isTaskClicked === 'STYLE' ? (
                            <AllDesignList setLoader={setLoader} />
                        ) : null}
                    </div>
                </ModalContainer>
            )}
        </>
    )
}

export default TimelinePoDetails
