import { configureStore } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import MoodboardSliceReducer from './Moodboard'
import MaterialSliceReducer from './Home'
import LoaderReducer from './Loader'
import OrderReducer from './Order'
import TimelineReducer from './Timeline'
import CollectionReducer from './Collection'
import CollectionViewReducer from './Collection/CollectionViewSlice'
import FilterReducer from './V3/Filter'
import FilterOptionReducers from './V3/Filter/FilterOptionReducers'
import ColorReducer from './Colors'
import QuoteSliceReducer from './Quote/QuoteSlice'
import SampleReducers from './Sample/SampleSlice'
import TeamReducers from './Team'
import FavouriteReducers from './Favourite'
import FavouriteStyleReducers from './Favourite/FavouriteStyleSlice'
import CollectionCountReducers from './Collection/CollectionCountSlice'
import NotificationReducers from './Notifications'
import ConversationReducers from './Conversation'
import ExploreDesignReducer from './V3/DesignLab/ExploreDesignSlice'
import DoFavouriteReducer from './V3/DoFavourite/index'
import SharedCollectionReducer from './V3/SharedCollection/index'
import CollectionDetailsReducer from './V3/CollectionDetails/index'
import RangeReducer from './V3/Range/index'
import searchReducer from './V3/Search/index'
import AppGlobalStateReducer from './V3/Common/index'

// this is the configure store portion of the redux + redux-toolkit setup
// applied a middleware to supress the serialized data warning
// you can add more slices to the store here
const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        // getDefaultMiddleware({ serializableCheck: false }).concat(logger),   // dev server
        getDefaultMiddleware({ serializableCheck: false }),
    devTools: process.env.NODE_ENV !== 'production',
    reducer: {
        collection: CollectionViewReducer,
        collections: CollectionReducer,
        timeline: TimelineReducer,
        orders: OrderReducer,
        material: MaterialSliceReducer,
        moodboard: MoodboardSliceReducer,
        loader: LoaderReducer,
        filter: FilterReducer,
        filterOption: FilterOptionReducers,
        colors: ColorReducer,
        quote: QuoteSliceReducer,
        sample: SampleReducers,
        team: TeamReducers,
        favourite: FavouriteReducers,
        favouriteStyle: FavouriteStyleReducers,
        collectionCount: CollectionCountReducers,
        notifications: NotificationReducers,
        conversations: ConversationReducers,
        exploreDesigns: ExploreDesignReducer,
        doFavourite: DoFavouriteReducer,
        sharedCollections: SharedCollectionReducer,
        collectionDetails: CollectionDetailsReducer,
        ranges: RangeReducer,
        searchDesigns: searchReducer,
        appGlobalState: AppGlobalStateReducer
    }
})

export default store

// we wont call andy selector from compoennt, we will call this hook instead
export const useStoreSelector = () => useSelector((state) => state)
