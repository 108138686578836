import {
    SET_ALL_SHARED_COLLECTION,
    TOGGLE_COLLECTION_AS_FAVOURITE
} from '../../@types/thunk.types'

const SharedCollectionReducer = {
    [SET_ALL_SHARED_COLLECTION]: (state, { payload }) => {
        let styles = []
        if (payload.merge) {
            styles = [...state.response.data, ...payload.data.data]
        } else {
            styles = [...payload.data.data]
        }
        payload.data.data = styles
        state.response = payload.data
    },
    [TOGGLE_COLLECTION_AS_FAVOURITE]: (state, { payload }) => {
        state.response.data = state.response?.data?.map((item) => {
            if (item.id === payload) {
                item.isFavorite = !item.isFavorite
            }
            return item
        })
    }
}

export default SharedCollectionReducer
