import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import {
    SampleActionsType,
    useSampleSelector
} from '../../redux_toolkit/Sample/SampleSlice'
import {
    capitalizeFirstLetter,
    isPageReachBottom,
    onSetSampleDetailsBreadcrumb
} from '../../helpers/Util'
import SampleThunks from '../../redux_toolkit/Sample/SampleThunks'
import { GET_SAMPLE_LIST } from '../../redux_toolkit/@types/thunk.types'
import StyleImageCardView from '../../common/StyleImageCardView'
import { SET_SAMPLE_LIST_PAGE_ACTIVE_TAB } from '../../redux_toolkit/@types/action.types'
import { closeLoader, openLoader, useLoader } from '../../redux_toolkit/Loader'
import EmptyDashboard from '../../common/EmptyDashboard'

const SampleList = ({ total }) => {
    const sampleStore = useSampleSelector()
    const dispatch = useDispatch()
    const ActiveStatus = {
        running: 'PENDING',
        pending: 'INITIALIZED',
        completed: 'COMPLETED',
        cancelled: 'CANCELLED'
    }
    const sampleStoreRef = useRef({})
    // const searchRef = useRef({})

    const [showSearch, setShowSearch] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const activeStatusParam = new URLSearchParams(location.search).get('status')
    const globalLoader = useLoader()
    const isLoading = useRef(null)
    const setIsDataLoading = (data) => {
        isLoading.current = data
    }

    useEffect(() => {
        setIsDataLoading(globalLoader)
    }, [globalLoader])

    useEffect(() => {
        if (!sampleStore.activeTab) {
            handleChangeActiveStatus(
                ActiveStatus[activeStatusParam?.toLowerCase()]
            )
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
            dispatch({
                type: SampleActionsType[SET_SAMPLE_LIST_PAGE_ACTIVE_TAB],
                payload: ''
            })
        }
    }, [])

    useEffect(() => {
        sampleStoreRef.current = sampleStore
    }, [sampleStore.samples])

    // useEffect(() => {
    //     searchRef.current.focus()
    // }, [showSearch])

    const handleChangeActiveStatus = (activeStatus) => {
        window.scrollTo(0, 0)
        dispatch(
            SampleThunks[GET_SAMPLE_LIST](
                generateParams(0, activeStatus),
                activeStatus
            )
        )

        if (activeStatus === 'PENDING') {
            navigate('/dashboard?active=SAMPLE&status=RUNNING')
        } else if (activeStatus === 'INITIALIZED') {
            navigate('/dashboard?active=SAMPLE&status=PENDING')
        } else {
            navigate(`/dashboard?active=SAMPLE&status=${activeStatus}`)
        }
    }

    const generateParams = (page = 0, activeStatus, search = '') => {
        const statusMap = {
            [ActiveStatus.running]: activeStatus,
            [ActiveStatus.pending]: activeStatus,
            [ActiveStatus.completed]: `${activeStatus}`,
            [ActiveStatus.cancelled]: 'INITIALIZED&approvalStatus=CANCELLED'
        }
        const status = `?size=15&page=${page}&sort=id,desc&search=${search}${
            statusMap[activeStatus] ? `&status=${statusMap[activeStatus]}` : ''
        }`

        return status
    }

    // const handleSearch = (e) => {
    //     const { value } = e.target
    //     setSearchValue(value)
    //     const activeStatus = sampleStore.activeTab
    //     dispatch(
    //         SampleThunks[GET_SAMPLE_LIST](
    //             generateParams(0, activeStatus, value),
    //             activeStatus
    //         )
    //     )
    // }

    const handleScroll = () => {
        if (isPageReachBottom()) {
            const { totalElements, currentPage, data } =
                sampleStoreRef.current.samples
            const activeTab = sampleStoreRef.current.activeTab
            if (
                totalElements > 0 &&
                totalElements !== data.length &&
                !isLoading.current
            ) {
                dispatch(
                    SampleThunks[GET_SAMPLE_LIST](
                        generateParams(currentPage + 1, activeTab),
                        activeTab
                    )
                )
            }
        }
    }

    return (
        <>
            <div>
                <div>
                    <div className='quote-sample-state-tab'>
                        <div
                            onClick={() =>
                                handleChangeActiveStatus(ActiveStatus.running)
                            }
                            className={`single-tab ${
                                sampleStore.activeTab === ActiveStatus.running
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <h4 className='regular-14'>
                                Running ({total?.sample?.RUNNING})
                            </h4>
                        </div>
                        <div
                            onClick={() =>
                                handleChangeActiveStatus(ActiveStatus.pending)
                            }
                            className={`single-tab ${
                                sampleStore.activeTab === ActiveStatus.pending
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <h4 className='regular-14'>
                                Pending ({total?.sample?.PENDING})
                            </h4>
                        </div>
                        <div
                            onClick={() =>
                                handleChangeActiveStatus(ActiveStatus.completed)
                            }
                            className={`single-tab ${
                                sampleStore.activeTab === ActiveStatus.completed
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <h4 className='regular-14'>
                                {capitalizeFirstLetter(ActiveStatus.completed)}(
                                {total?.sample?.COMPLETED})
                            </h4>
                        </div>

                        <div
                            onClick={() =>
                                handleChangeActiveStatus(ActiveStatus.cancelled)
                            }
                            className={`single-tab ${
                                sampleStore.activeTab === ActiveStatus.cancelled
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <h4 className='regular-14'>
                                {capitalizeFirstLetter(ActiveStatus.cancelled)}(
                                {total?.sample?.CANCELLED})
                            </h4>
                        </div>
                    </div>
                </div>

                {sampleStore?.samples?.data &&
                    sampleStore?.samples?.data?.length > 0 && (
                        <div className='py-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4'>
                            {sampleStore?.samples?.data?.map((sample) => {
                                return (
                                    <StyleImageCardView
                                        key={sample.id}
                                        navigationUrl={() =>
                                            onSetSampleDetailsBreadcrumb(
                                                sample,
                                                activeStatusParam,
                                                navigate
                                            )
                                        }
                                        productFeatureImageList={
                                            sample.productDocuments
                                        }
                                        titleName={sample.title}
                                        referenceNumber={sample.refNo}
                                        noOfDesign={sample.noOfDesign}
                                        status={sample.approvalStatus}
                                    />
                                )
                            })}
                        </div>
                    )}
                {sampleStore?.samples?.data &&
                    sampleStore?.samples?.data?.length == 0 && (
                        <EmptyDashboard requesType='sample' />
                    )}
            </div>
        </>
    )
}

export default SampleList
