import {
    FETCH_ORDER_LIST,
    SET_DEFAULT_QUOTES,
    REMOVE_PRODUCT_FROM_ORDER
} from '../@types/action.types'

export const OrderReducers = {
    [FETCH_ORDER_LIST]: (state, { payload }) => {
        if (payload.merge === false) {
            state.activeTab = payload.activeTab
            state.countResponse = payload.countResponse

            state.orderResponse = payload.response
        } else {
            let prevOrderResponse = []
            if (state.orderResponse.data) {
                prevOrderResponse = state.orderResponse.data
            }
            if (payload.response.data) {
                prevOrderResponse = [
                    ...prevOrderResponse,
                    ...payload.response.data
                ]
            }
            state.activeTab = payload.activeTab
            state.countResponse = payload.countResponse
            state.orderResponse = {
                ...payload.response,
                data: prevOrderResponse
            }
        }
    },
    [SET_DEFAULT_QUOTES]: (state, { payload }) => {
        state.defaultQuotes = payload
    },
    [REMOVE_PRODUCT_FROM_ORDER]: (state, { payload }) => {
        state.defaultQuotes = state.defaultQuotes.filter(
            (item) => item.id !== payload
        )
    }
}
