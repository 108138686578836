import { createSlice } from '@reduxjs/toolkit'
import { SET_COLLECTION_COUNT } from '../@types/action.types'
import { useSelector } from 'react-redux'

const initialState = {}

const CollectionCountSlice = createSlice({
    name: 'collectionCount',
    initialState,
    reducers: {
        [SET_COLLECTION_COUNT]: (state, { payload }) => {
            state.counts = payload
        }
    }
})

export const CollectionCountTypes = CollectionCountSlice.actions

export const useCollectionCount = () => useSelector(state => state.collectionCount)

export default CollectionCountSlice.reducer
