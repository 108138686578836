import {
    SET_ALL_FAVOURITE_COUNT,
    SET_FAVOURITE_COUNT,
    UPDATE_FAVOURITE_COUNT_BY_TYPE
} from '../@types/action.types'

export const FavouriteReducers = {
    [SET_FAVOURITE_COUNT]: (state, { payload }) => {
        state.counts = payload
    },
    [SET_ALL_FAVOURITE_COUNT]: (state, { payload }) => {
        state.allCounts = payload
    },
    [UPDATE_FAVOURITE_COUNT_BY_TYPE]: (state, { payload }) => {
        if (payload.liked) {
            state.counts[payload.type] = state.counts[payload.type] + 1
        } else {
            state.counts[payload.type] =
                state.counts[payload.type] > 0
                    ? state.counts[payload.type] - 1
                    : 0
        }
    }
}
