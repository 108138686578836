import { toast } from 'react-toastify'
import Http from '../../../helpers/Http'
import { BASE_URL } from '../../../constant'

export const fetchExploreDesigns = async (params) => {
    try {
        const { data } = await Http.GET('getExploreDesigns', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}
export const fetchDesignInfoById = async (designId) => {
    try {
        const { data } = await Http.GET('getStyleInfoByStyleID', designId)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const fetchDesignDocs = async (designId) => {
    try {
        const { data } = await Http.GET('getGroupwiseDoc', designId)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const fetchCollectionMembersByProductId = async (designId) => {
    try {
        const { data } = await Http.GET(
            `${BASE_URL}/product/${designId}/collection-members`,
            '',
            true
        )
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}
