import { GET_ORDER_LIST, FETCH_DEFAULT_QUOTES } from '../@types/thunk.types'
import Http from '../../helpers/Http'
import { closeLoader, openLoader } from '../Loader'
import { toast } from 'react-toastify'
import { orderActionTypes } from './index'
import { FETCH_ORDER_LIST, SET_DEFAULT_QUOTES } from '../@types/action.types'

const OrderThunks = {
    [GET_ORDER_LIST]:
        (params, merge, activeTab) => async (dispatch, getState) => {
            await Http.GET('statusWiseCount').then(async (countResponse) => {
                await Http.GET('getOrderList', params).then((response) => {
                    dispatch({
                        type: orderActionTypes[FETCH_ORDER_LIST],
                        payload: {
                            response: response.data,
                            merge: merge,
                            activeTab: activeTab,
                            countResponse: countResponse.data
                        }
                    })
                })
            })
        },
    [FETCH_DEFAULT_QUOTES]: (ids) => async (dispatch, getState) => {
        try {
            dispatch(openLoader())
            const response = await Http.POST('fetchDefaultQuotes', ids)
            if (response) {
                dispatch({
                    type: orderActionTypes[SET_DEFAULT_QUOTES],
                    payload: response.data
                })
                dispatch(closeLoader())
                return getState()?.orders?.defaultQuotes
            }
        } catch (error) {
            dispatch(closeLoader())
            toast.error(error.response.data.message)
        }
    }
}

export default OrderThunks
