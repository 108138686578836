import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    ACTIVE_TAB_OBJ,
    ACTIVE_VALUE,
    DATA_LOADED_ONCE,
    ERROR_MESSAGE,
    FILTER_ACTION,
    HAS_NEXT,
    NOTIFICATION_FILTER_NAME,
    NOTIFICATION_TAB_NAME,
    NOTIFICATIONS,
    PAGE,
    UNSEEN_COUNT,
    UNSEEN_COUNT_OBJ
} from './util/Utils'
import Http, { routes } from '../../../helpers/Http'
import LoaderComponent from '../../../common/LoaderComponent'
import { NotificationItem } from './components/NotificationItem'
import { toast } from 'react-toastify'
import {
    NotificationActionTypes,
    useNotificationSelector
} from '../../../redux_toolkit/Notifications'
import { SET_NOTIFICATION_DATA } from '../../../redux_toolkit/@types/action.types'

const Notification = ({ toggleModel, isToggleModel }) => {
    const [showAndHideDropDown, setShowAndHideDropDown] = useState(true)
    const {
        notifications,
        sort,
        page,
        size,
        hasNext,
        unseenCount,
        dataLoadedOnce,
        notificationTabName,
        notificationFilterName,
        unseenCountObj
    } = useNotificationSelector()

    let [isLoading, setIsLoading] = useState(false)
    const notificationPanelRef = useRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!dataLoadedOnce) {
            getAllUnseenCount().then().catch()
        }
        return () => {}
    }, [])

    useEffect(() => {
        if (!dataLoadedOnce) {
            fetchNotification(0, ACTIVE_TAB_OBJ.all.enum).then().catch()
        }
        return () => {}
    }, [])

    const onScrollToEnd = () => {
        const wrappedElement = notificationPanelRef.current
        if (
            Math.abs(
                wrappedElement.scrollHeight -
                    wrappedElement.scrollTop -
                    wrappedElement.clientHeight
            ) < 5
        ) {
            if (hasNext && !isLoading) {
                fetchNotification(
                    page + 1,
                    getCategory(),
                    true,
                    notificationFilterName.isSeen,
                    notificationFilterName.isImportant
                )
                    .then()
                    .catch()
            }
        }
    }

    const markAllByFrontEndCategory = async () => {
        setIsLoading(true)
        let event = getCategory()
        let params = event ? `?eventList=${event}` : ''
        let tempNotification = []
        await Http.POST('markAllNotificationUnreadByCategory', {}, params)
            .then(({ data }) => {
                let newNotification = JSON.parse(JSON.stringify(notifications))
                tempNotification = newNotification.map((notification) => {
                    if (event === null || event === undefined) {
                        notification.isSeen = true
                    } else if (
                        notification.frontEndNotificationEvent === event
                    ) {
                        notification.isSeen = true
                    }
                    return notification
                })
                dispatch({
                    type: NotificationActionTypes[SET_NOTIFICATION_DATA],
                    payload: {
                        key: NOTIFICATIONS,
                        data: [...tempNotification]
                    }
                })
                getAllUnseenCount().then().catch()
                setIsLoading(false)
            })
            .catch(({ response }) => {
                setIsLoading(false)
                if (response && response.data && response.data.message) {
                    toast.error(response.data.message)
                } else {
                    toast.error(ERROR_MESSAGE)
                }
            })
    }

    const fetchNotification = async (
        page = 0,
        category,
        merge = true,
        isSeen,
        isImportant
    ) => {
        let params = {
            page,
            size,
            sort,
            frontEndNotificationEvents: category,
            isSeen: isSeen,
            isImportant: isImportant
        }
        setIsLoading(true)

        let tempNotification = []
        let result = []
        let currentPage
        let totalPage
        await Http.GET('getNotifications', params)
            .then(({ data }) => {
                if (data && data.data.length !== 0) {
                    result = data.data
                    currentPage = data.currentPage
                    totalPage = data.totalPages
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                }
            })
            .catch(({ response }) => {
                setIsLoading(false)
                if (response && response.data && response.data.message) {
                    toast.error(response.data.message)
                } else {
                    toast.error(ERROR_MESSAGE)
                }
            })
        if (merge) {
            tempNotification = [...notifications, ...result]
        } else {
            tempNotification = [...result]
        }

        dispatch({
            type: NotificationActionTypes[SET_NOTIFICATION_DATA],
            payload: {
                key: DATA_LOADED_ONCE,
                data: true
            }
        })
        dispatch({
            type: NotificationActionTypes[SET_NOTIFICATION_DATA],
            payload: {
                key: HAS_NEXT,
                data: currentPage < totalPage
            }
        })

        dispatch({
            type: NotificationActionTypes[SET_NOTIFICATION_DATA],
            payload: {
                key: NOTIFICATIONS,
                data: [...tempNotification]
            }
        })

        if (result.length) {
            dispatch({
                type: NotificationActionTypes[SET_NOTIFICATION_DATA],
                payload: {
                    key: PAGE,
                    data: page
                }
            })
        }
    }

    const markRead = async (item, isSeen) => {
        let tempNotification = []
        setIsLoading(true)
        let groupByKey = item?.groupByKey
        let params = groupByKey
            ? `?id=${item?.id}&groupByKey=${item?.groupByKey}`
            : `?id=${item?.id}`
        if (isSeen) {
            await Http.POST('markNotificationUnread', {}, params)
                .then(({ data }) => {
                    setIsLoading(false)
                    let newNotification = JSON.parse(
                        JSON.stringify(notifications)
                    )
                    tempNotification = newNotification.map((notification) => {
                        if (notification.id === item.id) {
                            notification.isSeen = false
                        }
                        return notification
                    })
                    dispatch({
                        type: NotificationActionTypes[SET_NOTIFICATION_DATA],
                        payload: {
                            key: NOTIFICATIONS,
                            data: [...tempNotification]
                        }
                    })
                    getAllUnseenCount().then().catch()
                })
                .catch(({ response }) => {
                    setIsLoading(false)
                    if (response && response.data && response.data.message) {
                        toast.error(response.data.message)
                    } else {
                        toast.error(ERROR_MESSAGE)
                    }
                })
        } else {
            await Http.POST('markNotificationRead', {}, params)
                .then(({ data }) => {
                    setIsLoading(false)
                    let newNotification = JSON.parse(
                        JSON.stringify(notifications)
                    )
                    tempNotification = newNotification.map((notification) => {
                        if (notification.id === item.id) {
                            notification.isSeen = true
                        }
                        return notification
                    })
                    dispatch({
                        type: NotificationActionTypes[SET_NOTIFICATION_DATA],
                        payload: {
                            key: NOTIFICATIONS,
                            data: [...tempNotification]
                        }
                    })
                    getAllUnseenCount().then().catch()
                })
                .catch(({ response }) => {
                    setIsLoading(false)
                    if (response && response.data && response.data.message) {
                        toast.error(response.data.message)
                    } else {
                        toast.error(ERROR_MESSAGE)
                    }
                })
        }
    }

    const markImportant = async (item, isImportant) => {
        let tempNotification = []
        setIsLoading(true)
        let groupByKey = item?.groupByKey
        let params = groupByKey
            ? `?id=${item?.id}&groupByKey=${item?.groupByKey}`
            : `?id=${item?.id}`
        if (isImportant) {
            await Http.POST('markNotificationImportant', {}, params)
                .then(({ data }) => {
                    setIsLoading(false)
                    let newNotification = JSON.parse(
                        JSON.stringify(notifications)
                    )
                    tempNotification = newNotification.map((notification) => {
                        if (notification.id === item.id) {
                            notification.isImportant = true
                        }
                        return notification
                    })
                    dispatch({
                        type: NotificationActionTypes[SET_NOTIFICATION_DATA],
                        payload: {
                            key: NOTIFICATIONS,
                            data: [...tempNotification]
                        }
                    })
                })
                .catch(({ response }) => {
                    setIsLoading(false)
                    if (response && response.data && response.data.message) {
                        toast.error(response.data.message)
                    } else {
                        toast.error(ERROR_MESSAGE)
                    }
                })
        } else {
            await Http.POST('markNotificationUnImportant', {}, params)
                .then(({ data }) => {
                    setIsLoading(false)

                    let newNotification = JSON.parse(
                        JSON.stringify(notifications)
                    )
                    tempNotification = newNotification.map((notification) => {
                        if (notification.id === item.id) {
                            notification.isImportant = false
                        }
                        return notification
                    })
                    dispatch({
                        type: NotificationActionTypes[SET_NOTIFICATION_DATA],
                        payload: {
                            key: NOTIFICATIONS,
                            data: [...tempNotification]
                        }
                    })
                })
                .catch(({ response }) => {
                    setIsLoading(false)
                    if (response && response.data && response.data.message) {
                        toast.error(response.data.message)
                    } else {
                        toast.error(ERROR_MESSAGE)
                    }
                })
        }
    }
    const getCategory = () => {
        return ACTIVE_TAB_OBJ[
            Object.keys(ACTIVE_TAB_OBJ).find(
                (value) => value === notificationTabName.toLowerCase()
            )
        ].enum
    }
    const getAllUnseenCount = async () => {
        await Http.GET('getAllUnseenNotificationCount')
            .then(({ data }) => {
                dispatch({
                    type: NotificationActionTypes[SET_NOTIFICATION_DATA],
                    payload: {
                        key: UNSEEN_COUNT_OBJ,
                        data: data
                    }
                })
                dispatch({
                    type: NotificationActionTypes[SET_NOTIFICATION_DATA],
                    payload: {
                        key: UNSEEN_COUNT,
                        data: data.all
                    }
                })
            })
            .catch(({ response }) => {
                if (response && response.data && response.data.message) {
                    toast.error(response.data.message)
                } else {
                    toast.error(ERROR_MESSAGE)
                }
            })
    }

    const getActiveTab = (obj) => {
        notificationPanelRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
        dispatch({
            type: NotificationActionTypes[SET_NOTIFICATION_DATA],
            payload: {
                key: NOTIFICATION_TAB_NAME,
                data: obj.value
            }
        })
        dispatch({
            type: NotificationActionTypes[SET_NOTIFICATION_DATA],
            payload: {
                key: NOTIFICATION_FILTER_NAME,
                data: FILTER_ACTION.all
            }
        })

        fetchNotification(0, obj.enum, false).then().catch()
    }
    const getCountNumber = (value, isHtmlClassName) => {
        if (value === undefined || value === null) return ''
        else return value > 0 ? (isHtmlClassName ? 'count' : value) : ''
    }

    const getFilterData = (actionValue) => {
        setShowAndHideDropDown(true)
        dispatch({
            type: NotificationActionTypes[SET_NOTIFICATION_DATA],
            payload: {
                key: NOTIFICATION_FILTER_NAME,
                data: actionValue
            }
        })
        fetchNotification(
            0,
            getCategory(),
            false,
            actionValue.isSeen,
            actionValue.isImportant
        )
            .then()
            .catch()
    }

    return (
        <LoaderComponent loading={isLoading}>
            <div
                className=' flex flex-col sm:flex-row bg-white shadow-lg w-full sm:w-[550px] notification-submenu scroll-y-label fixed sm:absolute top-[60px] sm:top-[8px] right-0 sm:right-[-94px] lg:right-0 z-[999]'
                id='notification-panel'
                ref={notificationPanelRef}
                onScroll={onScrollToEnd}
            >
                <div>
                    <div className='notification-section'>
                        <div className='notification-header'>
                            <h3 className='text-[28px] sm:text-[32px] black font-bold pb-3 sm:pb-5'>
                                Notifications
                            </h3>
                            <div className='notification-tab'>
                                <ul>
                                    <li
                                        className={
                                            notificationTabName ===
                                            ACTIVE_TAB_OBJ.all.value
                                                ? ACTIVE_VALUE
                                                : ''
                                        }
                                        onClick={() =>
                                            getActiveTab(ACTIVE_TAB_OBJ.all)
                                        }
                                    >
                                        {ACTIVE_TAB_OBJ.all.value}
                                        <span
                                            className={getCountNumber(
                                                unseenCountObj?.all,
                                                true
                                            )}
                                        >
                                            {getCountNumber(
                                                unseenCountObj?.all,
                                                false
                                            )}
                                        </span>
                                    </li>
                                    <li
                                        className={
                                            notificationTabName ===
                                            ACTIVE_TAB_OBJ.comments.value
                                                ? ACTIVE_VALUE
                                                : ''
                                        }
                                        onClick={() =>
                                            getActiveTab(ACTIVE_TAB_OBJ.comments)
                                        }
                                    >
                                        {ACTIVE_TAB_OBJ.comments.value}
                                        <span
                                            className={getCountNumber(
                                                unseenCountObj?.comment,
                                                true
                                            )}
                                        >
                                            {getCountNumber(
                                                unseenCountObj?.comment,
                                                false
                                            )}
                                        </span>
                                    </li>
                                    <li
                                        className={
                                            notificationTabName ===
                                            ACTIVE_TAB_OBJ.collection.value
                                                ? ACTIVE_VALUE
                                                : ''
                                        }
                                        onClick={() =>
                                            getActiveTab(
                                                ACTIVE_TAB_OBJ.collection
                                            )
                                        }
                                    >
                                        {ACTIVE_TAB_OBJ.collection.value}
                                        <span
                                            className={getCountNumber(
                                                unseenCountObj?.collection,
                                                true
                                            )}
                                        >
                                            {getCountNumber(
                                                unseenCountObj?.collection,
                                                false
                                            )}
                                        </span>
                                    </li>
                                    <li
                                        className={
                                            notificationTabName ===
                                            ACTIVE_TAB_OBJ.request.value
                                                ? ACTIVE_VALUE
                                                : ''
                                        }
                                        onClick={() =>
                                            getActiveTab(ACTIVE_TAB_OBJ.request)
                                        }
                                    >
                                        {ACTIVE_TAB_OBJ.request.value}
                                        <span
                                            className={getCountNumber(
                                                unseenCountObj?.request,
                                                true
                                            )}
                                        >
                                            {getCountNumber(
                                                unseenCountObj?.request,
                                                false
                                            )}
                                        </span>
                                    </li>
                                    <li
                                        className={
                                            notificationTabName ===
                                            ACTIVE_TAB_OBJ.order.value
                                                ? ACTIVE_VALUE
                                                : ''
                                        }
                                        onClick={() =>
                                            getActiveTab(ACTIVE_TAB_OBJ.order)
                                        }
                                    >
                                        {ACTIVE_TAB_OBJ.order.value}
                                        <span
                                            className={getCountNumber(
                                                unseenCountObj?.order,
                                                true
                                            )}
                                        >
                                            {getCountNumber(
                                                unseenCountObj?.order,
                                                false
                                            )}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className='notification-filter'>
                                <div className='dropdown read-unread-dropdown'>
                                    <button
                                        className='button text-only dropdown-toggle regular-12 pr-2'
                                        type='button'
                                        id='dropdownStyles'
                                        data-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                        onClick={() =>
                                            setShowAndHideDropDown(
                                                !showAndHideDropDown
                                            )
                                        }
                                    >
                                        <span className='pr-1'>
                                            {' '}
                                            {notificationFilterName.value}
                                        </span>
                                        <img
                                            src='/icons/down-arrow.svg'
                                            alt='down-arrow'
                                        />
                                    </button>
                                    <div
                                        className={
                                            showAndHideDropDown
                                                ? 'dropdown-menu hidden'
                                                : 'dropdown-menu'
                                        }
                                        aria-labelledby='dropdownStyles'
                                    >
                                        <ul>
                                            <li>
                                                <span
                                                    onClick={() =>
                                                        getFilterData(
                                                            FILTER_ACTION.important
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src='/icons/important-flag-red.svg'
                                                        alt='important'
                                                        className='mr-1'
                                                    />{' '}
                                                    Important{' '}
                                                </span>
                                            </li>
                                            <li>
                                                <span
                                                    onClick={() =>
                                                        getFilterData(
                                                            FILTER_ACTION.all
                                                        )
                                                    }
                                                >
                                                    Read &amp; Unread
                                                </span>
                                            </li>
                                            <li>
                                                <span
                                                    onClick={() =>
                                                        getFilterData(
                                                            FILTER_ACTION.read
                                                        )
                                                    }
                                                >
                                                    Read
                                                </span>
                                            </li>
                                            <li>
                                                <span
                                                    onClick={() =>
                                                        getFilterData(
                                                            FILTER_ACTION.unread
                                                        )
                                                    }
                                                >
                                                    Unread
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {notificationTabName !==
                                ACTIVE_TAB_OBJ.all.value ? (
                                    <button
                                        className='read-btn regular-12'
                                        onClick={() =>
                                            markAllByFrontEndCategory()
                                        }
                                    >
                                        {' '}
                                        Mark all as read
                                    </button>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className='notification-body'>
                            {notifications?.length > 0 ? (
                                <div
                                    className='all-single-items'
                                    onScroll={onScrollToEnd}
                                >
                                    {notifications.map((notification, i) => {
                                        return (
                                            <NotificationItem
                                                item={notification}
                                                markRead={markRead}
                                                markImportant={markImportant}
                                                toggleModel={toggleModel}
                                                isToggleModel={isToggleModel}
                                                key={'key' + i}
                                            />
                                        )
                                    })}
                                </div>
                            ) : (
                                <div className='empty'>
                                    <p>No notification found</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </LoaderComponent>
    )
}

export default Notification
