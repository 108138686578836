import React, { useEffect, useRef, useState } from 'react'
import Http from '../helpers/Http'
import LoaderComponent from './LoaderComponent'
import { toast } from 'react-toastify'
import { Tooltip } from '@mui/material'

const AssignMemberToOrderStage = ({
    memberList,
    taskInfo,
    onAddOrDeleteSuccess
}) => {
    const [showAddNewMemberModal, setShowMemberModal] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const AddNewMemberModalRef = useRef(null)
    const moreMember = 3

    useEffect(() => {
        document.addEventListener('click', handleOutside, { capture: true })
        return () => {
            document.removeEventListener('click', handleOutside)
        }
    }, [])

    const handleOutside = (event) => {
        if (
            AddNewMemberModalRef.current &&
            !AddNewMemberModalRef.current.contains(event.target)
        ) {
            setShowMemberModal(false)
        }
    }

    const isMemberAdded = (member) => {
        let flag = false
        taskInfo.members &&
            taskInfo.members.map((taskMember) => {
                if (taskMember.id === member.memberId) {
                    flag = true
                }
            })
        return flag
    }

    const addOrDeleteMember = async (member) => {
        setIsloading(true)
        await Http.POST('addDeleteMemberToTask', {
            stepId: taskInfo.id,
            memberIdList: [member.memberId]
        })
            .then(({ data }) => {
                if (data.success) {
                    setIsloading(false)
                    let flag = true
                    taskInfo.members = taskInfo.members.filter((memberItem) => {
                        if (memberItem.id === member.memberId) {
                            flag = false
                        }
                        return memberItem.id !== member.memberId
                    })
                    if (flag) {
                        taskInfo.members.push(member)
                    }
                    onAddOrDeleteSuccess()
                }
            })
            .catch(({ response }) => {
                setIsloading(false)
                if (response && response.data && response.data.message) {
                    toast.error(response.data.message)
                } else {
                    toast.error('Something went wrong! Please try again.')
                }
            })
    }

    return (
        <LoaderComponent loading={isLoading}>
            <div
                className='member-and-date flex justify-between items-center'
                onClick={() => setShowMemberModal(true)}
            >
                <div className='member'>
                    <div className='item'>
                        <div className='add-member-popup cursor-pointer'>
                            <div
                                className={`assign-member shadow ${
                                    showAddNewMemberModal ? 'open' : ''
                                }`}
                                ref={AddNewMemberModalRef}
                            >
                                <div className='title'>Assigned member</div>
                                <div className='member-list-container custom-scroll'>
                                    {taskInfo.members &&
                                    taskInfo.members.length ? (
                                        taskInfo.members.map((member) => {
                                            return (
                                                <div
                                                    key={member.id}
                                                    className='member-list'
                                                >
                                                    {member.profilePicDocument
                                                        ?.docUrl ? (
                                                        <img
                                                            src={
                                                                member
                                                                    .profilePicDocument
                                                                    ?.docUrl
                                                            }
                                                            alt=''
                                                        />
                                                    ) : (
                                                        <img
                                                            src='/images/pro_pic_default.svg'
                                                            alt=''
                                                        />
                                                    )}
                                                    <div className='name'>
                                                        {member.name}{' '}
                                                        {member.designation && (
                                                            <span className='tag'>
                                                                {member.userType ===
                                                                'BUYER'
                                                                    ? 'Buyer'
                                                                    : member.designation}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {taskInfo?.members && taskInfo?.members?.length > 0
                        ? taskInfo?.members?.map((member, idx) => {
                              return (
                                  <div key={member.id} className='item'>
                                      {idx < moreMember && (
                                          <Tooltip
                                              title={member?.name}
                                              placement='top'
                                              arrow
                                              className='cursor-pointer'
                                          >
                                              <span>
                                                  {member?.profilePicDocument ? (
                                                      <img
                                                          src={
                                                              member
                                                                  .profilePicDocument
                                                                  ?.docUrl
                                                          }
                                                          alt={
                                                              member.memberName
                                                          }
                                                          title={
                                                              member.memberName
                                                          }
                                                      />
                                                  ) : (
                                                      <img
                                                          src='/images/pro_pic_default.svg'
                                                          alt={
                                                              member.memberName
                                                          }
                                                          title={
                                                              member.memberName
                                                          }
                                                      />
                                                  )}
                                              </span>
                                          </Tooltip>
                                      )}
                                  </div>
                              )
                          })
                        : null}

                    {taskInfo?.members?.length > moreMember && (
                        <div className=' more-people cursor-pointer more'>
                            <span
                                onClick={() =>
                                    setShowMemberModal(!showAddNewMemberModal)
                                }
                            >
                                +{taskInfo?.members?.length - moreMember}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </LoaderComponent>
    )
}

export default AssignMemberToOrderStage
