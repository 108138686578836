import React, { useState } from 'react'

const Tooltip = ({ title, children }) => {
    const [isVisible, setIsVisible] = useState(false)

    const showTooltip = () => {
        setIsVisible(true)
    }

    const hideTooltip = () => {
        setIsVisible(false)
    }

    return (
        <div
            className='custom-tooltip-container'
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
        >
            {children}
            {isVisible && <div className='custom-tooltip'>{title}</div>}
        </div>
    )
}

export default Tooltip
