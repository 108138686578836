import React from 'react';

const ImageGridComponent = ({ item, whiteOverLay }) => {

    const renderImageList = () => {
        return item?.documentResponseList?.map(item => {
            return <img
                key={`images_${item.id}`}
                className='w-full mb-1'
                alt='collectionImage'
                src={item.docUrl}
            />
        })
    }

    const renderImages = () => {
        if(item?.moodboardResponse?.docUrl){
            return(
                <div className={'columns-1 gap-1 direction-rtl'}>
                    <img
                        key={`images_${item.id}`}
                        className='w-full mb-1'
                        alt='collectionImage'
                        src={item?.moodboardResponse?.docUrl}
                    />
                </div>
            )
        }else{
            return(
                <div className={'columns-3 gap-1 direction-rtl'}>
                    {renderImageList()}
                </div>
            )
        }
    }

    return (
        <div className={whiteOverLay ? 'image-grid-overlay-white' : 'image-grid-overlay'}>
            {renderImages()}
        </div>
    )
}

export default ImageGridComponent
