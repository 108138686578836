import React, { useState } from 'react';
import Editor from './Editor'

const ExpOne = () => {
    const [value, setValue] = useState('')

    return (
        <>
            <input type={'text'} onChange={(e) => setValue(e.target.value)} value={value}/>
            <Editor />
        </>
    )
}

export default ExpOne
