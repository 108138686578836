import React, { useState } from 'react'
import { ReactComponent as FavouriteIcon } from '../../../assets/icons/favorite.svg'
import { ReactComponent as FavouritedIcon } from '../../../assets/icons/favorited.svg'
import DesignRightSidePanel from './DesignRightSidePanel'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import ConversationRightSidePanel from './ConversationRightSidePanel'
import {
    capitalizeFirstAndLowercaseRest,
    SHOW_CONVERSATION_PAGES
} from '../../helpers/Util'
import PackSetOrTag from './PackOrSetTag'
import DesignImages from './DesignImages'
import SetStyleMainFabricType from './SetStyleMainFabricType'

const DesignCardView = ({ designInfo, triggerIsFavourite, requestFrom }) => {
    const [openConversationId, setOpenConversationId] = useState(null)
    const [collectionMembers, setCollectionMembers] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const dispatch = useDispatch()

    const onCloseModal = () => {
        setOpenModal(false)
    }

    const toggleFavouriteModal = async (bool, isShowFav = true) => {
        try {
            dispatch(openLoader())
            await triggerIsFavourite(bool, designInfo, isShowFav)
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    return (
        <>
            <div
                className={`${
                    !designInfo?.backImageDocument?.docUrl || designInfo?.isSet
                        ? 'single-card-item no-rotation'
                        : 'single-card-item'
                }`}
            >
                <div className='style-status'>
                    {designInfo?.isPack && <PackSetOrTag tagName='Pack' />}
                    {designInfo?.isSet && <PackSetOrTag tagName='Set' />}
                </div>

                <DesignImages
                    designInfo={designInfo}
                    callback={() => setOpenModal(true)}
                />
                <div className='style-info'>
                    <div
                        className='info cursor-pointer'
                        onClick={() => setOpenModal(true)}
                    >
                        <p className='regular-18 weight-500'>
                            {designInfo?.subCategoryResponse?.name &&
                                capitalizeFirstAndLowercaseRest(
                                    designInfo?.subCategoryResponse?.name
                                )}
                            {!designInfo?.subCategoryResponse?.name &&
                                designInfo?.subCategory &&
                                capitalizeFirstAndLowercaseRest(
                                    designInfo?.subCategory
                                )}
                            {designInfo?.isSet && designInfo?.name}
                        </p>
                        <ul className='common-list-component mt-1'>
                            {designInfo?.mainFabricType && (
                                <li className='regular-14'>
                                    {capitalizeFirstAndLowercaseRest(
                                        designInfo?.mainFabricType
                                    )}
                                </li>
                            )}
                            {designInfo?.referenceNumber &&
                                !designInfo?.isSet && (
                                    <li className='regular-14'>
                                        {designInfo?.referenceNumber}
                                    </li>
                                )}
                        </ul>
                        {designInfo?.isSet && (
                            <ul className='common-list-component mt-1'>
                                <SetStyleMainFabricType product={designInfo} />
                            </ul>
                        )}
                    </div>
                    <div className='favorite'>
                        <button
                            className='button secondary icon-only'
                            onClick={() =>
                                toggleFavouriteModal(!designInfo?.liked)
                            }
                        >
                            {!designInfo?.liked && <FavouriteIcon />}
                            {designInfo?.liked && <FavouritedIcon />}
                        </button>
                    </div>
                </div>
                {openModal && (
                    <DesignRightSidePanel
                        styleId={designInfo.id}
                        openModal={openModal}
                        onCloseModal={onCloseModal}
                        toggleFavouriteModal={(bool) =>
                            toggleFavouriteModal(bool, false)
                        }
                        toggleConversation={(id) => {
                            setOpenConversationId(id)
                        }}
                        setCollectionMembers={setCollectionMembers}
                        isShowConversation={SHOW_CONVERSATION_PAGES.includes(
                            requestFrom
                        )}
                        styleInfo={designInfo}
                    />
                )}
                {openConversationId && (
                    <ConversationRightSidePanel
                        type='PRODUCT_DEVELOPMENT_COMMENT'
                        id={openConversationId}
                        openModal={openConversationId}
                        onGoBack={() => setOpenConversationId(null)}
                        onCloseModal={() => {
                            setOpenConversationId(null)
                            onCloseModal()
                        }}
                        memberList={collectionMembers}
                    />
                )}
            </div>
        </>
    )
}

export default DesignCardView
