import React from 'react'
import { ReactComponent as IconFavourite } from '../../assets/icons/old/favourite.svg'
import { ReactComponent as Favourite } from '../../assets/icons/old/favouriteIcon.svg'
import { ReactComponent as IconRightArrow } from '../../assets/icons/old/blackRightArrow.svg'
import { useNavigate } from 'react-router-dom'
import { closeLoader, openLoader } from '../redux_toolkit/Loader'
import { toggleFavouriteStyleById } from '../modules/CommonApiCalls'
import { UPDATE_FAVOURITE_COUNT_BY_TYPE } from '../redux_toolkit/@types/action.types'
import { FavouriteActionTypes } from '../redux_toolkit/Favourite'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { FavouriteThunks } from '../redux_toolkit/Favourite/FavouriteThunks'
import { GET_ALL_FAVOURITE_COUNT } from '../redux_toolkit/@types/thunk.types'

const MaterialBasedProduct = ({ product, modifyFavourite }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const renderProductImage = () => {
        if (product?.designDocuments.length > 0) {
            return (
                <img
                    className='w-full h-full object-contain'
                    src={product.designDocuments[0]?.docUrl}
                    alt='material-based-style'
                />
            )
        } else {
            return (
                <img
                    className='w-full h-full object-contain'
                    src='/images/defaultProduct.svg'
                    alt='material-based-style'
                />
            )
        }
    }

    const toggleFavourite = async (type) => {
        await dispatch(openLoader())
        await toggleFavouriteStyleById(product.id, type)
            .then(() => {
                modifyFavourite(product.id)
                dispatch({
                    type: FavouriteActionTypes[UPDATE_FAVOURITE_COUNT_BY_TYPE],
                    payload: { type: 'style', liked: type === 'like' }
                })
                dispatch(FavouriteThunks[GET_ALL_FAVOURITE_COUNT]())
                dispatch(closeLoader())
            })
            .catch((error) => {
                dispatch(closeLoader())
                toast.error(error.response.data.message)
            })
    }

    return (
        <div className='collection-box'>
            <div className='overflow-hidden relative aspect-[9/10] p-4 bg-white'>
                <div
                    className='h-full'
                    onClick={() => navigate(`/style/details/${product.id}`)}
                >
                    {renderProductImage()}
                </div>
                <div className='w-[40px] h-[40px] bg-white border border-white-shade-100 flex justify-center items-center absolute right-[20px] top-[20px] cursor-pointer'>
                    <span
                        className=''
                        onClick={() =>
                            toggleFavourite(!product.liked ? 'like' : 'unlike')
                        }
                    >
                        {product?.liked && <IconFavourite />}
                        {!product?.liked && <Favourite />}
                    </span>
                </div>
            </div>
            <div
                className='py-4'
                onClick={() => navigate(`/collection/${product.collectionId}`)}
            >
                <h4 className='text-base 5xl:text-xl font-bold text-primaryColor mb-2 5xl:mb-3'>
                    {product?.name}
                </h4>
                <div className='flex  items-center gap-6 text-sm 5xl:text-base text-primaryColor-shade-100 cursor-pointer'>
                    <span>View Collection</span>
                    <span>
                        <IconRightArrow />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default MaterialBasedProduct
