import { createSlice } from '@reduxjs/toolkit'
import { ColorReducers } from './ColorReducers'
import { useSelector } from 'react-redux'

const initialState = {
    colors: [],
    selectedColors: []
}

const colorSlice = createSlice({
    name: 'colors',
    initialState,
    reducers: ColorReducers
})

export const ColorActionTypes = colorSlice.actions
export const useColors = () => useSelector(state => state.colors)
export default colorSlice.reducer
