import {
    GET_ALL_EXPLORE_DESIGNS,
    SET_ALL_EXPLORE_DESIGNS
} from '../../@types/thunk.types'
import { closeLoader, openLoader } from '../../Loader'
import { fetchExploreDesigns } from '../../../services/v3/DesignLab/DesignLab'
import { ExploreDesignAction } from './ExploreDesignSlice';

export const ExploreDesignThunks = {
    [GET_ALL_EXPLORE_DESIGNS]: (params, isExploreMore = false) => async (dispatch) => {
       try {
           dispatch(openLoader())
           const data = await fetchExploreDesigns(params)
           dispatch(ExploreDesignAction[SET_ALL_EXPLORE_DESIGNS]({
               data,
               merge: (data.currentPage > 0 || isExploreMore === true)
           }))
           dispatch(closeLoader())
       }catch (e){
           dispatch(closeLoader())
       }
    }
}
