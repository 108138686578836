import { createSlice } from '@reduxjs/toolkit'
import DoFavouriteReducers from './DoFavouriteReducers'
import { useSelector } from 'react-redux'


const initialState = {
    count: 0,
    selectedProduct: null
}

const DoFavourite = createSlice({
    name: 'doFavourite',
    initialState,
    reducers: DoFavouriteReducers
})

export const DoFavouriteActions = DoFavourite.actions

export const useFavourite = () => useSelector(state => state.doFavourite)

export default DoFavourite.reducer
