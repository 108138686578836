import React, { useState } from 'react'
import ChangePassword from '../../modules/profile/ChangePassword'
import Preferences from '../../modules/profile/Preferences'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'

const Settings = ({ onCloseModal }) => {
    const [activeTab, setActiveTab] = useState('CHANGE_PASSWORD')

    return (
        <div className='common-popup'>
            <div className='common-popup-header border-bottom-solid d-flex justify-content-between'>
                <ul className='flex items-center'>
                    <li
                        onClick={() => setActiveTab('CHANGE_PASSWORD')}
                        className={
                            activeTab === 'CHANGE_PASSWORD'
                                ? 'text-sm sm:text-lg weight-500 black mr-4 sm:mr-6 cursor-pointer'
                                : 'text-sm sm:text-lg gray_300 mr-6 cursor-pointer'
                        }
                    >
                        Change Password
                    </li>
                    <li
                        onClick={() => setActiveTab('EMAIL_NOTIFICATION')}
                        className={
                            activeTab === 'EMAIL_NOTIFICATION'
                                ? 'text-sm sm:text-lg weight-500 black cursor-pointer'
                                : 'text-sm sm:text-lg gray_300 cursor-pointer'
                        }
                    >
                        Email Notification
                    </li>
                </ul>
                <div
                    className='close-btn cursor-pointer'
                    onClick={onCloseModal}
                >
                    <CloseIcon />
                </div>
            </div>

            <div className='common-popup-body !p-0'>
                {activeTab === 'CHANGE_PASSWORD' ? (
                    <ChangePassword />
                ) : (
                    <Preferences />
                )}
            </div>
        </div>
    )
}

export default Settings
