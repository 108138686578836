import {
    SET_NOTIFICATION_DATA,
    SET_NOTIFICATION_REPLY_COMMENT
} from '../@types/action.types'

export const NotificationReducers = {
    [SET_NOTIFICATION_DATA]: (state, action) => {
        return {
            ...state,
            [action.payload.key]: action.payload.data
        }
    },
    [SET_NOTIFICATION_REPLY_COMMENT]: (state, action) => {
        let newNotification = JSON.parse(JSON.stringify(state.notifications))
        newNotification.map((obj) => {
            if (obj.id === action.payload.id) {
                obj.reply = action.payload.body
                obj.replyActorName = action.payload.actorName
            }
        })
        return {
            ...state,
            notifications: newNotification
        }
    }
}
