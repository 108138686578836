import Http from '../../../helpers/Http'
import { toast } from 'react-toastify'
import { getUserInfo } from '../../../helpers/Util'

export const addMemberToBrandTeam = async (userId) => {
    try {
        let teamId = getUserInfo()?.teamId
        const { data } = await Http.POST(
            'brandTeamMemberAction',
            {},
            `${teamId}/add-client?clientIds=${[userId]}`
        )
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const removeMemberFromBrandTeam = async (userId) => {
    try {
        let teamId = getUserInfo()?.teamId
        const { data } = await Http.DELETE(
            'brandTeamMemberAction',
            {},
            `${teamId}/client/${userId}`
        )
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const fetchAllBrandTeams = async (paramsData = null) => {
    try {
        let brandId = getUserInfo()?.brandResponse?.id
        let params = `${brandId}?withMemberCount=true&withMemberDetails=true`
        if (paramsData) {
            params += paramsData
        }
        const { data } = await Http.GET('brandTeamMemberAction', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}
