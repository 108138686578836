import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from '@mui/material'
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { getShortName } from '../../../helpers/Util'

const SelectComponent = ({
    options,
    selected,
    onSelect,
    label,
    required,
    onItemSearch,
    searchPlaceholder = 'Search'
}) => {
    const [isShowOption, setIsShowOption] = useState(false)
    const [focusedIndex, setFocusedIndex] = useState(-1)
    const [searchTerm, setSearchTerm] = useState(null)
    // const [isOpen, setIsOpen] = useState(false)

    const dropdownRef = useRef()
    const optionsRef = useRef([])
    const searchRef = useRef()

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('click', handleClickOutside)
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [isShowOption, focusedIndex, options])

    useEffect(() => {
        searchRef.current && searchRef.current.focus()
    }, [isShowOption])

    let results = !searchTerm
        ? options
        : options.filter((item) => {
              if (onItemSearch) {
                  return item.name
              } else {
                  if (item?.name) {
                      const itemValue = item.name.toString().toLowerCase()
                      const searchTermLowerCase = searchTerm.toLowerCase()
                      return itemValue.includes(searchTermLowerCase)
                  }
              }
          })

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsShowOption(false)
            setFocusedIndex(-1)
        }
    }

    const handleKeyDown = (e) => {
        if (isShowOption) {
            switch (e.key) {
                case 'ArrowUp':
                    e.preventDefault()
                    if (focusedIndex > 0) {
                        setFocusedIndex((prevIndex) => prevIndex - 1)
                        scrollIntoViewIfNeeded(focusedIndex - 1)
                    }
                    break
                case 'ArrowDown':
                    e.preventDefault()
                    if (focusedIndex < options.length - 1) {
                        setFocusedIndex((prevIndex) => prevIndex + 1)
                    }
                    scrollIntoViewIfNeeded(focusedIndex + 1)
                    break
                case 'Enter':
                    e.preventDefault()
                    if (focusedIndex !== -1) {
                        onSelect(results[focusedIndex])
                        setIsShowOption(false)
                        setFocusedIndex(-1)
                        setSearchTerm(null)
                    }
                    break
                default:
                    break
            }
        }
    }

    const scrollIntoViewIfNeeded = (index) => {
        if (index >= 0 && index < options.length) {
            const itemElement = optionsRef.current[index]
            if (itemElement) {
                itemElement.scrollIntoView({ block: 'nearest' })
            }
        }
    }

    const renderOptions = () => {
        return results?.map((item, index) => {
            return (
                <div
                    className={`single-item cursor-pointer ${
                        index === focusedIndex ? 'focused' : ''
                    }`}
                    id={`category_${item.id}`}
                    ref={(el) => (optionsRef.current[index] = el)}
                    key={`category_${item.id}`}
                    onClick={() => {
                        onSelect(item)
                        setIsShowOption(false)
                        setFocusedIndex(-1)
                        setSearchTerm(null)
                    }}
                >
                    <Tooltip title={item.name} placement='top' arrow>
                        <p>{getShortName(item.name, 20)}</p>
                    </Tooltip>
                </div>
            )
        })
    }
    const onSearchItem = (event) => {
        setSearchTerm(event.target.value)
    }

    return (
        <div
            className={`select-component ${required ? 'required' : ''}`}
            ref={dropdownRef}
        >
            <button
                className={`click-box click mb-1 ${
                    selected?.name ? 'selected' : ''
                }`}
                onClick={() => setIsShowOption((prev) => !prev)}
            >
                {selected?.name && (
                    <Tooltip title={selected.name} placement={'top'} arrow>
                        <p className='regular-14 '>
                            {getShortName(selected.name, 20)}
                        </p>
                    </Tooltip>
                )}
                {!selected?.name && <p className='regular-14'>{label}</p>}
                <ArrowDownIcon />
            </button>
            <div
                className={`dropdown-box dropdown-menu scroll-y-label ${
                    !isShowOption ? 'hidden' : ''
                }`}
            >
                <div className='search sticky top-0 bg-white p-2'>
                    <div className='absolute top-[18px] right-4 flex items-center pl-3 pointer-events-none'>
                        <SearchIcon />
                    </div>
                    <input
                        type='text'
                        placeholder={searchPlaceholder}
                        value={searchTerm || ''}
                        onChange={onSearchItem}
                        ref={searchRef}
                        className='block w-full p-3 pr-10 text-sm bg-transparent'
                    />
                </div>

                <div className='list-items scroll-y-label'>
                    {renderOptions()}
                </div>
            </div>
        </div>
    )
}

export default SelectComponent
