import React from 'react'
import GenerateSolidColors from './GenerateSolidColors'
import ImageGridComponent from './ImageGridComponent'
import GenerateTags from './GenerateTags'
import { ReactComponent as IconFavourite } from '../../assets/icons/old/favourite.svg'
import { ReactComponent as Favourite } from '../../assets/icons/old/favouriteIcon.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../redux_toolkit/Loader'
import { collectionActionTypes } from '../redux_toolkit/Collection'
import {
    REMOVE_COLLECTION_BY_ID,
    TOGGLE_FAVOURITE_COLLECTION,
    UPDATE_FAVOURITE_COUNT_BY_TYPE
} from '../redux_toolkit/@types/action.types'
import { toggleFavouriteCollectionById } from '../modules/CommonApiCalls'
import { toast } from 'react-toastify'
import { FavouriteActionTypes } from '../redux_toolkit/Favourite'
import { FavouriteThunks } from '../redux_toolkit/Favourite/FavouriteThunks'
import { GET_ALL_FAVOURITE_COUNT } from '../redux_toolkit/@types/thunk.types'

const CardForCollection = ({
    collections,
    toggleFavourite,
    isFavouriteEnable,
    cardType
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleFavourite = async (id, type) => {
        if (toggleFavourite) {
            toggleFavourite(id, type)
        } else {
            await dispatch(openLoader())
            await toggleFavouriteCollectionById(id, type)
                .then(() => {
                    dispatch({
                        type: collectionActionTypes[
                            TOGGLE_FAVOURITE_COLLECTION
                        ],
                        payload: id
                    })
                    if (isFavouriteEnable) {
                        dispatch({
                            type: collectionActionTypes[
                                REMOVE_COLLECTION_BY_ID
                            ],
                            payload: id
                        })
                    }
                    dispatch({
                        type: FavouriteActionTypes[
                            UPDATE_FAVOURITE_COUNT_BY_TYPE
                        ],
                        payload: { type: 'collection', liked: type === 'like' }
                    })
                    dispatch(FavouriteThunks[GET_ALL_FAVOURITE_COUNT]())
                    dispatch(closeLoader())
                })
                .catch((error) => {
                    dispatch(closeLoader())
                    toast.error(error.response.data.message)
                })
        }
    }

    return collections?.map((item) => {
        return (
            <div className='pb-4' key={`collections_${item.id}`}>
                <div className='collection-box grid grid-cols-1 md:grid-cols-2 bg-white'>
                    <div
                        className='p-6 xl:p-10 relative flex items-center'
                        onClick={() => navigate(`/collection/${item.id}`)}
                    >
                        <div>
                            <div className='flex flex-wrap gap-3'>
                                <GenerateTags
                                    isViewed={item.isViewed}
                                    item={item}
                                    cardType={cardType}
                                />
                            </div>
                            <h1 className='text-2xl xl:text-px28 4xl:text-4xl text-primaryColor font-bold mt-3 md:mt-6 mb-4 md:mb-8 xl:!leading-[39px] 4xl:leading-[54px]'>
                                {item?.name}
                            </h1>
                            {item?.numOfDesign > 0 && (
                                <div className='flex items-center text-base 4xl:text-xl text-primaryColor gap-3 md:gap-5'>
                                    {item.isNitexCollection && (
                                        <span>Designed by NITEX</span>
                                    )}
                                    {item.isNitexCollection && (
                                        <span className='leading-none inline-block mb-2'>
                                            .
                                        </span>
                                    )}
                                    <span>{item?.numOfDesign} Styles</span>
                                </div>
                            )}
                            <div className='color-list flex gap-1 mt-10 md:mt-16'>
                                <GenerateSolidColors
                                    colors={item.colorResponseList}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='overflow-hidden relative h-[360px] tab:h-[432px] xl:!h-[524px] pt-0 md:pt-4 p-4 bg-white'>
                        <div onClick={() => navigate(`/collection/${item.id}`)}>
                            <ImageGridComponent
                                whiteOverLay={true}
                                item={item}
                            />
                        </div>
                        {item?.numOfDesign > 0 && (
                            <div
                                className='w-[40px] h-[40px] bg-white border border-white-shade-100 flex justify-center items-center absolute right-[35px] top-[35px] cursor-pointer'
                                onClick={() =>
                                    handleFavourite(
                                        item.id,
                                        item.isFavorite ? 'unlike' : 'like'
                                    )
                                }
                            >
                                <span className=''>
                                    {item.isFavorite && <IconFavourite />}
                                    {!item.isFavorite && <Favourite />}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    })
}

export default CardForCollection
