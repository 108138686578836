import React, { useState } from 'react'
import ModalContainer from '../../../../app/common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../icons/close.svg'
import { ReactComponent as ChatIcon } from '../../../icons/chat.svg'
import { ReactComponent as UploadIcon } from '../../../icons/upload.svg'
import { ReactComponent as PlusSquareIcon } from '../../../icons/plus-square.svg'
import { ReactComponent as DeleteIcon } from '../../../icons/delete.svg'
import SelectComponent from '../../../../app/v3/range/components/SelectComponent'

const MyDashboardUi = () => {
    const [showUpoloadDesignModal, setShowUpoloadDesignModal] = useState(false)
    const [showAskQuoteModal, setShowAskQuoteModal] = useState(false)
    const [showAskSampleModal, setShowAskSampleModal] = useState(false)

    const handleClose = () => {
        setShowUpoloadDesignModal(false)
    }
    return (
        <>
            <div className='flex justify-between mt-10'>
                <div className='quotes-sample-order-tab'>
                    <ul>
                        <li className='active'>
                            <h4 className='regular-16'>Quotes</h4>
                            <p className='regular-12'>Running: 0</p>
                        </li>
                        <li>
                            <h4 className='regular-16'>Sample</h4>
                            <p className='regular-12'>Running: 0</p>
                        </li>
                        <li>
                            <h4 className='regular-16'>Order</h4>
                            <p className='regular-12'>Running: 0</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <button className='button primary'>
                        Ask for Quote/Sample
                    </button>
                </div>
            </div>
            <div className='tab-contents'>
                <div className='quote-sample-epmpty-state py-40 text-center max-w-[600px] m-auto'>
                    <h3 className='regular-20 weight-700 gray_400 mb-2'>
                        No quote request found
                    </h3>
                    <p className='regular-12 gray_300'>
                        Lorem ipsum dolor sit amet, usu cu esse possit
                        referrentur, at eam falli deterruisset. No duo populo
                        animal noluisse, enim deterruisset eos et. An mundi
                        noster pri, ea eos quas expetendis scripserit, affert
                        gubergren dissentias et cum.
                    </p>
                    <div className='mt-6'>
                        <button
                            class='button primary sm'
                            onClick={(e) => setShowUpoloadDesignModal(true)}
                        >
                            Ask for Quote/Sample
                        </button>
                        <button class='button secondary sm ml-3'>
                            Visit Design Lab
                        </button>
                    </div>
                </div>
            </div>

            {/* Upload design modal */}
            {showUpoloadDesignModal && (
                <ModalContainer
                    className='upload-design-modal right-sidebar'
                    // onCloseModal={() => toggleModel(null)}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h2 className='regular-28 weight-700 mb-0'>
                                    Ask for Quote/Sample
                                </h2>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <div>
                                <h3 className='regular-20 weight-700'>
                                    Step 1: Upload Design
                                </h3>
                                <p className='regular-12 gray-300'>
                                    Please share your design with the Nitex team
                                </p>
                            </div>
                            <div className='form-group pt-3 max-w-[432px]'>
                                <div className='file'>
                                    <input id='input-file' type='file' />
                                    <label
                                        htmlFor='input-file'
                                        className='w-full justify-between'
                                    >
                                        <span className='mr-4'>
                                            Upload Techpack
                                        </span>
                                        <UploadIcon />
                                    </label>
                                </div>
                            </div>

                            <table className='table mt-8'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Techpack</th>
                                        <th>Style Name</th>
                                        <th>Market</th>
                                        <th>Category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-center w-[72px] max-w-[72px]'>
                                            <span className='cursor-pointer'>
                                                <DeleteIcon />
                                            </span>
                                        </td>
                                        <td>
                                            <span className='regular-14 black'>
                                                techpack.png
                                            </span>
                                        </td>
                                        <td>ZLMKWT-SG-0010789/3</td>
                                        <td>Mens</td>
                                        <td>Polo Shirt</td>
                                    </tr>
                                    <tr>
                                        <td className='text-center w-[72px] max-w-[72px]'>
                                            <span className='cursor-pointer'>
                                                <DeleteIcon />
                                            </span>
                                        </td>
                                        <td>
                                            <span className='regular-14 black'>
                                                techpack.png
                                            </span>
                                        </td>
                                        <td>
                                            <input
                                                type='text'
                                                placeholder='Style Name'
                                                className='max-w-[180px]'
                                            />
                                        </td>
                                        <td>{/* <SelectComponent /> */}</td>
                                        <td> {/* <SelectComponent /> */}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <button
                                className='button primary mt-5'
                                onClick={() => setShowAskQuoteModal(true)}
                            >
                                Ask for Quote
                            </button>
                            <button
                                className='button primary mt-5 ml-3'
                                onClick={() => setShowAskSampleModal(true)}
                            >
                                Ask for Sample
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}

            {/* ask for quote modal */}
            {showAskQuoteModal && (
                <ModalContainer
                    className='upload-design-modal right-sidebar'
                    // onCloseModal={() => toggleModel(null)}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h2 className='regular-28 weight-700 mb-0'>
                                    Ask for Quote/Sample
                                </h2>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={() => setShowAskQuoteModal(false)}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <div>
                                <h3 className='regular-20 weight-700'>
                                    Step 2: Ask for Quote
                                </h3>
                                <p className='regular-12 gray-300'>
                                    Please share your design with the Nitex team
                                </p>
                            </div>
                            <div className='max-w-[500px] mt-3'>
                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Title*
                                    </label>

                                    <input type='text' name='title' />
                                </div>
                                <div className='grid grid-cols-2 gap-7'>
                                    <div className='form-group'>
                                        <label className='regular-14 gray_300 mb-2'>
                                            Quote Required Date
                                        </label>

                                        <input type='date' name='date' />
                                    </div>
                                    <div className='form-group'>
                                        <label className='regular-14 gray_300 mb-2'>
                                            Estimated OrderDelivery Date
                                        </label>

                                        <input type='date' name='date' />
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Instruction
                                    </label>

                                    <textarea
                                        placeholder='Write in details'
                                        className='h-20'
                                    ></textarea>
                                </div>
                            </div>

                            <table className='ask-for-quote-table table mt-8'>
                                <thead>
                                    <tr>
                                        <th>Style</th>
                                        <th>Quantity*</th>
                                        <th>Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className='flex items-center'>
                                                <img
                                                    src='/images/single-design.png'
                                                    alt='style'
                                                    className='style-image w-9'
                                                />
                                                <span className='ml-2'>
                                                    Polo Shit
                                                </span>
                                            </div>
                                        </td>
                                        <td>12</td>
                                        <td>Polo Shirt</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='flex items-center'>
                                                <img
                                                    src='/images/single-design.png'
                                                    alt='style'
                                                    className='style-image w-9'
                                                />
                                                <span className='ml-2'>
                                                    Polo Shit
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <input
                                                type='number'
                                                placeholder='Input quantity'
                                                className='max-w-[180px]'
                                            />
                                        </td>
                                        <td>
                                            <textarea
                                                placeholder='Write in details'
                                                className='h-11'
                                            ></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <button className='button primary mt-5'>
                                Submit
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}

            {/* ask for quote modal */}
            {showAskSampleModal && (
                <ModalContainer
                    className='upload-design-modal right-sidebar'
                    // onCloseModal={() => toggleModel(null)}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h2 className='regular-28 weight-700 mb-0'>
                                    Ask for Quote/Sample
                                </h2>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={() => setShowAskSampleModal(false)}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <div>
                                <h3 className='regular-20 weight-700'>
                                    Step 2: Ask for Sample
                                </h3>
                                <p className='regular-12 gray-300'>
                                    Please share your design with the Nitex team
                                </p>
                            </div>
                            <div className='max-w-[500px] mt-3'>
                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Title*
                                    </label>

                                    <input type='text' name='title' />
                                </div>

                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Sample Required Date
                                    </label>

                                    <input type='date' name='date' />
                                </div>

                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Instruction
                                    </label>

                                    <textarea
                                        placeholder='Write in details'
                                        className='h-20'
                                    ></textarea>
                                </div>
                            </div>

                            <table className='ask-for-sample-table table mt-8'>
                                <thead>
                                    <tr>
                                        <th>Style</th>
                                        <th>Size</th>
                                        <th>Quantity*</th>
                                        <th>Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className='flex items-center'>
                                                <img
                                                    src='/images/single-design.png'
                                                    alt='style'
                                                    className='style-image w-9'
                                                />
                                                <span className='ml-2'>
                                                    Polo Shit
                                                </span>
                                            </div>
                                        </td>
                                        <td>M</td>
                                        <td>12</td>
                                        <td>Polo Shirt</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='flex items-center'>
                                                <img
                                                    src='/images/single-design.png'
                                                    alt='style'
                                                    className='style-image w-9'
                                                />
                                                <span className='ml-2'>
                                                    Polo Shit
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <input
                                                type='text'
                                                placeholder='input quantity'
                                                className='max-w-[180px]'
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='number'
                                                placeholder='input quantity'
                                                className='max-w-[180px]'
                                            />
                                        </td>
                                        <td>
                                            <textarea
                                                placeholder='Write in details'
                                                className='h-11'
                                            ></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className='tab-view-table py-4'>
                                <div className='select-all mb-2'>
                                    <div className='flex items-center'>
                                        <input
                                            type='checkbox'
                                            name='categories'
                                            id='all'
                                            value=''
                                        />
                                        <label className='text-base blue ml-2'>
                                            Select all
                                        </label>
                                    </div>
                                </div>
                                <div className='all-rows'>
                                    <div className='single-row'>
                                        <table className='table'>
                                            <tr>
                                                <td rowSpan='7'>
                                                    <input
                                                        type='checkbox'
                                                        name='categories'
                                                        id='all'
                                                        value=''
                                                    />
                                                </td>
                                                <td className='heading'>
                                                    Style
                                                </td>
                                                <td>
                                                    <div className='flex items-center'>
                                                        <img
                                                            src='/images/single-design.png'
                                                            alt='style'
                                                            className='style-image w-11'
                                                        />
                                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                                            MT22-A0121
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Description
                                                </td>
                                                <td>
                                                    Mix, Twill, 49% Tencel 49%
                                                    Cotton 2% Spandex, GSM 200
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Quantity
                                                </td>
                                                <td>456</td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Target Price
                                                </td>
                                                <td>$120.56</td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Offer price
                                                </td>
                                                <td>$112.56</td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Action
                                                </td>
                                                <td>
                                                    {' '}
                                                    <div className='flex items-center'>
                                                        <button className='button primary regular-12 round mr-2'>
                                                            Approve
                                                        </button>
                                                        <button className='button secondary regular-12 round'>
                                                            Re-cost
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Conversation
                                                </td>
                                                <td>
                                                    <button className='button secondary icon-only chat-btn'>
                                                        <ChatIcon />
                                                        <span className='count absolute'>
                                                            10
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className='add-row'>
                                        <button className='button link my-5'>
                                            <PlusSquareIcon /> Add new style
                                        </button>
                                    </div>
                                    <div className='single-row'>
                                        <table className='table'>
                                            <tr>
                                                <td rowSpan='7'>
                                                    <input
                                                        type='checkbox'
                                                        name='categories'
                                                        id='all'
                                                        value=''
                                                    />
                                                </td>
                                                <td className='heading'>
                                                    Style
                                                </td>
                                                <td>
                                                    <div className='flex items-center'>
                                                        <img
                                                            src='/images/single-design.png'
                                                            alt='style'
                                                            className='style-image w-11'
                                                        />
                                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                                            MT22-A0121
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Description
                                                </td>
                                                <td>
                                                    Mix, Twill, 49% Tencel 49%
                                                    Cotton 2% Spandex, GSM 200
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Quantity
                                                </td>
                                                <td>456</td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Target Price
                                                </td>
                                                <td>$120.56</td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Offer price
                                                </td>
                                                <td>$112.56</td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Action
                                                </td>
                                                <td>
                                                    {' '}
                                                    <div className='flex items-center'>
                                                        <button className='button primary regular-12 sm round mr-2'>
                                                            Approve
                                                        </button>
                                                        <button className='button secondary regular-12 sm round'>
                                                            Re-cost
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Conversation
                                                </td>
                                                <td>
                                                    <button className='button secondary icon-only chat-btn'>
                                                        <ChatIcon />
                                                        <span className='count absolute'>
                                                            10
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <button className='button primary mt-5'>
                                Submit
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    )
}

export default MyDashboardUi
