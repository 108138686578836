import React, { useEffect, useState } from 'react'
import { ReactComponent as SearchIconWhite } from '../../../../assets/images/search-white.svg'
import { useFilterOption } from '../../../redux_toolkit/V3/Filter/FilterOptionReducers'
import { FilterActionTypes, useFilter } from '../../../redux_toolkit/V3/Filter'
import { useDispatch } from 'react-redux'
import { SET_FILTER_FIELD } from '../../../redux_toolkit/@types/action.types'

const Category = () => {
    const options = useFilterOption()
    const [categories, setCategories] = useState([])
    const filter = useFilter()
    const dispatch = useDispatch()

    useEffect(() => {
        setCategories(options.categories)
    }, [options.categories])

    const handleChange = (e) => {
        if (e.target.value) {
            let searchedCategories = options.categories.filter(
                (item) =>
                    item.name
                        .toUpperCase()
                        .indexOf(e.target.value.toUpperCase()) !== -1
            )
            setCategories(searchedCategories)
        } else {
            setCategories(options.categories)
        }
    }

    const handleCheckbox = async (e, item) => {
        let data = {
            name: e.target.name,
            value: item,
            type: e.target.type,
            checked: e.target.checked
        }
        await dispatch(FilterActionTypes[SET_FILTER_FIELD](data))
    }

    const isCategoryChecked = (item) => {
        if (filter?.categories) {
            let isCategoryExist = false
            for (let category of filter.categories) {
                if (category.id === item.id) {
                    isCategoryExist = true
                    break
                }
            }
            return isCategoryExist
        } else {
            return false
        }
    }

    const renderCategories = () => {
        return categories?.map((item) => {
            return (
                <div className='flex items-start' key={`categories_${item.id}`}>
                    <span>
                        <input
                            type='checkbox'
                            name='categories'
                            id={`category_${item.id}`}
                            value={item}
                            onChange={(e) => handleCheckbox(e, item)}
                            checked={isCategoryChecked(item)}
                        />
                    </span>
                    <label
                        id={`category_${item.id}`}
                        className='align-middle pl-4 inline-block mt-[-3px]'
                    >
                        {item.name}
                    </label>
                </div>
            )
        })
    }

    return (
        <div className='border-r border-b last:border-r-none border-white-shade-100 py-6 px-10'>
            <span className='text-primaryColor-shade-100'>Category</span>
            <div className='mt-6'>
                <div className='flex'>
                    <input
                        type='text'
                        className='form-field border border-primaryColor h-[40px] p-2 px-4'
                        id='name'
                        placeholder='Search ...'
                        name='name'
                        onChange={handleChange}
                    />
                    <button type='button' className='btn h-[40px] flex p-2'>
                        <SearchIconWhite />
                    </button>
                </div>
            </div>
            <div className='mt-6 space-y-8 custom-scroll overflow-y-auto max-h-[250px] 4xl:max-h-[300px] pt-2'>
                {renderCategories()}
            </div>
        </div>
    )
}

export default Category
