import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as FavouriteIcon } from '../../../assets/icons/favorite.svg'
import { ReactComponent as PlayIcon } from '../../../assets/icons/play.svg'
import { ReactComponent as CollectionFillIcon } from '../../../assets/icons/collection-fill.svg'
import BreadCrumb from '../common/BreadCrumb'
import { getUserPreference, randomShuffle } from '../../helpers/Util'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { setSystemUserPreferences } from '../../services/CommonService'
import { useDispatch } from 'react-redux'
import {
    GET_ALL_PRODUCTS_FROM_RANGE,
    GET_FAVOURITE_COUNT,
    SET_ALL_PRODUCTS_TO_RANGE,
    TOGGLE_PRODUCT_AS_FAVOURITE_IN_RANGE
} from '../../redux_toolkit/@types/thunk.types'
import DesignCardView from '../common/DesignCardView'
import { RangeActions, useRanges } from '../../redux_toolkit/V3/Range'
import AddToFavouriteRange from './AddToFavouriteRange'
import BuildRangeRightSidePanel from './BuildRangeRightSidePanel'
import RangeThunks from '../../redux_toolkit/V3/Range/RangeThunks'
import {
    doFavourite,
    undoFavourite
} from '../../services/v3/Favourite/FavouriteService'
import AddToFavourite from '../common/AddToFavourite'
import { DoFavouriteActions } from '../../redux_toolkit/V3/DoFavourite'
import { SET_SELECTED_FAVOURITE } from '../../redux_toolkit/@types/action.types'
import PresentationViewModal from '../common/PresentationViewModal'
import { FavouriteThunks } from '../../redux_toolkit/Favourite/FavouriteThunks'
import { ReactComponent as CollectionUnFillIcon } from '../../../assets/icons/collection-unfill.svg'
import { useGlobalState } from '../../redux_toolkit/V3/Common'

const RangeList = () => {
    const [isShowPresentation, setIsShowPresentation] = useState(false)
    const buildInfos = JSON.parse(localStorage.getItem('buildRangeInfos'))
    const [openRangeModal, setOpenRangModal] = useState(false)
    const [showFavourite, setShowFavourite] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [viewType, setViewType] = useState(null)
    const [designs, setDesigns] = useState([])
    const trackId = useGlobalState()?.productTrackId
    const rangeDesigns = useRanges()
    const dispatch = useDispatch()
    const scrollRef = useRef()

    useEffect(() => {
        setDesigns(rangeDesigns)
        scrollRef.current = rangeDesigns
        generateCategoryString()
    }, [rangeDesigns])

    useEffect(() => {
        let type = getUserPreference()?.rangeListViewType || 'LARGE_VIEW'
        setViewType(type)

        return () => {
            localStorage.removeItem('buildRangeInfos')
        }
    }, [])

    const toggleCollectionViewType = async () => {
        try {
            let type =
                getUserPreference()?.rangeListViewType === 'NARROW_VIEW'
                    ? 'LARGE_VIEW'
                    : 'NARROW_VIEW'
            dispatch(openLoader())
            await setSystemUserPreferences({
                rangeListViewType: type
            })
            setViewType(type)
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const toggleIsFavourite = async (bool, designInfo) => {
        try {
            dispatch(openLoader())
            if (!bool) {
                await undoFavourite(designInfo.id)
            } else {
                let folderId = getUserPreference()?.defaultFavouriteFolder?.id
                let params = `?productIds=${designInfo.id}`
                if (folderId) {
                    params += `&folderId=${folderId}`
                }
                if (trackId) params += `&tid=${trackId}`
                await doFavourite(params)
            }
            dispatch(
                RangeActions[TOGGLE_PRODUCT_AS_FAVOURITE_IN_RANGE](
                    designInfo.id
                )
            )
            await dispatch(
                DoFavouriteActions[SET_SELECTED_FAVOURITE](designInfo)
            )
            await dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]())
            setShowFavourite(bool)
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const renderDesigns = () => {
        return designs?.map((item) => {
            return (
                <React.Fragment key={`collection_product_${item.id}`}>
                    <DesignCardView
                        designInfo={item}
                        triggerIsFavourite={toggleIsFavourite}
                    />
                </React.Fragment>
            )
        })
    }

    const handleRangeFavourite = () => {
        setOpenModal(true)
    }

    const regenerateBuilds = async () => {
        try {
            if (buildInfos) {
                dispatch(openLoader())
                let categoryWiseCount = {}
                let ranges = buildInfos.ranges
                let inputData = buildInfos.inputData
                for (let item of ranges) {
                    categoryWiseCount[item.category.id] = item.noOfStyle
                }
                let postData = {
                    categoryWiseCount,
                    ...inputData,
                    season: inputData?.season?.id,
                    fabricType: inputData?.fabricType?.id
                }
                await dispatch(
                    RangeThunks[GET_ALL_PRODUCTS_FROM_RANGE](postData)
                )
                localStorage.setItem(
                    'buildRangeInfos',
                    JSON.stringify({
                        ranges,
                        inputData
                    })
                )
                dispatch(closeLoader())
            }
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const generateCategoryString = () => {
        let products = rangeDesigns
        const categoryCount = {}

        for (const product of products) {
            const categoryId = product.categoryResponse.id

            if (categoryCount[categoryId]) {
                categoryCount[categoryId]++
            } else {
                categoryCount[categoryId] = 1
            }
        }

        const categoryStrings = []
        for (const categoryId in categoryCount) {
            let selectedProduct = products.find(
                (item) => item.categoryResponse.id === parseInt(categoryId)
            )
            const categoryName = selectedProduct.categoryResponse.name
            categoryStrings.push(
                `${categoryName} (${categoryCount[categoryId]})`
            )
        }
        document.getElementById('inner-html').innerHTML = categoryStrings.join(
            '&nbsp;&nbsp;&nbsp;&nbsp;'
        )
    }

    const shuffleRandomData = () => {
        const dataSet = randomShuffle([...designs])
        dispatch(
            RangeActions[SET_ALL_PRODUCTS_TO_RANGE]({
                data: { productResponseList: dataSet, rangeTrackerId: trackId }
            })
        )
    }

    const renderViewTypeWiseIcon = () => {
        let type =
            getUserPreference()?.rangeListViewType === 'NARROW_VIEW'
                ? 'LARGE_VIEW'
                : 'NARROW_VIEW'
        if (type !== 'NARROW_VIEW') {
            return <CollectionFillIcon />
        } else {
            return <CollectionUnFillIcon />
        }
    }

    return (
        <>
            <div className='flex justify-between py-3'>
                <BreadCrumb isShowBackButton={false} />
            </div>
            <div className='page-content'>
                <div className='flex flex-wrap justify-between'>
                    <div>
                        <div className='actions'>
                            <button
                                className='button secondary'
                                onClick={() => setIsShowPresentation(true)}
                            >
                                <span className='mr-2'>Presentation view</span>
                                <PlayIcon />
                            </button>
                            <button
                                className='button secondary mx-3'
                                onClick={handleRangeFavourite}
                            >
                                <span className='mr-2'>Add to fav</span>
                                <FavouriteIcon />
                            </button>
                            <button
                                className='button icon-only boder-0'
                                onClick={toggleCollectionViewType}
                            >
                                {renderViewTypeWiseIcon()}
                            </button>
                        </div>
                        <div className='list-summary d-flex items-center mt-5 mb-4 sm:mb-0'>
                            <p className='regular-14 mr-6' id='inner-html' />
                            <button
                                className='button link regular-14'
                                onClick={() => setOpenRangModal(true)}
                            >
                                Edit
                            </button>
                        </div>
                    </div>
                    <div>
                        <button
                            className='button secondary'
                            onClick={shuffleRandomData}
                        >
                            <span>Reshuffle</span>
                        </button>
                        {buildInfos?.ranges && (
                            <button
                                className='button secondary ml-3'
                                onClick={regenerateBuilds}
                            >
                                <span>Regenerate</span>
                            </button>
                        )}
                    </div>
                </div>
                <div
                    className={`design-list-container pt-5 ${
                        viewType === 'NARROW_VIEW' ? 'image-view' : ''
                    }`}
                >
                    {renderDesigns()}
                </div>
            </div>
            {openModal && (
                <AddToFavouriteRange
                    isShowModal={openModal}
                    closeModal={() => setOpenModal(false)}
                />
            )}
            {openRangeModal && (
                <BuildRangeRightSidePanel
                    openModal={openRangeModal}
                    onCloseModal={() => setOpenRangModal(false)}
                    isEdit={true}
                />
            )}
            {isShowPresentation && (
                <PresentationViewModal
                    onClose={() => setIsShowPresentation(false)}
                    isRequestFromRange={true}
                    productIds={designs?.map((item) => item.id)}
                />
            )}
            <AddToFavourite
                isVisible={showFavourite}
                setIsVisible={setShowFavourite}
            />
        </>
    )
}

export default RangeList
