import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import {
    ADD_CONVERSATION_PRODUCT_ID,
    ADD_PRODUCT_TRACK_ID,
    CLEAR_CONVERSATION_PRODUCT_ID,
    CLEAR_PRODUCT_TRACK_ID
} from '../../@types/thunk.types'

const initialState = {
    conversationProductId: null,
    productTrackId: null
}

const AppCommonState = createSlice({
    name: 'appGlobalState',
    initialState,
    reducers: {
        [CLEAR_CONVERSATION_PRODUCT_ID]: (state) => {
            state.conversationProductId = null
        },
        [ADD_CONVERSATION_PRODUCT_ID]: (state, { payload }) => {
            state.conversationProductId = payload
        },
        [ADD_PRODUCT_TRACK_ID]: (state, { payload }) => {
            state.productTrackId = payload
        },
        [CLEAR_PRODUCT_TRACK_ID]: (state, { payload }) => {
            state.productTrackId = null
        }
    }
})

export const CommonActions = AppCommonState.actions

export const useGlobalState = () => useSelector((state) => state.appGlobalState)

export default AppCommonState.reducer
