import React, { useEffect, useRef, useState } from 'react'
import Http from '../../helpers/Http'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import LoaderComponent from '../../common/LoaderComponent'
import { ReactComponent as NitexIcon } from '../../../assets/images/home/nitexLogo.svg'
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg'
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg'
import { ReactComponent as SustainableIcon } from '../../../assets/icons/sustainable.svg'
import { ReactComponent as PaymentIcon } from '../../../assets/icons/payment.svg'
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg'
import { ReactComponent as ClothIcon } from '../../../assets/icons/cloth.svg'
import { ReactComponent as BagIcon } from '../../../assets/icons/bag.svg'

const ActivationPopup = () => {
    const [loading, setLoading] = useState(true)
    const [managerInfo, setManagerInfo] = useState({})
    const [buyerDetailsInfo, setBuyerDetailsInfo] = useState({})
    const navigate = useNavigate()
    const popupRef = useRef()
    const closeModalRef = useRef()

    useEffect(() => {
        Http.GET('userInfo')
            .then(async (response) => {
                let refreshToken = localStorage.getItem('refreshToken')
                localStorage.setItem('userInfo', JSON.stringify(response.data))
                let isTokenUpdateRequired = response.data.updatedTokenRequired
                if (isTokenUpdateRequired === true && refreshToken) {
                    await Http.POST('refreshUserToken', {
                        refreshToken: refreshToken
                    })
                        .then(async (tokenResponse) => {
                            await localStorage.setItem(
                                'token',
                                `${tokenResponse.data.tokenType} ${tokenResponse.data.accessToken}`
                            )
                            await redirectPage(response)
                            setLoading(false)
                        })
                        .catch((error) => {
                            setLoading(false)
                            toast.error(error.response.data.message)
                        })
                } else {
                    await redirectPage(response)
                }
            })
            .catch(({ response }) => {
                setLoading(false)
                toast.error(response.data.message)
            })
    }, [])

    const redirectPage = async (response) => {
        if (response.data.status === 'ACTIVE') {
            closeModalRef.current.click()
            await navigate('/home')
        } else if (response.data.status === 'DISABLED') {
            await navigate('/login')
        }
    }

    useEffect(() => {
        Http.GET('accManagerInfo')
            .then((response) => {
                let buyerInfo = JSON.parse(localStorage.getItem('userInfo'))
                setManagerInfo(response.data)
                setBuyerDetailsInfo(buyerInfo)
                setLoading(false)
            })
            .catch(({ response }) => {
                setLoading(false)
                if (response && response.data && response.data.message) {
                    toast.error(response.data.message)
                } else {
                    // toast.error("Couldn't fetch user info.")
                }
            })
    }, [])

    useEffect(() => {
        popupRef.current.click()
    }, [])

    return (
        <LoaderComponent loading={loading}>
            <button
                ref={popupRef}
                type='button'
                className='btn hidden'
                data-bs-toggle='modal'
                data-bs-target='#ActivatedSoon'
            >
                Activated Soon
            </button>
            <div
                className='modal account-activation-modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto open'
                id='ActivatedSoon'
                tabIndex='-1'
                aria-labelledby='exampleModalCenterTitle'
                aria-modal='true'
                data-bs-backdrop='static'
                role='dialog'
            >
                <div className='modal-dialog !max-w-[1240px] mx-4 5xl:mx-auto overflow-hidden modal-dialog-centered relative w-auto pointer-events-none'>
                    <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current'>
                        <div className='modal-header flex flex-shrink-0 items-center justify-between p-8 pb-0'></div>
                        <div className='modal-body relative p-4'>
                            <div className='px-3 sm:px-6 pb-6'>
                                <button
                                    ref={closeModalRef}
                                    type='button'
                                    className='btn-close hidden box-content w-4 h-4 p-1 !mr-0.5 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                />
                                <div className='flex justify-between'>
                                    <h2 className='text-[24px] sm:text-[36px] leading-[24px] sm:leading-[36px] text-primaryColor font-bold mb-8 mt-6'>
                                        Your account will be activated soon
                                    </h2>
                                    <NitexIcon className='w-20' />
                                </div>
                                <div className='space-y-2'>
                                    <p className='text-base'>
                                        Welcome!{' '}
                                        <span className='uppercase weight-500'>
                                            {buyerDetailsInfo?.name}
                                        </span>
                                        . Thanks for joining us!
                                    </p>
                                    <p className='text-base'>
                                        This is{' '}
                                        <span className='uppercase weight-500'>
                                            {managerInfo?.name}
                                        </span>
                                        , a Business Evangelist dedicated to you
                                        and your brand.{' '}
                                    </p>
                                    <p className='text-base'>
                                        We will contact you{' '}
                                        <span className='weight-500'>
                                            within 24 hours{' '}
                                        </span>
                                    </p>
                                    <p className='text-base'>
                                        While waiting, you can check out how
                                        we’re empowering +50 brands from across
                                        the world.
                                    </p>
                                </div>
                                <div className='flex flex-wrap pt-6 relative'>
                                    {managerInfo?.email && (
                                        <div className='mr-6 mb-4'>
                                            <a
                                                href={`mailto:${managerInfo?.email}`}
                                            >
                                                <div className='flex gap-3'>
                                                    <span>
                                                        <MailIcon />
                                                    </span>
                                                    <span>
                                                        {managerInfo?.email}
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                    )}

                                    {managerInfo?.phone && (
                                        <div>
                                            <a href='tel:+88 015 21 300 845'>
                                                <div className='flex gap-3'>
                                                    <span>
                                                        <PhoneIcon />
                                                    </span>
                                                    <span>
                                                        {managerInfo?.phone}
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer pb-0 lg:pb-4 p-4 pt-0 mb-12'>
                            <div className='px-3 sm:px-6 flex flex-col lg:flex-row justify-between'>
                                <div className='grid grid-cols-1 sm:grid-cols-3 gap-6 2xl:gap-12 pb-12 gradient-bg'>
                                    <div className='flex items-center gap-6'>
                                        <span>
                                            <span className='w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center'>
                                                <ClothIcon />
                                            </span>
                                        </span>
                                        <span className='black'>
                                            100s of designs every week
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-6'>
                                        <span>
                                            <span className='w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center'>
                                                <PaymentIcon />
                                            </span>
                                        </span>
                                        <span className='black'>
                                            Up to 150 days Credit line
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-6'>
                                        <span>
                                            <span className='w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center'>
                                                <SustainableIcon />
                                            </span>
                                        </span>
                                        <span className='black'>
                                            100% Sustainable materials
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-6'>
                                        <span>
                                            <span className='w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center'>
                                                <ClockIcon />
                                            </span>
                                        </span>
                                        <span className='black'>
                                            4-6 wks production lead time
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-6'>
                                        <span>
                                            <span className='w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center'>
                                                <BagIcon />
                                            </span>
                                        </span>
                                        <span className='black'>
                                            Order as low as 250 units
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoaderComponent>
    )
}

export default ActivationPopup
