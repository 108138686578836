import {
    GET_ALL_FAVOURITE_COUNT,
    GET_FAVOURITE_COUNT,
    GET_FAVOURITE_STYLES
} from '../@types/thunk.types'
import { closeLoader, openLoader } from '../Loader'
import Http from '../../helpers/Http'
import { toast } from 'react-toastify'
import { FavouriteActionTypes } from './index'
import {
    SET_ALL_FAVOURITE_COUNT,
    SET_FAVOURITE_COUNT,
    SET_FAVOURITE_STYLES
} from '../@types/action.types'
import { FavouriteStyleActions } from './FavouriteStyleSlice'

export const FavouriteThunks = {
    [GET_FAVOURITE_COUNT]: () => async (dispatch) => {
        dispatch(openLoader())
        Http.GET('fetchUnseenFavouriteCount')
            .then((response) => {
                dispatch(FavouriteThunks[GET_ALL_FAVOURITE_COUNT]())
                dispatch({
                    type: FavouriteActionTypes[SET_FAVOURITE_COUNT],
                    payload: response.data
                })
                dispatch(closeLoader())
            })
            .catch((error) => {
                dispatch(closeLoader())
                toast.error(error.response.data.message)
            })
    },
    [GET_FAVOURITE_STYLES]: (params) => async (dispatch) => {
        await dispatch(openLoader())
        await Http.GET('fetchFavouriteStyles', params)
            .then((response) => {
                dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]())
                dispatch({
                    type: FavouriteStyleActions[SET_FAVOURITE_STYLES],
                    payload: { data: response.data, merge: response?.data?.currentPage > 0 }
                })
                dispatch(closeLoader())
            })
            .catch((error) => {
                dispatch(closeLoader())
                toast.error(error.response.data.message)
            })
    },
    [GET_ALL_FAVOURITE_COUNT]: () => async (dispatch) => {
        await Http.GET('allFavouriteCount')
            .then((response) => {
                dispatch({
                    type: FavouriteActionTypes[SET_ALL_FAVOURITE_COUNT],
                    payload: response.data
                })
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }


}
