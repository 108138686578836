import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ActivityLog from './ActivityLog'
import {
    authUserInfo,
    isPageReachBottom,
    isSetItem,
    onErrorImageLoad
} from '../../../helpers/Util'
import { useParams } from 'react-router-dom'
import {
    fetchTimeline,
    toggleSetMembersSelection
} from '../../../redux_toolkit/Timeline/TimelineThunks'
import { useLoader } from '../../../redux_toolkit/Loader'

const TimelineActivityLog = ({ toggleAddComment, setLoader }) => {
    const timelineStore = useSelector((store) => store.timeline)
    const dispatch = useDispatch()
    const myStateRef = useRef({})
    const params = useParams()
    const globalLoader = useLoader()
    const isLoading = useRef(null)
    const [productsOfSet, setProductsOfSet] = useState([])
    const [activeSetMemberInfo, setActiveSetMemberInfo] = useState(null)
    const extractAndOrganizeSetItem = () => {
        const listOfSetMembers = []
        const orderProducts = timelineStore?.selectedSetItem || []

        orderProducts.forEach((design) => {
            if (isSetItem(design)) {
                design?.setMemberList.forEach((member) => {
                    listOfSetMembers.push({ ...member })
                })
                listOfSetMembers.push({
                    id: design?.id,
                    name: 'handOver',
                    image: '/icons/order-hand-over.svg',
                    rfqId: design?.rfqId
                })
            }
        })
        setProductsOfSet(listOfSetMembers)
    }

    useEffect(() => {
        extractAndOrganizeSetItem()
    }, [timelineStore])

    useEffect(() => {
        setActiveSetMemberInfo(productsOfSet[0])
    }, [JSON.stringify(productsOfSet)])

    const setIsDataLoading = (data) => {
        isLoading.current = data
    }

    useEffect(() => {
        setIsDataLoading(globalLoader)
    }, [globalLoader])

    const setMyState = (data) => {
        myStateRef.current = data
    }

    useEffect(() => {
        setMyState(timelineStore)
    }, [timelineStore])

    useEffect(() => {
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const toggleSetMembersTab = async (design) => {
        window.scrollTo(0, 0)
        setLoader(true)
        await dispatch(
            toggleSetMembersSelection(design, generateParams(), params.orderId)
        )
        setLoader(false)
    }

    const onToggleSetMembersTab = (design) => {
        setActiveSetMemberInfo(design)
        toggleSetMembersTab({
            id: design?.id,
            rfqId: design?.rfqId,
            isSetParent: design.name === 'handOver'
        })
    }

    const generateParams = () => {
        return `${params.orderId}?page=0&size=15`
    }

    const handleScroll = () => {
        if (isPageReachBottom()) {
            let {
                totalElements,
                currentPage,
                selectedDesignList,
                data,
                rfqId,
                isSetParent
            } = myStateRef.current
            if (
                totalElements > 0 &&
                totalElements !== data.length &&
                !isLoading.current
            ) {
                let paramString
                if (selectedDesignList?.length > 0) {
                    paramString = `${params.orderId}?page=${
                        currentPage + 1
                    }&size=15&rfqId=${rfqId}${
                        !isSetParent
                            ? `&setMemberId=${selectedDesignList[0]}`
                            : ''
                    }`
                } else {
                    paramString = `${params.orderId}?page=${
                        currentPage + 1
                    }&size=15`
                }
                setLoader(true)
                dispatch(fetchTimeline(paramString, true)).finally(() =>
                    setLoader(false)
                )
            }
        }
    }

    const renderTimeline = () => {
        if (timelineStore?.data?.length > 0) {
            return timelineStore?.data?.map((item, index) => {
                return (
                    <ActivityLog
                        key={`activity_log_${item.id}`}
                        index={index}
                        activity={item}
                        setLoader={setLoader}
                    />
                )
            })
        } else {
            return (
                <p className='no-activity-text'>
                    {' '}
                    No activity found for this style
                </p>
            )
        }
    }

    return (
        <>
            <div className='one-third activity-logs'>
                <div
                    className='top-write-comments'
                    // data-bs-toggle='modal'
                    // data-bs-target='#addCommentModal'
                >
                    {productsOfSet.length > 0 && (
                        <div className='set-image-tab mb-4 ml-1'>
                            {productsOfSet.map((member) => (
                                <div
                                    key={member.id}
                                    onClick={() =>
                                        member?.id !==
                                            activeSetMemberInfo?.id &&
                                        onToggleSetMembersTab(member)
                                    }
                                >
                                    <img
                                        src={member?.image}
                                        alt='product'
                                        className={`${
                                            member?.id ===
                                            activeSetMemberInfo?.id
                                                ? 'active'
                                                : ''
                                        }`}
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                    <div
                        className='comments-button cursor-pointer'
                        onClick={toggleAddComment}
                    >
                        <p className='regular-12 mb-0 flex items-center'>
                            <img
                                src={
                                    authUserInfo()?.profilePicDocument
                                        ?.docUrl ||
                                    '/images/pro_pic_default.png'
                                }
                                onError={(e) =>
                                    onErrorImageLoad(
                                        e,
                                        authUserInfo()?.profilePicDocument
                                            ?.docUrl
                                    )
                                }
                                alt='profile'
                                className='profile-image'
                            />
                            Write comment...
                        </p>
                        <img
                            src='/icons/attachment.svg'
                            alt='attach'
                            className='attachment-icon'
                        />
                    </div>
                </div>
                <div className='activity-list custom-scroll'>
                    {renderTimeline()}
                </div>
            </div>
        </>
    )
}

export default TimelineActivityLog
