import {
    NEW_INCOMING_MESSAGE_SUCCESS,
    SET_CURRENT_ACTIVE_PAGE,
    CLEAR_ALL_CHATS
} from '../@types/action.types'

export const ConversationReducers = {
    [SET_CURRENT_ACTIVE_PAGE]: (state, { payload }) => {
        state.currentPage = payload
    },
    [NEW_INCOMING_MESSAGE_SUCCESS]: (state, { payload }) => {
        state.newMessage = payload
    },
    [CLEAR_ALL_CHATS]: (state) => {
        state.newMessage = {}
    }
}
