import React from 'react'
import { ReactComponent as ArrowLeftIcon } from '../../../icons/arrow-left.svg'
import { ReactComponent as Three60Icon } from '../../../icons/360-degree.svg'

const FactoryProfile = () => {
    return (
        <>
            <div className='py-3'>
                <div className='flex align-items-center'>
                    <button className='button link mr-6'>
                        <ArrowLeftIcon />
                        <span className='black'>Back</span>
                    </button>
                    <div className='breadcrumb'>
                        <p className='regular-14 black'>
                            <span className='blue'>My Dashboard </span> /{' '}
                            <span className='blue'>Order </span> /{' '}
                            <span className='blue'>OP/PII/2023 </span> /{' '}
                            <span className='regular-14'>Ananta Group</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className='factory-profile-container'>
                <div className='mb-10'>
                    <h3 className='text-2xl font-bold pb-5'>Ananta Group</h3>
                    <div className='factory-thambnail'>
                        <img
                            src='/images/factory-view.jpg'
                            alt='factory'
                            className='thambnail-image'
                        />
                        <div className='location-info flex items-center'>
                            <img
                                src='/icons/bd-flag.svg'
                                className='w-7 h-4 object-contain'
                                alt='flag'
                            />
                            <p className='regular-16 text-white ml-2'>
                                Dhaka,Bangladesh
                            </p>
                        </div>
                        <button className='button secondary icon-only virtual-btn'>
                            <Three60Icon />
                        </button>
                    </div>
                </div>
                <div className='mb-10'>
                    <h3 className='text-2xl font-bold pb-5'>Quick info</h3>
                    <div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 md:gap-6'>
                        <div className='same-padding'>
                            <p className='regular-14 gray_300 mb-1'>
                                Factory Type
                            </p>
                            <h3 className='text-base md:text-xl font-bold'>
                                Garments
                            </h3>
                        </div>
                        <div className='same-padding'>
                            <p className='regular-14 gray_300 mb-1'>Category</p>
                            <h3 className='text-base md:text-xl font-bold'>
                                knit
                            </h3>
                        </div>
                        <div className='same-padding'>
                            <p className='regular-14 gray_300 mb-1'>
                                Annual Turnover
                            </p>
                            <h3 className='text-base md:text-xl font-bold'>
                                $300 Million
                            </h3>
                        </div>
                        <div className='same-padding'>
                            <p className='regular-14 gray_300 mb-1'>
                                Total Employees
                            </p>
                            <h3 className='text-base md:text-xl font-bold'>
                                20,000
                            </h3>
                        </div>
                        <div className='same-padding'>
                            <p className='regular-14 gray_300 mb-1'>
                                Production Line
                            </p>
                            <h3 className='text-base md:text-xl font-bold'>
                                12
                            </h3>
                        </div>
                    </div>
                </div>
                <div className='mb-10 '>
                    <h3 className='text-2xl font-bold pb-5'>Key customers </h3>
                    <div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 md:gap-6'>
                        {Array.from({ length: 13 }).map((_, i) => (
                            <div className='same-padding h-24 flex items-center justify-center'>
                                <img
                                    src='/images/zalando.png'
                                    alt='zalando'
                                    className='w-32 h-auto object-contain'
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='mb-10 '>
                    <h3 className='text-2xl font-bold pb-5'>Certifications</h3>
                    <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-4 md:gap-6'>
                        {Array.from({ length: 12 }).map((_, i) => (
                            <div className='same-padding text-center'>
                                <div className='pt-6'>
                                    <img
                                        src='/images/certified3.png'
                                        alt='certificate'
                                        className='w-28 h-28 xl:w-36 xl:h-36 rounded-full object-contain'
                                    />
                                </div>

                                <p className='regular-16 black mt-8'>
                                    Certificates name
                                </p>
                                <p className='regular-12 gray_300 mb-1'>
                                    Expired: 12 Apr, 2021
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='mb-10 '>
                    <h3 className='text-2xl font-bold pb-5'>
                        Production Capacity
                    </h3>
                    <div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 md:gap-6'>
                        <div className='same-padding'>
                            <p className='regular-14 gray_300 mb-1'>Knitting</p>
                            <h3 className='text-base md:text-xl font-bold'>
                                15 Ton <span className='gray_300'>/Day</span>
                            </h3>
                        </div>
                        <div className='same-padding'>
                            <p className='regular-14 gray_300 mb-1'>Dyeing</p>
                            <h3 className='text-base md:text-xl font-bold'>
                                15 Ton <span className='gray_300'>/Day</span>
                            </h3>
                        </div>
                        <div className='same-padding'>
                            <p className='regular-14 gray_300 mb-1'>Sewing</p>
                            <h3 className='text-base md:text-xl font-bold'>
                                1500 Pcs <span className='gray_300'>/Day</span>
                            </h3>
                        </div>
                        <div className='same-padding'>
                            <p className='regular-14 gray_300 mb-1'>
                                Finishing
                            </p>
                            <h3 className='text-base md:text-xl font-bold'>
                                1500 Pcs <span className='gray_300'>/Day</span>
                            </h3>
                        </div>
                        <div className='same-padding'>
                            <p className='regular-14 gray_300 mb-1'>Washing</p>
                            <h3 className='text-base md:text-xl font-bold'>
                                1500 Pcs <span className='gray_300'>/Day</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className='mb-10 '>
                    <h3 className='text-2xl font-bold pb-5'>Technical</h3>
                    <div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 md:gap-6'>
                        <div className='same-padding text-center'>
                            <div className='py-3'>
                                <img
                                    src='/icons/r&d.svg'
                                    alt='technical'
                                    className='w-10 h-10'
                                />
                                <h3 className='regular-18 weight-500 mt-2'>
                                    R&D
                                </h3>
                            </div>
                        </div>
                        <div className='same-padding text-center'>
                            <div className='py-3'>
                                <img
                                    src='/icons/lab.svg'
                                    alt='technical'
                                    className='w-10 h-10'
                                />
                                <h3 className='regular-18 weight-500 mt-2'>
                                    Lab
                                </h3>
                            </div>
                        </div>
                        <div className='same-padding text-center'>
                            <div className='py-3'>
                                <img
                                    src='/icons/studio.svg'
                                    alt='technical'
                                    className='w-10 h-10'
                                />
                                <h3 className='regular-18 weight-500 mt-2'>
                                    Studio
                                </h3>
                            </div>
                        </div>
                        <div className='same-padding text-center'>
                            <div className='py-3'>
                                <img
                                    src='/icons/sample.svg'
                                    alt='technical'
                                    className='w-10 h-10'
                                />
                                <h3 className='regular-18 weight-500 mt-2'>
                                    Sample
                                </h3>
                            </div>
                        </div>
                        <div className='same-padding text-center'>
                            <div className='py-3'>
                                <img
                                    src='/icons/composite.svg'
                                    alt='technical'
                                    className='w-10 h-10'
                                />
                                <h3 className='regular-18 weight-500 mt-2'>
                                    Composite
                                </h3>
                            </div>
                        </div>
                        <div className='same-padding text-center'>
                            <div className='py-3'>
                                <img
                                    src='/icons/wtp.svg'
                                    alt='technical'
                                    className='w-10 h-10'
                                />
                                <h3 className='regular-18 weight-500 mt-2'>
                                    ETP
                                </h3>
                            </div>
                        </div>
                        <div className='same-padding text-center'>
                            <div className='py-3'>
                                <img
                                    src='/icons/wtp.svg'
                                    alt='technical'
                                    className='w-10 h-10'
                                />
                                <h3 className='regular-18 weight-500 mt-2'>
                                    WTP
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-10 '>
                    <h3 className='text-2xl font-bold pb-5'>Gallery</h3>
                    <div className='factory-gallery'>
                        <div className='gallery-row single-item mb-2 sm:mb-4'>
                            <div className='gallary-item'>
                                <img
                                    src='/images/factory-view.jpg'
                                    alt='factory'
                                />
                                <h3 className='gallery-title text-sm md:text-xl font-bold text-white'>
                                    Sewing Unit
                                </h3>
                            </div>
                        </div>
                        <div className='gallery-row two-in-row mb-2 sm:mb-4'>
                            <div className='gallary-item one-half'>
                                <img
                                    src='/images/factory-view.jpg'
                                    alt='factory'
                                />
                                <h3 className='gallery-title text-sm md:text-xl font-bold text-white'>
                                    Sewing Unit
                                </h3>
                            </div>
                            <div className='gallary-item one-half'>
                                <img
                                    src='/images/collections.jpg'
                                    alt='factory'
                                />
                                <h3 className='gallery-title text-sm md:text-xl font-bold text-white'>
                                    Sewing Unit
                                </h3>
                            </div>
                        </div>
                        <div className='gallery-row three-in-row right-aligned mb-2 sm:mb-4'>
                            <div className='gallary-item one-half'>
                                <img
                                    src='/images/factory-view.jpg'
                                    alt='factory'
                                />
                                <h3 className='gallery-title text-sm md:text-xl font-bold text-white'>
                                    Sewing Unit
                                </h3>
                            </div>
                            <div className='gallary-item one-third'>
                                <img
                                    src='/images/collections.jpg'
                                    alt='factory'
                                />
                                <h3 className='gallery-title text-sm md:text-xl font-bold text-white'>
                                    Sewing Unit
                                </h3>
                            </div>
                            <div className='gallary-item one-third'>
                                <img
                                    src='/images/factory-view.jpg'
                                    alt='factory'
                                />
                                <h3 className='gallery-title text-sm md:text-xl font-bold text-white'>
                                    Sewing Unit
                                </h3>
                            </div>
                        </div>
                        <div className='gallery-row three-in-row left-aligned mb-2 sm:mb-4'>
                            <div className='gallary-item one-third'>
                                <img
                                    src='/images/factory-view.jpg'
                                    alt='factory'
                                />
                                <h3 className='gallery-title text-sm md:text-xl font-bold text-white'>
                                    Sewing Unit
                                </h3>
                            </div>
                            <div className='gallary-item one-third'>
                                <img
                                    src='/images/collections.jpg'
                                    alt='factory'
                                />
                                <h3 className='gallery-title text-sm md:text-xl font-bold text-white'>
                                    Sewing Unit
                                </h3>
                            </div>
                            <div className='gallary-item one-half'>
                                <img
                                    src='/images/factory-view.jpg'
                                    alt='factory'
                                />
                                <h3 className='gallery-title text-sm md:text-xl font-bold text-white'>
                                    Sewing Unit
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FactoryProfile
