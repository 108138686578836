import {
    CLEAR_SELECTED_COLOR_LIST,
    SET_COLOR_LIST,
    SET_SELECTED_COLOR
} from '../@types/action.types'

export const ColorReducers = {
    [SET_COLOR_LIST]: (state, { payload }) => {
        state.colors = payload
    },
    [SET_SELECTED_COLOR]: (state, { payload }) => {
        state.selectedColors.push({ ...payload, colorType: 'SOLID' })
    },
    [CLEAR_SELECTED_COLOR_LIST]: (state, { payload }) =>
        void (state.selectedColors = payload)
}
