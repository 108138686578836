import React, { useEffect, useState } from 'react'
import Http from '../../helpers/Http'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { toast } from 'react-toastify'

const Preferences = () => {
    const [emailSettings, setEmailSettings] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        Http.GET('getSettingsData', 'MAIL_FREQUENCY')
            .then(({ data }) => {
                if (data && data.value) {
                    setEmailSettings(data.value)
                }
            })
            .catch(({ response }) => {})
    }, [])

    const updateEmailPreference = async () => {
        if (emailSettings) {
            await dispatch(openLoader())
            let body = {
                key: 'MAIL_FREQUENCY',
                value: emailSettings
            }
            await Http.POST('updatePersonalSettings', body)
                .then(({ data }) => {
                    if (data.success) {
                        toast.success(data.message)
                    } else {
                        toast.error(data.message)
                    }
                })
                .catch(({ response }) => {
                    if (response.data && response.data.message) {
                        toast.error(response.data.message)
                    } else {
                        toast.error('Something went wrong! Please try again.')
                    }
                })
                .finally(() => {
                    dispatch(closeLoader())
                })
        }
    }

    return (
        <div className='body-container'>
            <div className='space-y-6 px-8 py-6'>
                <div className='flex items-start'>
                    <span>
                        <input
                            type='radio'
                            id='DoEmails'
                            value='NONE'
                            name='emailSettings'
                            onChange={() => setEmailSettings('NONE')}
                            checked={emailSettings === 'NONE'}
                        />
                    </span>
                    <label
                        htmlFor='DoEmails'
                        className='text-sm sm:text-base align-middle pl-4 inline-block mt-[-3px]'
                    >
                        Do not send any email
                    </label>
                </div>
                <div className='flex items-start'>
                    <span>
                        <input
                            type='radio'
                            id='importantUpdates'
                            value='LIMITED'
                            name='emailSettings'
                            onChange={() => setEmailSettings('LIMITED')}
                            checked={emailSettings === 'LIMITED'}
                        />
                    </span>
                    <label
                        htmlFor='importantUpdates'
                        className='text-sm sm:text-base align-middle pl-4 inline-block mt-[-3px]'
                    >
                        Send only important updates
                    </label>
                </div>
                <div className='flex items-start'>
                    <span>
                        <input
                            type='radio'
                            id='Sendupdates'
                            value='ALL'
                            name='emailSettings'
                            onChange={() => setEmailSettings('ALL')}
                            checked={emailSettings === 'ALL'}
                        />
                    </span>
                    <label
                        htmlFor='Sendupdates'
                        className='text-sm sm:text-base align-middle pl-4 inline-block mt-[-3px]'
                    >
                        Send all updates
                    </label>
                </div>
            </div>
            <div className='px-8 py-6 border-top-solid'>
                <button
                    className='button primary'
                    onClick={() => updateEmailPreference()}
                >
                    Save changes
                </button>
            </div>
        </div>
    )
}

export default Preferences
