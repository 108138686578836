import React, { useState } from 'react'
import { ReactComponent as CollectionUnFillIcon } from '../../../../assets/icons/collection-unfill.svg'
import { ReactComponent as PlayIcon } from '../../../../assets/icons/play.svg'
import { ReactComponent as ListViewUnfillIcon } from '../../../../assets/icons/list-view-unfill.svg'
import { ReactComponent as LeftArrowIcon } from '../../../../assets/icons/arrow-left.svg'
import { ReactComponent as DownArrowIcon } from '../../../../assets/icons/arrow-down.svg'
import { ReactComponent as ChatIcon } from '../../../icons/chat.svg'
import { ReactComponent as PlusSquareIcon } from '../../../icons/plus-square.svg'
import { ReactComponent as ApprovedIcon } from '../../../icons/approved.svg'
import { ReactComponent as RecostIcon } from '../../../icons/recost.svg'
import { ReactComponent as CloseIcon } from '../../../icons/close.svg'
import { ReactComponent as SearchIcon } from '../../../icons/search.svg'
import Accordion from 'react-bootstrap/Accordion'
import ModalContainer from '../../../../app/common/modal/ModalContainer'

const FavoriteDetailsListView = () => {
    const [showTeamModal, setShowTeamModal] = useState(false)
    const [showInviteModal, setShowInviteModal] = useState(false)

    const openInviteModal = () => {
        setShowTeamModal(false)
        setShowInviteModal(true)
    }

    const handleClose = () => {
        setShowTeamModal(false)
        setShowInviteModal(false)
    }
    return (
        <>
            <div className='favourite-details-container flex'>
                <div className='favourite-details-data collapsed'>
                    <div className='flex justify-between py-3 px-4'>
                        <div className='flex align-items-center'>
                            <button className='button link mr-6'>
                                <LeftArrowIcon />
                                <span className='black'>Back</span>
                            </button>
                            <div className='breadcrumb'>
                                <p className='regular-14 black'>
                                    <span className='blue'>Favorites </span> /{' '}
                                    <span className='regular-12'>
                                        My Winter Collection
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className='team-members'>
                            <div className='flex items-center'>
                                <div className='member-photos flex'>
                                    <img src='/images/user.svg' alt='member' />
                                    <img src='/images/user.svg' alt='member' />
                                    <img src='/images/user.svg' alt='member' />
                                </div>
                                <button
                                    className='button secondary ml-2'
                                    onClick={() => setShowTeamModal(true)}
                                >
                                    Add People
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap sm:flex-nowrap justify-between py-3 px-4'>
                        <div className='actions'>
                            <button className='button secondary'>
                                <span className='mr-2'>Presentation view</span>
                                <PlayIcon />
                            </button>
                            <button className='button icon-only boder-0 mx-2'>
                                <CollectionUnFillIcon />
                            </button>
                            <button className='button !p-0'>
                                <ListViewUnfillIcon />
                            </button>
                        </div>
                        <div>
                            <button
                                className='button primary sm round'
                                onClick={() => setIsShowDraft(true)}
                            >
                                <LeftArrowIcon />
                                <span className='ml-2'>9 Styles</span>
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-wrap sm:flex-nowrap justify-between py-3 px-4'>
                        <div className='flex gap-7'>
                            <p className='text-sm weight-500'>Total: 9</p>
                            <p className='text-sm'>Tee (8)</p>
                            <p className='text-sm'>Polo Shirt (1)</p>
                        </div>
                        <div>
                            <button className='button secondary sm round'>
                                <span className='mr-2'>Sort</span>
                                <DownArrowIcon />
                            </button>
                        </div>
                    </div>
                    <div className='px-4'>
                        <div className='mt-6'>
                            <div className='group mb-3'>
                                <h3 className='text-xl font-bold mb-3'>
                                    Tee (4)
                                </h3>
                                <table className='table favourite-list-table'>
                                    <thead>
                                        <tr>
                                            <th>
                                                <input
                                                    type='checkbox'
                                                    name='categories'
                                                    id='all'
                                                    value=''
                                                />
                                            </th>
                                            <th>Style</th>
                                            <th>Description</th>
                                            <th>Quantity</th>
                                            <th>Quote</th>
                                            <th>Sample</th>
                                            <th>Order</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input
                                                    type='checkbox'
                                                    name='categories'
                                                    id='1'
                                                    value=''
                                                />
                                            </td>
                                            <td className='relative'>
                                                <div className='flex items-center'>
                                                    <img
                                                        src='/images/single-design.png'
                                                        alt='style'
                                                        className='style-image w-11'
                                                    />
                                                    <p className='blue regular-14 cursor-pointer ml-2'>
                                                        MT22-A0121
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                Diagonal Fleece, 100%
                                                Triacetate, 2x2 rib, 100.0 GSM,
                                                Bleaching
                                            </td>
                                            <td>
                                                <input type='number' />
                                            </td>
                                            <td>
                                                <p className='text-sm blue mb-1'>
                                                    Q2309-A0069
                                                </p>
                                                <p className='text-sm gray_300'>
                                                    $3.45
                                                </p>
                                            </td>
                                            <td>
                                                <p className='text-sm blue mb-1'>
                                                    S23-A1196
                                                </p>
                                                <span className='status teal-1'>
                                                    Design Uploaded
                                                </span>
                                            </td>
                                            <td>
                                                <span className='status purple-3'>
                                                    Pending
                                                </span>
                                                <p className='text-sm blue my-1'>
                                                    NTX/A23/0115
                                                </p>
                                                <p className='text-sm gray_300'>
                                                    ETD: 23 Nov, 2023
                                                </p>
                                            </td>

                                            <td>
                                                <textarea class='h-11'></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input
                                                    type='checkbox'
                                                    name='categories'
                                                    id='1'
                                                    value=''
                                                />
                                            </td>
                                            <td className='relative'>
                                                <div className='flex items-center'>
                                                    <img
                                                        src='/images/single-design.png'
                                                        alt='style'
                                                        className='style-image w-11'
                                                    />
                                                    <p className='blue regular-14 cursor-pointer ml-2'>
                                                        MT22-A0121
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                Diagonal Fleece, 100%
                                                Triacetate, 2x2 rib, 100.0 GSM,
                                                Bleaching
                                            </td>
                                            <td>
                                                <input type='number' />
                                            </td>
                                            <td>
                                                <p className='text-sm blue mb-1'>
                                                    Q2309-A0069
                                                </p>
                                                <p className='text-sm gray_300'>
                                                    $3.45
                                                </p>
                                            </td>
                                            <td>
                                                <p className='text-sm blue mb-1'>
                                                    S23-A1196
                                                </p>
                                                <span class='status magenta-1'>
                                                    Print
                                                </span>
                                            </td>
                                            <td>
                                                <span className='status teal-2'>
                                                    Production
                                                </span>
                                                <p className='text-sm blue my-1'>
                                                    NTX/A23/0115, NTX/A23/0115
                                                </p>
                                                <p className='text-sm gray_300'>
                                                    ETD: 23 Nov, 2023
                                                </p>
                                            </td>

                                            <td>
                                                <textarea class='h-11'></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input
                                                    type='checkbox'
                                                    name='categories'
                                                    id='1'
                                                    value=''
                                                />
                                            </td>
                                            <td className='relative'>
                                                <div className='flex items-center'>
                                                    <img
                                                        src='/images/single-design.png'
                                                        alt='style'
                                                        className='style-image w-11'
                                                    />
                                                    <p className='blue regular-14 cursor-pointer ml-2'>
                                                        MT22-A0121
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                Diagonal Fleece, 100%
                                                Triacetate, 2x2 rib, 100.0 GSM,
                                                Bleaching
                                            </td>
                                            <td>
                                                <input type='number' />
                                            </td>
                                            <td>
                                                <p className='text-sm blue mb-1'>
                                                    Q2309-A0069
                                                </p>
                                                <p className='text-sm gray_300'>
                                                    $3.45
                                                </p>
                                            </td>
                                            <td>
                                                <p className='text-sm blue mb-1'>
                                                    S23-A1196
                                                </p>
                                                <span class='status cyan-1'>
                                                    Pattern
                                                </span>
                                            </td>
                                            <td>
                                                <span className='status green-3'>
                                                    Shipment Completed
                                                </span>
                                                <p className='text-sm blue my-1'>
                                                    NTX/A23/0115
                                                </p>
                                                <p className='text-sm gray_300'>
                                                    ETD: 23 Nov, 2023
                                                </p>
                                            </td>

                                            <td>
                                                <textarea class='h-11'></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input
                                                    type='checkbox'
                                                    name='categories'
                                                    id='1'
                                                    value=''
                                                />
                                            </td>
                                            <td className='relative'>
                                                <div className='flex items-center'>
                                                    <img
                                                        src='/images/single-design.png'
                                                        alt='style'
                                                        className='style-image w-11'
                                                    />
                                                    <p className='blue regular-14 cursor-pointer ml-2'>
                                                        MT22-A0121
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                Diagonal Fleece, 100%
                                                Triacetate, 2x2 rib, 100.0 GSM,
                                                Bleaching
                                            </td>
                                            <td>
                                                <input type='number' />
                                            </td>
                                            <td>
                                                <p className='text-sm blue mb-1'>
                                                    Q2309-A0069
                                                </p>
                                                <p className='text-sm gray_300'>
                                                    $3.45
                                                </p>
                                            </td>
                                            <td>
                                                <p className='text-sm blue mb-1'>
                                                    S23-A1196
                                                </p>
                                                <span class='status yellow-2'>
                                                    Requested
                                                </span>
                                            </td>
                                            <td>
                                                <span className='status red-3'>
                                                    Cancel Request
                                                </span>
                                                <p className='text-sm blue my-1'>
                                                    NTX/A23/0115
                                                </p>
                                                <p className='text-sm gray_300'>
                                                    ETD: 23 Nov, 2023
                                                </p>
                                            </td>

                                            <td>
                                                <textarea class='h-11'></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Tab view cards */}
            <div className='tab-view-table favourite-tab-view-table py-4 px-3'>
                <div className='select-all mb-2'>
                    <div className='flex items-center'>
                        <input
                            type='checkbox'
                            name='categories'
                            id='all'
                            value=''
                        />
                        <label className='text-base blue ml-2'>
                            Select all
                        </label>
                    </div>
                </div>
                <div className='all-rows'>
                    <div className='single-row mb-4'>
                        <table className='table'>
                            <tr>
                                <td rowSpan='8'>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='all'
                                        value=''
                                    />
                                </td>
                                <td className='heading'>Style</td>
                                <td>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Description</td>
                                <td>
                                    {' '}
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Quantity</td>
                                <td>
                                    {' '}
                                    <input type='number' />
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Quote</td>
                                <td>
                                    <p className='text-sm blue mb-1'>
                                        Q2309-A0069
                                    </p>
                                    <p className='text-sm gray_300'>$3.45</p>
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Sample</td>
                                <td>
                                    {' '}
                                    <p className='text-sm blue mb-1'>
                                        S23-A1196
                                    </p>
                                    <span class='status yellow-2'>
                                        Requested
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Order</td>
                                <td>
                                    {' '}
                                    <span className='status red-3'>
                                        Cancel Request
                                    </span>
                                    <p className='text-sm blue my-1'>
                                        NTX/A23/0115
                                    </p>
                                    <p className='text-sm gray_300'>
                                        ETD: 23 Nov, 2023
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Remarks</td>
                                <td>
                                    <textarea class='h-11'></textarea>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='single-row mb-4'>
                        <table className='table'>
                            <tr>
                                <td rowSpan='8'>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='all'
                                        value=''
                                    />
                                </td>
                                <td className='heading'>Style</td>
                                <td>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Description</td>
                                <td>
                                    {' '}
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Quantity</td>
                                <td>
                                    {' '}
                                    <input type='number' />
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Quote</td>
                                <td>
                                    <p className='text-sm blue mb-1'>
                                        Q2309-A0069
                                    </p>
                                    <p className='text-sm gray_300'>$3.45</p>
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Sample</td>
                                <td>
                                    {' '}
                                    <p className='text-sm blue mb-1'>
                                        S23-A1196
                                    </p>
                                    <span class='status yellow-2'>
                                        Requested
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Order</td>
                                <td>
                                    {' '}
                                    <span className='status red-3'>
                                        Cancel Request
                                    </span>
                                    <p className='text-sm blue my-1'>
                                        NTX/A23/0115
                                    </p>
                                    <p className='text-sm gray_300'>
                                        ETD: 23 Nov, 2023
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Remarks</td>
                                <td>
                                    <textarea class='h-11'></textarea>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            {showTeamModal && (
                <ModalContainer
                    className='team-member-modal'
                    // onCloseModal={() => toggleModel(null)}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Add Team Member
                                </h3>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <div className='search-box bg-white'>
                                <div className='relative'>
                                    <div className='absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none'>
                                        <SearchIcon />
                                    </div>
                                    <input
                                        type='search'
                                        className='block w-full p-3 pr-10 text-sm'
                                        placeholder='Search'
                                    />
                                </div>
                            </div>
                            <div className='members-details'>
                                <Accordion>
                                    <Accordion.Item eventKey='0'>
                                        <Accordion.Header>
                                            <div className='flex justify-between items-center w-100'>
                                                <div className='flex items-center'>
                                                    <button className='button secondary icon-only arrow'>
                                                        <DownArrowIcon />
                                                    </button>
                                                    <h3 className='regular-16 weight-500'>
                                                        Men’s Buying team
                                                        <span className='filter-chip'>
                                                            3
                                                        </span>
                                                    </h3>
                                                </div>
                                                <div className='add flex'>
                                                    <button className='button secondary size32'>
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='members-list'>
                                                <div className='single-member flex items-center justify-between mb-3'>
                                                    <div className='flex items-center'>
                                                        <div className='profile mr-3'>
                                                            <img
                                                                src='/images/user.svg'
                                                                alt='member'
                                                                className='w-[32px] h-[32px] object-cover rounded-full'
                                                            />
                                                        </div>
                                                        <div className='designation-info'>
                                                            <h3 className='regular-16 weight-500'>
                                                                Esther Howard{' '}
                                                                <span className='filter-chip'>
                                                                    Senior
                                                                    Production
                                                                    Merchandiser
                                                                </span>
                                                            </h3>
                                                            <p className='regular-12 gray_300'>
                                                                sara.cruz@example.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='add'>
                                                        <button className='button secondary size32'>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='single-member flex items-center justify-between mb-3'>
                                                    <div className='flex items-center'>
                                                        <div className='profile mr-3'>
                                                            <img
                                                                src='/images/user.svg'
                                                                alt='member'
                                                                className='w-[32px] h-[32px] object-cover rounded-full'
                                                            />
                                                        </div>
                                                        <div className='designation-info'>
                                                            <h3 className='regular-16 weight-500'>
                                                                Esther Howard{' '}
                                                                <span className='filter-chip'>
                                                                    Senior
                                                                    Production
                                                                    Merchandiser
                                                                </span>
                                                            </h3>
                                                            <p className='regular-12 gray_300'>
                                                                sara.cruz@example.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='add'>
                                                        <button className='button secondary size32'>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className='single-member flex items-center justify-between mb-3'>
                                                    <div className='flex items-center'>
                                                        <div className='profile mr-3'>
                                                            <img
                                                                src='/images/user.svg'
                                                                alt='member'
                                                                className='w-[32px] h-[32px] object-cover rounded-full'
                                                            />
                                                        </div>
                                                        <div className='designation-info'>
                                                            <h3 className='regular-16 weight-500'>
                                                                Esther Howard{' '}
                                                                <span className='filter-chip'>
                                                                    Senior
                                                                    Production
                                                                    Merchandiser
                                                                </span>
                                                            </h3>
                                                            <p className='regular-12 gray_300'>
                                                                sara.cruz@example.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='add'>
                                                        <button className='button secondary size32'>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey='1'>
                                        <Accordion.Header>
                                            <div className='flex justify-between items-center w-100'>
                                                <div className='flex items-center'>
                                                    <button className='button secondary icon-only arrow'>
                                                        <DownArrowIcon />
                                                    </button>
                                                    <h3 className='regular-16 weight-500'>
                                                        Women’s Buying team
                                                        <span className='filter-chip'>
                                                            12
                                                        </span>
                                                    </h3>
                                                </div>
                                                <div className='add flex'>
                                                    <button className='button secondary size32'>
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='members-list'>
                                                <div className='single-member flex items-center justify-between mb-3'>
                                                    <div className='flex items-center'>
                                                        <div className='profile mr-3'>
                                                            <img
                                                                src='/images/user.svg'
                                                                alt='member'
                                                                className='w-[32px] h-[32px] object-cover rounded-full'
                                                            />
                                                        </div>
                                                        <div className='designation-info'>
                                                            <h3 className='regular-16 weight-500'>
                                                                Esther Howard{' '}
                                                                <span className='filter-chip'>
                                                                    Senior
                                                                    Production
                                                                    Merchandiser
                                                                </span>
                                                            </h3>
                                                            <p className='regular-12 gray_300'>
                                                                sara.cruz@example.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='add'>
                                                        <button className='button secondary size32'>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='single-member flex items-center justify-between mb-3'>
                                                    <div className='flex items-center'>
                                                        <div className='profile mr-3'>
                                                            <img
                                                                src='/images/user.svg'
                                                                alt='member'
                                                                className='w-[32px] h-[32px] object-cover rounded-full'
                                                            />
                                                        </div>
                                                        <div className='designation-info'>
                                                            <h3 className='regular-16 weight-500'>
                                                                Esther Howard{' '}
                                                                <span className='filter-chip'>
                                                                    Senior
                                                                    Production
                                                                    Merchandiser
                                                                </span>
                                                            </h3>
                                                            <p className='regular-12 gray_300'>
                                                                sara.cruz@example.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='add'>
                                                        <button className='button secondary size32'>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey='2'>
                                        <Accordion.Header>
                                            <div className='flex justify-between items-center w-100'>
                                                <div className='flex items-center'>
                                                    <button className='button secondary icon-only arrow'>
                                                        <DownArrowIcon />
                                                    </button>
                                                    <h3 className='regular-16 weight-500'>
                                                        Nitex Team
                                                        <span className='filter-chip'>
                                                            2
                                                        </span>
                                                    </h3>
                                                </div>
                                                <div className='add flex'>
                                                    <button className='button secondary size32'>
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='members-list'>
                                                <div className='single-member flex items-center justify-between mb-3'>
                                                    <div className='flex items-center'>
                                                        <div className='profile mr-3'>
                                                            <img
                                                                src='/images/user.svg'
                                                                alt='member'
                                                                className='w-[32px] h-[32px] object-cover rounded-full'
                                                            />
                                                        </div>
                                                        <div className='designation-info'>
                                                            <h3 className='regular-16 weight-500'>
                                                                Esther Howard{' '}
                                                                <span className='filter-chip'>
                                                                    Senior
                                                                    Production
                                                                    Merchandiser
                                                                </span>
                                                            </h3>
                                                            <p className='regular-12 gray_300'>
                                                                sara.cruz@example.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='add'>
                                                        <button className='button secondary size32'>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='single-member flex items-center justify-between mb-3'>
                                                    <div className='flex items-center'>
                                                        <div className='profile mr-3'>
                                                            <img
                                                                src='/images/user.svg'
                                                                alt='member'
                                                                className='w-[32px] h-[32px] object-cover rounded-full'
                                                            />
                                                        </div>
                                                        <div className='designation-info'>
                                                            <h3 className='regular-16 weight-500'>
                                                                Esther Howard{' '}
                                                                <span className='filter-chip'>
                                                                    Senior
                                                                    Production
                                                                    Merchandiser
                                                                </span>
                                                            </h3>
                                                            <p className='regular-12 gray_300'>
                                                                sara.cruz@example.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='add'>
                                                        <button className='button secondary size32'>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                                {/* <div className='members-list'>
                                    <div className='single-member flex items-center justify-between mb-3'>
                                        <div className='flex items-center'>
                                            <div className='profile mr-3'>
                                                <img
                                                    src='/images/user.svg'
                                                    alt='member'
                                                    className='w-[32px] h-[32px] object-cover rounded-full'
                                                />
                                            </div>
                                            <div className='designation-info'>
                                                <h3 className='regular-16 weight-500'>
                                                    Esther Howard{' '}
                                                    <span className='filter-chip'>
                                                        Senior Production
                                                        Merchandiser
                                                    </span>
                                                </h3>
                                                <p className='regular-12 gray_300'>
                                                    sara.cruz@example.com
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='single-member flex items-center justify-between mb-3'>
                                        <div className='flex items-center'>
                                            <div className='profile mr-3'>
                                                <img
                                                    src='/images/user.svg'
                                                    alt='member'
                                                    className='w-[32px] h-[32px] object-cover rounded-full'
                                                />
                                            </div>
                                            <div className='designation-info'>
                                                <h3 className='regular-16 weight-500'>
                                                    Esther Howard{' '}
                                                    <span className='filter-chip'>
                                                        Senior Production
                                                        Merchandiser
                                                    </span>
                                                </h3>
                                                <p className='regular-12 gray_300'>
                                                    sara.cruz@example.com
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='single-member flex items-center justify-between mb-3'>
                                        <div className='flex items-center'>
                                            <div className='profile mr-3'>
                                                <img
                                                    src='/images/user.svg'
                                                    alt='member'
                                                    className='w-[32px] h-[32px] object-cover rounded-full'
                                                />
                                            </div>
                                            <div className='designation-info'>
                                                <h3 className='regular-16 weight-500'>
                                                    Esther Howard{' '}
                                                    <span className='filter-chip'>
                                                        Senior Production
                                                        Merchandiser
                                                    </span>
                                                </h3>
                                                <p className='regular-12 gray_300'>
                                                    sara.cruz@example.com
                                                </p>
                                            </div>
                                        </div>
                                        <div className='add'>
                                            <button className='button secondary size32'>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}

            <div className='selected-item-count flex justify-center'>
                <div className='text-center'>
                    <button
                        className='button primary sm round'
                        onClick={() => setIsShowDraft(true)}
                    >
                        <LeftArrowIcon />
                        <span className='ml-2'>9 Styles</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default FavoriteDetailsListView
