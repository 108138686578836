import {
    GET_ALL_PRODUCTS_FROM_RANGE,
    SET_ALL_PRODUCTS_TO_RANGE
} from '../../@types/thunk.types'
import { closeLoader, openLoader } from '../../Loader'
import { RangeActions } from './index'
import { fetchProductsFromBuildRange } from '../../../services/v3/Range/RangeService'

const RangeThunks = {
    [GET_ALL_PRODUCTS_FROM_RANGE]: (postData) => async (dispatch) => {
        dispatch(openLoader())
        const data = await fetchProductsFromBuildRange(postData)
        dispatch(
            RangeActions[SET_ALL_PRODUCTS_TO_RANGE]({
                data
            })
        )
        dispatch(closeLoader())
    }
}

export default RangeThunks
