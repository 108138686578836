import React, { useState } from 'react'
import ModalContainer from '../../common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import {
    addNewFavouriteFolder,
    doFavourite
} from '../../services/v3/Favourite/FavouriteService'
import { useNavigate } from 'react-router-dom'
import { useRanges, useRangeTrackId } from '../../redux_toolkit/V3/Range'
import { setBreadCrumbRoutes } from '../../helpers/Util'

const AddToFavouriteRange = ({ isShowModal, closeModal }) => {
    const [isShowCreate, setIsShowCreate] = useState(false)
    const [rangeName, setRangeName] = useState('')
    const [error, setError] = useState(false)
    const rangeTrackId = useRangeTrackId()
    const rangeDesigns = useRanges()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleCreate = async () => {
        if (rangeName) {
            try {
                dispatch(openLoader())
                let params =`?name=${encodeURIComponent(rangeName)}&isRange=true`
                if (rangeTrackId) params += `&tid=${rangeTrackId}`
                const data = await addNewFavouriteFolder(params)
                let productIds = rangeDesigns?.map((item) => item.id)
                await doFavourite(`?productIds=${productIds.join(',')}&folderId=${
                    data.id
                }`)
                let newRoute = `/favourites/${data.id}`
                let visitingRoutes = [
                    {
                        key: '/favourites',
                        value: 'Favourites'
                    },
                    {
                        key: newRoute,
                        value: data.name
                    }
                ]
                setBreadCrumbRoutes(visitingRoutes)
                dispatch(closeLoader())
                closeModal()
                navigate(newRoute)
            } catch (e) {
                dispatch(closeLoader())
            }
        } else {
            setError(true)
        }
    }

    return (
        <>
            {isShowModal && (
                <ModalContainer
                    className='create-folder-modal'
                    onCloseModal={closeModal}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Add to Favorite
                                </h3>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={closeModal}
                            >
                                <CloseIcon />
                            </div>
                        </div>
                        <>
                            {!isShowCreate && (
                                <>
                                    <div className='common-popup-body p-4'>
                                        <p className='regular-18 black'>
                                            Do you want to favorite this range ?
                                        </p>
                                    </div>
                                    <div className='common-popup-footer'>
                                        <button
                                            className='button primary mr-3'
                                            onClick={() =>
                                                setIsShowCreate(true)
                                            }
                                        >
                                            <span>Yes</span>
                                        </button>
                                        <button
                                            className='button secondary'
                                            onClick={closeModal}
                                        >
                                            <span>No</span>
                                        </button>
                                    </div>
                                </>
                            )}
                        </>
                        <>
                            {isShowCreate && (
                                <>
                                    <div className='common-popup-body p-4'>
                                        <div className='form-group'>
                                            <label className='regular-14 gray_300 mb-2'>
                                                Range Name
                                            </label>
                                            <input
                                                type='text'
                                                name='title'
                                                placeholder=''
                                                className={
                                                    error ? 'required' : ''
                                                }
                                                value={rangeName}
                                                onChange={(e) =>
                                                    setRangeName(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='common-popup-footer'>
                                        <button
                                            className='button primary mr-3'
                                            onClick={handleCreate}
                                        >
                                            <span>Save</span>
                                        </button>
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </ModalContainer>
            )}
        </>
    )
}
export default AddToFavouriteRange
