import Http from '../../helpers/Http'
import { toast } from 'react-toastify'

export const getSampleList = (params) => {
    return Http.GET('getSampleList', params)
}

export const getColorList = () => {
    return Http.GET('getColorList')
}

export const getSizeList = () => {
    return Http.GET('getEnumList', '/info.nitex.app.measurement.Size')
}

export const postBuyerSampleRequest = (body) => {
    body.developmentSample = false
    return Http.POST('sampleRequest', body)
}

export const postBuyerSampleRequestForCollection = (collectionId, body) => {
    body.developmentSample = false
    return Http.POST('sampleRequestForColection', body, `/${collectionId}`)
}

export const getSampleDetailById = (id) => {
    return Http.GET('sampleRequestDetail', `/${id}`)
}

export const duplicateSampleItem = (sampleRequestId, body, sampleItemId) => {
    return Http.POST(
        'sampleRequestDetail',
        body,
        `/${sampleRequestId}/items/${sampleItemId}/copy`
    )
}

export const postBuyerApprove = (sampleRequestId, sampleItemId) => {
    return Http.POST(
        'sampleRequestDetail',
        '',
        `/${sampleRequestId}/items/${sampleItemId}/approve-by-buyer`
    )
}
export const postBuyerRevise = (sampleRequestId, sampleItemId) => {
    return Http.POST(
        'sampleRequestDetail',
        '',
        `/${sampleRequestId}/items/${sampleItemId}/revise-by-buyer`
    )
}

export const addMemberToSample = async (id, userId) => {
    try {
        const { data } = await Http.POST(
            'sampleMemberAction',
            {},
            `${id}/add-members?memberIds=${userId}`
        )
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}
