import React, { useState } from 'react'
import MoodboardThunks from '../../redux_toolkit/Moodboard/MoodboardThunks'
import { CREATE_NEW_MOODBOARD } from '../../redux_toolkit/@types/thunk.types'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const CreateMoodboardModal = () => {
    const [inputData, setInputData] = useState({})
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleChange = (e) => {
        let cloneObj = { ...inputData }
        cloneObj[e.target.name] = e.target.value
        setInputData(cloneObj)
    }

    const createNewMoodboard = async () => {
        let respose = await dispatch(
            MoodboardThunks[CREATE_NEW_MOODBOARD](
                inputData?.name,
                inputData?.description
            )
        )
        navigate('/moodboard/' + respose.data.id)
    }

    return (
        <div
            className='modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto'
            id='createMoodboardModal'
            tabIndex='-1'
            aria-labelledby='exampleModalCenterTitle'
            aria-modal='true'
            role='dialog'
        >
            <div className='modal-dialog max-w-[680px] overflow-hidden modal-dialog-centered relative w-auto pointer-events-none'>
                <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current'>
                    <div className='modal-header flex flex-shrink-0 items-center justify-between bg-primaryColor-shade-300 p-4 pl-8'>
                        <div>
                            <h5
                                className='text-xl font-bold leading-normal text-primaryColor uppercase'
                                id='exampleModalScrollableLabel'
                            >
                                Create Moodboard
                            </h5>
                        </div>

                        <button
                            type='button'
                            className='btn-close box-content w-4 h-4 p-1 !mr-0.5 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        ></button>
                    </div>
                    <div className='modal-body relative p-4'>
                        <div className='input-group flex items-center mb-3'>
                            <label htmlFor='text' className='label w-[30%]'>
                                Title
                            </label>
                            <input
                                type='text'
                                className={
                                    'form-field bg-primaryColor-shade-300 w-[70%]'
                                }
                                id='text'
                                placeholder='Write Here ...'
                                name='name'
                                value={inputData?.name || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-group flex items-center'>
                            <label htmlFor='text' className='label w-[30%]'>
                                Description
                            </label>
                            <textarea
                                value={inputData?.description || ''}
                                onChange={handleChange}
                                name='description'
                                cols='30'
                                rows='4'
                                className='form-field h-auto bg-primaryColor-shade-300 w-[70%]'
                                placeholder='Write Here ...'
                            />
                        </div>
                    </div>
                    <div className='modal-footer p-10 flex gap-6'>
                        <button
                            type='button'
                            className='btn w-[150px] bg-transparent font-normal border border-primaryColor text-primaryColor'
                            data-bs-dismiss='modal'
                        >
                            Close
                        </button>
                        <button
                            type='button'
                            className='btn w-full'
                            disabled={!inputData?.name}
                            data-bs-dismiss='modal'
                            onClick={() => createNewMoodboard()}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateMoodboardModal
