import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg'
import { ReactComponent as ChatIcon } from '../../../assets/icons/chat.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as PlusSquareIcon } from '../../../assets/icons/plus-square.svg'
import { ReactComponent as ApprovedIcon } from '../../../assets/icons/approved.svg'
import { ReactComponent as RecostIcon } from '../../../assets/icons/recost.svg'
import { ReactComponent as IconCollectionRemove } from '../../../assets/icons/old/collectionRemove.svg'
import { ReactComponent as RemoveSquareIcon } from '../../../assets/icons/minus-square.svg'
import RequestTimeline from '../samples/components/RequestTimeline'
import { useSampleSelector } from '../../redux_toolkit/Sample/SampleSlice'
import SampleThunks from '../../redux_toolkit/Sample/SampleThunks'
import {
    GET_SAMPLE_DETAIL,
    GET_TEAM_MEMBERS
} from '../../redux_toolkit/@types/thunk.types'
import { useTeams } from '../../redux_toolkit/Team'
import { TeamThunks } from '../../redux_toolkit/Team/TeamThunks'
import { BASE_URL } from '../../constant'
import {
    duplicateSampleItem,
    postBuyerApprove,
    postBuyerRevise
} from '../../services/Sample/SampleService'
import {
    SET_SAMPLE_DETAILS,
    SET_SELECT_PRODUCT_FROM_SAMPLE,
    SET_ALL_PRODUCT_FROM_SAMPLE,
    CLEAR_SELECTED_PRODUCT_FROM_SAMPLE,
    CLEAR_SAMPLE_DETAILS
} from '../../redux_toolkit/@types/action.types'
import { SampleActionsType } from '../../redux_toolkit/Sample/SampleSlice'
import BreadCrumb from '../../v3/common/BreadCrumb'
import AddPeople from '../../v3/common/AddPeople'
import Pagination from '../../common/Pagination'
import ConversationRightSidePanel from '../../v3/common/ConversationRightSidePanel'
import {
    changeDateFormat,
    isSetItem,
    isPackItem,
    _getKey,
    formatFeatureImageDocUrl,
    getColorWiseActivityStatusOfSample,
    getActionDoneBy
} from '../../helpers/Util'
import RequestForQuotes from '../../common/RequestForQuotes'
import DesignRightSidePanel from '../../v3/common/DesignRightSidePanel'
import {
    ConversationActionTypes,
    getCurrentPage
} from '../../redux_toolkit/Conversation'
import {
    CLEAR_ALL_CHATS,
    NEW_INCOMING_MESSAGE_SUCCESS,
    SET_CURRENT_ACTIVE_PAGE
} from '../../redux_toolkit/@types/action.types'
import PackSetOrTag from '../../v3/common/PackOrSetTag'
import DesignImages from '../../v3/common/DesignImages'

const SampleDetails = () => {
    const [showTimelineModal, setShowTimelineModal] = useState(false)
    const [isQuoteShow, setIsQuoteShow] = useState(false)
    const [sampleItemToEdit, setSampleItemToEdit] = useState([])
    const [duplicateRowInput, setDuplicateRowInput] = useState({})
    const [sampleStore, setSampleStore] = useState(null)
    const [errors, setErrors] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10)
    const [openConversationId, setOpenConversationId] = useState(null)
    const [selectedStyle, setSelectedStyle] = useState(null)
    const [isDesignClick, setIsDesignClick] = useState(null)
    const members = useTeams()
    const { id } = useParams()
    const sampleStoreData = useSampleSelector()
    const dispatch = useDispatch()
    const sizeRef = useRef(null)
    const quantityRef = useRef(null)
    const [searchParams] = useSearchParams()
    const isShowComment = searchParams.get('showComment')
    const productId = searchParams.get('productId')
    const [selectedStylesInfo, setSelectedStylesInfo] = useState(null)

    useEffect(() => {
        if (isShowComment) {
            dispatch({
                type: ConversationActionTypes[SET_CURRENT_ACTIVE_PAGE],
                payload: {
                    id: productId,
                    type: 'PRODUCT_CONVERSATION',
                    pageType: 'DETAILS'
                }
            })
            setOpenConversationId(productId)
        }
    }, [isShowComment])

    useEffect(() => {
        setSampleStore(sampleStoreData)
    }, [sampleStoreData])

    useEffect(() => {
        return () => {
            dispatch({
                type: SampleActionsType[CLEAR_SELECTED_PRODUCT_FROM_SAMPLE]
            })
            dispatch({
                type: SampleActionsType[CLEAR_SAMPLE_DETAILS]
            })
            dispatch({
                type: ConversationActionTypes[CLEAR_ALL_CHATS]
            })
        }
    }, [])

    useEffect(() => {
        getSampleDetail()
        dispatch(
            TeamThunks[GET_TEAM_MEMBERS](
                `${BASE_URL}/sample/request/${id}/members`,
                true
            )
        )
    }, [id])

    const getSampleDetail = () => {
        dispatch(SampleThunks[GET_SAMPLE_DETAIL](id))
    }

    const approvedOffer = async (sampleItemId) => {
        try {
            await postBuyerApprove(id, sampleItemId)
            toast.success('Approved')
            getSampleDetail()
        } catch ({ response }) {
            if (response) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    const revisedOffer = async (sampleItemId) => {
        try {
            await postBuyerRevise(id, sampleItemId)
            toast.success('Revised')
            getSampleDetail()
        } catch ({ response }) {
            if (response) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    const handleReSample = (sampleItemId) => {
        revisedOffer(sampleItemId)
    }

    const handleApprovedOffer = (sampleItemId) => {
        approvedOffer(sampleItemId)
    }

    const duplicatedSampleRow = async (sampleItemId, body) => {
        try {
            const { data } = await duplicateSampleItem(id, body, sampleItemId)
            if (data) toast.success(data.message)
            getSampleDetail()
            setSampleItemToEdit([])
        } catch ({ response }) {
            if (response) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    const duplicateSampleRowitem = (sampleItemId, index) => {
        if (sampleItemToEdit.length > 0) return
        const i = index + 1

        const oldSample = { ...sampleStore.sample }
        const oldSampleItemResponse = [
            ...sampleStore.sample.sampleRequestItemResponseList
        ]

        const duplicateRow = oldSampleItemResponse.find(
            (el) => el.id === sampleItemId
        )

        if (!duplicateRow) return

        const newSampleResponseList = [...oldSampleItemResponse]
        newSampleResponseList.splice(i, 0, { ...duplicateRow })

        oldSample.sampleRequestItemResponseList = newSampleResponseList

        dispatch({
            type: SampleActionsType[SET_SAMPLE_DETAILS],
            payload: oldSample
        })

        setSampleItemToEdit([{ sampleItemId, i }])
    }

    const removeSampleRowitem = (sampleItemId, index) => {
        const oldSampleItemResponse = [
            ...sampleStore.sample.sampleRequestItemResponseList
        ]
        oldSampleItemResponse.splice(index, 1)
        const oldSample = { ...sampleStore.sample }
        oldSample.sampleRequestItemResponseList = oldSampleItemResponse
        dispatch({
            type: SampleActionsType[SET_SAMPLE_DETAILS],
            payload: oldSample
        })
        setSampleItemToEdit([])
    }

    const hasRowItemEdit = (sampleItemId, index) => {
        const isExist = sampleItemToEdit.filter(
            (el) => el.sampleItemId === sampleItemId && el.i === index
        )
        return !!(isExist.length == 1)
    }

    const handleSampleRowInputChange = (sampleItemId, name, value) => {
        let porcessValue = value
        if (name == 'quantity') {
            porcessValue = Math.abs(value || 0)
            if (porcessValue > 0) {
                setErrors({})
            } else {
                setErrors({ quantity: 'Quantity required' })
            }
        }

        setDuplicateRowInput({
            ...duplicateRowInput,
            ...{ [name]: porcessValue }
        })

        // const updatedSampleStore = JSON.parse(JSON.stringify(sampleStore))
        // const itemToUpdate =
        //     updatedSampleStore.sample.sampleRequestItemResponseList.find(
        //         (item) => item.id === sampleItemId
        //     )
        // if (itemToUpdate) {
        //     itemToUpdate[name] = value
        // }
        // setSampleStore(updatedSampleStore)

        //    if (quantityRef.current && name === 'quantity') {
        //        quantityRef.current.focus()
        //    } else {
        //        sizeRef.current.focus()
        //    }
    }

    const handleSubmitDuplicateSampleRowitem = (e) => {
        e.preventDefault()
        const { material, quantity, size } = duplicateRowInput

        let error = {}
        if (!quantity || quantity === 0) {
            error['quantity'] = 'Quantity required'
            setErrors(error)
            return
        }

        const reqBody = {
            material,
            quantity,
            size
        }
        duplicatedSampleRow(sampleItemToEdit[0].sampleItemId, reqBody)
        setSampleItemToEdit([])
    }

    const handleClose = () => {
        setShowTimelineModal(false)
    }

    const renderActionStatus = (sampleItem) => {
        switch (sampleItem?.currentActivity?.activityType) {
            case 'SEND_TO_BUYER':
            case 'RECEIVED_BY_BUYER':
                if (sampleItem?.buyerApprovalStatus === 'APPROVED') {
                    return (
                        <button className='button primary round' disabled>
                            <span className='mr-2'>Approved</span>
                            <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <g id='Group'>
                                    <g id='Group_2'>
                                        <path
                                            id='Path'
                                            d='M16.3008 6.20312L10.0042 12.4998L6.85667 9.35146'
                                            stroke='white'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            id='Path_2'
                                            d='M17.5 10C17.5 14.1425 14.1425 17.5 10 17.5C5.8575 17.5 2.5 14.1425 2.5 10C2.5 5.8575 5.8575 2.5 10 2.5C11.2108 2.5 12.3508 2.79417 13.3633 3.30333'
                                            stroke='white'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </g>
                                </g>
                            </svg>
                        </button>
                    )
                } else if (
                    sampleItem?.buyerApprovalStatus === 'REQUEST_FOR_REVISION'
                ) {
                    return (
                        <button className='button pill round flexx items-center'>
                            <span className='mr-2 whitespace-nowrap'>
                                Re-sample Requested
                            </span>
                            <svg
                                width={22}
                                height={22}
                                viewBox='0 0 22 22'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <g id='Group'>
                                    <path
                                        id='Path'
                                        d='M3.49687 9.33216L6.70904 6.11999C7.85523 4.97376 9.40986 4.3299 11.0308 4.33008V4.33008C13.5297 4.33026 15.7767 5.85161 16.7049 8.17168L16.8358 8.49848'
                                        stroke='#21242B'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                    <path
                                        id='Path_2'
                                        d='M6.83159 9.33277H3.49687V5.99805'
                                        stroke='#21242B'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                    <path
                                        id='Path_3'
                                        d='M18.5031 12.668L15.2909 15.8801C14.1448 17.0264 12.5901 17.6702 10.9691 17.6701V17.6701C8.47031 17.6699 6.22328 16.1485 5.29512 13.8285L5.16423 13.5016'
                                        stroke='#21242B'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                    <path
                                        id='Path_4'
                                        d='M15.1684 12.668H18.5031V16.0027'
                                        stroke='#21242B'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </g>
                            </svg>
                        </button>
                    )
                } else {
                    return (
                        <div className='flex items-center'>
                            <button
                                className='button primary regular-12 round mr-2'
                                onClick={() =>
                                    handleApprovedOffer(sampleItem?.id)
                                }
                            >
                                Approve
                            </button>
                            <button
                                className='button secondary regular-12 round whitespace-nowrap'
                                onClick={() => handleReSample(sampleItem?.id)}
                            >
                                Re-sample
                            </button>
                        </div>
                    )
                }
            default:
                break
        }
    }

    const indexOfFirstPost = (currentPage - 1) * postsPerPage
    const indexOfLastPost = indexOfFirstPost + postsPerPage
    const currentPosts =
        sampleStore?.sample?.sampleRequestItemResponseList?.slice(
            indexOfFirstPost,
            indexOfLastPost
        )

    const onChangePostPerPage = (value) => {
        setPostsPerPage(value)
        setCurrentPage(1)
    }
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    const isCheckboxChecked = (quoteItem) => {
        return sampleStore?.selectedProducts?.includes(quoteItem?.id)
    }

    const isAllCheckboxChecked = () => {
        return (
            sampleStore?.selectedProducts?.length === getAllSampleIds()?.length
        )
    }

    const handleCheckbox = (e) => {
        let { checked, value } = e.target
        dispatch({
            type: SampleActionsType[SET_SELECT_PRODUCT_FROM_SAMPLE],
            payload: { checked, value: parseInt(value) }
        })
    }

    const getAllSampleIds = () => {
        let sampleIds = currentPosts?.map((item) => item.id)
        return sampleIds
        // return [...new Set(sampleIds)]
    }

    const onSelectAll = async () => {
        let sampleIds = await getAllSampleIds()
        dispatch({
            type: SampleActionsType[SET_ALL_PRODUCT_FROM_SAMPLE],
            payload: { checked: !isAllCheckboxChecked(), items: sampleIds }
        })
    }

    const getSelectedProductIds = () => {
        let productIds = sampleStore?.sample?.sampleRequestItemResponseList
            .filter((item) => sampleStore?.selectedProducts.includes(item.id))
            .map((item) => item.productId)
        return [...new Set(productIds)]
    }

    const onHidePopup = () => {
        setIsQuoteShow(false)
        dispatch({
            type: SampleActionsType[CLEAR_SELECTED_PRODUCT_FROM_SAMPLE]
        })
    }

    const onClearChat = (id) => {
        setSampleStore((prevSample) => {
            const updatedSampleStore = {
                ...prevSample,
                sample: {
                    ...prevSample?.sample,
                    sampleRequestItemResponseList:
                        prevSample?.sample?.sampleRequestItemResponseList?.map(
                            (item) => {
                                if (item.id === id) {
                                    return {
                                        ...item,
                                        noOfUnreadMessage: 0
                                    }
                                }
                                return item
                            }
                        )
                }
            }
            return updatedSampleStore
        })
    }

    const renderMaterials = (materialList = []) => {
        return materialList.map((item) => (
            <p key={_getKey()} className='mb-1'>
                {item?.toLowerCase()}
            </p>
        ))
    }

    const renderTableBody = () => {
        return currentPosts?.map((sampleItem, index) => {
            return (
                <>
                    <tr key={`${index}_post`}>
                        {/* checkbox */}
                        <td
                            rowSpan={
                                isSetItem(sampleItem)
                                    ? sampleItem
                                          .setMemberSampleRequestItemResponseList
                                          .length
                                    : 1
                            }
                        >
                            {!hasRowItemEdit(sampleItem.id, index) ? (
                                <input
                                    type='checkbox'
                                    id={sampleItem?.id}
                                    value={sampleItem?.id}
                                    checked={isCheckboxChecked(sampleItem)}
                                    onChange={handleCheckbox}
                                />
                            ) : (
                                <input
                                    className='checkbox-disabled'
                                    type='checkbox'
                                    disabled
                                />
                            )}
                        </td>

                        {/* Style */}
                        <td
                            className='relative min-w-[240px] w-[240px]'
                            rowSpan={
                                isSetItem(sampleItem)
                                    ? sampleItem
                                          .setMemberSampleRequestItemResponseList
                                          .length
                                    : 1
                            }
                        >
                            {!sampleItem.isSameProduct && (
                                <div className='flex items-center'>
                                    <div className='w-10'>
                                        <DesignImages
                                            designInfo={formatFeatureImageDocUrl(
                                                sampleItem
                                            )}
                                        />
                                    </div>
                                    <div className='ml-2'>
                                        {isSetItem(sampleItem) && (
                                            <p className='mb-1'>
                                                <PackSetOrTag tagName='Set' />
                                            </p>
                                        )}
                                        {isPackItem(sampleItem) && (
                                            <p className='mb-1'>
                                                <PackSetOrTag tagName='Pack' />
                                            </p>
                                        )}
                                        <p
                                            className='blue regular-14 cursor-pointer '
                                            onClick={() => {
                                                setIsDesignClick(
                                                    sampleItem?.productId
                                                )
                                                setSelectedStylesInfo({
                                                    id: sampleItem?.productId,
                                                    isSet: isSetItem(sampleItem)
                                                })
                                            }}
                                        >
                                            {sampleItem?.referenceNumber}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {!isSetItem(sampleItem) &&
                                sampleItemToEdit?.length === 0 && (
                                    <span
                                        className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'
                                        onClick={() =>
                                            duplicateSampleRowitem(
                                                sampleItem.id,
                                                index
                                            )
                                        }
                                    >
                                        <PlusSquareIcon />
                                    </span>
                                )}

                            {!isSetItem(sampleItem) &&
                                sampleItemToEdit?.length > 0 &&
                                sampleItemToEdit[0]?.i === index && (
                                    <span
                                        className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'
                                        onClick={() =>
                                            removeSampleRowitem(
                                                sampleItem.id,
                                                index
                                            )
                                        }
                                    >
                                        <RemoveSquareIcon />
                                    </span>
                                )}
                        </td>

                        {/* Fabric */}
                        <td>
                            {isSetItem(sampleItem)
                                ? renderMaterials(
                                      sampleItem
                                          ?.setMemberSampleRequestItemResponseList[0]
                                          ?.materialList
                                  )
                                : sampleItem.materialList &&
                                  renderMaterials(sampleItem?.materialList)}
                        </td>

                        {/* Size */}
                        <td
                            rowSpan={
                                isSetItem(sampleItem)
                                    ? sampleItem
                                          .setMemberSampleRequestItemResponseList
                                          .length
                                    : 1
                            }
                        >
                            {' '}
                            {!hasRowItemEdit(sampleItem.id, index) && (
                                <ul
                                    style={{
                                        listStyleType: 'none',
                                        padding: 0,
                                        margin: 0
                                    }}
                                >
                                    {isSetItem(sampleItem)
                                        ? sampleItem.setMemberSampleRequestItemResponseList &&
                                          sampleItem?.setMemberSampleRequestItemResponseList[0]?.sampleItemDetailsResponseList.map(
                                              (sizeOfMember) => (
                                                  <li key={sizeOfMember.id}>
                                                      {sizeOfMember.size}
                                                  </li>
                                              )
                                          )
                                        : sampleItem?.sampleItemDetailsResponseList &&
                                          sampleItem?.sampleItemDetailsResponseList.map(
                                              (s) => {
                                                  return (
                                                      <li key={s.id}>
                                                          {s.size}
                                                      </li>
                                                  )
                                              }
                                          )}
                                </ul>
                            )}
                            {hasRowItemEdit(sampleItem.id, index) && (
                                <div className='input-group bordered-style w-[200px]'>
                                    <input
                                        type='text'
                                        placeholder='Size'
                                        name='size'
                                        className='form-field bg-transparent border border-primaryColor w-[200px]'
                                        onChange={(e) =>
                                            handleSampleRowInputChange(
                                                sampleItem.id,
                                                'size',
                                                e.target.value
                                            )
                                        }
                                        ref={sizeRef}
                                        value={duplicateRowInput?.size || ''}
                                    />
                                </div>
                            )}
                        </td>

                        {/* Quantity */}
                        <td
                            rowSpan={
                                isSetItem(sampleItem)
                                    ? sampleItem
                                          .setMemberSampleRequestItemResponseList
                                          .length
                                    : 1
                            }
                        >
                            {' '}
                            <div className='flex gap-2'>
                                {!hasRowItemEdit(sampleItem.id, index) && (
                                    <ul
                                        style={{
                                            listStyleType: 'none',
                                            padding: 0,
                                            margin: 0
                                        }}
                                    >
                                        {isSetItem(sampleItem)
                                            ? sampleItem.setMemberSampleRequestItemResponseList &&
                                              sampleItem?.setMemberSampleRequestItemResponseList[0]?.sampleItemDetailsResponseList.map(
                                                  (sizeOfMember) => (
                                                      <li key={sizeOfMember.id}>
                                                          {
                                                              sizeOfMember?.quantity
                                                          }
                                                      </li>
                                                  )
                                              )
                                            : sampleItem?.sampleItemDetailsResponseList &&
                                              sampleItem?.sampleItemDetailsResponseList.map(
                                                  (s) => {
                                                      return (
                                                          <li key={s.id}>
                                                              {s.quantity}
                                                          </li>
                                                      )
                                                  }
                                              )}
                                    </ul>
                                )}

                                {hasRowItemEdit(sampleItem.id, index) && (
                                    <div className='input-group relative'>
                                        <input
                                            type='number'
                                            className='form-field bg-transparent border border-primaryColor w-[200px]'
                                            id='quantity'
                                            placeholder='Quantity'
                                            name='quantity'
                                            min='0'
                                            onChange={(e) =>
                                                handleSampleRowInputChange(
                                                    sampleItem.id,
                                                    e.currentTarget.name,
                                                    e.currentTarget.value
                                                )
                                            }
                                            style={{
                                                borderColor:
                                                    errors && errors.quantity
                                                        ? 'red'
                                                        : ''
                                            }}
                                            value={
                                                duplicateRowInput.quantity || ''
                                            }
                                            ref={quantityRef}
                                        />
                                        <p
                                            className='absolute top-[52px] 5xl:top-[58px] text-[13px]'
                                            style={{ color: 'red' }}
                                        >
                                            {errors && errors.quantity}
                                        </p>
                                    </div>
                                )}
                            </div>{' '}
                        </td>

                        {/* Status */}
                        <td
                            rowSpan={
                                isSetItem(sampleItem)
                                    ? sampleItem
                                          .setMemberSampleRequestItemResponseList
                                          .length
                                    : 1
                            }
                        >
                            <span
                                className='status cursor-pointer'
                                onClick={(e) =>
                                    setSelectedStyle(
                                        isSetItem(sampleItem)
                                            ? sampleItem
                                                  ?.setMemberSampleRequestItemResponseList[0]
                                            : sampleItem
                                    )
                                }
                            >
                                {getColorWiseActivityStatusOfSample(
                                    sampleItem?.currentActivity
                                        ?.activityType === 'REQUESTED'
                                        ? 'SAMPLE_REQUESTED'
                                        : sampleItem?.currentActivity
                                              ?.activityType,
                                    getActionDoneBy(
                                        sampleItem?.currentActivity?.userType ||
                                            null
                                    )
                                )}
                            </span>
                        </td>

                        {/* Due date	 */}
                        <td
                            rowSpan={
                                isSetItem(sampleItem)
                                    ? sampleItem
                                          .setMemberSampleRequestItemResponseList
                                          .length
                                    : 1
                            }
                        >
                            {' '}
                            {sampleItem.requireDate && (
                                <>
                                    <span className='gray_400 ml-2'>
                                        {changeDateFormat(
                                            sampleItem.requireDate,
                                            'YYYY-MM-DD',
                                            'Do MMM, YY'
                                        )}
                                    </span>
                                </>
                            )}
                        </td>

                        {/* Action */}
                        <td
                            rowSpan={
                                isSetItem(sampleItem)
                                    ? sampleItem
                                          .setMemberSampleRequestItemResponseList
                                          .length
                                    : 1
                            }
                        >
                            {!hasRowItemEdit(sampleItem.id, index) &&
                                renderActionStatus(sampleItem)}
                            {hasRowItemEdit(sampleItem.id, index) && (
                                <button
                                    onClick={handleSubmitDuplicateSampleRowitem}
                                    type='button'
                                    className='button primary'
                                >
                                    Save
                                </button>
                            )}
                        </td>

                        {/* Conversation */}
                        <td
                            className='text-center'
                            rowSpan={
                                isSetItem(sampleItem)
                                    ? sampleItem
                                          .setMemberSampleRequestItemResponseList
                                          .length
                                    : 1
                            }
                        >
                            {!hasRowItemEdit(sampleItem.id, index) && (
                                <button
                                    className='button secondary icon-only chat-btn'
                                    onClick={() => {
                                        dispatch({
                                            type: ConversationActionTypes[
                                                SET_CURRENT_ACTIVE_PAGE
                                            ],
                                            payload: {
                                                id: sampleItem?.productId,
                                                type: 'PRODUCT_CONVERSATION',
                                                pageType: 'DETAILS'
                                            }
                                        })
                                        setOpenConversationId(
                                            sampleItem?.productId
                                        )
                                        onClearChat(sampleItem?.id)
                                    }}
                                >
                                    <ChatIcon />
                                    {sampleItem?.noOfUnreadMessage > 0 && (
                                        <span className='count absolute'>
                                            {sampleItem?.noOfUnreadMessage}
                                        </span>
                                    )}
                                </button>
                            )}
                        </td>
                    </tr>

                    {/* not row span column */}
                    {isSetItem(sampleItem) &&
                        sampleItem?.setMemberSampleRequestItemResponseList
                            ?.length > 0 &&
                        sampleItem?.setMemberSampleRequestItemResponseList
                            .slice(1)
                            .map((memberOfSet) => (
                                <tr key={memberOfSet.id}>
                                    <td>
                                        {renderMaterials(
                                            memberOfSet?.materialList
                                        )}
                                    </td>
                                </tr>
                            ))}
                </>
            )
        })
    }
    const getMemberList = () => {
        return members?.data?.filter((item) =>
            ['BUYER', 'ACCOUNT_MANAGER', 'PROJECT_MANAGER'].includes(
                item.primaryUserType
            )
        )
    }

    const renderTableView = () => {
        return (
            <div className='sample-table mt-6'>
                <table className='table sample-list-table'>
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type='checkbox'
                                    id='SelectAll'
                                    checked={isAllCheckboxChecked()}
                                    onChange={onSelectAll}
                                />
                            </th>
                            <th>Style</th>
                            <th>Fabric</th>
                            <th>Size</th>
                            <th>Quantity</th>
                            <th>Status</th>
                            <th>Due date</th>
                            <th>Action</th>
                            <th>Conversation</th>
                        </tr>
                    </thead>
                    <tbody>{renderTableBody()}</tbody>
                </table>
            </div>
        )
    }

    const renderCardView = () => {
        return (
            <div className='tab-view-table sample-tab-view-table py-4'>
                <div className='select-all mb-2'>
                    <div className='flex items-center'>
                        <input
                            type='checkbox'
                            id='SelectAll'
                            checked={isAllCheckboxChecked()}
                            onChange={onSelectAll}
                        />
                        <label className='text-base blue ml-2'>
                            Select all
                        </label>
                    </div>
                </div>
                <div className='all-rows'>
                    {currentPosts?.map((sampleItem, index) => {
                        return (
                            <div key={`${index}_post`}>
                                <div className='single-row'>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <td
                                                    rowSpan='8'
                                                    className='w-[50px] min-w-[50px]'
                                                >
                                                    {!hasRowItemEdit(
                                                        sampleItem.id,
                                                        index
                                                    ) ? (
                                                        <input
                                                            type='checkbox'
                                                            id={sampleItem?.id}
                                                            value={
                                                                sampleItem?.id
                                                            }
                                                            checked={isCheckboxChecked(
                                                                sampleItem
                                                            )}
                                                            onChange={
                                                                handleCheckbox
                                                            }
                                                        />
                                                    ) : (
                                                        <input
                                                            className='checkbox-disabled'
                                                            type='checkbox'
                                                            disabled
                                                        />
                                                    )}

                                                    {sampleItemToEdit?.length >
                                                        0 &&
                                                        sampleItemToEdit[0]
                                                            ?.i === index && (
                                                            <button
                                                                className='button link remove-row'
                                                                onClick={() =>
                                                                    removeSampleRowitem(
                                                                        sampleItem.id,
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <RemoveSquareIcon />
                                                            </button>
                                                        )}
                                                </td>
                                                <td className='heading'>
                                                    Style
                                                </td>
                                                <td>
                                                    {!sampleItem.isSameProduct && (
                                                        <div className='flex items-center'>
                                                            <div className='w-10'>
                                                                <DesignImages
                                                                    designInfo={formatFeatureImageDocUrl(
                                                                        sampleItem
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className='ml-2'>
                                                                {isSetItem(
                                                                    sampleItem
                                                                ) && (
                                                                    <PackSetOrTag tagName='Set' />
                                                                )}
                                                                {isPackItem(
                                                                    sampleItem
                                                                ) && (
                                                                    <PackSetOrTag tagName='Pack' />
                                                                )}
                                                                <p
                                                                    className='blue regular-14 cursor-pointer '
                                                                    onClick={() => {
                                                                        setIsDesignClick(
                                                                            sampleItem?.productId
                                                                        )
                                                                        setSelectedStylesInfo(
                                                                            {
                                                                                id: sampleItem?.productId,
                                                                                isSet: isSetItem(
                                                                                    sampleItem
                                                                                )
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    {
                                                                        sampleItem?.referenceNumber
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Fabric
                                                </td>
                                                <td>
                                                    {isSetItem(sampleItem) ? (
                                                        <ul className='with-bullet'>
                                                            {sampleItem?.setMemberSampleRequestItemResponseList &&
                                                                sampleItem?.setMemberSampleRequestItemResponseList.map(
                                                                    (member) =>
                                                                        member?.materialList.map(
                                                                            (
                                                                                material
                                                                            ) => (
                                                                                <li
                                                                                    key={_getKey()}
                                                                                >
                                                                                    {
                                                                                        material
                                                                                    }
                                                                                </li>
                                                                            )
                                                                        )
                                                                )}
                                                        </ul>
                                                    ) : (
                                                        sampleItem.materialList &&
                                                        sampleItem
                                                            ?.materialList[0]
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Size
                                                </td>
                                                <td>
                                                    {' '}
                                                    {!hasRowItemEdit(
                                                        sampleItem.id,
                                                        index
                                                    ) && (
                                                        <ul
                                                            style={{
                                                                listStyleType:
                                                                    'none',
                                                                padding: 0,
                                                                margin: 0
                                                            }}
                                                        >
                                                            {isSetItem(
                                                                sampleItem
                                                            )
                                                                ? sampleItem.setMemberSampleRequestItemResponseList &&
                                                                  sampleItem?.setMemberSampleRequestItemResponseList[0]?.sampleItemDetailsResponseList.map(
                                                                      (
                                                                          sizeOfMember
                                                                      ) => (
                                                                          <li
                                                                              key={
                                                                                  sizeOfMember.id
                                                                              }
                                                                          >
                                                                              {
                                                                                  sizeOfMember.size
                                                                              }
                                                                          </li>
                                                                      )
                                                                  )
                                                                : sampleItem?.sampleItemDetailsResponseList &&
                                                                  sampleItem?.sampleItemDetailsResponseList.map(
                                                                      (s) => {
                                                                          return (
                                                                              <li
                                                                                  key={
                                                                                      s.id
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      s.size
                                                                                  }
                                                                              </li>
                                                                          )
                                                                      }
                                                                  )}
                                                        </ul>
                                                    )}
                                                    {hasRowItemEdit(
                                                        sampleItem.id,
                                                        index
                                                    ) && (
                                                        <div className='input-group bordered-style w-[200px]'>
                                                            <input
                                                                type='text'
                                                                placeholder='Size'
                                                                name='size'
                                                                className='form-field bg-transparent border border-primaryColor w-[200px]'
                                                                onChange={(e) =>
                                                                    handleSampleRowInputChange(
                                                                        sampleItem.id,
                                                                        'size',
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                value={
                                                                    duplicateRowInput?.size ||
                                                                    ''
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Quantity
                                                </td>
                                                <td>
                                                    {!hasRowItemEdit(
                                                        sampleItem.id,
                                                        index
                                                    ) && (
                                                        <ul
                                                            style={{
                                                                listStyleType:
                                                                    'none',
                                                                padding: 0,
                                                                margin: 0
                                                            }}
                                                        >
                                                            {isSetItem(
                                                                sampleItem
                                                            )
                                                                ? sampleItem.setMemberSampleRequestItemResponseList &&
                                                                  sampleItem?.setMemberSampleRequestItemResponseList[0]?.sampleItemDetailsResponseList.map(
                                                                      (
                                                                          sizeOfMember
                                                                      ) => (
                                                                          <li
                                                                              key={
                                                                                  sizeOfMember.id
                                                                              }
                                                                          >
                                                                              {
                                                                                  sizeOfMember.quantity
                                                                              }
                                                                          </li>
                                                                      )
                                                                  )
                                                                : sampleItem?.sampleItemDetailsResponseList &&
                                                                  sampleItem?.sampleItemDetailsResponseList.map(
                                                                      (s) => {
                                                                          return (
                                                                              <li
                                                                                  key={
                                                                                      s.id
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      s.quantity
                                                                                  }
                                                                              </li>
                                                                          )
                                                                      }
                                                                  )}
                                                        </ul>
                                                    )}

                                                    {hasRowItemEdit(
                                                        sampleItem.id,
                                                        index
                                                    ) && (
                                                        <div className='input-group bordered-style w-[200px]'>
                                                            <input
                                                                type='number'
                                                                className='form-field bg-transparent border border-primaryColor w-[200px]'
                                                                id='quantity'
                                                                placeholder='Quantity'
                                                                name='quantity'
                                                                min='0'
                                                                onChange={(e) =>
                                                                    handleSampleRowInputChange(
                                                                        sampleItem.id,
                                                                        e
                                                                            .currentTarget
                                                                            .name,
                                                                        e
                                                                            .currentTarget
                                                                            .value
                                                                    )
                                                                }
                                                                style={{
                                                                    borderColor:
                                                                        errors &&
                                                                        errors.quantity
                                                                            ? 'red'
                                                                            : ''
                                                                }}
                                                                value={
                                                                    duplicateRowInput.quantity ||
                                                                    ''
                                                                }
                                                            />
                                                            <p
                                                                className='absolute top-[52px] 5xl:top-[58px] text-[13px]'
                                                                style={{
                                                                    color: 'red'
                                                                }}
                                                            >
                                                                {errors &&
                                                                    errors.quantity}
                                                            </p>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Status
                                                </td>
                                                <td>
                                                    {isSetItem(sampleItem) ? (
                                                        sampleItem?.setMemberSampleRequestItemResponseList.map(
                                                            (member) => (
                                                                <div
                                                                    key={
                                                                        member?.id
                                                                    }
                                                                    className='mb-2'
                                                                >
                                                                    <span
                                                                        className='status'
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            setSelectedStyle(
                                                                                member
                                                                            )
                                                                        }
                                                                    >
                                                                        {getColorWiseActivityStatusOfSample(
                                                                            sampleItem
                                                                                ?.currentActivity
                                                                                ?.activityType ===
                                                                                'REQUESTED'
                                                                                ? 'SAMPLE_REQUESTED'
                                                                                : sampleItem
                                                                                      ?.currentActivity
                                                                                      ?.activityType,
                                                                            getActionDoneBy(
                                                                                sampleItem
                                                                                    ?.currentActivity
                                                                                    ?.userType ||
                                                                                    null
                                                                            )
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            )
                                                        )
                                                    ) : (
                                                        <span
                                                            className='status'
                                                            onClick={(e) =>
                                                                setSelectedStyle(
                                                                    sampleItem
                                                                )
                                                            }
                                                        >
                                                            {getColorWiseActivityStatusOfSample(
                                                                sampleItem
                                                                    ?.currentActivity
                                                                    ?.activityType ===
                                                                    'REQUESTED'
                                                                    ? 'SAMPLE_REQUESTED'
                                                                    : sampleItem
                                                                          ?.currentActivity
                                                                          ?.activityType,
                                                                getActionDoneBy(
                                                                    sampleItem
                                                                        ?.currentActivity
                                                                        ?.userType ||
                                                                        null
                                                                )
                                                            )}
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Due Date
                                                </td>
                                                <td>
                                                    {sampleItem.requireDate && (
                                                        <>
                                                            <span className='gray_400 ml-2'>
                                                                {changeDateFormat(
                                                                    sampleItem.requireDate,
                                                                    'YYYY-MM-DD',
                                                                    'Do MMM, YY'
                                                                )}
                                                            </span>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Action
                                                </td>
                                                <td>
                                                    {!hasRowItemEdit(
                                                        sampleItem.id,
                                                        index
                                                    ) &&
                                                        renderActionStatus(
                                                            sampleItem
                                                        )}
                                                    {hasRowItemEdit(
                                                        sampleItem.id,
                                                        index
                                                    ) && (
                                                        <button
                                                            onClick={
                                                                handleSubmitDuplicateSampleRowitem
                                                            }
                                                            type='button'
                                                            className='button primary'
                                                        >
                                                            Save
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Conversation
                                                </td>
                                                <td>
                                                    {!hasRowItemEdit(
                                                        sampleItem.id,
                                                        index
                                                    ) && (
                                                        <button
                                                            className='button secondary icon-only chat-btn'
                                                            onClick={() => {
                                                                dispatch({
                                                                    type: ConversationActionTypes[
                                                                        SET_CURRENT_ACTIVE_PAGE
                                                                    ],
                                                                    payload: {
                                                                        id: sampleItem?.productId,
                                                                        type: 'PRODUCT_CONVERSATION',
                                                                        pageType:
                                                                            'DETAILS'
                                                                    }
                                                                })
                                                                setOpenConversationId(
                                                                    sampleItem?.productId
                                                                )
                                                                onClearChat(
                                                                    sampleItem?.id
                                                                )
                                                            }}
                                                        >
                                                            <ChatIcon />
                                                            {sampleItem?.noOfUnreadMessage >
                                                                0 && (
                                                                <span className='count absolute'>
                                                                    {
                                                                        sampleItem?.noOfUnreadMessage
                                                                    }
                                                                </span>
                                                            )}
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className='add-row'>
                                    <button
                                        className='button link my-5'
                                        onClick={() =>
                                            duplicateSampleRowitem(
                                                sampleItem.id,
                                                index
                                            )
                                        }
                                        disabled={
                                            sampleItemToEdit?.length !== 0
                                        }
                                    >
                                        <PlusSquareIcon /> Add new style
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        sampleStore && (
            <>
                <div>
                    <div className='flex justify-between py-3 pr-4'>
                        <div className='sample-details-breadcrumb'>
                            <BreadCrumb isShowBackButton={true} />
                        </div>
                    </div>
                </div>

                <div className='page-content'>
                    <div className='flex flex-wrap justify-between'>
                        <div className='quote-content'>
                            <h2 className='regular-18 weight-500 mt-2'>
                                {sampleStore?.sample?.title}
                                <span className='regular-12 gray_200'>
                                    (
                                    {
                                        sampleStore?.sample
                                            ?.sampleRequestItemResponseList
                                            ?.length
                                    }
                                    )
                                </span>
                            </h2>
                            <h4 className='regular-14 gray_300 my-2'>
                                {sampleStore?.sample?.refNo}
                            </h4>
                            <p className='regular-12 max-w-[640px]'>
                                {sampleStore?.sample?.description || ''}
                            </p>
                        </div>
                        <div className='mt-4 lg:mt-0 w-full lg:w-auto'>
                            <AddPeople
                                members={getMemberList()}
                                requestFrom='SAMPLE'
                                callback={() =>
                                    dispatch(
                                        TeamThunks[GET_TEAM_MEMBERS](
                                            `${BASE_URL}/sample/request/${id}/members`,
                                            true
                                        )
                                    )
                                }
                            />
                        </div>
                    </div>
                    {renderTableView()}
                    {renderCardView()}
                </div>

                <div className='mt-2 mb-4'>
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={
                            sampleStore?.sample?.sampleRequestItemResponseList
                        }
                        paginate={paginate}
                        currentPage={currentPage}
                        onChangePostPerPage={onChangePostPerPage}
                    />
                </div>

                {selectedStyle?.id && (
                    <RequestTimeline
                        selectedStyle={selectedStyle}
                        onCloseModal={() => setSelectedStyle(null)}
                    />
                )}

                {openConversationId && (
                    <ConversationRightSidePanel
                        type='PRODUCT_SAMPLE_COMMENT'
                        pageId={id}
                        id={openConversationId}
                        openModal={openConversationId}
                        onGoBack={() => {
                            dispatch({
                                type: ConversationActionTypes[CLEAR_ALL_CHATS]
                            })
                            setOpenConversationId(null)
                        }}
                        onCloseModal={() => {
                            dispatch({
                                type: ConversationActionTypes[CLEAR_ALL_CHATS]
                            })
                            setOpenConversationId(null)
                        }}
                        memberList={members?.data}
                    />
                )}

                {sampleStore?.selectedProducts?.length > 0 && (
                    <div className='selected-item-count flex justify-center'>
                        <div className='item-count-content flex items-center justify-between max-w-[900px] w-full'>
                            <div className='flex items-center'>
                                <p className='regular-18'>
                                    {sampleStore?.selectedProducts?.length} Item{' '}
                                </p>
                                <span className='px-4'>|</span>
                                <button
                                    className='button link'
                                    onClick={() => onSelectAll()}
                                >
                                    {isAllCheckboxChecked()
                                        ? 'Unselect all'
                                        : 'Select all'}
                                </button>
                            </div>
                            <div>
                                <button
                                    className='button primary round'
                                    onClick={() => setIsQuoteShow(true)}
                                >
                                    Qoute
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {isQuoteShow && (
                    <RequestForQuotes
                        defaultTitle={sampleStore?.sample?.title || ''}
                        selectedProductList={getSelectedProductIds()}
                        onCLose={onHidePopup}
                        onSuccess={onHidePopup}
                        collectionId={sampleStore?.sample?.collectionId}
                    />
                )}
                {isDesignClick && (
                    <DesignRightSidePanel
                        styleId={isDesignClick}
                        openModal={isDesignClick}
                        onCloseModal={() => setIsDesignClick(false)}
                        toggleConversation={() => {
                            setOpenConversationId(isDesignClick)
                        }}
                        styleInfo={selectedStylesInfo}
                    />
                )}
            </>
        )
    )
}

export default SampleDetails
