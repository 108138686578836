import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader, useLoader } from '../../redux_toolkit/Loader'
import { getUserPreference, isPageReachBottom } from '../../helpers/Util'
import { useFilter } from '../../redux_toolkit/V3/Filter'
import {
    fetchFavouriteFolderLists,
    removeFolder
} from '../../services/v3/Favourite/FavouriteService'
import FolderCardView from './FolderCardView'
import { setSystemUserPreferences } from '../../services/CommonService'
import AddFolderModal from '../common/AddFolderModal'
import CustomDropdown from '../common/CustomDropdown'
import { FavouriteThunks } from '../../redux_toolkit/Favourite/FavouriteThunks'
import { GET_FAVOURITE_COUNT } from '../../redux_toolkit/@types/thunk.types'

const sortOptions = [
    {
        key: 'NEWEST',
        value: 'Newest'
    },
    {
        key: 'OLDEST',
        value: 'Oldest'
    },
    {
        key: 'MINE',
        value: 'My Folder'
    },
    {
        key: 'SHARED',
        value: 'Share with me'
    }
]

const FavouriteList = () => {
    const [isShowCreateModal, setIsShowCreateModal] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [favouriteList, setFavouriteList] = useState([])
    const [sort, setSort] = useState(sortOptions[0])
    const scrollRef = useRef()
    const dispatch = useDispatch()
    const globalLoader = useLoader()
    const isLoading = useRef(null)

    const setIsDataLoading = (data) => {
        isLoading.current = data
    }

    useEffect(() => {
        setIsDataLoading(globalLoader)
    }, [globalLoader])

    const generateParams = (pageNumber) => {
        return `?page=${pageNumber}&size=20&sortBy=${sort.key}`
    }

    const fetchFolderList = async (pageNumber) => {
        try {
            dispatch(openLoader())
            const data = await fetchFavouriteFolderLists(
                generateParams(pageNumber)
            )
            if (pageNumber === 0) {
                scrollRef.current = data
                setFavouriteList(data.data)
            } else {
                let tmpData = [...scrollRef.current.data, ...data.data]
                scrollRef.current = data
                scrollRef.current.data = tmpData
                setFavouriteList(tmpData)
            }
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    useEffect(() => {
        fetchFolderList(0)
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [sort])

    const handleScroll = () => {
        if (isPageReachBottom()) {
            let { totalElements, currentPage, data } = scrollRef.current
            if (
                totalElements > 0 &&
                totalElements !== data.length &&
                !isLoading.current
            ) {
                fetchFolderList(currentPage + 1)
            }
        }
    }

    const removeItem = async (folderId) => {
        try {
            dispatch(openLoader())
            await removeFolder(folderId)
            let defaultFolderId = await getUserPreference()
                ?.defaultFavouriteFolder?.id
            if (defaultFolderId === folderId) {
                await setSystemUserPreferences({
                    defaultFavouriteFolder: {}
                })
            }
            scrollRef.current.data = scrollRef.current.data.filter(
                (item) => item.id !== folderId
            )
            setFavouriteList(scrollRef.current.data)
            await dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]())
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const renderList = () => {
        return favouriteList?.map((item, i) => {
            return (
                <React.Fragment key={`favourites_${item.name}_${item.id}`}>
                    <FolderCardView folderInfo={item} removeItem={removeItem} />
                </React.Fragment>
            )
        })
    }

    const onClickSort = (option) => {
        setSort(option)
        setShowOptions(false)
    }

    return (
        <>
            <div className='body-content'>
                <div className='d-flex justify-between items-center py-4'>
                    <button
                        className='button primary'
                        onClick={() => setIsShowCreateModal(true)}
                    >
                        Create new folder
                    </button>
                    <CustomDropdown
                        label={'Sort'}
                        icon={<ArrowDownIcon />}
                        selectedOption={sort.value}
                        isShowOptions={showOptions}
                        setIsShowOptions={setShowOptions}
                    >
                        <div className='list-items scroll-y-label cursor-pointer'>
                            {sortOptions?.map((item) => {
                                return (
                                    <div
                                        key={`sort_${item.value}`}
                                        className='single-item'
                                        onClick={() => {
                                            onClickSort(item)
                                        }}
                                    >
                                        <p>{item.value}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </CustomDropdown>
                </div>
                <div />
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6 gap-y-6 mb-4'>
                    {renderList()}
                </div>
            </div>
            {isShowCreateModal && (
                <AddFolderModal
                    isShowModal={isShowCreateModal}
                    closeModal={() => setIsShowCreateModal(false)}
                    fetchFolderList={() => fetchFolderList(0)}
                />
            )}
        </>
    )
}

export default FavouriteList
