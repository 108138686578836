import React from 'react'
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg'
import {
    clearBreadCrumbRoutes,
    getBreadCrumbs,
    getPopStateRoutes,
    setBreadCrumbRoutes,
    setPopStateRoutes
} from '../../helpers/Util'
import { useLocation, useNavigate } from 'react-router-dom'

const BreadCrumb = ({ isShowBackButton = true }) => {
    const breadCrumbs = getBreadCrumbs()
    const location = useLocation()
    const navigate = useNavigate()

    const findLastIndexOf = (arrayOfObjects, searchValue) => {
        let reversedArray = [...arrayOfObjects].reverse()
        return reversedArray.findIndex(
            (obj) =>
                obj.key === searchValue.key && obj.value === searchValue.value
        )
    }

    const triggerRoute = (item) => {
        if (location.pathname !== item.key) {
            let goBackIndex = findLastIndexOf(breadCrumbs, item)
            if (breadCrumbs[0].key === item.key) {
                clearBreadCrumbRoutes()
                navigate(item.key)
            } else {
                let selectedIndex = breadCrumbs.findIndex(
                    (obj) => obj.key === item.key
                )
                let remainingRoutes = breadCrumbs.slice(0, selectedIndex + 1)
                setBreadCrumbRoutes(remainingRoutes)

                let popRoutes = breadCrumbs.slice(selectedIndex + 1)
                let popStateRoutes = getPopStateRoutes()
                setPopStateRoutes([...popStateRoutes, ...popRoutes])
                navigate(Math.abs(goBackIndex) * -1)
            }
        }
    }

    const renderBreadCrumbList = () => {
        return breadCrumbs?.map((item, i) => {
            let isClickAble = breadCrumbs.length !== i + 1
            return (
                <span
                    key={`breadcrumb_${i}`}
                    className={
                        isClickAble
                            ? 'blue cursor-pointer'
                            : 'regular-14 cursor-pointer'
                    }
                    onClick={() => (isClickAble ? triggerRoute(item) : false)}
                >
                    {item.value}{' '}
                    {isClickAble ? <span className='black'>/&nbsp;</span> : ''}
                </span>
            )
        })
    }

    const goBack = () => {
        if (breadCrumbs?.length > 0) {
            navigate(-1)
        }
    }

    return (
        <>
            {breadCrumbs?.length > 0 && (
                <div
                    className='flex flex-wrap sm:flex-nowrap align-items-center px-4'
                    id='bread-crumb'
                >
                    {isShowBackButton && (
                        <button
                            className='back button link mr-6'
                            onClick={goBack}
                        >
                            <ArrowLeftIcon />
                            <span className='black'>Back</span>
                        </button>
                    )}
                    <div className='breadcrumb w-full sm:w-auto mt-2 sm:mt-0'>
                        <p className='regular-14 black'>
                            {renderBreadCrumbList()}
                        </p>
                    </div>
                </div>
            )}
        </>
    )
}

export default BreadCrumb
