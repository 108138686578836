import React, { useEffect, useState } from 'react'
import nitexLogoDark from '../../../assets/images/login/nitexLogoDark.svg'
import LoaderComponent from '../../common/LoaderComponent'
import Http from '../../helpers/Http'
import { toast } from 'react-toastify'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getCurrentLocalDateTime } from '../../helpers/Util'
import 'tw-elements'
import rightWhite from '../../../assets/images/login/rightWhite.svg'
import bgImage from '../../../assets/images/login-left.png'

const ForgetPasswordChange = () => {
    const [loader, setLoader] = useState(false)
    const [inputData, setInputData] = useState({})
    const [passwordError, setPasswordError] = useState('')
    const [retypePasswordError, setRetypePasswordError] = useState('')
    const [bgImageLink, setBgImageLink] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.clear()
        sessionStorage.clear()
    }, [])

    useEffect(() => {
        // Http.GET(
        //     'getLoginPageBgImage',
        //     `?localDateTime=${getCurrentLocalDateTime()}`
        // )
        //     .then(({ data }) => {
        //         setBgImageLink(data)
        //         setLoader(false)
        //     })
        //     .catch((error) => {
        //         setLoader(false)
        //         toast.error(error.response.data.message)
        //     })
    }, [])

    const handleSubmit = () => {
        setPasswordError('')
        setRetypePasswordError('')
        setLoader(true)
        let postData = { ...inputData }
        postData['token'] = searchParams.get('key')
        Http.POST('changePassword', postData)
            .then(({ data }) => {
                toast.success('Password reset successful!')
                navigate('/login')
            })
            .catch((error) => {
                setLoader(false)
                try {
                    let errors = JSON.parse(error.response.data.message)
                    for (let item of errors) {
                        if (item.field === 'password') {
                            setPasswordError(item.defaultMessage)
                        }
                        if (item.field === 'retypePassword') {
                            setRetypePasswordError(item.defaultMessage)
                        }
                    }
                } catch (e) {
                    toast.error(error.response.data.message)
                }
            })
    }

    const handleChangeLogin = (e) => {
        let { name, value } = e.target
        let cloneLoginParams = { ...inputData }
        cloneLoginParams[name] = value
        setInputData(cloneLoginParams)
    }

    return (
        <LoaderComponent loading={loader}>
            <div
                className='login-container'
                // style={{ background: `url(${bgImage})` }}
                style={{
                    background: '../../../assets/images/login-left-bg.jpeg'
                }}
            >
                <div
                    className='left-half-image'
                    style={{
                        background: `url(${bgImage})`
                    }}
                ></div>

                <div className='right-half-content'>
                    <div className='h-full sm:p-10'>
                        <div className='login-form-contents'>
                            <div className='form-container max-w-[500px] lg:min-w-[450px] 3xl:max-w-[586px] mx-auto xl:ml-auto'>
                                <div className='entry-title'>
                                    <img
                                        src={nitexLogoDark}
                                        alt='nitex'
                                        className='max-w-[120px] lg:max-w-[150px]'
                                    />
                                    {/* <h2 className='belong-here-text font-bold text-6xl'>
                                        Create+Collaborate
                                    </h2> */}
                                </div>
                                <div className='login-input-forms xl:min-w-[500px] mt-16'>
                                    <div className='form-group'>
                                        <label htmlFor='password'>
                                            Password
                                        </label>
                                        <input
                                            type='password'
                                            className={
                                                passwordError
                                                    ? 'form-field border-error'
                                                    : 'form-field'
                                            }
                                            id='password'
                                            name='password'
                                            value={inputData?.password || ''}
                                            onChange={handleChangeLogin}
                                        />
                                        {passwordError && (
                                            <span className='text-error text-sm'>
                                                {passwordError}
                                            </span>
                                        )}
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='retypePassword'>
                                            Re-type password
                                        </label>
                                        <input
                                            type='password'
                                            className={
                                                retypePasswordError
                                                    ? 'form-field border-error'
                                                    : 'form-field'
                                            }
                                            id='retypePassword'
                                            name='retypePassword'
                                            value={
                                                inputData?.retypePassword || ''
                                            }
                                            onChange={handleChangeLogin}
                                        />
                                        {retypePasswordError && (
                                            <span className='text-error text-sm'>
                                                {retypePasswordError}
                                            </span>
                                        )}
                                    </div>
                                    <div className='form-group'>
                                        <button
                                            onClick={handleSubmit}
                                            type='submit'
                                            className='button primary w-full'
                                        >
                                            Change password
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoaderComponent>
    )
}

export default ForgetPasswordChange
