import React from 'react'
import { ReactComponent as PlusIcon } from '../../../icons/plus-round.svg'
import { ReactComponent as DeleteIcon } from '../../../icons/delete.svg'

const TeamMembers = () => {
    return (
        <div className='page-content'>
            <div className='flex flex-wrap justify-between items-center py-3'>
                <div className='common-tab'>
                    <ul>
                        <li className='active'>
                            <span>My Department - Buying (23)</span>
                        </li>
                        <li>
                            <span>Sourceing (23)</span>
                        </li>
                        <li>
                            <span>Design (23)</span>
                        </li>
                        <li>
                            <span>Commercial (23)</span>
                        </li>
                        <li>
                            <span>Merchandising (23)</span>
                        </li>
                        <li>
                            <span>Management (23)</span>
                        </li>
                    </ul>
                </div>
                <div className='mt-3 lg:mt-0'>
                    <button class='button link ml-2 cursor-pointer'>
                        <PlusIcon /> Add team member
                    </button>
                </div>
            </div>
            <div className='team-members-table'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Team member</th>
                            <th>Team</th>
                            <th>Email</th>
                            <th>Added</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className='flex items-center'>
                                    <div className='profile mr-3'>
                                        <img
                                            src='/images/user.svg'
                                            alt='member'
                                            className='w-[32px] h-[32px] object-cover rounded-full'
                                        />
                                    </div>
                                    <div className='designation-info'>
                                        <h3 className='text-sm black weight-500'>
                                            Esther Howard
                                        </h3>
                                        <p className='regular-12 gray_300'>
                                            Senior Production Merchandiser
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>Men’s Team </td>
                            <td>bessie@gmail.com</td>
                            <td>02 Mar, 2023</td>
                            <td>
                                <DeleteIcon />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className='flex items-center'>
                                    <div className='profile mr-3'>
                                        <img
                                            src='/images/user.svg'
                                            alt='member'
                                            className='w-[32px] h-[32px] object-cover rounded-full'
                                        />
                                    </div>
                                    <div className='designation-info'>
                                        <h3 className='text-sm black weight-500'>
                                            Esther Howard
                                        </h3>
                                        <p className='regular-12 gray_300'>
                                            Senior Production Merchandiser
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>Men’s Team </td>
                            <td>bessie@gmail.com</td>
                            <td>02 Mar, 2023</td>
                            <td>
                                <DeleteIcon />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='tab-view-table team-members-tab-view-table py-4'>
                <div className='all-rows'>
                    <div className='single-row mb-4'>
                        <table className='table'>
                            <tr>
                                <td className='heading'>Team member</td>
                                <td>
                                    <div className='flex items-center'>
                                        <div className='profile mr-3'>
                                            <img
                                                src='/images/user.svg'
                                                alt='member'
                                                className='w-[32px] h-[32px] object-cover rounded-full'
                                            />
                                        </div>
                                        <div className='designation-info'>
                                            <h3 className='text-sm black weight-500'>
                                                Esther Howard
                                            </h3>
                                            <p className='regular-12 gray_300'>
                                                Senior Production Merchandiser
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Team</td>
                                <td>Men’s Team </td>
                            </tr>
                            <tr>
                                <td className='heading'>Email</td>
                                <td>bessie@gmail.com</td>
                            </tr>
                            <tr>
                                <td className='heading'>Added</td>
                                <td>02 Mar, 2023</td>
                            </tr>
                            <tr>
                                <td className='heading'>Action</td>
                                <td>
                                    {' '}
                                    <DeleteIcon />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='single-row mb-4'>
                        <table className='table'>
                            <tr>
                                <td className='heading'>Team member</td>
                                <td>
                                    <div className='flex items-center'>
                                        <div className='profile mr-3'>
                                            <img
                                                src='/images/user.svg'
                                                alt='member'
                                                className='w-[32px] h-[32px] object-cover rounded-full'
                                            />
                                        </div>
                                        <div className='designation-info'>
                                            <h3 className='text-sm black weight-500'>
                                                Esther Howard
                                            </h3>
                                            <p className='regular-12 gray_300'>
                                                Senior Production Merchandiser
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='heading'>Team</td>
                                <td>Men’s Team </td>
                            </tr>
                            <tr>
                                <td className='heading'>Email</td>
                                <td>bessie@gmail.com</td>
                            </tr>
                            <tr>
                                <td className='heading'>Added</td>
                                <td>02 Mar, 2023</td>
                            </tr>
                            <tr>
                                <td className='heading'>Action</td>
                                <td>
                                    {' '}
                                    <DeleteIcon />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamMembers
