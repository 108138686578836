import {
    GET_COLLECTION_COUNT,
    GET_COLLECTION_LIST, GET_FAVOURITE_COUNT,
    GET_PRODUCTS_BY_COLLECTION_ID
} from '../@types/thunk.types';
import { closeLoader, openLoader } from '../Loader'
import Http from '../../helpers/Http'
import { collectionActionTypes } from './index'
import {
    SET_ACTIVE_TAB,
    SET_COLLECTION_COUNT,
    SET_COLLECTION_LIST,
    SET_PRODUCTS_BY_COLLECTION_ID
} from '../@types/action.types'
import { toast } from 'react-toastify'
import { authUserInfo } from '../../helpers/Util'
import { CollectionViewTypes } from './CollectionViewSlice'
import { CollectionCountTypes } from './CollectionCountSlice'
import { FavouriteThunks } from '../Favourite/FavouriteThunks';

export const CollectionThunks = {
    [GET_COLLECTION_LIST]:
        (params, activeTab) => async (dispatch, getState) => {
            await dispatch(openLoader())
            await Http.GET_WITH_ID_PARAM(
                'searchCollectionByUser',
                params,
                authUserInfo().id
            )
                .then(async ({ data }) => {
                    if (activeTab) {
                        await dispatch(
                            collectionActionTypes[SET_ACTIVE_TAB](activeTab)
                        )
                    }
                    await dispatch({
                        type: collectionActionTypes[SET_COLLECTION_LIST],
                        payload: { ...data, merge: data.currentPage > 0 }
                    })
                    if(params.indexOf('isFavourite=true') !== -1){
                        dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]())
                    }
                    await dispatch(closeLoader())
                })
                .catch(async ({ response }) => {
                    toast.error(response.data.message)
                    dispatch(closeLoader())
                })
        },
    [GET_PRODUCTS_BY_COLLECTION_ID]:
        (params, id) => async (dispatch, getState) => {
            await dispatch(openLoader())
            await Http.GET_WITH_ID_PARAM('fetchCollectionById', params, id)
                .then(({ data }) => {
                    dispatch({
                        type: CollectionViewTypes[
                            SET_PRODUCTS_BY_COLLECTION_ID
                        ],
                        payload: { ...data, merge: data.currentPage > 0 }
                    })
                    dispatch(closeLoader())
                })
                .catch((error) => {
                    toast.error(error.response.data.message)
                    dispatch(closeLoader())
                })
        },
    [GET_COLLECTION_COUNT]: () => async (dispatch) => {
        dispatch(openLoader())
        Http.GET('fetchCollectionCount')
            .then((response) => {
                dispatch({
                    type: CollectionCountTypes[SET_COLLECTION_COUNT],
                    payload: response.data
                })
                dispatch(closeLoader())
            })
            .catch((error) => {
                dispatch(closeLoader())
                toast.error(error.response.data.message)
            })
    }
}
