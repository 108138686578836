import React, { useRef } from 'react'
import { Cropper } from 'react-cropper'
import 'cropperjs/dist/cropper.css'

const CropperComponent = ({ imageSrc, className, callback }) => {
    const cropperRef = useRef()

    const onCropChange = () => {
        if (typeof cropperRef.current?.cropper !== 'undefined') {
            const cropper = cropperRef.current?.cropper
            if (callback) callback(cropper.getCroppedCanvas().toDataURL())
        }
    }

    return (
        <Cropper
            ref={cropperRef}
            preview='.img-preview'
            src={imageSrc}
            background={false}
            responsive={true}
            guides={true}
            cropend={onCropChange}
            className={className}
            zoomable={false}
            zoomOnTouch={false}
            ready={onCropChange}
            zoomTo={0.5}
            initialAspectRatio={1}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            autoCropArea={1}
            checkOrientation={false}
        />
    )
}

export default CropperComponent;
