import Http from '../../../helpers/Http'
import { toast } from 'react-toastify'

export const fetchProductsFromBuildRange = async (postData) => {
    try {
        const { data } = await Http.POST('fetchProductsFromBuildRange', postData)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}
