import React, { useState } from 'react'
import LoaderComponent from '../../common/LoaderComponent'
import Http from '../../helpers/Http'
import { toast } from 'react-toastify'
import ModalContainer from '../../common/modal/ModalContainer'

const ForgetPassword = ({ onClose }) => {
    const [isInvitationSent, setIsInvitationSent] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState('')

    const sentRecoveryMail = () => {
        if (!email) {
            setEmailError('Email Required!')
        } else {
            setEmailError('')
            setLoader(true)
            let postData = { email: email }
            Http.POST('forgetPassword', postData)
                .then(({ data }) => {
                    setIsInvitationSent(true)
                    setLoader(false)
                    toast.success('Mail Sent Successful!')
                })
                .catch((error) => {
                    setLoader(false)
                    try {
                        let errors = JSON.parse(error.response.data.message)
                        for (let item of errors) {
                            if (item.field === 'email') {
                                setEmailError(item.defaultMessage)
                            }
                        }
                    } catch (e) {
                        toast.error(error.response.data.message)
                    }
                })
        }
    }

    const renderButton = () => {
        if (!isInvitationSent) {
            return (
                <button
                    type='button'
                    className='button primary'
                    onClick={sentRecoveryMail}
                >
                    Receive Recovery Link
                </button>
            )
        } else {
            return (
                <button type='button' className='button primary'>
                    <a href={`mailto:${email}`}>GO TO EMAIL</a>
                </button>
            )
        }
    }

    return (
        <ModalContainer>
            <LoaderComponent loading={loader}>
                <div className='common-popup'>
                    <div className='modal-header px-5 sm:px-8 py-4 sm:py-6 pb-2'>
                        <div className='flex items-center w-full justify-between'>
                            <h3 className='regular-20 font-bold'>
                                Forgot password?
                            </h3>
                            <span
                                className='cursor-pointer'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <button
                                    type='button'
                                    className='btn-close box-content w-4 h-4 p-1 !mr-0.5 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                                    onClick={onClose}
                                />
                            </span>
                        </div>
                    </div>
                    <div className='modal-body relative px-5 sm:px-8'>
                        {!isInvitationSent && (
                            <div className='form-group !mb-0'>
                                <label htmlFor='email' className='label'>
                                    Email address
                                </label>
                                <input
                                    type='email'
                                    className={
                                        !emailError
                                            ? 'form-field bg-primaryColor-shade-300'
                                            : 'form-field bg-primaryColor-shade-300 border-error'
                                    }
                                    id='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder='e.g. johndoe@gmail.com'
                                    name='email'
                                />
                                {emailError && (
                                    <span className='text-error text-sm'>
                                        {emailError}
                                    </span>
                                )}
                            </div>
                        )}
                        {isInvitationSent && (
                            <p className='text-base'>
                                A recovery link has been sent to your email
                                address. <br />
                                By clicking the link, you can change your
                                password.
                            </p>
                        )}
                    </div>
                    <div className='modal-footer flex gap-4 p-5 sm:p-8'>
                        {renderButton()}
                    </div>
                </div>
            </LoaderComponent>
        </ModalContainer>
    )
}

export default ForgetPassword
