import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { FavouriteStyleReducers } from './FavouriteStyleReducers'

const initialState = {}

const FavouriteStyleSlice = createSlice({
    name: 'favouriteStyle',
    initialState,
    reducers: FavouriteStyleReducers
})

export const FavouriteStyleActions = FavouriteStyleSlice.actions

export const useFavouriteStyles = () => useSelector(state => state.favouriteStyle)

export default FavouriteStyleSlice.reducer
