import React, { useEffect, useRef, useState } from 'react'
import {
    Navigate,
    Outlet,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams
} from 'react-router-dom'
import { ReactComponent as IconFavourite } from '../../assets/icons/favorite.svg'
import { ReactComponent as IconNotification } from '../../assets/images/home/notification.svg'
import { ReactComponent as BergerMenuIcon } from '../../assets/icons/old/berger-menu.svg'
import { ReactComponent as PersonIcon } from '../../assets/icons/person.svg'
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg'
import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg'
import { ReactComponent as TeamIcon } from '../../assets/icons/team.svg'
import LoaderComponent from '../common/LoaderComponent'
import { useDispatch, useSelector } from 'react-redux'
import {
    ADD_CONVERSATION_PRODUCT_ID,
    CLEAR_CONVERSATION_PRODUCT_ID,
    GET_ALL_FAVOURITE_COUNT,
    GET_FAVOURITE_COUNT
} from '../redux_toolkit/@types/thunk.types'
import { useFavourite } from '../redux_toolkit/Favourite'
import { FavouriteThunks } from '../redux_toolkit/Favourite/FavouriteThunks'
import LogoutModal from '../modules/profile/LogoutModal'
import {
    CLEAR_ALL_CHATS,
    NEW_INCOMING_MESSAGE_SUCCESS,
    SET_CURRENT_ACTIVE_PAGE
} from '../redux_toolkit/@types/action.types'
import {
    getOneSignalAppId,
    hjid,
    hjsv,
    isItLocalServer,
    isItProductionServer
} from '../constant'
import {
    ACCESS_TOKEN,
    authUserInfo,
    clearBreadCrumbRoutes,
    clearPopStateRoutes,
    encodeIpToken,
    getBreadCrumbs,
    getPopStateRoutes,
    getStompCLient,
    getUserInfo,
    isValidJSON,
    setBreadCrumbRoutes,
    setPopStateRoutes
} from '../helpers/Util'
import {
    NotificationActionTypes,
    useNotificationSelector
} from '../redux_toolkit/Notifications'
import Http from '../helpers/Http'
import { ReactComponent as Favourite } from '../../assets/icons/favorited.svg'
import MobileMenu from './components/MobileMenu'
import {
    ConversationActionTypes,
    getCurrentPage
} from '../redux_toolkit/Conversation'
import { hotjar } from 'react-hotjar'
import ReactGA from 'react-ga'
import Notification from '../modules/notifications/new/Notification'
import ModalContainer from '../common/modal/ModalContainer'
import SearchModal from '../v3/common/SearchModal'
import Profile from '../modules/profile/Profile'
import Settings from '../v3/settings/Settings'
import Menu from './components/Menu'
import BrandInfo from './components/BrandInfo'
import ConversationRightSidePanel from '../v3/common/ConversationRightSidePanel'
import { CommonActions, useGlobalState } from '../redux_toolkit/V3/Common'
import OneSignal from 'react-onesignal'
import { ACTIVE_TAB_OBJ } from '../modules/notifications/new/util/Utils'

let stompClient = getStompCLient()
stompClient.reconnectDelay = 1000
stompClient.debug = (msg) => {
    if (isItLocalServer()) {
        // console.log(msg)
    }
}

const DefaultLayout = () => {
    const [isShowProfileMenu, setIsShowProfileMenu] = useState(false)
    const [showNotification, setShowNotification] = useState(false)
    const [isShowSettings, setIsShowSettings] = useState(false)
    const [openMobileMenu, setOpenMobileMenu] = useState(false)
    const [activeMenu, setActiveMenu] = useState('dashboard')
    const [isShowProfile, setIsShowProfile] = useState(false)
    const [isToggleModal, setIsToggleModal] = useState(null)
    const loaderState = useSelector((state) => state.loader)
    const [allFavCount, setAllFavCount] = useState({})
    const [isShowSearch, setIsShowSearch] = useState(false)
    const [isShowImageSearch, setIsShowImageSearch] = useState(false)

    const notificationRef = useRef(null)
    const conversationStore = getCurrentPage()
    const wrapperRef = useRef(null)
    const profileRef = useRef(null)
    const storeRef = useRef(null)
    const favouriteStore = useFavourite()
    const currentLocation = useLocation()
    const { id, orderId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const productId = useGlobalState()?.conversationProductId
    const notificationInfoRef = useRef({})
    const notificationInfo = useNotificationSelector()

    const [currentRoute, setCurrentRoute] = useState(null)
    const [previousRoute, setPreviousRoute] = useState(null)

    const location = useLocation()

    const getGeoLocation = async () => {
        try {
            const response = await fetch('https://api.ipify.org/?format=json')
            const geoLocationInfo = await response.json()
            if (getUserInfo()?.id) {
                let encodedToken = encodeIpToken(geoLocationInfo.ip)
                let { data } = await Http.GET(
                    'getAuthGL',
                    `?token=${encodedToken}`
                )
                sessionStorage.setItem('glInfo', JSON.stringify(data))
            }
        } catch (e) {
            // eslint-disable-next-line no-empty,no-console
            console.log(e)
        }
    }

    useEffect(() => {
        getGeoLocation()
    }, [])

    useEffect(() => {
        notificationInfoRef.current = notificationInfo
    }, [notificationInfo])

    useEffect(() => {
        if (
            searchParams.get('showComment') === 'true' &&
            searchParams.get('productId') &&
            !searchParams.get('deliverableId')
        ) {
            dispatch(
                CommonActions[ADD_CONVERSATION_PRODUCT_ID](
                    searchParams.get('productId')
                )
            )
        }
    }, [location.search])

    useEffect(() => {
        setPreviousRoute(currentRoute)
        setCurrentRoute(location.pathname)
    }, [location.pathname])

    function removeLastIfLastTwoAreSame(arr) {
        const length = arr.length
        if (length >= 2) {
            const lastElement = arr[length - 1]
            const secondLastElement = arr[length - 2]

            if (
                JSON.stringify(lastElement) ===
                JSON.stringify(secondLastElement)
            ) {
                arr.pop()
            }
        }

        return arr
    }

    useEffect(() => {
        let isBreadCrumbExist = document.getElementById('bread-crumb')
        let visitingRoutes = getBreadCrumbs()
        let popStateRoutes = getPopStateRoutes()
        if (isBreadCrumbExist) {
            if (
                previousRoute !== null &&
                currentRoute !== null &&
                visitingRoutes.length > 0
            ) {
                if (
                    popStateRoutes.length > 0 &&
                    currentRoute ===
                        popStateRoutes[popStateRoutes.length - 1].key
                ) {
                    let popElement = popStateRoutes.pop()
                    setPopStateRoutes(popStateRoutes)
                    setBreadCrumbRoutes(
                        removeLastIfLastTwoAreSame([
                            ...visitingRoutes,
                            popElement
                        ])
                    )
                } else if (
                    previousRoute ===
                    visitingRoutes[visitingRoutes.length - 1].key
                ) {
                    setPopStateRoutes([
                        ...popStateRoutes,
                        visitingRoutes[visitingRoutes.length - 1]
                    ])
                    visitingRoutes.pop()
                    setBreadCrumbRoutes(visitingRoutes)
                }
            }
        } else if (!isBreadCrumbExist && visitingRoutes.length > 0) {
            clearBreadCrumbRoutes()
            clearPopStateRoutes()
        }
    }, [previousRoute, currentRoute])

    const handleClickOutside = (event) => {
        if (
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target) &&
            notificationRef.current === null
        ) {
            setShowNotification(false)
        }
        if (profileRef.current && !profileRef.current.contains(event.target)) {
            setIsShowProfileMenu(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, {
            capture: true
        })
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, {
                capture: true
            })
        }
    }, [wrapperRef])

    const toggleShowModel = (notificationID) => {
        notificationRef.current = notificationID
        setIsToggleModal(notificationID)
    }

    const removeBackdrop = () => {
        let backDropNode = document.getElementsByClassName('modal-backdrop')
        backDropNode[0]?.remove()
    }

    const getRequestChannel = async (stompClient) => {
        try {
            const { data } = await Http.GET('getConversationTopic')
            return data
        } catch ({ response }) {
            if (response.status === 401) {
                stompClient.disconnect(function () {})
            }
        }
    }

    const onConnected = async () => {
        const channel = await getRequestChannel(stompClient)
        stompClient.subscribe(
            channel,
            (payload) => {
                const postResponse = JSON.parse(payload.body)
                if (
                    postResponse.content.entityId ===
                        parseInt(storeRef.current.id) &&
                    postResponse.content.conversationTopic ===
                        storeRef.current.type &&
                    storeRef.current.pageType === 'DETAILS'
                ) {
                    dispatch({
                        type: ConversationActionTypes[
                            NEW_INCOMING_MESSAGE_SUCCESS
                        ],
                        payload: postResponse
                    })
                }
            },
            { jwt: ACCESS_TOKEN() }
        )
    }

    const onError = (error) => {
        if (error?.headers?.message === '401') {
            stompClient.disconnect(function () {})
        }
    }

    const registerConnection = () => {
        stompClient.connect({ jwt: ACCESS_TOKEN() }, onConnected, onError)
    }

    useEffect(() => {
        storeRef.current = conversationStore
    }, [conversationStore])

    useEffect(() => {
        ReactGA.initialize('UA-168122648-1')
        registerConnection()
        window.addEventListener('popstate', removeBackdrop)
        return () => {
            document.removeEventListener('popstate', removeBackdrop)
        }
    }, [])

    const completeOnesignalConfig = async (userInfo) => {
        let subcriptionId = OneSignal.User.PushSubscription.id
        if (!subcriptionId) return

        await OneSignal.login(userInfo.id.toString())
        await OneSignal.Notifications.addEventListener(
            'foregroundWillDisplay',
            (event) => {
                let {
                    notifications,
                    unseenCount,
                    unseenCountObj,
                    notificationTabName
                } = notificationInfoRef.current
                let { additionalData } = event.notification
                if (additionalData) {
                    let content =
                        additionalData.content &&
                        isValidJSON(additionalData.content)
                            ? JSON.parse(additionalData.content)
                            : {}
                    let tempNotification = JSON.parse(
                        JSON.stringify(notifications)
                    )

                    let tempUnseenCountObj = JSON.parse(
                        JSON.stringify(unseenCountObj)
                    )
                    let tempUnSeenCount = parseInt(unseenCount)

                    if (content) {
                        if (
                            content.isGroup &&
                            content.groupByKey !== undefined &&
                            content.groupByKey !== null
                        ) {
                            let groupByNotification = notifications.find(
                                (notification) =>
                                    notification.groupByKey ===
                                    content.groupByKey
                            )

                            if (groupByNotification !== undefined) {
                                if (groupByNotification.isSeen === false) {
                                    tempUnseenCountObj[
                                        content.frontEndNotificationEvent.toLowerCase()
                                    ] -= 1 // front-end category wise count 1 minus
                                    tempUnseenCountObj[
                                        ACTIVE_TAB_OBJ.all.value.toLowerCase()
                                    ] -= 1 // front-end all category count 1 minus
                                    tempUnSeenCount -= 1 // total count 1 minus
                                }
                                tempNotification = tempNotification.filter(
                                    (notification) => {
                                        return (
                                            notification.groupByKey !==
                                            content.groupByKey
                                        )
                                    }
                                )
                            }
                        }

                        tempUnseenCountObj[
                            content.frontEndNotificationEvent.toLowerCase()
                        ] =
                            parseInt(
                                tempUnseenCountObj[
                                    content.frontEndNotificationEvent.toLowerCase()
                                ]
                            ) + 1 // front-end category wise count 1 minus
                        tempUnseenCountObj[
                            ACTIVE_TAB_OBJ.all.value.toLowerCase()
                        ] =
                            parseInt(
                                tempUnseenCountObj[
                                    ACTIVE_TAB_OBJ.all.value.toLowerCase()
                                ]
                            ) + 1 // front-end all category count 1 minus
                        dispatch({
                            type: NotificationActionTypes[
                                'SET_NOTIFICATION_DATA'
                            ],
                            payload: {
                                key: 'unseenCountObj',
                                data: tempUnseenCountObj
                            }
                        })
                        dispatch({
                            type: NotificationActionTypes[
                                'SET_NOTIFICATION_DATA'
                            ],
                            payload: {
                                key: 'unseenCount',
                                data: parseInt(tempUnSeenCount) + 1
                            }
                        })

                        let frontEndEvent =
                            content.frontEndNotificationEvent.toLowerCase()
                        frontEndEvent =
                            frontEndEvent === 'comment'
                                ? 'comments'
                                : frontEndEvent
                        if (
                            frontEndEvent ===
                                notificationTabName.toLowerCase() ||
                            ACTIVE_TAB_OBJ.all.value.toLowerCase() ===
                                notificationTabName.toLowerCase()
                        ) {
                            dispatch({
                                type: NotificationActionTypes[
                                    'SET_NOTIFICATION_DATA'
                                ],
                                payload: {
                                    key: 'notifications',
                                    data: [content, ...tempNotification]
                                }
                            })
                        }
                    }
                }
            }
        )
    }

    useEffect(() => {
        try {
            let userInfo = localStorage.getItem('userInfo')
            if (userInfo) {
                userInfo = JSON.parse(userInfo)
            } else {
                userInfo = {}
            }
            OneSignal.init({ appId: getOneSignalAppId() }).then(() => {
                OneSignal.Notifications.addEventListener(
                    'permissionChange',
                    (permission) => {
                        if (permission) {
                            setTimeout(
                                () => completeOnesignalConfig(userInfo),
                                1500
                            )
                        }
                    }
                )
            })

            setTimeout(() => completeOnesignalConfig(userInfo), 1500)
        } catch (e) {
            // eslint-disable-next-line no-empty,no-console
            console.log(e)
        }
    }, [])

    useEffect(() => {
        let firstSegment = currentLocation.pathname.split('/')[1]
        switch (firstSegment) {
            case 'dashboard':
                setActiveMenu('dashboard')
                break
            case 'orders':
                setActiveMenu('orders')
                break
            case 'moodboard':
                setActiveMenu('moodboard')
                break
            case 'favourite':
                setActiveMenu('favourite')
                break
            case 'my-profile':
                setActiveMenu('profile')
                break
            case 'change-password':
                setActiveMenu('changePassword')
                break
            case 'my-preferences':
                setActiveMenu('preferences')
                break
            case 'collections':
            case 'collection':
                setActiveMenu('collections')
                break
            case 'quotes':
                setActiveMenu('quotes')
                break
            case 'samples':
                setActiveMenu('samples')
                break
            case 'style':
                setActiveMenu('styles')
                break
            default:
                setActiveMenu('')
                break
        }
    }, [currentLocation])

    const setActivePageDetails = () => {
        dispatch({
            type: ConversationActionTypes[CLEAR_ALL_CHATS]
        })
        if (activeMenu && id) {
            if (activeMenu === 'collections') {
                dispatch({
                    type: ConversationActionTypes[SET_CURRENT_ACTIVE_PAGE],
                    payload: {
                        id: id,
                        type: 'COLLECTION_CONVERSATION',
                        pageType: 'DETAILS'
                    }
                })
            }
            //    else if (activeMenu === 'quotes') {
            //         dispatch({
            //             type: ConversationActionTypes[SET_CURRENT_ACTIVE_PAGE],
            //             payload: {
            //                 id: id,
            //                 type: 'COSTING_CONVERSATION',
            //                 pageType: 'DETAILS'
            //             }
            //         })
            //     } else if (activeMenu === 'samples') {
            //         dispatch({
            //             type: ConversationActionTypes[SET_CURRENT_ACTIVE_PAGE],
            //             payload: {
            //                 id: id,
            //                 type: 'SAMPLE_CONVERSATION',
            //                 pageType: 'DETAILS'
            //             }
            //         })
            //     }
            //     else if (activeMenu === 'styles') {
            //         dispatch({
            //             type: ConversationActionTypes[SET_CURRENT_ACTIVE_PAGE],
            //             payload: {
            //                 id: id,
            //                 type: 'PRODUCT_CONVERSATION',
            //                 pageType: 'DETAILS'
            //             }
            //         })
            //     }
            else {
                dispatch({
                    type: ConversationActionTypes[SET_CURRENT_ACTIVE_PAGE],
                    payload: { id: null, type: '', pageType: 'DETAILS' }
                })
            }
        } else {
            dispatch({
                type: ConversationActionTypes[SET_CURRENT_ACTIVE_PAGE],
                payload: { id: null, type: '', pageType: 'DETAILS' }
            })
        }
    }

    useEffect(() => {
        setActivePageDetails()
    }, [activeMenu, id, orderId])

    const getAllUnseenGroupNotificationCount = async () => {
        await Http.GET('getAllUnseenNotificationCount')
            .then(({ data }) => {
                dispatch({
                    type: NotificationActionTypes['SET_NOTIFICATION_DATA'],
                    payload: {
                        key: 'unseenCountObj',
                        data: data
                    }
                })

                dispatch({
                    type: NotificationActionTypes['SET_NOTIFICATION_DATA'],
                    payload: {
                        key: 'unseenCount',
                        data: data?.all
                    }
                })
            })
            .catch(({ response }) => {})
    }

    const onShowNotification = () => {
        setShowNotification((prev) => !prev)
    }

    const initializeHotJar = async () => {
        if (isItProductionServer()) {
            hotjar.initialize(hjid, hjsv)
        }
    }

    const setInitialLoad = async () => {
        await fetchFavouriteCount()
        let data = JSON.parse(localStorage.getItem('userInfo'))
        if (data?.status === 'ACTIVE') {
            // dispatch(CollectionThunks[GET_COLLECTION_COUNT]())
            dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]())
            dispatch(FavouriteThunks[GET_ALL_FAVOURITE_COUNT]())
            localStorage.setItem('subscriptionStatus', true)
            await initializeHotJar()
            await getAllUnseenGroupNotificationCount()
        }
        if (data === undefined || data === null) {
            navigate('/login')
        } else if (data?.status === 'ACTIVE') {
            if (
                currentLocation.pathname === '/' ||
                currentLocation.pathname === '/login' ||
                currentLocation.pathname === '/home'
            ) {
                navigate(`/home${location.search ? location.search : ''}`)
            }
        } else if (data?.status === 'DISABLED') {
            localStorage.clear()
            sessionStorage.clear()
            navigate('/login')
        } else if (data?.emailVerified === false) {
            navigate('/login', { state: { emailVerified: false } })
        } else if (data?.businessInfoGiven === false) {
            navigate('/activation')
            return <Navigate to='/activation' />
        } else if (data?.status === 'PENDING') {
            navigate('/activation')
        }
    }

    useEffect(() => {
        setInitialLoad()
    }, [])

    const fetchFavouriteCount = async () => {
        await Http.GET('allFavouriteCount').then((response) => {
            setAllFavCount(response.data)
        })
    }

    const renderNotificationSubmenu = () => {
        return (
            <div>
                <Notification
                    toggleModel={toggleShowModel}
                    isToggleModel={isToggleModal}
                />
            </div>
        )
    }

    const sumTotalFavourites = () => {
        let sum = 0
        sum += parseInt(favouriteStore?.counts?.style)
        return sum
    }
    const renderProfileSubmenu = () => {
        return (
            <>
                <div className='submenu flex flex-col sm:flex-row bg-white shadow-lg w-[320px] absolute top-[34px] right-0 z-[999]'>
                    <div className='w-full'>
                        <div className='m-6 mb-2 border-bottom-solid pb-3'>
                            <h3 className='f text-xl font-bold mb-1'>
                                {getUserInfo()?.name}
                            </h3>
                            <p className='text-sm gray_300'>
                                {getUserInfo()?.designation}
                            </p>
                        </div>
                        <ul className='menu-items'>
                            <li
                                onClick={() => setIsShowProfile(true)}
                                className={
                                    activeMenu === 'profile' ? 'active' : ''
                                }
                            >
                                <div className='flex items-center gap-2'>
                                    <PersonIcon />
                                    <span>Personal information</span>
                                </div>
                            </li>
                            <li
                                onClick={() => setIsShowSettings(true)}
                                className={
                                    activeMenu === 'Settings' ? 'active' : ''
                                }
                            >
                                <div className='flex items-center gap-2'>
                                    <FilterIcon />
                                    <span>Settings</span>
                                </div>
                            </li>
                            <li
                                // onClick={() => setIsShowSettings(true)}
                                className={
                                    activeMenu === 'Team' ? 'active' : ''
                                }
                            >
                                <div
                                    onClick={() => navigate('/team-members')}
                                    className='flex items-center gap-2'
                                >
                                    <TeamIcon />
                                    <span>Team Member</span>
                                </div>
                            </li>

                            <li
                                data-bs-toggle='modal'
                                data-bs-target='#logoutModal'
                            >
                                <a className='cursor-pointer'>
                                    <div className='flex items-center gap-2'>
                                        <LogoutIcon />
                                        <span>Logout</span>
                                    </div>
                                </a>
                            </li>
                        </ul>

                        <div className='privacy-and-terms-condition m-6 mt-2'>
                            <a
                                href='https://nitex.com/privacy-policy/'
                                target='_blank'
                                rel='noreferrer'
                                className='mr-3 text-xs hover:underline '
                            >
                                Privacy policy
                            </a>
                            <a
                                href='https://nitex.com/terms-and-conditions/'
                                target='_blank'
                                rel='noreferrer'
                                className='mr-2 text-xs hover:underline mt-[-2px]'
                            >
                                Terms and conditions
                            </a>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <LoaderComponent loading={loaderState}>
            <div className='container-fluid'>
                <header className='py-3 px-5 logo flex items-center justify-between'>
                    <div className='logo flex items-center gap-5'>
                        <div className='burger-menu lg:hidden'>
                            <span
                                className='mt-[2px] inline-block'
                                onClick={() => setOpenMobileMenu(true)}
                            >
                                <BergerMenuIcon />
                            </span>
                        </div>
                        <BrandInfo />
                    </div>

                    <div className='main-menu'>
                        {currentLocation.pathname !== '/home' &&
                            currentLocation.pathname !== '/' && <Menu />}
                    </div>

                    <div className='user-interaction flex items-center gap-x-5 sm:gap-x-6'>
                        <div className='common-image-search'>
                            <p
                                className='gray_200'
                                onClick={() => setIsShowSearch(true)}
                            >
                                Search from design lab
                            </p>
                            <CameraIcon
                                onClick={() => {
                                    setIsShowSearch(true)
                                    setIsShowImageSearch(true)
                                }}
                            />
                        </div>
                        <div className='favorite-interaction w-[35px] h-[35px] rounded-full bg-primaryColor-shade-300 p-2 relative flex items-center justify-center cursor-pointer mr-2'>
                            <span onClick={() => navigate('/favourites')}>
                                {sumTotalFavourites() > 0 ? (
                                    <>
                                        <Favourite />
                                        <span className='count absolute top-[7px] right-[-18px] text-xs text-white-shade-100 bg-primaryColor inline-block rounded-[20px] text-center'>
                                            {sumTotalFavourites() > 99
                                                ? '99+'
                                                : sumTotalFavourites()}
                                        </span>
                                    </>
                                ) : (
                                    <IconFavourite />
                                )}
                            </span>
                        </div>
                        <div
                            className={`favorite-interaction w-[35px] h-[35px] rounded-full bg-primaryColor-shade-300 p-2 relative  cursor-pointer  mr-3 ${
                                notificationInfoRef.current?.unseenCount > 0
                                    ? 'active'
                                    : ''
                            }`}
                            ref={wrapperRef}
                        >
                            <div
                                className='flex items-center justify-center'
                                onClick={() => onShowNotification()}
                            >
                                <span className='mt-[-3px]'>
                                    <IconNotification />
                                </span>
                                {notificationInfo.unseenCount > 0 && (
                                    <span className='count absolute top-[7px] right-[-16px] text-xs text-white-shade-100 bg-primaryColor inline-block rounded-[20px] text-center'>
                                        {notificationInfo.unseenCount > 99
                                            ? '99+'
                                            : notificationInfo.unseenCount}
                                    </span>
                                )}
                            </div>
                            {showNotification && renderNotificationSubmenu()}
                        </div>
                        <div
                            className='profile-interaction w-[35px] h-[35px] rounded-full bg-primaryColor-shade-300 relative border border-white-shade-100 flex items-center justify-center cursor-pointer'
                            onClick={() =>
                                setIsShowProfileMenu((prev) => !prev)
                            }
                            ref={profileRef}
                        >
                            {!authUserInfo()?.profilePicDocument?.docUrl && (
                                <span className='name-icon'>
                                    {getUserInfo()
                                        ?.name?.toUpperCase()
                                        ?.charAt(0)}
                                </span>
                            )}
                            {authUserInfo()?.profilePicDocument?.docUrl && (
                                <img
                                    src={
                                        authUserInfo()?.profilePicDocument
                                            ?.docUrl
                                    }
                                    className='object-cover object-top w-full h-full rounded-full'
                                    alt=''
                                />
                            )}
                            {isShowProfileMenu && renderProfileSubmenu()}
                        </div>
                    </div>
                </header>
                <MobileMenu
                    setOpenMobileMenu={setOpenMobileMenu}
                    openMobileMenu={openMobileMenu}
                />
                <div className='body-container'>{<Outlet />}</div>
                {isShowProfile && (
                    <ModalContainer
                        onCloseModal={() => setIsShowProfile(false)}
                        className='personal-information-modal'
                    >
                        <Profile onCloseModal={() => setIsShowProfile(false)} />
                    </ModalContainer>
                )}
                {isShowSettings && (
                    <ModalContainer
                        onCloseModal={() => setIsShowSettings(false)}
                        className='setting-modal'
                    >
                        <Settings
                            onCloseModal={() => setIsShowSettings(false)}
                        />
                    </ModalContainer>
                )}
                {productId !== null && (
                    <ConversationRightSidePanel
                        type='PRODUCT_DEVELOPMENT_COMMENT'
                        id={productId}
                        openModal={true}
                        onCloseModal={() => {
                            dispatch(
                                CommonActions[CLEAR_CONVERSATION_PRODUCT_ID]()
                            )
                        }}
                        memberList={[]}
                    />
                )}

                {isShowSearch && (
                    <SearchModal
                        closeModal={() => {
                            setIsShowSearch(false)
                            setIsShowImageSearch(false)
                        }}
                        isShowImageSearch={isShowImageSearch}
                    />
                )}

                <LogoutModal />
            </div>
        </LoaderComponent>
    )
}

export default DefaultLayout
