import {
    GET_ALL_PRODUCTS_FROM_COLLECTION,
    SET_ALL_PRODUCTS_TO_COLLECTION
} from '../../@types/thunk.types'
import { closeLoader, openLoader } from '../../Loader'
import { fetchAllProductsFromCollection } from '../../../services/v3/CollectionDetails/CollectionDetailsService'
import { CollectionDetailsAction } from './index'

const CollectionDetailsThunk = {
    [GET_ALL_PRODUCTS_FROM_COLLECTION]: (params) => async (dispatch) => {
        dispatch(openLoader())
        const data = await fetchAllProductsFromCollection(params)
        dispatch(
            CollectionDetailsAction[SET_ALL_PRODUCTS_TO_COLLECTION]({
                data,
                merge: data.currentPage > 0
            })
        )
        dispatch(closeLoader())
    }
}

export default CollectionDetailsThunk
