import {
    ADD_NEWLY_CREATED_COLLECTION,
    CLEAT_COLLECTION_STORE,
    REMOVE_COLLECTION_BY_ID,
    SET_ACTIVE_TAB,
    SET_COLLECTION_LIST,
    TOGGLE_FAVOURITE_COLLECTION
} from '../@types/action.types'

export const collectionReducer = {
    [SET_ACTIVE_TAB]: (state, action) => {
        state.activeTab = action.payload
    },
    [SET_COLLECTION_LIST]: (state, action) => {
        let collections = []
        if (action.payload.merge) {
            collections = [...state.collections.data, ...action.payload.data]
        } else {
            collections = [...action.payload.data]
        }
        action.payload.data = collections
        state.collections = action.payload
    },
    [TOGGLE_FAVOURITE_COLLECTION]: (state, { payload }) => {
        state?.collections?.data?.forEach(item => {
            if (item.id === payload) {
                item.isFavorite = !item.isFavorite
            }
        })
    },
    [ADD_NEWLY_CREATED_COLLECTION]: (state, { payload }) => {
        state?.collections?.data?.unshift(payload)
    },
    [CLEAT_COLLECTION_STORE]: (state) => {
        state.activeTab = '';
        state.collections = {}
    },
    [REMOVE_COLLECTION_BY_ID]: (state, { payload }) => {
        let index = state.collections.data.findIndex(item => item.id === payload)
        state.collections.data.splice(index, 1)
    }
}
