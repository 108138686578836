import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/scss/App.scss'
import {
    BrowserRouter as Router,
    Navigate,
    Outlet,
    Route,
    Routes,
    useLocation
} from 'react-router-dom'
import Login from './modules/login-journey/Login'
import ActivationPopup from './modules/login-journey/ActivationPopup'
import DefaultLayout from './layouts/DefaultLayout'
import Moodboard from './modules/moodboard/Moodboard'
import MoodboardView from './modules/moodboard/MoodboardView'
import VerifyEmail from './modules/login-journey/VerifyEmail'
import FabricWiseProduct from './modules/dashboard/FabricWiseProduct'
import MyOrderList from './modules/order/MyOrderList'
import Timeline from './modules/order/Timeline'
import PODetails from './modules/order/PODetails'
import CollectionList from './modules/collection/Collections'
import QuoteList from './modules/quote/QuoteList'
import QuoteDetails from './modules/quote/QuoteDetails'
import SampleDetails from './modules/samples/SampleDetails'
import ProductSearch from './v3/common/ProductSearch'
import FavouriteStyle from './modules/favourite/FavouriteStyle'
import Profile from './modules/profile/Profile'
import ChangePassword from './modules/profile/ChangePassword'
import Preferences from './modules/profile/Preferences'
// import Notifications from './modules/notifications/Notifications'
import SampleList from './modules/samples/SampleList'
import ForgetPasswordChange from './modules/login-journey/ForgetPasswordChange'
// import SampleDetail from './modules/samples/SampleDetail'
import SupplierProfile from './modules/supplier/SupplierProfile'
import ExpOne from './modules/quillEditor/ExpOne'
import { BASE_FRONT_END_URL, BASE_URL } from './constant'
import Http from './helpers/Http'
import ReactGA from 'react-ga'
import ExploreDesigns from './v3/exploreDesign/ExploreDesign'
import HomePage from './v3/HomePage'
import Favourites from './v3/favourites/FavouriteList'
import FavouriteDetails from './v3/favourites/FavouriteDetails'
import SharedCollections from './v3/sharedCollection/SharedCollection'
import DesignRangeList from './v3/range/RangeList'
import MyDashboard from './v3/MyDashboard'
import CollectionPresentation from './v3/presentation/CollectionPresentation'
import TeamMembers from './modules/team-members/TeamMembers'

// UI Imports
import Homepage from '../assets/designs/design-v3/Homepage'
import UiV2 from '../assets/designs/UiV2'
import Buttons from '../assets/designs/design-v3/Buttons'
import ExploreDesign from '../assets/designs/design-v3/design-lab/ExploreDesign'
import SharedCollection from '../assets/designs/design-v3/design-lab/SharedCollection'
import RangeList from '../assets/designs/design-v3/design-lab/RangeList'
import FavouriteList from '../assets/designs/design-v3/design-lab/FavouriteList'
import FavoriteDetails from '../assets/designs/design-v3/design-lab/FavoriteDetails'
import CollectionDetails from './v3/sharedCollection/CollectionDetails'
import QuoteDetailsDesign from '../assets/designs/design-v3/dashboard/QuoteDetails'
import SampleDesignDetails from '../assets/designs/design-v3/dashboard/SampleDetails'
import MyDashboardUi from '../assets/designs/design-v3/dashboard/MyDashboard'
import ColorSizeBreakdown from '../assets/designs/design-v3/dashboard/ColorSizeBreakdown'
import FactoryProfile from '../assets/designs/design-v3/dashboard/FactoryProfile'
import ImagesSearch from '../assets/designs/design-v3/design-lab/ImagesSearch'
import FavoriteDetailsListView from '../assets/designs/design-v3/design-lab/FavoriteDetailsListView'
import TeamMembersView from '../assets/designs/design-v3/design-lab/TeamMembers'

const useAuth = () => {
    const token = localStorage.getItem('token')
    return !!token
}

const ProtectedRoutes = (props) => {
    const auth = useAuth()
    if (auth) addPageVisitedEvent()
    return auth ? <Outlet /> : <Navigate to='/login' />
}

const addPageVisitedEvent = () => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    const { pathname } = useLocation()
    if (pathname === '/activation') return
    let body = {
        path: pathname,
        baseUrl: BASE_URL,
        baseFrontEndUrl: BASE_FRONT_END_URL
    }
    Http.POST('mixpanelAddEvent', body, 'PAGE_VISITED')
}

const App = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route path='login' element={<Login />} />
                    <Route path='verify/email' element={<VerifyEmail />} />
                    <Route
                        path='reset-password'
                        element={<ForgetPasswordChange />}
                    />
                    <Route
                        path='/collections-presentation/:id'
                        element={<CollectionPresentation />}
                    />
                    <Route
                        path='/presentation/:id'
                        element={<CollectionPresentation hideFavBtn={true} />}
                    />
                    <Route path='/' element={<ProtectedRoutes />}>
                        <Route element={<DefaultLayout />}>
                            <Route path='home' element={<HomePage />} />
                            <Route path='/' element={<HomePage />} />
                            <Route
                                path='/dashboard'
                                element={<MyDashboard />}
                            />
                            <Route
                                path='/design-lab'
                                element={<ExploreDesigns />}
                            />
                            <Route path='/search' element={<ProductSearch />} />
                            <Route
                                path='/favourites'
                                element={<Favourites />}
                            />
                            <Route
                                path='/favourites/:id'
                                element={<FavouriteDetails />}
                            />
                            <Route
                                path='/shared-collection'
                                element={<SharedCollections />}
                            />
                            <Route
                                path='/collections/:id'
                                element={<CollectionDetails />}
                            />
                            <Route
                                path='/suggested-designs'
                                element={<DesignRangeList />}
                            />
                            <Route path='quotes' element={<QuoteList />} />
                            <Route
                                path='quotes/detail/:id'
                                element={<QuoteDetails />}
                            />
                            <Route path='samples' element={<SampleList />} />
                            <Route
                                path='samples/detail/:id'
                                element={<SampleDetails />}
                            />
                            <Route
                                path='orders/my-orders'
                                element={<MyOrderList />}
                            />
                            <Route
                                path='orders/view/:orderId'
                                element={<Timeline />}
                            />
                            <Route
                                path='supplier/view/:id'
                                element={<SupplierProfile />}
                            />
                            <Route
                                path='purchaseDetails/:orderId'
                                element={<PODetails />}
                            />
                            <Route
                                path='products/materialId'
                                element={<FabricWiseProduct />}
                            />
                            <Route
                                path='collections'
                                element={<CollectionList />}
                            />

                            <Route
                                path='favourite/style'
                                element={<FavouriteStyle />}
                            />
                            <Route path='moodboard' element={<Moodboard />} />
                            <Route
                                path='moodboard/:id'
                                element={<MoodboardView />}
                            />
                            <Route
                                path='moodboard/:id/:created'
                                element={<MoodboardView />}
                            />
                            <Route path='my-profile' element={<Profile />} />
                            <Route
                                path='change-password'
                                element={<ChangePassword />}
                            />
                            <Route
                                path='my-preferences'
                                element={<Preferences />}
                            />
                            {/*<Route*/}
                            {/*    path='notifications/all'*/}
                            {/*    element={<Notifications />}*/}
                            {/*/>*/}
                            <Route path='editor' element={<ExpOne />} />
                            <Route
                                path='/team-members'
                                element={<TeamMembers />}
                            />
                        </Route>
                        <Route
                            path='/activation'
                            element={<ActivationPopup />}
                        />
                    </Route>

                    {/*Design UI Route*/}
                    <Route element={<DefaultLayout />}>
                        <Route path='/ui' element={<UiV2 />} />
                        <Route path='/home' element={<Homepage />} />
                        <Route path='/buttons' element={<Buttons />} />
                        <Route
                            path='/exploreDesign'
                            element={<ExploreDesign />}
                        />
                        <Route
                            path='/shared-collection'
                            element={<SharedCollection />}
                        />
                        <Route path='/range-list' element={<RangeList />} />
                        <Route
                            path='/favourite-list'
                            element={<FavouriteList />}
                        />
                        <Route
                            path='/favouriteDetails'
                            element={<FavoriteDetailsListView />}
                        />
                        <Route
                            path='/myDashboard'
                            element={<MyDashboardUi />}
                        />
                        <Route
                            path='/quoteDetails'
                            element={<QuoteDetailsDesign />}
                        />
                        <Route
                            path='/sampleDetails'
                            element={<SampleDesignDetails />}
                        />
                        <Route
                            path='/size-breakdown'
                            element={<ColorSizeBreakdown />}
                        />
                        <Route
                            path='/factoryProfile'
                            element={<FactoryProfile />}
                        />
                        <Route
                            path='/teamMembers'
                            element={<TeamMembersView />}
                        />
                        <Route path='/imageSearch' element={<ImagesSearch />} />
                        <Route path='*' element={<Homepage />} />
                    </Route>
                </Routes>
            </Router>
            <ToastContainer
                autoClose={3500}
                position='top-right'
                hideProgressBar={true}
            />
        </>
    )
}
export default App
