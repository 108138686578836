export const ERROR_MESSAGE = 'Something went wrong! Please try again.'
export const DATA_LOADED_ONCE = 'dataLoadedOnce'
export const NOTIFICATIONS = 'notifications'
export const REPLY_COMMENT_LIST = 'replyCommentList'
export const HAS_NEXT = 'hasNext'
export const PAGE = 'page'
export const UNSEEN_COUNT = 'unseenCount'
export const UNSEEN_COUNT_OBJ = 'unseenCountObj'
export const NOTIFICATION_TAB_NAME = 'notificationTabName'
export const NOTIFICATION_FILTER_NAME = 'notificationFilterName'
export const ACTIVE_VALUE = 'active'
export const ACTIVE_TAB_OBJ = {
    all: { value: 'All', enum: null },
    mentions: { value: 'Mentions', enum: 'MENTION' },
    comments: { value: 'Comments', enum: 'COMMENT' },
    collection: { value: 'Collection', enum: 'COLLECTION' },
    request: { value: 'Request', enum: 'REQUEST' },
    order: { value: 'Order', enum: 'ORDER' }
}

export const TITLE_PART_TYPE = {
    ACTOR: 'ACTOR',
    ACTION: 'ACTION',
    PREPOSITION: 'PREPOSITION',
    ACTED_UPON: 'ACTED_UPON',
    NEW_VALUE: 'NEW_VALUE',
    REGULAR: 'regular',
    MENTIONED: 'mentioned'
}
export const FRONTED_EVENT = {
    COMMENT: 'Comment',
    COLLECTION: 'Collection',
    REQUEST: 'Request',
    ORDER: 'Order'
}

export const ENTITY_TYPE = {
    USER: 'USER',
    POST: 'POST',
    STEP: 'STEP',
    ORDER: 'ORDER',
    COLLECTION: 'COLLECTION',
    BRAND: 'BRAND',
    INVOICE: 'INVOICE',
    SALES_CONTRACT: 'SALES_CONTRACT',
    SAMPLE: 'SAMPLE',
    SAMPLE_REQUEST: 'SAMPLE_REQUEST',
    DELIVERABLE: 'DELIVERABLE',
    PRODUCT: 'PRODUCT',
    QUOTE: 'QUOTE',
    PHOTOGRAPHY: 'PHOTOGRAPHY',
    PHOTOGRAPHY_REQUEST: 'PHOTOGRAPHY_REQUEST',
    POJO_CLASS: 'POJO_CLASS',
    NEW_VALUE: 'NEW_VALUE',
    RFQ: 'RFQ',
    MY_QUOTES: 'MY_QUOTES',
    FAVOURITE_FOLDER: 'FAVOURITE_FOLDER'
}
export const CONTENT = {
    EMPTY_LEFT_HALF_IMAGES_ITEM: 'EMPTY_LEFT_HALF_IMAGES_ITEM',
    MULTIPLE_IMAGES_SINGLE_ITEM: 'MULTIPLE_IMAGES_SINGLE_ITEM',
    COMMENT_ITEM: 'COMMENT_ITEM'
}
export const EVENT = {
    PRODUCT_DEVELOPMENT_POST: {
        value: 'PRODUCT_DEVELOPMENT_POST',
        icon: '/icons/notification/comment.svg',
        category: 'PRODUCT',
        frontEvent: 'Comment',
        firstAction: ' Comments ',
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.PRODUCT],
        entityTypeList: [ENTITY_TYPE.COLLECTION]
    },
    TASK_REVISED: {
        value: 'TASK_REVISED',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.POST,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' requested revision for ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.STEP, 'of', ENTITY_TYPE.PRODUCT],
        entityTypeList: [ENTITY_TYPE.ORDER]
    },
    TASK_POST_ADDED: {
        value: 'TASK_POST_ADDED',
        icon: '/icons/notification/comment.svg',
        category: ENTITY_TYPE.POST,
        frontEvent: 'Comment',
        firstAction: ' Comments ',
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.DELIVERABLE],
        entityTypeList: [ENTITY_TYPE.ORDER, ENTITY_TYPE.PRODUCT]
    },
    TASK_COMMENT_ADDED: {
        value: 'TASK_COMMENT_ADDED',
        icon: '/icons/notification/comment.svg',
        category: ENTITY_TYPE.POST,
        frontEvent: 'Comment',
        firstAction: ' Comments ',
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.DELIVERABLE],
        entityTypeList: [ENTITY_TYPE.ORDER, ENTITY_TYPE.PRODUCT]
    },
    COLLECTION_POST: {
        value: 'COLLECTION_POST',
        icon: '/icons/notification/comment.svg',
        category: ENTITY_TYPE.COLLECTION,
        frontEvent: 'Comment',
        firstAction: ' Comments ',
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.COLLECTION]
    },
    SAMPLE_DEVELOPMENT_POST: {
        value: 'SAMPLE_DEVELOPMENT_POST',
        icon: '/icons/notification/comment.svg',
        category: ENTITY_TYPE.SAMPLE_REQUEST,
        frontEvent: 'Comment',
        firstAction: ' Comments ',
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.SAMPLE_REQUEST]
    },
    QUOTE_REQUEST_POST: {
        value: 'QUOTE_REQUEST_POST',
        icon: '/icons/notification/comment.svg',
        category: 'QUOTE',
        frontEvent: 'Comment',
        firstAction: ' Comments ',
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.QUOTE]
    },
    TASK_COMMENT_MENTIONED: {
        value: 'TASK_COMMENT_MENTIONED',
        icon: '/icons/notification/mention.svg',
        category: ENTITY_TYPE.POST,
        frontEvent: 'Comment',
        firstAction: ' Mentioned you ',
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.DELIVERABLE],
        entityTypeList: [ENTITY_TYPE.ORDER, ENTITY_TYPE.PRODUCT]
    },
    TASK_POST_MENTIONED: {
        value: 'TASK_POST_MENTIONED',
        icon: '/icons/notification/mention.svg',
        category: ENTITY_TYPE.POST,
        frontEvent: 'Comment',
        firstAction: ' Mentioned you ',
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.DELIVERABLE],
        entityTypeList: [ENTITY_TYPE.ORDER, ENTITY_TYPE.PRODUCT]
    },
    PRODUCT_DEVELOPMENT_MENTIONED_AT_POST: {
        value: 'PRODUCT_DEVELOPMENT_MENTIONED_AT_POST',
        icon: '/icons/notification/mention.svg',
        category: 'PRODUCT',
        frontEvent: 'Comment',
        firstAction: ' Mentioned you ',
        isActor: false,
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.PRODUCT],
        entityTypeList: [ENTITY_TYPE.COLLECTION]
    },
    SAMPLE_DEVELOPMENT_MENTIONED_AT_POST: {
        value: 'SAMPLE_DEVELOPMENT_MENTIONED_AT_POST',
        icon: '/icons/notification/mention.svg',
        category: ENTITY_TYPE.SAMPLE,
        frontEvent: 'Comment',
        firstAction: ' Mentioned you ',
        isActor: false,
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.SAMPLE_REQUEST]
    },
    QUOTE_REQUEST_MENTIONED_AT_POST: {
        value: 'QUOTE_REQUEST_MENTIONED_AT_POST',
        icon: '/icons/notification/mention.svg',
        category: ENTITY_TYPE.QUOTE,
        frontEvent: 'Comment',
        firstAction: ' Mentioned you ',
        isActor: false,
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.QUOTE]
    },
    COLLECTION_MENTIONED_AT_POST: {
        value: 'COLLECTION_MENTIONED_AT_POST',
        icon: '/icons/notification/mention.svg',
        category: ENTITY_TYPE.COLLECTION,
        frontEvent: 'Comment',
        firstAction: ' Mentioned you ',
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.COLLECTION]
    },
    PHOTOGRAPHY_REQUEST_COMMENT: {
        value: 'PHOTOGRAPHY_REQUEST_COMMENT',
        icon: '/icons/notification/mention.svg',
        category: ENTITY_TYPE.PHOTOGRAPHY,
        frontEvent: 'Comment',
        firstAction: ' Mentioned you ',
        action: ' on ',
        anotherAction: '',
        titleEntityTypeList: [ENTITY_TYPE.PHOTOGRAPHY_REQUEST]
    },
    COLLECTION_NEW_DESIGN_ADDED: {
        value: 'COLLECTION_NEW_DESIGN_ADDED',
        icon: '/icons/notification/add-design.svg',
        category: 'COLLECTION',
        frontEvent: 'Collection',
        action: ' added ',
        anotherAction: ' styles to ',
        titleEntityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.MULTIPLE_IMAGES_SINGLE_ITEM
    },
    COLLECTION_DESIGN_DELETED: {
        value: 'COLLECTION_DESIGN_DELETED',
        icon: '/icons/notification/delete.svg',
        category: 'COLLECTION',
        frontEvent: 'Collection',
        action: ' deleted ',
        anotherAction: ' styles from ',
        titleEntityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.MULTIPLE_IMAGES_SINGLE_ITEM
    },
    COLLECTION_SHARED: {
        value: 'COLLECTION_SHARED',
        icon: '/icons/notification/person-add.svg',
        category: 'COLLECTION',
        frontEvent: 'Collection',
        isActor: true,
        action: ' shared ',
        anotherAction: ' a collection with you ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },

    REMOVED_FROM_COLLECTION: {
        value: 'REMOVED_FROM_COLLECTION',
        icon: '/icons/notification/delete.svg',
        category: 'COLLECTION',
        frontEvent: 'Collection',
        isActor: true,
        action: ' removed ',
        anotherAction: ' you from a collection ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    COLLECTION_DESIGN_MOVED: {
        value: 'COLLECTION_DESIGN_MOVED',
        icon: '/icons/notification/move.svg',
        category: 'COLLECTION',
        frontEvent: 'Collection',
        action: ' moved ',
        anotherAction: ' style from ',
        content: CONTENT.MULTIPLE_IMAGES_SINGLE_ITEM
    },
    BUYER_REGISTRATION: {
        value: 'BUYER_REGISTRATION',
        icon: '/icons/notification/person-request.svg',
        category: ENTITY_TYPE.USER,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' sent ',
        anotherAction: ' registration request ',
        titleEntityTypeList: [ENTITY_TYPE.POJO_CLASS]
    },
    NEW_COLLECTION_REQUEST: {
        value: 'NEW_COLLECTION_REQUEST',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.COLLECTION,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' sent ',
        anotherAction: ' a collection request ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    NEW_COLLECTION_MOODBOARD_REQUEST: {
        value: 'NEW_COLLECTION_MOODBOARD_REQUEST',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.COLLECTION,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' sent ',
        anotherAction: ' a moodboard request ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    NEW_QUOTE_REQUEST_FROM_BUYER: {
        value: 'NEW_QUOTE_REQUEST_FROM_BUYER',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.QUOTE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' sent ',
        anotherAction: ' a costing request ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    DESIGN_STUDIO_COSTING_REQUEST: {
        value: 'DESIGN_STUDIO_COSTING_REQUEST',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.QUOTE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' sent ',
        anotherAction: ' a costing request ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    NEW_SAMPLE_REQUEST: {
        value: 'NEW_SAMPLE_REQUEST',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.SAMPLE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' sent ',
        anotherAction: ' a sample request ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    NEW_SAMPLE_REQUEST_FROM_BUYER: {
        value: 'NEW_SAMPLE_REQUEST_FROM_BUYER',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.SAMPLE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' sent ',
        anotherAction: ' a sample request ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    NEW_ORDER_ADDED: {
        value: 'NEW_ORDER_ADDED',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' placed ',
        anotherAction: ' a new order ',
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    NEW_ORDER_ADDED_FROM_BUYER: {
        value: 'NEW_ORDER_ADDED_FROM_BUYER',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' placed ',
        anotherAction: ' a new order ',
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    SAMPLE_DEVELOPMENT_COMPLETE: {
        value: 'SAMPLE_DEVELOPMENT_COMPLETE',
        icon: '/icons/notification/complete.svg',
        category: ENTITY_TYPE.SAMPLE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' completed ',
        anotherAction: ' sample development ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    SAMPLE_REQUEST_APPROVED_FROM_BUYER: {
        value: 'SAMPLE_REQUEST_APPROVED_FROM_BUYER',
        icon: '/icons/notification/approve.svg',
        category: ENTITY_TYPE.SAMPLE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' approved sample of ',
        anotherAction: ' styles ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.MULTIPLE_IMAGES_SINGLE_ITEM
    },
    SAMPLE_REQUEST_REVISION_FROM_BUYER: {
        value: 'SAMPLE_REQUEST_REVISION_FROM_BUYER',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.SAMPLE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: '  request revision of sample for ',
        anotherAction: ' styles ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.MULTIPLE_IMAGES_SINGLE_ITEM
    },
    SAMPLE_REQUEST_MEMBER_ADDED: {
        value: 'SAMPLE_REQUEST_MEMBER_ADDED',
        icon: '/icons/notification/person-add.svg',
        category: ENTITY_TYPE.SAMPLE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: '  added ',
        anotherAction: ' you to a sample request ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.MULTIPLE_IMAGES_SINGLE_ITEM
    },
    NEW_QUOTE_REQUEST: {
        value: 'NEW_QUOTE_REQUEST',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.QUOTE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' sent ',
        anotherAction: ' a costing request ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    QUOTE_REQUEST_SUBMITTED_COSTING: {
        value: 'QUOTE_REQUEST_SUBMITTED_COSTING',
        icon: '/icons/notification/complete.svg',
        category: ENTITY_TYPE.QUOTE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' submitted ',
        anotherAction: ' costing ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    QUOTE_PRICE_PROVIDED: {
        value: 'QUOTE_PRICE_PROVIDED',
        icon: '/icons/notification/approve.svg',
        category: ENTITY_TYPE.QUOTE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' has sent quote offer of ',
        anotherAction: ' styles ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.MULTIPLE_IMAGES_SINGLE_ITEM
    },
    QUOTE_TARGET_PRICE_UPDATE: {
        value: 'QUOTE_TARGET_PRICE_UPDATE',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.QUOTE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' has updated target price of ',
        anotherAction: ' styles ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.MULTIPLE_IMAGES_SINGLE_ITEM
    },
    QUOTE_PRICE_APPROVED: {
        value: 'QUOTE_PRICE_APPROVED',
        icon: '/icons/notification/approve.svg',
        category: ENTITY_TYPE.QUOTE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' approved quote of ',
        anotherAction: ' styles ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.MULTIPLE_IMAGES_SINGLE_ITEM
    },
    QUOTE_PRICE_REVISION: {
        value: 'QUOTE_PRICE_REVISION',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.QUOTE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' request revision of quotes for ',
        anotherAction: ' styles ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.MULTIPLE_IMAGES_SINGLE_ITEM
    },
    QUOTE_REQUEST_MEMBER_ADDED: {
        value: 'QUOTE_REQUEST_MEMBER_ADDED',
        icon: '/icons/notification/person-add.svg',
        category: ENTITY_TYPE.QUOTE,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' added ',
        anotherAction: ' you to a costing request ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    NEW_PHOTOGRAPHY_REQUEST: {
        value: 'NEW_PHOTOGRAPHY_REQUEST',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.PHOTOGRAPHY,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' sent ',
        anotherAction: ' a photoshoot request ',
        entityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    PHOTOSHOOT_COMPLETED: {
        value: 'PHOTOSHOOT_COMPLETED',
        icon: '/icons/notification/complete.svg',
        category: ENTITY_TYPE.PHOTOGRAPHY,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: ' completed ',
        anotherAction: ' photoshoot of ',
        titleEntityTypeList: [ENTITY_TYPE.COLLECTION],
        content: CONTENT.MULTIPLE_IMAGES_SINGLE_ITEM
    },
    //order
    PROJECT_MEMBER_ADDED: {
        value: 'PROJECT_MEMBER_ADDED',
        icon: '/icons/notification/person-add.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: false,
        action: '',
        anotherAction: '',
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    PROJECT_DEADLINE_UPDATED: {
        value: 'PROJECT_DEADLINE_UPDATED',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: false,
        action: '',
        anotherAction: '',
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    PROJECT_STATUS_UPDATED: {
        value: 'PROJECT_DEADLINE_UPDATED',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: false,
        action: '',
        anotherAction: '',
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    TASK_MEMBER_ASSIGNED: {
        value: 'TASK_MEMBER_ASSIGNED',
        icon: '/icons/notification/assign.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' assigned ',
        anotherAction: ' you on ',
        titleEntityTypeList: [ENTITY_TYPE.STEP, 'of', ENTITY_TYPE.PRODUCT],
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    TNA_COMPLETED: {
        value: 'TNA_COMPLETED',
        icon: '/icons/notification/complete.svg',
        category: ENTITY_TYPE.STEP,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: false,
        action: ' TNA ',
        anotherAction: ' completed of ',
        titleEntityTypeList: [ENTITY_TYPE.PRODUCT],
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    ORDER_ON_SHIPMENT: {
        value: 'ORDER_ON_SHIPMENT',
        icon: '/icons/notification/complete.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: false,
        action: ' Order has been completed & sent ',
        anotherAction: ' for shipment ',
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    TASK_APPROVED: {
        value: 'TASK_APPROVED',
        icon: '/icons/notification/approve.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' completed ',
        anotherAction: ' task of ',
        titleEntityTypeList: [ENTITY_TYPE.PRODUCT],
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    TASK_DEADLINE_UPDATED: {
        value: 'TASK_DEADLINE_UPDATED',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' updated ',
        anotherAction: ' due date of ',
        titleEntityTypeList: [
            ENTITY_TYPE.STEP,
            'of',
            ENTITY_TYPE.PRODUCT,
            'to',
            ENTITY_TYPE.NEW_VALUE
        ],
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    NEW_RFQ_ADDED: {
        value: 'NEW_RFQ_ADDED',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.RFQ,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: '',
        anotherAction: '',
        entityTypeList: [ENTITY_TYPE.COLLECTION, ENTITY_TYPE.POJO_CLASS],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    RFQ_PRICE_UPDATED: {
        value: 'RFQ_PRICE_UPDATED',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.RFQ,
        frontEvent: FRONTED_EVENT.REQUEST,
        isActor: true,
        action: '',
        anotherAction: '',
        entityTypeList: [ENTITY_TYPE.PRODUCT],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    BUYER_ETD_UPDATED: {
        value: 'BUYER_ETD_UPDATED',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' updated ',
        anotherAction: ' buyer ETD to ',
        titleEntityTypeList: [ENTITY_TYPE.POJO_CLASS],
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    FACTORY_ETD_UPDATED: {
        value: 'FACTORY_ETD_UPDATED',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' updated ',
        anotherAction: ' factory ETD to ',
        titleEntityTypeList: [ENTITY_TYPE.POJO_CLASS],
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    MEASUREMENT_CHART_UPDATED: {
        value: 'MEASUREMENT_CHART_UPDATED',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' updated ',
        anotherAction: ' measurement chart of ',
        titleEntityTypeList: [ENTITY_TYPE.PRODUCT],
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    UPDATED_COLOR_SIZE_WISE_QTY: {
        value: 'UPDATED_COLOR_SIZE_WISE_QTY',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.PRODUCT,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' updated ',
        anotherAction: ' Color & size wise Quantity of ',
        titleEntityTypeList: [ENTITY_TYPE.PRODUCT],
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    UPDATED_COLOR_SIZE_WISE_PRICE: {
        value: 'UPDATED_COLOR_SIZE_WISE_PRICE',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.PRODUCT,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' updated ',
        anotherAction: ' inco terms of ',
        titleEntityTypeList: [ENTITY_TYPE.PRODUCT],
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    ORDER_PO_RECEIVE_DATE_UPDATED: {
        value: 'ORDER_PO_RECEIVE_DATE_UPDATED',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' updated ',
        anotherAction: ' PO receive date ',
        titleEntityTypeList: ['to', ENTITY_TYPE.POJO_CLASS],
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    ORDER_PI_UPDATED: {
        value: 'ORDER_PI_UPDATED',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: false,
        action: '',
        anotherAction: '',
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    ORDER_SC_UPDATED: {
        value: 'ORDER_SC_UPDATED',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: false,
        action: '',
        anotherAction: '',
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    NEW_PI_REQUEST: {
        value: 'NEW_PI_REQUEST',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' requested ',
        anotherAction: ' for PI ',
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    PI_PROVIDED: {
        value: 'PI_PROVIDED',
        icon: '/icons/notification/document-approved.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: false,
        action: '',
        anotherAction: ' ',
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    INVOICE_PAYMENT_ADDED: {
        value: 'INVOICE_PAYMENT_ADDED',
        icon: '/icons/notification/plus.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: false,
        action: '',
        anotherAction: ' ',
        entityTypeList: [ENTITY_TYPE.ORDER, ENTITY_TYPE.INVOICE],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    NEW_SC_REQUEST: {
        value: 'NEW_SC_REQUEST',
        icon: '/icons/notification/revise.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' requested ',
        anotherAction: ' for SC ',
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    SC_PROVIDED: {
        value: 'SC_PROVIDED',
        icon: '/icons/notification/document-approved.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: false,
        action: '',
        anotherAction: ' ',
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    TASK_COMPLETED: {
        value: 'TASK_COMPLETED',
        icon: '/icons/notification/complete.svg',
        category: ENTITY_TYPE.ORDER,
        frontEvent: FRONTED_EVENT.ORDER,
        isActor: true,
        action: ' Completed ',
        anotherAction: ' Task of ',
        titleEntityTypeList: [ENTITY_TYPE.PRODUCT],
        entityTypeList: [ENTITY_TYPE.ORDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    FAVOURITE_FOLDER_SHARED: {
        value: 'FAVOURITE_FOLDER_SHARED',
        icon: '/icons/notification/person-add.svg',
        category: 'COLLECTION',
        frontEvent: 'REQUEST',
        isActor: true,
        action: ' shared ',
        anotherAction: ' a folder with you ',
        entityTypeList: [ENTITY_TYPE.FAVOURITE_FOLDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    },
    REMOVED_FROM_FAVOURITE_FOLDER: {
        value: 'REMOVED_FROM_FAVOURITE_FOLDER',
        icon: '/icons/notification/delete.svg',
        category: 'COLLECTION',
        frontEvent: 'REQUEST',
        isActor: true,
        action: ' removed ',
        anotherAction: ' you from a folder ',
        entityTypeList: [ENTITY_TYPE.FAVOURITE_FOLDER],
        content: CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
    }
}

export const FILTER_ACTION = {
    all: { value: 'Read & Unread', isSeen: null, isImportant: null },
    read: { value: 'Read', isSeen: true, isImportant: null },
    unread: { value: 'Unread', isSeen: false, isImportant: null },
    important: { value: 'Important', isSeen: null, isImportant: true }
}

export const HIGHLIGHT_EVENTS = [
    'SAMPLE_DEVELOPMENT_POST',
    'PRODUCT_DEVELOPMENT_MENTIONED_AT_POST',
    'PRODUCT_DEVELOPMENT_POST',
    'SAMPLE_DEVELOPMENT_MENTIONED_AT_POST',
    'QUOTE_REQUEST_POST',
    'QUOTE_REQUEST_MENTIONED_AT_POST',
    'COLLECTION_POST',
    'COLLECTION_MENTIONED_AT_POST',
    'PHOTOGRAPHY_REQUEST_POST',
    'PHOTOGRAPHY_REQUEST_MENTIONED_AT_POST',
    'TASK_REVISED',
    'TASK_POST_ADDED',
    'TASK_COMMENT_ADDED',
    'TASK_POST_MENTIONED',
    'TASK_COMMENT_MENTIONED'
]

export const getTitlePartText = (titlePartList, titlePartType) => {
    let titleObj = titlePartList.find(
        (titlePart) => titlePart?.titlePartType === titlePartType
    )
    return titleObj
}
