import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    GET_QUOTE_DETAIL,
    GET_TEAM_MEMBERS
} from '../../redux_toolkit/@types/thunk.types'
import QuoteThunks from '../../redux_toolkit/Quote/QuoteThunks'
import {
    QouteActions,
    useQuoteSelector
} from '../../redux_toolkit/Quote/QuoteSlice'
import {
    CLEAR_SELECTED_PRODUCT_FROM_QUOTE,
    CLEAR_QUOTE_DETAILS,
    SET_SELECT_PRODUCT_FROM_QUOTE,
    SET_ALL_PRODUCT_FROM_QUOTE
} from '../../redux_toolkit/@types/action.types'
import {
    addNewVariant,
    getCostingSheet,
    postTargetPrice,
    quotationApproved,
    requestForRevision,
    updateVariant,
    removeDuplicateitem
} from '../../services/Quote'
import BreadCrumb from '../../v3/common/BreadCrumb'
import AddPeople from '../../v3/common/AddPeople'
import Pagination from '../../common/Pagination'
import { useTeams } from '../../redux_toolkit/Team'
import { TeamThunks } from '../../redux_toolkit/Team/TeamThunks'
import { BASE_URL } from '../../constant'
import { closeLoader, openLoader, useLoader } from '../../redux_toolkit/Loader'
import { ReactComponent as ChatIcon } from '../../../assets/icons/chat.svg'
import { ReactComponent as PlusSquareIcon } from '../../../assets/icons/plus-square.svg'
import { ReactComponent as RemoveSquareIcon } from '../../../assets/icons/minus-square.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg'
import {
    validateFloatNumber,
    validateNumber,
    generateUniqueId,
    isValidInput,
    isSetItem,
    isPackItem,
    formatFeatureImageDocUrl
} from '../../helpers/Util'
import ConversationRightSidePanel from '../../v3/common/ConversationRightSidePanel'
import PlaceOrder from './PlaceOrder'
import RequestForSample from '../../common/RequestForSample'
import DesignRightSidePanel from '../../v3/common/DesignRightSidePanel'
import {
    ConversationActionTypes,
    getCurrentPage
} from '../../redux_toolkit/Conversation'
import {
    CLEAR_ALL_CHATS,
    NEW_INCOMING_MESSAGE_SUCCESS,
    SET_CURRENT_ACTIVE_PAGE
} from '../../redux_toolkit/@types/action.types'
import PackSetOrTag from '../../v3/common/PackOrSetTag'
import DesignImages from '../../v3/common/DesignImages'

const QuoteDetails = () => {
    const quoteStoreData = useQuoteSelector()
    const dispatch = useDispatch()
    const [isEditTargetPrice, setIsEditTargetPrice] = useState([])
    const [editQuantityCellId, setEditQuantityCellId] = useState('')
    const [hasRecost, setHasRecost] = useState([])
    const [isPlaceOrder, setIsPlaceOrder] = useState(false)
    const [isOrderDisable, setIsOrderDisable] = useState(false)
    const [quoteStore, setQuoteStore] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10)
    const [openConversationId, setOpenConversationId] = useState(null)
    const [isShowSample, setIsShowSample] = useState(false)
    const [isDesignClick, setIsDesignClick] = useState(null)
    const [selectedDesignInfo, setSelectedDesignInfo] = useState(null)
    const members = useTeams()
    const { id } = useParams()
    const [searchParams] = useSearchParams()
    const isLoading = useLoader()
    const prevTarget = useRef(null)

    const isShowComment = searchParams.get('showComment')
    const productId = searchParams.get('productId')

    useEffect(() => {
        if (isShowComment) {
            dispatch({
                type: ConversationActionTypes[SET_CURRENT_ACTIVE_PAGE],
                payload: {
                    id: productId,
                    type: 'PRODUCT_CONVERSATION',
                    pageType: 'DETAILS'
                }
            })
            setOpenConversationId(productId)
        }
    }, [isShowComment])

    useEffect(() => {
        setQuoteStore(quoteStoreData)
    }, [quoteStoreData])

    useEffect(() => {
        return () => {
            dispatch({
                type: QouteActions[CLEAR_SELECTED_PRODUCT_FROM_QUOTE]
            })
            dispatch({
                type: QouteActions[CLEAR_QUOTE_DETAILS]
            })
            dispatch({
                type: ConversationActionTypes[CLEAR_ALL_CHATS]
            })
        }
    }, [])

    useEffect(() => {
        findFirstMatchAndBreak()
    }, [quoteStore?.selectedProducts])

    useEffect(() => {
        getQuoteDetail()
        dispatch(
            TeamThunks[GET_TEAM_MEMBERS](
                `${BASE_URL}/quote/${id}/members`,
                true
            )
        )
    }, [id])

    const indexOfFirstPost = (currentPage - 1) * postsPerPage
    const indexOfLastPost = indexOfFirstPost + postsPerPage
    const currentPosts = quoteStore?.quote?.quoteItemResponseList?.slice(
        indexOfFirstPost,
        indexOfLastPost
    )

    const getQuoteDetail = () => {
        dispatch(QuoteThunks[GET_QUOTE_DETAIL](id))
    }

    const recostForCollection = async (costingId) => {
        try {
            await requestForRevision(costingId)
            toast.success('Recost request successfull')
            setHasRecost([])
            getQuoteDetail()
        } catch ({ response }) {
            if (response) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    const approvedOffer = async (costingId) => {
        try {
            await quotationApproved(costingId)
            toast.success('Approved')
            getQuoteDetail()
        } catch ({ response }) {
            if (response) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    const handleRecost = (costingId) => {
        setIsEditTargetPrice([costingId])
        setHasRecost([costingId])
    }

    const handleApprovedOffer = (costingId) => {
        approvedOffer(costingId)
    }

    const handleEditTargetPrice = (costingId) => {
        setIsEditTargetPrice([costingId])
    }

    const checkTargetPriceEdit = (costingId) => {
        return !!isEditTargetPrice.includes(costingId)
    }

    const targetPricePosted = async (reqBody) => {
        try {
            const { data } = await postTargetPrice(reqBody)
            if (data) toast.success(data.message)
            getQuoteDetail()
            const isRecost = !!hasRecost.includes(reqBody.id)
            if (isRecost) recostForCollection(reqBody.id)
        } catch ({ response }) {
            if (response) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    const variantPosted = async (reqBody) => {
        try {
            const { data } = await updateVariant(reqBody, id)
            setEditQuantityCellId('')
            if (data) toast.success(data.message)
            getQuoteDetail()
        } catch ({ response }) {
            if (response) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    const handleTargetPriceSubmit = (e, costingId) => {
        const { value } = e.target
        if (value) {
            const body = {
                id: costingId,
                targetPrice: !value ? null : value
            }
            targetPricePosted(body)
        }
        setIsEditTargetPrice([])
    }

    const downloadCostingSheet = async (quantityWiseIds) => {
        try {
            dispatch(openLoader())
            const { data } = await getCostingSheet(id, quantityWiseIds)
            if (data) window.open(data, '_parent')
            dispatch(closeLoader())
        } catch ({ response }) {
            dispatch(closeLoader())
            if (response) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    const handleCostingDownload = () => {
        let ids = ''
        const length = quoteStore?.quote.quoteItemResponseList.length
        quoteStore?.quote.quoteItemResponseList.map((el, i) => {
            if (i == length - 1) ids = ids + el.quantityWiseCostingId
            else ids = ids + el.quantityWiseCostingId + ','
        })
        downloadCostingSheet(ids)
    }
    const duplicatedQuoteItem = async (quoteItemId) => {
        try {
            const { data } = await addNewVariant(id, quoteItemId)
            if (data) toast.success(data.message)
            getQuoteDetail()
        } catch ({ response }) {
            if (response) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    const onRemoveDuplicateitem = async (quoteItemId) => {
        try {
            const { data } = await removeDuplicateitem(id, quoteItemId)
            if (data) toast.success(data.message)
            getQuoteDetail()
        } catch ({ response }) {
            if (response) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    const onHidePopup = () => {
        setIsPlaceOrder(false)
        setIsShowSample(false)
        dispatch({
            type: QouteActions[CLEAR_SELECTED_PRODUCT_FROM_QUOTE]
        })
    }

    const onQuantityClick = (id) => {
        setEditQuantityCellId(id)
    }

    const onUpdateQuantity = (e, quoteItem) => {
        const { value } = e.target
        if (!value) {
            setEditQuantityCellId('')
            return
        }
        const body = {
            itemId: quoteItem.id,
            quantityWiseInitialCostingId: quoteItem.quantityWiseCostingId,
            quantity: value
        }
        variantPosted(body)
    }

    const getAllQuoteIds = () => {
        const uniqueProductIdIds = []
        const seenProductIds = new Set()
        currentPosts?.forEach((item) => {
            if (!seenProductIds.has(item.productId) && !item?.isSameProduct) {
                uniqueProductIdIds.push(item.id)
                seenProductIds.add(item.productId)
            }
        })
        return uniqueProductIdIds
    }

    const getSelectedProductIds = () => {
        let productIds = quoteStore?.quote.quoteItemResponseList
            .filter((item) => quoteStore?.selectedProducts.includes(item.id))
            .map((item) => item.productId)
        return [...new Set(productIds)]
    }

    const isCheckboxChecked = (quoteItem) => {
        return quoteStore?.selectedProducts?.includes(quoteItem?.id)
    }

    const isAllCheckboxChecked = () => {
        return quoteStore?.selectedProducts?.length === getAllQuoteIds()?.length
    }

    const handleCheckbox = (e) => {
        let { checked, value } = e.target
        dispatch({
            type: QouteActions[SET_SELECT_PRODUCT_FROM_QUOTE],
            payload: { checked, value: parseInt(value) }
        })
    }

    const onSelectAll = async () => {
        let quoteIds = await getAllQuoteIds()
        dispatch({
            type: QouteActions[SET_ALL_PRODUCT_FROM_QUOTE],
            payload: { checked: !isAllCheckboxChecked(), items: quoteIds }
        })
    }

    const isDisabled = (quoteItem) => {
        return (
            quoteStore?.selectedProducts?.length > 0 &&
            quoteItem.status !== 'APPROVED' &&
            quoteStore?.selectedProducts?.includes(quoteItem?.id)
        )
    }

    function findFirstMatchAndBreak() {
        let quoteList = quoteStore?.quote?.quoteItemResponseList || []
        for (const item of quoteList) {
            if (isDisabled(item)) {
                setIsOrderDisable(true)
                return item
            }
        }
        setIsOrderDisable(false)
        return null
    }

    const onClearChat = (id) => {
        setQuoteStore((prevQuoteStore) => {
            const updatedQuoteStore = {
                ...prevQuoteStore,
                quote: {
                    ...prevQuoteStore?.quote,
                    quoteItemResponseList:
                        prevQuoteStore?.quote?.quoteItemResponseList?.map(
                            (item) => {
                                if (item.id === id) {
                                    return {
                                        ...item,
                                        noOfUnreadMessage: 0
                                    }
                                }
                                return item
                            }
                        )
                }
            }
            return updatedQuoteStore
        })
    }

    const onChangePostPerPage = (value) => {
        setPostsPerPage(value)
        setCurrentPage(1)
    }
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    const renderActionStatus = (quoteItem) => {
        switch (quoteItem?.status) {
            case 'OFFER_SENT':
                return (
                    <div className='flex items-center'>
                        <button
                            className='button primary regular-12 round mr-2'
                            onClick={() =>
                                handleApprovedOffer(
                                    quoteItem?.quantityWiseCostingId
                                )
                            }
                        >
                            Approve
                        </button>
                        <button
                            className='button secondary regular-12 round whitespace-nowrap'
                            onClick={() =>
                                handleRecost(quoteItem?.quantityWiseCostingId)
                            }
                        >
                            Re-cost
                        </button>
                    </div>
                )
            case 'INITIALIZED':
                return ''
            case 'REQUEST_FOR_REVISION':
                return (
                    <button className='button pill round'>
                        <span className='mr-2'>Re-cost requested</span>
                        <svg
                            width={22}
                            height={22}
                            viewBox='0 0 22 22'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <g id='Group'>
                                <path
                                    id='Path'
                                    d='M3.49687 9.33216L6.70904 6.11999C7.85523 4.97376 9.40986 4.3299 11.0308 4.33008V4.33008C13.5297 4.33026 15.7767 5.85161 16.7049 8.17168L16.8358 8.49848'
                                    stroke='#21242B'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    id='Path_2'
                                    d='M6.83159 9.33277H3.49687V5.99805'
                                    stroke='#21242B'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    id='Path_3'
                                    d='M18.5031 12.668L15.2909 15.8801C14.1448 17.0264 12.5901 17.6702 10.9691 17.6701V17.6701C8.47031 17.6699 6.22328 16.1485 5.29512 13.8285L5.16423 13.5016'
                                    stroke='#21242B'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    id='Path_4'
                                    d='M15.1684 12.668H18.5031V16.0027'
                                    stroke='#21242B'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </g>
                        </svg>
                    </button>
                )
            case 'APPROVED':
                return (
                    <button className='button primary round' disabled>
                        <span className='mr-2'>Approved</span>
                        <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <g id='Group'>
                                <g id='Group_2'>
                                    <path
                                        id='Path'
                                        d='M16.3008 6.20312L10.0042 12.4998L6.85667 9.35146'
                                        stroke='white'
                                        strokeWidth='1.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                    <path
                                        id='Path_2'
                                        d='M17.5 10C17.5 14.1425 14.1425 17.5 10 17.5C5.8575 17.5 2.5 14.1425 2.5 10C2.5 5.8575 5.8575 2.5 10 2.5C11.2108 2.5 12.3508 2.79417 13.3633 3.30333'
                                        stroke='white'
                                        strokeWidth='1.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </g>
                            </g>
                        </svg>
                    </button>
                )

            default:
                break
        }
    }

    const renderTable = (currentPosts) => {
        return currentPosts?.map((quoteItem, idx) => {
            return (
                <>
                    <tr key={generateUniqueId()}>
                        <td
                            rowSpan={
                                isSetItem(quoteItem)
                                    ? quoteItem.setMemberList.length
                                    : 1
                            }
                        >
                            {!quoteItem.isSameProduct ? (
                                <input
                                    type='checkbox'
                                    id={quoteItem?.id}
                                    value={quoteItem?.id}
                                    checked={isCheckboxChecked(quoteItem)}
                                    onChange={handleCheckbox}
                                />
                            ) : (
                                <input
                                    className='checkbox-disabled'
                                    type='checkbox'
                                    disabled
                                />
                            )}
                        </td>
                        <td
                            className='relative min-w-[220px] w-[240px]'
                            rowSpan={
                                isSetItem(quoteItem)
                                    ? quoteItem.setMemberList.length
                                    : 1
                            }
                        >
                            {!quoteItem.isSameProduct && (
                                <div className='flex items-center'>
                                    <div className='w-10'>
                                        <DesignImages
                                            designInfo={formatFeatureImageDocUrl(
                                                quoteItem
                                            )}
                                        />
                                    </div>
                                    <div className='ml-2'>
                                        <p className='mb-1'>
                                            {isSetItem(quoteItem) && (
                                                <PackSetOrTag tagName='Set' />
                                            )}
                                        </p>
                                        <p className='mb-1'>
                                            {isPackItem(quoteItem) && (
                                                <PackSetOrTag tagName='Pack' />
                                            )}
                                        </p>
                                        <p
                                            className='blue regular-14 cursor-pointer flex items-center'
                                            onClick={() => {
                                                setIsDesignClick(
                                                    quoteItem?.productId
                                                )
                                                setSelectedDesignInfo({
                                                    id: quoteItem?.productId,
                                                    isSet: isSetItem(quoteItem)
                                                })
                                            }}
                                        >
                                            {quoteItem?.productRefNo}
                                            {isDisabled(quoteItem) && (
                                                <span className='regular-16 red flex items-center ml-2'>
                                                    <WarningIcon className='mr-1' />
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {!isSetItem(quoteItem) && (
                                <div className='flex absolute right-0 bottom-0 items-center column-hover-icon'>
                                    <span
                                        className='p-1 bg-primary cursor-pointer mr-1'
                                        onClick={() =>
                                            duplicatedQuoteItem(quoteItem.id)
                                        }
                                    >
                                        <PlusSquareIcon />
                                    </span>
                                    {quoteItem.isSameProduct && (
                                        <span
                                            className='p-1 bg-primary cursor-pointer'
                                            onClick={() =>
                                                onRemoveDuplicateitem(
                                                    quoteItem.id
                                                )
                                            }
                                        >
                                            <RemoveSquareIcon />
                                        </span>
                                    )}
                                </div>
                            )}
                        </td>
                        <td>
                            {isSetItem(quoteItem)
                                ? quoteItem?.setMemberList[0]?.variation
                                : quoteItem?.variation}
                        </td>
                        <td
                            onClick={() =>
                                !quoteItem?.quantity &&
                                onQuantityClick(quoteItem.id)
                            }
                            rowSpan={
                                isSetItem(quoteItem)
                                    ? quoteItem.setMemberList.length
                                    : 1
                            }
                        >
                            {editQuantityCellId === quoteItem.id ? (
                                <input
                                    type='number'
                                    name='quantity'
                                    autoFocus
                                    id='quantity'
                                    min={1}
                                    defaultValue={quoteItem?.quantity || ''}
                                    className='w-36 h-[50px] border p-2'
                                    onBlur={(e) =>
                                        onUpdateQuantity(e, quoteItem)
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            onUpdateQuantity(e, quoteItem)
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.target.value.length == 0 &&
                                            e.which == 48
                                        ) {
                                            e.preventDefault()
                                        }
                                        validateNumber(e)
                                    }}
                                />
                            ) : (
                                quoteItem?.quantity
                            )}
                        </td>
                        <td
                            onClick={() =>
                                !quoteItem?.target &&
                                handleEditTargetPrice(
                                    quoteItem?.quantityWiseCostingId
                                )
                            }
                            rowSpan={
                                isSetItem(quoteItem)
                                    ? quoteItem.setMemberList.length
                                    : 1
                            }
                        >
                            {checkTargetPriceEdit(
                                quoteItem?.quantityWiseCostingId
                            ) ? (
                                <input
                                    type='text'
                                    name='targetPrice'
                                    autoFocus
                                    id='target-price'
                                    defaultValue={
                                        quoteItem?.target?.toFixed(2) || ''
                                    }
                                    className='w-36 h-[50px] border p-2'
                                    onFocus={(event) =>
                                        (prevTarget.current =
                                            event.target.value)
                                    }
                                    onBlur={(e) => {
                                        if (!isValidInput(prevTarget, e)) {
                                            setIsEditTargetPrice([])
                                            setHasRecost([])
                                            prevTarget.current = null
                                            return
                                        }
                                        handleTargetPriceSubmit(
                                            e,
                                            quoteItem?.quantityWiseCostingId
                                        )
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleTargetPriceSubmit(
                                                e,
                                                quoteItem?.quantityWiseCostingId
                                            )
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (
                                            e.target.value.length == 0 &&
                                            e.which == 48
                                        ) {
                                            e.preventDefault()
                                        }
                                        validateFloatNumber(e)
                                    }}
                                />
                            ) : (
                                quoteItem.target &&
                                `$ ${quoteItem.target?.toFixed(2)}`
                            )}
                        </td>
                        <td
                            rowSpan={
                                isSetItem(quoteItem)
                                    ? quoteItem.setMemberList.length
                                    : 1
                            }
                        >
                            <div className='flex gap-2'>
                                {quoteItem?.buyerOfferPrice && (
                                    <span>
                                        ${' '}
                                        {quoteItem?.buyerOfferPrice?.toFixed(2)}
                                    </span>
                                )}
                                {quoteItem?.priceUpdateDTOArray && (
                                    <s className='text-[#C4C4C4]'>
                                        ${' '}
                                        {quoteItem?.priceUpdateDTOArray[0].previousValue?.toFixed(
                                            2
                                        )}
                                    </s>
                                )}
                            </div>
                        </td>
                        <td
                            rowSpan={
                                isSetItem(quoteItem)
                                    ? quoteItem.setMemberList.length
                                    : 1
                            }
                        >
                            {renderActionStatus(quoteItem)}
                        </td>
                        <td
                            className='text-center'
                            rowSpan={
                                isSetItem(quoteItem)
                                    ? quoteItem.setMemberList.length
                                    : 1
                            }
                        >
                            {!quoteItem.isSameProduct && (
                                <button
                                    className='button secondary icon-only chat-btn'
                                    onClick={() => {
                                        dispatch({
                                            type: ConversationActionTypes[
                                                SET_CURRENT_ACTIVE_PAGE
                                            ],
                                            payload: {
                                                id: quoteItem?.productId,
                                                type: 'PRODUCT_CONVERSATION',
                                                pageType: 'DETAILS'
                                            }
                                        })
                                        setOpenConversationId(
                                            quoteItem?.productId
                                        )
                                        onClearChat(quoteItem?.id)
                                    }}
                                >
                                    <ChatIcon />
                                    {quoteItem?.noOfUnreadMessage > 0 && (
                                        <span className='count absolute'>
                                            {quoteItem?.noOfUnreadMessage}
                                        </span>
                                    )}
                                </button>
                            )}
                        </td>
                    </tr>
                    {isSetItem(quoteItem) &&
                        quoteItem?.setMemberList &&
                        quoteItem?.setMemberList.length > 0 &&
                        quoteItem?.setMemberList.slice(1).map((member) => (
                            <tr key={member?.id}>
                                <td>{member?.variation}</td>
                            </tr>
                        ))}
                </>
            )
        })
    }

    const getMemberList = () => {
        return members?.data?.filter((item) =>
            ['BUYER', 'ACCOUNT_MANAGER', 'PROJECT_MANAGER'].includes(
                item.primaryUserType
            )
        )
    }

    const renderTableView = () => {
        return (
            <div className='costing-table mt-6'>
                <table className='table quote-list-table'>
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type='checkbox'
                                    id='SelectAll'
                                    checked={isAllCheckboxChecked()}
                                    onChange={onSelectAll}
                                />
                            </th>
                            <th>Style</th>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Target price</th>
                            <th>Offer price</th>
                            <th>Action</th>
                            <th>Conversation</th>
                        </tr>
                    </thead>
                    <tbody>{renderTable(currentPosts)}</tbody>
                </table>
            </div>
        )
    }

    const renderMobileView = () => {
        return (
            <div className='tab-view-table quote-tab-view-table py-4'>
                <div className='select-all mb-2'>
                    <div className='flex items-center'>
                        <input
                            type='checkbox'
                            id='SelectAll'
                            checked={isAllCheckboxChecked()}
                            onChange={onSelectAll}
                        />
                        <label className='text-base blue ml-2'>
                            Select all
                        </label>
                    </div>
                </div>
                <div className='all-rows'>
                    {currentPosts?.map((quoteItem, index) => {
                        return (
                            <div key={`${index}_post`}>
                                <div className='single-row'>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <td
                                                    rowSpan='7'
                                                    className='w-[50px] min-w-[50px]'
                                                >
                                                    {!quoteItem.isSameProduct ? (
                                                        <input
                                                            type='checkbox'
                                                            id={quoteItem?.id}
                                                            value={
                                                                quoteItem?.id
                                                            }
                                                            checked={isCheckboxChecked(
                                                                quoteItem
                                                            )}
                                                            onChange={
                                                                handleCheckbox
                                                            }
                                                        />
                                                    ) : (
                                                        <input
                                                            className='checkbox-disabled'
                                                            type='checkbox'
                                                            disabled
                                                        />
                                                    )}

                                                    {quoteItem.isSameProduct && (
                                                        <button
                                                            className='button link remove-row'
                                                            onClick={() =>
                                                                onRemoveDuplicateitem(
                                                                    quoteItem.id
                                                                )
                                                            }
                                                        >
                                                            <RemoveSquareIcon />
                                                        </button>
                                                    )}
                                                </td>
                                                <td className='heading'>
                                                    Style
                                                </td>
                                                <td>
                                                    {!quoteItem.isSameProduct && (
                                                        <div className='flex items-center'>
                                                            <div className='w-10'>
                                                                <DesignImages
                                                                    designInfo={formatFeatureImageDocUrl(
                                                                        quoteItem
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className='ml-2'>
                                                                <p className='mb-1'>
                                                                    {isSetItem(
                                                                        quoteItem
                                                                    ) && (
                                                                        <PackSetOrTag tagName='Set' />
                                                                    )}
                                                                </p>
                                                                <p className='mb-1'>
                                                                    {isPackItem(
                                                                        quoteItem
                                                                    ) && (
                                                                        <PackSetOrTag tagName='Pack' />
                                                                    )}
                                                                </p>
                                                                <p
                                                                    className='blue regular-14 cursor-pointer flex items-center'
                                                                    onClick={() => {
                                                                        setIsDesignClick(
                                                                            quoteItem?.productId
                                                                        )
                                                                        setSelectedDesignInfo(
                                                                            {
                                                                                id: quoteItem?.productId,
                                                                                isSet: isSetItem(
                                                                                    quoteItem
                                                                                )
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    {
                                                                        quoteItem?.productRefNo
                                                                    }
                                                                    {isDisabled(
                                                                        quoteItem
                                                                    ) && (
                                                                        <span className='regular-16 red flex items-center ml-2'>
                                                                            <WarningIcon className='mr-1' />
                                                                        </span>
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Description
                                                </td>
                                                <td>
                                                    {isSetItem(quoteItem) ? (
                                                        <ul className='with-bullet'>
                                                            {quoteItem?.setMemberList.map(
                                                                (member) => (
                                                                    <li
                                                                        key={
                                                                            member?.id
                                                                        }
                                                                    >
                                                                        {
                                                                            member?.variation
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    ) : (
                                                        quoteItem?.variation
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Quantity
                                                </td>
                                                <td
                                                    onClick={() =>
                                                        !quoteItem?.quantity &&
                                                        onQuantityClick(
                                                            quoteItem.id
                                                        )
                                                    }
                                                >
                                                    {editQuantityCellId ===
                                                    quoteItem.id ? (
                                                        <input
                                                            type='number'
                                                            name='quantity'
                                                            autoFocus
                                                            id='quantity'
                                                            placeholder='quantity'
                                                            min={1}
                                                            defaultValue={
                                                                quoteItem?.quantity ||
                                                                ''
                                                            }
                                                            className='w-36 h-[50px] border p-2'
                                                            onBlur={(e) =>
                                                                onUpdateQuantity(
                                                                    e,
                                                                    quoteItem
                                                                )
                                                            }
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.key ===
                                                                    'Enter'
                                                                ) {
                                                                    onUpdateQuantity(
                                                                        e,
                                                                        quoteItem
                                                                    )
                                                                }
                                                            }}
                                                            onKeyPress={(e) => {
                                                                if (
                                                                    e.target
                                                                        .value
                                                                        .length ==
                                                                        0 &&
                                                                    e.which ==
                                                                        48
                                                                ) {
                                                                    e.preventDefault()
                                                                }
                                                                validateNumber(
                                                                    e
                                                                )
                                                            }}
                                                        />
                                                    ) : (
                                                        quoteItem?.quantity
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Target Price
                                                </td>
                                                <td
                                                    onClick={() =>
                                                        !quoteItem?.target &&
                                                        handleEditTargetPrice(
                                                            quoteItem?.quantityWiseCostingId
                                                        )
                                                    }
                                                >
                                                    {checkTargetPriceEdit(
                                                        quoteItem?.quantityWiseCostingId
                                                    ) ? (
                                                        <input
                                                            type='text'
                                                            name='targetPrice'
                                                            autoFocus
                                                            id='target-price'
                                                            price='price'
                                                            defaultValue={
                                                                quoteItem?.target?.toFixed(
                                                                    2
                                                                ) || ''
                                                            }
                                                            className='w-36 h-[50px] border p-2'
                                                            onBlur={(e) =>
                                                                handleTargetPriceSubmit(
                                                                    e,
                                                                    quoteItem?.quantityWiseCostingId
                                                                )
                                                            }
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.key ===
                                                                    'Enter'
                                                                ) {
                                                                    handleTargetPriceSubmit(
                                                                        e,
                                                                        quoteItem?.quantityWiseCostingId
                                                                    )
                                                                }
                                                            }}
                                                            onKeyPress={(e) => {
                                                                if (
                                                                    e.target
                                                                        .value
                                                                        .length ==
                                                                        0 &&
                                                                    e.which ==
                                                                        48
                                                                ) {
                                                                    e.preventDefault()
                                                                }
                                                                validateFloatNumber(
                                                                    e
                                                                )
                                                            }}
                                                        />
                                                    ) : (
                                                        quoteItem.target &&
                                                        `$ ${quoteItem.target?.toFixed(
                                                            2
                                                        )}`
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Offer price
                                                </td>
                                                <td>
                                                    {' '}
                                                    <div className='flex gap-2'>
                                                        {quoteItem?.buyerOfferPrice && (
                                                            <span>
                                                                ${' '}
                                                                {quoteItem?.buyerOfferPrice?.toFixed(
                                                                    2
                                                                )}
                                                            </span>
                                                        )}
                                                        {quoteItem?.priceUpdateDTOArray && (
                                                            <s className='text-[#C4C4C4]'>
                                                                ${' '}
                                                                {quoteItem?.priceUpdateDTOArray[0].previousValue?.toFixed(
                                                                    2
                                                                )}
                                                            </s>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Action
                                                </td>
                                                <td>
                                                    {renderActionStatus(
                                                        quoteItem
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='heading'>
                                                    Conversation
                                                </td>
                                                <td>
                                                    {!quoteItem.isSameProduct && (
                                                        <button
                                                            className='button secondary icon-only chat-btn'
                                                            onClick={() => {
                                                                dispatch({
                                                                    type: ConversationActionTypes[
                                                                        SET_CURRENT_ACTIVE_PAGE
                                                                    ],
                                                                    payload: {
                                                                        id: quoteItem?.productId,
                                                                        type: 'PRODUCT_CONVERSATION',
                                                                        pageType:
                                                                            'DETAILS'
                                                                    }
                                                                })
                                                                setOpenConversationId(
                                                                    quoteItem?.productId
                                                                )
                                                                onClearChat(
                                                                    quoteItem?.id
                                                                )
                                                            }}
                                                        >
                                                            <ChatIcon />
                                                            {quoteItem?.noOfUnreadMessage >
                                                                0 && (
                                                                <span className='count absolute'>
                                                                    {
                                                                        quoteItem?.noOfUnreadMessage
                                                                    }
                                                                </span>
                                                            )}
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='add-row'>
                                    <button
                                        className='button link my-5'
                                        onClick={() =>
                                            duplicatedQuoteItem(quoteItem.id)
                                        }
                                    >
                                        <PlusSquareIcon /> Add new style
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        quoteStore && (
            <>
                <div>
                    <div className='flex justify-between py-3 pr-4'>
                        <div className='quote-details-breadcrumb'>
                            <BreadCrumb isShowBackButton={true} />
                        </div>
                    </div>
                </div>

                <div className='page-content'>
                    {quoteStore?.isQuoteReceived && (
                        <div className='quote-status mt-2'>
                            <span className='status blue-1 mr-2'>
                                Quote received
                            </span>
                        </div>
                    )}
                    <div className='flex flex-wrap justify-between'>
                        <div className='quote-content'>
                            <h2 className='regular-18 weight-500 mt-2'>
                                {quoteStore?.quote?.title}{' '}
                                <span className='regular-12 gray_200'>
                                    (
                                    {
                                        quoteStore?.quote?.quoteItemResponseList
                                            ?.length
                                    }
                                    )
                                </span>
                            </h2>
                            <h4 className='regular-14 gray_300 my-2'>
                                {quoteStore?.quote?.referenceNumber}
                            </h4>
                            <p className='regular-12 max-w-[640px]'>
                                {quoteStore?.quote?.description || ''}
                            </p>
                            {quoteStore?.quote?.status &&
                                !['PENDING', 'INITIALIZED'].includes(
                                    quoteStore?.quote?.status
                                ) && (
                                    <button
                                        className='button link mt-6'
                                        onClick={handleCostingDownload}
                                    >
                                        Download costing sheet
                                    </button>
                                )}
                        </div>
                        <div className='mt-4 lg:mt-0 w-full lg:w-auto'>
                            <AddPeople
                                members={getMemberList()}
                                requestFrom='QUOTE'
                                callback={() =>
                                    dispatch(
                                        TeamThunks[GET_TEAM_MEMBERS](
                                            `${BASE_URL}/quote/${id}/members`,
                                            true
                                        )
                                    )
                                }
                            />
                        </div>
                    </div>
                    {renderTableView()}
                    {renderMobileView()}
                    <div className='mt-2 mb-4'>
                        <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={
                                quoteStore?.quote?.quoteItemResponseList
                            }
                            paginate={paginate}
                            currentPage={currentPage}
                            onChangePostPerPage={onChangePostPerPage}
                        />
                    </div>
                </div>
                {quoteStore?.selectedProducts?.length > 0 && (
                    <div className='selected-item-count flex justify-center on-quote'>
                        <div className='item-count-content flex items-center justify-between max-w-[900px] w-full'>
                            <div className='flex items-center'>
                                <p className='regular-18'>
                                    {quoteStore?.selectedProducts?.length} Item{' '}
                                </p>
                                <span className='px-2 xl:px-4'>|</span>
                                <button
                                    className='button link'
                                    onClick={() => onSelectAll()}
                                >
                                    {isAllCheckboxChecked()
                                        ? 'Unselect all'
                                        : 'Select all'}
                                </button>
                            </div>
                            {isOrderDisable && (
                                <div className='warning-info'>
                                    <p className='regular-16 red flex items-center'>
                                        <WarningIcon className='mr-1' /> You can
                                        place order only for approved styles.
                                    </p>
                                </div>
                            )}
                            <div>
                                <button
                                    className='button primary round mr-2 xl:mr-3'
                                    disabled={isOrderDisable}
                                    onClick={() => setIsPlaceOrder(true)}
                                >
                                    Place order
                                </button>
                                <button
                                    className='button primary round'
                                    onClick={() => setIsShowSample(true)}
                                >
                                    Sample
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {openConversationId && (
                    <ConversationRightSidePanel
                        type='PRODUCT_QUOTE_COMMENT'
                        pageId={id}
                        id={openConversationId}
                        openModal={openConversationId}
                        onGoBack={() => {
                            dispatch({
                                type: ConversationActionTypes[CLEAR_ALL_CHATS]
                            })
                            setOpenConversationId(null)
                        }}
                        onCloseModal={() => {
                            dispatch({
                                type: ConversationActionTypes[CLEAR_ALL_CHATS]
                            })
                            setOpenConversationId(null)
                        }}
                        memberList={members?.data}
                    />
                )}

                {isPlaceOrder && (
                    <PlaceOrder
                        details={quoteStore}
                        handleClose={onHidePopup}
                        selectedProducts={getSelectedProductIds()}
                        collectionId={quoteStore?.quote?.collectionId}
                    />
                )}
                {isShowSample && (
                    <RequestForSample
                        defaultTitle={quoteStore?.quote?.title || ''}
                        selectedProductList={getSelectedProductIds()}
                        onCLose={onHidePopup}
                        onSuccess={onHidePopup}
                        collectionId={quoteStore?.quote?.collectionId}
                    />
                )}

                {isDesignClick && (
                    <DesignRightSidePanel
                        styleId={isDesignClick}
                        openModal={isDesignClick}
                        onCloseModal={() => setIsDesignClick(null)}
                        toggleConversation={() => {
                            setOpenConversationId(isDesignClick)
                        }}
                        styleInfo={selectedDesignInfo}
                    />
                )}
            </>
        )
    )
}

export default QuoteDetails
