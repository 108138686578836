import React, { useState } from 'react'
import { ReactComponent as UploadIcon } from '../../../icons/upload.svg'
import { ReactComponent as FilterIcon } from '../../../icons/filter.svg'
import { ReactComponent as FavouriteIcon } from '../../../icons/favorite.svg'
import UploadModal from './UploadModal'

const ImagesSearch = () => {
    const [showUploadModal, setShowUploadModal] = useState(false)

    return (
        <>
            <div className='py-3'>
                <div className='flex align-items-center'>
                    <div className='breadcrumb'>
                        <p className='regular-14 black'>
                            <span className='blue'>Design Lab </span> /{' '}
                            <span className='regular-12'>Search</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className='py-3'>
                <div>
                    <button
                        className='button primary mr-3'
                        onClick={() => setShowUploadModal(true)}
                    >
                        <span className='mr-2'>Upload Image</span>
                        <UploadIcon />
                    </button>
                    <button className='button secondary'>
                        <span className='mr-2'>Filter</span>
                        <FilterIcon />
                    </button>
                </div>
            </div>
            <div className='search-image-container grid grid-cols-1 sm:grid-cols-2 gap-6'>
                <div>
                    <div className='crop-image-container'>
                        <img
                            src='/images/single-design.png'
                            alt='style'
                            className='w-full h-full object-contain bg-image'
                        />
                    </div>
                    <div className='prev-search'>
                        <h4 className='text-xl font-bold mb-4'>
                            Previous Search
                        </h4>
                        <div className='flex flex-wrap'>
                            <img src='/images/design-list-2.png' alt='image' />
                            <img
                                src='/images/design-list-5.png'
                                alt='image'
                                className='active'
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className='mb-5'>
                        <h3 className='text-4xl font-bold mb-1'>
                            Visual Matches
                        </h3>
                        <p className='text-base gray_300'>
                            Showing similar results to your photo.
                        </p>
                    </div>
                    <div className='grid grid-cols-2 gap-4'>
                        {Array.from({ length: 4 }).map((_, i) => (
                            <div
                                className='single-card-item'
                                onClick={() => setShowZoomModal(true)}
                            >
                                <div className='style-photo'>
                                    <img
                                        src='/images/single-design.png'
                                        alt='design'
                                        className='design-image'
                                    />
                                </div>
                                <div className='style-info'>
                                    <div className='info'>
                                        <p className='regular-18 weight-500'>
                                            Stripe polo shirt
                                        </p>
                                        <ul className='common-list-component mt-1'>
                                            <li className='regular-14'>TEE</li>
                                            <li className='regular-14'>
                                                Woven
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='favorite'>
                                        <button className='button secondary icon-only'>
                                            <FavouriteIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <UploadModal
                showUploadModal={showUploadModal}
                setShowUploadModal={setShowUploadModal}
            />
        </>
    )
}

export default ImagesSearch
