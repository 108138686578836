import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { FavouriteReducers } from './FavouriteReducers'

const initialState = {
    counts: {
        collection: 0,
        moodBoard: 0,
        style: 0
    },
    allCounts: {
        collection: 0,
        moodBoard: 0,
        style: 0
    }

}

const favouriteSlice = createSlice({
    name: 'favourites',
    initialState,
    reducers: FavouriteReducers
})

export const FavouriteActionTypes = favouriteSlice.actions

export const useFavourite = () => useSelector((state) => state.favourite)

export default favouriteSlice.reducer
