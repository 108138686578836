import {
    SET_ALL_PRODUCTS_TO_RANGE,
    TOGGLE_PRODUCT_AS_FAVOURITE_IN_RANGE
} from '../../@types/thunk.types'

const RangeReducer = {
    [SET_ALL_PRODUCTS_TO_RANGE]: (state, { payload }) => {
        state.response = payload.data.productResponseList
        state.rangeTrackerId = payload.data.rangeTrackerId
    },
    [TOGGLE_PRODUCT_AS_FAVOURITE_IN_RANGE]: (state, { payload }) => {
        state.response = state.response?.map((item) => {
            if (item.id === payload) {
                item.liked = !item.liked
            }
            return item
        })
    }
}

export default RangeReducer
