import React, { useRef, useState } from 'react'
import { ReactComponent as IconCollectionDownload } from '../../../assets/icons/old/collectionDownload.svg'
import { ReactComponent as IconCollectionRemove } from '../../../assets/icons/old/collectionRemove.svg'
import { ReactComponent as IconCollectionArrow } from '../../../assets/icons/old/collectionArrow.svg'
import {
    getIconByMimType,
    readFileAsync,
    getShortName
} from '../../helpers/Util'
import ConfirmationModal from './ConfirmationModal'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import Http from '../../helpers/Http'
import { toast } from 'react-toastify'
import Tooltip from '@mui/material/Tooltip'
import { CollectionThunks } from '../../redux_toolkit/Collection/CollectionThunks'
import { GET_COLLECTION_COUNT } from '../../redux_toolkit/@types/thunk.types'

const BriefCollection = () => {
    const [inputData, setInputData] = useState({})
    const [fileList, setFiles] = useState([])
    const confirmationRef = useRef()
    const dispatch = useDispatch()
    const [payload, setPayload] = useState(null)

    const handleChange = (e) => {
        let cloneObj = { ...inputData }
        cloneObj[e.target.name] = e.target.value
        setInputData(cloneObj)
    }

    const handleFiles = async (e) => {
        let { files } = e.target
        let listOfFiles = [...fileList]
        let index = fileList.length
        for (let file of files) {
            let base64String = await readFileAsync(file)
            listOfFiles.push({
                fileIndex: index++,
                base64Str: base64String,
                documentType: 'MOODBOARD_FILE',
                docMimeType: file.type,
                name: file.name
            })
        }
        setFiles(listOfFiles)
    }

    const removeFile = (id) => {
        let tmpFileList = fileList.filter((file) => file.fileIndex !== id)
        setFiles(tmpFileList)
    }

    const renderFileInfo = () => {
        return fileList.map((file, index) => {
            return (
                <tr
                    className='even:bg-white-shade-100'
                    key={`file_row_${index}`}
                >
                    <td className='text-base font-normal px-6 py-6 whitespace-nowrap first:border-r border-primaryColor-shade-200'>
                        <span
                            className='cursor-pointer'
                            onClick={() => removeFile(file.fileIndex)}
                        >
                            <IconCollectionRemove />
                        </span>
                    </td>
                    <td className='text-base font-normal px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-center'>
                            <span>
                                <img
                                    src={getIconByMimType(file)}
                                    alt=''
                                    className='w-14 h-16 object-contain'
                                />
                            </span>
                            <Tooltip title={file.name} placement={'top'} arrow>
                                <span className='text-base ml-4'>
                                    {getShortName(file.name, 35)}
                                </span>
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    const clearState = () => {
        setFiles([])
        setInputData({})
    }

    const handleSubmit = () => {
        dispatch(openLoader())
        let postData = { ...inputData }
        postData['isCollectionRequest'] = true
        postData['documentDTOs'] = fileList
        Http.POST('addMoodboard', postData)
            .then((response) => {
                clearState()
                setPayload(response.data.payload)
                dispatch(CollectionThunks[GET_COLLECTION_COUNT]())
                dispatch(closeLoader())
                confirmationRef.current.click()
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                dispatch(closeLoader())
            })
    }

    return (
        <div>
            <button
                ref={confirmationRef}
                type='button'
                className='btn hidden'
                data-bs-toggle='modal'
                data-bs-target='#ConfirmationAction'
                data-bs-dismiss='modal'
                aria-label='Close'
            >
                confirmation
            </button>
            <div
                className='modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto'
                id='BriefCollection'
                tabIndex='-1'
                aria-labelledby='exampleModalCenterTitle'
                aria-modal='true'
                role='dialog'
                data-bs-backdrop='static'
                data-bs-keyboard='false'
            >
                <div className='modal-dialog max-w-[680px] modal-dialog-centered relative w-auto pointer-events-none'>
                    <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current'>
                        <div className='modal-header flex flex-shrink-0 items-center justify-between bg-primaryColor-shade-300 p-4'>
                            <h5
                                className='text-xl font-bold leading-normal text-primaryColor uppercase'
                                id='exampleModalScrollableLabel'
                            >
                                Brief a Collection
                            </h5>
                            <button
                                type='button'
                                onClick={clearState}
                                className='btn-close box-content w-4 h-4 p-1 !mr-0.5 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            />
                        </div>
                        <div className='modal-body relative p-4'>
                            <div className='space-y-4'>
                                <div className='input-group flex items-center'>
                                    <label
                                        htmlFor='text'
                                        className='label w-[30%]'
                                    >
                                        Title
                                    </label>
                                    <input
                                        type='text'
                                        className={
                                            'form-field bg-primaryColor-shade-300 w-[70%]'
                                        }
                                        id='text'
                                        placeholder='Write Here ...'
                                        name='name'
                                        value={inputData?.name || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='input-group flex items-center'>
                                    <label
                                        htmlFor='text'
                                        className='label w-[30%]'
                                    >
                                        Description
                                    </label>
                                    <textarea
                                        value={inputData?.description || ''}
                                        name='description'
                                        onChange={handleChange}
                                        cols='30'
                                        rows='4'
                                        className='form-field h-auto bg-primaryColor-shade-300 w-[70%]'
                                        placeholder='Write Here ...'
                                    />
                                </div>
                                <div className='input-group flex items-center'>
                                    <label
                                        htmlFor='text'
                                        className='label w-[30%]'
                                    >
                                        Attach
                                    </label>
                                    <div className='file w-[70%]'>
                                        <input
                                            id='input-file'
                                            type='file'
                                            multiple
                                            onClick={(e) =>
                                                (e.target.value = null)
                                            }
                                            onChange={handleFiles}
                                        />
                                        <label
                                            htmlFor='input-file'
                                            className='w-full justify-between'
                                        >
                                            <span className='mr-4'>
                                                Browse Files
                                            </span>
                                            <IconCollectionDownload />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {fileList.length > 0 && (
                                <div className='mt-14'>
                                    <div className='flex justify-between items-center mb-5'>
                                        <h5 className='text-xl font-bold leading-normal text-primaryColor'>
                                            Brief a Collection
                                        </h5>
                                        <span className='text-base'>
                                            {fileList.length} files
                                        </span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
                                            <div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
                                                <div className='overflow-hidden'>
                                                    <table className='min-w-full'>
                                                        <thead className='bg-white'>
                                                            <tr>
                                                                <th
                                                                    scope='col'
                                                                    className='w-[100px] text-base 4xl:text-xl font-normal bg-white-shade-100 px-6 py-4 text-left first:border-r border-primaryColor-shade-200'
                                                                />
                                                                <th
                                                                    scope='col'
                                                                    className='text-base 4xl:text-xl font-normal bg-white-shade-100 px-6 py-4 text-left first:border-r border-primaryColor-shade-200'
                                                                >
                                                                    File
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {renderFileInfo()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='modal-footer p-4'>
                            {(Object.keys(inputData).length > 0 ||
                                fileList.length > 0) && (
                                <button
                                    onClick={handleSubmit}
                                    type='button'
                                    className='btn flex justify-between items-center max-w-[445px] w-full'
                                >
                                    <span>
                                        Ask{' '}
                                        <strong className='font-bold'>
                                            Collection
                                        </strong>
                                    </span>
                                    <span className='ml-2'>
                                        <IconCollectionArrow />
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal collectionId={payload?.collectionId} />
        </div>
    )
}

export default BriefCollection
