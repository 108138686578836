import React, { useEffect, useState } from 'react'
import ModalContainer from '../../common/modal/ModalContainer'
import { ReactComponent as CameraIcon } from '../../../assets/icons/camera.svg'
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg'
import UploadModal from './UploadModal'
import { fetchProductSearchSuggestion } from '../../services/CommonService'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { useNavigate } from 'react-router-dom'
import { setBreadCrumbRoutes } from '../../helpers/Util'

const SearchModal = ({ closeModal, isShowImageSearch }) => {
    const [isShowSuggestion, setIsShowSuggestion] = useState(false)
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [suggestions, setSuggestions] = useState([])
    const [search, setSearch] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        ;(async () => {
            try {
                dispatch(openLoader())
                const data = await fetchProductSearchSuggestion(
                    `${search ? `?text=${encodeURI(search)}` : ''}`
                )
                setSuggestions(data)
                setIsShowSuggestion(true)
                dispatch(closeLoader())
            } catch (e) {
                dispatch(closeLoader())
            }
        })()
    }, [search])

    useEffect(() => {
        if (isShowImageSearch) {
            setIsShowSuggestion(false)
            setShowUploadModal(true)
        }
    }, [])

    const handleSearch = async (value) => {
        try {
            dispatch(openLoader())
            if (value) {
                let newRoute = `/search?type=text&search=${value}`
                let visitingRoutes = [
                    {
                        key: '/design-lab',
                        value: 'Design Lab'
                    },
                    {
                        key: newRoute,
                        value: 'Search'
                    }
                ]

                setBreadCrumbRoutes(visitingRoutes)
                navigate(newRoute)
            }
            closeModal()
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    return (
        <>
            {!isShowImageSearch && (
                <ModalContainer
                    className='search-bar-modal'
                    onCloseModal={closeModal}
                >
                    <div className='common-popup'>
                        <div className='common-popup-body p-4 relative'>
                            <div className='serach-bar'>
                                <input
                                    type='text'
                                    placeholder='Search from design lab'
                                    className='no-border'
                                    onClick={() => {
                                        setShowUploadModal(false)
                                    }}
                                    autoFocus={true}
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch(e.target?.value)
                                        }
                                    }}
                                />
                                <span
                                    className='upload cursor-pointer'
                                    onClick={() => {
                                        setIsShowSuggestion(false)
                                        setShowUploadModal(true)
                                    }}
                                >
                                    <CameraIcon />
                                </span>
                                {isShowSuggestion && suggestions.length > 0 && (
                                    <div className='suggestion-list'>
                                        <ul>
                                            {suggestions?.map((item, i) => {
                                                return (
                                                    <li
                                                        className='flex items-center cursor-pointer'
                                                        key={`suggestion_${i}`}
                                                        onClick={() =>
                                                            handleSearch(item)
                                                        }
                                                    >
                                                        <ClockIcon />
                                                        <span className='text-sm gray_300 ml-1'>
                                                            {item}
                                                        </span>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <button
                                className='button link absolute right-8 top-6'
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}
            {showUploadModal && (
                <UploadModal
                    setShowUploadModal={(value) => {
                        closeModal()
                        setShowUploadModal(value)
                    }}
                    isShowImageSearch={isShowImageSearch}
                />
            )}
        </>
    )
}

export default SearchModal
