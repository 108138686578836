import React from 'react'
import ModalContainer from '../../common/modal/ModalContainer'
import CollectionPresentation from '../presentation/CollectionPresentation'

const PresentationViewModal = ({
    collectionName,
    onClose,
    isRequestFromRange = false,
    productIds = [],
    pageSource
}) => {
    return (
        <ModalContainer
            className='presentation-view-modal'
            onCloseModal={onClose}
        >
            <CollectionPresentation
                collectionName={collectionName}
                onClose={onClose}
                isRequestFromRange={isRequestFromRange}
                productIds={productIds}
                pageSource={pageSource}
            />
        </ModalContainer>
    )
}

export default PresentationViewModal
