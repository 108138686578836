import { SET_TEAM_MEMBER_LIST } from '../@types/action.types'

export const TeamReducers = {
    [SET_TEAM_MEMBER_LIST]: (state, { payload }) => {
        if (payload.bool) {
            state.ownMembers = payload.data
        }
        state.data = payload.data
    }
}
