import React, { useEffect, useRef } from 'react'
import 'quill-mention'
import ReactQuill from 'react-quill'
import { mentionModule } from '../helpers/Util'

const Editor = ({
    setReadOnly,
    callBack,
    mention,
    memberList,
    readOnly,
    value
}) => {
    const reactQuillRef = useRef()

    useEffect(() => {
        if (!readOnly) {
            reactQuillRef.current.focus()
        }
    }, [readOnly])

    return (
        <div className='w-full relative' onClick={() => setReadOnly(false)}>
            <ReactQuill
                ref={reactQuillRef}
                readOnly={readOnly}
                value={value}
                selection={{ start: 0, end: 0 }}
                name='post'
                debug='info'
                theme='bubble'
                onChange={(value) => callBack(value)}
                placeholder='Write comment or use @ to mention'
                modules={{
                    mention: !mention
                        ? null
                        : mentionModule(memberList, readOnly)
                }}
                preserveWhitespace={true}
                className='custom-scroll overflow-y-auto h-[70px] w-[90%]'
            />
        </div>
    )
}

export default Editor
