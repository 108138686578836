import {
    SET_ALL_EXPLORE_DESIGNS,
    SET_DESIGN_FOR_FAVOURITE
} from '../../@types/thunk.types'

export const ExploreDesignReducer = {
    [SET_ALL_EXPLORE_DESIGNS]: (state, { payload }) => {
        let styles = []
        if (payload.merge) {
            styles = [...state.response.data, ...payload.data.data]
        } else {
            styles = [...payload.data.data]
        }
        payload.data.data = styles
        state.response = payload.data
    },
    [SET_DESIGN_FOR_FAVOURITE]: (state, { payload }) => {
        state.response.data = state.response?.data?.map((item) => {
            if (item.id === payload) {
                item.liked = !item.liked
            }
            return item
        })
    }
}
