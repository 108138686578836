import React, { useState } from 'react'
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-blue.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import ModalContainer from '../../common/modal/ModalContainer'
import { useNavigate } from 'react-router-dom'
import { readFileAsDataURL, setBreadCrumbRoutes } from '../../helpers/Util'
import { useDispatch } from 'react-redux'
import { SearchActions } from '../../redux_toolkit/V3/Search'
import { ADD_NEW_DESIGN } from '../../redux_toolkit/@types/thunk.types'
import { uploadDocument } from '../../services/CommonService'
import { toast } from 'react-toastify'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'

const UploadModal = ({ setShowUploadModal, isShowImageSearch }) => {
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleRoute = () => {
        let newRoute = '/search?type=image'
        let visitingRoutes = [
            {
                key: '/design-lab',
                value: 'Design Lab'
            },
            {
                key: newRoute,
                value: 'Search'
            }
        ]

        setBreadCrumbRoutes(visitingRoutes)
        navigate(newRoute)
    }

    const onDrop = (e) => {
        e.preventDefault()
        onUploadFile(e.dataTransfer.files)
    }

    const onDragOver = (e) => {
        e.preventDefault()
    }

    const onDragLeave = () => {}

    const handleFileValidate = (files) => {
        const allowedFormats = ['jpg', 'jpeg', 'png', 'webp', 'hiac']
        let isValid = true
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            const fileExtension = file.name.split('.').pop().toLowerCase()

            if (!allowedFormats.includes(fileExtension)) {
                setErrorMessage(`Invalid file format for ${file.name}.`)
                toast.warning('Invalid image format!')
                isValid = false
                break
            }
        }
        return isValid
    }

    const onUploadFile = async (files) => {
        try {
            if (handleFileValidate(files)) {
                const postData = await Promise.all(
                    Array.from(files).map(async (file) => {
                        const data = {
                            name: file.name,
                            docMimeType:
                                file.type === ''
                                    ? 'application/octet-stream'
                                    : file.type,
                            documentType: 'PRODUCT_IMAGE_SEARCH_DATA',
                            base64Str: ''
                        }
                        data.base64Str = await readFileAsDataURL(file)
                        return data
                    })
                )

                dispatch(openLoader())
                const response = await uploadDocument(postData)
                await dispatch(
                    SearchActions[ADD_NEW_DESIGN]({
                        data: response
                    })
                )
                dispatch(closeLoader())
                setShowUploadModal(false)
                await handleRoute()
            }
        } catch (error) {
            toast.error('Error reading or processing files!')
        }
    }

    return (
        <>
            <ModalContainer
                onCloseModal={setShowUploadModal}
                className='upload-modal'
            >
                <div className='common-popup'>
                    <div className='common-popup-header'>
                        <div className='popup-tilte text-center'>
                            <h3 className='text-base sm:text-lg weight-500 mb-0 pt-5 sm:pt-0'>
                                Search images with{' '}
                                <span className='weight-700 text-base sm:text-xl'>
                                    Nitex Lens
                                </span>
                            </h3>
                            {isShowImageSearch && (
                                <button
                                    className='button icon-only absolute right-6 top-2 sm:right-4 sm:top-2'
                                    onClick={() => setShowUploadModal(false)}
                                >
                                   <CloseIcon />
                                </button>
                            )}
                        </div>
                    </div>

                    <div
                        className='common-popup-body !pt-0'
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                    >
                        <label
                            className='upload-container text-center'
                            htmlFor='uploadImage'
                        >
                            <div className='text-center'>
                                <UploadIcon />
                                <p className='text-sm sm:text-base mt-2 gray_300 mb-3'>
                                    Drag & drop images to upload here <br />{' '}
                                    (JPG, PNG, JPEG, WEBP, HIAC)
                                </p>
                                <p className='text-sm sm:text-base blue weight-500'>
                                    Upload Images
                                </p>
                            </div>
                        </label>
                        <input
                            type='file'
                            className='hidden'
                            id='uploadImage'
                            multiple={true}
                            onChange={(e) => onUploadFile(e.target.files)}
                            accept='.jpg, .jpeg, .png, .webp, .hiac'
                        />
                    </div>
                </div>
            </ModalContainer>
        </>
    )
}

export default UploadModal
