import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FilterActionTypes, useFilter } from '../../redux_toolkit/V3/Filter'
import { useLoader } from '../../redux_toolkit/Loader'
import {
    generateSharedCollectionPageParams,
    sharedCollectionFields
} from '../filter/filterUtil'
import {
    authUserInfo,
    isPageReachBottom,
    sharedCollectionCardRenderSequence
} from '../../helpers/Util'
import { useSharedCollections } from '../../redux_toolkit/V3/SharedCollection'
import SharedCollectionThunk from '../../redux_toolkit/V3/SharedCollection/SharedCollectionThunk'
import { GET_ALL_SHARED_COLLECTION } from '../../redux_toolkit/@types/thunk.types'
import SingleItemCollection from './components/SingleItemCollection'
import TwoItemCollection from './components/TwoItemCollection'
import ThreeItemCollection from './components/ThreeItemCollection'
import FourItemCollection from './components/FourItemCollection'
import BreadCrumb from '../common/BreadCrumb'
import FilterComponent from '../filter/FilterComponent'
import { fetchFilterListByBuyer } from '../../services/CommonService'
import { FilterOptionTypes } from '../../redux_toolkit/V3/Filter/FilterOptionReducers'
import {
    CLEAR_FILTER_FIELDS,
    SET_CATEGORY_LIST,
    SET_SEASON_LIST
} from '../../redux_toolkit/@types/action.types'
import useDocumentTitle from '../../hooks/userDocumentTitle'

const SharedCollection = () => {
    const [collections, setCollections] = useState([])
    const sharedCollections = useSharedCollections()
    const isLoading = useRef(null)
    const globalLoader = useLoader()
    const filterStore = useFilter()
    const dispatch = useDispatch()
    const scrollRef = useRef()

    useDocumentTitle('Nitex - Collections')

    const fetchFilterFields = async () => {
        const data = await fetchFilterListByBuyer('?isExploreDesignPage=false')
        for (let item in sharedCollectionFields) {
            if (item === 'CATEGORY')
                dispatch({
                    type: FilterOptionTypes[SET_CATEGORY_LIST],
                    payload: data.categoryList
                })
            if (item === 'SEASON')
                dispatch({
                    type: FilterOptionTypes[SET_SEASON_LIST],
                    payload: data?.seasonList?.map((item) => ({
                        id: item.constant,
                        name: item.name
                    }))
                })
        }
    }

    useEffect(() => {
        fetchFilterFields()
        return () => {
            dispatch(FilterActionTypes[CLEAR_FILTER_FIELDS]())
        }
    }, [])

    const setIsDataLoading = (data) => {
        isLoading.current = data
    }

    useEffect(() => {
        setIsDataLoading(globalLoader)
    }, [globalLoader])

    const generateParams = (pageNumber) => {
        return `${
            authUserInfo().id
        }?page=${pageNumber}&memberType=SHARED&size=20&sort=members.sharedAt,desc${generateSharedCollectionPageParams(
            filterStore
        )}`
    }

    const fetchSharedCollections = (pageNumber) => {
        dispatch(
            SharedCollectionThunk[GET_ALL_SHARED_COLLECTION](
                generateParams(pageNumber)
            )
        )
    }

    useEffect(() => {
        fetchSharedCollections(0)
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [filterStore])

    const handleScroll = () => {
        if (isPageReachBottom()) {
            let { totalElements, currentPage, data } = scrollRef.current
            if (
                totalElements > 0 &&
                totalElements !== data.length &&
                !isLoading.current
            ) {
                fetchSharedCollections(currentPage + 1)
            }
        }
    }

    useEffect(() => {
        setCollections(sharedCollections?.data)
        scrollRef.current = sharedCollections
    }, [sharedCollections])

    const getCollectionCard = (item, collectionList) => {
        if (item === 1) {
            return <SingleItemCollection collections={collectionList} />
        } else if (item === 2) {
            return <TwoItemCollection collections={collectionList} />
        } else if (item === 3) {
            return <ThreeItemCollection collections={collectionList} />
        } else if (item === 4) {
            return <FourItemCollection collections={collectionList} />
        }
    }

    const renderCollections = () => {
        if (collections?.length > 0) {
            let sequenceArray = sharedCollectionCardRenderSequence(
                collections.length
            )
            let startingPosition = 0
            let endingPosition = 0
            return sequenceArray?.map((item, i) => {
                endingPosition = startingPosition + item
                let collectionList = collections.slice(
                    startingPosition,
                    endingPosition
                )
                startingPosition = endingPosition
                return (
                    <React.Fragment key={`collection_card_${i}`}>
                        {getCollectionCard(item, collectionList)}
                    </React.Fragment>
                )
            })
        }
    }

    return (
        <>
            <div className='flex justify-between py-3'>
                <BreadCrumb isShowBackButton={false} />
            </div>
            <FilterComponent
                requiredFields={sharedCollectionFields}
                filteredDataLength={collections?.length}
            />
            <div className='shared-collections py-5 px-4'>
                {renderCollections()}
                {collections?.length === 0 && (
                    <div className='epmpty-state py-40 text-center m-auto'>
                        <h3 className='text-xl weight-700 gray_400'>
                            No collection share yet
                        </h3>
                    </div>
                )}
            </div>
        </>
    )
}

export default SharedCollection
