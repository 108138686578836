import React from 'react'

const GenerateTags = ({ item, isViewed, cardType = 'COLLECTION' }) => {

    const renderTags = () => {
        return item?.tags?.map((item) => {
            return (
                <span
                    className={
                        item?.text === 'New'
                            ? 'badge bg-warning font-bold'
                            : 'badge bg-success font-bold'
                    }
                    key={`tag_${item.id}`}
                >
                    {item.text}
                </span>
            )
        })
    }

    const renderRequestedCollectionTags = () => {
        if (cardType === 'REQUESTED_COLLECTION') {
            return (
                <>
                    {item.moodboardStatus === 'REQUESTED' && (
                        <span
                            className='badge bg-pending border border-primaryColor'
                            key={'TAG_REQUESTED'}
                        >
                            REQUESTED FOR COLLECTION
                        </span>
                    )}
                    {item.moodboardStatus === 'COMPLETED' && (
                        <span
                            className='badge bg-warning font-bold py-1'
                            key={'TAG_COMPLETED'}
                        >
                            COLLECTION RECEIVED
                        </span>
                    )}
                </>
            )
        }
    }

    return (
        <>
            {!isViewed && cardType !== 'REQUESTED_COLLECTION' && (
                <span className={'badge bg-warning font-bold'}>New</span>
            )}
            {renderTags()}
            {renderRequestedCollectionTags()}
        </>
    )
}

export default GenerateTags
