import React from 'react'

const Breadcrumb = () => {
    return (
        <div className='breadcrumb py-3'>
            <p className='regular-14 black'>
                <span className='blue'>Design Lab</span> /{' '}
                <span className='regular-12'>Search</span>
            </p>
        </div>
    )
}

export default Breadcrumb
