/* eslint-disable quotes */
import React, { useEffect, useRef, useState } from 'react'
import {
    addImageSuffix,
    authUserInfo,
    getMentionedUserIds,
    onErrorImageLoad
} from '../../../helpers/Util'
import Http from '../../../helpers/Http'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addNewCommentOnTimeline } from '../../../redux_toolkit/Timeline/TimelineThunks'
import LoaderComponent from '../../../common/LoaderComponent'
import { toast } from 'react-toastify'
import { SelectedFileViewComponent } from '../../../common/SelectedFileViewComponent'
import SelectComponent from '../../../common/SelectComponent'
import 'quill-mention'
import { ReactComponent as CloseSmallWhiteIcon } from '../../../../assets/icons/close.svg'
import Editor from '../../../common/Editor'
import ModalContainer from '../../../common/modal/ModalContainer'

const AddComment = ({ toggleAddComment, openModal }) => {
    const [selectedTask, setSelectedTask] = useState(null)
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState({})
    const [selectedFiles, setSelectedFiles] = useState([])
    const [memberList, setMemberList] = useState([])
    const params = useParams()
    const timelineStore = useSelector((store) => store.timeline)
    const [taskList, setTaskList] = useState([])
    const dispatch = useDispatch()
    const postInputRef = useRef(null)
    const [mentionSearch, setMentionSearch] = useState('')
    const [isBuyerMentioned, setIsBuyerMentioned] = useState(true)
    const [isNitexMentioned, setIsNitexMentioned] = useState(true)
    const [mentionToUsers, setMentionToUsers] = useState([])
    const [selectedMentionedUser, setSelectedMentionedUser] = useState([])
    const [readOnly, setReadOnly] = useState(true)
    const dropdownRef = useRef(null)
    const searchRef = useRef()

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [])

    useEffect(() => {
        if (timelineStore?.selectedDesignList) {
            setLoader(true)
            Http.GET(
                'getDesignWiseTaskList',
                `${params.orderId}/product/${timelineStore?.selectedDesignList[0]}`
            )
                .then((response) => {
                    let tmpList = []
                    for (let item of response.data) {
                        tmpList.push({ label: item.name, value: item.id })
                    }

                    setTaskList(tmpList)
                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }
    }, [params.orderId, timelineStore])

    useEffect(() => {
        if (timelineStore?.orderInfo?.orderMemberList) {
            let members = []
            let tmpList = timelineStore?.orderInfo?.orderMemberList
            tmpList.map((member) =>
                members.push({
                    id: member.email,
                    value: member.memberName,
                    email: member.email,
                    memberId: member.memberId
                })
            )
            setMemberList(members)
        }
    }, [timelineStore])

    const handleTask = (task) => {
        let errorObj = { ...error }
        errorObj['taskError'] = undefined
        setError(errorObj)
        setSelectedTask(task)
    }

    const checkValidation = () => {
        let errors = {}
        if (!selectedTask) {
            errors['taskError'] = 'Required!'
        }
        let newComment =
            postInputRef.current === null
                ? ''
                : postInputRef?.current.toString().replace(/(<([^>]+)>)/gi, '')
        if (!newComment) {
            errors['commentError'] = 'Comment required'
        }
        if (
            !isBuyerMentioned &&
            !isNitexMentioned &&
            !selectedMentionedUser.length > 0
        ) {
            errors['receptionError'] = 'Please specify at least one recipient.'
        }
        setError(errors)
        return Object.keys(errors).length > 0
    }

    const handlePost = async () => {
        if (!checkValidation()) {
            setLoader(true)
            let message = postInputRef.current
            let body = {
                documentDTOList: selectedFiles,
                orderId: parseInt(params.orderId),
                deliverableId: selectedTask.value,
                text: message.replace(/"/g, "'"),
                recipientList: [],
                taggedUserIdList: timelineStore?.orderInfo?.orderMemberList
                    ? getMentionedUserIds(
                          message,
                          timelineStore?.orderInfo?.orderMemberList
                      )
                    : []
            }
            if (isNitexMentioned) {
                body.recipientList.push({ recipientType: 'NITEX' })
            }
            if (isBuyerMentioned) {
                body.recipientList.push({ recipientType: 'BUYER' })
            }
            selectedMentionedUser?.map((user) => {
                const userIdMap = {
                    recipientType: 'INDIVIDUAL',
                    userId: user.memberId
                }
                body.recipientList.push(userIdMap)
            })
            await Http.POST('postOnTask', body, '?fromTimeline=true')
                .then((response) => {
                    dispatch(addNewCommentOnTimeline(response.data.payload))
                    setLoader(false)
                    toggleAddComment()
                    toast.success('Comment Add Successful!')
                })
                .catch(({ response }) => {
                    setLoader(false)
                    toast.error(response.data.message)
                })
        }
    }

    const onMultipleFileSelect = (e, docType) => {
        let files = Array.from(e.target.files)
        let fileList = [...selectedFiles]
        files.map((item) => {
            let data = {
                name: item.name,
                docMimeType: item.type,
                documentType: docType,
                print: false
            }
            let reader = new FileReader()
            reader.readAsDataURL(item)
            reader.onload = async () => {
                data.base64Str = reader.result
                fileList.push(data)
            }
        })
        setTimeout(() => {
            setSelectedFiles(fileList)
        }, 500)
    }

    const removeFile = (index) => {
        let fileList = selectedFiles.filter((file, i) => i !== index)
        setSelectedFiles(fileList)
    }

    const renderFilePreview = () => {
        return (
            <div
                className={`files-n-photos custom-scroll ${
                    selectedFiles.length ? 'open' : ''
                }`}
                key={selectedFiles.length}
            >
                {selectedFiles.map((file, i) => {
                    return (
                        <SelectedFileViewComponent
                            showRemoveOption={true}
                            file={file}
                            key={i}
                            index={i}
                            remove={removeFile}
                        />
                    )
                })}
            </div>
        )
    }

    const onDismissSelection = (userId) => {
        const userDismiss = [...selectedMentionedUser]
        const index = selectedMentionedUser.findIndex(
            (el) => el.memberId === userId
        )
        userDismiss.splice(index, 1)
        setSelectedMentionedUser(userDismiss)
    }

    const selectedUserToMention = (value) => {
        const selectedUser = mentionToUsers?.filter((el) => el.email === value)
        if (selectedUser?.length > 0) {
            let tmpArray = [...selectedMentionedUser]
            tmpArray.push(selectedUser[0])
            setSelectedMentionedUser(tmpArray)
            setMentionSearch('')
            setMentionToUsers([])
        }
    }

    const handleMentionTo = async (e) => {
        setMentionSearch(e.target.value)
        if (e.target.value) {
            const data = memberList.filter(
                (member) =>
                    !selectedMentionedUser.some(
                        (item) => item.email === member.email
                    ) &&
                    member.email
                        .toUpperCase()
                        .includes(e.target.value.toUpperCase())
            )
            setMentionToUsers(data)
        } else {
            setMentionToUsers([])
        }
    }

    useEffect(() => {
        document.body.addEventListener('keydown', handleKeyDown)
        return () => {
            document.body.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    const handleKeyDown = (event) => {
        if(document.activeElement.getAttribute('class') === 'ql-editor') return
        const isUp = event.key === 'ArrowUp'
        const isDown = event.key === 'ArrowDown'
        const inputIsFocused = document.activeElement === searchRef.current
        const resultsItems = Array.from(
            (dropdownRef.current && dropdownRef.current.children) || []
        )
        const activeResultIndex = resultsItems.findIndex((child) => {
            return child.querySelector('span') === document.activeElement
        })

        if (isUp) {
            if (inputIsFocused) {
                resultsItems[resultsItems.length - 1]
                    .querySelector('span')
                    .focus()
            } else if (resultsItems[activeResultIndex - 1]) {
                resultsItems[activeResultIndex - 1]
                    .querySelector('span')
                    .focus()
            } else {
                searchRef.current && searchRef.current.focus()
            }
        }

        if (isDown) {
            if (inputIsFocused) {
                resultsItems[0].querySelector('span').focus()
            } else if (resultsItems[activeResultIndex + 1]) {
                resultsItems[activeResultIndex + 1]
                    .querySelector('span')
                    .focus()
            } else {
                searchRef.current && searchRef.current.focus()
            }
        }
    }

    // const handleKeyDown = (event) => {
    //     if (
    //        ( document.activeElement.getAttribute('class') === 'outline-none mention-search-box') ||
    //         (document.activeElement.getAttribute('class') === 'single-item-focus')
    //     ) {
    //         const isUp = event.key === 'ArrowUp'
    //         const isDown = event.key === 'ArrowDown'
    //         const inputIsFocused = document.activeElement === searchRef.current
    //         const resultsItems = Array.from(
    //             (dropdownRef.current && dropdownRef.current.children) || []
    //         )
    //         const activeResultIndex = resultsItems.findIndex((child) => {
    //             return child.querySelector('span') === document.activeElement
    //         })
    //
    //
    //         console.log(inputIsFocused, resultsItems,activeResultIndex);
    //
    //         //
    //         // if (isUp) {
    //         //     if (inputIsFocused) {
    //         //         resultsItems[resultsItems.length - 1]
    //         //             .querySelector('span')
    //         //             .focus()
    //         //     } else if (resultsItems[activeResultIndex - 1]) {
    //         //         resultsItems[activeResultIndex - 1]
    //         //             .querySelector('span')
    //         //             .focus()
    //         //     } else {
    //         //         searchRef.current && searchRef.current.focus()
    //         //     }
    //         // }
    //         //
    //         if (isDown) {
    //             if (inputIsFocused) {
    //                 resultsItems[0].querySelector('span').focus()
    //             } else if (resultsItems[activeResultIndex + 1]) {
    //                 resultsItems[activeResultIndex + 1]
    //                     .querySelector('span')
    //                     .focus()
    //             } else {
    //                 searchRef.current && searchRef.current.focus()
    //             }
    //         }
    //     }
    // }

    const selectNitex = () => {
        setIsNitexMentioned(true)
        setMentionToUsers([])
        setMentionSearch('')
    }

    const selectBuyer = () => {
        setIsBuyerMentioned(true)
        setMentionToUsers([])
        setMentionSearch('')
    }
    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            toggleAddComment()
        }
    }
    return (
        <div
            className={`modal custom-modal-backdrop fade ${
                openModal ? 'show' : ''
            } fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto`}
            id='exampleModal'
            tabIndex='-1'
            role='dialog'
            aria-labelledby='exampleModalLabel'
            style={{ display: 'block' }}
            aria-modal='true'
            data-bs-focus='false'
            onClick={handleBackdropClick}
        >
            <div className='modal-dialog !max-w-[720px] modal-dialog-centered relative w-auto pointer-events-none'>
                <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current'>
                    <div className='modal-body relative'>
                        <LoaderComponent loading={loader}>
                            <div className='common-popup'>
                                <div className='common-popup-header flex justify-between'>
                                    <h3 className='regular-20 weight-700'>
                                        Write comment
                                    </h3>
                                    <div
                                        className='close-icon cursor-pointer'
                                        onClick={toggleAddComment}
                                    >
                                        <img
                                            src='/icons/close.svg'
                                            alt='close'
                                        />
                                    </div>
                                </div>
                                <div className='common-popup-body !pt-0'>
                                    <div className='top-write-comments'>
                                        <div className='comments-header'>
                                            <div className='header-contents w-full flex flex-wrap sm:flex-nowrap items-center justify-between'>
                                                <div className='profile-image flex items-center'>
                                                    <img
                                                        src={
                                                            authUserInfo()
                                                                ?.profilePicDocument
                                                                ?.docUrl ||
                                                            '/images/pro_pic_default.png'
                                                        }
                                                        onError={(e) =>
                                                            onErrorImageLoad(
                                                                e,
                                                                authUserInfo()
                                                                    ?.profilePicDocument
                                                                    ?.docUrl
                                                            )
                                                        }
                                                        alt='profile'
                                                    />
                                                    <p className='regular-18 weight-500 ml-2'>
                                                        {authUserInfo()?.name}
                                                    </p>
                                                </div>

                                                <div className='task-select flex items-center'>
                                                    <div className='design-select'>
                                                        <p className='comments-hint gray_400 mr-2 whitespace-nowrap'>
                                                            Comments on:
                                                        </p>
                                                    </div>
                                                    <SelectComponent
                                                        options={taskList}
                                                        onChange={handleTask}
                                                        isSearchable={true}
                                                    />
                                                    {error.taskError && (
                                                        <p className='error'>
                                                            {error.taskError}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            onClick={() => setReadOnly(true)}
                                            className='mentions py-3 flex flex-wrap items-center gap-2 border-t border-b border-primaryColor-shade-200 overflow-x-auto custom-scroll scroll-y'
                                        >
                                            <span>To</span>
                                            <div className='flex flex-wrap gap-2'>
                                                {isNitexMentioned && (
                                                    <div className='flex items-center gap-2'>
                                                        <span className='status gray-3'>
                                                            <span>Nitex</span>
                                                            <span
                                                                className='ml-1 cursor-pointer'
                                                                onClick={() =>
                                                                    setIsNitexMentioned(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                <CloseSmallWhiteIcon />
                                                            </span>
                                                        </span>
                                                    </div>
                                                )}
                                                {isBuyerMentioned && (
                                                    <div className='flex items-center gap-2'>
                                                        <span className='status blue-3'>
                                                            <span>
                                                                {
                                                                    authUserInfo()
                                                                        ?.brandResponse
                                                                        ?.name
                                                                }
                                                            </span>
                                                            <span
                                                                className='ml-1 cursor-pointer'
                                                                onClick={() =>
                                                                    setIsBuyerMentioned(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                <CloseSmallWhiteIcon />
                                                            </span>
                                                        </span>
                                                    </div>
                                                )}
                                                {selectedMentionedUser &&
                                                    selectedMentionedUser.map(
                                                        (user, i) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        user.id
                                                                    }
                                                                    className='flex items-center gap-2'
                                                                >
                                                                    <span className='status blue-3'>
                                                                        <span>
                                                                            {
                                                                                user.email
                                                                            }
                                                                        </span>
                                                                        <span
                                                                            className='ml-1 cursor-pointer'
                                                                            onClick={() =>
                                                                                onDismissSelection(
                                                                                    user.memberId
                                                                                )
                                                                            }
                                                                        >
                                                                            <CloseSmallWhiteIcon />
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                            </div>
                                            <div>
                                                <div className='metion-person-suggetion absolute bottom-full bg-white top-[100px] z-10'>
                                                    <ul
                                                        className='max-h-[300px] bg-white custom-scroll shadow-lg  overflow-y-auto'
                                                        ref={dropdownRef}
                                                    >
                                                        {!isNitexMentioned &&
                                                            mentionSearch !==
                                                                '' && (
                                                                <li
                                                                    key={
                                                                        'nitex'
                                                                    }
                                                                    className='cursor-pointer px-3 py-1'
                                                                    onClick={
                                                                        selectNitex
                                                                    }
                                                                    onKeyPress={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e.key ===
                                                                            'Enter'
                                                                        ) {
                                                                            selectNitex()
                                                                        }
                                                                    }}
                                                                >
                                                                    <span
                                                                        className='text-sm single-item-focus'
                                                                        tabIndex={
                                                                            0
                                                                        }
                                                                    >
                                                                        Nitex
                                                                    </span>
                                                                </li>
                                                            )}
                                                        {!isBuyerMentioned &&
                                                            mentionSearch !==
                                                                '' && (
                                                                <li
                                                                    key={
                                                                        'buyer'
                                                                    }
                                                                    className='cursor-pointer px-3 py-1'
                                                                    onClick={
                                                                        selectBuyer
                                                                    }
                                                                    onKeyPress={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e.key ===
                                                                            'Enter'
                                                                        ) {
                                                                            selectBuyer()
                                                                        }
                                                                    }}
                                                                >
                                                                    <span
                                                                        className='text-sm single-item-focus'
                                                                        tabIndex={
                                                                            0
                                                                        }
                                                                    >
                                                                        {
                                                                            authUserInfo()
                                                                                ?.brandResponse
                                                                                ?.name
                                                                        }
                                                                    </span>
                                                                </li>
                                                            )}
                                                        {mentionToUsers?.map(
                                                            (user) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            user.id
                                                                        }
                                                                        className='cursor-pointer px-3 py-1'
                                                                        onClick={() =>
                                                                            selectedUserToMention(
                                                                                user.email
                                                                            )
                                                                        }
                                                                        onKeyPress={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                e.key ===
                                                                                'Enter'
                                                                            ) {
                                                                                selectedUserToMention(
                                                                                    user.email
                                                                                )
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span
                                                                            className='text-sm single-item-focus'
                                                                            tabIndex={
                                                                                0
                                                                            }
                                                                        >
                                                                            {
                                                                                user.email
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                )
                                                            }
                                                        )}
                                                    </ul>
                                                </div>
                                                <input
                                                    type='text'
                                                    className='outline-none mention-search-box'
                                                    placeholder='Search with email...'
                                                    value={mentionSearch}
                                                    onFocus={() =>
                                                        setReadOnly(true)
                                                    }
                                                    onChange={handleMentionTo}
                                                    onSelect={
                                                        selectedUserToMention
                                                    }
                                                    ref={searchRef}
                                                />
                                            </div>
                                            {error.receptionError && (
                                                <p className='error mt-8'>
                                                    {error.receptionError}
                                                </p>
                                            )}
                                        </div>
                                        <div className='comments-body'>
                                            <div className='comment-text clicked'>
                                                <Editor
                                                    readOnly={readOnly}
                                                    setReadOnly={setReadOnly}
                                                    callBack={(value) => {
                                                        postInputRef.current =
                                                            value
                                                    }}
                                                    memberList={memberList}
                                                    mention={true}
                                                    value={
                                                        postInputRef.current ||
                                                        ''
                                                    }
                                                />
                                                {error.commentError && (
                                                    <p className='error'>
                                                        {error.commentError}
                                                    </p>
                                                )}
                                                {renderFilePreview()}
                                            </div>
                                            <div className='post-actions flex !pl-0 items-center h-[60px]'>
                                                <button
                                                    className='button primary'
                                                    onClick={handlePost}
                                                >
                                                    Post
                                                </button>
                                                <button className='button secondary icon-only attachment m-3'>
                                                    <label htmlFor='upload-input-file'>
                                                        <img
                                                            src='/icons/attachment.svg'
                                                            alt='attach'
                                                        />
                                                    </label>
                                                    <input
                                                        id='upload-input-file'
                                                        type='file'
                                                        name='selectedFiles'
                                                        onChange={(e) =>
                                                            onMultipleFileSelect(
                                                                e,
                                                                'ACCESSORIES_DESIGN'
                                                            )
                                                        }
                                                        multiple
                                                        onClick={(e) =>
                                                            (e.target.value =
                                                                null)
                                                        }
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </LoaderComponent>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddComment
