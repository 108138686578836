import {
    SET_ALL_SELECT_PRODUCT,
    SET_PRODUCTS_BY_COLLECTION_ID,
    SET_SELECT_PRODUCT, TOGGLE_FAVOURITE_PRODUCT_BY_ID
} from '../@types/action.types';

export const CollectionViewReducer = {
    [SET_PRODUCTS_BY_COLLECTION_ID]: (state, { payload }) => {
        if (payload.merge) {
            payload.data = [...state.productResponse.data, ...payload.data]
        }
        state.productResponse = payload
    },
    [SET_SELECT_PRODUCT]: (state, { payload }) => {
        if (payload.checked) {
            state.selectedProducts.push(payload.value)
        } else {
            state.selectedProducts = state.selectedProducts.filter(
                (item) => item !== payload.value
            )
        }
    },
    [SET_ALL_SELECT_PRODUCT]: (state, { payload }) => {
        if (payload.checked) {
            state.selectedProducts = payload.value
        } else {
            state.selectedProducts = []
        }
    },
    [TOGGLE_FAVOURITE_PRODUCT_BY_ID]: (state, { payload }) => {
        state?.productResponse?.data?.forEach(item => {
            if(item.id === payload){
                item.liked = !item.liked
            }
        })
    }
}
