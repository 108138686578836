import {
    GET_ALL_FABRIC_TYPES,
    GET_CATEGORY_LIST, GET_DESIGN_LOOK,
    GET_MARKET_LIST,
    GET_PRODUCT_AGE_GROUP_LIST,
    GET_SEASON_LIST,
    GET_SUB_CATEGORY_LIST
} from '../../@types/thunk.types';
import Http from '../../../helpers/Http'
import { FilterOptionTypes } from './FilterOptionReducers'
import {
    SET_AGE_GROUP_LIST, SET_ALL_DESIGN_LOOK,
    SET_ALL_FABRIC_TYPES,
    SET_CATEGORY_LIST,
    SET_MARKET_LIST,
    SET_SEASON_LIST,
    SET_SUB_CATEGORY_LIST
} from '../../@types/action.types';
import { toast } from 'react-toastify'
import { fetchEnumValues } from '../../../services/CommonService';

export const FilterOptionThunks = {
    [GET_SEASON_LIST]: () => async (dispatch) => {
        await Http.GET('fetchSeasons')
            .then(async (response) => {
                await dispatch({
                    type: FilterOptionTypes[SET_SEASON_LIST],
                    payload: response.data?.map((item) => ({
                        id: item.constant,
                        name: item.name
                    }))
                })
            })
            .catch(() => {
                toast.error('Failed to load seasons!')
            })
    },
    [GET_MARKET_LIST]: () => async (dispatch) => {
        await Http.GET('fetchMarkets')
            .then(async (response) => {
                await dispatch({
                    type: FilterOptionTypes[SET_MARKET_LIST],
                    payload: response.data
                })
            })
            .catch(() => {
                toast.error('Failed to load markets!')
            })
    },
    [GET_CATEGORY_LIST]: () => async (dispatch) => {
        await Http.GET('fetchAllCategories')
            .then(async (response) => {
                await dispatch({
                    type: FilterOptionTypes[SET_CATEGORY_LIST],
                    payload: response.data
                })
            })
            .catch(() => {
                toast.error('Failed to load categories!')
            })
    },
    [GET_SUB_CATEGORY_LIST]: () => async (dispatch) => {
        await Http.GET('fetchCategories')
            .then(async (response) => {
                await dispatch({
                    type: FilterOptionTypes[SET_SUB_CATEGORY_LIST],
                    payload: response.data
                })
            })
            .catch(() => {
                toast.error('Failed to load categories!')
            })
    },
    [GET_PRODUCT_AGE_GROUP_LIST]: () => async (dispatch) => {
        await Http.GET('fetchAgeGroup')
            .then(async (response) => {
                await dispatch({
                    type: FilterOptionTypes[SET_AGE_GROUP_LIST],
                    payload: response.data?.map((item) => ({
                        id: item.constant,
                        name: item.name
                    }))
                })
            })
            .catch(() => {
                toast.error('Failed to load categories!')
            })
    },
    [GET_ALL_FABRIC_TYPES]: () => async (dispatch) => {
        await Http.GET('getAllMaterialCatagory')
            .then(async (response) => {
                await dispatch({
                    type: FilterOptionTypes[SET_ALL_FABRIC_TYPES],
                    payload: response.data?.map((item) => ({
                        id: item.code,
                        name: item.value
                    }))
                })
            })
            .catch(() => {
                toast.error('Failed to load categories!')
            })
    },
    [GET_DESIGN_LOOK]: () => async (dispatch) => {
        const data = await fetchEnumValues('info.nitex.app.product.DesignLook')
        await dispatch({
            type: FilterOptionTypes[SET_ALL_DESIGN_LOOK],
            payload: data?.map((item) => ({
                id: item.constant,
                name: item.name
            }))
        })
    }
}
