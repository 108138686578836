import React from 'react'
import {
    changeDateFormat,
    replaceUnderLine,
    getSampleActivityStatus,
    getActionDoneBy
} from '../../../helpers/Util'
import ModalContainer from '../../../common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg'

const RequestTimeline = ({ onCloseModal, selectedStyle }) => {
    const requireDate = selectedStyle?.requireDate
    const currentActivity = selectedStyle?.currentActivity?.activityType || null
    const activityList = selectedStyle.timeLineActivityResponse

    const getStatus = (status, type) => {
        if (
            (status === 'PENDING' && currentActivity === type) ||
            status === 'COMPLETED'
        ) {
            return getSampleActivityStatus(type, 'TIMELINE_STATUS')
        }
        return 'gray_dark_03'
    }

    return (
        <ModalContainer
            className='timeline-modal right-sidebar'
            onCloseModal={() => onCloseModal(null)}
        >
            <div className='common-popup'>
                <div className='common-popup-header d-flex justify-content-between'>
                    <div className='popup-tilte'>
                        <h3 className='semibold-16 mb-0'>Design Timeline</h3>
                        <p className='text-sm gray_400'>
                            Required:{' '}
                            {changeDateFormat(requireDate, '', 'Do MMM, YYYY')}
                        </p>
                    </div>
                    <div
                        className='close-btn cursor-pointer'
                        onClick={onCloseModal}
                    >
                        <CloseIcon />
                    </div>
                </div>
                <div className='common-popup-body p-4'>
                    <div className='design-timeline-container'>
                        <ul className='design-timeline design-timeline-centered'>
                            {activityList?.map((item) => (
                                <li
                                    className={`design-timeline-item ${getStatus(
                                        item.status,
                                        item.activityType
                                    )}`}
                                    key={item.activityType}
                                >
                                    <div className='design-timeline-marker' />
                                    <div className='design-timeline-content'>
                                        <p className={'text-xs'}>
                                            {replaceUnderLine(
                                                item.activityType
                                            )}
                                            {item?.userType &&
                                                getActionDoneBy(
                                                    item?.userType || null
                                                )}
                                        </p>
                                        {item?.status === 'COMPLETED' && (
                                            <span className={'text-xs'}>
                                                {changeDateFormat(
                                                    item?.deliveryDate ||
                                                        item?.createdAt,
                                                    '',
                                                    'Do MMM, YYYY'
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </ModalContainer>
    )
}

export default RequestTimeline
