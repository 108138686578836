import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import RangeReducer from './RangeReducer'

const initialState = {
    buildInfos: null,
    response: [],
    rangeTrackerId: null
}

const Range = createSlice({
    name: 'ranges',
    initialState,
    reducers: RangeReducer
})

export const RangeActions = Range.actions;

export const useRanges = () => useSelector(state => state.ranges.response)

export const useRangeTrackId = () => useSelector(state => state.ranges.rangeTrackerId)

export const useBuildInfos = () => useSelector(state => state.ranges.buildInfos)

export default Range.reducer
