import React from 'react'
import {
    _getKey,
    getFileType,
    getIconByFileType,
    getImageExt,
    IMAGE_EXTS
} from '../helpers/Util'
import { ReactComponent as IconFileClose } from '../../assets/icons/old/fileClose.svg'
import Tooltip from '@mui/material/Tooltip'

export const SelectedFileViewComponent = ({
    file,
    showRemoveOption,
    index,
    remove
}) => {
    let ext = getImageExt(file.name)
    const fileType = getFileType(file.name)
    if (IMAGE_EXTS.includes(ext)) {
        return (
            <div className='item'>
                {showRemoveOption ? (
                    <div className='close' onClick={() => remove(index)}>
                        <IconFileClose />
                    </div>
                ) : (
                    <></>
                )}

                <a href={file.docUrl} target='_blank' rel='noreferrer'>
                    <Tooltip title={file.name} arrow placement={'top'}>
                        <img
                            src={
                                showRemoveOption ? file.base64Str : file.docUrl
                            }
                            alt=''
                        />
                    </Tooltip>
                </a>
            </div>
        )
    }

    return (
        <>
            {showRemoveOption ? (
                <div className='close' onClick={() => remove(index)}>
                    <IconFileClose />
                </div>
            ) : (
                <></>
            )}
            <a href={file.docUrl} target='_blank' rel='noreferrer'>
                <Tooltip title={file.name} arrow placement={'top'}>
                    <span>
                        <img src={getIconByFileType(fileType)} alt='' />
                    </span>
                </Tooltip>
            </a>
        </>
    )
}
