import React from 'react'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import OneSignal from 'react-onesignal'

const LogoutModal = () => {
    const navigate = useNavigate()

    const clearTokens = async () => {
        setTimeout(() => {
            try {
                OneSignal.logout()
            } catch (e) {
                // eslint-disable-next-line no-empty,no-console
                console.log(e)
            }
            localStorage.clear()
            sessionStorage.clear()
            delete axios.defaults.headers.common['Authorization']
            window.location.assign('/login')
        }, 250)
    }

    return (
        <div
            className='modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto'
            id='logoutModal'
            tabIndex='-1'
            aria-labelledby='exampleModalCenterTitle'
            aria-modal='true'
            role='dialog'
        >
            <div className='modal-dialog max-w-[485px] modal-dialog-centered relative w-auto pointer-events-none'>
                <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current'>
                    <div className='modal-header px-5 sm:px-8 py-4 sm:py-6 pb-2'>
                        <div className='flex items-center w-full justify-between'>
                            <h3 className='regular-20 font-bold'>Logout</h3>
                            <span
                                className='cursor-pointer'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <CloseIcon />
                            </span>
                        </div>
                    </div>
                    <div className='modal-body relative px-5 sm:px-8'>
                        <div className='space-y-4'>
                            <p className='text-lg black'>
                                Do you really want to Logout?
                            </p>
                        </div>
                    </div>
                    <div className='modal-footer flex gap-4 p-5 sm:p-8'>
                        <button
                            type='button'
                            className='button primary'
                            data-bs-dismiss='modal'
                            onClick={() => clearTokens()}
                        >
                            <span>Logout</span>
                        </button>
                        <button
                            type='button'
                            className='button secondary'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoutModal
