import React, { useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import {
    changeDateFormat,
    convertTimeToLocal,
    parseHtml,
    STATIC_SET_CONSTANTS
} from '../../../../helpers/Util'
import {
    ACTIVE_TAB_OBJ,
    CONTENT,
    ENTITY_TYPE,
    EVENT,
    HIGHLIGHT_EVENTS,
    TITLE_PART_TYPE
} from '../util/Utils'
import { NotificationCommentReply } from './NotificationCommentReply'
import ModalContainer from '../../../../common/modal/ModalContainer'
import { useDispatch } from 'react-redux'
import DesignImages from '../../../../v3/common/DesignImages'

export const NotificationItem = ({
    item,
    markRead,
    markImportant,
    toggleModel,
    isToggleModel
}) => {
    const [enableReplyOption, setEnableReplyOption] = useState(true)
    let { titlePartList, entityIdTypeMapList } = item && item.body
    let {
        frontEndNotificationEvent,
        event,
        isSeen,
        isImportant,
        isGroup,
        notificationCount,
        memberNames,
        notificationImages,
        setPackStyleFlag
    } = item
    const dispatch = useDispatch()

    const eventObj = EVENT[event]

    const redirectNotification = async () => {
        if (item.isSeen === false) {
            await read()
        }
        let { relatedEntityId } = item
        let url = window.location.origin
        switch (item.category) {
            case 'COLLECTION':
                if (item.event === EVENT.COLLECTION_DESIGN_MOVED.value) {
                    const moveCollectionId = entityIdTypeMapList.pop()?.id
                    url += `/collections/${moveCollectionId}`
                } else if (
                    item.event === EVENT.REMOVED_FROM_FAVOURITE_FOLDER.value
                ) {
                    url += '/favourites'
                } else if (item.event === EVENT.FAVOURITE_FOLDER_SHARED.value) {
                    url += `/favourites/${relatedEntityId.favouriteFolderId}`
                } else url += `/collections/${relatedEntityId.collectionId}`
                break
            case 'ORDER':
                url += `/orders/view/${relatedEntityId.orderId}`
                if (item.event === 'BUYER_ETD_UPDATED') {
                    url += `?productId=${relatedEntityId.productId}`
                }
                break
            case 'POST':
            case 'STEP':
                url += `/orders/view/${relatedEntityId.orderId}`
                if (item.event === 'TASK_COMPLETED') {
                    url += `?deliverableId=${relatedEntityId.deliverableId}&productId=${relatedEntityId.productId}`
                }
                if (item.event !== 'TASK_COMPLETED') {
                    if (relatedEntityId.deliverableId) {
                        url += `?deliverableId=${relatedEntityId.deliverableId}`
                    }
                    if (relatedEntityId.productId) {
                        url += !relatedEntityId.deliverableId ? '?' : '&'
                        url += `productId=${relatedEntityId.productId}`
                    }
                }

                break
            case 'QUOTE':
                url += `/quotes/detail/${relatedEntityId.quoteRequestId}`
                break
            case 'SAMPLE':
                url += `/samples/detail/${relatedEntityId.sampleRequestId}`
                break
            case 'TASK':
                break

            case 'PRODUCT':
                if (
                    event === EVENT.UPDATED_COLOR_SIZE_WISE_PRICE.value ||
                    event === EVENT.UPDATED_COLOR_SIZE_WISE_QTY.value ||
                    event === 'MEASUREMENT_CHART_UPDATED'
                )
                    url += `/orders/view/${relatedEntityId.orderId}?productId=${relatedEntityId.productId}`
                else {
                    let isQuoteComment = entityIdTypeMapList?.find(
                        (item) =>
                            item.type === 'POST' &&
                            item.postTagType === 'PRODUCT_QUOTE_COMMENT'
                    )
                    let isSampleComment = entityIdTypeMapList?.find(
                        (item) =>
                            item.type === 'POST' &&
                            item.postTagType === 'PRODUCT_SAMPLE_COMMENT'
                    )
                    if (isQuoteComment) {
                        url += `/quotes/detail/${relatedEntityId.quoteRequestId}`
                    } else if (isSampleComment) {
                        url += `/samples/detail/${relatedEntityId.sampleRequestId}`
                    } else {
                        url = '/home'
                    }
                }
                break
            case 'INVOICE':
                url += `/invoices/view/${relatedEntityId.invoiceId}`
                break

            default:
                break
        }

        if (HIGHLIGHT_EVENTS.includes(item.event)) {
            let selectedPost = item.body.entityIdTypeMapList.find(
                (item) => item.type === 'POST'
            )
            url += url.includes('?') ? '&showComment=true' : '?showComment=true'
            if (relatedEntityId?.productId && !url.includes('productId')) {
                url += `&productId=${relatedEntityId.productId}`
            }
            if (selectedPost?.id) {
                url += `&postId=${selectedPost.id}`
            }
        }

        if (item.event !== 'REMOVED_FROM_COLLECTION') {
            window.open(url, '_blank')
        }
    }

    const important = (e) => {
        e.stopPropagation()
        markImportant(item, !item.isImportant)
    }

    const read = async () => {
        if (item.isSeen === false) {
            await markRead(item, false)
        }
    }

    const onClickItem = async (e) => {
        e.stopPropagation()
        if ([EVENT.COLLECTION_DESIGN_DELETED.value].includes(event)) {
            await read()
            if (!isToggleModel) toggleModel(item.id)
        } else redirectNotification()
    }
    const replyButtonClick = (e, isEnableReply) => {
        e.stopPropagation()
        setEnableReplyOption(isEnableReply)
    }
    const replyButtonClosed = () => {
        setEnableReplyOption(true)
    }
    const replyHtmlButton = () => {
        if (eventObj && eventObj?.value.includes('MENTIONED')) {
            return (
                <button className='button text-only'>
                    <span
                        className='gray_400 regular-12'
                        onClick={(e) => replyButtonClick(e, false)}
                    >
                        REPLY
                    </span>
                </button>
            )
        } else {
            return ''
        }
    }
    const renderModalDesign = () => (
        <div className='common-popup'>
            <div className='modal-header px-5 sm:px-8 py-4 sm:py-6 pb-2'>
                <div className='flex items-center w-full justify-between'>
                    <h3 className='text-base sm:text-xl font-bold'>
                        {' '}
                        {'Deleted from '}-{' '}
                        {
                            entityIdTypeMapList.find(
                                (obj) => obj.type === ENTITY_TYPE.COLLECTION
                            )?.text
                        }
                    </h3>
                    <button
                        onClick={() => {
                            toggleModel(null)
                        }}
                        type='button'
                        className='btn-close box-content w-4 h-4 p-1 !mr-0.5 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                    />
                </div>
            </div>

            <div className='modal-body relative p-5 sm:p-8 pt-2'>
                <p className='text-sm gray_400 mb-5' />
                <div className='all-designs grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                    {item?.deletedCollectionInfoList?.map((deletedItem, i) => {
                        return (
                            <div key={'key' + i} className='single-design'>
                                <div className='single-style'>
                                    <div className='image-container'>
                                        <img
                                            src={deletedItem?.image}
                                            alt='style'
                                            className='style-photo'
                                        />
                                    </div>
                                    <p className='regular-12 gray_800 weight-500 mb-1 mt-2'>
                                        {deletedItem?.productName}
                                    </p>
                                    <p className='regular-12 gray_800 mb-1'>
                                        {deletedItem?.actorName}/
                                        <span>
                                            {' '}
                                            {changeDateFormat(
                                                deletedItem?.createdAt,
                                                '',
                                                'DD MMM, YY'
                                            )}
                                        </span>
                                    </p>
                                    {/*<span className='status'>*/}
                                    {/*    {deletedItem.status}*/}
                                    {/*</span>*/}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='modal-footer flex gap-4 p-5 sm:p-8 pt-2'>
                <button
                    type='button'
                    className='button primary'
                    onClick={() => redirectNotification()}
                >
                    Go to collection
                </button>
                <button
                    type='button'
                    className='button secondary'
                    onClick={() => toggleModel(null)}
                >
                    <span>Close</span>
                    <span className='ml-2' />
                </button>
            </div>
        </div>
    )

    const getIcon = () => {
        if (eventObj !== undefined) return eventObj.icon
        else return ''
    }
    const getIsSeenOrNot = () => {
        if (!isSeen) return <div className='new-dot' />
        else return ''
    }
    const getMember = () => {
        if (
            frontEndNotificationEvent === ACTIVE_TAB_OBJ.comments.enum ||
            frontEndNotificationEvent === ACTIVE_TAB_OBJ.mentions.enum
        ) {
            return eventObj.firstAction
                ? getNotificationCount() + ' ' + eventObj.firstAction
                : ''
        } else {
            if (isGroup) {
                let members = ''
                if (memberNames !== undefined)
                    memberNames.map((name, i) => {
                        members += name
                        if (
                            memberNames.length - 2 !== i &&
                            memberNames.length - 1 !== i &&
                            memberNames.length > 1
                        ) {
                            members += ', '
                        } else if (
                            memberNames.length - 2 === i &&
                            memberNames.length > 1
                        ) {
                            members += ' & '
                        }
                    })
                return members
            } else {
                if (eventObj !== undefined && eventObj.isActor) {
                    return getTitlePartText(TITLE_PART_TYPE.ACTOR)
                } else {
                    return ''
                }
            }
        }
    }
    const getNumberNotificationCount = () => {
        if (
            item?.frontEndNotificationEvent === ACTIVE_TAB_OBJ.comments.enum ||
            item?.frontEndNotificationEvent === ACTIVE_TAB_OBJ.mentions.enum
        ) {
            return ''
        } else {
            return getNotificationCount()
        }
    }
    const getNotificationCount = () => {
        if (isGroup) {
            return ' ' + (notificationCount ? notificationCount : '') + ' '
        } else return ''
    }

    const getTitlePartText = (titlePartType) => {
        let titleObj = titlePartList.find(
            (titlePart) => titlePart?.titlePartType === titlePartType
        )
        return titleObj?.text
    }
    const getEntityMapTitlePartText = (type) => {
        if (type === ENTITY_TYPE.NEW_VALUE) {
            let entityObj = titlePartList.find(
                (entityIdTypeMap) => entityIdTypeMap?.titlePartType === type
            )
            return entityObj?.text
        } else if (type === 'of' || type === 'on' || type === 'to') return type
        else {
            let entityObj = titlePartList.find(
                (entityIdTypeMap) => entityIdTypeMap?.entityType === type
            )
            if (
                type === ENTITY_TYPE.POJO_CLASS &&
                (EVENT.BUYER_ETD_UPDATED.value === event ||
                    EVENT.FACTORY_ETD_UPDATED.value === event ||
                    EVENT.ORDER_PO_RECEIVE_DATE_UPDATED.value === event)
            ) {
                return changeDateFormat(entityObj?.text, '', 'DD MMM')
            } else return entityObj?.text
        }
    }
    const getEntityMap = (type) => {
        let entityObj = entityIdTypeMapList.find(
            (entityIdTypeMap) => entityIdTypeMap?.type === type
        )
        return entityObj
    }
    const getAction = () => {
        if (eventObj !== undefined && eventObj.action) return eventObj.action
        else return ''
    }
    const getAnotherAction = () => {
        if (eventObj !== undefined && eventObj.anotherAction)
            return eventObj.anotherAction
        else return ''
    }
    const getTitleEntity = () => {
        if (eventObj !== undefined && eventObj.titleEntityTypeList)
            return eventObj.titleEntityTypeList
                .map((type, i) => getEntityMapTitlePartText(type))
                .join(' ')
        else return ''
    }

    const getAnotherTitlePar = () => {
        if (eventObj?.value === EVENT.COLLECTION_DESIGN_MOVED.value) {
            return getTitleEntityForMove()
        } else if (
            eventObj?.value === EVENT.PROJECT_DEADLINE_UPDATED.value ||
            eventObj?.value === EVENT.PROJECT_MEMBER_ADDED.value ||
            eventObj?.value === EVENT.PI_PROVIDED.value ||
            eventObj?.value === EVENT.SC_PROVIDED.value ||
            eventObj?.value === EVENT.ORDER_PI_UPDATED.value ||
            eventObj?.value === EVENT.ORDER_SC_UPDATED.value ||
            eventObj?.value === EVENT.NEW_RFQ_ADDED.value ||
            eventObj?.value === EVENT.RFQ_PRICE_UPDATED.value ||
            eventObj?.value === EVENT.INVOICE_PAYMENT_ADDED.value ||
            eventObj?.value === EVENT.PROJECT_STATUS_UPDATED.value
        ) {
            return getTitleParForProject()
        } else {
            return getTitleEntity()
        }
    }
    const getTitleEntityForMove = () => {
        return entityIdTypeMapList.map((obj) => obj.text).join(' ')
    }
    const getTitleParForProject = () => {
        return titlePartList.map((obj) => obj.text).join(' ')
    }

    const getItemTagNameList = () => {
        if (eventObj !== undefined && eventObj.entityTypeList) {
            let listOfObj = eventObj?.entityTypeList.map((type) =>
                getEntityMap(type)
            )

            if (listOfObj && listOfObj?.length === 1) {
                return (
                    <p className='regular-14 black ml-3'>
                        {listOfObj[0].text ? listOfObj[0].text : ''}
                    </p>
                )
            } else {
                if (
                    listOfObj &&
                    listOfObj?.length > 1 &&
                    listOfObj[0] !== undefined
                ) {
                    return (
                        <ul className='common-list-component ml-3'>
                            {listOfObj?.map((obj, i) => {
                                return (
                                    <li key={'key' + i}>
                                        {obj?.text ? obj?.text : ''}
                                    </li>
                                )
                            })}
                        </ul>
                    )
                } else {
                    return ''
                }
            }
        } else {
            return ''
        }
    }

    const getNotificationImages = () => {
        if (setPackStyleFlag === STATIC_SET_CONSTANTS.SET_PRODUCT) {
            return (
                <DesignImages
                    designInfo={{
                        designDocuments: notificationImages?.map((item) => ({
                            docUrl: item
                        }))
                    }}
                />
            )
        } else if (notificationImages.length === 1)
            return (
                <img
                    src={notificationImages[0]}
                    alt='style'
                    className='single-image'
                />
            )
        else
            return (
                <div className='grid-images'>
                    {notificationImages.map((image, i) => {
                        return (
                            <div key={'key' + i} className='grid-single'>
                                <img src={image} alt='' />
                            </div>
                        )
                    })}
                </div>
            )
    }
    const getCommentItem = () => {
        let titleObj = titlePartList.find(
            (titlePart) => titlePart?.titlePartType === TITLE_PART_TYPE.ACTOR
        )
        let entityObj = entityIdTypeMapList.find(
            (entityIdTypeMap) => entityIdTypeMap?.type === ENTITY_TYPE.POST
        )

        return (
            <div className='contents-with-image'>
                <div className='left-half'>
                    <div className='text-content'>
                        <p className='regular-16 black weight-500'>
                            {titleObj?.text}
                        </p>
                        <p
                            className='regular-14 gray_400'
                            dangerouslySetInnerHTML={{
                                __html: parseHtml(
                                    entityObj?.text
                                        ? entityObj?.text.toString('html')
                                        : ''
                                )
                            }}
                        />
                    </div>
                    {item?.reply && (
                        <div className='text-content mt-2'>
                            <p className='regular-16 gray_800 weight-500'>
                                {item?.replyActorName}
                            </p>
                            <p
                                className='regular-14 gray_400'
                                dangerouslySetInnerHTML={{
                                    __html: parseHtml(
                                        item?.reply
                                            ? item?.reply.toString('html')
                                            : ''
                                    )
                                }}
                            />
                        </div>
                    )}
                    {!enableReplyOption ? (
                        <div onClick={(e) => e.stopPropagation()}>
                            <NotificationCommentReply
                                item={item}
                                replyButtonClosed={replyButtonClosed}
                                read={read}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className='images'>{getNotificationImages()}</div>
            </div>
        )
    }

    const organizeImages = () => {
        const images = []
        const imagesOfNotification = notificationImages
        imagesOfNotification.forEach((imgUrl) => {
            if (imgUrl && imgUrl.startsWith('[')) {
                const temp = JSON.parse(imgUrl)
                images.push(...temp)
            } else if (imgUrl) {
                images.push(imgUrl)
            }
        })
        return images
    }

    const getMultipleImagesSingleItem = () => {
        return (
            <div className='styles-only'>
                {organizeImages().map((image, i) => {
                    if (i <= 4)
                        return (
                            <div key={'key' + i} className='single-style'>
                                <img src={image} alt='style' />
                            </div>
                        )
                    else if (i === 5)
                        return (
                            <div key={'key' + i} className='single-style'>
                                <img src={image} alt='style' />
                                {notificationImages.length - 1 - i < 1 ? (
                                    ''
                                ) : (
                                    <div className='overlay-count'>
                                        <span className='image-count'>
                                            +{notificationImages.length - 1 - i}{' '}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )
                })}
            </div>
        )
    }

    const getEmptyLeftHalfImagesItem = () => {
        return (
            <div className='contents-with-image'>
                <div className='images'>{getNotificationImages()}</div>
            </div>
        )
    }

    const renderTimeCount = () => {
        return (
            <ul className='common-list-component'>
                <li className='regular-12 gray_400'>
                    {changeDateFormat(item?.createdAt, '', 'MMM DD, YYYY')}
                </li>
                <li className='regular-12 gray_400'>
                    {convertTimeToLocal(
                        changeDateFormat(
                            item?.createdAt?.split('T')[0],
                            '',
                            'DD/MM/YYYY'
                        ),
                        item?.createdAt?.split('T')[1],
                        'h:mm A'
                    )}
                </li>
            </ul>
        )
    }

    return (
        <div
            className={
                isImportant
                    ? `single-notification important ${!isSeen ? 'unread' : ''}`
                    : `single-notification ${!isSeen ? 'unread' : ''}`
            }
            onClick={(e) => onClickItem(e)}
        >
            <div className='heading-information'>
                <div className='title d-flex align-items-center'>
                    <div className='action-icon'>
                        <img src={getIcon()} alt='action-icon' />
                    </div>
                    <p className='regular-16 black'>
                        <span className='font-bold'>{getMember()} </span>
                        <span className='gray_300 weight-400 ml-1'>
                            {' '}
                            {getAction()}{' '}
                        </span>
                        {event === 'QUOTE_PRICE_PROVIDED' ? (
                            <span className='ml-1'>
                                {' '}
                                {notificationImages &&
                                    notificationImages?.length}
                            </span>
                        ) : (
                            getNumberNotificationCount()
                        )}{' '}
                        {getAnotherAction()}
                        {getAnotherTitlePar()}
                    </p>
                </div>
                {getItemTagNameList()}
            </div>
            <div className='notification-details-information'>
                {getIsSeenOrNot()}
                {item?.frontEndNotificationEvent ===
                    ACTIVE_TAB_OBJ.comments.enum ||
                item?.frontEndNotificationEvent === ACTIVE_TAB_OBJ.mentions.enum
                    ? getCommentItem()
                    : ''}
                {eventObj
                    ? eventObj.content === CONTENT.EMPTY_LEFT_HALF_IMAGES_ITEM
                        ? getEmptyLeftHalfImagesItem()
                        : ''
                    : ''}
                {eventObj
                    ? eventObj.content === CONTENT.MULTIPLE_IMAGES_SINGLE_ITEM
                        ? getMultipleImagesSingleItem()
                        : ''
                    : ''}
                <div className='date-important-info d-flex justify-content-between align-items-center'>
                    <div className='date-info'>{renderTimeCount()}</div>
                    <div className='important-info'>
                        {(enableReplyOption &&
                            item?.frontEndNotificationEvent ===
                                ACTIVE_TAB_OBJ.comments.enum) ||
                        item?.frontEndNotificationEvent ===
                            ACTIVE_TAB_OBJ.mentions.enum
                            ? replyHtmlButton()
                            : ''}
                        <div
                            className={
                                isImportant
                                    ? 'important-flag red'
                                    : 'important-flag'
                            }
                        >
                            <Tooltip
                                title={'Important flag'}
                                placement='top'
                                arrow
                            >
                                <img
                                    src={
                                        isImportant
                                            ? '/icons/important-flag-red.svg'
                                            : '/icons/important-flag-gray.svg'
                                    }
                                    alt='important'
                                    onClick={(e) => important(e)}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>

            {isToggleModel === item.id && (
                <ModalContainer
                    className='design-delete-request-modal'
                    onCloseModal={() => toggleModel(null)}
                >
                    {renderModalDesign()}
                </ModalContainer>
            )}
        </div>
    )
}
