import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as NitexIcon } from '../../../assets/images/home/nitexLogo.svg'
import { ReactComponent as CloseIcon } from '../../../assets/images/home/cross.svg'
import { authUserInfo } from '../../helpers/Util'
import { FilterActionTypes } from '../../redux_toolkit/V3/Filter'
import { CLEAR_FILTER_FIELDS } from '../../redux_toolkit/@types/action.types'
import { useDispatch } from 'react-redux'

const BrandInfo = ({ onClose }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const resetStores = () => {
        dispatch(FilterActionTypes[CLEAR_FILTER_FIELDS]())
        if(onClose) onClose()
    }

    return (
        <div className='brand-info flex items-center'>
            <Link to='/home' onClick={resetStores}>
                <span className='brand-logo'>
                    <NitexIcon />
                </span>
            </Link>
            <span
                className='divider cursor-pointer mx-3'
                onClick={() => {
                    navigate('/home')
                    resetStores()
                }}
            >
                <CloseIcon />
            </span>
            <span
                className='text-logo text-lg text-primaryColor-shade-100 cursor-pointer'
                onClick={() => {
                    navigate('/home')
                    resetStores()
                }}
            >
                {authUserInfo()?.brandResponse?.name}
            </span>
        </div>
    )
}

export default BrandInfo
