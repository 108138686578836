import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const Menu = ({ onClose }) => {
    const [activeTab, setActiveTab] = useState('')
    const currentLocation = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        getNavActiveTab()
    }, [currentLocation.pathname])

    const getNavActiveTab = () => {
        let activeTab =
            currentLocation.pathname === '/design-lab'
                ? 'DESIGN_LAB'
                : currentLocation.pathname === '/dashboard'
                ? 'MY_DASHBOARD'
                : ''
        setActiveTab(activeTab)
    }

    const setNavActiveTab = (tabName) => {
        setActiveTab(tabName)
    }

    return (
        <ul>
            <li
                className={
                    activeTab === 'DESIGN_LAB'
                        ? 'active cursor-pointer'
                        : 'cursor-pointer'
                }
                onClick={() => {
                    setNavActiveTab('DESIGN_LAB')
                    navigate('/design-lab')
                    if(onClose) onClose()
                }}
            >
                Design Lab
            </li>
            <li
                className={
                    activeTab === 'MY_DASHBOARD'
                        ? 'active cursor-pointer'
                        : 'cursor-pointer'
                }
                onClick={() => {
                    setNavActiveTab('MY_DASHBOARD')
                    navigate('/dashboard?active=QUOTES&status=RUNNING')
                    if(onClose) onClose()
                }}
            >
                My Dashboard
            </li>
        </ul>
    )
}

export default Menu
