import React, { useEffect, useState } from 'react'
import ModalContainer from '../../common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus-round.svg'
import { useNavigate } from 'react-router-dom'
import { FilterActionTypes } from '../../redux_toolkit/V3/Filter'
import {
    CLEAR_FILTER_FIELDS,
    SET_CATEGORY_LIST
} from '../../redux_toolkit/@types/action.types'
import {
    generateRandomString,
    setBreadCrumbRoutes,
    validateNumber
} from '../../helpers/Util'
import { useDispatch } from 'react-redux'
import SelectComponent from './components/SelectComponent'
import {
    FilterOptionTypes,
    useFilterOption
} from '../../redux_toolkit/V3/Filter/FilterOptionReducers'
import { GET_ALL_PRODUCTS_FROM_RANGE } from '../../redux_toolkit/@types/thunk.types'
import RangeThunks from '../../redux_toolkit/V3/Range/RangeThunks'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { fetchFilterListByBuyer } from '../../services/CommonService'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-round.svg'

const BuildRangeRightSidePanel = ({ openModal, onCloseModal, isEdit }) => {
    const initialState = [
        {
            category: {},
            noOfStyle: '',
            uId: generateRandomString()
        },
        {
            category: {},
            noOfStyle: '',
            uId: generateRandomString()
        }
    ]

    const initialInputData = {
        hasPrint: false,
        hasEmbroidery: false,
        hasWash: false,
        hasDye: false
    }

    const [inputData, setInputData] = useState(initialInputData)
    const [showMore, setShowMore] = useState(false)
    const [ranges, setRanges] = useState(initialState)
    const options = useFilterOption()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        ;(async () => {
            try {
                dispatch(openLoader())
                const data = await fetchFilterListByBuyer(
                    '?isExploreDesignPage=true'
                )
                dispatch({
                    type: FilterOptionTypes[SET_CATEGORY_LIST],
                    payload: data.categoryList
                })
                dispatch(closeLoader())
            } catch (e) {
                dispatch(closeLoader())
            }
        })()
        let buildRangeInfos = localStorage.getItem('buildRangeInfos')
        if (buildRangeInfos) {
            let info = JSON.parse(buildRangeInfos)
            setRanges(info.ranges)
            if (
                info.inputData?.season?.id ||
                info.inputData?.fabricType?.id ||
                inputData?.hasDye ||
                inputData?.hasWash ||
                inputData?.hasPrint ||
                inputData?.hasEmbroidery
            ) {
                setShowMore(true)
                setInputData(info.inputData)
            }
        }
    }, [])

    const closeModal = () => {
        setInputData(initialInputData)
        setRanges(initialState)
        onCloseModal()
    }

    const handleValidation = () => {
        let error = false
        let modifiedRanges = ranges?.map((item) => {
            item.categoryError = !item.category?.id
            item.noOfStyleError =
                item.noOfStyle === '' || item.noOfStyle === '0'
            if (
                !item.category?.id ||
                item.noOfStyle === '' ||
                item.noOfStyle === '0'
            )
                error = true
            return item
        })
        setRanges(modifiedRanges)
        return error
    }

    const handleBuild = async () => {
        if (handleValidation()) return
        try {
            dispatch(openLoader())
            let categoryWiseCount = {}
            for (let item of ranges) {
                categoryWiseCount[item.category.id] = item.noOfStyle
            }
            let postData = {
                categoryWiseCount,
                ...inputData,
                season: inputData?.season?.id,
                fabricType: inputData?.fabricType?.id
            }
            await dispatch(RangeThunks[GET_ALL_PRODUCTS_FROM_RANGE](postData))
            localStorage.setItem(
                'buildRangeInfos',
                JSON.stringify({
                    ranges,
                    inputData
                })
            )
            closeModal()
            dispatch(closeLoader())
            if (!isEdit) {
                dispatch(FilterActionTypes[CLEAR_FILTER_FIELDS]())
                let newRoute = '/suggested-designs'
                let visitingRoutes = [
                    {
                        key: '/design-lab',
                        value: 'Designs'
                    },
                    {
                        key: newRoute,
                        value: 'Build your range'
                    }
                ]

                setBreadCrumbRoutes(visitingRoutes)
                navigate(newRoute)
            }
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const addNewRow = () => {
        setRanges((prev) => [
            ...prev,
            {
                category: {},
                noOfStyle: '',
                uId: generateRandomString()
            }
        ])
    }

    const removeRow = (index) => {
        let cloneArray = [...ranges]
        cloneArray.splice(index, 1)
        setRanges(cloneArray)
    }

    const onChangeField = (uId, fieldName, value) => {
        let cloneArray = ranges?.map((item) => {
            if (item.uId === uId) {
                item[fieldName] = value
            }
            return item
        })

        setRanges(cloneArray)
    }

    const getCategories = () => {
        const selectedCategories = []
        ranges?.forEach((item) => {
            if (item.category?.id) {
                selectedCategories.push(item.category.id)
            }
        })

        return options?.categories?.filter(
            (item) => !selectedCategories.includes(item.id)
        )
    }

    const renderFields = () => {
        return ranges?.map((item, i) => {
            return (
                <div
                    className='grid grid-cols-2 gap-2'
                    key={`range_row_${item.uId}`}
                >
                    <div className='form-group'>
                        <label className='regular-14 gray_300 mb-2'>
                            Category
                        </label>
                        <div>
                            <SelectComponent
                                options={getCategories()}
                                selected={item.category}
                                onSelect={(obj) =>
                                    onChangeField(item.uId, 'category', obj)
                                }
                                required={item.categoryError}
                                label={'Select category'}
                            />
                        </div>
                    </div>
                    <div className='form-group'>
                        <label className='regular-14 gray_300 mb-2'>
                            Number of style
                        </label>
                        <div className='flex items-center'>
                            <input
                                type='number'
                                name='nos'
                                className={
                                    item.noOfStyleError ? 'required' : ''
                                }
                                value={item.noOfStyle}
                                onChange={(e) =>
                                    onChangeField(
                                        item.uId,
                                        'noOfStyle',
                                        e.target.value
                                    )
                                }
                                onKeyPress={validateNumber}
                                onKeyDown={(event) => {
                                    if ([38, 40].includes(event.keyCode)) {
                                        event.preventDefault()
                                    }
                                }}
                            />
                            <button
                                className='button primary icon-only ml-2'
                                disabled={ranges.length < 3}
                                onClick={() => removeRow(i)}
                            >
                                <MinusIcon />
                            </button>
                        </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <>
            {openModal && (
                <ModalContainer
                    className='build-range-modal right-sidebar'
                    onCloseModal={onCloseModal}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Build your range
                                </h3>
                                <p className='regular-12 gray_300'>
                                    Create your own range
                                </p>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={closeModal}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            {renderFields()}
                            <div>
                                <button
                                    className='button link ml-2 cursor-pointer'
                                    onClick={addNewRow}
                                >
                                    <PlusIcon /> Add
                                </button>
                            </div>
                        </div>
                        <div className='common-popup-footer'>
                            <button
                                className='button primary'
                                onClick={handleBuild}
                            >
                                <span>{isEdit ? 'Re-build' : 'Build'}</span>
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    )
}

export default BuildRangeRightSidePanel
