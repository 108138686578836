import React from 'react'
import { useSelector } from 'react-redux'
import {
    getFileType,
    getIconByFileType,
    getShortName
} from '../../../helpers/Util'
import Tooltip from '@mui/material/Tooltip'

const PoAttachedFiles = () => {
    const timelineStore = useSelector((store) => store.timeline)
    const { orderInfo } = timelineStore

    const renderFileInfo = () => {
        return orderInfo?.poDocumentResponse?.map((file, index) => {
            const fileType = getFileType(file.docUrl)
            return (
                <tr
                    className='even:bg-white-shade-100'
                    key={`file_row_${index}`}
                >
                    <td className='text-base font-normal px-6 py-4 whitespace-nowrap'>
                        <div
                            className='flex items-center cursor-pointer'
                            onClick={() => window.open(file.docUrl, '_blank')}
                        >
                            <span>
                                {fileType === 'IMAGE' ? (
                                    <img
                                        className='w-7 h-7 object-contain'
                                        src={file?.docUrl}
                                        alt=''
                                    />
                                ) : (
                                    <span>
                                        <img
                                            className='w-7 h-7 object-contain'
                                            src={getIconByFileType(fileType)}
                                            alt=''
                                        />{' '}
                                    </span>
                                )}
                            </span>
                            <Tooltip title={file.name} placement={'top'} arrow>
                                <span className='regular-14 black ml-2'>
                                    {getShortName(file.name, 35)}
                                </span>
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div
            className='modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto'
            id='poAttachedFiles'
            tabIndex='-1'
            aria-labelledby='exampleModalCenterTitle'
            aria-modal='true'
            role='dialog'
        >
            <div className='modal-dialog max-w-[680px] modal-dialog-centered relative w-auto pointer-events-none'>
                <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current'>
                    <div className='modal-header flex flex-shrink-0 items-center justify-between p-4'>
                        <h5
                            className='regular-20 font-bold leading-normal black'
                            id='exampleModalScrollableLabel'
                        >
                            Attached documents
                        </h5>
                        <button
                            type='button'
                            className='btn-close box-content w-4 h-4 p-1 !mr-0.5 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        />
                    </div>
                    <div className='modal-body relative px-4 pb-4'>
                        <div>
                            {/* <div className='flex justify-between items-center mb-5'>
                                <h5 className='text-xl font-bold leading-normal text-primaryColor'>
                                    Attached Files
                                </h5>
                                <span className='text-base'>
                                    {orderInfo?.poDocumentResponse?.length}{' '}
                                    files
                                </span>
                            </div> */}
                            <div className='flex flex-col'>
                                <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
                                    <div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
                                        <div className='overflow-hidden'>
                                            <table className='table'>
                                                <thead className='bg-white'>
                                                    <tr>
                                                        <th
                                                            scope='col'
                                                            className='text-xl font-normal bg-white-shade-100 px-6 py-4 text-left first:border-r border-primaryColor-shade-200'
                                                        >
                                                            File
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderFileInfo()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PoAttachedFiles
