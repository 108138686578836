import React from 'react'
import { ReactComponent as FavouriteIcon } from '../../../icons/favorite.svg'

const SharedCollection = () => {
    return (
        <div className='shared-collections py-5'>
            {/* Single item in a row */}
            <div className='collection-single-row-item mb-10'>
                <div className='collection-content'>
                    <div className='collection-status'>
                        <span className='status green-1 mr-2'>
                            Eco-Friendly
                        </span>
                        <span className='status magenta-1 mr-2'>
                            Sustainable
                        </span>
                    </div>
                    <h3 className='collection-title'>
                        Men’s Semi-formal Full Set Pack Summer 2024
                    </h3>
                    <div className='details-info'>
                        <ul className='common-list-component'>
                            <li>Summer 24</li>
                            <li>34 Styles</li>
                            <li>Manna</li>
                        </ul>
                    </div>
                </div>
                <div className='collection-photos'>
                    <img src='/images/single-design.png' alt='style' />
                    <img src='/images/single-design.png' alt='style' />
                    <img src='/images/single-design.png' alt='style' />
                    <img src='/images/single-design.png' alt='style' />
                    <img src='/images/single-design.png' alt='style' />
                    <img src='/images/single-design.png' alt='style' />
                    <img src='/images/single-design.png' alt='style' />
                    <img src='/images/single-design.png' alt='style' />
                </div>
            </div>

            {/* Two item in a row */}
            <div className='collection-two-row-item'>
                <div className='collection-card'>
                    <div className='collection-status'>
                        <span className='status green-1 mr-2'>
                            Eco-Friendly
                        </span>
                        <span className='status magenta-1 mr-2'>
                            Sustainable
                        </span>
                    </div>
                    <div className='product-img-container'>
                        <div className='prev-img'>
                            <img src='/images/design-list-5.png' alt='style' />
                        </div>
                        <div className='prev-img-thumb'>
                            <div className='single-first'>
                                <img
                                    src='/images/design-list-1.png'
                                    alt='style'
                                />
                            </div>
                            <div className='single-second'>
                                <img
                                    src='/images/single-design.png'
                                    alt='style'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='collection-content d-flex justify-between items-center'>
                        <div>
                            <h3 className='collection-title'>
                                Men’s Semi-formal Full Set Pack Summer 2024
                            </h3>
                            <div className='details-info'>
                                <ul className='common-list-component'>
                                    <li>Summer 24</li>
                                    <li>34 Styles</li>
                                    <li>Manna</li>
                                </ul>
                            </div>
                        </div>
                        <button className='button secondary icon-only bg-white'>
                            <FavouriteIcon />
                        </button>
                    </div>
                </div>
                <div className='collection-card'>
                    <div className='collection-status'>
                        <span className='status green-1 mr-2'>
                            Eco-Friendly
                        </span>
                        <span className='status magenta-1 mr-2'>
                            Sustainable
                        </span>
                    </div>
                    <div className='product-img-container'>
                        <div className='prev-img'>
                            <img src='/images/design-list-5.png' alt='style' />
                        </div>
                        <div className='prev-img-thumb'>
                            <div className='single-first'>
                                <img
                                    src='/images/design-list-1.png'
                                    alt='style'
                                />
                            </div>
                            <div className='single-second'>
                                <img
                                    src='/images/single-design.png'
                                    alt='style'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='collection-content d-flex justify-between items-center'>
                        <div>
                            <h3 className='collection-title'>
                                Men’s Semi-formal Full Set Pack Summer 2024
                            </h3>
                            <div className='details-info'>
                                <ul className='common-list-component'>
                                    <li>Summer 24</li>
                                    <li>34 Styles</li>
                                    <li>Manna</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Three item in a row */}
            <div className='collection-three-row-item my-5'>
                {Array.from({ length: 3 }).map((_, i) => (
                    <div className='collection-card'>
                        <div className='product-img-container'>
                            <div className='prev-img'>
                                <img
                                    src='/images/design-list-5.png'
                                    alt='style'
                                />
                            </div>
                            <div className='prev-img-thumb'>
                                <div className='single-first'>
                                    <img
                                        src='/images/design-list-1.png'
                                        alt='style'
                                    />
                                </div>
                                <div className='single-second'>
                                    <img
                                        src='/images/single-design.png'
                                        alt='style'
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='collection-content d-flex justify-between items-center'>
                            <div>
                                <h3 className='collection-title'>
                                    My Favorites
                                </h3>
                                <div className='details-info'>
                                    <ul className='common-list-component'>
                                        <li>10:23 PM</li>
                                        <li>23 May, 2024</li>
                                    </ul>
                                </div>
                            </div>
                            <button className='button secondary icon-only'>
                                <FavouriteIcon />
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Four item in a row */}
            <div className='collection-four-row-item my-5'>
                {Array.from({ length: 4 }).map((_, i) => (
                    <div className='collection-card'>
                        <div className='four-item-image'>
                            <div className='single-item'>
                                <img
                                    src='/images/single-design.png'
                                    alt='style'
                                />
                            </div>
                            <div className='single-item'>
                                <img
                                    src='/images/single-design.png'
                                    alt='style'
                                />
                            </div>
                            <div className='single-item'>
                                <img
                                    src='/images/single-design.png'
                                    alt='style'
                                />
                            </div>
                            <div className='single-item'>
                                <img
                                    src='/images/single-design.png'
                                    alt='style'
                                />
                            </div>
                        </div>

                        <div className='collection-content d-flex justify-between items-center'>
                            <div>
                                <h3 className='collection-title'>AW23</h3>
                                <div className='details-info'>
                                    <ul className='common-list-component'>
                                        <li>Summer 24</li>
                                        <li>34 Styles</li>
                                        <li>Manna</li>
                                    </ul>
                                </div>
                            </div>
                            <button className='button secondary icon-only'>
                                <FavouriteIcon />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SharedCollection
