import React, { useState, useEffect } from 'react'
import ModalContainer from '../common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import { ReactComponent as IconCollectionRemove } from '../../assets/icons/old/collectionRemove.svg'
import SelectComponent from '../../app/v3/range/components/SelectComponent'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { readFileAsync, generateUniqueId, getShortName } from '../helpers/Util'
import { getAllCategory, getAllMarket, uploadStyleDoc } from '../services/Quote'
import { closeLoader, openLoader } from '../redux_toolkit/Loader'
import Tooltip from '@mui/material/Tooltip'
import RequestForQuotes from '../common/RequestForQuotes'
import RequestForSample from '../common/RequestForSample'

const AskQuoteSample = ({ onCloseModal }) => {
    const [showAskSampleModal, setShowAskSampleModal] = useState(false)
    const [files, setFiles] = useState([])
    const [market, setMarket] = useState([])
    const [categories, setCategories] = useState([])
    const [errorState, setErrorState] = useState([])
    const [clickedAskQuotation, setClickedAskQuotation] = useState(false)
    const dispatch = useDispatch()

    const getMarketList = async () => {
        try {
            const { data } = await getAllMarket()
            let marketList = []
            data.forEach((item) => {
                const itemObj = { name: item.name, id: item.id }
                marketList.push(itemObj)
            })
            setMarket(marketList)
        } catch ({ response }) {
            if (response.data.message) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    const getCategory = async () => {
        try {
            const { data } = await getAllCategory()
            let allCategory = []
            data.forEach((item) => {
                const itemObj = { name: item.name, id: item.id }
                allCategory.push(itemObj)
            })
            setCategories(allCategory)
        } catch ({ response }) {
            if (response.data.message) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    useEffect(() => {
        getMarketList()
        getCategory()
    }, [])

    const handleUploadTechPackFiles = async (e) => {
        let { files: fileList } = e.target
        let listOfFiles = []
        for (let file of fileList) {
            let base64String = await readFileAsync(file)
            const uploadBody = {
                base64Str: base64String,
                documentType: 'TECH_PACK_DESIGN',
                docMimeType: file.type,
                name: file.name
            }
            dispatch(openLoader())
            const response = await styleDocUpload(uploadBody)
            listOfFiles.push({
                identifier: response?.identifier,
                designDocuments: [response?.productDesignDocResponse],
                name: response?.productDesignDocResponse?.name,
                productGroupId: null,
                productSubCategoryId: null,
                quantity: null,
                productDesignId: response?.productDesignDocResponse?.id,
                techpackId: response?.techpackDocResponse?.id || null,
                comment: ''
            })
        }
        setFiles([...files, ...listOfFiles])
    }

    const handleMarketChange = (options, identifier) => {
        const oldFileList = [...files]
        const selectedIndex = files.findIndex(
            (el) => el.identifier === identifier
        )
        oldFileList[selectedIndex].productGroupId = options
        let updatedState = errorState.map((item) => {
            if (item?.identifier === identifier) {
                return {
                    ...item,
                    productGroupId: false
                }
            }
            return item
        })
        setErrorState(updatedState)
        setFiles(oldFileList)
    }

    const handleCategoryChange = (options, identifier) => {
        const oldFileList = [...files]
        const selectedIndex = files.findIndex(
            (el) => el.identifier === identifier
        )
        oldFileList[selectedIndex].productSubCategoryId = options
        let updatedState = errorState.map((item) => {
            if (item?.identifier === identifier) {
                return {
                    ...item,
                    productSubCategoryId: false
                }
            }
            return item
        })
        setErrorState(updatedState)
        setFiles(oldFileList)
    }

    const handleStyleName = (e, identifier) => {
        const oldFileList = [...files]
        const i = oldFileList.findIndex((el) => el.identifier === identifier)
        oldFileList[i].styleName = e.target.value
        setFiles(oldFileList)
    }

    const onRemoveFile = (identifier) => {
        const remainedfileList = files.filter(
            (file) => file.identifier !== identifier
        )
        setFiles(remainedfileList)
    }

    const styleDocUpload = async (file) => {
        try {
            const { data: uploadRes } = await uploadStyleDoc(file)
            dispatch(closeLoader())
            return { ...uploadRes, identifier: generateUniqueId() }
        } catch ({ response }) {
            if (response) toast(response.data.message)
            else toast('Error')
        }
    }

    const isError = (type) => {
        const errors = files.reduce((acc, product) => {
            if (
                product.productGroupId === null ||
                product.productSubCategoryId === null
            ) {
                const errorObject = {
                    identifier: product.identifier,
                    productGroupId: product.productGroupId === null,
                    productSubCategoryId: product.productSubCategoryId === null
                }
                acc.push(errorObject)
            }
            return acc
        }, [])
        if (errors?.length > 0) setErrorState(errors)
        else {
            if (type === 'QUOTE') setClickedAskQuotation(true)
            else setShowAskSampleModal(true)
        }
    }

    function hasError(type, identifier) {
        const error = errorState.find(
            (error) => error.identifier === identifier
        )
        return error ? error[type] === true : false
    }

    const renderTabView = () => {
        return (
            <div className='tab-view-table py-2'>
                <div className='all-rows'>
                    {files.map((file, idx) => (
                        <div className='single-row' key={idx}>
                            <table className='table'>
                                <tr>
                                    <td
                                        rowSpan='4'
                                        className='w-[56px] max-w-[56px]'
                                    >
                                        <span
                                            className='cursor-pointer'
                                            onClick={() =>
                                                onRemoveFile(file?.identifier)
                                            }
                                        >
                                            <IconCollectionRemove />
                                        </span>
                                    </td>

                                    <td className='heading'>Techpack</td>
                                    <td>
                                        <Tooltip
                                            title={'design-list-1.png'}
                                            placement={'top'}
                                            arrow
                                        >
                                            <span className='regular-14 black'>
                                                {getShortName(
                                                    'design-list-1.png',
                                                    20
                                                )}
                                            </span>
                                        </Tooltip>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Style name</td>
                                    <td>
                                        <input
                                            type='text'
                                            className='max-w-[180px]'
                                            name='styleName'
                                            onChange={(e) =>
                                                handleStyleName(
                                                    e,
                                                    file.identifier
                                                )
                                            }
                                            value={
                                                files.filter(
                                                    (el) =>
                                                        el.identifier ===
                                                        file.identifier
                                                ).styleName
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Market*</td>
                                    <td>
                                        <SelectComponent
                                            placeholder={'SELECT MARKET'}
                                            onSelect={(options) =>
                                                handleMarketChange(
                                                    options,
                                                    file.identifier
                                                )
                                            }
                                            selected={{
                                                name: file?.productGroupId?.name
                                            }}
                                            options={market}
                                            required={hasError(
                                                'productGroupId',
                                                file.identifier
                                            )}
                                            label={'Select'}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Category*</td>
                                    <td>
                                        <SelectComponent
                                            placeholder={'SELECT CATEGORY'}
                                            onSelect={(options) =>
                                                handleCategoryChange(
                                                    options,
                                                    file.identifier
                                                )
                                            }
                                            selected={{
                                                name: file?.productSubCategoryId
                                                    ?.name
                                            }}
                                            options={categories}
                                            required={hasError(
                                                'productSubCategoryId',
                                                file.identifier
                                            )}
                                            label={'Select'}
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <ModalContainer
            className='upload-design-modal right-sidebar'
            onCloseModal={onCloseModal}
        >
            <div className='common-popup'>
                <div className='common-popup-header d-flex justify-content-between'>
                    <div className='popup-tilte'>
                        <h2 className='text-xl lg:text-[28px] weight-700 mb-0'>
                            Ask for quote/sample
                        </h2>
                    </div>
                    <div
                        className='close-btn cursor-pointer'
                        onClick={onCloseModal}
                    >
                        <CloseIcon />
                    </div>
                </div>

                <div className='common-popup-body p-4'>
                    <div>
                        <h3 className='text-base lg:text-xl weight-700'>
                            Step 1: Upload design
                        </h3>
                        <p className='regular-12 gray-300'>
                            Please share your design with the Nitex team
                        </p>
                    </div>
                    <div className='form-group pt-3 max-w-[432px]'>
                        <div className='file'>
                            <input
                                id='input-file-quote'
                                type='file'
                                multiple
                                onClick={(e) => (e.target.value = null)}
                                onChange={handleUploadTechPackFiles}
                            />
                            <label
                                htmlFor='input-file-quote'
                                className='w-full !flex justify-between'
                            >
                                <span className='mr-4'>Upload techpack</span>
                                <UploadIcon />
                            </label>
                        </div>
                    </div>

                    <table className='table upload-design-table mt-8'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Techpack</th>
                                <th>Style name</th>
                                <th>Market*</th>
                                <th>Category*</th>
                            </tr>
                        </thead>
                        <tbody>
                            {files.map((file, idx) => (
                                <tr key={idx}>
                                    <td className='text-center w-[72px] max-w-[72px]'>
                                        <span
                                            className='cursor-pointer'
                                            onClick={() =>
                                                onRemoveFile(file?.identifier)
                                            }
                                        >
                                            <DeleteIcon />
                                        </span>
                                    </td>
                                    <td>
                                        <Tooltip
                                            title={file.name}
                                            placement={'top'}
                                            arrow
                                        >
                                            <span className='regular-14 black'>
                                                {getShortName(file.name, 20)}
                                            </span>
                                        </Tooltip>
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            className='max-w-[180px]'
                                            name='styleName'
                                            onChange={(e) =>
                                                handleStyleName(
                                                    e,
                                                    file.identifier
                                                )
                                            }
                                            value={
                                                files.filter(
                                                    (el) =>
                                                        el.identifier ===
                                                        file.identifier
                                                ).styleName
                                            }
                                        />
                                    </td>
                                    <td className='market-column'>
                                        <SelectComponent
                                            placeholder={'SELECT MARKET'}
                                            onSelect={(options) =>
                                                handleMarketChange(
                                                    options,
                                                    file.identifier
                                                )
                                            }
                                            selected={{
                                                name: file?.productGroupId?.name
                                            }}
                                            options={market}
                                            required={hasError(
                                                'productGroupId',
                                                file.identifier
                                            )}
                                            label={'Select'}
                                        />
                                    </td>
                                    <td className='category-column'>
                                        <SelectComponent
                                            placeholder={'SELECT CATEGORY'}
                                            onSelect={(options) =>
                                                handleCategoryChange(
                                                    options,
                                                    file.identifier
                                                )
                                            }
                                            selected={{
                                                name: file?.productSubCategoryId
                                                    ?.name
                                            }}
                                            options={categories}
                                            required={hasError(
                                                'productSubCategoryId',
                                                file.identifier
                                            )}
                                            label={'Select'}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {renderTabView()}

                    <button
                        disabled={files?.length === 0}
                        className='button primary mt-5'
                        onClick={() => isError('QUOTE')}
                    >
                        Ask for quote
                    </button>
                    <button
                        disabled={files?.length === 0}
                        className='button primary mt-5 ml-3'
                        onClick={() => isError('SAMPLE')}
                    >
                        Ask for sample
                    </button>
                </div>
            </div>
            {clickedAskQuotation && (
                <RequestForQuotes
                    selectedProductList={files}
                    type='ASK_QUOTATION_OR_SAMPLE'
                    onCLose={() => {
                        setClickedAskQuotation(false)
                    }}
                    onSuccess={() => onCloseModal()}
                />
            )}
            {showAskSampleModal && (
                <RequestForSample
                    selectedProductList={files?.map((item) => ({
                        ...item,
                        size: 'M',
                        quantity: 1
                    }))}
                    type='ASK_QUOTATION_OR_SAMPLE'
                    onCLose={() => {
                        setShowAskSampleModal(false)
                    }}
                    onSuccess={() => onCloseModal()}
                />
            )}
        </ModalContainer>
    )
}

export default AskQuoteSample
