import React, { useState } from 'react'
import TopFilter from '../common/TopFilter'
import { ReactComponent as FavouriteIcon } from '../../../icons/favorite.svg'
import { ReactComponent as ArrowRightIcon } from '../../../icons/arrow-right.svg'
import { ReactComponent as ArrowDownIcon } from '../../../icons/arrow-down.svg'
import { ReactComponent as ScrollTopIcon } from '../../../icons/scroll-top.svg'
import { ReactComponent as PlusIcon } from '../../../icons/plus-round.svg'
import { ReactComponent as MinusIcon } from '../../../icons/minus-round.svg'
import { ReactComponent as ChatIcon } from '../../../icons/chat.svg'
import { ReactComponent as CloseIcon } from '../../../icons/close.svg'
import { ReactComponent as CameraIcon } from '../../../icons/camera.svg'
import { ReactComponent as ClockIcon } from '../../../icons/clock.svg'
import ModalContainer from '../../../../app/common/modal/ModalContainer'
import AddToFavoritePopup from '../common/AddToFavoritePopup'

const ExploreDesign = () => {
    const [showFilter, setShowFilter] = useState(false)
    const [showZoomModal, setShowZoomModal] = useState(false)
    const [showRangeModal, setShowRangeModal] = useState(false)
    const [showSearchModal, setShowSearchModal] = useState(true)

    const renderModalDesign = () => (
        <div className='common-popup'>
            <div className='common-popup-header d-flex justify-content-between'>
                <div className='popup-tilte'>
                    <h3 className='semibold-16 mb-0'>Category</h3>
                </div>
                <div className='close-btn'>
                    <img src='/icons/close.svg' alt='close' />
                </div>
            </div>

            <div className='common-popup-body p-4'>
                <div className='filter-steps'>
                    <div className='single-step'>
                        <p className='regular-14 d-flex justify-between items-center'>
                            <span className='mr-2'>SHIRTS</span>
                            <ArrowRightIcon />
                        </p>
                        <p className='regular-14 d-flex justify-between items-center'>
                            PANTS
                        </p>
                        <p className='regular-14 d-flex justify-between items-center'>
                            JACKET
                        </p>
                        <p className='regular-14 d-flex justify-between items-center'>
                            TEE
                        </p>
                        <p className='regular-14 d-flex justify-between items-center'>
                            VEST
                        </p>
                        <p className='regular-14 d-flex justify-between items-center'>
                            SWEATSHIRT
                        </p>
                        <p className='regular-14 d-flex justify-between items-center'>
                            COATS
                        </p>
                    </div>
                    <div className='single-step'>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    All
                                </label>
                            </div>
                            <ArrowRightIcon />
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    Button down shirt
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    Cleric Shirt
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    Resort
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    PULLOVER SHIRT
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    EPAUETTE SHIRT
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    WESTERN SHIRT
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    SAFARI SHIRT
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='single-step'>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    Rib
                                </label>
                            </div>
                            <ArrowRightIcon />
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    Interlock
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    Ponte
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    Dobble Knit
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    Ribbed
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    Basket
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    Dobby
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-between items-center mb-5'>
                            <div className='d-flex items-center'>
                                <input
                                    type='checkbox'
                                    name='categories'
                                    id='category_7'
                                    value=''
                                />
                                <label
                                    htmlFor='category_7'
                                    className='regular-14 ml-2'
                                >
                                    Jersey
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    const handleClose = () => {
        setShowZoomModal(false)
        setShowRangeModal(false)
    }

    return (
        <>
            <TopFilter />
            <div className='page-content relative'>
                {/* Added to favorite popup */}
                <AddToFavoritePopup />
                <div className='design-list-container'>
                    {Array.from({ length: 24 }).map((_, i) => (
                        <div
                            className='single-card-item'
                            onClick={() => setShowZoomModal(true)}
                        >
                            <div className='style-photo'>
                                <img
                                    src='/images/single-design.png'
                                    alt='design'
                                    className='design-image'
                                />
                            </div>
                            <div className='style-info'>
                                <div className='info'>
                                    <p className='regular-18 weight-500'>
                                        Stripe polo shirt
                                    </p>
                                    <ul className='common-list-component mt-1'>
                                        <li className='regular-14'>TEE</li>
                                        <li className='regular-14'>Woven</li>
                                    </ul>
                                </div>
                                <div className='favorite'>
                                    <button className='button secondary icon-only'>
                                        <FavouriteIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='more-design mt-16 mb-8 d-flex justify-center items-center'>
                    <p className='regular-16 mr-6'>
                        Do you want to explore more designs?
                    </p>
                    <button className='button primary mr-3'>Yes</button>
                    <button className='button secondary'>No</button>
                </div>

                {/* Scroll top button */}
                <button
                    className='button link scrollTop-btn'
                    onClick={scrollTop}
                >
                    <ScrollTopIcon />
                </button>
            </div>

            {showFilter && (
                <ModalContainer
                    className='category-filter-modal'
                    onCloseModal={() => setShowFilter(false)}
                >
                    {renderModalDesign()}
                </ModalContainer>
            )}

            {/* Zoom modal */}
            {showZoomModal && (
                <ModalContainer
                    className='style-zoom-modal right-sidebar'
                    // onCloseModal={() => toggleModel(null)}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>MJY230463</h3>
                                <ul className='common-list-component mt-2'>
                                    <li>TEE</li>
                                    <li>Woven</li>
                                </ul>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <div className='d-flex justify-between'>
                                <div className='left d-flex'>
                                    <div className='single-info mr-10'>
                                        <p className='regular-14 gray_300'>
                                            Item
                                        </p>
                                        <h3 className='regular-18 weight-700'>
                                            Basic Tee
                                        </h3>
                                    </div>
                                    <div className='single-info mr-10'>
                                        <p className='regular-14 gray_300'>
                                            Fabric
                                        </p>
                                        <h3 className='regular-18 weight-700'>
                                            Cotton
                                        </h3>
                                    </div>
                                </div>
                                <div className='right d-flex'>
                                    <div className='single-info mr-10'>
                                        <p className='regular-14 gray_300'>
                                            Lead Time
                                        </p>
                                        <h3 className='regular-18 weight-700'>
                                            30 days
                                        </h3>
                                    </div>
                                    <div className='single-info mr-10'>
                                        <p className='regular-14 gray_300'>
                                            MOQ
                                        </p>
                                        <h3 className='regular-18 weight-700'>
                                            300 Unit
                                        </h3>
                                    </div>
                                    <div className='single-info'>
                                        <p className='regular-14 gray_300'>
                                            Price
                                        </p>
                                        <h3 className='regular-18 weight-700'>
                                            $3.5
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div className='style-and-fabric'>
                                <div className='single-image front'>
                                    <img src='/images/single-design.png' />
                                </div>
                                <div className='single-image back'>
                                    <img src='/images/single-design.png' />
                                </div>
                                <div className='single-image fabric'>
                                    <img src='/images/fabric.png' />
                                </div>
                                <div className='single-image fabric'>
                                    <img src='/images/fabric-2.png' />
                                </div>
                            </div>
                        </div>
                        <div className='common-popup-footer'>
                            <button className='button secondary icon-only mr-3'>
                                <ChatIcon />
                            </button>
                            <button className='button secondary'>
                                <span className='mr-2'>Button</span>
                                <FavouriteIcon />
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}

            {/* Range modal */}
            {showRangeModal && (
                <ModalContainer
                    className='build-range-modal right-sidebar'
                    // onCloseModal={() => toggleModel(null)}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Build Your Range
                                </h3>
                                <p className='regular-12 gray_300'>
                                    Create your own range
                                </p>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <div className='grid grid-cols-2 gap-2'>
                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Category
                                    </label>

                                    <div>
                                        <div className='select-component'>
                                            <button className='click-box click mb-1'>
                                                <p className='regular-14 '>
                                                    Select brand
                                                </p>
                                                <ArrowDownIcon />
                                            </button>
                                            <div className='dropdown-box dropdown-menu scroll-y-label hidden'>
                                                <div className='list-items scroll-y-label'>
                                                    <div className='single-item'>
                                                        <p>brand 1</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>brand 2</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>brand 3</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>brand 4</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>brand 5</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Number of style
                                    </label>
                                    <div className='flex items-center'>
                                        <input
                                            type='number'
                                            name='nos'
                                            placeholder=''
                                        />
                                        <button
                                            className='button primary icon-only ml-2'
                                            disabled
                                        >
                                            <MinusIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-2'>
                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Category
                                    </label>

                                    <div>
                                        <div className='select-component'>
                                            <button className='click-box click mb-1'>
                                                <p className='regular-14 '>
                                                    Select brand
                                                </p>
                                                <ArrowDownIcon />
                                            </button>
                                            <div className='dropdown-box dropdown-menu scroll-y-label hidden'>
                                                <div className='list-items scroll-y-label'>
                                                    <div className='single-item'>
                                                        <p>Item 1</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 2</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 3</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 4</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 5</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Number of style
                                    </label>
                                    <div className='flex items-center'>
                                        <input
                                            type='number'
                                            name='nos'
                                            placeholder=''
                                        />
                                        <button className='button primary icon-only ml-2'>
                                            <PlusIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-2'>
                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Category
                                    </label>

                                    <div>
                                        <div className='select-component'>
                                            <button className='click-box click mb-1'>
                                                <p className='regular-14 '>
                                                    Select brand
                                                </p>
                                                <ArrowDownIcon />
                                            </button>
                                            <div className='dropdown-box dropdown-menu scroll-y-label hidden'>
                                                <div className='list-items scroll-y-label'>
                                                    <div className='single-item'>
                                                        <p>Item 1</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 2</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 3</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 4</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 5</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Number of style
                                    </label>
                                    <div className='flex items-center'>
                                        <input
                                            type='number'
                                            name='nos'
                                            placeholder=''
                                        />
                                        <button className='button primary icon-only ml-2'>
                                            <PlusIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='more-btn mb-3'>
                                <button className='button link'>
                                    More options
                                </button>
                            </div>
                            <div className='grid grid-cols-2 gap-2'>
                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Season
                                    </label>

                                    <div>
                                        <div className='select-component'>
                                            <button className='click-box click mb-1'>
                                                <p className='regular-14 '>
                                                    Select Season
                                                </p>
                                                <ArrowDownIcon />
                                            </button>
                                            <div className='dropdown-box dropdown-menu scroll-y-label hidden'>
                                                <div className='list-items scroll-y-label'>
                                                    <div className='single-item'>
                                                        <p>Item 1</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 2</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 3</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 4</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 5</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='regular-14 gray_300 mb-2'>
                                        Fabric
                                    </label>
                                    <div className='flex items-center'>
                                        <div className='select-component'>
                                            <button className='click-box click mb-1'>
                                                <p className='regular-14 '>
                                                    Select Fabric
                                                </p>
                                                <ArrowDownIcon />
                                            </button>
                                            <div className='dropdown-box dropdown-menu scroll-y-label hidden'>
                                                <div className='list-items scroll-y-label'>
                                                    <div className='single-item'>
                                                        <p>Item 1</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 2</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 3</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 4</p>
                                                    </div>
                                                    <div className='single-item'>
                                                        <p>Item 5</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className='button primary icon-only ml-2 invisible'>
                                            <PlusIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='checkboxes flex mt-2'>
                                <div className='flex items-center mr-6'>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='Print'
                                        value=''
                                    />
                                    <label
                                        htmlFor='Print'
                                        className='regular-14 ml-2'
                                    >
                                        Print
                                    </label>
                                </div>
                                <div className='flex items-center mr-6'>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='EMB'
                                        value=''
                                    />
                                    <label
                                        htmlFor='EMB'
                                        className='regular-14 ml-2'
                                    >
                                        EMB
                                    </label>
                                </div>
                                <div className='flex items-center mr-6'>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='Wash'
                                        value=''
                                    />
                                    <label
                                        htmlFor='Wash'
                                        className='regular-14 ml-2'
                                    >
                                        Wash
                                    </label>
                                </div>
                                <div className='flex items-center'>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='DYE'
                                        value=''
                                    />
                                    <label
                                        htmlFor='DYE'
                                        className='regular-14 ml-2'
                                    >
                                        DYE
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='common-popup-footer'>
                            <button className='button primary'>
                                <span>Re-build</span>
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}
            {/* Range modal */}
            {showSearchModal && (
                <ModalContainer
                    className='search-bar-modal'
                    onCloseModal={() => setShowSearchModal(false)}
                >
                    <div className='common-popup'>
                        <div className='common-popup-body p-4 relative'>
                            <div className='serach-bar'>
                                <input
                                    type='text'
                                    placeholder='Search from design lab'
                                    className='no-border'
                                />
                                <span className='upload'>
                                    <CameraIcon />
                                </span>
                                <div className='suggestion-list'>
                                    <ul>
                                        <li className='flex items-center'>
                                            <ClockIcon />
                                            <span className='text-sm gray_300 ml-1'>
                                                Men’s T-shirt
                                            </span>
                                        </li>
                                        <li className='flex items-center'>
                                            <ClockIcon />
                                            <span className='text-sm gray_300 ml-1'>
                                                Men’s T-shirt
                                            </span>
                                        </li>
                                        <li className='flex items-center'>
                                            <ClockIcon />
                                            <span className='text-sm gray_300 ml-1'>
                                                Men’s T-shirt
                                            </span>
                                        </li>
                                        <li className='flex items-center'>
                                            <ClockIcon />
                                            <span className='text-sm gray_300 ml-1'>
                                                Men’s T-shirt
                                            </span>
                                        </li>
                                        <li className='flex items-center'>
                                            <ClockIcon />
                                            <span className='text-sm gray_300 ml-1'>
                                                Men’s T-shirt
                                            </span>
                                        </li>
                                        <li className='flex items-center'>
                                            <ClockIcon />
                                            <span className='text-sm gray_300 ml-1'>
                                                Men’s T-shirt
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <button
                                className='button link absolute right-8 top-6'
                                onClick={() => setShowSearchModal(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    )
}

export default ExploreDesign
