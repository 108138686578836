import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ReactComponent as SearchIconWhite } from '../../../assets/images/search-white.svg'
import { isPageReachBottom } from '../../helpers/Util'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import OrderThunks from '../../redux_toolkit/Order/OrderThunks'
import { GET_ORDER_LIST } from '../../redux_toolkit/@types/thunk.types'
import { useOrderSelector } from '../../redux_toolkit/Order'
import ListOfOrder from './ListOfOrder'
import { useLoader } from '../../redux_toolkit/Loader'

const MyOrderList = ({ total }) => {
    const orderStore = useOrderSelector()
    const dispatch = useDispatch()
    const myStateRef = useRef({})
    const [search, setSearch] = useState('')
    const globalLoader = useLoader()
    const isLoading = useRef(null)
    const navigate = useNavigate()
    const location = useLocation()
    const activeStatusParam = new URLSearchParams(location.search).get('status')
    const [activeTab, setActiveTab] = useState(activeStatusParam)

    const setIsDataLoading = (data) => {
        isLoading.current = data
    }

    useEffect(() => {
        setIsDataLoading(globalLoader)
    }, [globalLoader])

    useEffect(() => {
        if (orderStore) {
            myStateRef.current = { ...myStateRef.current, ...orderStore }
            setActiveTab(orderStore?.activeTab)
        }
    }, [orderStore])

    const getTabName = {
        COMPLETED: 'COMPLETED,ON_SHIPMENT',
        PENDING: 'PENDING,INITIALIZED',
        RUNNING: 'RUNNING',
        CANCELLED: 'CANCELLED'
    }

    const generateParams = (tabName, page, searchParam) => {
        let tab = getTabName[tabName]
        if (tab !== getTabName['CANCELLED']) {
            let params = `?size=16&sort=id,desc&filterBy=${tab}&page=${page}`
            if (searchParam) {
                params += `&search=${searchParam}&poNumber=${searchParam}`
            }
            return params
        } else {
            let params = `?size=16&sort=id,desc&filterBy=INITIALIZED&approvalStatus=${tab}&page=${page}`
            if (searchParam) {
                params += `&search=${searchParam}&poNumber=${searchParam}`
            }
            return params
        }
    }

    useEffect(() => {
        dispatch(openLoader())
        let params = generateParams(activeTab, 0)
        dispatch(OrderThunks[GET_ORDER_LIST](params, false, activeTab)).finally(
            () => dispatch(closeLoader())
        )
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleScroll = () => {
        if (isPageReachBottom()) {
            let { totalElements, currentPage, data } =
                myStateRef.current.orderResponse
            let activeTabName = myStateRef.current.activeTab
            if (totalElements > 0) {
                if (totalElements !== data.length && !isLoading.current) {
                    dispatch(openLoader())
                    let params = generateParams(
                        activeTabName,
                        currentPage + 1,
                        myStateRef.current.searchParam
                    )
                    dispatch(
                        OrderThunks[GET_ORDER_LIST](params, true, activeTabName)
                    ).finally(() => dispatch(closeLoader()))
                }
            }
        }
    }

    const changeTab = (value) => {
        window.scrollTo(0, 0)
        dispatch(openLoader())
        let params = generateParams(value, 0, myStateRef.current.searchParam)
        dispatch(OrderThunks[GET_ORDER_LIST](params, false, value)).finally(
            () => dispatch(closeLoader())
        )
        navigate(`/dashboard?active=ORDER&status=${value}`)
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
        myStateRef.current = { ...orderStore, searchParam: e.target.value }
        changeTab(activeTab)
    }

    const renderEmptyState = () => {
        if (orderStore?.orderResponse?.data?.length === 0) {
            return (
                <div className='w-full'>
                    <div className='py-40 text-center max-w-[600px] m-auto'>
                        <h1 className='regular-20 weight-700 gray_400 mb-2'>
                            No order found
                        </h1>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            <div className='order-list-page-container'>
                <div className='tab-and-search flex items-center justify-between'>
                    <div>
                        <ul className='common-tab on-order'>
                            <li
                                className={
                                    activeTab === 'RUNNING' ? 'active' : ''
                                }
                                onClick={() => changeTab('RUNNING')}
                            >
                                <span className='task-name regular-14'>
                                    Running ({total?.order?.RUNNING})
                                </span>
                            </li>
                            <li
                                className={
                                    activeTab === 'PENDING' ? 'active' : ''
                                }
                                onClick={() => changeTab('PENDING')}
                            >
                                <span className='task-name regular-14'>
                                    Pending ({total?.order?.PENDING})
                                </span>
                            </li>
                            <li
                                className={
                                    activeTab === 'COMPLETED' ? 'active' : ''
                                }
                                onClick={() => changeTab('COMPLETED')}
                            >
                                <span className='task-name regular-14'>
                                    Completed ({total?.order?.COMPLETED})
                                </span>
                            </li>

                            <li
                                className={
                                    activeTab === 'CANCELLED' ? 'active' : ''
                                }
                                onClick={() => changeTab('CANCELLED')}
                            >
                                <span className='task-name regular-14'>
                                    Cancelled ({total?.order?.CANCELLED})
                                </span>
                            </li>
                        </ul>
                    </div>

                    {/* <div className='search-div'>
                        <div className='flex'>
                            <input
                                type='text'
                                className='form-field w-auto h-[48px] 5xl:h-[60px] border border-primaryColor bg-transparent  p-2 px-4'
                                id='name'
                                placeholder='Search your order'
                                name='search'
                                value={search}
                                onChange={handleSearch}
                            />
                            <button
                                type='button'
                                className='btn w-[48px] 5xl:w-[60px] h-[48px] 5xl:h-[60px] flex items-center justify-center p-2'
                            >
                                <SearchIconWhite />
                            </button>
                        </div>
                    </div> */}
                </div>
                <ListOfOrder orderStore={orderStore} activeTab={activeTab} />
                {renderEmptyState()}
            </div>
        </>
    )
}

export default MyOrderList
