import React, { useEffect, useState } from 'react'
import { useFilterOption } from '../../../redux_toolkit/V3/Filter/FilterOptionReducers'
import { FilterActionTypes, useFilter } from '../../../redux_toolkit/V3/Filter'
import { useDispatch } from 'react-redux'
import { SET_FILTER_FIELD } from '../../../redux_toolkit/@types/action.types'
import { ReactComponent as SearchIconWhite } from '../../../../assets/images/search-white.svg'

const Season = () => {
    const [seasons, setSeasons] = useState([])
    const options = useFilterOption()
    const filter = useFilter()
    const dispatch = useDispatch()

    const handleCheckbox = async (e, item) => {
        let data = {
            name: e.target.name,
            value: item,
            type: e.target.type,
            checked: e.target.checked
        }
        await dispatch(FilterActionTypes[SET_FILTER_FIELD](data))
    }

    const isSeasonChecked = (item) => {
        if (filter?.seasons) {
            let isSeasonExist = false
            for (let season of filter.seasons) {
                if (season.constant === item.constant) {
                    isSeasonExist = true
                    break
                }
            }
            return isSeasonExist
        } else {
            return false
        }
    }

    const renderSeason = () => {
        return seasons?.map((item) => {
            return (
                <div
                    className='flex items-start'
                    key={`seasons_${item.constant}`}
                >
                    <span>
                        <input
                            type='checkbox'
                            id={`season_${item.constant}`}
                            value={item.constant}
                            onChange={(e) => handleCheckbox(e, item)}
                            checked={isSeasonChecked(item)}
                            name='seasons'
                        />
                    </span>
                    <label
                        htmlFor={`season_${item.constant}`}
                        className='align-middle pl-4 inline-block mt-[-3px]'
                    >
                        {item.name}
                    </label>
                </div>
            )
        })
    }

    useEffect(() => {
        setSeasons(options.seasons)
    }, [options.seasons])

    const handleChange = (e) => {
        if (e.target.value) {
            let searchedSeasons = options.seasons.filter(
                (item) =>
                    item.name
                        .toUpperCase()
                        .indexOf(e.target.value.toUpperCase()) !== -1
            )
            setSeasons(searchedSeasons)
        } else {
            setSeasons(options.seasons)
        }
    }

    return (
        <div className='border-r border-b last:border-r-none border-white-shade-100 py-6 px-10'>
            <span className='text-primaryColor-shade-100'>Season</span>
            <div className='mt-6'>
                <div className='flex'>
                    <input
                        type='text'
                        className='form-field border border-primaryColor h-[40px] p-2 px-4'
                        id='name'
                        placeholder='Search ...'
                        name='name'
                        onChange={handleChange}
                    />
                    <button type='button' className='btn h-[40px] p-2 flex'>
                        <SearchIconWhite />
                    </button>
                </div>
            </div>
            <div className='mt-6 space-y-8 custom-scroll overflow-y-auto max-h-[250px] 4xl:max-h-[400px] pt-2'>
                {renderSeason()}
            </div>
        </div>
    )
}

export default Season
