import React from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { Stomp } from '@stomp/stompjs'
import { getBaseWebSocketUrl } from '../constant'

const capitalizeFirstLetter = (str) =>
    str?.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    )

const replaceSpace = (str) => str.split(' ').join('_')
export const VALID_FILE_SIZE = 12582912

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

const isTokenExpired = (token) => {
    let base64Url = token.split('.')[1]
    if (base64Url === undefined) return true
    let base64 = base64Url.replace('-', '+')
    if (base64 === undefined) return true
    base64 = base64.replace('_', '/')
    if (base64 === undefined) return true
    let json = JSON.parse(window.atob(base64))
    if (json === undefined) return true
    let exp = JSON.parse(window.atob(base64)).exp
    if (exp === undefined) return true

    exp *= 1000

    return exp <= new Date().getTime()
}
const getDateFromMillis = (millis) => {
    let date = new Date(millis)
    return (
        (date.getDate() < 10 ? '0' : '') +
        date.getDate() +
        '-' +
        months[date.getMonth()] +
        '-' +
        date.getFullYear()
    )
}

const getDateWithHourFromMillis = (millis) => {
    let d = new Date(millis)
    return (
        ('0' + d.getDate()).slice(-2) +
        '-' +
        ('0' + (d.getMonth() + 1)).slice(-2) +
        '-' +
        d.getFullYear() +
        ' ' +
        ('0' + d.getHours()).slice(-2) +
        ':' +
        ('0' + d.getMinutes()).slice(-2)
    )
}

const doCommaSeparationWithIntegers = (amount) => {
    if (amount === null || amount === undefined) return '-'
    amount = amount.toString()
    if (amount.length <= 3) {
        return amount
    }
    let formattedAmount = ''
    for (let i = amount.length - 1, p = 0; i >= 0; i--, p++) {
        if (p >= 3 && (p - 3) % 2 === 0)
            formattedAmount = ','.concat(formattedAmount)
        formattedAmount = amount[i].concat(formattedAmount)
    }
    return formattedAmount
}

const convertToISODate = (dateObj) => {
    let date = dateObj.getFullYear()
    date += '-'
    if (dateObj.getMonth() < 9) date += '0' + (dateObj.getMonth() + 1) + '-'
    else date += dateObj.getMonth() + 1 + '-'
    if (dateObj.getDate() < 10) date += '0'
    date += dateObj.getDate()
    return date
}

const getOneWeekAgoMillis = () => {
    let start = new Date()
    start.setHours(0, 0, 0, 0)
    return start.getTime() - 7 * 24 * 60 * 60 * 1000
}

const convertToSelectOptions = (obj) => {
    let array = []
    for (let i in obj) {
        array.push({ value: parseInt(i, 10), label: obj[i] })
    }
    return array
}

const doCommaSeparationWithDecimals = (amount) => {
    if (amount === null || amount === undefined) return '-'
    amount = amount.toString()
    let minusFound = false
    if (amount.startsWith('-')) {
        minusFound = true
        amount = amount.substr(1)
    }
    amount = parseFloat(amount).toFixed(2)

    let lastIndex = amount.lastIndexOf('.')
    let formattedAmount = amount.substring(lastIndex)

    for (let i = lastIndex - 1, p = 0; i >= 0; i--, p++) {
        if (p >= 3 && (p - 3) % 2 === 0)
            formattedAmount = ','.concat(formattedAmount)
        formattedAmount = amount[i].concat(formattedAmount)
    }

    if (minusFound) formattedAmount = '-' + formattedAmount
    return formattedAmount
}

const convertToDateFromMiliSeconds = (input) => {
    return new Date(input).toLocaleDateString('en-GB')
}

const convertToDateTimeFromMiliSeconds = (input) => {
    return new Date(input).toLocaleString('en-GB')
}

const encodeQueryData = (data) => {
    let ret = [],
        temp
    for (let i in data) {
        temp = data[i]
        if (temp !== '' && temp !== null) {
            ret.push(encodeURIComponent(i) + '=' + encodeURIComponent(temp))
        }
    }
    return ret.length ? '?' + ret.join('&') : ''
}

const _getKey = () => {
    return (
        Math.floor(Math.random() * 10000000) +
        Math.floor(Math.random() * 10000000)
    )
}

const getToken = () => {
    let token = ''
    let rememberMe = localStorage.getItem('rememberMe')
    if (parseInt(rememberMe) === 1) {
        token = localStorage.getItem('token')
    } else {
        token = sessionStorage.getItem('token')
    }
    return token
}

const IMAGE_SOURCE = ['cloudfront.net']

const addImageSuffix = (imgUrl, suffix) => {
    if (!imgUrl) {
        return ''
    }
    let flag = true
    IMAGE_SOURCE.map((url) => {
        if (imgUrl.includes(url)) {
            flag = false
        }
    })

    if (flag) {
        return imgUrl
    }

    //checking the scaled image types
    if (!['jpeg', 'jpg', 'png'].includes(getImageExt(imgUrl))) {
        return imgUrl
    }

    let splits = imgUrl.split('.')
    let result = ''
    splits.map((item, i) => {
        if (i === splits.length - 1) {
            result += suffix + '.' + item
        } else if (i === 0) {
            result += item
        } else {
            result += '.' + item
        }
    })
    return result
}

// by Rana
const convertDateAndTimeToLocal = (
    date,
    time = '',
    outputFormat = 'DD.MM.YYYY'
) => {
    let formatForUtc =
        moment(date, 'DD/MM/YYYY').format('MM/DD/YYYY') +
        (time ? ' ' + time : '')
    let convertedDate = moment.utc(formatForUtc).format()
    return moment.utc(convertedDate).local().format(outputFormat)
}

const convertDateTimeToLocal = (
    date,
    time = '',
    outputFormat = 'DD.MM.YYYY'
) => {
    let formattedTime = moment(time, 'HH:mm:ss').format('hh:mm A')
    let formatForUtc =
        moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY') +
        (time ? ' ' + formattedTime : '')
    let convertedDate = moment.utc(formatForUtc).format()
    return moment.utc(convertedDate).local().format(outputFormat)
}
export const convertTimeToLocal = (
    date,
    time = '',
    outputFormat = 'DD.MM.YYYY'
) => {
    // console.log(date, 'GGGG', time, '---', outputFormat);
    let formatForUtc =
        moment(date, 'DD/MM/YYYY').format('MM/DD/YYYY') +
        (time ? ' ' + time : '')
    let convertedDate = moment.utc(formatForUtc).format()
    return moment.utc(convertedDate).local().format(outputFormat)
}
const getTodayTimeDifference = (startDate, endDate = moment()) => {
    let a = moment(startDate)
    let b = moment(endDate)
    let res = b.diff(a, 'minutes')
    if (res < 60) {
        return `${res} minutes ago`
    } else {
        let res = b.diff(a, 'hours')
        return `${res} hours ago`
    }
}

const getUrlParameter = (name, params) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')

    var results = regex.exec(params)
    return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

const changeDateFormat = (
    date,
    currentFormat = 'DD/MM/YYYY',
    newFormat = 'Do MMM, YY'
) => moment(date, currentFormat).format(newFormat)

const parseHtml = (text) => {
    let urlRegex =
        /(\b((https?|ftp|file):\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?)/gi

    return text?.replace(urlRegex, function (url) {
        let newUrl = url.indexOf('http') === -1 ? 'http://' + url : url
        return '<a href="' + newUrl + '">' + url + '</a>'
    })
}

const validateNumber = (e) => {
    const valuesAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    if (!valuesAllowed.includes(e.key)) {
        e.preventDefault()
    }
}

const validateFloatNumber = (e) => {
    const valuesAllowed = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.'
    ]
    if (!valuesAllowed.includes(e.key)) {
        e.preventDefault()
    }
}

const authUserInfo = () => {
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
        userInfo = JSON.parse(userInfo)
    } else {
        userInfo = {}
    }
    return userInfo
}
const isValidJSON = (str) => {
    try {
        JSON.parse(str)
        return true
    } catch (e) {
        return false
    }
}

const getImageExt = (url) => {
    if (!url) {
        return ''
    }
    let splits = url.split('.')
    let result = ''
    if (splits.length) {
        result = splits[splits.length - 1]
    }
    return result
}

const IMAGE_EXTS = [
    'jpeg',
    'jpg',
    'png',
    'gif',
    'tiff',
    'svg',
    'JPEG',
    'JPG',
    'PNG',
    'GIF',
    'TIFF',
    'SVG',
    'webp'
]

const replaceUnderLine = (str) =>
    capitalizeFirstLetter(str?.split('_')?.join(' '))
const sizeInMB = (sizeInBytes) => (sizeInBytes / (1024 * 1024)).toFixed(2)

const isValidFile = (file, type, validSize = VALID_FILE_SIZE) => {
    let ext = file.name.split('.').pop()
    if (
        type === 'PRODUCT_DESIGN' ||
        type === 'REFERENCE_IMAGE' ||
        type === 'IMAGE'
    ) {
        if (IMAGE_EXTS.includes(ext) && file.size < validSize) {
            return true
        }
    } else {
        if (
            (IMAGE_EXTS.includes(ext) ||
                ext === 'xls' ||
                ext === 'xlsx' ||
                ext === 'docx' ||
                ext === 'doc' ||
                ext === 'eps' ||
                ext === 'ai' ||
                ext === 'pdf' ||
                ext === 'ppt' ||
                ext === 'pptx') &&
            file.size < validSize
        ) {
            return true
        }
    }

    return false
}

const parseDate = (date) => {
    let temp = date.split('/')
    return temp[2] + '-' + temp[1] + '-' + temp[0]
}

const dateCompare = (orderDate, dueDate) => {
    const dateA = moment(orderDate)
    const dateB = moment(dueDate)
    const difference = dateB.diff(dateA, 'days')
    if (difference >= 1) {
        return true
    } else {
        return false
    }
}

const DATE_TYPES = [
    'year',
    'quarter',
    'month',
    'week',
    'day',
    'hour',
    'minute',
    'second',
    'millisecond'
]

const addWithCurrentDate = (
    date,
    duration,
    dateType,
    dateFormat = 'Do MMM, YY'
) => {
    if (DATE_TYPES.includes(dateType)) {
        return moment(date).add(duration, dateType).format(dateFormat)
    }
    return false
}

const copy = (obj) => JSON.parse(JSON.stringify(obj))

const getDateDifference = (startDate = moment(), endDate) => {
    const dateA = moment(startDate)
    const dateB = moment(endDate)
    return dateB.diff(dateA, 'days')
}

const getNumberUnit = (value) => {
    if (value < 1e3) return value
    if (value >= 1e3 && value < 1e6) return +(value / 1e3).toFixed(2) + 'K'
    if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(2) + 'M'
    if (value >= 1e9 && value < 1e12) return +(value / 1e9).toFixed(2) + 'B'
    if (value >= 1e12) return +(value / 1e12).toFixed(2) + 'T'
}

const getShortName = (source, size = 35) => {
    return source?.length > size ? source?.slice(0, size - 1) + '…' : source
}
const isPageReachBottom = () => {
    let bool = false
    const windowHeight =
        'innerHeight' in window
            ? window.innerHeight
            : document.documentElement.offsetHeight
    const html = document.documentElement
    const windowBottom = windowHeight + window.pageYOffset
    if (Math.abs(Math.ceil(windowBottom) - Math.ceil(html.scrollHeight)) <= 3) {
        bool = true
    }
    return bool
}

const toOrdinalSuffix = (num) => {
    const int = parseInt(num),
        digits = [int % 10, int % 100],
        ordinals = ['st', 'nd', 'rd', 'th'],
        oPattern = [1, 2, 3, 4],
        tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
        ? int + ordinals[digits[0] - 1]
        : int + ordinals[3]
}

const DOC_EXTS = ['doc', 'docx', 'DOC', 'DOCX']
const XLS_EXTS = ['xls', 'xlsx', 'XLS', 'XLSX']
const PDF_EXTS = ['pdf', 'PDF']
const AI_EXTS = ['ai', 'AI']
const PSD_EXTS = ['ps', 'psd', 'PS', 'PSD']
const EPS_EXTS = ['eps', 'EPS']

const getFileType = (fileName) => {
    if (!fileName || fileName.trim().length === 0) return 'NO_FILE'
    let ext = fileName.split('.').pop()
    if (IMAGE_EXTS.includes(ext)) return 'IMAGE'
    else if (DOC_EXTS.includes(ext)) return 'DOC'
    else if (XLS_EXTS.includes(ext)) return 'XLX'
    else if (PDF_EXTS.includes(ext)) return 'PDF'
    else if (AI_EXTS.includes(ext)) return 'AI'
    else if (PSD_EXTS.includes(ext)) return 'PSD'
    else if (EPS_EXTS.includes(ext)) return 'EPS'
    else return 'FILE'
}
const getIconByFileType = (fileType) => {
    switch (fileType) {
        case 'DOC':
            return '/images/file/doc.svg'
        case 'XLX':
            return '/images/file/xlx.svg'
        case 'PDF':
            return '/images/file/pdf.svg'
        case 'AI':
            return '/images/file/ai.svg'
        case 'PSD':
            return '/images/file/ps.svg'
        case 'EPS':
            return '/images/file/ps.svg'
        case 'FILE':
            return '/images/file/noImage.svg'
        default:
            return '/images/file/file.svg'
    }
}

const openNewWindow = (url) => {
    if (url) {
        if (url.indexOf('_xthumbnail') !== -1) {
            url = url.replace('_xthumbnail', '')
        }
        window.open(url)
    }
}

const getMentionedUserIds = (post, orderMemberList) => {
    let foundEmails = []
    let emailRegex =
        /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
    let match = null
    let ids = []
    while ((match = emailRegex.exec(post))) {
        foundEmails.push(match[0])
        post = post.replace(match[0], '')
    }
    foundEmails.map((email) => {
        orderMemberList.map((member) => {
            if (member.email === email && !ids.includes(member.memberId)) {
                ids.push(member.memberId)
            }
        })
    })
    return ids
}

const mentionModule = (memberList, bool, clear) => {
    if (!bool && !clear) {
        return {
            allowedChars: /^[A-Za-z\s]*$/,
            mentionDenotationChars: ['@'],
            source: async (searchTerm, renderList) => {
                if (searchTerm.length === 0) {
                    renderList(memberList, searchTerm)
                } else {
                    let matches = []
                    for (let i = 0; i < memberList.length; i++) {
                        if (
                            memberList[i].value
                                .toLowerCase()
                                .indexOf(searchTerm.toLowerCase()) !== -1
                        ) {
                            matches.push(memberList[i])
                        }
                    }
                    renderList(matches, searchTerm)
                }
            }
        }
    }
}

const onErrorImageLoad = (e, url, type = 'user') => {
    let image = '/images/default_product.svg'
    if (type === 'user') {
        image = '/images/pro_pic_default.svg'
    }
    fetch(addImageSuffix(url, '')).then(() => {
        image = addImageSuffix(url, '')
    })
    e.target.src = image
    e.target.onerror = null
}

const getRedirectUrl = (data) => {
    if (data.status === 'ACTIVE') {
        return '/home'
    } else if (data.status === 'DISABLED') {
        localStorage.clear()
        sessionStorage.clear()
        return '/login'
    } else if (data.emailVerified === false) {
        return '/activation'
    } else if (data.businessInfoGiven === false) {
        return '/activation'
    } else if (data.status === 'PENDING') {
        return '/activation'
    }
}

const getCurrentLocalDateTime = () => {
    let utcFormat = moment(new Date()).format()
    return encodeURI(utcFormat)
}

const getIconByMimType = (file) => {
    switch (file.docMimeType) {
        case 'DOC':
            return '/images/file-doc.svg'
        case 'XLX':
            return '/images/file-xls.svg'
        case 'application/pdf':
            return '/images/file-pdf.svg'
        case 'AI':
            return '/images/file-ai.svg'
        case 'PSD':
            return '/images/file-ps.svg'
        case 'EPS':
            return '/images/file-eps.svg'
        case 'image/png':
        case 'image/svg+xml':
        case 'image/jpeg':
        case 'image/webp':
            return file.base64Str
        default:
            return '/images/file.svg'
    }
}

const generateFavouriteStylesFilterParams = (filter, params) => {
    if (filter?.categories && filter?.categories.length > 0) {
        let categories = ''
        for (let item of filter.categories) {
            categories += `${item.id},`
        }
        params += `&categoryId=${categories.substring(
            0,
            categories.length - 1
        )}`
    }
    if (filter?.markets && filter?.markets.length > 0) {
        let markets = ''
        for (let item of filter.markets) {
            markets += `${item.id},`
        }
        params += `&productGroupId=${markets.substring(0, markets.length - 1)}`
    }
    if (filter?.seasons && filter?.seasons.length > 0) {
        let seasons = ''
        for (let item of filter.seasons) {
            seasons += `${item.constant},`
        }
        params += `&season=${seasons.substring(0, seasons.length - 1)}`
    }
    if (filter?.sortBy) {
        params += `&sort=${filter.sortBy}`
    } else {
        params += '&sort=id,desc'
    }
    if (filter?.name) {
        params += `&search=${filter.name}`
    }
    return params
}

const generateCollectionFilterParams = (filter, params) => {
    if (filter?.categories && filter?.categories.length > 0) {
        let categories = ''
        for (let item of filter.categories) {
            categories += `${item.id},`
        }
        params += `&categoryId=${categories.substring(
            0,
            categories.length - 1
        )}`
    }
    if (filter?.markets && filter?.markets.length > 0) {
        let markets = ''
        for (let item of filter.markets) {
            markets += `${item.id},`
        }
        params += `&productGroupId=${markets.substring(0, markets.length - 1)}`
    }
    if (filter?.seasons && filter?.seasons.length > 0) {
        let seasons = ''
        for (let item of filter.seasons) {
            seasons += `${item.constant},`
        }
        params += `&season=${seasons.substring(0, seasons.length - 1)}`
    }
    if (filter?.sortBy) {
        params += `&sort=${filter.sortBy}`
    } else {
        params += '&sort=id,desc'
    }
    if (filter?.name) {
        params += `&name=${filter.name}`
    }
    if (filter?.search) {
        params += `&search=${filter.search}`
    }
    return params
}
const convertDateToLocal = (date, outputFormat = 'DD.MM.YYYY') => {
    return moment.utc(date).local().format(outputFormat)
}

const checkSingular = (title, value) => {
    if (parseInt(value) > 1) {
        return `${value + ' ' + title}s`
    }
    return value + ' ' + title
}

const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader()

        reader.onload = () => {
            resolve(reader.result)
        }

        reader.onerror = reject

        reader.readAsDataURL(file)
    })
}

const buildFile = (file, e, docType, onFileUploaded) => {
    if (file) {
        if (isValidFile(file, file?.type, VALID_FILE_SIZE)) {
            let data = {
                name: file?.name,
                docMimeType: file?.type,
                documentType: docType,
                base64Str: ''
            }
            if (e.id) {
                data.id = e.id
            }
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                data.base64Str = reader.result
                onFileUploaded(data)
            }
            reader.onerror = function (error) {
                toast.error(error)
            }
        } else {
            toast.warning('Please upload file less than 12mb')
        }
    }
}

const onUploadFile = (e, docType, onFileUploaded, uploadType = 'SINGLE') => {
    if (uploadType === 'SINGLE') {
        let file = e.target.files[0]
        buildFile(file, e, docType, onFileUploaded)
    } else {
        let oldObj = { ...e.target.files }
        let arr = []
        Object.keys(oldObj).map((item) => {
            arr.push(oldObj[item])
        })

        arr.map((file) => {
            buildFile(file, e, docType, onFileUploaded)
        })
    }
}
const onImageLoadError = (e) => {
    e.target.onerror = null
    e.target.src = '/images/noImage.png'
}

const getUserInfo = () => {
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
        userInfo = JSON.parse(userInfo)
        return userInfo
    } else {
        return null
    }
}
const getUserPreference = () => {
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
        userInfo = JSON.parse(userInfo)
        return userInfo?.systemPreference?.value
            ? JSON.parse(userInfo.systemPreference.value)
            : userInfo.systemPreference
    } else {
        return null
    }
}

const convertNumberToUSFormat = (value, removeFloating) => {
    let formatter
    if (removeFloating) {
        formatter = new Intl.NumberFormat('en-us')
        return formatter.format(Math.floor(value))
    } else {
        formatter = new Intl.NumberFormat('en-us', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        return formatter.format(value)
    }
}

const generateUniqueId = () => {
    return (
        Math.random().toString(16).slice(2) +
        new Date().getTime() +
        Math.random().toString(16).slice(2)
    )
}

const extractContent = (html) => {
    return new DOMParser().parseFromString(html, 'text/html').documentElement
        .textContent
}

const getRoundValue = (originalValue, interval) => {
    if (originalValue > interval) {
        return parseInt((originalValue / interval).toString()) * interval
    } else {
        return originalValue
    }
}

const openBase64InNewTab = (data) => {
    let image = new Image()
    image.src = data
    let w = window.open('', '_blank')
    w.document.write(image.outerHTML)
}

const validateScroll = () => {
    let nodes = document.getElementsByClassName('validate-error')
    if (nodes.length > 0) {
        nodes[0].focus()
    }
}

const ACCESS_TOKEN = () =>
    localStorage.getItem('token') && localStorage.getItem('token').split(' ')[1]

const getStompCLient = () => Stomp.client(getBaseWebSocketUrl())

const onGetLocalFromUtc = (utcTime, outputFormat = 'YYYY-MM-DD HH:mm:ss') => {
    //	utcTime parameter should receive 'YYYY-MM-DD HH:mm:ss' ,
    //	either pass dateTime value directly like this	-> `${2023-03-12T10:03:56}`	or
    //	concat the date and Time (space in middle) like this	-> `${2023-03-12} ${10:03:56}`
    return moment.utc(utcTime).local().format(outputFormat)
}
const getMinsCountFromNow = (dateTime, format) => {
    let res = moment().valueOf() - moment(dateTime, format).valueOf()
    if (res) {
        return parseInt(res / (1000 * 60))
    }
    return 0
}

const getTimeCount = (mins, createdAt) => {
    let hrs = parseInt(mins / 60)
    let today = moment()
    let yesterday = moment().subtract(1, 'day')

    if (mins < 1) {
        return 'Just now'
    } else if (mins < 60) {
        return `${parseInt(mins)}m ago`
    } else if (mins < 720) {
        return `${hrs}h ago`
    } else if (moment(createdAt).isSame(today, 'day')) {
        return `Today at ${changeDateFormat(createdAt, '', 'h:mm a')}`
    } else if (moment(createdAt).isSame(yesterday, 'day')) {
        return `Yesterday at ${changeDateFormat(createdAt, '', 'h:mm a')}`
    } else {
        if (createdAt !== undefined && createdAt !== null) {
            return `${changeDateFormat(
                createdAt,
                '',
                'DD MMM'
            )} at ${changeDateFormat(createdAt, '', 'h:mm a')}`
        }
    }
}

const getDaysAgo = (date, currentFormat = 'YYYY-MM-DD HH:mm:ss') => {
    if (date === undefined) return
    return moment(date, currentFormat).fromNow()
}

const setBreadCrumbRoutes = (postData) => {
    localStorage.setItem('visitingRoutes', JSON.stringify(postData))
}

const clearBreadCrumbRoutes = () => {
    localStorage.removeItem('visitingRoutes')
}

const removeLastVisitingRoute = () => {
    let visitingRoutes = getBreadCrumbs()
    visitingRoutes.pop()
    setBreadCrumbRoutes(visitingRoutes)
}

const getBreadCrumbs = () => {
    let routes = localStorage.getItem('visitingRoutes')
    return routes ? JSON.parse(routes) : []
}

const getPopStateRoutes = () => {
    let routes = localStorage.getItem('popStateRoutes')
    return routes ? JSON.parse(routes) : []
}

const setPopStateRoutes = (postData) => {
    localStorage.setItem('popStateRoutes', JSON.stringify(postData))
}

const clearPopStateRoutes = () => {
    localStorage.removeItem('popStateRoutes')
}

function concatenateArrayNTimes(array, n) {
    const result = []
    for (let i = 0; i < n; i++) {
        result.push(...array)
    }
    return result
}

const sharedCollectionCardRenderSequence = (totalElements) => {
    let sequence = []
    let staticSequence = [2, 4, 1, 3, 2, 1, 4, 3]
    let sequenceNumbers = concatenateArrayNTimes(
        staticSequence,
        Math.ceil(totalElements / 20)
    )
    let sum = 0
    for (let item of sequenceNumbers) {
        sum += item
        if (sum <= totalElements) {
            sequence.push(item)
        } else {
            sum -= item
        }
    }

    return sequence
}

const supplierCardRenderSequence = (totalElements) => {
    const chartToNum = {
        A: 1,
        B: 2,
        C: 3,
        D: 3
    }
    let sequence = []
    let staticSequence = ['B', 'D', 'A', 'C', 'B', 'A', 'D', 'C']
    let sequenceNumbers = concatenateArrayNTimes(
        staticSequence,
        Math.ceil(totalElements / 18)
    )
    let sum = 0
    for (let item of sequenceNumbers) {
        sum += chartToNum[item]
        if (sum <= totalElements) {
            sequence.push(item)
        } else {
            sum -= chartToNum[item]
        }
    }
    return sequence
}

const generateRandomString = () => Math.random().toString(36).slice(2)

// Fisher-Yates shuffle
const randomShuffle = (arr) => {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[arr[i], arr[j]] = [arr[j], arr[i]]
    }
    return arr
}

const customStylesSelect = {
    control: (provided, state) => ({
        ...provided,
        background: '#f5f5f5;',
        borderColor: '#f5f5f5;',
        minHeight: '42px',
        height: 'px',
        boxShadow: state.isFocused ? null : null
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '42px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
        fontSize: '14px'
    }),
    indicatorSeparator: (state) => ({
        display: 'none'
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '42px'
    })
}

const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailRegex.test(email)
}

const onSetQuoteDetailsBreadcrumb = (quotes, activeStatus, callback) => {
    let newRoute = `/quotes/detail/${quotes?.id}`
    let visitingRoutes = [
        {
            key: window.location.pathname + window.location.search || '',
            value: 'Quotes'
        },
        {
            key: window.location.pathname + window.location.search || '',
            value: capitalizeFirstLetter(activeStatus)
        },
        {
            key: newRoute,
            value: quotes?.title
        }
    ]
    setBreadCrumbRoutes(visitingRoutes)
    callback(newRoute)
}

const onSetSampleDetailsBreadcrumb = (sample, activeStatus, callback) => {
    let newRoute = `/samples/detail/${sample.id}`
    let visitingRoutes = [
        {
            key: window.location.pathname + window.location.search || '',
            value: 'Sample'
        },
        {
            key: window.location.pathname + window.location.search || '',
            value: capitalizeFirstLetter(activeStatus)
        },
        {
            key: newRoute,
            value: sample?.title
        }
    ]
    setBreadCrumbRoutes(visitingRoutes)
    callback(newRoute)
}
const isValidInput = (prevTarget, event) => {
    if (prevTarget.current === event.target.value) {
        return false
    }
    return true
}

const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(file)
    })
}

const capitalizeFirstAndLowercaseRest = (sentence) => {
    if (sentence) {
        const lowercaseSentence = sentence.trimLeft().toLowerCase()
        let firstWord = lowercaseSentence.charAt(0).toUpperCase()
        return firstWord + lowercaseSentence.slice(1)
    } else {
        return sentence
    }
}

const renderCertificateBadge = (certificateType) => {
    switch (certificateType) {
        case 'GOTS':
            return '/icons/gots.svg'
        case 'BSCI':
            return '/icons/bsci.svg'
        case 'ISO':
            return '/icons/iso.svg'
        case 'RDS':
            return '/icons/rds.svg'
        case 'RWS':
            return '/icons/rws.svg'
        case 'SCS':
            return '/icons/scs.svg'
        case 'SEDEX':
            return '/icons/Sedex.svg'
        case 'OCS':
            return '/icons/ocs.svg'
        case 'GRS':
            return '/icons/GRS.svg'
        case 'OEKOTEX':
            return '/icons/ORKOTEX.svg'
        case 'WRAP':
            return '/icons/wrap.svg'
        case 'RSC_ACCORD':
            return '/icons/accord.svg'
        case 'HIGG':
            return '/icons/HIGG.svg'
        case 'RCS':
            return '/icons/RCS.svg'
        default:
            return '/icons/sfa.svg'
    }
}

const encodeIpToken = (ipAddress) => {
    let formattedIp = ipAddress
        .replace('.', 'k')
        .replace('.', 'm')
        .replace('.', 'l')
    let userId = parseInt(getUserInfo().id)
    let p1 = btoa(userId * userId + userId).replaceAll('=', '')
    let p2 = btoa(formattedIp).replaceAll('=', '')
    let p3 = btoa(userId + 9999).replaceAll('=', '')
    return btoa(p1 + p2 + p3).replaceAll('=', '')
}

const getQuoteStatusClass = {
    APPROVED: 'green-3',
    PENDING: 'purple-3',
    REQUEST_FOR_REVISION: 'orange-2',
    OFFER_SENT: 'teal-2',
    CANCELLED: 'red-3'
}

const getTotalDashboardCount = (data) => {
    const modifiedStatus = { ...data }
    const statusMappings = {
        quotes: { PENDING: 'INITIALIZED', COMPLETED: 'ARCHIVE' },
        sample: { RUNNING: 'PENDING', PENDING: 'INITIALIZED' },
        order: { COMPLETED: 'ON_SHIPMENT', PENDING: 'INITIALIZED' }
    }

    for (const key in statusMappings) {
        for (const subKey in statusMappings[key]) {
            const targetKey = statusMappings[key][subKey]
            modifiedStatus[key] = {
                ...modifiedStatus[key],
                [subKey]:
                    (modifiedStatus[key]?.[subKey] || 0) +
                    (modifiedStatus[key]?.[targetKey] || 0)
            }
        }
    }

    return modifiedStatus
}

const downloadBlobFIle = (response) => {
    const [, filename] =
        response.headers['content-disposition'].split('filename=')
    const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/octet-stream' })
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename) //or any other extension
    document.body.appendChild(link)
    link.click()
}

const SHOW_CONVERSATION_PAGES = ['COLLECTION_DETAILS']

const STATIC_SET_CONSTANTS = {
    SET_PRODUCT: 'SET_STYLE',
    SET_MEMBER: 'ADDED_IN_SET',
    PACK_PRODUCT: 'PACK_STYLE'
}

const isSetItem = (product = {}) => {
    return (
        product.isSet ||
        product.setPackStyleFlag === STATIC_SET_CONSTANTS.SET_PRODUCT
    )
}

const isPackItem = (product = {}) => {
    return (
        product.isPack ||
        product.setPackStyleFlag === STATIC_SET_CONSTANTS.PACK_PRODUCT
    )
}
const printApiError = (error) => {
    if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
    } else {
        toast.error('Something went wrong!')
    }
    throw new Error(error)
}

// format featureImageDocUrl to match DesignImages component required Props*
const formatFeatureImageDocUrl = (designItem = {}) => {
    const designDoc = []
    const membersOfSet =
        designItem?.setMemberList ||
        designItem?.setMemberSampleRequestItemResponseList ||
        []

    if (isSetItem(designItem)) {
        membersOfSet.forEach((member) => {
            designDoc.push({
                id: member?.id,
                docUrl: member?.featureImageDocUrl || member?.image
            })
        })
    } else {
        designDoc.push({
            id: designItem?.id,
            docUrl: designItem?.featureImageDocUrl || designItem?.image
        })
    }

    return {
        isSet: isSetItem(designItem),
        designDocuments: designDoc
    }
}

const isAllIdExistBetweenTwoList = (list1 = [], list2 = []) => {
    if (list2.length === 0) return false
    for (const item of list1) {
        if (!list2.includes(item)) {
            return false
        }
    }
    return true
}

const getSampleActivityStatus = (status, type) => {
    switch (status) {
        case 'SAMPLE_REQUESTED':
        case 'COSTING_REQUESTED':
        case 'REQUESTED':
            return 'purple-1'
        case 'PATTERN':
        case 'CUTTING':
        case 'PRINT':
        case 'EMBROIDERY':
        case 'SEWING':
        case 'WASH':
        case 'DELIVERED':
        case 'ON_SHIPMENT':
            return 'teal-1'
        case 'HANDOVER_TO_FACTORY':
        case 'COSTING_DONE':
            return 'cyan-1'
        case 'RECEIVED_FROM_FACTORY':
            return 'megenta-1'
        case 'SAMPLE_APPROVED_BY_BUYER':
        case 'SAMPLE_APPROVED_BY_AM':
        case 'COSTING_APPROVED_BY_BUYER':
        case 'COSTING_APPROVED_BY_AM':
            return 'green-1'
        case 'SHIPMENT_COMPLETED':
        case 'SUPPLIER_ASSIGNED':
            return 'green'
        case 'SEND_TO_BUYER':
        case 'PRODUCTION':
        case 'OFFER_SENT':
            return 'yellow-1'
        case 'RECEIVED_BY_BUYER':
            return 'amber-1'
        case 'SAMPLE_REJECTED_BY_BUYER':
        case 'SAMPLE_REJECTED_BY_AM':
        case 'COSTING_REJECTED_BY_BUYER':
        case 'COSTING_REJECTED_BY_AM':
        case 'CANCEL_REQUEST':
        case 'CANCELED':
            return 'red-1'
        case 'COSTING':
            return 'blue-1'
        case 'PHOTOSHOT':
            return 'red-2'
        case 'PUBLISHED':
            return 'cyan-2'
        case 'SENT_TO_BD':
            return 'purple-2'
        case 'APPROVED':
            return 'green-2'
        case 'REJECTED':
            return 'red-2'
        case 'PENDING':
            return ''
        default:
            return ''
    }
}

const getColorWiseActivityStatusOfSample = (status, actiotnBy = '') => {
    const trimedStatus = replaceUnderLine(status)
    return (
        <span
            className={`status ${getSampleActivityStatus(
                status,
                'TABLE_STATUS'
            )}`}
        >
            {trimedStatus}
            {actiotnBy}
        </span>
    )
}
const getActionDoneBy = (userType) => {
    if (!userType) return ''
    if (userType === 'ACCOUNT_MANAGER') return ' by AM'
    return ' by Buyer'
}
export {
    validateScroll,
    capitalizeFirstLetter,
    replaceSpace,
    convertToDateTimeFromMiliSeconds,
    convertToDateFromMiliSeconds,
    convertToSelectOptions,
    isTokenExpired,
    convertToISODate,
    getOneWeekAgoMillis,
    getDateFromMillis,
    doCommaSeparationWithDecimals,
    doCommaSeparationWithIntegers,
    getDateWithHourFromMillis,
    encodeQueryData,
    _getKey,
    convertDateToLocal,
    getToken,
    addImageSuffix,
    convertDateAndTimeToLocal,
    getTodayTimeDifference,
    getUrlParameter,
    changeDateFormat,
    parseHtml,
    validateNumber,
    authUserInfo,
    isValidJSON,
    getImageExt,
    IMAGE_EXTS,
    replaceUnderLine,
    isValidFile,
    parseDate,
    dateCompare,
    addWithCurrentDate,
    copy,
    validateFloatNumber,
    getDateDifference,
    convertDateTimeToLocal,
    getNumberUnit,
    getShortName,
    isPageReachBottom,
    toOrdinalSuffix,
    getFileType,
    getIconByFileType,
    getMentionedUserIds,
    mentionModule,
    getIconByMimType,
    onErrorImageLoad,
    getRedirectUrl,
    getCurrentLocalDateTime,
    readFileAsync,
    generateCollectionFilterParams,
    checkSingular,
    onUploadFile,
    openNewWindow,
    onImageLoadError,
    getUserInfo,
    generateFavouriteStylesFilterParams,
    convertNumberToUSFormat,
    generateUniqueId,
    extractContent,
    getRoundValue,
    openBase64InNewTab,
    ACCESS_TOKEN,
    getStompCLient,
    onGetLocalFromUtc,
    getMinsCountFromNow,
    getTimeCount,
    getUserPreference,
    setBreadCrumbRoutes,
    clearBreadCrumbRoutes,
    getBreadCrumbs,
    sharedCollectionCardRenderSequence,
    removeLastVisitingRoute,
    generateRandomString,
    randomShuffle,
    customStylesSelect,
    isValidEmail,
    getDaysAgo,
    onSetQuoteDetailsBreadcrumb,
    onSetSampleDetailsBreadcrumb,
    getPopStateRoutes,
    setPopStateRoutes,
    clearPopStateRoutes,
    supplierCardRenderSequence,
    isValidInput,
    readFileAsDataURL,
    capitalizeFirstAndLowercaseRest,
    renderCertificateBadge,
    encodeIpToken,
    getQuoteStatusClass,
    getTotalDashboardCount,
    downloadBlobFIle,
    SHOW_CONVERSATION_PAGES,
    STATIC_SET_CONSTANTS,
    isSetItem,
    isPackItem,
    printApiError,
    formatFeatureImageDocUrl,
    isAllIdExistBetweenTwoList,
    getColorWiseActivityStatusOfSample,
    getSampleActivityStatus,
    getActionDoneBy
}
