import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import Http from '../../helpers/Http'
import { toast } from 'react-toastify'
import { ReactComponent as Eye } from '../../../assets/icons/old/eye.svg'
import { ReactComponent as HideEye } from '../../../assets/icons/old/hideEye.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg'

const ChangePassword = () => {
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [newPassRe, setNewPassRe] = useState('')
    const [oldPassError, setOldPassError] = useState('')
    const [newPassError, setNewPassError] = useState('')
    const [newPassReError, setNewPassReError] = useState('')
    const [passwordTypes, setPasswordTypes] = useState({
        oldPass: 'password',
        newPass: 'password',
        newPassRe: 'password'
    })
    const dispatch = useDispatch()

    const isValid = (password) => {
        if (!password || password.length === 0) return 'Password is required'
        let re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/
        if (!re.test(password))
            return 'Password must contain 6 characters, at least one number, one lowercase and one uppercase letter'
        return null
    }

    const updatePassword = async () => {
        let e1 = isValid(oldPass)
        let e2 = isValid(newPass)
        let e3 = isValid(newPassRe)

        setOldPassError(e1)
        setNewPassError(e2)
        setNewPassReError(e3)

        if (!e1 && !e2 && !e3) {
            if (newPass === newPassRe) {
                await dispatch(openLoader())
                let body = {
                    currentPassword: oldPass,
                    password: newPass,
                    retypePassword: newPassRe
                }
                await Http.POST('updatePassword', body)
                    .then(({ data }) => {
                        if (data.success) {
                            toast.success(data.message)
                        } else {
                            toast.error(data.message)
                        }
                    })
                    .catch(({ response }) => {
                        if (response.data && response.data.message) {
                            toast.error(response.data.message)
                        } else {
                            toast.error(
                                'Something went wrong! Please try again.'
                            )
                        }
                    })
                    .finally(() => {
                        dispatch(closeLoader())
                    })
            } else {
                setNewPassReError('Password does not match')
            }
        }
    }

    return (
        <div className='body-container'>
            <div className='w-full sm:w-9/12 px-8 py-6'>
                <div className='form-group relative'>
                    <label>Current password</label>
                    <input
                        type={passwordTypes.oldPass}
                        placeholder='*****'
                        value={oldPass}
                        onChange={(e) => setOldPass(e.target.value)}
                    />
                    {oldPassError && (
                        <p className='relative bottom-0 left-0 text-red-600 flex ite'>
                            <WarningIcon className='mr-1 w-5' />
                            {oldPassError}
                        </p>
                    )}
                    <span
                        className='absolute cursor-pointer right-[8px] top-[30px]'
                        onClick={() =>
                            setPasswordTypes((prev) => ({
                                ...prev,
                                oldPass:
                                    prev.oldPass === 'password'
                                        ? 'text'
                                        : 'password'
                            }))
                        }
                    >
                        {passwordTypes.oldPass === 'password' && <Eye />}
                        {passwordTypes.oldPass === 'text' && <HideEye />}
                    </span>
                </div>
                <div className='form-group relative'>
                    <label>New password</label>
                    <input
                        type={passwordTypes.newPass}
                        placeholder='*****'
                        value={newPass}
                        onChange={(e) => setNewPass(e.target.value)}
                    />
                    {newPassError && (
                        <p className='relative bottom-0 left-0 text-red-600 flex ite'>
                            <WarningIcon className='mr-1 w-5' />
                            {newPassError}
                        </p>
                    )}
                    <span
                        className='absolute cursor-pointer right-[8px] top-[30px]'
                        onClick={() =>
                            setPasswordTypes((prev) => ({
                                ...prev,
                                newPass:
                                    prev.newPass === 'password'
                                        ? 'text'
                                        : 'password'
                            }))
                        }
                    >
                        {passwordTypes.newPass === 'password' && <Eye />}
                        {passwordTypes.newPass === 'text' && <HideEye />}
                    </span>
                </div>
                <div className='form-group !mb-0 relative'>
                    <label>Re-enter new password</label>
                    <input
                        type={passwordTypes.newPassRe}
                        placeholder='*****'
                        value={newPassRe}
                        onChange={(e) => setNewPassRe(e.target.value)}
                    />
                    {newPassReError && (
                        <p className='relative bottom-0 left-0 text-red-600 flex ite'>
                            <WarningIcon className='mr-1 w-5' />
                            {newPassReError}
                        </p>
                    )}
                    <span
                        className='absolute cursor-pointer right-[8px] top-[30px]'
                        onClick={() =>
                            setPasswordTypes((prev) => ({
                                ...prev,
                                newPassRe:
                                    prev.newPassRe === 'password'
                                        ? 'text'
                                        : 'password'
                            }))
                        }
                    >
                        {passwordTypes.newPassRe === 'password' && <Eye />}
                        {passwordTypes.newPassRe === 'text' && <HideEye />}
                    </span>
                </div>
            </div>
            <div className='px-8 py-6 border-top-solid'>
                <button
                    className='button primary'
                    onClick={() => updatePassword()}
                >
                    Save changes
                </button>
            </div>
        </div>
    )
}

export default ChangePassword
