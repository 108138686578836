import { createSlice } from '@reduxjs/toolkit'
import SharedCollectionReducer from './SharedCollectionReducer'
import { useSelector } from 'react-redux'

const initialState = {
    response: {}
}

const SharedCollection = createSlice({
    name: 'sharedCollections',
    initialState,
    reducers: SharedCollectionReducer

})

export const SharedCollectionActions = SharedCollection.actions

export const useSharedCollections = () => useSelector(state => state.sharedCollections.response)

export default SharedCollection.reducer
