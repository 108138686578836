import React, { useState } from 'react'
import { ReactComponent as ArrowLeftIcon } from '../../../icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../../icons/arrow-right.svg'
import { ReactComponent as ChatIcon } from '../../../icons/chat.svg'
import { ReactComponent as CloseIcon } from '../../../icons/close.svg'
import { ReactComponent as PlusSquareIcon } from '../../../icons/plus-square.svg'
import { ReactComponent as ApprovedIcon } from '../../../icons/approved.svg'
import { ReactComponent as RecostIcon } from '../../../icons/recost.svg'
import ModalContainer from '../../../../app/common/modal/ModalContainer'
import { set } from 'react-ga'

const SampleDetails = () => {
    const [showTimelineModal, setShowTimelineModal] = useState(false)

    const handleClose = () => {
        setShowTimelineModal(false)
    }
    return (
        <>
            <div className='py-3'>
                <div className='flex align-items-center'>
                    <button className='button link mr-6'>
                        <ArrowLeftIcon />
                        <span className='black'>Back</span>
                    </button>
                    <div className='breadcrumb'>
                        <p className='regular-14 black'>
                            <span className='blue'>Sample </span> /{' '}
                            <span className='blue'>Pending </span> /{' '}
                            <span className='regular-12'>
                                Men’s Semi-formal Winter 2024
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className='page-content'>
                <div className='quote-status mt-2'>
                    <span className='status blue-1 mr-2'>Quote Received</span>
                    <span className='status purple-1 mr-2'>Buyer Design</span>
                </div>
                <div className='flex justify-between'>
                    <div className='quote-content'>
                        <h2 className='regular-18 weight-500 mt-2'>
                            Men’s Semi-formal Winter 2024{' '}
                            <span className='regular-12 gray_200'>(2)</span>
                        </h2>
                        <h4 className='regular-14 gray_300 my-2'>
                            Q2210-A0024
                        </h4>
                        <p className='regular-12 max-w-[640px]'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse ultricies mauris odio. Maecenas
                            congue nisl eu suscipit aliquet. Phasellus rhoncus
                            varius ultricies. Maecenas sollicitudin lectus at mi
                            facilisis auctor.
                        </p>
                    </div>
                    <div className='team-members'>
                        <div className='flex items-center'>
                            <div className='member-photos flex'>
                                <img src='/images/user.svg' alt='member' />
                                <img src='/images/user.svg' alt='member' />
                                <img src='/images/user.svg' alt='member' />
                            </div>
                            <button className='button secondary ml-2'>
                                Add People
                            </button>
                        </div>
                    </div>
                </div>

                <div className='sample-table mt-6'>
                    <table className='table sample-list-table'>
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='all'
                                        value=''
                                    />
                                </th>
                                <th>Style</th>
                                <th>Fabric</th>
                                <th>Size</th>
                                <th>Quantity</th>
                                <th>Status</th>
                                <th>Due Date</th>
                                <th>Action</th>
                                <th>Conversation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status teal-1'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Design Uploaded
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button primary regular-12 round mr-2'>
                                            Approve
                                        </button>
                                        <button className='button secondary regular-12 round whitespace-nowrap'>
                                            Re-sample
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status yellow-2'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Requested
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button
                                            className='button primary round'
                                            disabled
                                        >
                                            <span className='mr-2'>
                                                Approved
                                            </span>
                                            <ApprovedIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status cyan-1'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Pattern
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button pill round cursor-default'>
                                            <span className='mr-2'>
                                                Re-cost Requested
                                            </span>
                                            <RecostIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status magenta-1'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Print
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button pill round cursor-default'>
                                            <span className='mr-2'>
                                                Re-cost Requested
                                            </span>
                                            <RecostIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status purple-1'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Embroidery
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button pill round cursor-default'>
                                            <span className='mr-2'>
                                                Re-cost Requested
                                            </span>
                                            <RecostIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status purple-2'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Sewing
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button pill round cursor-default'>
                                            <span className='mr-2'>
                                                Re-cost Requested
                                            </span>
                                            <RecostIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status magenta-2'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Wash
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button pill round cursor-default'>
                                            <span className='mr-2'>
                                                Re-cost Requested
                                            </span>
                                            <RecostIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status green-1'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Delivered
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button pill round cursor-default'>
                                            <span className='mr-2'>
                                                Re-cost Requested
                                            </span>
                                            <RecostIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status green-2'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Published
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button pill round cursor-default'>
                                            <span className='mr-2'>
                                                Re-cost Requested
                                            </span>
                                            <RecostIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status orange-1'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Photoshoot
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button pill round cursor-default'>
                                            <span className='mr-2'>
                                                Re-cost Requested
                                            </span>
                                            <RecostIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status orange-2'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Costing
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button pill round cursor-default'>
                                            <span className='mr-2'>
                                                Re-cost Requested
                                            </span>
                                            <RecostIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status yellow-1'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Cutting
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button pill round cursor-default'>
                                            <span className='mr-2'>
                                                Re-cost Requested
                                            </span>
                                            <RecostIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        id='1'
                                        value=''
                                    />
                                </td>
                                <td className='relative'>
                                    <div className='flex items-center'>
                                        <img
                                            src='/images/single-design.png'
                                            alt='style'
                                            className='style-image w-11'
                                        />
                                        <p className='blue regular-14 cursor-pointer ml-2'>
                                            MT22-A0121
                                        </p>
                                    </div>
                                    <span className='absolute right-0 bottom-0 p-1 bg-primary column-hover-icon cursor-pointer'>
                                        <PlusSquareIcon />
                                    </span>
                                </td>
                                <td>
                                    Diagonal Fleece, 100% Triacetate, 2x2 rib,
                                    100.0 GSM, Bleaching
                                </td>
                                <td>L</td>
                                <td>45 </td>
                                <td>
                                    <span
                                        className='status pink-3'
                                        onClick={(e) =>
                                            setShowTimelineModal(true)
                                        }
                                    >
                                        Send to BD
                                    </span>
                                </td>
                                <td>8th Sep, 22</td>
                                <td>
                                    <div className='flex items-center'>
                                        <button className='button pill round cursor-default'>
                                            <span className='mr-2'>
                                                Re-cost Requested
                                            </span>
                                            <RecostIcon />
                                        </button>
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <button className='button secondary icon-only chat-btn'>
                                        <ChatIcon />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Tab view cards */}
                <div className='tab-view-table sample-tab-view-table py-4'>
                    <div className='select-all mb-2'>
                        <div className='flex items-center'>
                            <input
                                type='checkbox'
                                name='categories'
                                id='all'
                                value=''
                            />
                            <label className='text-base blue ml-2'>
                                Select all
                            </label>
                        </div>
                    </div>
                    <div className='all-rows'>
                        <div className='single-row'>
                            <table className='table'>
                                <tr>
                                    <td rowSpan='8'>
                                        <input
                                            type='checkbox'
                                            name='categories'
                                            id='all'
                                            value=''
                                        />
                                    </td>
                                    <td className='heading'>Style</td>
                                    <td>
                                        <div className='flex items-center'>
                                            <img
                                                src='/images/single-design.png'
                                                alt='style'
                                                className='style-image w-11'
                                            />
                                            <p className='blue regular-14 cursor-pointer ml-2'>
                                                MT22-A0121
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Fabric</td>
                                    <td>
                                        Mix, Twill, 49% Tencel 49% Cotton 2%
                                        Spandex, GSM 200
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Size</td>
                                    <td>L</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Quantity</td>
                                    <td>456</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Status</td>
                                    <td>
                                        <span className='status teal-1'>
                                            Design Uploaded
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Due Date</td>
                                    <td>8th Sep, 22</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Action</td>
                                    <td>
                                        {' '}
                                        <div className='flex items-center'>
                                            <button className='button primary regular-12 sm round mr-2'>
                                                Approve
                                            </button>
                                            <button className='button secondary regular-12 sm round'>
                                                Re-sample
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Conversation</td>
                                    <td>
                                        <button className='button secondary icon-only chat-btn'>
                                            <ChatIcon />
                                            <span className='count absolute'>
                                                10
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className='add-row'>
                            <button className='button link my-5'>
                                <PlusSquareIcon /> Add new style
                            </button>
                        </div>
                        <div className='single-row'>
                            <table className='table'>
                                <tr>
                                    <td rowSpan='8'>
                                        <input
                                            type='checkbox'
                                            name='categories'
                                            id='all'
                                            value=''
                                        />
                                    </td>
                                    <td className='heading'>Style</td>
                                    <td>
                                        <div className='flex items-center'>
                                            <img
                                                src='/images/single-design.png'
                                                alt='style'
                                                className='style-image w-11'
                                            />
                                            <p className='blue regular-14 cursor-pointer ml-2'>
                                                MT22-A0121
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Fabric</td>
                                    <td>
                                        Mix, Twill, 49% Tencel 49% Cotton 2%
                                        Spandex, GSM 200
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Size</td>
                                    <td>L</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Quantity</td>
                                    <td>456</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Status</td>
                                    <td>
                                        <span className='status teal-1'>
                                            Design Uploaded
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Due Date</td>
                                    <td>8th Sep, 22</td>
                                </tr>
                                <tr>
                                    <td className='heading'>Action</td>
                                    <td>
                                        {' '}
                                        <div className='flex items-center'>
                                            <button className='button primary regular-12 sm round mr-2'>
                                                Approve
                                            </button>
                                            <button className='button secondary regular-12 sm round'>
                                                Re-sample
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>Conversation</td>
                                    <td>
                                        <button className='button secondary icon-only chat-btn'>
                                            <ChatIcon />
                                            <span className='count absolute'>
                                                10
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='pagination mt-8 flex items-center justify-center'>
                    <div className='flex items-center'>
                        <button className='button link mr-1'>
                            <ArrowLeftIcon />
                        </button>
                        <div className='page-numbers'>
                            <span className='active'>1</span>
                            <span>2</span>
                            <span>3</span>
                            <span>4</span>
                            <span>5</span>
                            <span className='dots'>...</span>
                            <span>10</span>
                            <span>20</span>
                        </div>
                        <button className='button link ml-1'>
                            <ArrowRightIcon />
                        </button>
                        <div className='page-sorting ml-4'>
                            <select>
                                <option>20</option>
                                <option>40</option>
                                <option>60</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='selected-item-count flex justify-center'>
                <div className='item-count-content flex items-center justify-between max-w-[900px] w-full'>
                    <div className='flex items-center'>
                        <p className='regular-18'>1 Item </p>
                        <span className='px-4'>|</span>
                        <button className='button link'>Select all</button>
                    </div>

                    <div>
                        <button className='button primary round'>Qoute</button>
                    </div>
                </div>
            </div> */}

            {/* Timeline modal */}
            {showTimelineModal && (
                <ModalContainer
                    className='timeline-modal right-sidebar'
                    // onCloseModal={() => toggleModel(null)}
                >
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Design Timeline
                                </h3>
                                <p className='regular-12 gray_300'>
                                    Required: 9th Nov, 2022
                                </p>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4'>
                            <div className='design-timeline-container'>
                                <ul className='design-timeline design-timeline-centered'>
                                    <li className='design-timeline-item green'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>
                                                Design Uploaded
                                            </p>
                                            <span className='text-xs'>
                                                28th Oct
                                            </span>
                                        </div>
                                    </li>
                                    <li className='design-timeline-item requested'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>Requested</p>
                                            <span className='text-xs'>
                                                18th Apr
                                            </span>
                                        </div>
                                    </li>
                                    <li className='design-timeline-item gray_dark_03'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>Pattern</p>
                                        </div>
                                    </li>
                                    <li className='design-timeline-item gray_dark_03'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>Cutting</p>
                                        </div>
                                    </li>
                                    <li className='design-timeline-item gray_dark_03'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>Print</p>
                                        </div>
                                    </li>
                                    <li className='design-timeline-item gray_dark_03'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>
                                                Embroidery
                                            </p>
                                        </div>
                                    </li>
                                    <li className='design-timeline-item gray_dark_03'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>Sewing</p>
                                        </div>
                                    </li>
                                    <li className='design-timeline-item gray_dark_03'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>Wash</p>
                                        </div>
                                    </li>
                                    <li className='design-timeline-item gray_dark_03'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>Delivered</p>
                                        </div>
                                    </li>
                                    <li className='design-timeline-item gray_dark_03'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>Costing</p>
                                        </div>
                                    </li>
                                    <li className='design-timeline-item gray_dark_03'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>
                                                Photoshoot
                                            </p>
                                        </div>
                                    </li>
                                    <li className='design-timeline-item gray_dark_03'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>Published</p>
                                        </div>
                                    </li>
                                    <li className='design-timeline-item gray_dark_03'>
                                        <div className='design-timeline-marker' />
                                        <div className='design-timeline-content'>
                                            <p className='text-xs'>
                                                Sent To Bd
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    )
}

export default SampleDetails
