import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import QuoteList from '../modules/quote/QuoteList'
import SampleList from '../modules/samples/SampleList'
import MyOrderList from '../modules/order/MyOrderList'
import AskQuoteSample from '../common/AskQuoteSample'
import { fetchCurrentBuyerRequest } from '../services/v3/Dashboard/Dashborad'
import useDocumentTitle from '../hooks/userDocumentTitle'
import { getTotalDashboardCount } from '../helpers/Util'
const LIST = {
    QUOTES: 'QUOTES',
    SAMPLE: 'SAMPLE',
    ORDER: 'ORDER'
}

const MyDashboard = () => {
    const [total, setToal] = useState(null)
    const [isAskedClick, setIsAskedClick] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const activeParam = new URLSearchParams(location.search).get('active')
    const activeStatus = new URLSearchParams(location.search).get('status')
    const [selectedList, setSelectedList] = useState(
        Object.prototype.hasOwnProperty.call(LIST, activeParam)
            ? LIST[activeParam]
            : LIST.QUOTES
    )
    useDocumentTitle('Nitex - Dashboard')

    const isListNotEmpty = () =>
        total && total[selectedList.toLowerCase()][activeStatus]

    useEffect(() => {
        onFetchBuyerRequest()
    }, [])

    const onFetchBuyerRequest = async () => {
        const data = await fetchCurrentBuyerRequest()
        setToal(getTotalDashboardCount(data))
    }

    const onSelectList = (value) => {
        setSelectedList(value)
        navigate(`/dashboard?active=${value}&status=RUNNING`)
    }

    const renderList = {
        QUOTES: <QuoteList total={total} />,
        SAMPLE: <SampleList total={total} />,
        ORDER: <MyOrderList total={total} />
    }

    return (
        <>
            <div className='flex flex-wrap justify-between mt-5 lg:mt-10 px-4'>
                <div className='quotes-sample-order-tab'>
                    <ul>
                        <li
                            className={
                                selectedList === LIST.QUOTES ? 'active' : ''
                            }
                            onClick={() => onSelectList(LIST.QUOTES)}
                        >
                            <h4 className='regular-16'>Quotes</h4>
                            <p className='regular-12'>
                                {/* {capitalizeFirstLetter(quoteStore?.activeTab)}:{' '} */}
                                Running: {total?.quotes?.RUNNING}
                            </p>
                        </li>
                        <li
                            className={
                                selectedList === LIST.SAMPLE ? 'active' : ''
                            }
                            onClick={() => onSelectList(LIST.SAMPLE)}
                        >
                            <h4 className='regular-16'>Samples</h4>
                            <p className='regular-12'>
                                {/* {capitalizeFirstLetter(sampleStore?.activeTab)}:{' '} */}
                                Running: {total?.sample?.RUNNING}
                            </p>
                        </li>
                        <li
                            className={
                                selectedList === LIST.ORDER ? 'active' : ''
                            }
                            onClick={() => onSelectList(LIST.ORDER)}
                        >
                            <h4 className='regular-16'>Orders</h4>
                            <p className='regular-12'>
                                Running: {total?.order?.RUNNING}
                            </p>
                        </li>
                    </ul>
                </div>
                {isListNotEmpty() ? (
                    <div className='mt-5 sm:mt-0'>
                        <button
                            className='button primary'
                            onClick={() => setIsAskedClick(true)}
                        >
                            Ask for quote/sample
                        </button>
                    </div>
                ) : null}
            </div>
            <div className='tab-contents mt-5 px-4'>
                {renderList[selectedList]}
            </div>
            {isAskedClick && (
                <AskQuoteSample onCloseModal={() => setIsAskedClick(false)} />
            )}
        </>
    )
}

export default MyDashboard
