import React from 'react'

const StyleImageCardView = ({
    navigationUrl,
    productFeatureImageList,
    titleName,
    referenceNumber,
    noOfDesign,
    status,
    statusText = ''
}) => {
    const renderBlankImage = () => {
        let rows = []
        for (let i = productFeatureImageList.length + 1; i <= 4; i++) {
            rows.push(
                <div
                    key={`Image${i}`}
                    className='w-[64px] h-[70px] bg-[#F6F5F3]'
                >
                    <img src='/images/default_product.svg' alt='design' />
                </div>
            )
        }
        return rows
    }

    return (
        <div
            className='quote-box flex tems-center bg-white p-2'
            onClick={() => navigationUrl()}
        >
            <div className='grid grid-cols-2 gap-1 w-[132px] bg-white'>
                {productFeatureImageList.map((image, i) => {
                    return (
                        <div key={i} className='w-[64px] h-[70px] bg-[#F6F5F3]'>
                            <img
                                src={image}
                                alt=''
                                className='w-full h-full object-cover object-top'
                            />
                        </div>
                    )
                })}
                {productFeatureImageList.length < 4 && renderBlankImage()}
            </div>
            <div className='flex-1 px-4'>
                {status && statusText && (
                    <span
                        className={`status ${
                            statusText === 'Quote Received'
                                ? 'blue-1'
                                : 'magenta-1'
                        }`}
                    >
                        {statusText}
                    </span>
                )}
                <h5 className='weight-500 regular-16 mb-2 truncate-2 mt-3'>
                    {titleName}
                </h5>
                <ul className='common-list-component'>
                    <li className='regular-14'>
                        <span>
                            {noOfDesign} {noOfDesign > 1 ? 'Styles' : 'Style'}
                        </span>
                    </li>
                    <li className='regular-14'> {referenceNumber}</li>
                </ul>
            </div>
        </div>
    )
}

export default StyleImageCardView
