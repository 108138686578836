import React from 'react'
import { useFilterOption } from '../../../redux_toolkit/V3/Filter/FilterOptionReducers'
import { FilterActionTypes, useFilter } from '../../../redux_toolkit/V3/Filter'
import { SET_FILTER_FIELD } from '../../../redux_toolkit/@types/action.types'
import { useDispatch } from 'react-redux'

const Market = () => {
    const options = useFilterOption()
    const dispatch = useDispatch()
    const filter = useFilter()

    const handleCheckbox = async (e, item) => {
        // console.log(e.target.checked);
        let data = {
            name: e.target.name,
            value: item,
            type: e.target.type,
            checked: e.target.checked
        }
        await dispatch(FilterActionTypes[SET_FILTER_FIELD](data))
    }

    const isMarketChecked = (item) => {
        if (filter?.markets) {
            let isMarketExist = false
            for (let market of filter.markets) {
                if (market.id === item.id) {
                    isMarketExist = true
                    break
                }
            }
            return isMarketExist
        } else {
            return false
        }
    }

    const renderMarkets = () => {
        return options?.markets?.map((item) => {
            return (
                <div className='flex items-start' key={`markets_${item.id}`}>
                    <span>
                        <input
                            name='markets'
                            type='checkbox'
                            value={item.id}
                            id={`market_${item.id}`}
                            onChange={(e) => handleCheckbox(e, item)}
                            checked={isMarketChecked(item)}
                        />
                    </span>
                    <label
                        id={`market_${item.id}`}
                        className='align-middle pl-4 inline-block mt-[-3px]'
                    >
                        {item?.name}
                    </label>
                </div>
            )
        })
    }

    return (
        <div className='border-r border-b last:border-r-none border-white-shade-100 py-6 px-10'>
            <span className='text-primaryColor-shade-100'>Market</span>
            <div className='mt-6 space-y-8'>{renderMarkets()}</div>
        </div>
    )
}

export default Market
