/* eslint-disable quotes */
import React, { useEffect, useRef, useState } from 'react'
import 'quill-mention'
import 'quill-mention/dist/quill.mention.css'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { addImageSuffix } from '../../helpers/Util'
import conversationService from '../../services/Conversation/conversation-service'
import { SelectedFileViewComponent } from '../SelectedFileViewComponent'
import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg'
import Editor from '../Editor'
import { toast } from 'react-toastify'

const NewPostSection = ({
    onSetDeliverableDetails,
    onAddNewPost,
    userInfo,
    orderMemberList,
    deliverableId,
    orderId,
    reviseInfo,
    brandName,
    onGetCommentsData,
    onResetRevise,
    resetRevisionInfo
}) => {
    const [selectedFiles, setSelectedFiles] = useState([])
    const [selectedMentionedUser, setSelectedMentionedUser] = useState([])
    const [mentionToUser, setMentionToUser] = useState([])
    const [mentionSearch, setMentionSearch] = useState('')
    const [postType, setPostType] = useState('')
    const [post, setPost] = useState('')
    const [createPostFlag, setCreatePostFlag] = useState(false)
    const [nitexMentioned, setNitexMentioned] = useState(true)
    const [brandMentioned, setBrandMentioned] = useState(true)
    const [readOnly, setReadOnly] = useState(true)
    const dropdownRef = useRef(null)
    const searchRef = useRef(null)
    const postInputRef = useRef(null)
    const dispatch = useDispatch()

    const onDismissSelection = (userId) => {
        const userDismiss = selectedMentionedUser
        const index = selectedMentionedUser.findIndex((el) => el.id === userId)
        userDismiss.splice(index, 1)
        setSelectedMentionedUser([...userDismiss])
        setMentionToUser([])
        setMentionSearch('')
    }

    const onSelectedUserToMention = (value) => {
        if (mentionToUser && mentionToUser.length > 0) {
            const mentionedUsers = selectedMentionedUser
            const selectedUser = mentionToUser.filter(
                (el) => el.email === value
            )
            if (selectedUser[0]) {
                mentionedUsers.push(selectedUser[0])
                const unique = [
                    ...new Map(
                        mentionedUsers.map((item) => [item['email'], item])
                    ).values()
                ]
                setSelectedMentionedUser([...unique])
                setMentionToUser([])
                setMentionSearch('')
            }
        }
    }

    const handleMentionTo = async (e) => {
        setMentionSearch(e.target.value)
        if (e.target.value) {
            const data = orderMemberList.map((item) => {
                return {
                    id: item.memberId,
                    name: item.memberName,
                    email: item.email
                }
            })

            const newData = data.filter(
                (member) =>
                    !selectedMentionedUser.some(
                        (item) => item.email === member.email
                    ) &&
                    member.email
                        .toUpperCase()
                        .includes(e.target.value.toUpperCase())
            )
            setMentionToUser(newData)
        }
    }

    const removeFile = (index) => {
        let updatedFiles = selectedFiles.filter((file, i) => i !== index)
        setSelectedFiles([...updatedFiles])
    }

    const onMultipleFileSelect = async (e, docType) => {
        let existingFile = selectedFiles
        let files = Array.from(e.target.files)
        let key = e.target.name
        files.map((item) => {
            let data = {
                name: item.name,
                docMimeType: item.type,
                documentType: docType,
                print: false,
                base64Str: ''
            }
            let reader = new FileReader()
            reader.readAsDataURL(item)
            reader.onload = () => {
                data.base64Str = reader.result
                existingFile.push(data)
                setSelectedFiles([...selectedFiles])
                setCreatePostFlag(true)
            }
            reader.onerror = function (error) {
                //console.log('Error: ', error);
            }
        })
    }

    const getMentionedUserIds = () => {
        let newComment = post
        let foundEmails = []
        let emailRegex =
            /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
        let gotcha
        let match = null
        let ids = []
        while ((match = emailRegex.exec(newComment))) {
            foundEmails.push(match[0])
            newComment = newComment.replace(match[0], '')
        }
        foundEmails.map((email) => {
            orderMemberList.map((member) => {
                if (member.email === email && !ids.includes(member.memberId)) {
                    ids.push(member.memberId)
                }
            })
        })
        return ids
    }

    useEffect(() => {
        document.body.addEventListener('keydown', handleKeyDown)
        return () => {
            document.body.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    const handleKeyDown = (event) => {
        if (
            document.activeElement.getAttribute('class') ===
                'outline-none mention-search-box' ||
            document.activeElement.getAttribute('class') === 'single-item-focus'
        ) {
            const isUp = event.key === 'ArrowUp'
            const isDown = event.key === 'ArrowDown'
            const inputIsFocused = document.activeElement === searchRef.current
            const resultsItems = Array.from(
                (dropdownRef.current && dropdownRef.current.children) || []
            )
            const activeResultIndex = resultsItems.findIndex((child) => {
                return child.querySelector('span') === document.activeElement
            })

            if (isUp) {
                if (inputIsFocused) {
                    resultsItems[resultsItems.length - 1]
                        .querySelector('span')
                        .focus()
                } else if (resultsItems[activeResultIndex - 1]) {
                    resultsItems[activeResultIndex - 1]
                        .querySelector('span')
                        .focus()
                } else {
                    searchRef.current && searchRef.current.focus()
                }
            }

            if (isDown) {
                if (inputIsFocused) {
                    resultsItems[0].querySelector('span').focus()
                } else if (resultsItems[activeResultIndex + 1]) {
                    resultsItems[activeResultIndex + 1]
                        .querySelector('span')
                        .focus()
                } else {
                    searchRef.current && searchRef.current.focus()
                }
            }
        }
    }

    const resetAll = () => {
        postInputRef.current = null
        setSelectedFiles([])
        setPostType('')
        setPost('')
        setCreatePostFlag(false)
    }

    const onRevisePost = async (body) => {
        body.postType = 'TASK_REVISION'
        dispatch(openLoader())
        const response = await conversationService.onReviseTask(
            body,
            reviseInfo?.id
        )
        if (response?.success) {
            toast.success(response.message)
            resetAll()
            resetRevisionInfo()
            onResetRevise()
            onGetCommentsData(0)
            onSetDeliverableDetails(response?.payload?.deliverableDetails)
        }
        dispatch(closeLoader())
    }

    const onAddNewComment = async (body) => {
        body.postType = 'TASK_REGULAR_POST'
        dispatch(openLoader())
        const response = await conversationService.onAddNewComment(body)
        if (response?.success) {
            toast.success(response.message)
            onAddNewPost(response?.payload?.postResponse)
            resetAll()
            resetRevisionInfo()
            onResetRevise()
        }
        dispatch(closeLoader())
    }

    const onAddPost = async () => {
        let message = postInputRef.current
        let body = {
            deliverableId: deliverableId,
            documentDTOList: selectedFiles,
            orderId,
            text: message.replace(/"/g, "'"),
            taggedUserIdList: getMentionedUserIds()
        }
        if (reviseInfo?.id) {
            onRevisePost(body)
        } else {
            body.recipientList = []
            if (nitexMentioned)
                body.recipientList.push({ recipientType: 'NITEX' })
            if (brandMentioned && brandName !== 'Nitex')
                body.recipientList.push({ recipientType: 'BUYER' })
            if (selectedMentionedUser) {
                selectedMentionedUser.map((user) => {
                    const userIdMap = {
                        recipientType: 'INDIVIDUAL',
                        userId: user.id
                    }
                    body.recipientList.push(userIdMap)
                })
            }
            if (body.recipientList.length === 0) {
                toast.error('At least one mention nedded')
                setNitexMentioned(true)
                setBrandMentioned(true)
                return
            }
            onAddNewComment(body)
        }
    }

    const handlePost = async () => {
        let newComment =
            postInputRef.current === null
                ? ''
                : postInputRef?.current.toString().replace(/(<([^>]+)>)/gi, '')
        if (!newComment.length) {
            toast.warning('Please provide a description!')
            return false
        }
        if (postType === 'COMPLETE' && !selectedFiles.length) {
            return
        }
        await onAddPost()
    }

    const onCloseNote = () => {
        setPost('')
        setSelectedFiles([])
        setPostType('')
        resetRevisionInfo()
        onResetRevise()
        setCreatePostFlag(false)
        postInputRef.current = null
    }

    return (
        <div
            className={`modal-footer-fixed posting-option ${
                selectedFiles?.length > 0 ? 'file-exist' : ''
            } ${createPostFlag ? 'open' : ''}`}
        >
            {reviseInfo && (
                <div className='header-info-title open'>
                    Revision{' '}
                    <span className='weight-500 gray_400'>
                        on{' '}
                        <span className='gray_800'>
                            {' '}
                            {reviseInfo?.stepName}
                        </span>
                    </span>
                </div>
            )}

            <div className='tag-person'>
                <div className='tag-items'>
                    <p>TO</p>
                    {nitexMentioned && (
                        <span className='status gray-3 mr-1'>
                            <span>Nitex</span>
                            <img
                                src='/icons/close-14.svg'
                                onClick={() => setNitexMentioned(false)}
                            />
                        </span>
                    )}
                    {brandMentioned && brandName !== 'Nitex' && (
                        <span className='status blue-3 mr-1'>
                            <span>{brandName}</span>
                            <img
                                src='/icons/close-14.svg'
                                onClick={() => setBrandMentioned(false)}
                            />
                        </span>
                    )}
                    {selectedMentionedUser &&
                        selectedMentionedUser.map((user, i) => {
                            return (
                                <span
                                    key={user.id}
                                    className='status blue-3 mr-1'
                                >
                                    <span>{user.email}</span>
                                    <img
                                        src='/icons/close-14.svg'
                                        onClick={() =>
                                            onDismissSelection(user.id)
                                        }
                                    />
                                </span>
                            )
                        })}
                    <div>
                        <div className='metion-person-suggetion absolute bottom-[100%] scroll-y-label bg-white'>
                            <ul className=' shadow-lg' ref={dropdownRef}>
                                {!nitexMentioned && mentionSearch !== '' && (
                                    <li
                                        key={'nitex'}
                                        className='cursor-pointer'
                                        onClick={() => {
                                            setNitexMentioned(true)
                                            setMentionToUser([])
                                            setMentionSearch('')
                                        }}
                                        onKeyPress={() => {
                                            setNitexMentioned(true)
                                            setMentionToUser([])
                                            setMentionSearch('')
                                        }}
                                    >
                                        <span
                                            className='single-item-focus'
                                            tabIndex={0}
                                        >
                                            Nitex
                                        </span>
                                    </li>
                                )}
                                {!brandMentioned &&
                                    mentionSearch !== '' &&
                                    brandName !== 'Nitex' && (
                                        <li
                                            key={'buyer'}
                                            className='cursor-pointer'
                                            onClick={() => {
                                                setBrandMentioned(true)
                                                setMentionToUser([])
                                                setMentionSearch('')
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    setBrandMentioned(true)
                                                    setMentionToUser([])
                                                    setMentionSearch('')
                                                }
                                            }}
                                        >
                                            <span
                                                className='single-item-focus'
                                                tabIndex={0}
                                            >
                                                {brandName}
                                            </span>
                                        </li>
                                    )}

                                {mentionSearch.length > 0 &&
                                    mentionToUser.map((user) => {
                                        return (
                                            <li
                                                key={user.id}
                                                className='cursor-pointer px-3 py-1'
                                                onClick={() =>
                                                    onSelectedUserToMention(
                                                        user.email
                                                    )
                                                }
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        onSelectedUserToMention(
                                                            user.email
                                                        )
                                                    }
                                                }}
                                            >
                                                <span
                                                    className='single-item-focus'
                                                    tabIndex={0}
                                                >
                                                    {user.email}
                                                </span>
                                            </li>
                                        )
                                    })}
                            </ul>
                        </div>
                        <input
                            className='outline-none mention-search-box'
                            value={mentionSearch}
                            placeholder='Search with email'
                            type='text'
                            onChange={handleMentionTo}
                            ref={searchRef}
                            onFocus={() => setReadOnly(true)}
                        />
                    </div>
                </div>
            </div>

            <div className='flex w-full'>
                <div className='user-photo'>
                    {userInfo && userInfo?.profilePicDocument ? (
                        <img src={userInfo.profilePicDocument?.docUrl} />
                    ) : (
                        <img src='/images/pro_pic_default.png' />
                    )}
                </div>

                {!createPostFlag ? (
                    <textarea
                        placeholder='Write comment or use @ to mention'
                        onClick={() => {
                            setCreatePostFlag(true)
                            setReadOnly(false)
                        }}
                    />
                ) : (
                    <Editor
                        callBack={(value) => (postInputRef.current = value)}
                        memberList={orderMemberList?.map((user) => {
                            return {
                                id: user.email,
                                value: user.memberName
                            }
                        })}
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                        mention={true}
                        value={postInputRef.current || ''}
                    />
                )}
            </div>

            <div
                className={`files-n-photos custom-scrollbar ${
                    selectedFiles.length ? 'open' : ''
                }`}
            >
                {selectedFiles.map((file, i) => {
                    return (
                        <SelectedFileViewComponent
                            showRemoveOption={true}
                            file={file}
                            key={i}
                            index={i}
                            remove={removeFile}
                        />
                    )
                })}
            </div>

            <div className='post-actions'>
                <div className='attachment cursor-pointer'>
                    <label htmlFor='input-file'>
                        <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M12.5 22C9.46 22 7 19.54 7 16.5L7 6C7 3.79 8.79 2 11 2C13.21 2 15 3.79 15 6L15 14.5C15 15.88 13.88 17 12.5 17C11.12 17 10 15.88 10 14.5L10 7L12 7L12 14.59C12 15.14 13 15.14 13 14.59L13 6C13 4.9 12.1 4 11 4C9.9 4 9 4.9 9 6L9 16.5C9 18.43 10.57 20 12.5 20C14.43 20 16 18.43 16 16.5L16 7L18 7L18 16.5C18 19.54 15.54 22 12.5 22Z'
                                fill='#595959'
                            />
                        </svg>
                    </label>
                    <input
                        id='input-file'
                        type='file'
                        name='selectedFiles'
                        onChange={(e) =>
                            onMultipleFileSelect(e, 'ACCESSORIES_DESIGN')
                        }
                        multiple
                        onClick={(e) => (e.target.value = null)}
                    />
                </div>
                <div className='ml-2 save-note'>
                    <button
                        // className={`text-white ${
                        //     post.length ? 'brand-bg-color' : ''
                        // }`
                        className='button primary icon-only'
                        onClick={handlePost}
                        // disabled={!post.length}
                    >
                        <SendIcon />
                    </button>
                    <div
                        className='close-note cursor-pointer ml-3'
                        onClick={() => {
                            onCloseNote()
                        }}
                    >
                        <svg
                            width='14'
                            height='15'
                            viewBox='0 0 14 15'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z'
                                fill='#222222'
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewPostSection
