import React, { useState } from 'react'

import ModalContainer from '../../../../app/common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../icons/close.svg'
import { NavLink } from 'react-router-dom'

const ColorSizeBreakdown = () => {
    const [showSizewiseModal, setShowSizewiseModal] = useState(false)
    const [showDesignwiseModal, setShowDesignwiseModal] = useState(false)
    const [showColorwiseModal, setShowColorwiseModal] = useState(false)

    const handleClose = () => {
        setShowSizewiseModal(false)
        setShowDesignwiseModal(false)
        setShowColorwiseModal(false)
    }

    return (
        <>
            <div className='mt-4'>
                <button
                    className='button primary mr-3'
                    onClick={(e) => setShowSizewiseModal(true)}
                >
                    Size-wise price
                </button>
                <button
                    className='button primary mr-3'
                    onClick={(e) => setShowDesignwiseModal(true)}
                >
                    Design wise price
                </button>
                <button
                    className='button primary mr-3'
                    onClick={(e) => setShowColorwiseModal(true)}
                >
                    Color wise price
                </button>
            </div>

            {/* Size-wise price modal */}
            {showSizewiseModal && (
                <ModalContainer className='create-folder-modal'>
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Color & Size Breakdown
                                </h3>
                                <p className='regular-12 gray_300'>
                                    Size-wise price
                                </p>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body !pt-0 !pb-6'>
                            <table className='table small-padding'>
                                <thead>
                                    <tr>
                                        <th>Color</th>
                                        <th>2XS</th>
                                        <th>M</th>
                                        <th>L</th>
                                        <th>XL</th>
                                        <th>2XL</th>
                                        <th>3XL</th>
                                        <th>4XL</th>
                                        <th>5XL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Blue</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className='weight-500 black'>
                                                Price
                                            </span>
                                        </td>
                                        <td>
                                            <span className='secondary'>
                                                $45
                                            </span>
                                        </td>
                                        <td>
                                            <span className='secondary'>
                                                $45
                                            </span>
                                        </td>
                                        <td>
                                            <span className='secondary'>
                                                $45
                                            </span>
                                        </td>
                                        <td>
                                            <span className='secondary'>
                                                $45
                                            </span>
                                        </td>
                                        <td>
                                            <span className='secondary'>
                                                $45
                                            </span>
                                        </td>
                                        <td>
                                            <span className='secondary'>
                                                $45
                                            </span>
                                        </td>
                                        <td>
                                            <span className='secondary'>
                                                $45
                                            </span>
                                        </td>
                                        <td>
                                            <span className='secondary'>
                                                $45
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalContainer>
            )}

            {/* Design wise price modal */}
            {showDesignwiseModal && (
                <ModalContainer className='create-folder-modal'>
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Color & Size Breakdown
                                </h3>
                                <p className='regular-12 gray_300'>
                                    Design wise price
                                </p>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body !pt-0 !pb-6'>
                            <table className='table small-padding'>
                                <thead>
                                    <tr>
                                        <th>Color</th>
                                        <th>2XS</th>
                                        <th>M</th>
                                        <th>L</th>
                                        <th>XL</th>
                                        <th>2XL</th>
                                        <th>3XL</th>
                                        <th>4XL</th>
                                        <th>5XL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Blue</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className='weight-500 black'>
                                                Unit Price
                                            </span>
                                        </td>
                                        <td colSpan='8' className='text-center'>
                                            <span className='secondary'>
                                                $45
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalContainer>
            )}

            {/* Color wise price modal */}
            {showColorwiseModal && (
                <ModalContainer className='create-folder-modal'>
                    <div className='common-popup'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                <h3 className='semibold-16 mb-0'>
                                    Color & Size Breakdown
                                </h3>
                                <p className='regular-12 gray_300'>
                                    Color wise price
                                </p>
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body !pt-0 !pb-6'>
                            <table className='table small-padding'>
                                <thead>
                                    <tr>
                                        <th>Color</th>
                                        <th>2XS</th>
                                        <th>M</th>
                                        <th>L</th>
                                        <th>XL</th>
                                        <th>2XL</th>
                                        <th>3XL</th>
                                        <th>4XL</th>
                                        <th>
                                            <span className='weight-500 black'>
                                                Price
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Blue</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>
                                            <span className='secondary'>
                                                $45
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Blue</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>
                                            {' '}
                                            <span className='secondary'>
                                                $45
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    )
}

export default ColorSizeBreakdown
