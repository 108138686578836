import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { getFileType, getIconByFileType } from '../../../helpers/Util'

const MoreDesign = ({ toggleModal, openModal, imageList }) => {
    const renderImageList = () => {
        return imageList?.map((image, index) => {
            const fileType = getFileType(image)
            return fileType === 'IMAGE' || fileType === 'NO_FILE' ? (
                <a href={image} target='_blank' rel='noreferrer'>
                    <img src={image} alt='' key={`image_list_${index}`} />
                </a>
            ) : (
                <a href={image} target='_blank' rel='noreferrer'>
                    <img
                        src={getIconByFileType(fileType)}
                        alt=''
                        key={`image_list_${index}`}
                    />
                </a>
            )
        })
    }

    return (
        <>
            <div
                className='modal show custom-modal-backdrop fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto'
                id='moreDesign'
                tabIndex='-1'
                aria-labelledby='exampleModalCenterTitle'
                aria-modal='true'
                role='dialog'
                style={{ display: 'block' }}
            >
                <div className='modal-dialog max-w-[600px] modal-dialog-centered relative w-auto pointer-events-none'>
                    <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current'>
                        <div className='modal-header flex flex-shrink-0 items-center justify-between bg-primaryColor-shade-300 p-4'>
                            <h5
                                className='text-xl font-bold leading-normal text-primaryColor uppercase'
                                id='exampleModalScrollableLabel'
                            >
                                All designs
                            </h5>
                            <button
                                onClick={toggleModal}
                                type='button'
                                className='btn-close box-content w-4 h-4 p-1 !mr-0.5 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            />
                        </div>
                        <div className='modal-body relative'>
                            <div className='more-designs-popup'>
                                <div className='designs-grid custom-scroll overflow-y-auto'>
                                    {renderImageList()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MoreDesign
