// this file will be used to define the action types

//Home Page Action Types
export const SET_MATERIAL_LIST = 'SET_MATERIAL_LIST'
export const SET_SELECTED_MATERIAL_ID = 'SET_SELECTED_MATERIAL_ID'
export const SET_SELECTED_FABRIC = 'SET_SELECTED_FABRIC'

//Order Page Action Types
export const FETCH_ORDER_LIST = 'FETCH_ORDER_LIST'
export const ADD_TIMELINE_DATA_BY_INDEX = 'ADD_TIMELINE_DATA_BY_INDEX'
export const ADD_NEW_COMMENT = 'ADD_NEW_COMMENT'
export const FETCH_STEP_INFO = 'FETCH_STEP_INFO'
export const TOGGLE_DESIGN_SELECTION = 'TOGGLE_DESIGN_SELECTION'
export const SELECT_ALL_DESIGN = 'SELECT_ALL_DESIGN'
export const CLEAR_DESIGN_SELECTION = 'CLEAR_DESIGN_SELECTION'
export const FETCH_ORDER_INFO_LIST = 'FETCH_ORDER_INFO_LIST'
export const FETCH_TIMELINE_LIST = 'FETCH_TIMELINE_LIST'
export const CLEAR_TIMELINE_DATA = 'CLEAR_TIMELINE_DATA'
export const GENERATE_TIMELINE_PARAMS = 'GENERATE_TIMELINE_PARAMS'
export const SET_DEFAULT_QUOTES = 'SET_DEFAULT_QUOTES'
export const REMOVE_PRODUCT_FROM_ORDER = 'REMOVE_PRODUCT_FROM_ORDER'
export const ON_TOGGLE_SET_MEMBER_TAB = 'ON_TOGGLE_SET_MEMBER_TAB'

// Moodboard Action Types
export const SET_MOODBOARD_LIST = 'SET_MOODBOARD_LIST'
export const UPDATE_MOODBOARD_LIST = 'UPDATE_MOODBOARD_LIST'
export const SET_MOODBOARD_BY_ID = 'SET_MOODBOARD_BY_ID'
export const SET_MOODBOARD_COLOR = 'SET_MOODBOARD_COLOR'
export const SET_MOODBOARD_ADDED_IMAGE = 'SET_MOODBOARD_ADDED_IMAGE'
export const UPDATE_SELECTED_MOODBOARD_STATE = 'UPDATE_SELECTED_MOODBOARD_STATE'
export const SET_COLOR_CODES = 'SET_COLOR_CODES'
export const SET_MOODBOARD_FABRICS = 'SET_MOODBOARD_FABRICS'
export const SET_FAVOURITE_MOODBOARD = 'SET_FAVOURITE_MOODBOARD'
export const UNSET_FAVOURITE_MOODBOARD = 'UNSET_FAVOURITE_MOODBOARD'
export const SET_ALL_MATERIAL_CATEGORY = 'SET_ALL_MATERIAL_CATEGORY'
export const SET_ALL_MATERIAL_SUB_CATEGORY = 'SET_ALL_MATERIAL_SUB_CATEGORY'
export const SET_FABRIC_SEARCH_FILTER = 'SET_FABRIC_SEARCH_FILTER'
export const SET_ALL_FILTER_DATA = 'SET_ALL_FILTER_DATA'
export const SET_MOODBOARD_FILTER_SELECTED_FIELDS =
    'SET_MOODBOARD_FILTER_SELECTED_FIELDS'
export const SET_TAG_LIST = 'SET_TAG_LIST'

//Collection action types
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'
export const SET_COLLECTION_LIST = 'SET_COLLECTION_LIST'
export const SET_PRODUCTS_BY_COLLECTION_ID = 'SET_PRODUCTS_BY_COLLECTION_ID'
export const SET_SELECT_PRODUCT = 'SET_SELECT_PRODUCT'
export const SET_ALL_SELECT_PRODUCT = 'SET_ALL_SELECT_PRODUCT'
export const TOGGLE_FAVOURITE_COLLECTION = 'TOGGLE_FAVOURITE_COLLECTION'
export const TOGGLE_FAVOURITE_PRODUCT_BY_ID = 'TOGGLE_FAVOURITE_PRODUCT_BY_ID'
export const SET_COLLECTION_COUNT = 'SET_COLLECTION_COUNT'
export const ADD_NEWLY_CREATED_COLLECTION = 'ADD_NEWLY_CREATED_COLLECTION'
export const CLEAT_COLLECTION_STORE = 'CLEAT_COLLECTION_STORE'
export const REMOVE_COLLECTION_BY_ID = 'REMOVE_COLLECTION_BY_ID'

//Qoute Action types
export const SET_QUOTE_LIST = 'SET_QUOTE_LIST'
export const SET_QUOTE_LIST_PAGE_ACTIVE_TAB = 'SET_QUOTE_LIST_PAGE_ACTIVE_TAB'
export const SET_QUOTE_DETAILS = 'SET_QUOTE_DETAILS'
export const SET_SELECT_PRODUCT_FROM_QUOTE = 'SET_SELECT_PRODUCT_FROM_QUOTE'
export const SET_ALL_PRODUCT_FROM_QUOTE = 'SET_ALL_PRODUCT_FROM_QUOTE'
export const CLEAR_SELECTED_PRODUCT_FROM_QUOTE =
    'CLEAR_SELECTED_PRODUCT_FROM_QUOTE'
export const CLEAR_QUOTE_DETAILS = 'CLEAR_QUOTE_DETAILS'

//Sample Action types
export const SET_SAMPLE_LIST = 'SET_SAMPLE_LIST'
export const SET_SAMPLE_LIST_PAGE_ACTIVE_TAB = 'SET_SAMPLE_LIST_PAGE_ACTIVE_TAB'
export const SET_SAMPLE_DETAILS = 'SET_SAMPLE_DETAILS'
export const SET_SELECT_PRODUCT_FROM_SAMPLE = 'SET_SELECT_PRODUCT_FROM_SAMPLE'
export const SET_ALL_PRODUCT_FROM_SAMPLE = 'SET_ALL_PRODUCT_FROM_SAMPLE'
export const CLEAR_SELECTED_PRODUCT_FROM_SAMPLE =
    'CLEAR_SELECTED_PRODUCT_FROM_SAMPLE'
export const CLEAR_SAMPLE_DETAILS = 'CLEAR_SAMPLE_DETAILS'

//Common Filter
export const SET_SEASON_LIST = 'SET_SEASON_LIST'
export const SET_MARKET_LIST = 'SET_MARKET_LIST'
export const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST'
export const SET_SUB_CATEGORY_LIST = 'SET_SUB_CATEGORY_LIST'
export const SET_AGE_GROUP_LIST = 'SET_AGE_GROUP_LIST'
export const SET_ALL_FABRIC_TYPES = 'SET_ALL_FABRIC_TYPES'
export const SET_ALL_DESIGN_LOOK = 'SET_ALL_DESIGN_LOOK'
export const SET_FABRIC_LIST = 'SET_FABRIC_LIST'
export const SET_FILTER_FIELD = 'SET_FILTER_FIELD'
export const REMOVE_FILTER_FIELD = 'REMOVE_FILTER_FIELD'
export const CLEAR_FILTER_FIELDS = 'CLEAR_FILTER_FIELDS'
export const CLEAR_FILTER_FIELD = 'CLEAR_FILTER_FIELD'
export const IS_FILTER_SUBMIT_BUTTON_CLICKED = 'IS_FILTER_SUBMIT_BUTTON_CLICKED'
export const ADD_FILTER_FIELDS = 'ADD_FILTER_FIELDS'
export const SET_DEPARTMENTS_LIST = 'SET_DEPARTMENTS_LIST'

//Color Picker
export const SET_COLOR_LIST = 'SET_COLOR_LIST'
export const SET_SELECTED_COLOR = 'SET_SELECTED_COLOR'
export const CLEAR_SELECTED_COLOR_LIST = 'CLEAR_SELECTED_COLOR_LIST'

//Teams
export const SET_TEAM_MEMBER_LIST = 'SET_TEAM_MEMBER_LIST'

//Favourite
export const SET_FAVOURITE_COUNT = 'SET_FAVOURITE_COUNT'
export const SET_ALL_FAVOURITE_COUNT = 'SET_ALL_FAVOURITE_COUNT'
export const SET_FAVOURITE_STYLES = 'SET_FAVOURITE_STYLES'
export const UPDATE_FAVOURITE_COUNT_BY_TYPE = 'UPDATE_FAVOURITE_COUNT_BY_TYPE'
export const REMOVE_FAVOURITE_STYLE_BY_ID = 'REMOVE_FAVOURITE_STYLE_BY_ID'

//Notifications
export const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA'
export const SET_NOTIFICATION_REPLY_COMMENT = 'SET_NOTIFICATION_REPLY_COMMENT'

// Conversation
export const SET_CURRENT_ACTIVE_PAGE = 'SET_CURRENT_ACTIVE_PAGE'
export const NEW_INCOMING_MESSAGE_SUCCESS = 'NEW_INCOMING_MESSAGE_SUCCESS'
export const CLEAR_ALL_CHATS = 'CLEAR_ALL_CHATS'

//Favourite
export const SET_SELECTED_FAVOURITE = 'SET_SELECTED_FAVOURITE'
