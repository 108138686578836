import React from 'react'
import { ReactComponent as SearchIcon } from '../../../icons/search.svg'
import { ReactComponent as CollectionsIcon } from '../../../icons/collections.svg'
import { ReactComponent as CloseIcon } from '../../../icons/close.svg'

const TopFilter = () => {
    return (
        <div className='filter-top-header'>
            <div className='chips-and-action'>
                <div className='chips'>
                    <span className='chip'>New In</span>
                    <span className='chip'>Age Group</span>
                    <span className='chip active'>Category</span>
                    <span className='chip'>Season</span>
                    <span className='chip'>Fabric</span>
                </div>
                <div className='actions'>
                    <div className='serach mr-4'>
                        <SearchIcon />
                    </div>
                    <button className='button secondary round mr-2'>
                        <span className='mr-2'>Collections</span>
                        <CollectionsIcon />
                    </button>
                    <button className='button primary round'>
                        <span>Build your range</span>
                    </button>
                </div>
            </div>
            <div className='filtered-chips'>
                <div className='filter-chip'>
                    <span>Button down shirt</span>
                    <CloseIcon />
                </div>
                <div className='filter-chip'>
                    <span>Cleric Shirt</span>
                    <CloseIcon />
                </div>
                <div className='filter-chip'>
                    <span>Resort</span>
                    <CloseIcon />
                </div>
            </div>
        </div>
    )
}

export default TopFilter
