import React from 'react'
import CollectionTags from '../../common/CollectionTags'
import { ReactComponent as FavouriteIcon } from '../../../../assets/icons/favorite.svg'
import { ReactComponent as FavouritedIcon } from '../../../../assets/icons/favorited.svg'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../../redux_toolkit/Loader'
import SharedCollectionThunk from '../../../redux_toolkit/V3/SharedCollection/SharedCollectionThunk'
import {
    CLEAR_FILTER_FIELDS,
    TOGGLE_FAVOURITE_COLLECTION
} from '../../../redux_toolkit/@types/action.types'
import { useNavigate } from 'react-router-dom'
import { FilterActionTypes } from '../../../redux_toolkit/V3/Filter'
import { getBreadCrumbs, setBreadCrumbRoutes } from '../../../helpers/Util'
import { toast } from 'react-toastify'
import { FavouriteThunks } from '../../../redux_toolkit/Favourite/FavouriteThunks'
import { GET_FAVOURITE_COUNT } from '../../../redux_toolkit/@types/thunk.types'

const SingleItemCollection = ({ collections }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const toggleFavourite = async (event, bool, collectionId) => {
        event.stopPropagation()
        try {
            dispatch(openLoader())
            await dispatch(
                SharedCollectionThunk[TOGGLE_FAVOURITE_COLLECTION](
                    bool,
                    collectionId
                )
            )
            if (bool) await dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]())
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const renderCollectionPhotos = (photos) => {
        return photos?.slice(0, 7)?.map((item) => {
            return (
                <img key={`photo_${item.id}`} src={item.docUrl} alt='style' />
            )
        })
    }

    const handleRoute = (collection) => {
        dispatch(FilterActionTypes[CLEAR_FILTER_FIELDS]())
        let newRoute = `/collections/${collection.id}`
        let visitingRoutes = getBreadCrumbs()
        visitingRoutes.push({
            key: newRoute,
            value: collection.name
        })

        setBreadCrumbRoutes(visitingRoutes)
        navigate(newRoute)
    }

    const renderCollections = () => {
        return collections?.map((item) => {
            return (
                <div
                    className={`collection-single-row-item mb-6 4xl:mb-10 ${
                        item?.coverDocumentResponse?.docUrl ? 'cover-photo' : ''
                    }`}
                    key={`collection_card_one_${item.id}`}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleRoute(item)
                    }}
                >
                    <div className='collection-content'>
                        <div className='collection-status'>
                            <CollectionTags item={item} />
                        </div>
                        <h3 className='collection-title'>{item.name}</h3>
                        <div className='details-info'>
                            <ul className='common-list-component'>
                                {item?.season && <li>{item?.season}</li>}
                                {item?.numOfDesign && (
                                    <li>
                                        {item.numOfDesign}{' '}
                                        {item.numOfDesign > 1
                                            ? 'Styles'
                                            : 'Style'}
                                    </li>
                                )}
                                {item?.ownerName && <li>{item.ownerName}</li>}
                            </ul>

                            <button
                                className='button secondary icon-only mt-2'
                                onClick={(e) =>
                                    toggleFavourite(
                                        e,
                                        !item.isFavorite,
                                        item.id
                                    )
                                }
                            >
                                {!item.isFavorite && <FavouriteIcon />}
                                {item?.isFavorite && <FavouritedIcon />}
                            </button>
                        </div>
                    </div>
                    <div className='collection-photos'>
                        {!item?.coverDocumentResponse?.docUrl &&
                            renderCollectionPhotos(item.documentResponseList)}
                        {item?.coverDocumentResponse?.docUrl && (
                            <img
                                key={`photo_${item.id}`}
                                src={item?.coverDocumentResponse?.docUrl}
                                alt='style'
                            />
                        )}
                    </div>
                </div>
            )
        })
    }

    return <>{renderCollections()}</>
}

export default SingleItemCollection
