import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate, useSearchParams } from 'react-router-dom'
// import { ReactComponent as FilterIcon } from '../../icons/Filter-24.svg'
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg'
import { ReactComponent as UploadIcon } from '../../../assets/images/upload.svg'
import { ReactComponent as FilterIcon } from '../../../assets/images/filter.svg'
import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg'
import { ReactComponent as HeartIconFill } from '../../../assets/icons/old/favourite.svg'
import { ReactComponent as HeartIcon } from '../../../assets/images/fav-border.svg'

// import thunkType
import {
    ADD_MOODBOARD_TO_FAVORITE,
    CREATE_NEW_MOODBOARD,
    GET_ALL_FAVOURITE_COUNT,
    GET_ALL_MOODBOARD_FILTER_DATA,
    GET_CATEGORY_LIST,
    GET_FAVOURITE_COUNT,
    GET_MARKET_LIST,
    GET_MOODBOARD_LIST,
    GET_SEASON_LIST,
    REMOVE_MOODBOARD_FROM_FAVORITE
} from '../../redux_toolkit/@types/thunk.types'

// importing thunks
import MoodboardThunks from '../../redux_toolkit/Moodboard/MoodboardThunks'
import {
    generateCollectionFilterParams,
    isPageReachBottom
} from '../../helpers/Util'
import MoodboardFilter from '../../common/filter/MoodboardFilter'
import { FilterOptionThunks } from '../../redux_toolkit/V3/Filter/FilterOptionThunks'
import FilterChip from '../../common/filter/core/FilterChip'
import { FilterActionTypes, useFilter } from '../../redux_toolkit/V3/Filter'
import UploadMoodboardModal from './UploadMoodboardModal'
import {
    SET_FILTER_FIELD,
    SET_MOODBOARD_LIST,
    UPDATE_FAVOURITE_COUNT_BY_TYPE,
    UPDATE_MOODBOARD_LIST
} from '../../redux_toolkit/@types/action.types'
import CreateMoodboardModal from './CreateMoodboardModal'
import { MoodboardActions } from '../../redux_toolkit/Moodboard'
import { FavouriteActionTypes } from '../../redux_toolkit/Favourite'
import { FavouriteThunks } from '../../redux_toolkit/Favourite/FavouriteThunks'
import { useLoader } from '../../redux_toolkit/Loader'

const Moodboard = () => {
    const dispatch = useDispatch()
    const filter = useFilter()
    const [showSearch, setShowSearch] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const isFavourite = searchParams.get('moodBoardFavourite')

    // selectors
    const moodboardList = useSelector((state) => state.moodboard.moodboardList)
    const totalElements = useSelector((state) => state.moodboard.totalElements)
    const currentPage = useSelector((state) => state.moodboard.currentPage)
    const totalPages = useSelector((state) => state.moodboard.totalPages)
    const pageRef = useRef(null)
    const navigate = useNavigate()
    const globalLoader = useLoader()
    const isLoading = useRef(null)

    const setIsDataLoading = (data) => {
        isLoading.current = data
    }

    useEffect(() => {
        setIsDataLoading(globalLoader)
    }, [globalLoader])

    let moodboardStatusToString = (status) => {
        switch (status) {
            case 'INITIALIZED':
            case 'PENDING':
            case 'APPROVED':
                return <></>
            case 'REQUESTED':
                return (
                    <span className='badge bg-pending border border-primaryColor'>
                        REQUESTED FOR COLLECTION
                    </span>
                )
            case 'COMPLETED':
                return (
                    <span className='badge bg-warning font-bold py-1'>
                        COLLECTION RECEIVED
                    </span>
                )
            default:
                break
        }
    }

    const handleMoodboardClick = (id) => {
        navigate(`/moodboard/${id}`)
    }

    const setFavourite = async (id) => {
        if (!isFavourite) {
            dispatch({
                type: FavouriteActionTypes[UPDATE_FAVOURITE_COUNT_BY_TYPE],
                payload: { type: 'moodBoard', liked: true }
            })
            dispatch(FavouriteThunks[GET_ALL_FAVOURITE_COUNT]())
        }
        await dispatch(MoodboardThunks[ADD_MOODBOARD_TO_FAVORITE](id))
    }

    const removeFavourite = async (id) => {
        await dispatch(MoodboardThunks[REMOVE_MOODBOARD_FROM_FAVORITE](id))
        if (isFavourite) {
            let modifiedMoodboardList = moodboardList.filter(
                (moodboard) => moodboard.id !== id
            )
            dispatch({
                type: MoodboardActions[UPDATE_MOODBOARD_LIST],
                payload: modifiedMoodboardList,
                merge: false
            })
        } else {
            dispatch({
                type: FavouriteActionTypes[UPDATE_FAVOURITE_COUNT_BY_TYPE],
                payload: { type: 'moodBoard', liked: false }
            })
            dispatch(FavouriteThunks[GET_ALL_FAVOURITE_COUNT]())
        }
    }

    const handleScroll = () => {
        if (isPageReachBottom()) {
            let { currentPage, totalPages, filter, isFavourite } =
                pageRef.current
            if (currentPage < totalPages && !isLoading.current) {
                let defaultParams = '?size=20&page=' + (currentPage + 1)
                if (isFavourite)
                    defaultParams = defaultParams + '&isFavorite=true'
                let params = generateCollectionFilterParams(
                    filter,
                    defaultParams
                )
                dispatch(MoodboardThunks[GET_MOODBOARD_LIST](params, true))
            }
        }
    }

    const showStyleNo = (noOfStyle) => {
        if (!noOfStyle) return ''
        if (noOfStyle === 1) return '1 Style'
        return noOfStyle + ' Styles'
    }

    const handleSearch = async (e) => {
        let data = {
            name: e.target.name,
            value: e.target.value,
            type: e.target.type
        }
        await dispatch(FilterActionTypes[SET_FILTER_FIELD](data))
    }

    useEffect(() => {
        if (filter.search !== undefined) {
            let defaultParams = '?size=20&page=0'
            if (isFavourite) {
                defaultParams = defaultParams + '&isFavorite=true'
            }
            let params = generateCollectionFilterParams(filter, defaultParams)
            dispatch(MoodboardThunks[GET_MOODBOARD_LIST](params, false))
        }
    }, [filter.search])

    useEffect(() => {
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        pageRef.current = { currentPage, totalPages, filter, isFavourite }
    }, [currentPage, totalPages, filter, isFavourite])

    useEffect(() => {
        let data = { name: 'sortBy', value: 'id,desc', type: 'radio' }
        dispatch(FilterActionTypes[SET_FILTER_FIELD](data))

        let defaultParams = '?size=20&page=0'
        if (isFavourite) defaultParams = defaultParams + '&isFavorite=true'
        let params = generateCollectionFilterParams(filter, defaultParams)
        dispatch(MoodboardThunks[GET_MOODBOARD_LIST](params))
        setTimeout(() => dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]()), 250)
    }, [isFavourite])

    useEffect(() => {
        dispatch(MoodboardThunks[GET_ALL_MOODBOARD_FILTER_DATA]())
        dispatch(FilterOptionThunks[GET_SEASON_LIST]())
        dispatch(FilterOptionThunks[GET_MARKET_LIST]())
        dispatch(FilterOptionThunks[GET_CATEGORY_LIST]())
    }, [])

    const columnClassName = (moodboard) => {
        if (moodboard?.moodboardImageList?.length > 0) return 'columns-1'
        return 'columns-3'
    }

    const renderImages = (moodboard) => {
        if (moodboard?.moodboardImageList?.length > 0) {
            let image = moodboard.moodboardImageList[0]
            return (
                <img
                    key={image.id}
                    className='w-full mb-1'
                    src={image.docUrl}
                />
            )
        } else if (moodboard?.productImageList?.length > 0) {
            return moodboard.productImageList.map((image) => {
                return (
                    <img
                        key={image.id}
                        className='w-full mb-1'
                        src={image.docUrl}
                    />
                )
            })
        }
    }

    const generateEmptyState = (title, description) => {
        return (
            <div className='w-full'>
                <div className='flex items-center justify-center h-full pt-52 5xl:pt-60'>
                    <div className='max-w-[582px] text-center relative'>
                        <h1 className='text-4xl 5xl:text-5xl text-primaryColor font-bold  mb-4'>
                            {title}
                        </h1>
                    </div>
                </div>
            </div>
        )
    }

    const renderEmptyMessage = () => {
        if (!globalLoader) {
            if (isFavourite) {
                let title = 'No moodboard found'
                let description = 'You have not made any design favourite'
                return generateEmptyState(title, description)
            } else {
                let title = 'No moodboard found'
                let description =
                    'You have not created or uploaded any moodboard yet'
                return generateEmptyState(title, description)
            }
        }
        return <></>
    }

    return (
        <div className='container-fluid bg-primaryColor-shade-300'>
            <div className='body-container'>
                <div className='filter'>
                    <div className='flex flex-wrap 2xl:flex-row xl:items-center justify-between gap-2 xl:gap-6'>
                        <div className='text-base md:text-xl text-primaryColor whitespace-nowrap order-2 xl:order-1'>
                            {totalElements} Moodboards
                        </div>
                        <div className='flex flex-wrap xl:flex-nowrap justify-end gap-2 w-full xl:w-[80%] 2xl:w-[85%] order-1 xl:order-2'>
                            <FilterChip chipType='moodboard' />
                            <div className='flex items-center gap-2'>
                                <input
                                    type='text'
                                    className={`form-field bg-transparent border border-primaryColor w-[220px] 4xl:w-[300px] ${
                                        showSearch ? '' : 'hidden'
                                    }`}
                                    id='text'
                                    placeholder='Search ...'
                                    name='search'
                                    value={filter.search ? filter.search : ''}
                                    onChange={handleSearch}
                                />
                                <button
                                    type='button'
                                    className='btn bg-transparent p-2 px-3 4xl:px-5 font-normal border border-primaryColor text-primaryColor'
                                    onClick={() =>
                                        setShowSearch((prev) => !prev)
                                    }
                                >
                                    <SearchIcon />
                                </button>
                                <button
                                    data-bs-toggle='modal'
                                    data-bs-target='#SortFilter'
                                    type='button'
                                    className='btn bg-transparent p-2 px-3 4xl:px-5 font-normal border border-primaryColor text-primaryColor'
                                >
                                    <FilterIcon />
                                </button>
                                <div className='h-[60px] w-[1px] bg-primaryColor-shade-200 mx-3'></div>
                                {!isFavourite && (
                                    <button
                                        type='button'
                                        data-bs-toggle='modal'
                                        data-bs-target='#UploadMoodboard'
                                        className='btn bg-transparent font-normal border border-primaryColor text-primaryColor flex justify-between items-center'
                                    >
                                        <span>Upload</span>
                                        <span className='ml-4'>
                                            <UploadIcon />
                                        </span>
                                    </button>
                                )}
                                {!isFavourite && (
                                    <button
                                        type='button'
                                        className='btn flex justify-between items-center'
                                        data-bs-toggle='modal'
                                        data-bs-target='#createMoodboardModal'
                                        // onClick={(e) => {
                                        //     e.preventDefault()
                                        //     createNewMoodboard()
                                        // }}
                                    >
                                        <span>Create</span>
                                        <span className='ml-2'>
                                            <PlusIcon />
                                        </span>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* here starts the redering cards part */}
                <div className='mt-3 5xl:mt-4'>
                    {moodboardList.length > 0
                        ? moodboardList.map((moodboard) => {
                              return (
                                  <div
                                      className='pb-4'
                                      key={moodboard.id}
                                      onClick={(e) => {
                                          e.preventDefault()
                                          handleMoodboardClick(moodboard.id)
                                      }}
                                  >
                                      <div className='collection-box grid grid-cols-1 md:grid-cols-2 bg-white'>
                                          <div className='p-6 xl:p-10 relative flex items-center'>
                                              <div>
                                                  <div className='flex gap-3'>
                                                      {moodboardStatusToString(
                                                          moodboard.status
                                                      )}
                                                  </div>
                                                  <h1 className='text-2xl xl:text-px28 4xl:text-4xl text-primaryColor font-bold mt-3  md:leading-[54px]'>
                                                      {moodboard.name}
                                                  </h1>
                                                  <div className='paragraph-grid-overlay-white relative mb-4'>
                                                      <p>
                                                          {
                                                              moodboard.description
                                                          }
                                                      </p>
                                                  </div>
                                                  <div className='flex items-center text-base md:text-xl text-primaryColor gap-3 md:gap-5'>
                                                      <span>
                                                          {showStyleNo(
                                                              moodboard?.noOfProduct
                                                          )}
                                                      </span>
                                                  </div>

                                                  {moodboard.colorResponseList
                                                      .length > 0 && (
                                                      <div className='color-list flex gap-1 mt-10 md:mt-16'>
                                                          {moodboard.colorResponseList
                                                              .slice(0, 4)
                                                              .map((color) => {
                                                                  return (
                                                                      <span
                                                                          className='color-circle'
                                                                          key={
                                                                              color.id
                                                                          }
                                                                          style={{
                                                                              background:
                                                                                  color.hexCode
                                                                          }}
                                                                      ></span>
                                                                  )
                                                              })}
                                                      </div>
                                                  )}
                                              </div>
                                          </div>
                                          <div className='overflow-hidden relative  h-[300px] tab:h-[432px] xl:!h-[524px]  pt-0 md:pt-4 p-4 bg-white'>
                                              <div className='image-grid-overlay-white'>
                                                  <div
                                                      className={
                                                          columnClassName(
                                                              moodboard
                                                          ) +
                                                          ' gap-1 direction-rtl'
                                                      }
                                                  >
                                                      {renderImages(moodboard)}
                                                  </div>
                                              </div>
                                              <div className='w-[40px] h-[40px] bg-white border border-white-shade-100 flex justify-center items-center absolute right-[35px] top-[35px] cursor-pointer'>
                                                  <span>
                                                      {moodboard.isFavorite && (
                                                          <HeartIconFill
                                                              onClick={(e) => {
                                                                  e.stopPropagation()
                                                                  removeFavourite(
                                                                      moodboard.id
                                                                  )
                                                              }}
                                                          />
                                                      )}
                                                      {!moodboard?.isFavorite && (
                                                          <HeartIcon
                                                              onClick={(e) => {
                                                                  e.stopPropagation()
                                                                  setFavourite(
                                                                      moodboard.id
                                                                  )
                                                              }}
                                                          />
                                                      )}
                                                  </span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              )
                          })
                        : renderEmptyMessage()}
                </div>
            </div>

            <MoodboardFilter />
            <UploadMoodboardModal />
            <CreateMoodboardModal />
        </div>
    )
}

export default Moodboard
