import React, { useEffect, useState } from 'react'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import Http from '../../helpers/Http'
import { toast } from 'react-toastify'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { useDispatch } from 'react-redux'

const Profile = ({ onCloseModal }) => {
    const [userInfo, setUserInfo] = useState('')
    const [name, setName] = useState('')
    const [designation, setDesignation] = useState('')
    const [contract, setContract] = useState('')
    const [email, setEmail] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        Http.GET('userInfo')
            .then(async (response) => {
                setUserInfo(response.data)
                if (response.data) {
                    setName(response.data.name)
                    setDesignation(response.data.designation)
                    setContract(response.data.phone)
                    setEmail(response.data.email)
                }
            })
            .catch(() => {})
    }, [])

    const updateProfileInfo = (event) => {
        event.preventDefault()
        let data = {
            name: name,
            phone: contract,
            designation: designation
        }
        Http.POST('updateBasicInfo', data)
            .then(() => {
                Http.GET('userInfo').then(async (response) => {
                    localStorage.setItem(
                        'userInfo',
                        JSON.stringify(response.data)
                    )
                    if (onCloseModal) onCloseModal()
                })
                toast.success('Updated successfully')
            })
            .catch()
    }

    const updateProfileImage = async (event) => {
        let file = event.target.files[0]
        if (file) {
            let data = {
                name: file?.name,
                docMimeType: file?.type,
                documentType: 'PROFILE_PHOTO',
                base64Str: ''
            }
            await dispatch(openLoader())
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                data.base64Str = reader.result
                Http.POST('updateProfilePicture', data)
                    .then(() => {
                        Http.GET('userInfo').then(async (response) => {
                            setUserInfo(response.data)
                            localStorage.setItem(
                                'userInfo',
                                JSON.stringify(response.data)
                            )
                        })
                        toast.success('Updated successfully')
                    })
                    .finally(() => {
                        dispatch(closeLoader())
                    })
            }
            reader.onerror = function (error) {
                toast.error(error)
                dispatch(closeLoader())
            }
        }
    }

    return (
        <div className='common-popup'>
            <div className='common-popup-header border-bottom-solid d-flex justify-content-between'>
                <div className='popup-tilte'>
                    <h3 className='text-base sm:text-lg weight-500 mb-0'>
                        Personal information
                    </h3>
                </div>
                <div
                    className='close-btn cursor-pointer'
                    onClick={onCloseModal}
                >
                    <CloseIcon />
                </div>
            </div>

            <div className='common-popup-body !pt-0 !pb-6'>
                <div>
                    <div className='flex flex-wrap sm:flex-nowrap mt-5 sm:mt-10'>
                        <span className='w-[138px] min-w-[138px] h-[138px] border border-white rounded-full inline-block relative'>
                            <img
                                src={userInfo?.profilePicDocument?.docUrl}
                                alt=''
                                className='object-cover w-full h-full rounded-full'
                            />
                            <span className='flex items-center justify-center border-solid-1 absolute bottom-1 right-1 bg-white w-8 h-8 rounded-full text-center py-2 z-10 border-solid-1'>
                                <label
                                    htmlFor='input-file'
                                    className='cursor-pointer'
                                >
                                    <EditIcon className='inline-block' />
                                    <input
                                        id='input-file'
                                        type='file'
                                        name='image-upload'
                                        accept='image/*'
                                        style={{ display: 'none' }}
                                        onChange={(event) =>
                                            updateProfileImage(event)
                                        }
                                    />
                                </label>
                            </span>
                        </span>
                        <div className='sm:ml-10 mt-6 sm:mt-0 w-full'>
                            <div className='form-group'>
                                <label>Full name</label>
                                <input
                                    type='text'
                                    value={name}
                                    name='name'
                                    onChange={(event) => {
                                        setName(event.target.value)
                                    }}
                                />
                            </div>
                            <div className='form-group'>
                                <label>Designation</label>

                                <input
                                    type='text'
                                    value={designation}
                                    name='designation'
                                    onChange={(event) => {
                                        setDesignation(event.target.value)
                                    }}
                                    placeholder='e.g. Project Manager'
                                />
                            </div>
                            <div className='form-group'>
                                <label>Email</label>
                                <input
                                    type='text'
                                    value={email}
                                    readOnly={true}
                                    name='contract'
                                    disabled
                                />
                            </div>
                            <div className='form-group'>
                                <label>Contact number</label>
                                <input
                                    type='text'
                                    value={contract}
                                    onChange={(event) => {
                                        setContract(event.target.value)
                                    }}
                                    name='contract'
                                    placeholder='e.g. +123456789'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='common-popup-footer border-top-solid'>
                <button
                    className='button primary'
                    onClick={(event) => updateProfileInfo(event)}
                >
                    Save changes
                </button>
            </div>
        </div>
    )
}

export default Profile
