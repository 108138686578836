import Http from '../../../helpers/Http'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../constant'
import axios from 'axios'

export const doFavourite = async (params) => {
    try {
        const { data } = await Http.POST('doFavourite', '', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const undoFavourite = async (designId, folderId) => {
    try {
        let urlParams = '' + designId
        if (folderId && folderId !== 'my-favourite') {
            urlParams += `?folderId=${folderId}`
        }
        const { data } = await Http.POST('undoFavourite', '', urlParams)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const addNewFavouriteFolder = async (params) => {
    try {
        const { data } = await Http.POST('addFavouriteFolder', '', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const changeFavouriteFolder = async (productId, folderId) => {
    try {
        let params = folderId ? `?folderId=${folderId}` : ''
        const { data } = await axios.post(
            `${BASE_URL}/product/liked/${productId}/change-folder${params}`
        )
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const fetchMyFavouriteFolders = async (params) => {
    try {
        const { data } = await Http.GET('getMyFavouriteFolder', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const fetchFolderDetailsById = async (params) => {
    try {
        const { data } = await Http.GET('getFolderDetailsByFolderId', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const fetchFavouriteFolderLists = async (params) => {
    try {
        const { data } = await Http.GET('fetchFavouriteFolderList', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const modifyFolderName = async (folderId, folderName) => {
    try {
        const { data } = await axios.post(
            `${BASE_URL}/user/favourites/folder/${folderId}/rename?name=${encodeURIComponent(
                folderName
            )}`
        )
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const removeFolder = async (folderId) => {
    try {
        const { data } = await axios.delete(
            `${BASE_URL}/user/favourites/folder/${folderId}`
        )
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const addMemberToFavourite = async (folderId, memberIds) => {
    try {
        const { data } = await axios.post(
            `${BASE_URL}/user/favourites/folder/${folderId}/add-member?memberIds=${memberIds}`
        )
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const inviteMemberFromFavourite = async (postData) => {
    try {
        const { data } = await Http.POST('inviteUserFromFavourite', postData)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const fetchFavouriteDetailsLists = async (params) => {
    try {
        const { data } = await Http.GET('fetchBuyerLikedDesigns', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const fetchFavouriteProductsByFolderId = async (params) => {
    try {
        const { data } = await Http.GET('fetchFavouriteProductId', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const addToDraft = async (params) => {
    try {
        const { data } = await Http.POST('addTodraft', '', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const removeFromdraft = async (params) => {
    try {
        const { data } = await Http.POST('removeFromdraft', '', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const fetchFavouriteDetailsListTableData = async (params) => {
    try {
        const { data } = await Http.GET('fetchBuyerLikedDesignsSummary', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}

export const updateFavourite = async (params) => {
    try {
        const { data } = await Http.POST('updateFavouriteData', '', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
        throw error
    }
}
