import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import {
    addNewFavouriteFolder,
    changeFavouriteFolder,
    fetchMyFavouriteFolders
} from '../../services/v3/Favourite/FavouriteService'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import { useFavourite } from '../../redux_toolkit/V3/DoFavourite'
import { setSystemUserPreferences } from '../../services/CommonService'
import { capitalizeFirstLetter, getUserPreference } from '../../helpers/Util'
import DesignImages from './DesignImages'

const AddToFavourite = ({ isVisible, setIsVisible }) => {
    const [isShowFolders, setIsShowFolders] = useState(false)
    const [isShowCreate, setIsShowCreate] = useState(false)
    const [folderList, setFolderList] = useState([])
    const [folderName, setFolderName] = useState('')
    const favouriteInfo = useFavourite()?.selectedProduct
    const [search, setSearch] = useState('')
    const isHovering = useRef(false)
    const timerRef = useRef(null)
    const dispatch = useDispatch()
    const timerInMs = 500000

    const fetchMyFolderList = async (params) => {
        dispatch(openLoader())
        const data = await fetchMyFavouriteFolders(params)
        setFolderList(data)
        dispatch(closeLoader())
    }

    useEffect(() => {
        fetchMyFolderList(`?page=0&size=5&search=${search}`)
    }, [search])

    const handleMouseEnter = () => {
        isHovering.current = true
        clearTimeout(timerRef.current)
    }

    const closeModal = () => {
        setFolderName('')
        setSearch('')
        setIsShowFolders(false)
        setIsShowCreate(false)
        setIsVisible(false)
    }

    const handleMouseLeave = () => {
        isHovering.current = false
        timerRef.current = setTimeout(() => {
            closeModal()
        }, timerInMs)
    }

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            closeModal()
        }, timerInMs)

        return () => clearTimeout(timerRef.current)
    }, [isVisible])

    const handleFolderCreate = async () => {
        if (folderName) {
            try {
                dispatch(openLoader())
                const data = await addNewFavouriteFolder(
                    `?name=${encodeURIComponent(folderName)}`
                )
                await changeFavouriteFolder(favouriteInfo.id, data.id)
                await setSystemUserPreferences({
                    defaultFavouriteFolder: { id: data.id, name: data.name }
                })
                await fetchMyFolderList(`?page=0&size=5&search=${search}`)
                dispatch(closeLoader())
                closeModal()
            } catch (e) {
                dispatch(closeLoader())
            }
        }
    }

    const onClickFolder = async (selectedFolder) => {
        if (selectedFolder) {
            try {
                dispatch(openLoader())
                let folderId = selectedFolder.id || null
                await changeFavouriteFolder(favouriteInfo.id, folderId)
                await setSystemUserPreferences({
                    defaultFavouriteFolder: {
                        id: selectedFolder.id,
                        name: selectedFolder.name
                    }
                })
                dispatch(closeLoader())
                closeModal()
            } catch (e) {
                dispatch(closeLoader())
            }
        }
    }

    const renderFolderLists = () => {
        return folderList?.map((item, index) => {
            return (
                <div
                    className='single-item cursor-pointer'
                    key={`my_folder_${index}`}
                    onClick={() => onClickFolder(item)}
                >
                    <p>{item.name}</p>
                </div>
            )
        })
    }
    return (
        <div>
            {isVisible && favouriteInfo !== null && (
                <div
                    className='added-to-favorite-popup shadow-medium p-4 bg-white'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className='d-flex justify-between items-center'>
                        <h3 className='regular-18 weight-500'>
                            Added to{' '}
                            {getUserPreference()?.defaultFavouriteFolder
                                ?.name || 'my favourite'}
                        </h3>
                        <div
                            className='close-btn cursor-pointer'
                            onClick={() => closeModal()}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                    <div className='d-flex items-center py-4'>
                        <div className='w-[80px] min-w-[80px]'>
                            <DesignImages
                                designInfo={{
                                    designDocuments:
                                        favouriteInfo?.designDocuments,
                                    isSet: favouriteInfo?.isSet
                                }}
                            />
                        </div>
                        <div className='ml-4'>
                            <p className='regular-16 weight-500'>
                                {capitalizeFirstLetter(favouriteInfo?.name)}
                            </p>
                            <ul className='common-list-component mt-1'>
                                {favouriteInfo?.categoryResponse?.name && (
                                    <li>
                                        {favouriteInfo?.categoryResponse?.name}
                                    </li>
                                )}
                                {favouriteInfo?.mainFabricType && (
                                    <li>{favouriteInfo?.mainFabricType}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <button
                        className='button secondary w-full'
                        onClick={() => setIsShowFolders((prev) => !prev)}
                    >
                        {getUserPreference()?.defaultFavouriteFolder?.name && (
                            <span className='mr-2'>
                                {
                                    getUserPreference()?.defaultFavouriteFolder
                                        ?.name
                                }
                            </span>
                        )}
                        {!getUserPreference()?.defaultFavouriteFolder?.name && (
                            <span className='mr-2'>My favorite</span>
                        )}
                        <ArrowDownIcon />
                    </button>

                    {isShowFolders && !isShowCreate && (
                        <div className='serach-folder mt-2'>
                            <div className='dropdown-box dropdown-menu scroll-y-label bg-white'>
                                <div className='search-box p-3 bg-white'>
                                    <div className='relative'>
                                        <div className='absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none'>
                                            <SearchIcon />
                                        </div>
                                        <input
                                            type='search'
                                            className='block w-full p-3 pr-10 text-sm'
                                            placeholder='Search'
                                            name='favourite-folder-search'
                                            id='favourite-folder-search'
                                            onChange={(e) => {
                                                e.stopPropagation()
                                                setSearch(e.target.value)
                                            }}
                                            autoFocus={true}
                                            value={search}
                                        />
                                    </div>
                                </div>
                                <div className='list-items scroll-y-label'>
                                    {renderFolderLists()}
                                </div>
                            </div>
                            <button
                                className='button link mt-2'
                                onClick={() => {
                                    setFolderName(search)
                                    setIsShowCreate((prev) => !prev)
                                }}
                            >
                                Create new folder
                            </button>
                        </div>
                    )}
                    {isShowCreate && (
                        <div className='create-new-folder'>
                            <div className='form-group mt-2'>
                                <label className='regular-14 gray_300 mb-2'>
                                    Create new folder
                                </label>

                                <input
                                    type='text'
                                    onChange={(e) =>
                                        setFolderName(e.target.value)
                                    }
                                    value={folderName}
                                    placeholder='Title'
                                    autoFocus={true}
                                />
                            </div>
                            <button
                                className='button primary mr-3'
                                onClick={handleFolderCreate}
                            >
                                Create
                            </button>
                            <button
                                className='button secondary'
                                onClick={() => setIsShowCreate(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default AddToFavourite
