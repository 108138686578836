import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { quoteReducer } from './QuoteReducer'

const initialState = {
    activeTab: '',
    quotes: {},
    quote: {},
    selectedProducts: [],
    selectedQuoteItemIds: []
}
const quoteSlice = createSlice({
    name: 'quote',
    initialState,
    reducers: quoteReducer
})

export const QouteActions = quoteSlice.actions

export const useQuoteSelector = () => useSelector((state) => state.quote)

export default quoteSlice.reducer
