import { isSetItem } from '../../helpers/Util'
import {
    ADD_NEW_COMMENT,
    ADD_TIMELINE_DATA_BY_INDEX,
    CLEAR_DESIGN_SELECTION,
    CLEAR_TIMELINE_DATA,
    FETCH_ORDER_INFO_LIST,
    FETCH_STEP_INFO,
    FETCH_TIMELINE_LIST,
    ON_TOGGLE_SET_MEMBER_TAB,
    SELECT_ALL_DESIGN,
    TOGGLE_DESIGN_SELECTION
} from '../@types/action.types'

export const TimelineReducers = {
    [ADD_TIMELINE_DATA_BY_INDEX]: (state, action) => {
        let timelineData = [...state.data]
        timelineData.splice(action.payload.index, 0, action.payload.data)
        return {
            ...state,
            data: timelineData
        }
    },
    [ADD_NEW_COMMENT]: (state, action) => {
        let data = [action.payload, ...state.data]
        return { ...state, data: data }
    },
    [FETCH_STEP_INFO]: (state, action) => {
        return {
            ...state,
            stepList: action.payload.data,
            selectedDesignNumber: action.payload.selectedDesignNumber
        }
    },
    [TOGGLE_DESIGN_SELECTION]: (state, action) => {
        let designList = []
        designList.push(action.payload.id)
        return {
            ...state,
            selectedDesignList: designList,
            rfqId: action.payload.design.rfqId,
            isSetParent: false,
            selectedSetItem: isSetItem(action.payload.design)
                ? [action.payload.design]
                : []
        }
    },
    [ON_TOGGLE_SET_MEMBER_TAB]: (state, action) => {
        const designIds = []
        designIds.push(action.payload.id)
        return {
            ...state,
            selectedDesignList: designIds,
            rfqId: action.payload.rfqId,
            isSetParent: action.payload.isSetParent
        }
    },
    [SELECT_ALL_DESIGN]: (state, action) => {
        return {
            ...state,
            selectedDesignList: action.payload
        }
    },
    [CLEAR_DESIGN_SELECTION]: (state, action) => {
        return {
            ...state,
            selectedDesignList: action.payload
        }
    },
    [FETCH_ORDER_INFO_LIST]: (state, action) => {
        let productList = action.payload?.orderProductList
        let selectedDesignList = []
        const selectedSetItem = []
        const queryProductId = action.payload?.paramProductId
        const findDesignByQueryParam = queryProductId
            ? productList.find((item) => {
                  if (parseInt(item.id) === queryProductId) {
                      return true
                  }
                  if (item.setMemberList) {
                      return item.setMemberList.some(
                          (member) => parseInt(member.id) === queryProductId
                      )
                  }
                  return false
              })
            : null
        if (action.payload?.defaultSelectedDesign) {
            selectedDesignList.push(
                parseInt(action.payload.defaultSelectedDesign)
            )
        } else if (productList.length > 0) {
            if (findDesignByQueryParam && isSetItem(findDesignByQueryParam)) {
                selectedDesignList.push(
                    findDesignByQueryParam?.setMemberList[0]?.id
                )
                selectedSetItem.push(
                    findDesignByQueryParam
                        ? findDesignByQueryParam
                        : productList[0]
                )
            } else if (
                findDesignByQueryParam &&
                !isSetItem(findDesignByQueryParam)
            ) {
                selectedDesignList.push(findDesignByQueryParam.id)
            } else if (!findDesignByQueryParam && isSetItem(productList[0])) {
                selectedDesignList.push(productList[0]?.setMemberList[0]?.id)
                selectedSetItem.push(productList[0])
            } else {
                selectedDesignList.push(productList[0].id)
            }
        }
        return {
            ...state,
            orderInfo: action.payload,
            selectedDesignList,
            selectedSetItem,
            rfqId: findDesignByQueryParam
                ? findDesignByQueryParam?.rfqId
                : productList[0].rfqId,
            isSetParent: false
        }
    },
    [FETCH_TIMELINE_LIST]: (state, action) => {
        let { payload } = action
        if (payload.merge === false) {
            return {
                ...payload.response,
                orderInfo: state.orderInfo,
                selectedDesignList: state.selectedDesignList,
                stepList: state.stepList,
                selectedDesignNumber: state.selectedDesignNumber,
                selectedSetItem: state.selectedSetItem,
                isSetParent: state.isSetParent,
                rfqId: state.rfqId
            }
        } else {
            return {
                ...payload.response,
                data: [...state.data, ...payload.response.data],
                orderInfo: state.orderInfo,
                selectedDesignList: state.selectedDesignList,
                stepList: state.stepList,
                selectedDesignNumber: state.selectedDesignNumber,
                selectedSetItem: state.selectedSetItem,
                isSetParent: state.isSetParent,
                rfqId: state.rfqId
            }
        }
    },
    [CLEAR_TIMELINE_DATA]: (state, action) => {
        return {}
    }
}
