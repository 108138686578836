/* eslint-disable quotes */
import React, { useEffect, useRef, useState } from 'react'
import ModalContainer from '../../common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { useSearchParams } from 'react-router-dom'
import {
    authUserInfo,
    convertDateTimeToLocal,
    extractContent,
    generateUniqueId,
    getMentionedUserIds,
    getMinsCountFromNow,
    getShortName,
    getTimeCount,
    onGetLocalFromUtc,
    readFileAsync
} from '../../helpers/Util'
import {
    ConversationActionTypes,
    getNewMessage
} from '../../redux_toolkit/Conversation'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { CLEAR_ALL_CHATS } from '../../redux_toolkit/@types/action.types'
import {
    addNewConversation,
    addPostReply,
    getConversations,
    getTypeWiseRequestBody
} from '../../services/Conversation'
import { SelectedFileViewComponent } from '../../common/SelectedFileViewComponent'
import { ReactComponent as Notification } from '../../../assets/icons/old/Notification.svg'
import { ReactComponent as CloseSmallWhiteIcon } from '../../../assets/icons/close.svg'
import Editor from '../../common/Editor'
import { ReactComponent as AttachedIcon } from '../../../assets/images/attached.svg'
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/arrow-left.svg'
import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg'
import { fetchUserSuggestions } from '../../services/CommonService'

const ConversationRightSidePanel = ({
    openModal,
    onCloseModal,
    id,
    type,
    onGoBack,
    pageId,
    conversationsType = 'PRODUCT_CONVERSATION'
}) => {
    const [selectedPostTag, setSelectedPostTag] = useState(type)
    const [selectedMentionedUser, setSelectedMentionedUser] = useState([])
    const [isBuyerMentioned, setIsBuyerMentioned] = useState(true)
    const [isNitexMentioned, setIsNitexMentioned] = useState(true)
    const [mentionToUsers, setMentionToUsers] = useState([])
    const [mentionSearch, setMentionSearch] = useState('')
    const [highlightId, setHighlightId] = useState(null)
    const [memberList, setMemberList] = useState([])
    const [minimize, setMinimize] = useState(true)
    const [replying, setReplying] = useState(null)
    const [readOnly, setReadOnly] = useState(true)
    const [chatInfo, setChatInfo] = useState({})
    const [members, setMembers] = useState([])
    const [expand, setExpand] = useState(true)
    const [loader, setLoader] = useState(true)
    const [files, setFiles] = useState([])
    const postInputRef = useRef(null)
    const dropdownRef = useRef(null)
    const [searchParams] = useSearchParams()
    const uniqueId = generateUniqueId()
    const newMsg = getNewMessage()
    const dispatch = useDispatch()
    const searchRef = useRef()

    const scrollDownInChatBody = () => {
        let container = document.getElementById('chat-body')
        let o1 = container.scrollHeight - container.clientHeight
        let elem = document.getElementById('chat-body')
        elem.scrollTop = o1
    }

    useEffect(() => {
        if (searchParams.get('showComment') === 'true') {
            setMinimize(false)
        }
        if (searchParams.get('postId') !== undefined) {
            setHighlightId(parseInt(searchParams.get('postId')))
            setTimeout(() => {
                scrollDownInChatBody()
            }, 1000)
        }
    }, [searchParams])

    useEffect(() => {
        ;(async () => {
            const data = await fetchUserSuggestions(
                `?isNitexUser=true&page=0&size=500&brandId=${
                    authUserInfo()?.brandResponse?.id
                }`
            )
            if (data?.length > 0) {
                let listOfMember = []
                data.map((member) =>
                    listOfMember.push({
                        id: member.email,
                        value: member.name,
                        email: member.email,
                        memberId: member.id
                    })
                )
                setMemberList(data)
                setMembers(listOfMember)
            }
        })()
    }, [])

    useEffect(() => {
        scrollDownInChatBody()
        const postResponse = newMsg.content
        const cloneChatInfo = JSON.parse(JSON.stringify(chatInfo))
        if (
            postResponse?.text &&
            authUserInfo()?.id !== postResponse?.postedBy.id
        ) {
            if (postResponse?.parentPostId) {
                cloneChatInfo?.data?.forEach((post) => {
                    if (
                        parseInt(post.id) ===
                        parseInt(postResponse?.parentPostId)
                    ) {
                        post.commentList.push(postResponse)
                        toast.success('New message received')
                    }
                })
            } else {
                cloneChatInfo?.data?.push(postResponse)
                cloneChatInfo.totalElements = cloneChatInfo.totalElements + 1
            }
            setChatInfo(JSON.parse(JSON.stringify(cloneChatInfo)))
            if (!postResponse?.parentPostId) {
                toast.success('New message received')
                setTimeout(() => {
                    dispatch({
                        type: ConversationActionTypes[CLEAR_ALL_CHATS]
                    })
                }, 1000)
            }
        }
    }, [newMsg?.content?.id])

    const generateParams = (page = 0) => {
        return `?page=${page}&size=10&sort=id,desc&postTag=${selectedPostTag}`
    }

    const getConversationInfo = async (params) => {
        setLoader(true)
        const conversations = await getConversations(
            conversationsType,
            id,
            params
        )
        conversations.data.reverse()
        if (chatInfo?.data) {
            conversations.data = [...conversations.data, ...chatInfo.data]
        }
        setChatInfo(conversations)
        setLoader(false)
    }

    useEffect(() => {
        getConversationInfo(generateParams()).then((r) => setLoader(false))
    }, [selectedPostTag])

    const clearQuillContent = () => {
        let elements = document.getElementsByClassName('ql-editor')
        elements[0].innerHTML = ''
        elements[0].classList.add('ql-blank')
    }

    const handleSubmit = () => {
        if (
            !isNitexMentioned &&
            !isBuyerMentioned &&
            !selectedMentionedUser.length > 0
        ) {
            toast.warning('Please specify at least one recipient.')
            return
        }
        let newComment =
            postInputRef.current === null
                ? ''
                : postInputRef?.current.toString().replace(/(<([^>]+)>)/gi, '')
        if (!newComment) {
            toast.warning('Comment required')
            return
        }
        setLoader(true)
        let message = postInputRef.current
        let body = {
            ...getTypeWiseRequestBody(type, id, pageId),
            documentDTOList: files,
            text: message.replace(/"/g, "'"),
            recipientList: [],
            taggedUserIdList:
                members.length > 0 ? getMentionedUserIds(message, members) : []
        }
        if (isNitexMentioned) {
            body.recipientList.push({ recipientType: 'NITEX' })
        }
        if (isBuyerMentioned) {
            body.recipientList.push({ recipientType: 'BUYER' })
        }
        selectedMentionedUser?.map((user) => {
            const userIdMap = {
                recipientType: 'INDIVIDUAL',
                userId: user.id
            }
            body.recipientList.push(userIdMap)
        })
        addNewConversation(id, body).then((response) => {
            let cloneChatInfo = { ...chatInfo }
            cloneChatInfo.totalElements =
                parseInt(cloneChatInfo.totalElements) + 1
            cloneChatInfo.data.push(response.payload)
            setTimeout(() => {
                scrollDownInChatBody()
            }, 100)
            setChatInfo(cloneChatInfo)
            setFiles([])
            clearQuillContent()
            postInputRef.current = null
            setLoader(false)
        })
    }

    const handleMentionTo = async (e) => {
        setMentionSearch(e.target.value)
        if (e.target.value) {
            let data = memberList.filter(
                (member) =>
                    member.email
                        .toUpperCase()
                        .indexOf(e.target.value.toUpperCase()) !== -1
            )
            data = data.filter(
                (user) =>
                    !selectedMentionedUser.some((item) => item.id === user.id)
            )
            setMentionToUsers(data)
        } else {
            setMentionToUsers([])
        }
    }

    const selectedUserToMention = (value) => {
        const selectedUser = mentionToUsers?.filter((el) => el.email === value)
        if (selectedUser?.length > 0) {
            let tmpArray = [...selectedMentionedUser]
            tmpArray.push(selectedUser[0])
            setSelectedMentionedUser(tmpArray)
            setMentionSearch('')
            setMentionToUsers([])
        }
    }

    const onDismissSelection = (userId) => {
        const userDismiss = [...selectedMentionedUser]
        const index = selectedMentionedUser.findIndex((el) => el.id === userId)
        userDismiss.splice(index, 1)
        setSelectedMentionedUser(userDismiss)
    }

    const handleFiles = async (e) => {
        let { files } = e.target
        let listOfFiles = []
        for (let file of files) {
            let base64String = await readFileAsync(file)
            listOfFiles.push({
                base64Str: base64String,
                documentType: 'ACCESSORIES_DESIGN',
                docMimeType: file.type,
                name: file.name
            })
        }
        setFiles((prev) => [...prev, ...listOfFiles])
    }

    const removeFile = (index) => {
        let remainingFiles = files.filter((file, i) => i !== index)
        setFiles(remainingFiles)
    }

    const handleReply = () => {
        let newComment =
            postInputRef.current === null
                ? ''
                : postInputRef?.current.toString().replace(/(<([^>]+)>)/gi, '')
        if (!newComment) {
            toast.warning('Comment required')
            return
        }
        setLoader(true)
        let message = postInputRef.current
        let body = {
            postType: 'COMMENT',
            postTag: type,
            postId: replying.id,
            documentDTOList: files,
            text: message.replace(/"/g, "'"),
            taggedUserIdList:
                members.length > 0 ? getMentionedUserIds(message, members) : []
        }

        addPostReply(id, body)
            .then((response) => {
                let cloneChatInfo = { ...chatInfo }
                for (let item of cloneChatInfo.data) {
                    if (item.id === replying.id) {
                        item.commentList.push(response.payload)
                        break
                    }
                }
                setChatInfo(chatInfo)
                setReplying(null)
                setFiles([])
                clearQuillContent()
                postInputRef.current = null
                setLoader(false)
            })
            .catch((err) => setLoader(false))
    }

    const POST_TAG_STATUS = {
        PRODUCT_QUOTE_COMMENT: 'orange-2',
        PRODUCT_SAMPLE_COMMENT: 'magenta-1',
        PRODUCT_DEVELOPMENT_COMMENT: 'teal-1'
    }

    const POST_TAG = {
        PRODUCT_QUOTE_COMMENT: 'Costing',
        PRODUCT_SAMPLE_COMMENT: 'Sample',
        PRODUCT_DEVELOPMENT_COMMENT: 'Design'
    }

    const renderReplyList = (item) => {
        return item?.map((reply) => {
            let timeAdded = reply.dateAdded?.split('T')[1]
            let dateAdded = reply.dateAdded?.split('T')[0]

            let formattedDate = convertDateTimeToLocal(
                dateAdded,
                timeAdded,
                'hh:mm A, MMM DD, YY'
            )
            return (
                <div className='flex mb-2' key={`reply_${reply.id}`}>
                    <div className='replying-content p-1 px-2 border-l-1'>
                        <div className='flex'>
                            <span className='font-bold'>
                                {reply?.postedBy?.name}
                            </span>
                            <div className='flex items-center text-sm text-primaryColor-shade-100'>
                                <div className='dot bg-primaryColor-shade-100 mx-2' />
                                <span>{formattedDate}</span>
                            </div>
                        </div>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: reply.text
                            }}
                        />
                        {reply.docList.length > 0 && (
                            <div className='flex gap-1'>
                                <div
                                    className={`files-n-photos custom-scroll ${
                                        reply.docList.length ? 'open' : ''
                                    }`}
                                >
                                    {reply.docList.map((file, i) => {
                                        return (
                                            <SelectedFileViewComponent
                                                showRemoveOption={false}
                                                file={file}
                                                key={i}
                                                index={i}
                                                remove={removeFile}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        })
    }

    const isAutoGenerated = (item) => {
        return (
            item?.postType === 'SAMPLE_AUTO_GENERATED_COMMENT' ||
            item?.postType === 'QUOTE_AUTO_GENERATED_COMMENT'
        )
    }

    const renderMessages = (item, profilePic, formattedDate) => {
        return (
            <>
                <div className='flex items-center gap-2 mb-1'>
                    {!isAutoGenerated(item) && (
                        <>
                            <span className='w-[40px] h-[40px] rounded-full overflow-hidden inline-block'>
                                <img
                                    src={profilePic}
                                    alt=''
                                    className='object-cover w-full h-full rounded-full'
                                />
                            </span>
                            <span className='font-bold'>
                                {item?.postedBy?.name}
                            </span>
                            {!selectedPostTag && (
                                <span
                                    className={`ml-1 status ${
                                        POST_TAG_STATUS[item?.postTag]
                                    }`}
                                >
                                    {POST_TAG[item?.postTag]}
                                </span>
                            )}
                        </>
                    )}
                    {isAutoGenerated(item) && (
                        <>
                            <span className='w-[20px] h-[20px] rounded-full overflow-hidden inline-block'>
                                <Notification />
                            </span>
                        </>
                    )}
                </div>
                <div className='conversation-content'>
                    <div className='conversations-item'>
                        <p
                            className={`${
                                isAutoGenerated(item)
                                    ? 'px-8 py-1 bg-[#F5F5F5] rounded-[60px] mb-2 text-[12px]'
                                    : ''
                            }`}
                            dangerouslySetInnerHTML={{
                                __html: item.text
                            }}
                        />
                        <div className='flex items-center my-1'>
                            <span className='regular-14 gray_200'>
                                {formattedDate}
                            </span>
                            {!isAutoGenerated(item) && (
                                <div className='dot bg-primaryColor-shade-100 mx-2' />
                            )}
                            <span
                                className='regular-14 blue cursor-pointer'
                                onClick={() => {
                                    postInputRef.current = null
                                    setFiles([])
                                    setReplying(item)
                                }}
                            >
                                Reply
                            </span>
                        </div>
                    </div>
                    {item?.docList.length > 0 && (
                        <div className='flex gap-1'>
                            <div
                                className={`files-n-photos custom-scroll ${
                                    item?.docList.length ? 'open' : ''
                                }`}
                            >
                                {item?.docList.map((file, i) => {
                                    return (
                                        <SelectedFileViewComponent
                                            showRemoveOption={false}
                                            file={file}
                                            key={i}
                                            index={i}
                                            remove={removeFile}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    )}
                    {renderReplyList(item.commentList)}
                </div>
            </>
        )
    }

    const getDateTimeFormat = (item) => {
        let prev = onGetLocalFromUtc(item?.dateAdded)
        const inMins = getMinsCountFromNow(prev, 'YYYY-MM-DD HH:mm:ss')
        return getTimeCount(inMins, prev)
    }

    const renderChatBody = () => {
        return chatInfo?.data?.map((item, index) => {
            let profilePic = item.postedBy?.imageUrl
                ? item.postedBy?.imageUrl
                : '/images/pro_pic_default.svg'

            return (
                <div
                    key={`chat_item_${item.id}_${index}`}
                    className={
                        highlightId && highlightId === item.id
                            ? 'highlight-wrapper'
                            : ''
                    }
                >
                    {renderMessages(
                        item,
                        profilePic,
                        getDateTimeFormat(item).toUpperCase()
                    )}
                </div>
            )
        })
    }

    useEffect(() => {
        if (!minimize) {
            scrollDownInChatBody()
        }
    }, [minimize])

    const handleScroll = (e) => {
        let container = document.getElementById('chat-body')
        if (
            e.target.scrollTop === 0 &&
            chatInfo?.totalElements !== chatInfo?.data?.length
        ) {
            setLoader(true)
            getConversationInfo(generateParams(chatInfo.currentPage + 1)).then(
                (r) => {
                    let o1 = container.scrollHeight - container.clientHeight
                    setTimeout(() => {
                        let newContainer = document.getElementById('chat-body')
                        let n1 =
                            newContainer.scrollHeight -
                            newContainer.clientHeight
                        let elem = document.getElementById('chat-body')
                        elem.scrollTop = n1 - o1
                        setLoader(false)
                    }, 100)
                }
            )
        }
    }

    useEffect(() => {
        window.addEventListener(
            'keydown',
            (e) => {
                if (['ArrowUp', 'ArrowDown'].includes(e.code)) {
                    e.preventDefault()
                }
            },
            false
        )
    }, [])

    useEffect(() => {
        document.body.addEventListener('keydown', handleKeyDown)
        return () => {
            document.body.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    const isQuillMentionListExist = () => {
        let quillLists = document.getElementById('quill-mention-list')
        return !!quillLists
    }

    const handleKeyDown = (event) => {
        if (
            document.activeElement.getAttribute('class') ===
                'outline-none no-border mention-search-box' ||
            document.activeElement.getAttribute('class') === 'single-item-focus'
        ) {
            const isUp = event.key === 'ArrowUp'
            const isDown = event.key === 'ArrowDown'
            const inputIsFocused = document.activeElement === searchRef.current
            const resultsItems = Array.from(
                (dropdownRef.current && dropdownRef.current.children) || []
            )
            const activeResultIndex = resultsItems.findIndex((child) => {
                return child.querySelector('span') === document.activeElement
            })

            if (isUp) {
                if (!isQuillMentionListExist()) {
                    if (inputIsFocused) {
                        resultsItems[resultsItems.length - 1]
                            .querySelector('span')
                            .focus()
                    } else if (resultsItems[activeResultIndex - 1]) {
                        resultsItems[activeResultIndex - 1]
                            .querySelector('span')
                            .focus()
                    } else {
                        searchRef.current && searchRef.current.focus()
                    }
                }
            }

            if (isDown) {
                if (!isQuillMentionListExist()) {
                    if (inputIsFocused) {
                        resultsItems[0].querySelector('span').focus()
                    } else if (resultsItems[activeResultIndex + 1]) {
                        resultsItems[activeResultIndex + 1]
                            .querySelector('span')
                            .focus()
                    } else {
                        searchRef.current && searchRef.current.focus()
                    }
                }
            }
        }
    }

    const selectBuyer = () => {
        setIsBuyerMentioned(true)
        setMentionToUsers([])
        setMentionSearch('')
    }

    const selectNitex = () => {
        setIsNitexMentioned(true)
        setMentionToUsers([])
        setMentionSearch('')
    }
    return (
        <>
            {openModal && (
                <ModalContainer
                    className='design-conversation-right-sidebar right-sidebar'
                    onCloseModal={onCloseModal}
                >
                    <div className='common-popup bg-white'>
                        <div className='common-popup-header d-flex justify-content-between'>
                            <div className='popup-tilte'>
                                {onGoBack && (
                                    <button
                                        className='button link flex items-center ml-[-8px] mb-1'
                                        onClick={onGoBack}
                                    >
                                        <LeftArrowIcon />
                                        <span className='black'>Back</span>
                                    </button>
                                )}
                                <p className='text-lg 5xl:text-xl font-bold'>
                                    Conversations{' '}
                                    {chatInfo?.totalElements
                                        ? `(${chatInfo?.totalElements})`
                                        : ''}
                                </p>
                                {/* <p className='regular-14 gray_300'>
                                    Q2210-A0024
                                </p> */}
                            </div>
                            <div
                                className='close-btn cursor-pointer'
                                onClick={onCloseModal}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='common-popup-body p-4 conversation-body bg-white'>
                            {[
                                'PRODUCT_SAMPLE_COMMENT',
                                'PRODUCT_QUOTE_COMMENT',
                                'PRODUCT_DEVELOPMENT_COMMENT'
                            ].includes(type) && (
                                <div className='conversation-filter-dropdown bg-white'>
                                    <select
                                        className='w-full mb-12'
                                        name='postTag'
                                        id='postTag'
                                        value={selectedPostTag}
                                        onChange={(e) => {
                                            setChatInfo({
                                                ...chatInfo,
                                                data: []
                                            })
                                            setSelectedPostTag(e.target.value)
                                        }}
                                    >
                                        <option value={''}>All</option>
                                        <option value='PRODUCT_DEVELOPMENT_COMMENT'>
                                            Design
                                        </option>
                                        <option value='PRODUCT_SAMPLE_COMMENT'>
                                            Sample
                                        </option>
                                        <option value='PRODUCT_QUOTE_COMMENT'>
                                            Costing
                                        </option>
                                    </select>
                                </div>
                            )}

                            {loader && (
                                <div className='absolute w-full text-center pt-24'>
                                    <img
                                        src={'/icons/Blue.gif'}
                                        className='w-32 h-32'
                                    />
                                </div>
                            )}
                            <div
                                className={`conversations custom-scroll ${
                                    !expand ? 'expand' : ''
                                }`}
                                onScroll={handleScroll}
                                id='chat-body'
                            >
                                <div className='space-y-4' id='chat-inner-body'>
                                    {renderChatBody()}
                                </div>
                            </div>
                        </div>
                        <div className='common-popup-footer'>
                            <div
                                className={`conversation-footer ${
                                    expand ? 'expand' : ''
                                } ${files.length > 0 ? 'file-exist' : ''}`}
                            >
                                {!replying && (
                                    <div
                                        className='mentions mention-to-person px-4 py-3 flex flex-wrap items-center gap-2 border-t border-b border-primaryColor-shade-200 bg-white'
                                        onClick={() => {
                                            setReadOnly(true)
                                        }}
                                    >
                                        <span>To</span>
                                        <div className='flex gap-2 flex-wrap'>
                                            {isNitexMentioned && (
                                                <div className='flex items-center gap-2'>
                                                    <span className='status gray-3'>
                                                        <span>Nitex</span>
                                                        <span
                                                            className='ml-1 cursor-pointer'
                                                            onClick={() =>
                                                                setIsNitexMentioned(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            <CloseSmallWhiteIcon />
                                                        </span>
                                                    </span>
                                                </div>
                                            )}
                                            {isBuyerMentioned && (
                                                <div className='flex flex-wrap items-center gap-2'>
                                                    <span className='status blue-3'>
                                                        <span>
                                                            {
                                                                authUserInfo()
                                                                    ?.brandResponse
                                                                    ?.name
                                                            }
                                                        </span>
                                                        <span
                                                            className='ml-1 cursor-pointer'
                                                            onClick={() =>
                                                                setIsBuyerMentioned(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            <CloseSmallWhiteIcon />
                                                        </span>
                                                    </span>
                                                </div>
                                            )}
                                            {selectedMentionedUser &&
                                                selectedMentionedUser.map(
                                                    (user, i) => {
                                                        return (
                                                            <div
                                                                key={user.id}
                                                                className='flex items-center gap-2'
                                                            >
                                                                <span className='status blue-3'>
                                                                    <span>
                                                                        {
                                                                            user.email
                                                                        }
                                                                    </span>
                                                                    <span
                                                                        className='ml-1 cursor-pointer'
                                                                        onClick={() =>
                                                                            onDismissSelection(
                                                                                user.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <CloseSmallWhiteIcon />
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                        </div>
                                        <div>
                                            <div className='metion-person-suggetion absolute bottom-full bg-white shadow-lg'>
                                                <ul
                                                    className='max-h-[300px] scroll-y-label'
                                                    ref={dropdownRef}
                                                >
                                                    {!isNitexMentioned &&
                                                        mentionSearch !==
                                                            '' && (
                                                            <li
                                                                key={'nitex'}
                                                                className='cursor-pointer px-3 py-1'
                                                                onClick={
                                                                    selectNitex
                                                                }
                                                                onKeyPress={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.key ===
                                                                        'Enter'
                                                                    ) {
                                                                        selectNitex()
                                                                    }
                                                                }}
                                                            >
                                                                <span
                                                                    className='single-item-focus'
                                                                    tabIndex={0}
                                                                >
                                                                    Nitex
                                                                </span>
                                                            </li>
                                                        )}
                                                    {!isBuyerMentioned &&
                                                        mentionSearch !==
                                                            '' && (
                                                            <li
                                                                key={'buyer'}
                                                                className='cursor-pointer px-3 py-1'
                                                                onClick={
                                                                    selectBuyer
                                                                }
                                                                onKeyPress={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.key ===
                                                                        'Enter'
                                                                    ) {
                                                                        selectBuyer()
                                                                    }
                                                                }}
                                                            >
                                                                <span
                                                                    className='single-item-focus'
                                                                    tabIndex={0}
                                                                >
                                                                    {
                                                                        authUserInfo()
                                                                            ?.brandResponse
                                                                            ?.name
                                                                    }
                                                                </span>
                                                            </li>
                                                        )}
                                                    {mentionToUsers?.map(
                                                        (user) => {
                                                            return (
                                                                <li
                                                                    key={
                                                                        user.id
                                                                    }
                                                                    className='cursor-pointer px-3 py-1'
                                                                    onClick={() =>
                                                                        selectedUserToMention(
                                                                            user.email
                                                                        )
                                                                    }
                                                                    onKeyPress={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e.key ===
                                                                            'Enter'
                                                                        ) {
                                                                            selectedUserToMention(
                                                                                user.email
                                                                            )
                                                                        }
                                                                    }}
                                                                >
                                                                    <span
                                                                        className='single-item-focus'
                                                                        tabIndex={
                                                                            0
                                                                        }
                                                                    >
                                                                        {
                                                                            user.email
                                                                        }
                                                                    </span>
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                            <input
                                                type='text'
                                                className='outline-none no-border mention-search-box'
                                                placeholder='Search with email'
                                                value={mentionSearch}
                                                onChange={handleMentionTo}
                                                onSelect={selectedUserToMention}
                                                ref={searchRef}
                                                onFocus={() =>
                                                    setReadOnly(true)
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                {replying && (
                                    <div className='px-4 py-3 flex items-center gap-2 border-t border-b border-primaryColor-shade-200 overflow-x-auto custom-scroll scroll-y bg-white'>
                                        <div className='flex'>
                                            <div className='bg-white-shade-100 p-1 px-2 border-l-2  border-primaryColor-shade-100'>
                                                <span className='font-bold'>
                                                    {replying?.postedBy?.name}
                                                </span>
                                                <p>
                                                    {getShortName(
                                                        extractContent(
                                                            replying.text
                                                        ),
                                                        45
                                                    )}
                                                </p>
                                                {/*<p*/}
                                                {/*    dangerouslySetInnerHTML={{*/}
                                                {/*        __html: replying.text*/}
                                                {/*    }}*/}
                                                {/*/>*/}
                                            </div>
                                            <div
                                                className='close-icon cursor-pointer'
                                                onClick={() =>
                                                    setReplying(null)
                                                }
                                            >
                                                <img
                                                    src='/icons/close.svg'
                                                    alt='close'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='expand-height-content'>
                                    <div
                                        className='image-with-text flex gap-2'
                                        onClick={() => setExpand(false)}
                                    >
                                        <div>
                                            <span className='w-[40px] h-[40px] rounded-full overflow-hidden inline-block'>
                                                {authUserInfo()
                                                    ?.profilePicDocument
                                                    ?.docUrl && (
                                                    <img
                                                        src={
                                                            authUserInfo()
                                                                ?.profilePicDocument
                                                                ?.docUrl
                                                        }
                                                        alt=''
                                                        className='object-cover w-full h-full'
                                                    />
                                                )}
                                                {!authUserInfo()
                                                    ?.profilePicDocument
                                                    ?.docUrl && (
                                                    <img
                                                        src='/images/pro_pic_default.svg'
                                                        alt=''
                                                        className='object-cover w-full h-full'
                                                    />
                                                )}
                                            </span>
                                        </div>
                                        <Editor
                                            readOnly={readOnly}
                                            setReadOnly={setReadOnly}
                                            callBack={(value) => {
                                                postInputRef.current = value
                                            }}
                                            memberList={members}
                                            mention={true}
                                            value={postInputRef.current || ''}
                                        />
                                    </div>
                                    <div
                                        className={`files-n-photos custom-scroll ${
                                            files.length ? 'open' : ''
                                        }`}
                                    >
                                        {files.map((file, i) => {
                                            return (
                                                <SelectedFileViewComponent
                                                    showRemoveOption={true}
                                                    file={file}
                                                    key={i}
                                                    index={i}
                                                    remove={removeFile}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className='absolute right-[16px] bottom-[12px] ml-auto cursor-pointer flex items-center gap-2 mt-3'>
                                        <div
                                            className='attachment cursor-pointer'
                                            onClick={() => setExpand(false)}
                                        >
                                            <label
                                                htmlFor={`input-file-${uniqueId}`}
                                            >
                                                <AttachedIcon />
                                            </label>
                                            <input
                                                id={`input-file-${uniqueId}`}
                                                type='file'
                                                name='selectedFiles'
                                                onChange={handleFiles}
                                                multiple
                                                onClick={(e) =>
                                                    (e.target.value = null)
                                                }
                                            />
                                        </div>
                                        {!replying && (
                                            <button
                                                type='submit'
                                                onClick={handleSubmit}
                                                className='button primary icon-only'
                                            >
                                                <SendIcon />
                                            </button>
                                        )}
                                        {replying && (
                                            <button
                                                type='submit'
                                                onClick={handleReply}
                                                className='button primary icon-only'
                                            >
                                                <SendIcon />
                                            </button>
                                        )}
                                        <span
                                            className='expand-close'
                                            onClick={() => setExpand(true)}
                                        >
                                            <CloseIcon className='w-6 h-6' />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    )
}

export default ConversationRightSidePanel
