import React, { useEffect, useState } from 'react'
import nitexLogoDark from '../../../assets/images/login/nitexLogoDark.svg'
import rightWhite from '../../../assets/images/login/rightWhite.svg'
import LoaderComponent from '../../common/LoaderComponent'
import Http from '../../helpers/Http'
import { toast } from 'react-toastify'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
    authUserInfo,
    getCurrentLocalDateTime,
    getRedirectUrl,
    validateNumber
} from '../../helpers/Util'
import 'tw-elements'
import countryList from '../../helpers/DialCodeList'
import SelectComponent from '../../common/SelectComponent'
import ForgetPassword from './ForgetPassword'
import EmailVerification from './EmailVerification'
import { ReactComponent as HideEye } from '../../../assets/icons/old/hideEye.svg'
import { ReactComponent as Eye } from '../../../assets/icons/old/eye.svg'
import bgImage from '../../../assets/images/login-left.png'
import { setSystemUserPreferences } from '../../services/CommonService';

const Login = () => {
    const [loader, setLoader] = useState(false)
    const [activeTab, setActiveTab] = useState('login')
    const [passwordType, setPasswordType] = useState('password')
    const [inputData, setInputData] = useState({})
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [nameError, setNameError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [brandError, setBrandError] = useState('')
    const [listOfCountryCode, setListOfCountryCode] = useState([])
    const [isRegisterButtonClicked, setIsRegisterButtonClicked] =
        useState(false)
    const [showEmailVerifiedModal, setShowEmailVerifiedModal] = useState(false)
    const [clickLogin, setClickLogin] = useState(false)
    const [isShowForgotPassword, setIsShowForgotPassword] = useState(false)
    const [bgImageLink, setBgImageLink] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (searchParams.get('activeTab') === 'register') {
            setActiveTab('register')
        }
    }, [searchParams])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    const handleKeyDown = (e) => {
        if (!clickLogin && e.keyCode === 13) {
            setClickLogin(true)
        }
    }

    useEffect(() => {
        if (clickLogin) handleLoginSubmit()
    }, [clickLogin])

    useEffect(() => {
        if (
            authUserInfo()?.emailVerified === false &&
            authUserInfo()?.status !== 'ACTIVE'
        ) {
            setShowEmailVerifiedModal(true)
        }
    }, [])

    // useEffect(() => {
    //     Http.GET(
    //         'getLoginPageBgImage',
    //         `?localDateTime=${getCurrentLocalDateTime()}`
    //     )
    //         .then(({ data }) => {
    //             setBgImageLink(data)
    //             setLoader(false)
    //         })
    //         .catch((error) => {
    //             setLoader(false)
    //             toast.error(error.response.data.message)
    //         })
    // }, [])

    useEffect(() => {
        if (!location?.state?.emailVerified) {
            // localStorage.clear()
        }
    }, [])

    useEffect(() => {
        let countryCodeList = []
        for (let item of countryList) {
            countryCodeList.push({
                label: `${item.code} (${item.dial_code})`,
                value: item.dial_code
            })
        }
        setListOfCountryCode(countryCodeList)
    }, [isRegisterButtonClicked])

    const handleChangeLogin = (e) => {
        let { name, value } = e.target
        let cloneLoginParams = { ...inputData }
        if (name === 'approveTC') {
            cloneLoginParams[name] = e.target.checked
        } else {
            cloneLoginParams[name] = value
        }
        setInputData(cloneLoginParams)
    }

    const handleUserInfo = () => {
        Http.GET('userInfo')
            .then(async ({ data }) => {
                let systemPreference = data?.systemPreference?.value ? JSON.parse(data.systemPreference.value) : {}
                if(Object.keys(systemPreference).length > 0 && systemPreference?.id && systemPreference?.settingType ===
                    'SYSTEM_PREFERENCES'){
                    await setSystemUserPreferences({})
                    data.systemPreference = {}
                }
                localStorage.setItem('userInfo', JSON.stringify(data))
                setLoader(false)
                if (
                    !data.emailVerified &&
                    authUserInfo()?.status !== 'ACTIVE'
                ) {
                    setShowEmailVerifiedModal(true)
                } else {
                    // toast.success('Successful!')
                    navigate(getRedirectUrl(data))
                }
            })
            .catch((error) => {
                setLoader(false)
                toast.error(error.response.data.message)
            })
    }

    const handleLoginSubmit = () => {
        setPasswordError('')
        setEmailError('')
        setLoader(true)
        let cloneInputData = { ...inputData }
        cloneInputData['approveTC'] = true
        Http.POST('login', cloneInputData)
            .then(({ data }) => {
                if (data.accessToken) {
                    localStorage.setItem(
                        'token',
                        data.tokenType + ' ' + data.accessToken
                    )
                    localStorage.setItem('refreshToken', data.refreshToken)
                    handleUserInfo()
                }
            })
            .catch((error) => {
                setLoader(false)
                setClickLogin(false)
                try {
                    let errors = JSON.parse(error.response.data.message)
                    for (let item of errors) {
                        if (item.field === 'email') {
                            setEmailError(item.defaultMessage)
                        }
                        if (item.field === 'password') {
                            setPasswordError(item.defaultMessage)
                        }
                    }
                } catch (e) {
                    toast.error(error.response.data.message)
                }
            })
    }

    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }

    const renderLoginOrRegister = () => {
        if (activeTab === 'login') {
            return (
                <div className='form-group'>
                    <button
                        type='submit'
                        className='button primary w-full'
                        onClick={handleLoginSubmit}
                    >
                        Login
                    </button>
                </div>
            )
        } else {
            return (
                <div className='form-group'>
                    <button
                        type='submit'
                        className='button primary w-full'
                        onClick={checkServerSideEmailValidation}
                        disabled={!inputData?.approveTC}
                    >
                        Next
                    </button>
                </div>
            )
        }
    }

    const renderLoginForm = () => {
        if (!isRegisterButtonClicked) {
            return (
                <>
                    <div className='login-register-tab'>
                        <ul>
                            <li
                                className={
                                    activeTab === 'login' ? 'active' : ''
                                }
                                onClick={() => setActiveTab('login')}
                            >
                                Login
                            </li>
                            <li
                                className={
                                    activeTab === 'register' ? 'active' : ''
                                }
                                onClick={() => setActiveTab('register')}
                            >
                                Registration
                            </li>
                        </ul>
                    </div>
                    <div className='login-input-forms xl:min-w-[500px]'>
                        <div className='form-group'>
                            <label htmlFor='email'>Email Address</label>
                            <input
                                type='email'
                                className={
                                    emailError
                                        ? 'form-field border-error'
                                        : 'form-field'
                                }
                                id='email'
                                placeholder='e.g. johndoe@gmail.com'
                                name='email'
                                value={inputData?.email || ''}
                                onChange={handleChangeLogin}
                            />
                            {emailError && (
                                <span className='text-error text-sm'>
                                    {emailError}
                                </span>
                            )}
                        </div>
                        <div className='form-group '>
                            <div className='flex justify-between items-center'>
                                <label htmlFor='password'>Password</label>
                            </div>
                            <div className='input-group relative'>
                                <input
                                    type={passwordType}
                                    id='password'
                                    className={
                                        passwordError
                                            ? 'form-field pr-12 border-error'
                                            : 'form-field pr-12'
                                    }
                                    placeholder='Password'
                                    name='password'
                                    value={inputData?.password || ''}
                                    onChange={handleChangeLogin}
                                />
                                {passwordError && (
                                    <p className='text-error text-sm'>
                                        {passwordError}
                                    </p>
                                )}
                                <span
                                    className='absolute cursor-pointer right-[8px] top-[2px]'
                                    onClick={togglePassword}
                                >
                                    {passwordType === 'password' && <Eye />}
                                    {passwordType === 'text' && <HideEye />}
                                </span>
                                {activeTab === 'login' && (
                                    <button
                                        className='button link mt-1'
                                        onClick={() =>
                                            setIsShowForgotPassword(true)
                                        }
                                    >
                                        <span className='regular-14'>
                                            Forgot Password?
                                        </span>
                                    </button>
                                )}
                            </div>
                        </div>
                        {activeTab !== 'login' && (
                            <div className='form-group'>
                                <div className='flex items-center'>
                                    <span className=''>
                                        <input
                                            type='checkbox'
                                            name='approveTC'
                                            value={inputData?.approveTC}
                                            onChange={handleChangeLogin}
                                        />
                                    </span>
                                    <span className='agree-text'>
                                        Agree to our
                                        <a
                                            rel='noreferrer'
                                            href='https://nitex.com/terms-and-conditions/'
                                            target='_blank'
                                            className='border-b border-black  mx-2 uppercase'
                                        >
                                            Terms & Conditions
                                        </a>
                                    </span>
                                </div>
                            </div>
                        )}
                        {renderLoginOrRegister()}
                    </div>
                </>
            )
        }
    }

    const handleCountryCode = (e) => {
        let cloneLoginParams = { ...inputData }
        cloneLoginParams['countryCode'] = e
        setInputData(cloneLoginParams)
    }

    const isValidate = () => {
        let error = false
        if (!inputData.brandName) {
            error = true
            setBrandError('Brand name required!')
        } else {
            setBrandError('')
        }
        if (!inputData.contactNumber || !inputData.countryCode) {
            error = true
            setPhoneError('Contact number Required!')
        } else {
            setPhoneError('')
        }
        if (!inputData.name) {
            error = true
            setNameError('Name required!')
        } else {
            setNameError('')
        }
        return error
    }

    const clearValidation = () => {
        setEmailError('')
        setPasswordError('')
        setNameError('')
        setBrandError('')
        setPhoneError('')
    }

    const checkServerSideEmailValidation = () => {
        clearValidation()
        setLoader(true)
        let postData = { ...inputData }
        postData.name = inputData.email
        Http.POST('validateSignUp', postData)
            .then(({ data }) => {
                setIsRegisterButtonClicked(true)
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                try {
                    let errors = JSON.parse(error.response.data.message)
                    for (let item of errors) {
                        if (item.field === 'email') {
                            setEmailError(item.defaultMessage)
                        }
                        if (item.field === 'password') {
                            setPasswordError(item.defaultMessage)
                        }
                        if (item.field === 'name') {
                            setNameError(item.defaultMessage)
                        }
                    }
                } catch (e) {
                    toast.error(error.response.data.message)
                }
            })
    }

    const handleRegisterSubmit = () => {
        if (isValidate()) {
            return true
        }
        setLoader(true)
        let postData = { ...inputData }
        let contactNumber = ''
        if (inputData?.countryCode?.value) {
            contactNumber = inputData.countryCode.value
        }
        if (inputData?.contactNumber) {
            contactNumber += inputData.contactNumber
        }
        postData['contactNumber'] = contactNumber
        Http.POST('signup', postData)
            .then(({ data }) => {
                setLoader(false)
                localStorage.setItem(
                    'token',
                    data.tokenType + ' ' + data.accessToken
                )
                localStorage.setItem('refreshToken', data.refreshToken)
                handleUserInfo()
            })
            .catch((error) => {
                setLoader(false)
                try {
                    let errors = JSON.parse(error.response.data.message)
                    for (let item of errors) {
                        if (item.field === 'email') {
                            setEmailError(item.defaultMessage)
                        }
                        if (item.field === 'password') {
                            setPasswordError(item.defaultMessage)
                        }
                        if (item.field === 'name') {
                            setNameError(item.defaultMessage)
                        }
                    }
                } catch (e) {
                    toast.error(error.response.data.message)
                }
            })
    }

    const renderRegistrationForm = () => {
        if (activeTab === 'register' && isRegisterButtonClicked) {
            return (
                <div className='login-input-forms register xl:min-w-[500px]'>
                    <div className='form-group'>
                        <label htmlFor='name'>Full name</label>
                        <input
                            type='text'
                            className={
                                nameError
                                    ? 'form-field border-error'
                                    : 'form-field'
                            }
                            id='name'
                            placeholder='Write Here ...'
                            name='name'
                            value={inputData?.name || ''}
                            onChange={handleChangeLogin}
                        />
                        {nameError && (
                            <span className='text-error text-sm'>
                                {nameError}
                            </span>
                        )}
                    </div>
                    <div className='form-group'>
                        <label htmlFor='contactNumber'>Contact number</label>
                        <div className='flex'>
                            <div className='min-w-[150px] mr-3'>
                                <SelectComponent
                                    options={listOfCountryCode}
                                    onChange={handleCountryCode}
                                />
                            </div>
                            <input
                                type='text'
                                className={
                                    phoneError
                                        ? 'form-field border-error'
                                        : 'form-field'
                                }
                                id='contactNumber'
                                placeholder='e.g. 01521300845'
                                name='contactNumber'
                                onKeyPress={validateNumber}
                                value={inputData?.contactNumber || ''}
                                onChange={handleChangeLogin}
                            />
                        </div>
                        {phoneError && (
                            <span className='text-error text-sm'>
                                {phoneError}
                            </span>
                        )}
                    </div>
                    <div className='form-group'>
                        <label htmlFor='brandName'>Brand name</label>
                        <input
                            type='text'
                            className={
                                brandError
                                    ? 'form-field border-error'
                                    : 'form-field'
                            }
                            id='brandName'
                            placeholder='Write Here ...'
                            name='brandName'
                            value={inputData?.brandName || ''}
                            onChange={handleChangeLogin}
                        />
                        {brandError && (
                            <span className='text-error text-sm'>
                                {brandError}
                            </span>
                        )}
                    </div>
                    <div className='form-group'>
                        <button
                            type='submit'
                            className='button primary w-full'
                            onClick={handleRegisterSubmit}
                        >
                            Register now
                        </button>
                    </div>
                </div>
            )
        }
    }

    return (
        <LoaderComponent loading={loader}>
            <div
                className='login-container'
                // style={{ background: `url(${bgImageLink})` }}
            >
                <div
                    className='left-half-image'
                    style={{
                        background: `url(${bgImage})`
                    }}
                />
                <div className='right-half-content'>
                    <div className='h-full sm:p-10'>
                        <div className='login-form-contents'>
                            <div className='form-container max-w-[500px] lg:min-w-[450px] 3xl:max-w-[586px] mx-auto xl:ml-auto'>
                                <div className='entry-title'>
                                    <img
                                        src={nitexLogoDark}
                                        alt='nitex'
                                        className='max-w-[120px] lg:max-w-[150px]'
                                    />
                                    {/* <h2 className='belong-here-text font-bold text-6xl'>
                                        Create+Collaborate
                                    </h2> */}
                                </div>
                                {renderLoginForm()}
                                {renderRegistrationForm()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isShowForgotPassword && (
                <ForgetPassword
                    onClose={() => setIsShowForgotPassword(false)}
                />
            )}
            {showEmailVerifiedModal && (
                <EmailVerification target='emailVerification' />
            )}
        </LoaderComponent>
    )
}

export default Login
