import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg'
import { ReactComponent as FilterIcon } from '../../../assets/images/filter.svg'
import { ReactComponent as Favourite } from '../../../assets/images/favourite.svg'
import { ReactComponent as CollectionRightArrow } from '../../../assets/icons/old/collectionRightArrow.svg'
import { useDispatch } from 'react-redux'
import { FavouriteThunks } from '../../redux_toolkit/Favourite/FavouriteThunks'
import {
    GET_ALL_FAVOURITE_COUNT,
    GET_CATEGORY_LIST,
    GET_FAVOURITE_STYLES,
    GET_MARKET_LIST,
    GET_SEASON_LIST
} from '../../redux_toolkit/@types/thunk.types'
import {
    FavouriteStyleActions,
    useFavouriteStyles
} from '../../redux_toolkit/Favourite/FavouriteStyleSlice'
import { ReactComponent as IconFavourite } from '../../../assets/icons/old/favourite.svg'
import { useNavigate } from 'react-router-dom'
import { closeLoader, openLoader, useLoader } from '../../redux_toolkit/Loader'
import { toggleFavouriteStyleById } from '../CommonApiCalls'
import {
    REMOVE_FAVOURITE_STYLE_BY_ID,
    SET_FILTER_FIELD
} from '../../redux_toolkit/@types/action.types'
import { toast } from 'react-toastify'
import {
    generateFavouriteStylesFilterParams,
    isPageReachBottom
} from '../../helpers/Util'
import FilterChip from '../../common/filter/core/FilterChip'
import FavouriteStyleFilter from '../../common/filter/FavouriteStyleFilter'
import { FilterOptionThunks } from '../../redux_toolkit/V3/Filter/FilterOptionThunks'
import { FilterActionTypes, useFilter } from '../../redux_toolkit/V3/Filter'

const FavouriteStyle = () => {
    const [showSearch, setShowSearch] = useState(false)
    const dispatch = useDispatch()
    const styles = useFavouriteStyles()
    const navigate = useNavigate()
    const myStateRef = useRef({})
    const filter = useFilter()
    const globalLoader = useLoader()
    const isLoading = useRef(null)

    const setIsDataLoading = (data) => {
        isLoading.current = data
    }

    useEffect(() => {
        setIsDataLoading(globalLoader)
    }, [globalLoader])

    const generateParams = (page = 0) => {
        let params = `?size=15&page=${page}`
        return generateFavouriteStylesFilterParams(filter, params)
    }

    const fetchFavouriteStyles = () => {
        dispatch(FavouriteThunks[GET_FAVOURITE_STYLES](generateParams(0)))
    }

    useEffect(() => {
        fetchFavouriteStyles()
    }, [filter.name])

    useEffect(() => {
        myStateRef.current = styles.response
    }, [styles.response])

    useEffect(() => {
        dispatch(FilterOptionThunks[GET_MARKET_LIST]())
        dispatch(FilterOptionThunks[GET_SEASON_LIST]())
        dispatch(FilterOptionThunks[GET_CATEGORY_LIST]())
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleScroll = () => {
        if (isPageReachBottom()) {
            let { totalElements, currentPage, data } = myStateRef.current
            if (
                totalElements > 0 &&
                totalElements !== data.length &&
                !isLoading.current
            ) {
                dispatch(
                    FavouriteThunks[GET_FAVOURITE_STYLES](
                        generateParams(currentPage + 1)
                    )
                )
            }
        }
    }

    const toggleFavourite = async (id) => {
        await dispatch(openLoader())
        await toggleFavouriteStyleById(id, 'unlike')
            .then(() => {
                dispatch(FavouriteThunks[GET_ALL_FAVOURITE_COUNT]())
                dispatch({
                    type: FavouriteStyleActions[REMOVE_FAVOURITE_STYLE_BY_ID],
                    payload: id
                })
                dispatch(closeLoader())
            })
            .catch((error) => {
                dispatch(closeLoader())
                toast.error(error.response.data.message)
            })
    }

    const renderProducts = () => {
        return styles?.response?.data?.map((product) => {
            return (
                <div
                    className='collection-box'
                    key={`favourite_styles_${product.id}`}
                >
                    <div className='overflow-hidden relative aspect-[9/10] p-4 bg-white border border-white'>
                        <div
                            className='h-full'
                            onClick={() =>
                                navigate(`/style/details/${product.id}`)
                            }
                        >
                            {product?.designDocuments?.length > 0 && (
                                <img
                                    className='w-full h-full object-contain'
                                    src={product.designDocuments[0].docUrl}
                                    alt='product-image'
                                />
                            )}
                            {!product?.designDocuments?.length > 0 && (
                                <img
                                    className='w-full h-full object-contain'
                                    src='/images/default_product.svg'
                                    alt='product-image'
                                />
                            )}
                        </div>
                        <div
                            className='w-[40px] h-[40px] bg-white border border-white-shade-100 flex justify-center items-center absolute right-[20px] top-[20px] cursor-pointer'
                            onClick={() => toggleFavourite(product.id)}
                        >
                            <span className='mt-2'>
                                {product.liked && <IconFavourite />}
                                {!product.liked && <Favourite />}
                            </span>
                        </div>
                    </div>

                    <div className='py-4'>
                        <h4
                            className='text-base 5xl:text-xl font-bold text-primaryColor mb-2 5xl:mb-3'
                            onClick={() =>
                                navigate(`/style/details/${product.id}`)
                            }
                        >
                            {product.name}
                        </h4>
                        {/* <div
                            className='flex items-center text-base text-primaryColor-shade-100 gap-1 mt-2 mb-4'
                            onClick={() =>
                                navigate(`/style/details/${product.id}`)
                            }
                        >
                            <span>{product?.mainFabricType}</span>
                            {product?.composition &&
                                product?.mainFabricType && (
                                    <div className='dot' />
                                )}
                            <span>{product?.composition}</span>
                        </div> */}
                        <div
                            className='flex  items-center gap-6 text-sm 5xl:text-base text-primaryColor-shade-100 cursor-pointer'
                            onClick={() =>
                                navigate(`/collection/${product.collectionId}`)
                            }
                        >
                            <span>View Collection</span>
                            <span>
                                <CollectionRightArrow />
                            </span>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const handleSearch = async (e) => {
        let data = {
            name: e.target.name,
            value: e.target.value,
            type: e.target.type
        }
        await dispatch(FilterActionTypes[SET_FILTER_FIELD](data))
    }

    return (
        <>
            <div className='filter'>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mb-4 gap-6'>
                    <div className='text-base md:text-xl text-white-shade-200 flex flex-col sm:flex-row items-center gap-4 sm:gap-8'>
                        <p className='uppercase text-xl'>Favorite Styles</p>
                    </div>
                    <div className='flex flex-wrap justify-end gap-4 lg:gap-2'>
                        <FilterChip chipType='favourite' />
                        <div className='flex items-center overflow-x-auto gap-2'>
                            <input
                                type='text'
                                className={`form-field bg-transparent border border-primaryColor w-[220px] 4xl:w-[300px] ${
                                    showSearch ? '' : 'hidden'
                                }`}
                                id='text'
                                placeholder='Search ...'
                                name='name'
                                value={filter.name ? filter.name : ''}
                                onChange={handleSearch}
                            />
                            <button
                                onClick={() => setShowSearch((prev) => !prev)}
                                type='button'
                                className='btn bg-transparent p-2 px-4 4xl:px-5 font-normal border border-primaryColor text-primaryColor'
                            >
                                <SearchIcon />
                            </button>
                            <button
                                data-bs-toggle='modal'
                                data-bs-target='#FavouriteStyleFilter'
                                type='button'
                                className='btn bg-transparent px-5 font-normal border border-primaryColor text-primaryColor'
                            >
                                <FilterIcon />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pb-4'>
                <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 xl:!grid-cols-4 gap-5 mb-60 tab:mb-40'>
                    {renderProducts()}
                </div>
                {styles?.response?.data?.length < 1 && (
                    <div className='w-full'>
                        <div className='flex items-center justify-center h-full pt-12 5xl:pt-20'>
                            <div className='max-w-[582px] text-center relative'>
                                <img
                                    src='/images/leef-new.png'
                                    className='absolute left-0 sm:left-[-74px] top-0 z-10 hidden sm:block'
                                    alt=''
                                />
                                <h1 className='text-4xl 5xl:text-5xl text-primaryColor font-bold  mb-4'>
                                    Style not found!
                                </h1>
                                <p className='max-w-[398px] mx-auto text-base text-primaryColor m-0'>
                                    We are designing something interisting for
                                    you. We will revolutionize together.
                                </p>
                                <img
                                    src='/images/leef-new-reflect.png'
                                    className='absolute right-0 sm:right-[-74px] top-0 z-10 hidden sm:block'
                                    alt=''
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <FavouriteStyleFilter triggerSubmit={fetchFavouriteStyles} />
        </>
    )
}

export default FavouriteStyle
