import React from 'react'

const CollectionTags = ({ item }) => {
    const renderTags = () => {
        return item?.tags?.map((item) => {
            return (
                <span
                    className={
                        item?.text === 'New'
                            ? 'status magenta-1 mr-2'
                            : 'status green-1 mr-2'
                    }
                    key={`tag_${item.id}`}
                >
                    {item.text}
                </span>
            )
        })
    }

    return <>{renderTags()}</>
}

export default CollectionTags
