import React from 'react'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import Menu from './Menu'
import BrandInfo from './BrandInfo'

const MobileMenu = ({ openMobileMenu, setOpenMobileMenu }) => {
    return (
        <aside className='mobile-menu w-full fixed top-0 z-[9999]'>
            <div
                className={`overflow-y-auto h-screen py-4 px-3 bg-[#ffffff] ${
                    !openMobileMenu ? 'hidden' : ''
                }`}
            >
                <div
                    className='close p-1 mb-3'
                    onClick={() => setOpenMobileMenu(false)}
                >
                    <CloseIcon className='w-8 h-8' />
                </div>
                <BrandInfo onClose={() => setOpenMobileMenu(false)} />
                <Menu onClose={() => setOpenMobileMenu(false)} />
            </div>
        </aside>
    )
}

export default MobileMenu
