import React from 'react'
import { ReactComponent as FavouriteIcon } from '../../icons/favorite.svg'

const Buttons = () => {
    return (
        <div>
            {/* Small buttons */}
            <div className='large-buttons mt-6'>
                <h3 className='text-xl weight-500'>Large Buttons</h3>
                <div className='grid grid-cols-6 gap-8 py-5'>
                    <div>
                        <button className='button primary'>
                            <span className='mr-2'>Button</span>
                            <FavouriteIcon />
                        </button>
                    </div>
                    <div>
                        <button className='button secondary'>
                            <span className='mr-2'>Button</span>
                            <FavouriteIcon />
                        </button>
                    </div>
                    <div>
                        <button className='button link'>
                            <span>Button</span>
                        </button>
                    </div>
                    <div>
                        <button className='button primary icon-only'>
                            <FavouriteIcon />
                        </button>
                    </div>
                    <div>
                        <button className='button secondary icon-only'>
                            <FavouriteIcon />
                        </button>
                    </div>
                    <div>
                        <button className='button primary round'>
                            <span className='mr-2'>Button</span>
                            <FavouriteIcon />
                        </button>
                    </div>
                </div>
            </div>

            {/* Small buttons */}
            <div className='large-buttons mt-5'>
                <h3 className='text-xl weight-500'>Small Buttons</h3>
                <div className='grid grid-cols-6 gap-8 py-5'>
                    <div>
                        <button className='button primary sm'>
                            <span className='mr-2'>Button</span>
                            <FavouriteIcon />
                        </button>
                    </div>
                    <div>
                        <button className='button secondary sm'>
                            <span className='mr-2'>Button</span>
                            <FavouriteIcon />
                        </button>
                    </div>
                    <div>
                        <button className='button link sm'>
                            <span>Button</span>
                        </button>
                    </div>
                    <div>
                        <button className='button primary icon-only sm'>
                            <FavouriteIcon />
                        </button>
                    </div>
                    <div>
                        <button className='button secondary icon-only sm'>
                            <FavouriteIcon />
                        </button>
                    </div>
                    <div>
                        <button className='button primary round sm'>
                            <span className='mr-2'>Button</span>
                            <FavouriteIcon />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Buttons
