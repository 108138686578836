import React from 'react'
import Modal from 'react-bootstrap/Modal'

const ModalContainer = ({
    onCloseModal,
    children,
    defaultProps = {},
    className = ''
}) => {
    return (
        <div>
            <Modal
                {...defaultProps}
                show={true}
                onHide={() => {
                    if (onCloseModal) onCloseModal()
                }}
                // backdrop='static'
                className={className}
                centered
            >
                {children}
            </Modal>
        </div>
    )
}

export default ModalContainer
