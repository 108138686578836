import Http from '../helpers/Http'
import { BASE_URL } from '../constant'

export const fetchMarketList = () => {
    return new Promise((resolve, reject) => {
        Http.GET('fetchMarkets')
            .then((response) => {
                let listOfMarket = []
                for (let item of response.data) {
                    listOfMarket.push({ label: item.name, value: item.id })
                }
                resolve(listOfMarket)
            })
            .catch((err) => {
                reject(err)
            })
    })
}
export const fetchCategoryList = () => {
    return new Promise((resolve, reject) => {
        Http.GET('fetchCategories')
            .then((response) => {
                let listOfCategories = []
                for (let item of response.data) {
                    listOfCategories.push({ label: item.name, value: item.id })
                }
                resolve(listOfCategories)
            })
            .catch((err) => {
                reject(err)
            })
    })
}
export const fetchFabricCategoryList = () => {
    return new Promise((resolve, reject) => {
        Http.GET('fetchFabricCategory')
            .then((response) => {
                let listOfCategories = []
                for (let item of response.data) {
                    listOfCategories.push({ label: item.value, value: item.code })
                }
                resolve(listOfCategories)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export const toggleFavouriteCollectionById = (collectionId, type) => {
    return new Promise((resolve, reject) => {
        Http.POST('toggleFavouriteCollection','',`${collectionId}/${type}`)
            .then((response) => {
                resolve(response)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export const toggleFavouriteStyleById = (styleId, type) => {
    return new Promise((resolve, reject) => {
        Http.POST('toggleFavouriteStyle', '', `${type}/${styleId}`)
            .then((response) => {
                resolve(response)
            })
            .catch((err) => {
                reject(err)
            })
    })
}
