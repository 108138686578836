import React, { useState } from 'react'
import ModalContainer from '../../common/modal/ModalContainer'
import { ReactComponent as Eye } from '../../../assets/icons/old/eye.svg'
import { ReactComponent as HideEye } from '../../../assets/icons/old/hideEye.svg'
import { ReactComponent as BrandLogo } from '../../../assets/images/login/nitexLogoDark.svg'
import { useNavigate } from 'react-router-dom'
import Http from '../../helpers/Http'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'

const LoginModal = ({ callback, openForgotPassword, trackId }) => {
    const [passwordType, setPasswordType] = useState('password')
    const [inputData, setInputData] = useState({})
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleChangeLogin = (e) => {
        let { name, value } = e.target
        let cloneLoginParams = { ...inputData }
        if (name === 'approveTC') {
            cloneLoginParams[name] = e.target.checked
        } else {
            cloneLoginParams[name] = value
        }
        setInputData(cloneLoginParams)
    }

    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }

    const handleUserInfo = () => {
        Http.GET('userInfo')
            .then(({ data }) => {
                localStorage.setItem('userInfo', JSON.stringify(data))
                if (callback) callback()
                dispatch(closeLoader())
            })
            .catch((error) => {
                dispatch(closeLoader())
                toast.error(error.response.data.message)
            })
    }

    const handleLoginSubmit = () => {
        setPasswordError('')
        setEmailError('')
        dispatch(openLoader())
        let cloneInputData = { ...inputData }
        cloneInputData['approveTC'] = true
        if(trackId) {
            cloneInputData['tid'] = trackId
            cloneInputData['fromPresentationView'] = true
        }
        Http.POST('login', cloneInputData)
            .then(({ data }) => {
                if (data.accessToken) {
                    localStorage.setItem(
                        'token',
                        data.tokenType + ' ' + data.accessToken
                    )
                    localStorage.setItem('refreshToken', data.refreshToken)
                    handleUserInfo()
                }
            })
            .catch((error) => {
                dispatch(closeLoader())
                try {
                    let errors = JSON.parse(error.response.data.message)
                    for (let item of errors) {
                        if (item.field === 'email') {
                            setEmailError(item.defaultMessage)
                        }
                        if (item.field === 'password') {
                            setPasswordError(item.defaultMessage)
                        }
                    }
                } catch (e) {
                    toast.error(error.response.data.message)
                }
            })
    }

    return (
        <>
            <ModalContainer>
                <div className='common-popup rounded-[20px]'>
                    <div className='common-popup-body'>
                        <div className='login-input-forms'>
                            <div className='logo py-8'>
                                <BrandLogo className='w-[128px] h-auto' />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='email' className='mb-1'>
                                    Email Address
                                </label>
                                <input
                                    type='email'
                                    className={
                                        emailError
                                            ? 'form-field border-error'
                                            : 'form-field'
                                    }
                                    id='email'
                                    placeholder='e.g. shiblysaikat@gmail.com'
                                    name='email'
                                    value={inputData?.email || ''}
                                    onChange={handleChangeLogin}
                                />
                                {emailError && (
                                    <span className='text-error text-sm'>
                                        {emailError}
                                    </span>
                                )}
                            </div>
                            <div className='form-group'>
                                <div className='flex justify-between items-center'>
                                    <label htmlFor='password' className='mb-1'>
                                        Password
                                    </label>
                                </div>
                                <div className='input-group relative'>
                                    <input
                                        type={passwordType}
                                        id='password'
                                        className={
                                            passwordError
                                                ? 'form-field pr-12 border-error'
                                                : 'form-field pr-12'
                                        }
                                        placeholder='Password'
                                        name='password'
                                        value={inputData?.password || ''}
                                        onChange={handleChangeLogin}
                                    />
                                    {passwordError && (
                                        <span className='text-error text-sm'>
                                            {passwordError}
                                        </span>
                                    )}
                                    <span
                                        className='absolute cursor-pointer right-[8px] top-[2px]'
                                        onClick={togglePassword}
                                    >
                                        {passwordType === 'password' && <Eye />}
                                        {passwordType === 'text' && <HideEye />}
                                    </span>

                                    <button
                                        className='button link mt-1'
                                        onClick={openForgotPassword}
                                    >
                                        <span className='regular-14'>
                                            Forget Password?
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className='submit-button pb-5'>
                                <button
                                    className='button primary w-full'
                                    onClick={handleLoginSubmit}
                                >
                                    Login
                                </button>
                                <p className='regular-14 mt-2'>
                                    Don’t have any account?{' '}
                                    <span
                                        className='blue'
                                        onClick={() =>
                                            navigate(
                                                '/login?activeTab=register'
                                            )
                                        }
                                    >
                                        Create new account.
                                    </span>{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </>
    )
}

export default LoginModal
