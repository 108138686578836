import React, { useState, useEffect } from 'react'
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg'

const Pagination = ({
    postsPerPage,
    totalPosts = [],
    paginate,
    currentPage,
    onChangePostPerPage
}) => {
    const [showPrev, setPrev] = useState(false)
    const [showNext, setNext] = useState(false)
    const [pageNumbers, setPageNumbers] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (currentPage - 1 > 0) {
            setPrev(true)
        } else {
            setPrev(false)
        }
        if (currentPage !== pageNumbers.length) {
            setNext(true)
        } else {
            setNext(false)
        }
    }, [currentPage, pageNumbers])

    const onSetPageNumber = () => {
        let pagesNo = []
        for (let i = 1; i <= Math.ceil(totalPosts.length / postsPerPage); i++) {
            pagesNo.push(i)
        }
        setPageNumbers(pagesNo)
    }

    useEffect(() => {
        onSetPageNumber()
    }, [totalPosts?.length, postsPerPage])

    const prev = () => paginate(currentPage - 1)
    const next = () => paginate(currentPage + 1)
    const handleChangePostPerPage = (e) => onChangePostPerPage(e.target.value)

    return (
        <div className='pagination mt-8 flex items-center justify-center'>
            <div className='flex items-center'>
                <button
                    className='button link mr-1'
                    disabled={!showPrev}
                    onClick={prev}
                >
                    <ArrowLeftIcon />
                </button>
                <div className='page-numbers'>
                    {pageNumbers?.map((item) => (
                        <span
                            className={`${
                                currentPage === item ? 'active' : ''
                            }`}
                            key={item}
                            onClick={() => paginate(item)}
                        >
                            {item}
                        </span>
                    ))}

                    {/* <span className='dots'>...</span> */}
                </div>
                <button
                    className='button link ml-1'
                    disabled={!showNext}
                    onClick={next}
                >
                    <ArrowRightIcon />
                </button>
                <div className='page-sorting ml-4'>
                    <select
                        value={postsPerPage}
                        onChange={handleChangePostPerPage}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={40}>40</option>
                        <option value={60}>60</option>
                    </select>
                </div>
            </div>
        </div>
    )
}

export default Pagination
