// Here we will hold all the types of thunks
// Thunks are for me async tasks that need network transaction

//Home Page Thunks
export const GET_MATERIAL_LIST_BY_FABRIC_TYPE =
    'GET_MATERIAL_LIST_BY_FABRIC_TYPE'
export const GET_PRODUCT_BY_MATERIAL_ID = 'GET_PRODUCT_BY_MATERIAL_ID'

//Order Page Thunks
export const GET_ORDER_LIST = 'GET_ORDER_LIST'
export const FETCH_DEFAULT_QUOTES = 'FETCH_DEFAULT_QUOTES'

//Collection Thunks
export const GET_COLLECTION_LIST = 'GET_COLLECTION_LIST'
export const GET_PRODUCTS_BY_COLLECTION_ID = 'GET_PRODUCTS_BY_COLLECTION_ID'
export const GET_COLLECTION_COUNT = 'GET_COLLECTION_COUNT'

// Moodboard Thunks
export const GET_MOODBOARD_LIST = 'GET_MOODBOARD_LIST'
export const GET_MOODBOARD_BY_ID = 'GET_MOODBOARD_BY_ID'
export const GET_MOODBOARD_COLOR = 'GET_MOODBOARD_COLOR'
export const GET_MOODBOARD_ADDED_IMAGE = 'GET_MOODBOARD_ADDED_FABRICS'
export const GET_SIMILAR_FABRIC = 'GET_SIMILAR_FABRIC'
export const UPLOAD_MOODBOARDS = 'UPLOAD_MOODBOARDS'
export const UPDATE_MOODBOARD = 'UPDATE_MOODBOARD'
export const UPLOAD_MOODBOARD_IMAGES = 'UPLOAD_MOODBOARD_IMAGES'
export const GET_COLOR_CODES = 'GET_COLOR_CODES'
export const DELETE_PRODUCT_IMAGE = 'DELETE_PRODUCT_IMAGE'
export const ADD_COLOR_CODE = 'ADD_COLOR_CODE'
export const DELETE_COLOR_FROM_MOODBOARD = 'DELETE_COLOR_FROM_MOODBOARD'
export const GET_MOODBOARD_FABRICS = 'GET_MOODBOARD_FABRICS'
export const ADD_FABRIC_TO_MOODBOARD = 'ADD_FABRIC_TO_MOODBOARD'
export const DELETE_FABRIC_FROM_MOODBOARD = 'DELETE_FABRIC_FROM_MOODBOARD'
export const ADD_MOODBOARD_TO_FAVORITE = 'ADD_MOODBOARD_TO_FAVORITE'
export const REMOVE_MOODBOARD_FROM_FAVORITE = 'REMOVE_MOODBOARD_FROM_FAVORITE'
export const GET_ALL_MATERIAL_CATEGORY = 'GET_ALL_MATERIAL_CATEGORY'
export const GET_ALL_MATERIAL_SUB_CATEGORY = 'GET_ALL_MATERIAL_SUB_CATEGORY'
export const GET_ALL_MOODBOARD_FILTER_DATA = 'GET_ALL_MOODBOARD_FILTER_DATA'
export const GET_FILTERED_MOODBOARDS = 'GET_FILTERED_MOODBOARDS'
export const CREATE_NEW_MOODBOARD = 'CREATE_NEW_MOODBOARD'

//Qoute Thunks
export const GET_QUOTE_LIST ='GET_QUOTE_LIST'
export const GET_QUOTE_DETAIL = 'GET_QUOTE_DETAIL'

//Qoute Thunks
export const GET_SAMPLE_LIST ='GET_SAMPLE_LIST'
export const GET_SAMPLE_DETAIL = 'GET_SAMPLE_DETAIL'

//Filter Thunks
export const REMOVE_FIELD_FROM_FILTER = 'REMOVE_FIELD_FROM_FILTER'
export const REMOVE_ALL_FILTER_FIELDS = 'REMOVE_ALL_FILTER_FIELDS'
export const GET_SEASON_LIST = 'GET_SEASON_LIST'
export const GET_MARKET_LIST = 'GET_MARKET_LIST'
export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST'
export const GET_SUB_CATEGORY_LIST = 'GET_SUB_CATEGORY_LIST'
export const GET_PRODUCT_AGE_GROUP_LIST = 'GET_PRODUCT_AGE_GROUP_LIST'
export const GET_DESIGN_LOOK = 'GET_DESIGN_LOOK'
export const GET_ALL_FABRIC_TYPES = 'GET_ALL_FABRIC_TYPES'

//Color Thunks
export const GET_COLOR_LIST = 'GET_COLOR_LIST'

//Team Thunks
export const GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS'

//Favourite Thunks
export const GET_FAVOURITE_COUNT = 'GET_FAVOURITE_COUNT'
export const GET_FAVOURITE_STYLES = 'GET_FAVOURITE_STYLES'
export const GET_ALL_FAVOURITE_COUNT = 'GET_ALL_FAVOURITE_COUNT'

//v3 types
//Explore design
export const GET_ALL_EXPLORE_DESIGNS = 'GET_ALL_EXPLORE_DESIGNS'
export const SET_ALL_EXPLORE_DESIGNS = 'SET_ALL_EXPLORE_DESIGNS'

//Favourite
export const SELECT_DESIGN_FOR_FAVOURITE = 'SELECT_DESIGN_FOR_FAVOURITE'
export const UNSELECT_DESIGN_FOR_FAVOURITE = 'UNSELECT_DESIGN_FOR_FAVOURITE'
export const SET_DESIGN_FOR_FAVOURITE = 'SET_DESIGN_FOR_FAVOURITE'

//Shared collection
export const GET_ALL_SHARED_COLLECTION = 'GET_ALL_SHARED_COLLECTION'
export const SET_ALL_SHARED_COLLECTION = 'SET_ALL_SHARED_COLLECTION'
export const TOGGLE_FAVOURITE_COLLECTION = 'TOGGLE_FAVOURITE_COLLECTION'
export const TOGGLE_COLLECTION_AS_FAVOURITE = 'TOGGLE_COLLECTION_AS_FAVOURITE'

//Collection details
export const GET_ALL_PRODUCTS_FROM_COLLECTION = 'GET_ALL_PRODUCTS_FROM_COLLECTION'
export const SET_ALL_PRODUCTS_TO_COLLECTION = 'SET_ALL_PRODUCTS_TO_COLLECTION'
export const TOGGLE_PRODUCT_AS_FAVOURITE = 'TOGGLE_PRODUCT_AS_FAVOURITE'

//ranges
export const GET_ALL_PRODUCTS_FROM_RANGE = 'GET_ALL_PRODUCTS_FROM_RANGE'
export const SET_ALL_PRODUCTS_TO_RANGE = 'SET_ALL_PRODUCTS_TO_RANGE'
export const TOGGLE_PRODUCT_AS_FAVOURITE_IN_RANGE = 'TOGGLE_PRODUCT_AS_FAVOURITE_IN_RANGE'

//Search Designs
export const ADD_NEW_DESIGN = 'ADD_NEW_DESIGN'
export const ADD_ACTIVE_SEARCH_IMAGE = 'ADD_ACTIVE_SEARCH_IMAGE'
export const ADD_CROPPED_IMAGE = 'ADD_CROPPED_IMAGE'
export const ADD_SEARCH_RESULTS = 'ADD_SEARCH_RESULTS'
export const TOGGLE_SEARCH_AS_FAVOURITE = 'TOGGLE_SEARCH_AS_FAVOURITE'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'

//APP Global State
export const ADD_CONVERSATION_PRODUCT_ID = 'ADD_CONVERSATION_PRODUCT_ID'
export const CLEAR_CONVERSATION_PRODUCT_ID = 'CLEAR_CONVERSATION_PRODUCT_ID'
export const ADD_PRODUCT_TRACK_ID = 'ADD_PRODUCT_TRACK_ID'
export const CLEAR_PRODUCT_TRACK_ID = 'CLEAR_PRODUCT_TRACK_ID'
