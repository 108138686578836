import React from 'react';
import { useNavigate } from 'react-router-dom';

const CollectionRequestConfirmation = ({ collectionId }) => {
    const navigate = useNavigate();

    return (
        <div className='modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto' id='CollectionRequestConfirmationAction' tabIndex='-1' aria-labelledby='exampleModalCenterTitle' aria-modal='true' role='dialog'>
            <div className='modal-dialog max-w-[680px] overflow-hidden modal-dialog-centered relative w-auto pointer-events-none'>
                <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current'>
                    <div className='modal-header flex flex-shrink-0 items-center justify-between p-8 pb-0'>
                        <button type='button'
                                className='btn-close box-content w-4 h-4 p-1 text-black border-none  opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                                data-bs-dismiss='modal' aria-label='Close'>
                        </button>
                    </div>
                    <div className='modal-body relative p-4'>
                        <div className='px-6 pb-6'>
                            <h2 className='text-4xl sm:text-[44px] text-primaryColor uppercase font-bold mb-8'>Thanks!</h2>
                            <div className='space-y-4'>
                                <p className='text-xl'>Your <strong>Moodboard</strong> has been received. We will share a collection with you based on your moodboard within <strong>07 days</strong>.</p>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer p-10 flex gap-6'>
                        <button type='button' className='btn w-[150px] bg-transparent font-normal border border-primaryColor text-primaryColor' data-bs-dismiss='modal'>
                            Close
                        </button>
                        <button type='button' className='btn w-full' data-bs-dismiss='modal' onClick={()=>navigate('/collection/' + collectionId)}>
                            Go to <strong className='!font-bold'>Collection</strong>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollectionRequestConfirmation;
