import Http from '../../helpers/Http'
import { toast } from 'react-toastify';

const getSearchedUser = async (query) => {
    try {
        const { data } = await Http.GET('userSearch', `?search=${query}`);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.error) {
            toast.error(response.data.error);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const getRequestChannel = async (stompClient) => {
    try {
        const { data } = await Http.GET('getConversationTopic');
        return data;
        // eslint-disable-next-line no-empty
    } catch ({ response }) {
        if(response.status === 401){
            stompClient.disconnect(function () {})
        }
    }
};

const onReviseTask = async (body, taskId) => {
    try {
        const { data } = await Http.POST('reviseTask', body, taskId);
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const onAddNewComment = async (body) => {
    try {
        const { data } = await Http.POST('postOnTask', body, '?fromTimeline=false');
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

const onAddNewReply = async (body) => {
    try {
        const { data } = await Http.POST('commentOnTask', body, '?fromTimeline=false');
        return data;
    } catch ({ response }) {
        if (response && response.data && response.data.message) {
            toast.error(response.data.message);
        } else {
            toast.error('Something went wrong! Please try again.');
        }
    }
};

export default {
    getSearchedUser,
    getRequestChannel,
    onReviseTask,
    onAddNewComment,
    onAddNewReply
};
