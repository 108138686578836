import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import {
    capitalizeFirstLetter,
    isPageReachBottom,
    onSetQuoteDetailsBreadcrumb
} from '../../helpers/Util'
import {
    QouteActions,
    useQuoteSelector
} from '../../redux_toolkit/Quote/QuoteSlice'
import QuoteThunks from '../../redux_toolkit/Quote/QuoteThunks'
import { GET_QUOTE_LIST } from '../../redux_toolkit/@types/thunk.types'
import StyleImageCardView from '../../common/StyleImageCardView'
import { SET_QUOTE_LIST_PAGE_ACTIVE_TAB } from '../../redux_toolkit/@types/action.types'
import { useLoader } from '../../redux_toolkit/Loader'
import EmptyDashboard from '../../common/EmptyDashboard'

const QuoteList = ({ total }) => {
    const quoteStore = useQuoteSelector()
    const dispatch = useDispatch()
    const ActiveStatus = {
        running: 'RUNNING',
        pending: 'PENDING',
        completed: 'COMPLETED',
        cancelled: 'CANCELLED'
    }
    const quoteStateRef = useRef({})
    // const searchRef = useRef({})
    // const [showSearch, setShowSearch] = useState(false)
    // const [searchValue, setSearchValue] = useState('')
    const globalLoader = useLoader()
    const isLoading = useRef(null)
    const navigate = useNavigate()
    const location = useLocation()
    const activeStatusParam = new URLSearchParams(location.search).get('status')

    const setIsDataLoading = (data) => {
        isLoading.current = data
    }

    useEffect(() => {
        setIsDataLoading(globalLoader)
    }, [globalLoader])

    useEffect(() => {
        if (!quoteStore.activeTab) {
            handleChangeActiveStatus(
                !activeStatusParam ? 'RUNNING' : activeStatusParam
            )
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
            dispatch({
                type: QouteActions[SET_QUOTE_LIST_PAGE_ACTIVE_TAB],
                payload: ''
            })
        }
    }, [])

    // useEffect(() => {
    //     searchRef.current.focus()
    // }, [showSearch])

    useEffect(() => {
        quoteStateRef.current = quoteStore
    }, [quoteStore.quotes])

    const handleChangeActiveStatus = (activeStatus) => {
        window.scrollTo(0, 0)
        dispatch(
            QuoteThunks[GET_QUOTE_LIST](
                generateParams(0, activeStatus),
                activeStatus
            )
        )
        navigate(`/dashboard?active=QUOTES&status=${activeStatus}`)
    }

    const generateParams = (page = 0, activeStatus, search = '') => {
        const statusMap = {
            [ActiveStatus.running]: activeStatus,
            [ActiveStatus.pending]: `${activeStatus},INITIALIZED`,
            [ActiveStatus.completed]: `${activeStatus},ARCHIVE`,
            [ActiveStatus.cancelled]: 'INITIALIZED&approvalStatus=CANCELLED'
        }
        const status = `?size=15&page=${page}&sort=id,desc&search=${search}${
            statusMap[activeStatus] ? `&status=${statusMap[activeStatus]}` : ''
        }`

        return status
    }

    const handleScroll = () => {
        if (isPageReachBottom()) {
            const { totalElements, currentPage, data } =
                quoteStateRef.current.quotes
            const activeTab = quoteStateRef.current.activeTab
            if (
                totalElements > 0 &&
                totalElements !== data.length &&
                !isLoading.current
            ) {
                dispatch(
                    QuoteThunks[GET_QUOTE_LIST](
                        generateParams(currentPage + 1, activeTab),
                        activeTab
                    )
                )
            }
        }
    }

    const handleSearch = (e) => {
        const { value } = e.target
        const activeStatus = quoteStore.activeTab
        dispatch(
            QuoteThunks[GET_QUOTE_LIST](
                generateParams(0, activeStatus, value),
                activeStatus
            )
        )
    }
    return (
        <>
            <div>
                <div>
                    <div className='quote-sample-state-tab'>
                        <div
                            onClick={() =>
                                handleChangeActiveStatus(ActiveStatus.running)
                            }
                            className={`single-tab ${
                                quoteStore.activeTab === ActiveStatus.running
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <h4 className='regular-14'>
                                {capitalizeFirstLetter(ActiveStatus.running)} (
                                {total?.quotes?.RUNNING})
                            </h4>
                        </div>
                        <div
                            onClick={() =>
                                handleChangeActiveStatus(ActiveStatus.pending)
                            }
                            className={`single-tab ${
                                quoteStore.activeTab === ActiveStatus.pending
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <h4 className='regular-14'>
                                {capitalizeFirstLetter(ActiveStatus.pending)} (
                                {total?.quotes?.PENDING})
                            </h4>
                        </div>
                        <div
                            onClick={() =>
                                handleChangeActiveStatus(ActiveStatus.completed)
                            }
                            className={`single-tab ${
                                quoteStore.activeTab === ActiveStatus.completed
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <h4 className='regular-14'>
                                {capitalizeFirstLetter(ActiveStatus.completed)}{' '}
                                ({total?.quotes?.COMPLETED})
                            </h4>
                        </div>

                        <div
                            onClick={() =>
                                handleChangeActiveStatus(ActiveStatus.cancelled)
                            }
                            className={`single-tab ${
                                quoteStore.activeTab === ActiveStatus.cancelled
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <h4 className='regular-14'>
                                {capitalizeFirstLetter(ActiveStatus.cancelled)}{' '}
                                ({total?.quotes?.CANCELLED})
                            </h4>
                        </div>
                    </div>
                </div>

                {quoteStore?.quotes?.data &&
                    quoteStore?.quotes?.data?.length > 0 && (
                        <div className='py-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4'>
                            {quoteStore.quotes.data.map((q) => {
                                return (
                                    <StyleImageCardView
                                        key={q.id}
                                        navigationUrl={() =>
                                            onSetQuoteDetailsBreadcrumb(
                                                q,
                                                activeStatusParam,
                                                navigate
                                            )
                                        }
                                        productFeatureImageList={
                                            q.productFeatureImageList
                                        }
                                        titleName={q.title}
                                        referenceNumber={q.referenceNumber}
                                        noOfDesign={q.noOfDesign}
                                        status={q.isQuoteReceived}
                                        statusText={'Quote Received'}
                                    />
                                )
                            })}
                        </div>
                    )}

                {quoteStore?.quotes?.data?.length === 0 && (
                    <EmptyDashboard requesType='quote' />
                )}
            </div>
        </>
    )
}

export default QuoteList
