import React, { useEffect, useRef, useState } from 'react'
import {
    addImageSuffix,
    getMentionedUserIds,
    getUserInfo,
    mentionModule
} from '../../../../helpers/Util'
import * as ReactQuill from 'react-quill'
import { SelectedFileViewComponent } from '../../../../../app/common/SelectedFileViewComponent'
import Http from '../../../../../app/helpers/Http'
import { useDispatch, useSelector } from 'react-redux'
import {
    closeLoader,
    openLoader,
    useLoader
} from '../../../../redux_toolkit/Loader'
import {
    ENTITY_TYPE,
    EVENT,
    getTitlePartText,
    NOTIFICATION_FILTER_NAME,
    NOTIFICATIONS,
    TITLE_PART_TYPE
} from '../util/Utils'
import {
    _storeData,
    setReplyData
} from '../../../../redux/action/NotificationAction'
import { toast } from 'react-toastify'
import { NotificationActionTypes } from '../../../../redux_toolkit/Notifications'
import {
    SET_NOTIFICATION_DATA,
    SET_NOTIFICATION_REPLY_COMMENT
} from '../../../../redux_toolkit/@types/action.types'

export const NotificationCommentReply = ({ item, replyButtonClosed, read }) => {
    let { titlePartList, entityIdTypeMapList } = item && item.body
    const [commentError, setCommentError] = useState('')
    const dispatch = useDispatch()
    const reactQuillRef = useRef(null)

    let memberId = getTitlePartText(titlePartList, TITLE_PART_TYPE.ACTOR)?.id
    let memberName = getTitlePartText(
        titlePartList,
        TITLE_PART_TYPE.ACTOR
    )?.text

    const [commentValue, setCommentValue] = useState(
        '<span class="mention" data-index="2" data-denotation-char="@" data-id=member_' +
            memberId +
            ' data-value=' +
            memberName +
            '>&#xFEFF;' +
            '<span contenteditable="false"><span class="ql-mention-denotation-char">@</span>' +
            memberName +
            '</span>&#xFEFF;</span>'
    )
    const [selectedFiles, setSelectedFiles] = useState([])
    const [memberList, setMemberList] = useState([
        {
            email: 'member_' + memberId,
            id: 'member_' + memberId,
            memberId: memberId,
            value: memberName
        }
    ])

    useEffect(() => {
        if (commentValue && memberName) {
            reactQuillRef.current.editor.setSelection(
                reactQuillRef.current.state.value.length + 2
            )
            reactQuillRef.current.focus()
        }
    }, [memberName])

    const profileImage = getUserInfo()?.profilePicDocument?.docUrl

    const onMultipleFileSelect = (e, docType) => {
        const files = Array.from(e.target.files)
        const fileList = [...selectedFiles]
        files.map((item) => {
            const data = {
                name: item.name,
                docMimeType: item.type,
                documentType: docType,
                print: false
            }
            const reader = new FileReader()
            reader.readAsDataURL(item)
            reader.onload = async () => {
                data.base64Str = reader.result
                fileList.push(data)
            }
        })

        setTimeout(() => {
            setSelectedFiles(fileList)
        }, 500)
    }
    const getEntityIdByEntity = (entityIdTypeMapList, entityType) => {
        return entityIdTypeMapList.find((obj) => obj.type === entityType).id
    }
    const getTitlePartIdByEntity = (titlePartList, entityType) => {
        return titlePartList.find((obj) => obj.titlePartType === entityType).id
    }
    const removeFile = (index) => {
        setSelectedFiles(selectedFiles.filter((file, i) => i !== index))
    }
    const crossButtonClick = (e) => {
        e.stopPropagation()
        setCommentValue('')
        setSelectedFiles([])
        replyButtonClosed(true)
    }

    const submitComment = (event) => {
        event.stopPropagation()

        let newComment =
            reactQuillRef.current === null
                ? ''
                : reactQuillRef?.current.toString().replace(/(<([^>]+)>)/gi, '')
        if (!newComment) {
            setCommentError('Comment required')
            return
        }
        // memberList ? getMentionedUserIds(message, memberList) :

        setCommentError('')
        dispatch(openLoader())
        let message = reactQuillRef.current
        let body = {
            documentDTOList: selectedFiles,
            postId: getEntityIdByEntity(entityIdTypeMapList, ENTITY_TYPE.POST),
            text: message.replace(/"/g, ''),
            taggedUserIdList: [
                getTitlePartIdByEntity(titlePartList, TITLE_PART_TYPE.ACTOR)
            ]
        }

        Http.POST('addPostReply', body)
            .then((response) => {
                let data = response.data
                dispatch({
                    type: NotificationActionTypes[
                        SET_NOTIFICATION_REPLY_COMMENT
                    ],
                    payload: {
                        id: item.id,
                        actorName: data?.payload?.postedBy?.name,
                        body: data?.payload?.text
                    }
                })
                dispatch(closeLoader())
                replyButtonClosed(false)
                read()
            })
            .catch((error) => {
                dispatch(closeLoader())
                toast.error(error.response.data.message)
            })
    }

    return (
        <div className='conversation conv-reply'>
            <div className='reply-option'>
                <div className='user-photo'>
                    {profileImage !== undefined ? (
                        <img src={addImageSuffix(profileImage, '_xicon')} />
                    ) : (
                        <img src='/images/pro_pic_default.svg' />
                    )}
                </div>
                <ReactQuill
                    ref={reactQuillRef}
                    name='comment'
                    value={commentValue || ''}
                    debug='info'
                    theme='bubble'
                    placeholder='Write reply…'
                    onChange={(value) => (reactQuillRef.current = value)}
                    modules={{ mention: mentionModule(memberList) }}
                />
                {commentError && <p className='error'>{commentError}</p>}
                <div
                    className={`files-n-photos custom-scrollbar ${
                        selectedFiles?.length ? 'open' : ''
                    }`}
                >
                    {selectedFiles?.map((file, i) => {
                        return (
                            <SelectedFileViewComponent
                                showRemoveOption={true}
                                file={file}
                                key={i}
                                index={i}
                                remove={removeFile}
                            />
                        )
                    })}
                </div>

                <div className='post-actions'>
                    <div className='attachment cursor-pointer'>
                        <label htmlFor='input-file-reply'>
                            <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M12.5 22C9.46 22 7 19.54 7 16.5L7 6C7 3.79 8.79 2 11 2C13.21 2 15 3.79 15 6L15 14.5C15 15.88 13.88 17 12.5 17C11.12 17 10 15.88 10 14.5L10 7L12 7L12 14.59C12 15.14 13 15.14 13 14.59L13 6C13 4.9 12.1 4 11 4C9.9 4 9 4.9 9 6L9 16.5C9 18.43 10.57 20 12.5 20C14.43 20 16 18.43 16 16.5L16 7L18 7L18 16.5C18 19.54 15.54 22 12.5 22Z'
                                    fill='#595959'
                                />
                            </svg>
                        </label>
                        <input
                            id='input-file-reply'
                            type='file'
                            name='selectedFiles'
                            onChange={(e) =>
                                onMultipleFileSelect(e, 'ACCESSORIES_DESIGN')
                            }
                            multiple
                        />
                    </div>
                    <div className='save-note'>
                        <button
                            className='text-white brand-bg-color'
                            onClick={(e) => submitComment(e)}
                        >
                            Reply
                        </button>
                        <div
                            className='close-note cursor-pointer'
                            onClick={(e) => crossButtonClick(e)}
                        >
                            <svg
                                width='14'
                                height='15'
                                viewBox='0 0 14 15'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z'
                                    fill='#222222'
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
