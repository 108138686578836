import {
    SELECT_DESIGN_FOR_FAVOURITE,
    SET_DESIGN_FOR_FAVOURITE,
    UNSELECT_DESIGN_FOR_FAVOURITE
} from '../../@types/thunk.types'
import { ExploreDesignAction } from '../DesignLab/ExploreDesignSlice'
import {
    doFavourite,
    undoFavourite
} from '../../../services/v3/Favourite/FavouriteService'
import { DoFavouriteActions } from './index'
import { SET_SELECTED_FAVOURITE } from '../../@types/action.types'
import { getUserPreference } from '../../../helpers/Util'

export const DoFavouriteThunk = {
    [SELECT_DESIGN_FOR_FAVOURITE]: (designInfo, trackId) => async (dispatch) => {
        let folderId = getUserPreference()?.defaultFavouriteFolder?.id
        let params = `?productIds=${designInfo.id}`
        if (trackId) params += `&tid=${trackId}`
        if (folderId) params += `&folderId=${folderId}`
        await doFavourite(params)
        dispatch(DoFavouriteActions[SET_SELECTED_FAVOURITE](designInfo))
        dispatch(ExploreDesignAction[SET_DESIGN_FOR_FAVOURITE](designInfo.id))
    },
    [UNSELECT_DESIGN_FOR_FAVOURITE]: (designInfo) => async (dispatch) => {
        await undoFavourite(designInfo.id)
        dispatch(ExploreDesignAction[SET_DESIGN_FOR_FAVOURITE](designInfo.id))
    }
}
