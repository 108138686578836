import {
    generateUniqueId,
    getUserInfo,
    getUserPreference,
    printApiError
} from '../helpers/Util'
import Http from '../helpers/Http'
import { toast } from 'react-toastify'

export const setSystemUserPreferences = async (newKeyValues) => {
    let body = getUserPreference()
    body = {
        ...body,
        ...newKeyValues
    }
    await Http.POST('setSettingsData', {
        key: 'SYSTEM_PREFERENCES',
        value: JSON.stringify(body)
    })
        .then(() => {
            let userInfo = { ...getUserInfo() }
            userInfo.systemPreference = body
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
        })
        .catch(({ response }) => {
            if (response && response.data && response.data.message) {
                toast.error(response.data.message)
            }
        })
}

export const fetchProductInfosByIds = async (type, ids) => {
    try {
        const { data } = await Http.GET(
            'getCollectionStylesInfoById',
            `?ids=${ids}`
        )
        let tmpProducts = []
        for (let item of data) {
            tmpProducts.push({
                identifier: generateUniqueId(),
                productId: item.id,
                name: item.name,
                designDocuments: item.designDocuments,
                quantity: null,
                comment: '',
                isSet: item.isSet,
                isPack: item.isPack,
                setMemberProductIds: item?.setMemberProductIds,
                setMemberProducts: item?.setMemberProducts || [],
                ...(type === 'SAMPLE' && {
                    size: ''
                })
            })
        }
        return tmpProducts
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const getCollectionPresentationInfo = async (params) => {
    try {
        const { data } = await Http.GET('collectionAction', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const fetchEnumValues = async (type, setData) => {
    try {
        const { data } = await Http.GET('getEnums', type)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const fetchFilterListByBuyer = async (params) => {
    try {
        const { data } = await Http.GET('fetchFilterListByBuyer', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const fetchProductSearchSuggestion = async (params) => {
    try {
        const { data } = await Http.GET('productSearchSuggestion', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const doProductSearch = async (params) => {
    try {
        const { data } = await Http.GET('productSearch', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const addProductBulkImages = async (params, productId) => {
    try {
        const { data } = await Http.POST(
            'addProductBulkImages',
            params,
            productId
        )
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const fetchUserSuggestions = async (params) => {
    try {
        const { data } = await Http.GET('getUserSuggestions', params)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const callProductTracker = async (productId) => {
    try {
        const { data } = await Http.POST('trackProductById', '', productId)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const callPresentationTracker = async () => {
    try {
        const { data } = await Http.POST('trackPresentationView', '')
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const uploadDocument = async (postData) => {
    try {
        const { data } = await Http.POST('uploadBulkDocument', postData)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const getFavouritePresentationInfo = async (params) => {
    try {
        const { data } = await Http.POST(
            'favouritePresentationInfo',
            '',
            params
        )
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const fetchAllBrandDepartments = async () => {
    try {
        const { data } = await Http.GET('getAllBrandDepartments')
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const fetchMembersByDepartment = async (department) => {
    try {
        const { data } = await Http.GET('getMembersByDepartment', department)
        return data
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
        } else {
            toast.error('Something went wrong! Please try again.')
        }
    }
}

export const fetchSetInfoById = async (id) => {
    try {
        const { data } = await Http.GET(
            'fetchSetInfo',
            `${id}?withMemberDetails=true`
        )
        return data
    } catch (error) {
        printApiError(error)
    }
}
