import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getShortName,
    changeDateFormat,
    isSetItem,
    isPackItem,
    formatFeatureImageDocUrl
} from '../../../helpers/Util'
import { useParams } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import { toggleDesignSelection } from '../../../redux_toolkit/Timeline/TimelineThunks'
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info.svg'
import Http from '../../../helpers/Http'
import { toast } from 'react-toastify'
import ModalContainer from '../../../../app/common/modal/ModalContainer'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg'
import DesignRightSidePanel from '../../../v3/common/DesignRightSidePanel'
import ConversationRightSidePanel from '../../../v3/common/ConversationRightSidePanel'
import { useTeams } from '../../../redux_toolkit/Team'
import PackSetOrTag from '../../../v3/common/PackOrSetTag'
import DesignImages from '../../../v3/common/DesignImages'

const AllDesignList = ({ setLoader }) => {
    const [sizeData, setSizeData] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [openConversation, setOpenConversation] = useState(false)
    const [selectedDesignInfo, setSelectedDesignInfo] = useState(null)
    const timelineStore = useSelector((store) => store.timeline)
    const dispatch = useDispatch()
    const params = useParams()
    const members = useTeams()

    const isCheckboxChecked = (id) => {
        let design
        let designOfSet

        if (timelineStore.selectedDesignList.length > 0) {
            designOfSet = timelineStore?.selectedSetItem[0]?.setMemberList.find(
                (member) => member.id === id
            )
            design = timelineStore.selectedDesignList.find(
                (item) => item === id
            )
        }
        return design !== undefined || designOfSet !== undefined
    }

    const toggleCheckbox = async (value) => {
        window.scrollTo(0, 0)
        setLoader(true)
        await dispatch(
            toggleDesignSelection(value, generateParams(), params.orderId)
        )
        setLoader(false)
    }

    const generateParams = () => {
        return `${params.orderId}?page=0&size=15`
    }

    const getSizeColor = async (rfqId) => {
        try {
            const { data } = await Http.GET(
                'getOrderQuotes',
                `${params.orderId}/rfq/${rfqId}`
            )
            setSizeData(data)
        } catch (error) {
            if (error.message) {
                toast.error(error.message)
            } else {
                toast.error('Something went wrong! Please try again.')
            }
        }
    }

    const onCellClick = (e, design) => {
        if (e.currentTarget.id === 'cell') {
            if (isSetItem(design)) {
                toggleCheckbox({ id: design?.setMemberList[0]?.id, design })
            } else {
                toggleCheckbox({ id: design.id, design })
            }
        } else if (e.currentTarget.id === 'product') {
            setSelectedProduct(design.id)
            setSelectedDesignInfo({
                id: design?.id,
                isSet: isSetItem(design)
            })
        } else getSizeColor(design?.rfqId)
    }

    function getStatusText(design) {
        if (design?.actualDeliveryDate) {
            return (
                <p className='regular-14 green'>
                    Delivered (
                    {changeDateFormat(design.actualDeliveryDate, '', 'MMM DD')})
                </p>
            )
        } else if (design?.timeOfCompletion > 0) {
            return (
                <p className='regular-14'>
                    Due in {design.timeOfCompletion} days (
                    {changeDateFormat(
                        design.deliveryDate,
                        'YYYY-MM-DD',
                        'MMM DD'
                    )}
                    )
                </p>
            )
        } else {
            return (
                <p className='regular-14 red'>
                    {Math.abs(design.timeOfCompletion)} days Overdue (
                    {changeDateFormat(
                        design.deliveryDate,
                        'YYYY-MM-DD',
                        'MMM DD'
                    )}
                    )
                </p>
            )
        }
    }

    const getSizeWisePrice = (sizeWisePrices, id) => {
        if (sizeWisePrices && sizeWisePrices[id]) {
            return sizeWisePrices[id]
        }
        return null
    }

    const getColorWisePrice = (colorWisePrices, id) => {
        if (colorWisePrices && colorWisePrices[id]) {
            return colorWisePrices[id]
        }
        return null
    }

    const colrWiseSizeQuantityList = sizeData?.colorWiseSizeQuantityPairList
    let tableHeading =
        colrWiseSizeQuantityList?.length > 0
            ? colrWiseSizeQuantityList[0]?.sizeQuantityPairList
            : sizeData?.sizeQuantityPairList

    const renderSizeWise = () => {
        return (
            <ModalContainer
                className='create-folder-modal'
                onCloseModal={() => setSizeData(null)}
            >
                <div className='common-popup'>
                    <div className='common-popup-header d-flex justify-content-between'>
                        <div className='popup-tilte'>
                            <h3 className='semibold-16 mb-0'>
                                Color & Size Breakdown
                            </h3>
                            <p className='regular-12 gray_300'>
                                Size wise price
                            </p>
                        </div>
                        <div
                            className='close-btn cursor-pointer'
                            onClick={() => setSizeData(null)}
                        >
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='common-popup-body !pt-0 !pb-6'>
                        <div className='scroll-x-label'>
                            <table className='table small-padding'>
                                <thead>
                                    <tr>
                                        <th>Color</th>
                                        {tableHeading?.map((item) => (
                                            <th key={item.id}>{item.label}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {colrWiseSizeQuantityList &&
                                        colrWiseSizeQuantityList?.map(
                                            (value, index) => (
                                                <tr key={value.id}>
                                                    <td>{value?.name}</td>
                                                    {value &&
                                                        value.sizeQuantityPairList.map(
                                                            (
                                                                style,
                                                                inputIndex
                                                            ) => (
                                                                <td
                                                                    key={
                                                                        style.id
                                                                    }
                                                                >
                                                                    {
                                                                        style.quantity
                                                                    }
                                                                </td>
                                                            )
                                                        )}
                                                </tr>
                                            )
                                        )}
                                    <tr>
                                        <td>
                                            <span className='weight-500 black'>
                                                Price
                                            </span>
                                        </td>
                                        {colrWiseSizeQuantityList &&
                                            colrWiseSizeQuantityList[0]?.sizeQuantityPairList.map(
                                                (style) => (
                                                    <td key={style.id}>
                                                        <span className='secondary'>
                                                            {getSizeWisePrice(
                                                                sizeData?.sizeWiseBuyerPrice,
                                                                style.id
                                                            )}
                                                        </span>
                                                    </td>
                                                )
                                            )}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        )
    }

    const renderDesignWise = () => {
        return (
            <ModalContainer
                className='create-folder-modal'
                onCloseModal={() => setSizeData(null)}
            >
                <div className='common-popup'>
                    <div className='common-popup-header d-flex justify-content-between'>
                        <div className='popup-tilte'>
                            <h3 className='semibold-16 mb-0'>
                                Color & Size Breakdown
                            </h3>
                            <p className='regular-12 gray_300'>
                                Design wise price
                            </p>
                        </div>
                        <div
                            className='close-btn cursor-pointer'
                            onClick={() => setSizeData(null)}
                        >
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='common-popup-body !pt-0 !pb-6'>
                        <div className='scroll-x-label'>
                            <table className='table small-padding'>
                                <thead>
                                    <tr>
                                        <th>Color</th>
                                        {tableHeading?.map((item) => (
                                            <th key={item.id}>{item.label}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {colrWiseSizeQuantityList &&
                                        colrWiseSizeQuantityList?.map(
                                            (value, index) => (
                                                <tr key={value.id}>
                                                    <td>{value?.name}</td>
                                                    {value &&
                                                        value.sizeQuantityPairList.map(
                                                            (
                                                                style,
                                                                inputIndex
                                                            ) => (
                                                                <td
                                                                    key={
                                                                        style.id
                                                                    }
                                                                >
                                                                    {
                                                                        style.quantity
                                                                    }
                                                                </td>
                                                            )
                                                        )}
                                                </tr>
                                            )
                                        )}
                                    <tr>
                                        <td>
                                            <span className='weight-500 black'>
                                                Unit price
                                            </span>
                                        </td>
                                        <td colSpan='8' className='text-center'>
                                            <span className='secondary'>
                                                $
                                                {sizeData?.designWiseBuyerPrice}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        )
    }

    const renderColorWise = () => {
        return (
            <ModalContainer
                className='create-folder-modal'
                onCloseModal={() => setSizeData(null)}
            >
                <div className='common-popup'>
                    <div className='common-popup-header d-flex justify-content-between'>
                        <div className='popup-tilte'>
                            <h3 className='semibold-16 mb-0'>
                                Color & Size Breakdown
                            </h3>
                            <p className='regular-12 gray_300'>
                                Color wise price
                            </p>
                        </div>
                        <div
                            className='close-btn cursor-pointer'
                            onClick={() => setSizeData(null)}
                        >
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='common-popup-body !pt-0 !pb-6'>
                        <div className='scroll-x-label'>
                            <table className='table small-padding'>
                                <thead>
                                    <tr>
                                        <th>Color</th>
                                        {tableHeading?.map((item) => (
                                            <th key={item.id}>{item.label}</th>
                                        ))}
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sizeData?.colorWiseSizeQuantityPairList &&
                                        sizeData?.colorWiseSizeQuantityPairList?.map(
                                            (value, index) => (
                                                <tr key={value.id}>
                                                    <td>{value?.name}</td>
                                                    {value &&
                                                        value.sizeQuantityPairList.map(
                                                            (
                                                                style,
                                                                inputIndex
                                                            ) => (
                                                                <td
                                                                    key={
                                                                        style.id
                                                                    }
                                                                >
                                                                    {
                                                                        style.quantity
                                                                    }
                                                                </td>
                                                            )
                                                        )}
                                                    <td>
                                                        <span className='secondary'>
                                                            {getColorWisePrice(
                                                                sizeData?.colorWiseBuyerPrice,
                                                                value.id
                                                            )}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        )
    }

    const renderPopup = {
        DESIGNWISE: renderDesignWise(),
        SIZEWISE: renderSizeWise(),
        COLORWISE: renderColorWise()
    }

    const renderDesignList = () => {
        return timelineStore?.orderInfo?.orderProductList?.map(
            (design, index) => {
                return (
                    <div
                        className={`single-design-row ${
                            isCheckboxChecked(
                                isSetItem(design)
                                    ? design.setMemberList[0]?.id
                                    : design?.id
                            )
                                ? 'selected'
                                : ''
                        }`}
                        key={`design_number_${index}`}
                        // onClick={() => toggleCheckbox(design.id)}
                        id='cell'
                        onClick={(e) => onCellClick(e, design)}
                    >
                        <div className='design-details items-center'>
                            <div className='w-[60px]'>
                                <DesignImages
                                    designInfo={formatFeatureImageDocUrl(
                                        design
                                    )}
                                />
                            </div>
                            <div className='design-info'>
                                {isSetItem(design) && (
                                    <p className='mb-1'>
                                        <PackSetOrTag tagName='Set' />
                                    </p>
                                )}
                                {isPackItem(design) && (
                                    <p className='mb-1'>
                                        <PackSetOrTag tagName='Pack' />
                                    </p>
                                )}
                                {isSetItem(design) ? (
                                    <div>
                                        <Tooltip
                                            title={design.referenceNumber}
                                            placement='top'
                                            arrow
                                        >
                                            <span
                                                className='design-title'
                                                id='product'
                                                onClick={(e) =>
                                                    onCellClick(e, design)
                                                }
                                            >
                                                {getShortName(
                                                    design.referenceNumber,
                                                    25
                                                )}
                                            </span>
                                        </Tooltip>
                                        <p>PO: {design?.poNumber}</p>
                                    </div>
                                ) : (
                                    <ul className='common-list-component'>
                                        <li>
                                            <Tooltip
                                                title={design.referenceNumber}
                                                placement='top'
                                                arrow
                                            >
                                                <span
                                                    className='design-title'
                                                    id='product'
                                                    onClick={(e) =>
                                                        onCellClick(e, design)
                                                    }
                                                >
                                                    {getShortName(
                                                        design.referenceNumber,
                                                        15
                                                    )}
                                                </span>
                                            </Tooltip>
                                        </li>
                                        <li>PO: {design?.poNumber}</li>
                                    </ul>
                                )}

                                <p className='gray_400 d'>
                                    {design.quantity} Units / ${design?.amount}
                                    <span
                                        id='info'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onCellClick(e, design)
                                        }}
                                        className='ml-1 relative top-[-2px]'
                                    >
                                        <InfoIcon />
                                    </span>
                                </p>
                                <div className='date-status'>
                                    {getStatusText(design)}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        )
    }

    return (
        <>
            <div className='order-all-products-status mt-0 lg:mt-5 bg-white'>
                <div className='style-count flex justify-between p-3 4xl:p-4'>
                    <p className='regular-18 weight-500'>
                        Styles (
                        {timelineStore?.orderInfo?.orderProductList.length})
                    </p>
                </div>
                <div className='all-designs-with-status'>
                    <div className='design-lists custom-scroll'>
                        {renderDesignList()}
                    </div>
                </div>
            </div>
            {sizeData && renderPopup[sizeData?.buyerQuotationType]}
            {selectedProduct && (
                <DesignRightSidePanel
                    styleId={selectedProduct}
                    openModal={selectedProduct}
                    onCloseModal={() => setSelectedProduct(null)}
                    toggleConversation={() => {
                        setOpenConversation((prev) => !prev)
                    }}
                    styleInfo={selectedDesignInfo}
                />
            )}

            {openConversation && (
                <ConversationRightSidePanel
                    type='PRODUCT_DEVELOPMENT_COMMENT'
                    id={selectedProduct}
                    openModal={openConversation}
                    onGoBack={() => setOpenConversation(false)}
                    onCloseModal={() => {
                        setOpenConversation(false)
                        setSelectedProduct(null)
                    }}
                    memberList={members}
                />
            )}
        </>
    )
}

export default AllDesignList
