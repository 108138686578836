import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import {
    SET_AGE_GROUP_LIST,
    SET_ALL_DESIGN_LOOK,
    SET_ALL_FABRIC_TYPES,
    SET_CATEGORY_LIST,
    SET_FABRIC_LIST,
    SET_MARKET_LIST,
    SET_SEASON_LIST,
    SET_SUB_CATEGORY_LIST,
    SET_DEPARTMENTS_LIST
} from '../../@types/action.types'

const initialState = {}

const filterOptionReducers = createSlice({
    name: 'filterOption',
    initialState,
    reducers: {
        [SET_SEASON_LIST]: (state, { payload }) => {
            state.seasons = payload
        },
        [SET_MARKET_LIST]: (state, { payload }) => {
            state.markets = payload
        },
        [SET_CATEGORY_LIST]: (state, { payload }) => {
            state.categories = payload
        },
        [SET_SUB_CATEGORY_LIST]: (state, { payload }) => {
            state.subCategories = payload
        },
        [SET_AGE_GROUP_LIST]: (state, { payload }) => {
            state.ageGroups = payload
        },
        [SET_FABRIC_LIST]: (state, { payload }) => {
            state.fabrics = payload
        },
        [SET_ALL_FABRIC_TYPES]: (state, { payload }) => {
            state.fabricTypes = payload
        },
        [SET_ALL_DESIGN_LOOK]: (state, { payload }) => {
            state.designLooks = payload
        },
        [SET_DEPARTMENTS_LIST]: (state, { payload }) => {
            state.departments = payload
        }
    }
})

export const FilterOptionTypes = filterOptionReducers.actions

export const useFilterOption = () => useSelector((state) => state.filterOption)

export default filterOptionReducers.reducer
