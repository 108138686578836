import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
    changeDateFormat,
    getShortName,
    getUserInfo,
    setBreadCrumbRoutes,
    getBreadCrumbs
} from '../../../helpers/Util'
import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as RightArrow } from '../../../../assets/icons/old/rightArrow.svg'
import Http from '../../../helpers/Http'
import { ConversationActionTypes } from '../../../redux_toolkit/Conversation'
import { SET_CURRENT_ACTIVE_PAGE } from '../../../redux_toolkit/@types/action.types'
import { OrderContext } from '../../../useContext/OrderContext'
import OrderTask from './OrderTask'
import Modal from 'react-bootstrap/Modal'

const AllProductionList = () => {
    const timelineStore = useSelector((store) => store.timeline)
    const [showTaskDetailsModal, setShowTaskDetailsModal] = useState(false)
    const [supplierInfo, setSupplierInfo] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedId, setSelectedId] = useState(null)
    const [reviseInfo, setReviseInfo] = useState(null)
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (searchParams.get('deliverableId') && selectedId === null) {
            setShowTaskDetailsModal(true)
            setSelectedId(searchParams.get('deliverableId'))
        }
    }, [])

    useEffect(() => {
        Http.GET_WITH_ID_PARAM('getSupplierInfo', '', params.orderId).then(
            (response) => {
                setSupplierInfo(response.data)
            }
        )
    }, [])

    const renderStepIcon = (sample) => {
        if (
            sample?.currentStep?.status === 'APPROVED' ||
            sample?.currentStep?.status === 'COMPLETED'
        ) {
            return '/icons/Completed-icon.svg'
        } else {
            return '/icons/due-gray.svg'
        }
    }

    const renderStepClass = (sample) => {
        if (
            sample.formattedTaskStatus === 'APPROVED' ||
            sample.formattedTaskStatus === 'LATE_APPROVED' ||
            sample.status === 'SCOPE_OFF'
        ) {
            return 'completed'
        } else if (sample.formattedTaskStatus === 'EXPIRED') {
            return 'due'
        } else {
            return 'pending'
        }
    }

    const handleTaskManager = (sample) => {
        dispatch({
            type: ConversationActionTypes[SET_CURRENT_ACTIVE_PAGE],
            payload: {
                id: sample.id,
                type: 'TASK_CONVERSATION',
                pageType: 'DETAILS'
            }
        })
        setSelectedId(sample.id)
        setShowTaskDetailsModal(true)
    }
    const getClassNameByStatus = {
        COMPLETED: 'green',
        EXPIRED: 'red',
        OTHER: 'other'
    }
    const renderStatusWiseDate = (sample, date, type) => {
        return (
            <>
                {sample?.dateOver > 0 && (
                    <span className='red date-count state mr-1'>
                        +{sample?.dateOver}
                    </span>
                )}
                {sample.status !== 'PENDING' && sample?.dateOver < 0 && (
                    <span className='green date-count state mr-1'>
                        {sample?.dateOver}
                    </span>
                )}
                <span
                    className={`${getClassNameByStatus[type]} state ml-2 mr-1`}
                >
                    {date && changeDateFormat(date, 'YYYY-MM-DD', 'MMM DD')}
                </span>
            </>
        )
    }

    const renderTaskDate = (sample) => {
        return (
            <div className='cursor-pointer regular-12 w-100'>
                {sample?.formattedTaskStatus === 'COMPLETED' ||
                sample?.formattedTaskStatus === 'APPROVED' ||
                sample?.formattedTaskStatus === 'LATE_APPROVED'
                    ? renderStatusWiseDate(
                          sample,
                          sample?.actualEndDate,
                          'COMPLETED'
                      )
                    : sample?.formattedTaskStatus === 'EXPIRED'
                    ? renderStatusWiseDate(sample, sample?.endDate, 'COMPLETED')
                    : renderStatusWiseDate(sample, sample?.endDate, 'OTHER')}
            </div>
        )
    }

    const renderDescription = (step) => {
        if (step?.currentStep?.stepName?.length > 20) {
            return (
                <Tooltip
                    title={step?.currentStep?.stepName}
                    placement={'top'}
                    arrow
                >
                    <span>{getShortName(step?.currentStep?.stepName, 20)}</span>
                </Tooltip>
            )
        } else {
            return <span>{step?.currentStep?.stepName}</span>
        }
    }

    const renderStepDetails = (step) => {
        if (step?.stageConstants === 'PRODUCTION') {
            let selectedDesign =
                timelineStore?.orderInfo?.orderProductList?.find(
                    (item) =>
                        parseInt(item.id) ===
                        parseInt(timelineStore?.selectedDesignList[0])
                )
            return (
                <span>
                    {step?.currentStep?.actualProductionQuantity || 0}/
                    {selectedDesign?.quantity || 0}
                </span>
            )
        } else {
            return renderDescription(step)
        }
    }

    const renderSamplingStepList = (list) => {
        return list?.map((sample, index) => {
            return (
                <div
                    className='single-task-group'
                    key={`sample_index_${sample.id}`}
                >
                    <span className='task-group-title'>{sample.name}</span>
                    <div
                        className={`single-task ${renderStepClass(sample)}`}
                        onClick={() => handleTaskManager(sample)}
                    >
                        <div className='task-name'>
                            <img src={renderStepIcon(sample)} alt='complete' />
                            {renderStepDetails(sample)}
                            {sample?.currentStep?.revisionCount && (
                                <span className='status red-alert ml-1 mr-1'>
                                    <img
                                        src='/icons/update-white.svg'
                                        alt='update'
                                    />
                                    {sample?.currentStep?.revisionCount}
                                </span>
                            )}
                        </div>
                        <div className='date-details'>
                            <span>{renderTaskDate(sample?.currentStep)}</span>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const closeModal = () => {
        setShowTaskDetailsModal(false)
    }

    const resetRevisionInfo = () => {
        setReviseInfo(null)
    }

    const onClickDetails = (supplierInfo) => {
        let newRoute = `/supplier/view/${supplierInfo?.id}`
        let visitingRoutes = [
            ...getBreadCrumbs(),
            {
                key: newRoute,
                value: supplierInfo?.name
            }
        ]
        setBreadCrumbRoutes(visitingRoutes)
        navigate(newRoute)
    }

    return (
        <>
            <div className='one-third all-production-details'>
                {!supplierInfo[0]?.profileImageDocUrl && (
                    <div className='mt-4 xl:mt-0 mx-4 xl:mx-0'>
                        <button
                            onClick={() => onClickDetails(supplierInfo[0])}
                            type='button'
                            className='button primary mb-3 lg:mb-5 w-full flex justify-between items-center whitespace-nowrap'
                        >
                            <Tooltip
                                title={supplierInfo[0]?.name}
                                placement={'top'}
                                arrow
                            >
                                <span>
                                    {getShortName(supplierInfo[0]?.name, 30)}
                                </span>
                            </Tooltip>
                            <span className='ml-2'>
                                <RightArrow />
                            </span>
                        </button>
                    </div>
                )}

                {supplierInfo[0]?.profileImageDocUrl && (
                    <div className='factory-view relative m-4 mt-0 xl:m-0 mb-4'>
                        <button
                            className='button primary w-full absolute left-0 bottom-[-20px]'
                            onClick={() => onClickDetails(supplierInfo[0])}
                        >
                            <span className='py-2 flex justify-between'>
                                {supplierInfo[0]?.name}
                                <RightArrow className='ml-4' />
                            </span>
                        </button>
                        {supplierInfo[0]?.profileImageDocUrl && (
                            <img
                                src={supplierInfo[0]?.profileImageDocUrl}
                                alt='supplier_image'
                                onClick={() =>
                                    navigate(
                                        `/supplier/view/${supplierInfo[0]?.id}`
                                    )
                                }
                                className='w-full rounded-md'
                            />
                        )}
                    </div>
                )}

                <div className='time-actions'>
                    <div className='text-tc'>
                        <span className='regular-18 weight-500'>
                            Time & action / critical dates
                        </span>
                    </div>

                    <div className='production-accordion custom-scroll'>
                        <div className='accordion' id='accordionExample'>
                            <div className='accordion-item'>
                                <div
                                    id='collapseSampling'
                                    className='accordion-collapse bg-white collapse show'
                                    aria-labelledby='headingSampling'
                                    data-bs-parent='#accordionExample'
                                >
                                    <div className='card-body'>
                                        <div className='all-task-status'>
                                            {renderSamplingStepList(
                                                timelineStore?.stepList
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showTaskDetailsModal}
                onHide={() => {
                    closeModal()
                }}
                className={'modal-right task-conversation with-tag-person'}
                aria-labelledby='example-custom-modal-styling-title'
            >
                <OrderContext.Provider
                    value={{
                        deliverableId: selectedId,
                        brandName: getUserInfo()?.brandResponse?.name,
                        reviseInfo: reviseInfo,
                        orderId: params.orderId,
                        closeModal,
                        resetRevisionInfo
                    }}
                >
                    <OrderTask callback={() => false} />
                </OrderContext.Provider>
            </Modal>
        </>
    )
}

export default AllProductionList
