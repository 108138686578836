import React, { useEffect, useState } from 'react'
import AllProductionList from './AllProductionList'

const TimelineProductionDetails = ({ setLoader }) => {
    const [isLargeDevice, setIsLargeDevice] = useState(false)

    useEffect(() => {
        let element = document.getElementById('task-status-hidden-modal')
        setIsLargeDevice(window.getComputedStyle(element).display === 'none')
    }, [])

    return (
        <>
            <div className='tab-none one-third'>
                {isLargeDevice && <AllProductionList setLoader={setLoader} />}
            </div>
        </>
    )
}

export default TimelineProductionDetails
