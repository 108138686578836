import { createSlice } from '@reduxjs/toolkit'
import CollectionDetailsReducer from './CollectionDetailsReducer'
import { useSelector } from 'react-redux'

const initialState = {
    response: {}
}

const CollectionDetails = createSlice({
    name: 'collectionDetails',
    initialState,
    reducers: CollectionDetailsReducer
})

export const CollectionDetailsAction = CollectionDetails.actions

export const useCollectionDetails = () => useSelector(state => state.collectionDetails.response)

export default CollectionDetails.reducer
