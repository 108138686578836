import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Tooltip from '../common/Tooltip'
import { ReactComponent as DownloadIcon } from '../../../assets/images/download-2.svg'
import { ReactComponent as CollectionFillIcon } from '../../../assets/icons/collection-fill.svg'
import { ReactComponent as NarrowViewSelected } from '../../../assets/icons/narrow-view-selected.svg'
import { ReactComponent as ChatIcon } from '../../../assets/icons/chat.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg'
import Http from '../../helpers/Http'
import { closeLoader, openLoader, useLoader } from '../../redux_toolkit/Loader'
import {
    addToDraft,
    fetchFavouriteDetailsLists,
    fetchFavouriteDetailsListTableData,
    fetchFavouriteProductsByFolderId,
    fetchFolderDetailsById,
    removeFromdraft,
    undoFavourite
} from '../../services/v3/Favourite/FavouriteService'
import {
    capitalizeFirstAndLowercaseRest,
    capitalizeFirstLetter,
    downloadBlobFIle,
    getUserPreference,
    isAllIdExistBetweenTwoList,
    isPageReachBottom
} from '../../helpers/Util'
import { setSystemUserPreferences } from '../../services/CommonService'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { ReactComponent as CollectionUnFillIcon } from '../../../assets/icons/collection-unfill.svg'
import { ReactComponent as PlayIcon } from '../../../assets/icons/play.svg'
import { ReactComponent as ListViewUnfillIcon } from '../../../assets/icons/list-view-unfill.svg'
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/arrow-left.svg'
import { ReactComponent as RightArrowIcon } from '../../../assets/icons/arrow-right.svg'
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/arrow-down.svg'
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus.svg'
import { ReactComponent as SelectedListViewIcon } from '../../../assets/icons/list-view-filled.svg'
import { ReactComponent as SelectedCardViewIcon } from '../../../assets/icons/grid-view-active.svg'
import BreadCrumb from '../common/BreadCrumb'
import DesignRightSidePanel from '../common/DesignRightSidePanel'
import PresentationViewModal from '../common/PresentationViewModal'
import AddPeople from '../common/AddPeople'
import { FavouriteThunks } from '../../redux_toolkit/Favourite/FavouriteThunks'
import { GET_FAVOURITE_COUNT } from '../../redux_toolkit/@types/thunk.types'
import RequestForSample from '../../common/RequestForSample'
import RequestForQuotes from '../../common/RequestForQuotes'
import CustomDropdown from '../common/CustomDropdown'
import TableView from './TableView'
import ConversationRightSidePanel from '../common/ConversationRightSidePanel'
import PlaceOrder from '../../modules/quote/PlaceOrder'
import DesignImages from '../common/DesignImages'
import PackSetOrTag from '../common/PackOrSetTag'
import SetStyleMainFabricType from '../common/SetStyleMainFabricType'

const sortOptions = [
    {
        key: 'Default',
        value: ''
    },
    {
        key: 'Category',
        value: 'category.id,desc'
    },
    {
        key: 'Fabric Type',
        value: 'fabricType,asc'
    },
    {
        key: 'Qty high to low',
        value: 'quantity,desc'
    },
    {
        key: 'Qty low to high',
        value: 'quantity,asc'
    }
]

const FavouriteDetails = () => {
    const [selectedDesign, setSelectedDesign] = useState(null)
    const [isShowSample, setIsShowSample] = useState(false)
    const [favouriteList, setFavouriteList] = useState([])
    const [draftList, setDraftList] = useState([])
    const [isQuoteShow, setIsQuoteShow] = useState(false)
    const [selectedIds, setSelectedIds] = useState([])
    const [folderInfo, setFolderInfo] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [isShowDraft, setIsShowDraft] = useState(true)
    const [isShowPresentation, setIsShowPresentation] = useState(false)
    const [sort, setSort] = useState(sortOptions[0])
    const [viewType, setViewType] = useState(null)
    const [showOptions, setShowOptions] = useState(false)
    const [openConversationId, setOpenConversationId] = useState(null)
    const [openFolderConversation, setOpenFolderConversation] = useState(false)
    const [isOrderDisable, setIsOrderDisable] = useState(false)
    const [isPlaceOrder, setIsPlaceOrder] = useState(false)

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const isLoading = useRef(null)
    const globalLoader = useLoader(null)
    const dispatch = useDispatch()
    const scrollRef = useRef()
    const draftScrollRef = useRef()
    const { id } = useParams()
    const navigate = useNavigate()

    const fetchFolderInfos = async () => {
        let params = ''
        if (id && id !== 'my-favourite') {
            params += `?folderId=${id}`
            const data = await fetchFolderDetailsById(params)
            setFolderInfo(data)
        } else {
            const data = await fetchFolderDetailsById(params)
            setFolderInfo(data)
        }
    }

    const setIsDataLoading = (data) => {
        isLoading.current = data
    }

    useEffect(() => {
        setIsShowDraft(draftList?.length > 0 && viewType !== 'TABLE_VIEW')
    }, [draftList, viewType])

    useEffect(() => {
        fetchDesignList(0, false)
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [sort, viewType])

    useEffect(() => {
        fetchFolderInfos()
        if (viewType !== 'TABLE_VIEW') {
            fetchDesignList(0, true)
            document.addEventListener('scroll', handleDraftScroll)
            return () => {
                document.removeEventListener('scroll', handleDraftScroll)
            }
        }
    }, [viewType])

    useEffect(() => {
        setIsDataLoading(globalLoader)
    }, [globalLoader])

    useEffect(() => {
        let type = getUserPreference()?.favouriteDetailsViewType || 'CARD_VIEW'
        setViewType(type)
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        findFirstMatchAndBreak()
    }, [selectedIds])

    function findFirstMatchAndBreak() {
        let quoteList = favouriteList || []
        for (const item of quoteList) {
            if (isDisabled(item)) {
                setIsOrderDisable(true)
                return item
            }
        }
        setIsOrderDisable(false)
        return null
    }

    const isDisabled = (quoteItem) => {
        return (
            selectedIds?.length > 0 &&
            quoteItem?.quoteStatus !== 'APPROVED' &&
            selectedIds?.includes(quoteItem?.id)
        )
    }

    const toggleDesignViewType = async (type) => {
        setSelectedIds([])
        await setSystemUserPreferences({
            favouriteDetailsViewType: type
        })
        setSort(sortOptions[0])
        setViewType(type)
    }

    const generateParams = (pageNumber, isDrafted) => {
        let params = `?page=${pageNumber}&size=20&isDrafted=${isDrafted}`
        if (sort?.key !== 'Sort') {
            params += `&sort=${sort?.value}`
        }
        if (id && id !== 'my-favourite') {
            params += `&folderId=${id}`
        }
        return params
    }

    const fetchDesignList = async (pageNumber, isDrafted = false) => {
        try {
            if (!viewType) {
                return
            }

            dispatch(openLoader())
            let data
            if (['CARD_VIEW', 'NARROW_VIEW']?.includes(viewType)) {
                data = await fetchFavouriteDetailsLists(
                    generateParams(pageNumber, isDrafted)
                )
            } else {
                let params = `?page=${pageNumber}&size=20&sort=${sort?.value}`
                if (id && id !== 'my-favourite') {
                    params += `&folderId=${id}`
                }
                data = await fetchFavouriteDetailsListTableData(params)
            }
            if (!isDrafted) {
                await dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]())

                const newData =
                    pageNumber === 0
                        ? data.data
                        : [...scrollRef.current.data, ...data.data]
                scrollRef.current = data
                scrollRef.current.data = newData
                setFavouriteList(newData)
            } else if (viewType !== 'TABLE_VIEW') {
                const newData =
                    pageNumber === 0
                        ? data.data
                        : [...draftScrollRef.current.data, ...data.data]
                draftScrollRef.current = data
                draftScrollRef.current.data = newData
                setDraftList(newData)
            }
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const handleScroll = () => {
        if (isPageReachBottom()) {
            let { totalElements, currentPage, data, totalPages } =
                scrollRef.current
            let newPage = currentPage + 1
            if (
                totalElements > 0 &&
                totalElements !== data.length &&
                !isLoading.current &&
                newPage !== totalPages
            ) {
                fetchDesignList(newPage)
            }
        }
    }

    const handleDraftScroll = () => {
        if (isPageReachBottom()) {
            let { totalElements, currentPage, data, totalPages } =
                draftScrollRef.current
            let newPage = currentPage + 1
            if (
                totalElements > 0 &&
                totalElements !== data.length &&
                !isLoading.current &&
                newPage !== totalPages
            ) {
                fetchDesignList(newPage)
            }
        }
    }

    const toggleAllSelection = async () => {
        try {
            // selectedIds.length === scrollRef.current.totalElements
            if (selectedIds.length >= favouriteList.length) {
                setSelectedIds([])
            } else {
                dispatch(openLoader())
                let params = ''
                if (id && id !== 'my-favourite') {
                    params += `?folderId=${id}`
                }
                const ids = await fetchFavouriteProductsByFolderId(params)
                setSelectedIds(ids)
                dispatch(closeLoader())
            }
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const toggleSelection = (id) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter((item) => item !== id))
        } else {
            setSelectedIds((prev) => [...prev, id])
        }
    }

    const onToggleCategoryData = (categoryName, categoryWiseSelectedItems) => {
        let ids = categoryWiseSelectedItems?.map((item) => item.id)
        if (isAllIdExistBetweenTwoList(ids, selectedIds)) {
            setSelectedIds([])
        } else {
            setSelectedIds(ids)
        }
    }

    const toggleFavouriteModal = async (productId) => {
        try {
            dispatch(openLoader())
            await undoFavourite(productId, id)
            let tmpData = draftScrollRef.current.data.filter(
                (item) => item.id !== productId
            )
            draftScrollRef.current.totalElements =
                draftScrollRef.current.totalElements - 1
            draftScrollRef.current.data = tmpData
            setDraftList(tmpData)
            setOpenModal(false)
            setSelectedDesign(null)
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    // const onUpdateFavouriteData = (tmpData) => {
    //     scrollRef.current.totalElements = scrollRef.current.totalElements - 1
    //     scrollRef.current.data = tmpData
    //     setFavouriteList(tmpData)
    // }

    // const onUpdateDraftBoardData = (design) => {
    //      let tmpData = draftScrollRef.current.data.unshift(design)
    //     draftScrollRef.current.totalElements = draftScrollRef.current.totalElements + 1
    //     draftScrollRef.current.data = tmpData
    //     setDraftList(tmpData)
    // }

    const onAddToDraft = async (design) => {
        try {
            let params = ''
            if (id && id !== 'my-favourite') {
                params = `${design.id}?folderId=${id}`
            } else {
                params = `${design.id}`
            }
            await addToDraft(params)
            setSelectedIds([])
            let tmpData = scrollRef.current.data.filter(
                (item) => item.id !== design.id
            )

            scrollRef.current.totalElements =
                scrollRef.current.totalElements - 1
            scrollRef.current.data = tmpData
            setFavouriteList(tmpData)

            let newData = [design, ...draftScrollRef.current.data]
            draftScrollRef.current.totalElements =
                draftScrollRef.current.totalElements + 1
            draftScrollRef.current.data = newData
            fetchFolderInfos()
            setDraftList(newData)
            setSelectedDesign(null)
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const onRemoveFromDraft = async (design) => {
        try {
            let params = ''
            if (id && id !== 'my-favourite') {
                params = `${design?.id}?folderId=${id}`
            } else {
                params = `${design?.id}`
            }
            await removeFromdraft(params)
            let tmpData = draftScrollRef.current.data.filter(
                (item) => item.id !== design?.id
            )

            draftScrollRef.current.totalElements =
                draftScrollRef.current.totalElements - 1
            draftScrollRef.current.data = tmpData
            setDraftList(tmpData)

            let newData = [design, ...scrollRef.current.data]
            scrollRef.current.totalElements =
                scrollRef.current.totalElements + 1
            scrollRef.current.data = newData
            setFavouriteList(newData)

            setSelectedDesign(null)
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const onClickDesign = (item) => {
        setSelectedDesign(item)
        setOpenModal(true)
    }

    const onClickSort = (item) => {
        setSelectedIds([])
        setSort(item)
        setShowOptions(false)
    }

    const renderDesignCard = (favouriteData, categoryName) => {
        return (
            <>
                {categoryName ? (
                    <p className='text-xl ml-3 weight-700'>
                        {capitalizeFirstLetter(categoryName)}:{' '}
                        {favouriteData?.length}
                    </p>
                ) : null}
                <div className='px-4'>
                    <div
                        className={`design-list-container pt-5 pb-10 ${
                            viewType === 'NARROW_VIEW' ? 'image-view' : ''
                        }`}
                    >
                        {favouriteData?.map((item) => (
                            <div
                                key={`favourite_card_${item.id}`}
                                className={`${
                                    !item?.backImageDocument?.docUrl ||
                                    item?.isSet
                                        ? 'single-card-item no-rotation'
                                        : 'single-card-item'
                                }`}
                            >
                                <DesignImages
                                    designInfo={item}
                                    callback={() => onClickDesign(item)}
                                />
                                <div className='style-status flex  items-center gap-2'>
                                    {item.isNew && (
                                        <span className='status blue-3'>
                                            New
                                        </span>
                                    )}

                                    {item?.isPack && (
                                        <PackSetOrTag tagName='Pack' />
                                    )}
                                    {item?.isSet && (
                                        <PackSetOrTag tagName='Set' />
                                    )}
                                    {isDisabled(item) && (
                                        <WarningIcon className='mr-1' />
                                    )}
                                </div>

                                <div className='style-action'>
                                    <div>
                                        <input
                                            type='checkbox'
                                            name='categories'
                                            checked={selectedIds.includes(
                                                item.id
                                            )}
                                            onChange={() =>
                                                toggleSelection(item.id)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='style-info'>
                                    <div
                                        className='info'
                                        onClick={() => onClickDesign(item)}
                                    >
                                        <p className='regular-18 weight-500'>
                                            {capitalizeFirstAndLowercaseRest(
                                                item?.subCategoryResponse?.name
                                            )}
                                            {item?.isSet && item?.name}
                                        </p>
                                        <ul className='common-list-component mt-1'>
                                            {!item?.isSet &&
                                                item?.mainFabricType && (
                                                    <li className='regular-14'>
                                                        {capitalizeFirstAndLowercaseRest(
                                                            item?.mainFabricType
                                                        )}
                                                    </li>
                                                )}
                                            {item?.isSet && (
                                                <SetStyleMainFabricType
                                                    product={item}
                                                />
                                            )}
                                            {item?.referenceNumber &&
                                                !item?.isSet && (
                                                    <li className='regular-14'>
                                                        {item?.referenceNumber}
                                                    </li>
                                                )}
                                        </ul>
                                    </div>
                                    <div className='favorite'>
                                        <button
                                            className='button secondary icon-only'
                                            onClick={() => onAddToDraft(item)}
                                        >
                                            <MinusIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    }

    const onSetUpdatedData = (data) => {
        let updatedData = [...favouriteList]
        const index = updatedData.findIndex(
            (dataItem) => dataItem?.id === data?.id
        )
        if (index !== -1) {
            updatedData[index] = data
            setFavouriteList(updatedData)
        }
    }

    const getGroupName = (item) => {
        if (sort?.key === 'Category') return item?.categoryResponse?.name
        let fabricType = item?.mainFabricType || item?.fabricType
        return fabricType
    }

    const renderDesigns = (favouriteData) => {
        if (sort?.key === 'Category' || sort?.key === 'Fabric Type') {
            const groupedData = {}
            favouriteData?.forEach((item) => {
                const categoryName = item?.isSet
                    ? 'Set'
                    : getGroupName(item) || ''
                if (!groupedData[categoryName]) {
                    groupedData[categoryName] = []
                }
                groupedData[categoryName].push(item)
            })
            if (['CARD_VIEW', 'NARROW_VIEW']?.includes(viewType)) {
                const renderedCards = Object.entries(groupedData).map(
                    ([category, items]) => {
                        return renderDesignCard(items, category)
                    }
                )
                return renderedCards
            } else {
                return Object.entries(groupedData).map(([category, items]) => {
                    return (
                        <div key={category}>
                            <TableView
                                categoryName={category}
                                favouriteData={items}
                                setFavouriteData={(data) =>
                                    onSetUpdatedData(data[0])
                                }
                                selectedIds={selectedIds}
                                toggleSelection={toggleSelection}
                                toggleAllSelection={() =>
                                    onToggleCategoryData(category, items)
                                }
                                onClickDesign={onClickDesign}
                                isDisabled={isDisabled}
                            />
                        </div>
                    )
                })
            }
        } else {
            return ['CARD_VIEW', 'NARROW_VIEW']?.includes(viewType) ? (
                renderDesignCard(favouriteData)
            ) : (
                <TableView
                    favouriteData={favouriteData}
                    setFavouriteData={setFavouriteList}
                    selectedIds={selectedIds}
                    toggleSelection={toggleSelection}
                    toggleAllSelection={toggleAllSelection}
                    onClickDesign={(item) => onClickDesign(item)}
                    isDisabled={isDisabled}
                />
            )
        }
    }

    const onCloseModal = () => {
        setOpenModal(false)
        setSelectedDesign(null)
    }

    const members = () => {
        return folderInfo?.members?.filter((item) =>
            ['BUYER', 'ACCOUNT_MANAGER', 'PROJECT_MANAGER'].includes(
                item.primaryUserType
            )
        )
    }

    const onDownLoad = async () => {
        try {
            dispatch(openLoader())
            let params = `/download-report?page=0&size=250&sort=${sort?.value}&productIds=${selectedIds}`
            if (id && id !== 'my-favourite') {
                params += `&folderId=${id}`
            }
            const response = await Http.GET_FILE_DATA(
                'fetchBuyerLikedDesignsSummary',
                params
            )
            downloadBlobFIle(response)
            setSelectedIds([])
            dispatch(closeLoader())
        } catch ({ response }) {
            dispatch(closeLoader())
            if (response) toast.error(response.data.message)
            else toast.error('Something went wrong')
        }
    }

    const renderEmptyView = () => {
        return (
            <div className='epmpty-state py-40 text-center max-w-[600px] m-auto'>
                <h3 className='regular-20 weight-700 gray_400 mb-2'>
                    No design found.
                </h3>
                <p className='regular-12 gray_300'>
                    Explore our design lab to add designs in this folder
                </p>
                <div className='mt-6'>
                    <button
                        className='button primary sm'
                        onClick={() => navigate('/design-lab')}
                    >
                        Visit Design Lab
                    </button>
                </div>
            </div>
        )
    }

    const renderDraftViewButton = () => {
        return (
            <div>
                {isShowDraft ? (
                    <button
                        className='button primary sm round hidden sm:flex items-center '
                        onClick={() => setIsShowDraft(false)}
                    >
                        {' '}
                        <span className='mr-1'>Hide</span>
                        <RightArrowIcon />
                    </button>
                ) : (
                    <button
                        className='button primary sm round hidden sm:flex items-center'
                        onClick={() => setIsShowDraft(true)}
                    >
                        {' '}
                        <LeftArrowIcon />
                        <span className='ml-1'>
                            Draft ({draftList?.length})
                        </span>
                    </button>
                )}
            </div>
        )
    }

    const renderSortingDropdown = () => {
        return (
            <div className='block'>
                <CustomDropdown
                    label={'Default'}
                    icon={<DownArrowIcon />}
                    selectedOption={sort.key}
                    isShowOptions={showOptions}
                    setIsShowOptions={setShowOptions}
                >
                    <div className='list-items scroll-y-label cursor-pointer'>
                        {sortOptions
                            ?.slice(
                                0,
                                ['CARD_VIEW', 'NARROW_VIEW']?.includes(viewType)
                                    ? 3
                                    : sortOptions.length
                            )
                            .map((item) => {
                                return (
                                    <div
                                        key={`sort_${item.key}`}
                                        className='single-item'
                                        onClick={() => {
                                            onClickSort(item)
                                        }}
                                    >
                                        <p>{item.key}</p>
                                    </div>
                                )
                            })}
                    </div>
                </CustomDropdown>
            </div>
        )
    }

    const onPlaceOrderSuccess = (data) => {
        const updatedFavouriteList = favouriteList.map((item) => {
            const matchingDataItem = data.find(
                (dataItem) => dataItem.id === item.id
            )
            if (matchingDataItem) {
                return { ...item, ...matchingDataItem }
            }
            return item
        })
        setFavouriteList(updatedFavouriteList)
    }

    return (
        <>
            <div className='favourite-details-container flex'>
                <div
                    className={`${
                        isShowDraft
                            ? 'favourite-details-data pb-24 collapsed'
                            : 'favourite-details-data pb-24'
                    }`}
                >
                    <div className='flex flex-wrap sm:flex-nowrap justify-between py-3 pr-4'>
                        <div>
                            <BreadCrumb />
                        </div>
                        <div className='ml-4 mt-4 sm:ml-0 sm:mt-0'>
                            {id && id !== 'my-favourite' && (
                                <AddPeople
                                    requestFrom={'FAVOURITE_DETAILS'}
                                    members={members()}
                                    callback={fetchFolderInfos}
                                />
                            )}
                        </div>
                    </div>
                    {scrollRef?.current?.totalElements === 0 &&
                    !globalLoader ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                            className='py-3 px-4'
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                                className='py-3 px-4'
                            >
                                {renderDraftViewButton()}
                            </div>
                            {renderEmptyView()}
                        </div>
                    ) : (
                        <div>
                            <div className='flex flex-wrap sm:flex-nowrap justify-between py-3 px-4'>
                                <div className='actions mb-3 sm:mb-0'>
                                    <button
                                        className='button !p-0 min-w-[44px]'
                                        onClick={() =>
                                            toggleDesignViewType('CARD_VIEW')
                                        }
                                    >
                                        <Tooltip title='Card view'>
                                            {viewType === 'CARD_VIEW' ? (
                                                <SelectedCardViewIcon className='!w-[44px] !h-[44px]' />
                                            ) : (
                                                <CollectionUnFillIcon className='!min-w-[24px] !min-h-[24px]' />
                                            )}
                                        </Tooltip>
                                    </button>

                                    <button
                                        className='button mx-2 !p-0 min-w-[44px]'
                                        onClick={() =>
                                            toggleDesignViewType('NARROW_VIEW')
                                        }
                                    >
                                        <Tooltip title='Image view'>
                                            {viewType === 'NARROW_VIEW' ? (
                                                <NarrowViewSelected className='!w-[44px] !h-[44px]' />
                                            ) : (
                                                <CollectionFillIcon className='!min-w-[24px] !min-h-[24px]' />
                                            )}
                                        </Tooltip>
                                    </button>
                                    <button
                                        className='button !p-0 min-w-[44px]'
                                        onClick={() =>
                                            toggleDesignViewType('TABLE_VIEW')
                                        }
                                    >
                                        <Tooltip title='List view'>
                                            {viewType === 'TABLE_VIEW' ? (
                                                <SelectedListViewIcon className='!w-[44px] !h-[44px]' />
                                            ) : (
                                                <ListViewUnfillIcon className='!w-[24px] !h-[24px]' />
                                            )}
                                        </Tooltip>
                                    </button>
                                    <button
                                        className='button !p-0 ml-2'
                                        onClick={() =>
                                            setIsShowPresentation(true)
                                        }
                                    >
                                        <Tooltip title='Presentation view'>
                                            <PlayIcon className='!w-[24px] !h-[24px]' />
                                        </Tooltip>
                                    </button>
                                </div>
                                {screenWidth < 640 && renderSortingDropdown()}

                                {['CARD_VIEW', 'NARROW_VIEW']?.includes(
                                    viewType
                                ) && renderDraftViewButton()}
                            </div>
                            <div className='flex flex-wrap sm:flex-nowrap justify-between items-center py-3 px-4 gap-4'>
                                <div className='flex flex-wrap gap-x-7 gap-y-2'>
                                    <p className='text-sm weight-500'>
                                        Total:{' '}
                                        {scrollRef?.current?.totalElements}
                                    </p>
                                    {folderInfo?.categoryCounts?.length > 0 &&
                                        folderInfo?.categoryCounts?.map(
                                            (item) => (
                                                <p
                                                    className='text-sm'
                                                    key={item?.categoryId}
                                                >
                                                    {capitalizeFirstLetter(
                                                        item?.categoryName
                                                    )}
                                                    &nbsp;({item?.count})
                                                </p>
                                            )
                                        )}
                                </div>
                                {screenWidth > 640 && renderSortingDropdown()}
                            </div>
                            {renderDesigns(favouriteList)}
                        </div>
                    )}
                </div>

                {isShowDraft && (
                    <div className='draft-board px-3 scroll-y-label'>
                        <div className='draft-board-header py-4'>
                            <div className='flex justify-between items-center'>
                                <h3 className='text-xl font-bold'>
                                    Draft Board
                                </h3>
                                <CloseIcon
                                    className='cursor-pointer'
                                    onClick={() => setIsShowDraft(false)}
                                />
                            </div>
                            <p className='text-sm mt-2'>
                                Total:{' '}
                                <span className='weight-500'>
                                    {draftScrollRef?.current?.totalElements}
                                </span>{' '}
                            </p>
                        </div>
                        <div className='draft-board-body pb-4'>
                            <div className='grid grid-cols-1 gap-4'>
                                {draftList?.map((item) => (
                                    <div
                                        className={`single-card-item ${
                                            item?.isSet ? 'no-rotation' : ''
                                        }`}
                                        key={item?.id}
                                    >
                                        <DesignImages
                                            designInfo={item}
                                            callback={() => false}
                                        />
                                        <div className='style-action'>
                                            <DeleteIcon
                                                className='cursor-pointer'
                                                onClick={() =>
                                                    toggleFavouriteModal(
                                                        item?.id
                                                    )
                                                }
                                            />
                                        </div>
                                        <button
                                            disabled={viewType === 'TABLE_VIEW'}
                                            className='button secondary icon-only absolute right-2 bottom-2'
                                            onClick={() =>
                                                onRemoveFromDraft(item)
                                            }
                                        >
                                            <PlusIcon />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {openModal && (
                <DesignRightSidePanel
                    styleInfo={selectedDesign}
                    styleId={selectedDesign.id}
                    openModal={openModal}
                    onCloseModal={onCloseModal}
                    // toggleFavouriteModal={() =>
                    //     toggleFavouriteModal(false, selectedDesign.id)
                    // }
                    isHideBottom={!(id && id !== 'my-favourite')}
                    toggleConversation={(id) => {
                        setOpenConversationId(id)
                    }}
                    requestFrom='FAVOURITE'
                />
            )}

            {screenWidth < 640 && (
                <div className='selected-item-count flex justify-center'>
                    <div className='text-center'>
                        {isShowDraft ? (
                            <button
                                className='button primary sm round'
                                onClick={() => setIsShowDraft(false)}
                            >
                                {' '}
                                <span className='mr-1'>Hide</span>
                                <RightArrowIcon />
                            </button>
                        ) : (
                            <button
                                className='button primary sm round'
                                onClick={() => setIsShowDraft(true)}
                            >
                                <LeftArrowIcon />
                                <span className='ml-2'>
                                    Draft ({draftList?.length})
                                </span>
                            </button>
                        )}
                    </div>
                </div>
            )}

            {selectedIds.length > 0 && (
                <div className='selected-item-count sm:flex justify-center block on-favourite'>
                    <div className='item-count-content items-center sm:flex justify-between max-w-[1200px] w-full block'>
                        <div className='flex items-center justify-center mt-[22px] sm:mt-0'>
                            <p className='regular-18'>
                                {selectedIds.length}{' '}
                                {selectedIds.length > 1 ? 'Items' : 'Item'}
                            </p>
                            <span className='px-4'>|</span>
                            <button
                                className='button link'
                                onClick={toggleAllSelection}
                            >
                                {selectedIds.length ===
                                scrollRef.current.totalElements
                                    ? 'Unselect'
                                    : 'Select'}{' '}
                                all
                            </button>
                        </div>
                        {isOrderDisable && (
                            <div className='warning-info'>
                                <p className='regular-16 red flex items-center'>
                                    <WarningIcon className='mr-1' /> You can
                                    place order only for approved styles.
                                </p>
                            </div>
                        )}
                        <div className='sm:text-start text-center mt-[30px] sm:mt-0 flex flex-wrap gap-3 xl:gap-4 justify-center'>
                            <button
                                className='button primary round'
                                disabled={isOrderDisable}
                                onClick={() => setIsPlaceOrder(true)}
                            >
                                Place order
                            </button>
                            <button
                                className='button button-fav primary round'
                                onClick={() => setIsQuoteShow(true)}
                            >
                                Quote
                            </button>
                            <button
                                className='button button-fav primary round'
                                onClick={() => setIsShowSample(true)}
                            >
                                Sample
                            </button>
                            <button
                                className='button button-fav secondary p-1 round'
                                onClick={() => onDownLoad()}
                            >
                                Summary Sheet
                                <span className='ml-2'>
                                    <DownloadIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {id && id !== 'my-favourite' && (
                <button
                    onClick={() => setOpenFolderConversation(true)}
                    className='button primary w-[55px] h-[55px] flex items-center justify-center icon-only fixed right-6 bottom-16 xl:bottom-8 !rounded-full'
                >
                    <ChatIcon />
                </button>
            )}

            {isShowSample && (
                <RequestForSample
                    defaultTitle={folderInfo?.name || ''}
                    type='SAMPLE_REQUEST_FROM_FAVOURITE'
                    selectedProductList={selectedIds}
                    onCLose={() => {
                        setIsShowSample(false)
                    }}
                    onSubmitSample={() => {}}
                />
            )}
            {isQuoteShow && (
                <RequestForQuotes
                    defaultTitle={folderInfo?.name || ''}
                    selectedProductList={selectedIds}
                    onCLose={() => {
                        setIsQuoteShow(false)
                    }}
                    onSubmitSample={() => {}}
                    type={'QUOTE_REQUEST_FROM_FAVOURITE_FOLDER'}
                />
            )}
            {isShowPresentation && (
                <PresentationViewModal
                    onClose={() => setIsShowPresentation(false)}
                    pageSource='FAVOURITE'
                />
            )}

            {isPlaceOrder && (
                <PlaceOrder
                    details={{
                        quote: {
                            title: folderInfo?.name,
                            fromFav: true,
                            folderId: id
                        }
                    }}
                    handleClose={() => {
                        setSelectedIds([])
                        setIsPlaceOrder(false)
                    }}
                    selectedProducts={selectedIds}
                    collectionId={folderInfo?.collectionId}
                    onSuccess={(data) => onPlaceOrderSuccess(data)}
                />
            )}

            {openConversationId && (
                <ConversationRightSidePanel
                    type='PRODUCT_DEVELOPMENT_COMMENT'
                    id={openConversationId}
                    openModal={openConversationId}
                    onGoBack={() => setOpenConversationId(null)}
                    onCloseModal={() => {
                        setOpenConversationId(null)
                        onCloseModal()
                    }}
                    memberList={members()}
                />
            )}

            {openFolderConversation && (
                <ConversationRightSidePanel
                    type='COLLECTION_COMMENT'
                    conversationsType='COLLECTION_CONVERSATION'
                    id={folderInfo?.collectionId}
                    openModal={openFolderConversation}
                    onGoBack={() => setOpenFolderConversation(false)}
                    onCloseModal={() => {
                        setOpenFolderConversation(false)
                    }}
                />
            )}
        </>
    )
}

export default FavouriteDetails
