import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import tnaService from '../../../services/Tna/tnaService'
import {
    _getKey,
    changeDateFormat,
    getShortName,
    getUserInfo,
    validateNumber
} from '../../../helpers/Util'
import SampleReply from '../../../common/comments/SampleReply'
import moment from 'moment'
import useFetch from '../../../hooks/useFetch'
import AssignMemberToOrderStage from '../../../common/AssignMemberToOrderStage'
import NewPostSection from '../../../common/comments/NewPostSection'
import { OrderContext } from '../../../useContext/OrderContext'
import LoaderComponent from '../../../common/LoaderComponent'
import { toast } from 'react-toastify'
import {
    ConversationActionTypes,
    getNewMessage
} from '../../../redux_toolkit/Conversation'
import {
    CLEAR_ALL_CHATS,
    SET_CURRENT_ACTIVE_PAGE
} from '../../../redux_toolkit/@types/action.types'

const OrderTask = ({ callback }) => {
    const dispatch = useDispatch()
    const {
        deliverableId,
        orderId,
        closeModal,
        reviseInfo,
        brandName,
        resetRevisionInfo
    } = useContext(OrderContext)
    const { responseData: memberData } = useFetch('getProjectMembers', orderId)
    const [deliverableDetails, setDeliverableDetails] = useState(null)
    const [orderMemberList, setOrderMemberList] = useState([])
    const [isShowInputTarget, setIsShowInputTarget] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasNext, setHasNext] = useState(true)
    const [inputPoQuantity, setInputPoQuantity] = useState(null)
    const [editSubTaskId, setEditSubTaskId] = useState(null)
    const [editSubTaskDateId, setEditSubTaskDateId] = useState(null)
    const [isAllCommentsOn, setIsAllCommentsOn] = useState(false)
    const [newlyAddedSubTaskList, setNewlyAddedSubtaskList] = useState([])
    const [page, setPage] = useState(0)
    const [commentsData, setCommentsData] = useState([])
    const [reviseDetails, setReviseDetails] = useState(reviseInfo)
    const generalTaskList = deliverableDetails?.taskList
    const materialTaskList = deliverableDetails?.materialTaskList
    const stepProduct = deliverableDetails?.stepProduct
    const STEP_TYPE = deliverableDetails?.stageResponse?.stageConstants
    const [userInfo, setUserInfo] = useState(null)
    const isNewPost = getNewMessage()
    let { id } = useParams()
    const timelineStore = useSelector((store) => store.timeline)?.orderInfo

    const scrollDownInChatBody = () => {
        let container = document.getElementById('taskPostScroll')
        let o1 = container?.scrollHeight - container?.clientHeight
        let elem = document.getElementById('taskPostScroll')
        elem.scrollTop = o1
    }

    const onSetDeliverableDetails = (deliverable) => {
        setDeliverableDetails(deliverable)
    }

    const onGetDeliverableDetails = async () => {
        setIsLoading(true)
        const data = await tnaService.onGetDeliverableDetails(deliverableId)
        setDeliverableDetails(data)
        setIsLoading(false)
    }

    useEffect(() => {
        onGetDeliverableDetails()
    }, [deliverableId])

    useEffect(() => {
        if (deliverableDetails?.id) {
            onGetCommentsData(0)
        }
    }, [deliverableDetails?.id, isAllCommentsOn])

    useEffect(() => {
        dispatch(
            dispatch({
                type: ConversationActionTypes[SET_CURRENT_ACTIVE_PAGE],
                payload: {
                    id: deliverableId,
                    type: 'TASK_CONVERSATION',
                    pageType: 'DETAILS'
                }
            })
        )
        setUserInfo(getUserInfo())
        return () => {
            dispatch({
                type: ConversationActionTypes[SET_CURRENT_ACTIVE_PAGE],
                payload: { id: null, type: '', pageType: 'DETAILS' }
            })
        }
    }, [deliverableId])

    useEffect(() => {
        onSetDeliverableDetails(deliverableDetails)
        setOrderMemberList(memberData?.memberList)
    }, [deliverableDetails, memberData])

    useEffect(() => {
        scrollDownInChatBody()
        const postResponse = isNewPost?.content
            ? JSON.parse(JSON.stringify(isNewPost.content))
            : {}
        if (
            postResponse?.text &&
            getUserInfo()?.id !== postResponse?.postedBy.id
        ) {
            const oldPosts = [...commentsData]
            if (
                postResponse?.text &&
                getUserInfo()?.id !== postResponse?.postedBy.id
            ) {
                if (postResponse?.parentPostId) {
                    const index = oldPosts.findIndex(
                        (post) => post.id === postResponse?.parentPostId
                    )
                    oldPosts[index].commentList.push(postResponse)
                } else {
                    oldPosts.push(postResponse)
                }
                setCommentsData([...oldPosts])
                scrollDownInChatBody()
                toast.success('New message received')
                setTimeout(() => {
                    dispatch({
                        type: ConversationActionTypes[CLEAR_ALL_CHATS]
                    })
                }, 1000)
            }
        }
    }, [isNewPost?.content?.id])

    const onGetCommentsData = async (pageNumber) => {
        setIsLoading(true)
        const paramsData = {
            orderId: deliverableDetails?.orderId,
            page: pageNumber,
            ProductId: deliverableDetails?.stepProduct?.id
        }
        if (!isAllCommentsOn) {
            paramsData.deliverableId = deliverableId
        }
        const allComments = await tnaService.fetchPostComments(paramsData)
        allComments.reverse()
        setHasNext(allComments?.length === 10)
        if (pageNumber === 0) {
            setCommentsData(allComments)
            scrollDownInChatBody()
        } else {
            setCommentsData([...allComments, ...commentsData])
            setPage((page) => page + 1)
            let elem = document.getElementById('taskPostScroll')
            elem.scrollTop = 555
        }
        setIsLoading(false)
    }

    const onScrollComments = () => {
        const wrappedElement = document.getElementById('taskPostScroll')
        if (wrappedElement.scrollTop === 0) {
            if (hasNext) {
                onGetCommentsData(page + 1)
            }
        }
    }

    const onUpdateQuantity = async (id) => {
        setIsLoading(true)
        const data = await tnaService.onSubmitPoQuantity(id, inputPoQuantity)
        if (data?.success) {
            toast.success(data?.message)
            setInputPoQuantity(null)
            setIsShowInputTarget(false)
            setDeliverableDetails(data?.payload)
            callback()
        }
        setIsLoading(false)
    }

    const handleSubtaskChange = (i, e) => {
        let newSubTask = [...newlyAddedSubTaskList]
        newSubTask[i][e.target.name] = e.target.value
        setNewlyAddedSubtaskList(newSubTask)
    }

    const removeFormFields = (i) => {
        let subTaskformValues = [...newlyAddedSubTaskList]
        subTaskformValues.splice(i, 1)
        setNewlyAddedSubtaskList([...subTaskformValues])
    }

    const postSubtask = async ({ date, subTask }) => {
        setIsLoading(true)
        const body = {
            endDate: date,
            id: deliverableId,
            name: subTask,
            status: 'PENDING'
        }
        const subTaskCreateresponse = await tnaService.subtaskCreate(
            deliverableId,
            body
        )
        if (subTaskCreateresponse?.success) {
            toast.success(subTaskCreateresponse?.message)
            setDeliverableDetails({
                ...deliverableDetails,
                subTaskList: subTaskCreateresponse.payload.subTaskList
            })
        }
        setIsLoading(false)
    }

    const handleSubtaskComplete = (i) => {
        let subTaskformValues = [...newlyAddedSubTaskList]
        subTaskformValues[i].isActive = true
        setNewlyAddedSubtaskList([...subTaskformValues])
        postSubtask(subTaskformValues[i])
    }

    const onApproveTask = async (taskId) => {
        setIsLoading(true)
        const response = await tnaService.onApproveTask(taskId)
        if (response?.success) {
            setDeliverableDetails(response?.payload?.deliverableDetails)
        }
        setIsLoading(false)
    }

    const onAddNewPost = (newPost) => {
        let prevData = [...commentsData]
        prevData.push(newPost)
        setCommentsData([...prevData])
    }

    const addNewReply = (payload) => {
        let prevData = [...commentsData]
        for (let item of prevData) {
            if (parseInt(item.id) === parseInt(payload.parentPostId)) {
                let cloneArray = [...item.commentList]
                cloneArray.push(payload.postResponse)
                item.commentList = cloneArray
            }
        }
        setCommentsData([...prevData])
    }
    const renderTopHeader = () => (
        <>
            <div className='taskname'>
                <h3 className='text-base sm:text-xl black weight-700 py-4 sm:py-6 px-4 sm:px-8'>
                    {deliverableDetails?.name}
                </h3>
            </div>
            <div className='haeder-task-title flex'>
                <div className='header-photo mr-1'>
                    <img
                        src={
                            stepProduct?.image || '/images/default_product.svg'
                        }
                        alt='product'
                    />
                </div>
                <div className='header-info ml-2'>
                    <div className='d-flex flex-wrap sm:flex-nowrap'>
                        <ul className='w-full sm:w-auto'>
                            <li>
                                <span className='regular-14 weight-500 blue'>
                                    {stepProduct?.referenceNumber}
                                </span>
                            </li>
                            <li className='regular-14 gray_300'>
                                {stepProduct?.market}
                            </li>
                            <li className='regular-14 gray_300'>
                                {stepProduct?.subCategory}
                            </li>
                            <li className='regular-14 gray_300'>
                                Price:{' '}
                                <span className='weight-500 black'>
                                    ${timelineStore?.orderValue}
                                </span>
                            </li>
                        </ul>
                        <div className='ml-0 sm:ml-8 mt-2 sm:mt-1'>
                            <p className='regular-14 gray_300'>
                                {timelineStore?.orderQuantity} Units
                            </p>
                            <ul className='common-list-component'>
                                <li>PO: {deliverableDetails?.poNumber}</li>
                                <li className='regular-14 gray_300'>
                                    ETD{' '}
                                    {changeDateFormat(
                                        timelineStore?.deliveryDateList[0],
                                        'YYYY-MM-DD',
                                        'MMM DD'
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    const renderInputTargetForm = (task) => {
        let title =
            Math.sign(task?.extraProductionTargetPercentage) === -1
                ? 'PO'
                : 'PO+'
        return (
            <div className='production-details'>
                <button className='production-details-btn'>
                    <span className='input-qty'>Input target qty</span>{' '}
                    <img
                        src='/icons/down-arrow-gray-dark-02.svg'
                        alt='down arrow'
                        onClick={() => setIsShowInputTarget(true)}
                    />
                </button>
                {isShowInputTarget && (
                    <div className='po-qty-popup shadow-lg'>
                        <p className='regular-12 gray_400 mb-2'>
                            <span className='weight-500'>PO QTY:</span>{' '}
                            <span className='gray_800 mb-1'>
                                {task?.poQuantity}
                            </span>
                            &nbsp; ({title}
                            {parseFloat(
                                task?.extraProductionTargetPercentage
                            ).toFixed(2)}
                            %)
                        </p>
                        <p className='regular-14 gray_400 mb-1'>
                            Cutting target qty
                        </p>
                        <input
                            type='number'
                            min={1}
                            name='quantity'
                            placeholder={'00'}
                            value={inputPoQuantity}
                            onChange={(e) => setInputPoQuantity(e.target.value)}
                            onKeyPress={validateNumber}
                        />
                        <div className='text-right mt-3'>
                            <button
                                className='button text-only gray_400 mr-2'
                                onClick={() => setIsShowInputTarget(false)}
                            >
                                CANCEL
                            </button>
                            <button
                                className='button text-only gray_800 weight-500'
                                onClick={() => onUpdateQuantity(task.id)}
                            >
                                UPDATE
                            </button>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const renderStatusIcon = (task) => {
        if (
            task?.formattedTaskStatus === 'EXPIRED' ||
            task?.formattedTaskStatus === 'PENDING' ||
            task?.formattedTaskStatus === 'RUNNING'
        )
            return (
                <>
                    <img
                        src={
                            task.isBuyerApprovalNeeded
                                ? '/icons/due-green.svg'
                                : '/icons/due-gray.svg'
                        }
                        alt='due'
                        className={
                            task.isBuyerApprovalNeeded
                                ? 'state enable-hover'
                                : 'state disable-hover'
                        }
                        onClick={() => {
                            if (task.isBuyerApprovalNeeded) {
                                onApproveTask(task.id)
                            }
                        }}
                    />
                    {task?.stepType === 'REVIEW' ? (
                        <img
                            onClick={() => {
                                if (task.isBuyerApprovalNeeded) {
                                    setReviseDetails({
                                        id: task.id,
                                        stepName: task.stepName
                                    })
                                }
                            }}
                            className='state'
                            src='/icons/Update-red.svg'
                            alt='update'
                        />
                    ) : null}
                </>
            )
        else
            return (
                <img
                    src='/icons/Completed-icon.svg'
                    alt='success'
                    className='state'
                />
            )
    }

    const renderInputTargetItem = (task) => {
        if (task?.productionTarget) {
            return (
                <div className='production-details ml-2'>
                    <div className='production-details-btn'>
                        <span className='blue_800'>
                            {task?.actualProductionQuantity || 0}
                        </span>
                        /{task?.poQuantity}
                        {/*<span className='percentage'>*/}
                        {/*    (PO+*/}
                        {/*    {parseFloat(*/}
                        {/*        task.extraProductionTargetPercentage*/}
                        {/*    ).toFixed(2)}*/}
                        {/*    %)*/}
                        {/*</span>*/}
                        <span className='devider'>|</span> Bal:
                        <span className='gray_800'>
                            {parseInt(task?.poQuantity) -
                                parseInt(task?.actualProductionQuantity || 0)}
                        </span>
                    </div>
                </div>
            )
        }
        // else {
        //     return renderInputTargetForm(task)
        // }
    }

    const renderStepName = (name) => (
        <Tooltip title={name} placement='top-start' arrow>
            <span className='cursor-pointer gray_300'>
                {' '}
                {getShortName(name, 30)}
            </span>
        </Tooltip>
    )

    const renderTaskRow = (task) => {
        return (
            <div key={task?.id} className='single-task'>
                <div className='flex justify-between items-center'>
                    <p>
                        {renderStatusIcon(task)}
                        {generalTaskList && generalTaskList?.length === 1
                            ? renderStepName(task?.stepDescription)
                            : renderStepName(task?.stepName)}

                        {(task?.formattedTaskStatus === 'COMPLETED' ||
                            task?.formattedTaskStatus === 'APPROVED' ||
                            task?.formattedTaskStatus === 'LATE_APPROVED') && (
                            <>
                                <span className='green_500 state ml-2 mr-1'>
                                    <img
                                        src='/icons/tick-green.png'
                                        className='mr-1'
                                    />
                                    {task?.actualEndDate &&
                                        moment(task?.actualEndDate).format(
                                            'D MMM, YY'
                                        )}
                                </span>
                                {task?.dateOver > 0 && (
                                    <span className='red date-count state mr-1'>
                                        +{task?.dateOver}
                                    </span>
                                )}
                            </>
                        )}

                        {task?.formattedTaskStatus === 'EXPIRED' && (
                            <>
                                <span className='status unsafe state ml-2 mr-1'>
                                    <img
                                        src='/icons/alert-octagon-white.svg'
                                        alt='update'
                                    />{' '}
                                    +{task?.dateOver}
                                </span>
                            </>
                        )}

                        {task?.revisionCount && (
                            <span className='status red-alert ml-1 mr-1'>
                                <img
                                    src='/icons/update-white.svg'
                                    alt='update'
                                />
                                {task?.revisionCount}
                            </span>
                        )}
                    </p>

                    <AssignMemberToOrderStage
                        memberList={orderMemberList}
                        taskInfo={task}
                        onAddOrDeleteSuccess={() => callback()}
                    />
                    <span>
                        <p className='whitespace-nowrap date-details'>
                            <span>
                                {changeDateFormat(
                                    task?.startDate,
                                    'YYYY-MM-DD',
                                    'DD MMM, YY'
                                )}{' '}
                                -{' '}
                            </span>
                            <span>
                                {changeDateFormat(
                                    task?.endDate,
                                    'YYYY-MM-DD',
                                    'DD MMM, YY'
                                )}
                            </span>
                        </p>
                    </span>
                </div>
                {STEP_TYPE === 'PRODUCTION' &&
                deliverableDetails?.name === 'Cutting'
                    ? renderInputTargetItem(task)
                    : null}
            </div>
        )
    }

    const renderAddSubTask = () => <>{renderSubTaskList()}</>

    const renderGeneeralTaskList = () => (
        <>
            {generalTaskList.map((item) => renderTaskRow(item))}
            {renderAddSubTask()}
            {deliverableDetails?.taskList[0]?.quantityResponseList?.length >
                0 && (
                <p className='text-sm ml-2 mt-3'>
                    Date wise {deliverableDetails?.taskList[0]?.stepName}{' '}
                    quantity
                </p>
            )}

            {STEP_TYPE === 'PRODUCTION' && renderQuantityResponseList()}
        </>
    )

    const renderMaterialTaskList = () => (
        <>
            {materialTaskList?.map((item) => {
                return (
                    <>
                        <Tooltip
                            title={item?.stepMaterial?.materialName}
                            placement='top-start'
                            arrow
                        >
                            <p className='semibold-12 task-title'>
                                {getShortName(
                                    item?.stepMaterial?.materialName,
                                    70
                                )}
                            </p>
                        </Tooltip>

                        {item?.stepResponseList?.map((step, idx) =>
                            renderTaskRow(step)
                        )}
                    </>
                )
            })}
            {renderAddSubTask()}
            {STEP_TYPE === 'PRODUCTION' && renderQuantityResponseList()}
        </>
    )

    const renderQuantityResponseList = () => {
        return (
            <>
                <div className='added-qty-chips'>
                    {deliverableDetails?.taskList[0]?.quantityResponseList?.map(
                        (item) => (
                            <div className='qty-chip' key={item.id}>
                                <span className='gray_800'>
                                    {item?.quantity}
                                </span>
                                <span className='devider'>|</span>
                                <span className='gray_400'>
                                    {changeDateFormat(
                                        item?.productionDate,
                                        'YYYY-MM-DD',
                                        'MMM-DD'
                                    )}
                                </span>
                            </div>
                        )
                    )}
                </div>
            </>
        )
    }

    const renderSubTaskList = () => (
        <div className='added-task-list scroll-y-label'>
            {deliverableDetails?.subTaskList?.map((subTask, index) => {
                return (
                    <div key={subTask?.id} className='added-single-task'>
                        <div className='task-name flex items-center w-75'>
                            {subTask.status === 'PENDING' && (
                                <img src='/icons/due-green.svg' />
                            )}

                            {subTask.status !== 'PENDING' && (
                                <img src='/icons/Completed-icon.svg' />
                            )}
                            {(!editSubTaskId ||
                                editSubTaskId !== subTask?.id) && (
                                <span className='semibold-12 d-block w-100'>
                                    {subTask?.name}
                                </span>
                            )}
                        </div>
                        {(!editSubTaskDateId ||
                            editSubTaskDateId !== subTask.id) && (
                            <div
                                className='date w-25 text-right'
                                // onClick={() => setEditSubTaskDateId(subTask.id)}
                            >
                                {moment(subTask.endDate).format('D MMM YY')}
                            </div>
                        )}
                    </div>
                )
            })}

            {newlyAddedSubTaskList?.map((subtask, index) => {
                return !subtask.isActive ? (
                    <div key={index} className='added-single-task new'>
                        <img
                            src='/icons/close.svg'
                            alt='delete'
                            className='close-subtask cursor-pointer'
                            onClick={() => removeFormFields(index)}
                        />
                        <div className='task-name flex items-center'>
                            {!subtask.isActive && (
                                <img src='/icons/due-green.svg' />
                            )}
                            {subtask.isActive && (
                                <img src='/icons/delivered-round.svg' />
                            )}
                            <input
                                type='text'
                                name='subTask'
                                value={subtask.subTask}
                                autoFocus
                                placeholder='Write task name'
                                onChange={(e) => handleSubtaskChange(index, e)}
                                onKeyPress={(event) => {
                                    if (
                                        event.key === 'Enter' &&
                                        subtask.subTask.trim()
                                    )
                                        handleSubtaskComplete(index)
                                }}
                            />
                        </div>
                        <div className='date'>
                            <input
                                type='date'
                                name='date'
                                min={deliverableDetails?.minTaskStartDate}
                                max={deliverableDetails?.maxTaskEndDate}
                                value={subtask.date}
                                onChange={(e) => handleSubtaskChange(index, e)}
                            />
                        </div>
                    </div>
                ) : null
            })}
        </div>
    )

    return (
        <LoaderComponent loading={isLoading}>
            <div role='document'>
                <div>
                    <div className='modal-header'>
                        {renderTopHeader()}
                        {generalTaskList ? (
                            <div>
                                <div className='general-tasks'>
                                    <div className='task-group'>
                                        {renderGeneeralTaskList()}
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={closeModal}
                        >
                            <span aria-hidden='true'>
                                <svg
                                    width={14}
                                    height={14}
                                    viewBox='0 0 14 14'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
                                        fill='#222222'
                                        fillOpacity='0.87'
                                    />
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div
                        className={
                            STEP_TYPE === 'MATERIALS'
                                ? 'modal-body custom-scroll material-tasks'
                                : 'modal-body custom-scroll other-tasks'
                        }
                        id='taskPostScroll'
                        onScroll={() => onScrollComments()}
                    >
                        {materialTaskList ? (
                            <div>
                                <div className='general-tasks'>
                                    <div className='task-group'>
                                        {renderMaterialTaskList()}
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className='task-conversation-container'>
                            {commentsData?.map((post, i) => {
                                return (
                                    <div key={`sample_${_getKey()}`}>
                                        <SampleReply
                                            post={post}
                                            orderMemberList={orderMemberList?.map(
                                                (item) => {
                                                    return {
                                                        email: item.email,
                                                        name: item.memberName
                                                    }
                                                }
                                            )}
                                            userInfo={userInfo}
                                            commentSubmitUrl={'commentOnTask'}
                                            commentSubmitparam={
                                                '?fromTimeline=false'
                                            }
                                            commentFrom='ORDER'
                                            addNewReply={addNewReply}
                                        />
                                    </div>
                                )
                            })}
                            {commentsData.length === 0 && !isLoading && (
                                <div className='no-comments'>
                                    No comment found
                                </div>
                            )}
                        </div>
                    </div>
                    <NewPostSection
                        onSetDeliverableDetails={onSetDeliverableDetails}
                        onAddNewPost={onAddNewPost}
                        orderMemberList={orderMemberList}
                        userInfo={userInfo}
                        deliverableId={deliverableId}
                        orderId={orderId}
                        closeModal={closeModal}
                        reviseInfo={reviseDetails}
                        brandName={brandName}
                        onGetCommentsData={onGetCommentsData}
                        onResetRevise={() => {
                            setReviseDetails(null)
                            callback()
                        }}
                        resetRevisionInfo={resetRevisionInfo}
                    />
                </div>
            </div>
        </LoaderComponent>
    )
}

export default OrderTask
