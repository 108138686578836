import { createSlice } from '@reduxjs/toolkit'
import { ConversationReducers } from './ConversationReducers'
import { useSelector } from 'react-redux'

const initialState = {
    currentPage: { id: null, type: '', pageType: 'DETAILS' },
    newMessage: {}
}

const conversationSlice = createSlice({
    name: 'conversations',
    initialState,
    reducers: ConversationReducers
})

export const ConversationActionTypes = conversationSlice.actions
export const getCurrentPage = () =>
    useSelector((state) => state.conversations.currentPage)
export const getNewMessage = () =>
    useSelector((state) => state.conversations.newMessage)
export default conversationSlice.reducer
