import React from 'react'
import CollectionTags from '../../common/CollectionTags'
import { ReactComponent as FavouriteIcon } from '../../../../assets/icons/favorite.svg'
import { ReactComponent as FavouritedIcon } from '../../../../assets/icons/favorited.svg'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../../redux_toolkit/Loader'
import SharedCollectionThunk from '../../../redux_toolkit/V3/SharedCollection/SharedCollectionThunk'
import {
    CLEAR_FILTER_FIELDS,
    TOGGLE_FAVOURITE_COLLECTION
} from '../../../redux_toolkit/@types/action.types'
import { FilterActionTypes } from '../../../redux_toolkit/V3/Filter'
import { getBreadCrumbs, setBreadCrumbRoutes } from '../../../helpers/Util'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FavouriteThunks } from '../../../redux_toolkit/Favourite/FavouriteThunks'
import { GET_FAVOURITE_COUNT } from '../../../redux_toolkit/@types/thunk.types'

const TwoItemCollection = ({ collections }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const toggleFavourite = async (event, bool, collectionId) => {
        event.stopPropagation()
        try {
            dispatch(openLoader())
            await dispatch(
                SharedCollectionThunk[TOGGLE_FAVOURITE_COLLECTION](
                    bool,
                    collectionId
                )
            )
            if (bool) await dispatch(FavouriteThunks[GET_FAVOURITE_COUNT]())
            dispatch(closeLoader())
        } catch (e) {
            dispatch(closeLoader())
        }
    }

    const handleRoute = (collection) => {
        dispatch(FilterActionTypes[CLEAR_FILTER_FIELDS]())
        let newRoute = `/collections/${collection.id}`
        let visitingRoutes = getBreadCrumbs()
        visitingRoutes.push({
            key: newRoute,
            value: collection.name
        })

        setBreadCrumbRoutes(visitingRoutes)
        navigate(newRoute)
    }

    const renderCollections = () => {
        return collections?.map((item) => {
            return (
                <div
                    key={`collection_card_two_${item.id}`}
                    className={`collection-card ${
                        item?.coverDocumentResponse?.docUrl ? 'cover-photo' : ''
                    }`}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleRoute(item)
                    }}
                >
                    <div className='collection-status'>
                        <CollectionTags item={item} />
                    </div>
                    <div className='product-img-container'>
                        {!item?.coverDocumentResponse?.docUrl && (
                            <>
                                <div className='prev-img'>
                                    {item?.documentResponseList?.[0] && (
                                        <img
                                            src={
                                                item?.documentResponseList?.[0]
                                                    ?.docUrl
                                            }
                                            alt='style'
                                        />
                                    )}
                                </div>
                                <div className='prev-img-thumb'>
                                    <div className='single-first'>
                                        {item?.documentResponseList?.[1] && (
                                            <img
                                                src={
                                                    item
                                                        ?.documentResponseList?.[1]
                                                        ?.docUrl
                                                }
                                                alt='style'
                                            />
                                        )}
                                    </div>
                                    <div className='single-second'>
                                        {item?.documentResponseList?.[2] && (
                                            <img
                                                src={
                                                    item
                                                        ?.documentResponseList?.[2]
                                                        ?.docUrl
                                                }
                                                alt='style'
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {item?.coverDocumentResponse?.docUrl && (
                            <div className='prev-img'>
                                <img
                                    src={item?.coverDocumentResponse?.docUrl}
                                    alt='style'
                                />
                            </div>
                        )}
                    </div>
                    <div className='collection-content d-flex justify-between items-center'>
                        <div>
                            <h3 className='collection-title'>{item.name}</h3>
                            <div className='details-info'>
                                <ul className='common-list-component'>
                                    {item?.season && <li>{item?.season}</li>}
                                    {item?.numOfDesign && (
                                        <li>
                                            {item.numOfDesign}{' '}
                                            {item.numOfDesign > 1
                                                ? 'Styles'
                                                : 'Style'}
                                        </li>
                                    )}
                                    {item?.ownerName && (
                                        <li>{item.ownerName}</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <button
                            className='button secondary icon-only bg-white'
                            onClick={(e) =>
                                toggleFavourite(e, !item.isFavorite, item.id)
                            }
                        >
                            {!item.isFavorite && <FavouriteIcon />}
                            {item?.isFavorite && <FavouritedIcon />}
                        </button>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className='collection-two-row-item mb-6 4xl:mb-10'>
            {renderCollections()}
        </div>
    )
}

export default TwoItemCollection
