import React, { useEffect, useRef } from 'react'

const CustomDropdown = ({
    children,
    label,
    icon,
    selectedOption,
    isShowOptions = false,
    setIsShowOptions
}) => {
    const dropdownRef = useRef()

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsShowOptions(false)
        }
    }

    return (
        <div className='common-dropdown' ref={dropdownRef}>
            <button
                className='button secondary d-flex items-center round'
                onClick={() => setIsShowOptions((prev) => !prev)}
            >
                <p className='regular-14 '>
                    {selectedOption ? selectedOption : label}
                </p>
                {icon}
            </button>
            <div
                className={`dropdown-box dropdown-menu scroll-y-label ${
                    !isShowOptions ? 'hidden' : ''
                }`}
            >
                {children}
            </div>
        </div>
    )
}

export default CustomDropdown
