import React from 'react'
import { ReactComponent as CloseIcon } from '../../../icons/close.svg'
import { ReactComponent as ArrowDownIcon } from '../../../icons/arrow-down.svg'
import { ReactComponent as SearchIcon } from '../../../icons/search.svg'

const AddToFavoritePopup = () => {
    return (
        <div className='added-to-favorite-popup shadow-medium p-4 bg-white'>
            <div className='d-flex justify-between items-center'>
                <h3 className='regular-18 weight-500'>Added to my favorites</h3>
                <div className='close-btn cursor-pointer'>
                    <CloseIcon />
                </div>
            </div>
            <div className='d-flex items-center py-4'>
                <img
                    src='/images/single-design.png'
                    alt=''
                    className='style-image w-[80px]'
                />
                <div className='ml-4'>
                    <p className='regular-16 weight-500'>MJY230463</p>
                    <ul className='common-list-component mt-1'>
                        <li>TEE</li>
                        <li>Woven</li>
                    </ul>
                </div>
            </div>
            <button className='button secondary w-full'>
                <span className='mr-2'>My Favorite</span>
                <ArrowDownIcon />
            </button>

            <div className='serach-folder mt-2'>
                <div className='dropdown-box dropdown-menu scroll-y-label bg-white'>
                    <div className='search-box p-3 bg-white'>
                        <div className='relative'>
                            <div className='absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none'>
                                <SearchIcon />
                            </div>
                            <input
                                type='search'
                                className='block w-full p-3 pr-10 text-sm'
                                placeholder='Search'
                            />
                        </div>
                    </div>
                    <div className='list-items scroll-y-label'>
                        <div className='single-item'>
                            <p>Item 1</p>
                        </div>
                        <div className='single-item'>
                            <p>Item 2</p>
                        </div>
                        <div className='single-item'>
                            <p>Item 3</p>
                        </div>
                        <div className='single-item'>
                            <p>Item 4</p>
                        </div>
                        <div className='single-item'>
                            <p>Item 5</p>
                        </div>
                    </div>
                </div>
                <button className='button link mt-2'>Create new folder</button>
            </div>
            <div className='create-new-folder'>
                <div className='form-group mt-2'>
                    <label className='regular-14 gray_300 mb-2'>
                        Create new folder
                    </label>

                    <input type='text' name='' placeholder='Title' />
                </div>
                <button className='button primary mr-3'>Create</button>
                <button className='button secondary'>Cancel</button>
            </div>
        </div>
    )
}

export default AddToFavoritePopup
