import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux'
import { sampleReducer } from './SampleReducer';

const initialState = {
    activeTab:'',
    samples: {},
    sample:{},
    selectedProducts: []
}
const sampleSlice = createSlice({
    name: 'sample',
    initialState,
    reducers: sampleReducer
})

export const SampleActionsType = sampleSlice.actions

export const useSampleSelector = () => useSelector((state) => state.sample)

export default sampleSlice.reducer
