import React, { useEffect, useState } from 'react'
import { ReactComponent as Three60Icon } from '../../../assets/icons/360-degree.svg'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../../redux_toolkit/Loader'
import Http from '../../helpers/Http'
import { toast } from 'react-toastify'
import {
    changeDateFormat,
    dateCompare,
    getDateDifference,
    getNumberUnit,
    onGetLocalFromUtc,
    renderCertificateBadge,
    supplierCardRenderSequence
} from '../../helpers/Util'
import BreadCrumb from '../../v3/common/BreadCrumb'
import moment from 'moment'

const chartToNum = {
    A: 1,
    B: 2,
    C: 3,
    D: 3
}

const SupplierProfile = () => {
    const [supplierInfo, setSupplierInfo] = useState()
    const [supplierCertificates, setSupplierCertificates] = useState([])
    const [supplierDocs, setSupplierDocs] = useState([])
    const [tags, setTags] = useState([])
    const { id } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(openLoader())
        Http.GET_WITH_ID_PARAM('fetchSupplierInfo', id)
            .then(({ data }) => {
                setSupplierInfo(data)
                const tmpTags = []
                const factoryInfo = data?.factoryInfo
                const strengthList = data?.strengthList || []
                if (factoryInfo) {
                    if (factoryInfo.haveEtp) tmpTags.push('ETP')
                    if (factoryInfo.haveLab) tmpTags.push('Lab')
                    if (factoryInfo.haveRnd) tmpTags.push('R&D')
                    if (factoryInfo.haveSample) tmpTags.push('Sample')
                    if (factoryInfo.haveStudio) tmpTags.push('Studio')
                    if (factoryInfo.haveWtp) tmpTags.push('WTP')
                    if (factoryInfo.isComposite) tmpTags.push('Composite')
                }
                const strengthTags = strengthList.map((item) => item.text)
                setTags([...tmpTags, ...strengthTags])
            })
            .catch((error) => {
                dispatch(closeLoader())
                toast.error(error.response.data.message)
            })
        Http.GET_WITH_ID_PARAM('fetchSupplierDocs', id)
            .then((response) => {
                setSupplierDocs(response.data)
            })
            .catch((error) => {
                dispatch(closeLoader())
                toast.error(error.response.data.message)
            })
        Http.GET_WITH_ID_PARAM(
            'fetchSupplierCertificates',
            `${id}/certificates`
        )
            .then((response) => {
                setSupplierCertificates(response.data)
                dispatch(closeLoader())
            })
            .catch((error) => {
                dispatch(closeLoader())
                toast.error(error.response.data.message)
            })
    }, [id])

    const getCapacity = (total, unit) => {
        return (
            <h3 className='text-base md:text-xl font-bold'>
                {total} {unit}
                <span className='gray_300'>/Month</span>
            </h3>
        )
    }

    const gallaryClassName = {
        A: 'single-item mb-2 sm:mb-4',
        B: 'two-in-row mb-2 sm:mb-4',
        C: 'three-in-row right-aligned mb-2 sm:mb-4',
        D: 'three-in-row left-aligned mb-2 sm:mb-4'
    }

    const gallaryCardClassName = (idx, type) => {
        if (type === 'SINGLE') return 'gallery-item'
        else if (type === 'DOUBLE') return 'gallery-item one-half'
        else if (type === 'TRIPLE_C') {
            if (idx === 0) return 'gallery-item one-half'
            else return 'gallery-item one-third'
        } else if (type === 'TRIPLE_D') {
            if (idx === 2) return 'gallery-item one-half'
            else return 'gallery-item one-third'
        }
    }

    const renderGalleryItem = (doc, idx, type) => (
        <div className={`${gallaryCardClassName(idx, type)}`} key={doc.id}>
            <img src={doc?.docUrl} alt={doc?.name} />
            <h3 className='gallery-title text-sm md:text-xl font-bold text-white'>
                {doc?.note}
            </h3>
        </div>
    )

    const getImageCard = (galleryItems, cardItemCount) => {
        if (cardItemCount === 'A') {
            {
                return galleryItems?.map((doc, idx) =>
                    renderGalleryItem(doc, idx, 'SINGLE')
                )
            }
        } else if (cardItemCount === 'B') {
            {
                return galleryItems?.map((doc, idx) =>
                    renderGalleryItem(doc, idx, 'DOUBLE')
                )
            }
        } else if (cardItemCount === 'C') {
            {
                return galleryItems?.map((doc, idx) =>
                    renderGalleryItem(doc, idx, 'TRIPLE_C')
                )
            }
        } else if (cardItemCount === 'D') {
            {
                return galleryItems?.map((doc, idx) =>
                    renderGalleryItem(doc, idx, 'TRIPLE_D')
                )
            }
        }
    }

    // const generateCardArray = (n) => {
    //     const arr = []
    //     while (n > 0) {
    //         const a = Math.min(Math.floor(Math.random() * 3) + 1, n)
    //         n -= a
    //         arr.push(a)
    //     }
    //     return arr
    // }

    const onRenderGallery = () => {
        const cardArray = supplierCardRenderSequence(supplierDocs?.length)
        let startIndex = 0
        return cardArray.map((cardItemCount, index) => {
            const galleryItems = supplierDocs.slice(
                startIndex,
                startIndex + chartToNum[cardItemCount]
            )
            startIndex += chartToNum[cardItemCount]
            return (
                <div
                    className={`gallery-row ${gallaryClassName[cardItemCount]}`}
                    key={index}
                >
                    {getImageCard(galleryItems, cardItemCount)}
                </div>
            )
        })
    }

    const getTechnical = (img, type) => {
        return (
            <div className='same-padding text-center'>
                <div className='py-3'>
                    <img src={img} alt='technical' className='w-10 h-10' />
                    <h3 className='regular-18 weight-500 mt-2'>{type}</h3>
                </div>
            </div>
        )
    }

    const renderTechnicalElement = (iconPath, text, type) => {
        if (supplierInfo?.factoryInfo[type]) {
            return getTechnical(iconPath, text)
        }
        return null
    }
    const technicalElements = [
        { iconPath: '/icons/r&d.svg', text: 'R&D', type: 'haveRnd' },
        { iconPath: '/icons/lab.svg', text: 'Lab', type: 'haveLab' },
        { iconPath: '/icons/studio.svg', text: 'Studio', type: 'haveStudio' },
        { iconPath: '/icons/sample.svg', text: 'Sample', type: 'haveSample' },
        {
            iconPath: '/icons/composite.svg',
            text: 'Composite',
            type: 'isComposite'
        },
        { iconPath: '/icons/wtp.svg', text: 'ETP', type: 'haveEtp' },
        { iconPath: '/icons/wtp.svg', text: 'WTP', type: 'haveWtp' }
    ]

    const todaysDate = new Date()

    return (
        <>
            <div className='py-3'>
                <BreadCrumb isShowBackButton={true} />
            </div>
            <div className='page-content'>
                <div className='factory-profile-container'>
                    <div className='mb-10'>
                        <div
                            className={`${
                                supplierInfo?.profileImageDocUrl
                                    ? 'factory-thambnail'
                                    : 'factory-thambnail no-thambnail'
                            }`}
                        >
                            {supplierInfo?.profileImageDocUrl && (
                                <img
                                    src={supplierInfo?.profileImageDocUrl}
                                    alt='factory'
                                    className='thambnail-image'
                                />
                            )}

                            <h3 className='factory-name text-white text-xl md:text-2xl lg:text-[32px] uppercase font-bold absolute bottom-11'>
                                {supplierInfo?.name}
                            </h3>

                            {supplierInfo?.factoryLocation?.state && (
                                <div className='location-info flex items-center'>
                                    <p className='text-sm 2xl:text-base'>
                                        {supplierInfo?.factoryLocation?.state},{' '}
                                        {
                                            supplierInfo?.factoryLocation
                                                ?.countryResponse?.name
                                        }{' '}
                                    </p>
                                </div>
                            )}
                            {supplierInfo?.threeSixtyViewUrl && (
                                <button
                                    className='button virtual-btn'
                                    onClick={() => {
                                        window.open(
                                            supplierInfo?.threeSixtyViewUrl,
                                            '_blank'
                                        )
                                    }}
                                >
                                    <Three60Icon />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className='mb-10'>
                        <h3 className='text-xl 2xl:text-2xl font-bold pb-3 2xl:pb-5'>
                            Quick info
                        </h3>
                        <div className='flex flex-wrap gap-4 md:gap-6'>
                            <div className='same-padding'>
                                <p className='regular-14 gray_300 mb-1'>
                                    Factory type
                                </p>

                                <p className='text-base weight-500 black capitalize'>
                                    {' '}
                                    {supplierInfo?.supplierTypeList
                                        .join(', ')
                                        .toLowerCase()}
                                </p>
                            </div>
                            <div className='same-padding'>
                                <p className='regular-14 gray_300 mb-1'>
                                    Category
                                </p>
                                <p className='text-base weight-500 black capitalize'>
                                    {supplierInfo?.supplierCategoryList
                                        .join(', ')
                                        .toLowerCase()}
                                </p>
                            </div>
                            {supplierInfo?.annualTrunOver && (
                                <div className='same-padding'>
                                    <p className='regular-14 gray_300 mb-1'>
                                        Annual turnover
                                    </p>
                                    <h3 className='text-base weight-500 black'>
                                        $
                                        {getNumberUnit(
                                            supplierInfo?.annualTrunOver
                                        )}
                                    </h3>
                                </div>
                            )}
                            <div className='same-padding'>
                                <p className='regular-14 gray_300 mb-1'>
                                    Total employees
                                </p>
                                <h3 className='text-base weight-500 black'>
                                    {supplierInfo?.noOfWorkers}
                                </h3>
                            </div>
                            <div className='same-padding'>
                                <p className='regular-14 gray_300 mb-1'>
                                    Production line
                                </p>
                                <h3 className='text-base weight-500 black'>
                                    {supplierInfo?.numberOfLine}
                                </h3>
                            </div>
                        </div>
                    </div>
                    {supplierInfo?.brandResponseList?.length !== 0 && (
                        <div className='mb-10 '>
                            <h3 className='text-xl 2xl:text-2xl font-bold pb-3 2xl:pb-5'>
                                Key customers{' '}
                            </h3>
                            <div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 md:gap-6'>
                                {supplierInfo?.brandResponseList?.map(
                                    (item, i) => (
                                        <div
                                            className='same-padding h-24 flex items-center justify-center'
                                            key={item.id}
                                        >
                                            <img
                                                src={
                                                    item?.brandLogoDocResponse
                                                        ?.docUrl
                                                }
                                                alt={item?.BrandName}
                                                className='w-32 h-auto max-h-16 object-contain'
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    )}
                    <div className='mb-10 '>
                        <h3 className='text-xl 2xl:text-2xl font-bold pb-3 2xl:pb-5'>
                            Compliance
                        </h3>
                        <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-4 md:gap-6'>
                            {supplierCertificates?.map((item, i) => {
                                let remainingDay = getDateDifference(
                                    moment().format('YYYY-MM-DD'),
                                    onGetLocalFromUtc(item.expireDate),
                                    'days'
                                );
                                if(remainingDay > 0){
                                    return (
                                        (
                                            <div
                                                className='same-padding text-center'
                                                key={i}
                                            >
                                                <div
                                                    className='pt-6 cursor-pointer'
                                                    onClick={() => {
                                                        window.open(
                                                            item?.documentResponse?.docUrl,
                                                            '_blank'
                                                        )
                                                    }}
                                                >
                                                    <img
                                                        src={renderCertificateBadge(
                                                            item.certificateType
                                                        )}
                                                        alt='certificate'
                                                        className='w-28 h-28 xl:w-36 xl:h-36 rounded-full object-contain'
                                                    />
                                                </div>

                                                <p className='regular-16 black mt-8'>
                                                    {item?.certificateTypeName}
                                                </p>
                                                <p className='regular-12 gray_300 mb-1'>
                                                    {dateCompare(
                                                        todaysDate,
                                                        item.expireDate
                                                    )
                                                        ? 'Valid till'
                                                        : 'Expired on'}
                                                    : &nbsp;
                                                    {changeDateFormat(
                                                        item.expireDate,
                                                        'YYYY-MM-DD',
                                                        'Do MMM YYYY'
                                                    )}
                                                </p>
                                            </div>
                                        )
                                    )
                                }
                            })}
                        </div>
                    </div>
                    {Object.keys(supplierInfo?.productionCapacity || {})
                        .length !== 0 && (
                        <div className='mb-10 '>
                            <h3 className='text-xl 2xl:text-2xl font-bold pb-3 2xl:pb-5'>
                                Production capacity
                            </h3>
                            <div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 md:gap-6'>
                                <div className='same-padding'>
                                    <p className='regular-14 gray_300 mb-1'>
                                        Knitting
                                    </p>
                                    {supplierInfo?.productionCapacity
                                            ?.knitting >= 0 &&
                                        getCapacity(
                                            supplierInfo?.productionCapacity
                                                ?.knitting,
                                            'Ton '
                                        )}
                                </div>
                                <div className='same-padding'>
                                    <p className='regular-14 gray_300 mb-1'>
                                        Dyeing
                                    </p>
                                    {supplierInfo?.productionCapacity?.dyeing >=
                                        0 &&
                                        getCapacity(
                                            supplierInfo?.productionCapacity
                                                ?.dyeing,
                                            'Ton '
                                        )}
                                </div>
                                <div className='same-padding'>
                                    <p className='regular-14 gray_300 mb-1'>
                                        Sewing
                                    </p>
                                    {supplierInfo?.productionCapacity?.sewing >=
                                        0 &&
                                        getCapacity(
                                            supplierInfo?.productionCapacity
                                                ?.sewing,
                                            'Pcs '
                                        )}
                                </div>
                                <div className='same-padding'>
                                    <p className='regular-14 gray_300 mb-1'>
                                        Finishing
                                    </p>
                                    {supplierInfo?.productionCapacity
                                            ?.finishing >= 0 &&
                                        getCapacity(
                                            supplierInfo?.productionCapacity
                                                ?.finishing,
                                            'Pcs '
                                        )}
                                </div>
                                <div className='same-padding'>
                                    <p className='regular-14 gray_300 mb-1'>
                                        Washing
                                    </p>
                                    {supplierInfo?.productionCapacity
                                            ?.washing >= 0 &&
                                        getCapacity(
                                            supplierInfo?.productionCapacity
                                                ?.washing,
                                            'Pcs '
                                        )}
                                </div>
                            </div>
                        </div>
                    )}
                    {Object.keys(supplierInfo?.factoryInfo || {}).length !==
                        0 && (
                            <div className='mb-10 '>
                                <h3 className='text-2xl font-bold pb-5'>
                                    Technical
                                </h3>
                                <div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 md:gap-6'>
                                    {technicalElements.map((element, index) => (
                                        <React.Fragment key={index}>
                                            {renderTechnicalElement(
                                                element.iconPath,
                                                element.text,
                                                element.type
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        )}
                    {supplierDocs?.length > 0 && (
                        <div className='mb-10 '>
                            <h3 className='text-2xl font-bold pb-5'>Gallery</h3>
                            <div className='factory-gallery'>
                                {onRenderGallery()}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default SupplierProfile
