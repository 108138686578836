import React, { useEffect, useState } from 'react'
import LandingLeftImage from '../../assets/images/home/landing-left-new.png'
import LandingRightImage from '../../assets/images/home/landing-right-new.png'
import { useNavigate } from 'react-router-dom'
import { fetchCurrentBuyerRequest } from '../services/v3/Dashboard/Dashborad'
import { useDispatch } from 'react-redux'
import { closeLoader, openLoader } from '../redux_toolkit/Loader'

const HomePage = () => {
    const [dashboardCount, setDashboardCount] = useState(0)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        ;(async () => {
            try {
                dispatch(openLoader())
                const data = await fetchCurrentBuyerRequest()
                let totalCount = 0
                if (data?.order?.RUNNING) totalCount += data.order.RUNNING
                if (data?.quotes?.RUNNING) totalCount += data.quotes.RUNNING
                if (data?.sample?.RUNNING) totalCount += data.sample.RUNNING
                if (data?.sample?.PENDING) totalCount += data.sample.PENDING
                setDashboardCount(totalCount)
                dispatch(closeLoader())
            } catch (e) {
                dispatch(closeLoader())
            }
        })()
    }, [])

    return (
        <div className='landing-layout'>
            <div
                className='one-half landing-left cursor-pointer'
                onClick={() => {
                    localStorage.setItem('navActiveTab', 'DESIGN_LAB')
                    navigate('/design-lab')
                }}
            >
                <img src={LandingLeftImage} alt='banner' />
                <div className='banner-content'>
                    <h3>Design Lab</h3>
                    <p>
                        Get access to thousands of exclusive designs every day
                    </p>
                </div>
            </div>
            <div
                className='one-half landing-right cursor-pointer'
                onClick={() =>
                    navigate('/dashboard?active=QUOTES&status=RUNNING')
                }
            >
                <img src={LandingRightImage} alt='banner' />
                <div className='banner-content'>
                    <h3>
                        My Dashboard{' '}
                        {dashboardCount > 0 && <>({dashboardCount})</>}
                    </h3>
                    <p>
                        Stay tuned with your techpacks, quotes, samples, and
                        orders
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HomePage
