import axios from 'axios'
import { BASE_FRONT_END_URL, BASE_URL, HTTP_STATUS } from '../constant'

const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
}

// const BASE_URL = "http://nitex-env.eba-bj9qc7tu.eu-central-1.elasticbeanstalk.com";

export const routes = {
    //Login Module
    userInfo: `${BASE_URL}/user/me`, // get
    login: `${BASE_URL}/auth/login`, // POST
    signup: `${BASE_URL}/auth/signup`, // POST
    forgetPassword: `${BASE_URL}/user/request-forgot-password`, // POST
    accManagerInfo: `${BASE_URL}/user/my-account-manager`,
    completeForgotPassword: `${BASE_URL}/user/complete-forgot-password`, // POST
    updateBasicInfo: `${BASE_URL}/user/update-basic-info`,
    updateProfilePicture: `${BASE_URL}/user/update-profile-picture`,
    updatePassword: `${BASE_URL}/user/change-password`,
    getSettingsData: `${BASE_URL}/personal-setting/get/`,
    updatePersonalSettings: `${BASE_URL}/personal-setting/set`,
    refreshUserToken: `${BASE_URL}/auth/refresh`,
    verifyToken: `${BASE_URL}/auth/with-verification-token`,
    verifyEmail: `${BASE_URL}/user/client/verify-email`,
    resendVerificationMail: `${BASE_URL}/user/client/resend-email`,
    getLoginPageBgImage: `${BASE_URL}/static-content/buyer/login`,
    changePassword: `${BASE_URL}/user/complete-forgot-password`,
    validateSignUp: `${BASE_URL}/auth/validate-signup`,

    // moodboard
    getMoodboardList: `${BASE_URL}/moodboard/search`,
    getMoodboardByID: `${BASE_URL}/moodboard/`,
    addMoodboard: `${BASE_URL}/moodboard/add`,
    updateMoodboard: `${BASE_URL}/moodboard/update`,
    uploadMoodboardImages: `${BASE_URL}/moodboard/`,
    getAllColorCodes: `${BASE_URL}/color/pantone/search`,
    addColorToMoodboard: `${BASE_URL}/moodboard/`,
    deleteColorFromMoodboard: `${BASE_URL}/moodboard/`,
    deleteProductImage: `${BASE_URL}/moodboard/`,
    getMoodboardFabrics: `${BASE_URL}/material/all`,
    addFabricToMoodboard: `${BASE_URL}/moodboard/`,
    removeFabricFromMoodboard: `${BASE_URL}/moodboard/`,
    addToFavoriteMoodboards: `${BASE_URL}/moodboard/`,
    removeFromFavoriteMoodboards: `${BASE_URL}/moodboard/`,
    requestForCollection: `${BASE_URL}/moodboard/`,
    // moodboard fabric ---- search parameteres
    getAllMaterialCatagory: `${BASE_URL}/material/fabric-type/all`,
    getAllMaterialSubCategory: `${BASE_URL}/material/fabric/constructions`,
    // moodboard filter parameteres
    getAllCategory: `${BASE_URL}/category/subcategory/all`,
    getAllSeasons: `${BASE_URL}/material/season/all`,
    getAllMarkets: `${BASE_URL}/product-group/all`,

    //home page
    fetchGreetingSlider: `${BASE_URL}/static-content/buyer/home`,
    searchCollectionByUser: `${BASE_URL}/collection/search/user/`,
    nitexOffer: `${BASE_URL}/dashboard/nitex/offer`,
    fetchMaterialsByFabric: `${BASE_URL}/material/fabric/base/`,
    fetchProductByMaterials: `${BASE_URL}/product/search`,
    fetchDashboardCount: `${BASE_URL}/dashboard/buyer/dashboard-overview`,

    // getTimeLineStepInfo: `${BASE_URL}/order-timeline/critical-deliverables/order/`,

    //order page
    statusWiseCount: `${BASE_URL}/order/status-wise-count`,
    getOrderList: `${BASE_URL}/order/my-order`,
    getTimeLineData: `${BASE_URL}/order-timeline/order/`,
    downloadInvoice: `${BASE_URL}/invoice/download/`,
    getTimeLineOrderInfo: `${BASE_URL}/order-timeline/order-info/`,
    getTimeLineStepInfo: `${BASE_URL}/order-timeline/critical-deliverables/order/`,
    getDesignWiseTaskList: `${BASE_URL}/deliverable/order/`,
    getOrderQuotes: `${BASE_URL}/order/quotes/`,
    getProjectMembers: `${BASE_URL}/order/get-member-list/`,
    addDeleteMemberToTask: `${BASE_URL}/step/add-delete-member`,
    approveTask: `${BASE_URL}/step/approve/`,
    reviseTask: `${BASE_URL}/step/revise/`,
    commentOnTask: `${BASE_URL}/order-timeline/new-comment`,
    postOnTask: `${BASE_URL}/order-timeline/new-post`,
    task: `${BASE_URL}/step/`,
    getTaskPosts: `${BASE_URL}/order-timeline/step/`,
    getSupplierInfo: `${BASE_URL}/supplier/order-timeline/`,
    uploadDocument: `${BASE_URL}/doc/add`,
    uploadBulkDocument: `${BASE_URL}/doc/add-bulk`,
    placeOrderFromCosting: `${BASE_URL}/collection/place-order`,
    addMemberToOrder: `${BASE_URL}/order/assign-member/`,
    removeMemberFromOrder: `${BASE_URL}/order/remove-members/`,

    //collection module
    addCollectionByName: `${BASE_URL}/collection/add`,
    fetchSeasons: `${BASE_URL}/enum/list/info.nitex.app.materials.Season`,
    fetchCategories: `${BASE_URL}/category/subcategory/all`,
    fetchMarkets: `${BASE_URL}/product-group/all`,
    fetchCollectionById: `${BASE_URL}/collection/products/`,
    updateCollectionById: `${BASE_URL}/collection/update`,
    addInspiration: `${BASE_URL}/product/add-inspiration`,
    addFromInspiration: `${BASE_URL}/product/add-from-inspiration`,
    getMaterialsByStyleID: `${BASE_URL}/product/material/`,
    getMeasurementByStyleID: `${BASE_URL}/product-measurement/`,
    getStyleInfoByStyleID: `${BASE_URL}/product/buyer-design-info/`,
    getProductInfoByStyleID: `${BASE_URL}/product/style-info/`,
    getGroupwiseDoc: `${BASE_URL}/product/groupwise-doc/`,
    fetchArtBoard: `${BASE_URL}/art-board/product/`,
    downloadTechPack: `${BASE_URL}/product/download-techpack/`,
    upDateDesign: `${BASE_URL}/product/style-info/`,
    fetchDefaultQuotes: `${BASE_URL}/rfq/product-default-quotes`,
    searchCollection: `${BASE_URL}/collection/search`,
    moveCollection: `${BASE_URL}/collection/product/move-to-another`,
    copyCollection: `${BASE_URL}/collection/product/add`,
    fetchFabricCategory: `${BASE_URL}/material/fabric-type/all`,

    // Qoutes Module
    getQouteList: `${BASE_URL}/quote/request/all`,
    getQouteDetail: `${BASE_URL}/quote`,
    postQuoteRequest: `${BASE_URL}/quote/request/buyer`,
    getCollectionForUser: `${BASE_URL}/collection/search/user`,
    getProductsOfCollectionUser: `${BASE_URL}/collection/products`,
    askForQuotation: `${BASE_URL}/quote/request`,
    approveOffer: `${BASE_URL}/quantity-wise-cost`,
    rquestForRevision: `${BASE_URL}/quantity-wise-cost`,
    updateTargetPrice: `${BASE_URL}/quantity-wise-cost/update-price`,
    addQuantityWiseCost: `${BASE_URL}/quantity-wise-cost/add`,
    fetchCollectionCount: `${BASE_URL}/collection/count/buyer`,

    //Sample Module
    getSampleList: `${BASE_URL}/sample/request/all`,
    getColorList: `${BASE_URL}/color/pantone/all`,
    getEnumList: `${BASE_URL}/enum/list`,
    sampleRequest: `${BASE_URL}/sample/request/buyer`,
    sampleRequestForColection: `${BASE_URL}/sample/request/collection`,
    sampleRequestDetail: `${BASE_URL}/sample/request`,

    //Team
    addMember: `${BASE_URL}/collection/share`,
    removeMember: `${BASE_URL}/collection/remove-member`,
    inviteUser: `${BASE_URL}/user/invite`,
    sampleMemberAction: `${BASE_URL}/sample/request/`,
    getAllBrandDepartments: `${BASE_URL}/team/brand/departments`,
    getMembersByDepartment: `${BASE_URL}/team/brand/members-by-department/`,
    brandTeamMemberAction: `${BASE_URL}/team/brand/`,

    //Favourite
    toggleFavouriteCollection: `${BASE_URL}/collection/`,
    toggleFavouriteStyle: `${BASE_URL}/product/`,
    fetchUnseenFavouriteCount: `${BASE_URL}/user/favourites/unseen-count/all`,
    fetchFavouriteStyles: `${BASE_URL}/product/my-favourites`,
    allFavouriteCount: `${BASE_URL}/user/favourites/count/all`,
    addTodraft: `${BASE_URL}/user/favourites/draft/`,
    removeFromdraft: `${BASE_URL}/user/favourites/undraft/`,
    favouritePresentationInfo: `${BASE_URL}/user/favourites/presentation-info`,

    //Notifications
    getNotifications: `${BASE_URL}/notification/all`,
    markNotificationRead: `${BASE_URL}/notification/mark-seen`,
    markNotificationUnread: `${BASE_URL}/notification/mark-unseen`,
    getUnseenNotificationCount: `${BASE_URL}/notification/unseen-count`,
    getAllUnseenNotificationCount: `${BASE_URL}/notification/unseen-count/all`,
    markAllNotificationUnreadByCategory: `${BASE_URL}/notification/mark-all-seen-by-category`,
    markNotificationImportant: `${BASE_URL}/notification/mark-important`,
    markNotificationUnImportant: `${BASE_URL}/notification/mark-unimportant`,

    //conversation
    webSocketUrl: `${BASE_URL}/ws`,
    fetchSubscriptionChannel: `${BASE_URL}/conversation/topics`,
    fetchCollectionConversationList: `${BASE_URL}/conversation/collection/`,
    fetchQuoteConversationList: `${BASE_URL}/conversation/quote-request/`,
    fetchSampleConversationList: `${BASE_URL}/sample-development-comment/`,
    fetchProductConversationList: `${BASE_URL}/product-development-comment/product/`,
    addNewPost: `${BASE_URL}/conversation/new-post`,
    userSearch: `${BASE_URL}/user/search`,
    addPostReply: `${BASE_URL}/conversation/new-comment`,
    getConversationTopic: `${BASE_URL}/conversation/topics`,

    //supplier
    fetchSupplierInfo: `${BASE_URL}/supplier/location-contact-info/`,
    fetchSupplierCertificates: `${BASE_URL}/supplier/`,
    fetchSupplierDocs: `${BASE_URL}/supplier/photo-documents/`,

    //mixpanel
    mixpanelAddEvent: `${BASE_URL}/mixpanel/event/add/`,

    //TNA APIs URL
    getInitialAllOrder: `${BASE_URL}/order/get-product-list`,
    tnaPlanningOrder: `${BASE_URL}/tna-planning/order`,
    getTnaTemplateDay: `${BASE_URL}/tna-planning/templates/all`,
    getRunningAllOrder: `${BASE_URL}/order`,
    getRunningTnaStyleDetails: `${BASE_URL}/step/order-details/order`,
    submitTna: `${BASE_URL}/order/order`,
    stepUrl: `${BASE_URL}/step`,
    subTaskAction: `${BASE_URL}/step/sub-task/`,
    getDeliverableDetails: `${BASE_URL}/deliverable/`,
    orderTimelineAction: `${BASE_URL}/order-timeline/`,

    getCollectionDetails: `${BASE_URL}/collection/`,
    getCollectionStylesInfoById: `${BASE_URL}/product/get-info`,

    //v3 explore designs
    getExploreDesigns: `${BASE_URL}/product/buyer-explore-design`,
    fetchAllCategories: `${BASE_URL}/category/v2/subcategory`,
    fetchAgeGroup: `${BASE_URL}/enum/list/info.nitex.app.product.AgeGroup`,
    fetchProductsFromBuildRange: `${BASE_URL}/product/build-range`,
    inviteUserFromFavourite: `${BASE_URL}/user/favourites/invite-to-folder`,
    collectionAction: `${BASE_URL}/collection/`,
    getEnums: `${BASE_URL}/enum/list/`,
    searchProductByImage: `${BASE_URL}/product/image-search`,
    fetchFavouriteProductId: `${BASE_URL}/product/ids-by-folderId`,
    fetchFilterListByBuyer: `${BASE_URL}/product/buyer/filter-list`,
    productSearchSuggestion: `${BASE_URL}/product/latest-five-text`,
    productSearch: `${BASE_URL}/product/text-search`,
    requestQuoteFromFavourite: `${BASE_URL}/quote/request/from-fav`,
    requestSampleFromFavourite: `${BASE_URL}/sample/request/from-fav`,
    addProductBulkImages: `${BASE_URL}/product/doc/add-bulk/`,
    getUserSuggestions: `${BASE_URL}/user/find-list-by-email-and-user-type`,
    trackProductById: `${BASE_URL}/tracker/product/`,
    trackPresentationView: `${BASE_URL}/tracker/presentation-view`,
    getAuthGL: `${BASE_URL}/auth/gl`,

    // v3 favourite
    doFavourite: `${BASE_URL}/product/like`,
    undoFavourite: `${BASE_URL}/product/unlike/`,
    addFavouriteFolder: `${BASE_URL}/user/favourites/add-folder`,
    getMyFavouriteFolder: `${BASE_URL}/user/favourites/my-folders`,
    getFolderDetailsByFolderId: `${BASE_URL}/user/favourites/folder-details`,
    setSettingsData: `${BASE_URL}/personal-setting/set`,
    fetchFavouriteFolderList: `${BASE_URL}/user/favourites/folder-details-list`,
    fetchBuyerLikedDesigns: `${BASE_URL}/product/buyer-liked-design`,
    fetchBuyerLikedDesignsSummary: `${BASE_URL}/product/buyer/liked-design-summary`,
    updateFavouriteData: `${BASE_URL}/user/favourites/update-sheet/`,

    // v3 dashboard
    fetchCurrentBuyerRequest: `${BASE_URL}/buyer-request/running`,
    fetchSetInfo: `${BASE_URL}/product/set-info/`
}

// Axios request interceptor
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    config.headers.Authorization = token ? token : ''
    config.headers.unameid = userInfo?.id || ''
    config.headers.unamefull =
        userInfo?.name?.replace(/[^a-zA-Z\s]/g, '?') || ''
    config.headers.xfurl =
        window.location.pathname + window.location.search || ''
    let geoInfo = sessionStorage.getItem('glInfo')
    if (geoInfo !== null) {
        geoInfo = JSON.parse(geoInfo)
        config.headers.xglid = geoInfo.glid
        config.headers.xuglid = geoInfo.uglid
    }
    return config
})

// Axios response interceptor
axios.interceptors.response.use(
    (response) => {
        if (response.config.url.includes('logout')) {
            delete axios.defaults.headers.common['Authorization']
        }
        return response
    },
    (error) => {
        if (
            error.response.data.status === HTTP_STATUS['Unauthorized'] &&
            localStorage.getItem('token')
        ) {
            localStorage.removeItem('token')
            delete axios.defaults.headers.common['Authorization']
            window.location.replace(BASE_FRONT_END_URL)
        }
        return Promise.reject(error)
    }
)

const encodeQueryData = (data) => {
    let ret = [],
        temp
    for (let i in data) {
        temp = data[i]
        if (temp !== '' && temp !== null && temp !== undefined) {
            ret.push(encodeURIComponent(i) + '=' + encodeURIComponent(temp))
        }
    }
    return ret.length ? '?' + ret.join('&') : ''
}

const updateTokenInHeader = () => {
    let token = localStorage.getItem('token')
    if (token) {
        axios.defaults.headers.common['Authorization'] = token
    }

    // const token = {
    //     local: JSON.parse(localStorage.getItem('token')),
    //     header: axios.defaults.headers.common['Authorization']
    // };
    // if (token.local && (!token.header || token.local !== token.header)) {
    //     axios.defaults.headers.common['Authorization'] = token.local;
    // }
}

const Http = {
    GET: (key, params = '', isRestFormat = false) => {
        // updateTokenInHeader();
        params = typeof params === 'object' ? encodeQueryData(params) : params
        if (isRestFormat) {
            return axios.get(key + params, headers)
        } else {
            return axios.get(routes[key] + params, headers)
        }
    },
    GET_FILE_DATA: (key, params = '', isRestFormat = false) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Disposition': 'attachment'
            },
            responseType: 'blob'
        }
        params = typeof params === 'object' ? encodeQueryData(params) : params
        if (isRestFormat) {
            return axios.get(key + params, config)
        } else {
            return axios.get(routes[key] + params, config)
        }
    },
    GET_WITH_ID_PARAM: (key, params = '', id = '', isRestFormat = false) => {
        // updateTokenInHeader();
        // console.log("id",id)
        // console.log("params",params)
        params = typeof params === 'object' ? encodeQueryData(params) : params
        if (isRestFormat) {
            return axios.get(key + id + params, headers)
        } else {
            return axios.get(routes[key] + id + params, headers)
        }
    },
    POST: (key, params, id = '') => {
        // if(key!=='signup' || key!=='login'){
        // console.log("token header called",key);
        // updateTokenInHeader();
        // }
        // console.log("from post",routes[key] + id, params)
        // console.log(params)
        return axios.post(routes[key] + id, params, headers)
    },
    POST_TEXT_BODY: (key, params, id = '') => {
        // if(key!=='signup' || key!=='login'){
        // console.log("token header called",key);
        // updateTokenInHeader();
        // }
        // console.log("from post",params)
        return axios.post(routes[key] + id, params, {
            'Content-Type': 'text/html',
            Accept: 'application/json'
        })
    },
    PUT: (key, params, id = '') => {
        // updateTokenInHeader();
        return axios.put(routes[key] + id, params, headers)
    },
    DELETE: (key, data, id = '') => {
        // updateTokenInHeader();
        // console.log(routes[key] + id)
        return axios.delete(routes[key] + id, { data, headers })
    },
    DELETE_WITH_BODY: (key, body, params = '') => {
        // updateTokenInHeader();
        params = typeof params === 'object' ? encodeQueryData(params) : params
        return axios.delete(routes[key] + params, { headers, data: body })
    },
    UPLOAD_MULTIPLE_FILE: (key, files = []) => {
        let formData = new FormData()
        files.forEach((file, i) => {
            formData.append('file', file)
        })
        return axios.post(routes[key], formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Disposition': 'attachment'
            },
            responseType: 'blob'
        })
    },
    UPLOAD: (key, { name, file }) => {
        // updateTokenInHeader();
        const formData = new FormData()
        formData.append(name, file)

        return axios.post(routes[key], formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    DOWNLOAD: (key, params = '') => {
        // Only GET is supported
        // updateTokenInHeader();
        params = typeof params === 'object' ? encodeQueryData(params) : params
        return axios(routes[key] + params, {
            method: 'GET',
            responseType: 'blob', // Force to receive data in a Blob Format
            header: JSON.parse(localStorage.getItem('token'))
        })
    },
    UPLOAD_WITH_PROGRESS: (key, params, id = '', progressCallback) => {
        // if(key!=='signup' || key!=='login'){
        // console.log("token header called",key);
        // updateTokenInHeader();
        // }
        // console.log("from post",routes[key] + id, params)
        return axios.post(routes[key] + id, params, {
            headers,
            onUploadProgress: (data) => {
                //Set the progress value to show the progress bar
                progressCallback(data, params)
            }
        })
    }
}

export default Http
