import {
    CLEAR_SELECTED_PRODUCT_FROM_SAMPLE,
    SET_SAMPLE_DETAILS,
    SET_SAMPLE_LIST,
    SET_SAMPLE_LIST_PAGE_ACTIVE_TAB,
    SET_SELECT_PRODUCT_FROM_SAMPLE,
    SET_ALL_PRODUCT_FROM_SAMPLE,
    CLEAR_SAMPLE_DETAILS
} from '../@types/action.types'

export const sampleReducer = {
    [SET_SAMPLE_LIST_PAGE_ACTIVE_TAB]: (state, action) => {
        state.activeTab = action.payload
    },
    [SET_SAMPLE_LIST]: (state, action) => {
        let samples = []
        if (action.payload.merge) {
            samples = [...state.samples.data, ...action.payload.data]
        } else {
            samples = [...action.payload.data]
        }
        action.payload.data = samples
        state.samples = action.payload
    },
    [SET_SAMPLE_DETAILS]: (state, action) => {
        state.sample = action.payload
    },
    [SET_SELECT_PRODUCT_FROM_SAMPLE]: (state, { payload }) => {
        if (payload.checked) {
            state.selectedProducts.push(payload.value)
        } else {
            state.selectedProducts = state.selectedProducts.filter(
                (item) => item !== payload.value
            )
        }
    },
    [SET_ALL_PRODUCT_FROM_SAMPLE]: (state, { payload }) => {
        if (payload.checked) {
            state.selectedProducts = [...payload.items]
        } else {
            state.selectedProducts = []
        }
    },
    [CLEAR_SELECTED_PRODUCT_FROM_SAMPLE]: (state, action) => {
        state.selectedProducts = []
    },
    [CLEAR_SAMPLE_DETAILS]: (state, action) => {
        state.sample = null
    }
}
