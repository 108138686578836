import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
const initialState = false

const LoaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    openLoader: state => true,
    closeLoader: state => false
  }
})

export const { openLoader, closeLoader } = LoaderSlice.actions
export const useLoader = () => useSelector(state => state.loader)
export default LoaderSlice.reducer
