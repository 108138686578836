import {
    GET_ALL_SHARED_COLLECTION,
    SET_ALL_SHARED_COLLECTION,
    TOGGLE_COLLECTION_AS_FAVOURITE,
    TOGGLE_FAVOURITE_COLLECTION
} from '../../@types/thunk.types'
import { closeLoader, openLoader } from '../../Loader'
import {
    fetchSharedCollection,
    likeSharedCollection,
    unlikeSharedCollection
} from '../../../services/v3/SharedCollection/SharedCollection'
import { SharedCollectionActions } from './index'
import { toast } from 'react-toastify';

const SharedCollectionThunk = {
    [GET_ALL_SHARED_COLLECTION]: (params) => async (dispatch) => {
        dispatch(openLoader())
        const data = await fetchSharedCollection(params)
        dispatch(
            SharedCollectionActions[SET_ALL_SHARED_COLLECTION]({
                data,
                merge: data.currentPage > 0
            })
        )
        dispatch(closeLoader())
    },
    [TOGGLE_FAVOURITE_COLLECTION]: (bool, collectionId) => async (dispatch) => {
        dispatch(openLoader())
        if (bool) {
            const data = await likeSharedCollection(collectionId)
            toast.success(data.message)
        } else {
            await unlikeSharedCollection(collectionId)
        }
        dispatch(SharedCollectionActions[TOGGLE_COLLECTION_AS_FAVOURITE](collectionId))
        dispatch(closeLoader())
    }
}

export default SharedCollectionThunk
