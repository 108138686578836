import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ModalContainer from '../../app/common/modal/ModalContainer'
import { closeLoader, openLoader, useLoader } from '../redux_toolkit/Loader'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { ReactComponent as IconCollectionRemove } from '../../assets/icons/old/collectionRemove.svg'
import { fetchProductInfosByIds } from '../services/CommonService'
import {
    clearBreadCrumbRoutes,
    getFileType,
    getIconByFileType,
    getShortName,
    isPackItem,
    isSetItem,
    validateNumber
} from '../helpers/Util'
import Http from '../helpers/Http'
import { Tooltip } from '@mui/material'
import PackSetOrTag from '../v3/common/PackOrSetTag'
import DesignImages from '../v3/common/DesignImages'

const RequestForSample = ({
    defaultTitle = '',
    type,
    onCLose,
    selectedProductList,
    collectionId
}) => {
    const [productList, setProductList] = useState([])
    const [newFiles, setNewFiles] = useState([])
    const [title, setTitle] = useState(defaultTitle)
    const [description, setDescription] = useState('')
    const [error, setError] = useState(false)
    const [etdDate, setEtdDate] = useState(null)
    const dispatch = useDispatch()
    const globalLoader = useLoader()
    const navigate = useNavigate()
    const { id } = useParams()

    const setProducts = async () => {
        let products = await fetchProductInfosByIds(
            'SAMPLE',
            selectedProductList
        )
        products = products.map((item) => ({
            ...item,
            quantity: '1',
            size: 'M'
        }))

        setProductList(products)
    }

    useEffect(() => {
        if (type === 'ASK_QUOTATION_OR_SAMPLE') {
            setProductList(selectedProductList)
        } else {
            setProducts()
        }
    }, [])

    const checkValidation = () => {
        let invalid = false
        for (let item of productList) {
            if (!item?.quantity) {
                invalid = true
                break
            }
            if (!item?.size) {
                invalid = true
                break
            }
        }
        return invalid
    }

    const getReqSampleData = (cloneProducts) => {
        let modifiedData = cloneProducts.map(
            ({
                designDocuments,
                name,
                productId,
                quantity,
                comment,
                size,
                isSet,
                setMemberProducts
            }) => {
                return isSet
                    ? {
                          requiredDate: etdDate || null,
                          designDocuments,
                          name,
                          productId,
                          remarks: comment || '',
                          isSet: isSet,
                          setMembersSampleRequestList: setMemberProducts.map(
                              (member) => {
                                  return {
                                      productId: member.id,
                                      requiredDate: etdDate || null,
                                      remarks: comment || '',
                                      sampleQuantityList: [
                                          {
                                              quantity: quantity || null,
                                              size: size || ''
                                          }
                                      ]
                                  }
                              }
                          )
                      }
                    : {
                          requiredDate: etdDate || null,
                          designDocuments,
                          name,
                          productId,
                          remarks: comment || '',
                          sampleQuantityList: [
                              {
                                  quantity: quantity || null,
                                  size: size || ''
                              }
                          ]
                      }
            }
        )
        let postData = {
            title,
            description,
            developmentSample: false,
            requestedDocumentDTOs: newFiles,
            addSampleRequestList: modifiedData
        }
        return postData
    }

    const getAskedSampleData = (cloneProducts) => {
        let modifiedData = cloneProducts.map(
            ({
                styleName,
                quantity,
                comment,
                productSubCategoryId,
                productGroupId,
                productDesignId,
                techpackId,
                size
            }) => {
                return {
                    styleName,
                    productSubCategoryId: productSubCategoryId?.id,
                    productGroupId: productGroupId?.id,
                    productDesignId,
                    techpackId,
                    requiredDate: etdDate,
                    remarks: comment,
                    sampleQuantityList: [
                        {
                            size: size,
                            quantity: quantity || null
                        }
                    ]
                }
            }
        )
        let postData = {
            title,
            description,
            addSampleRequestList: modifiedData
        }
        return postData
    }

    const onSampleRequest = async (cloneProducts) => {
        let postData =
            type === 'ASK_QUOTATION_OR_SAMPLE'
                ? getAskedSampleData(cloneProducts)
                : getReqSampleData(cloneProducts)
        if (type === 'ASK_QUOTATION_OR_SAMPLE') {
            return await Http.POST('sampleRequest', postData)
        } else if (type === 'SAMPLE_REQUEST_FROM_FAVOURITE') {
            const getFolderId = () =>
                id && id !== 'my-favourite' ? `?folderId=${id}` : ''

            return await Http.POST(
                'requestSampleFromFavourite',
                postData,
                getFolderId()
            )
        } else {
            return await Http.POST(
                'sampleRequestForColection',
                postData,
                `/${collectionId}`
            )
        }
    }

    const handleSubmit = () => {
        dispatch(openLoader())
        if (checkValidation() || !title) {
            setError(true)
            dispatch(closeLoader())
        } else {
            let cloneProducts = [...productList]
            onSampleRequest(cloneProducts)
                .then((response) => {
                    clearBreadCrumbRoutes()
                    toast.success(response?.data?.message)
                    navigate(`/samples/detail/${response?.data?.id}`)
                    onCLose()
                })
                .catch((error) => {
                    toast.error(error.response.data.message)
                })
                .finally(() => {
                    dispatch(closeLoader())
                })
        }
    }

    const handleChange = (e, sampleIdentifier, type) => {
        let cloneProducts = [...productList]
        cloneProducts.forEach((item) => {
            if (item.identifier === sampleIdentifier) {
                if (type === 'SIZE') {
                    item.size = e.target.value
                } else if (type === 'QUANTITY') {
                    item.quantity = e.target.value
                } else if (type === 'COMMENT') {
                    item.comment = e.target.value
                }
            }
        })
        setProductList(cloneProducts)
    }

    const renderSizes = (item) => {
        return (
            <input
                type='text'
                className={`max-w-[180px] ${
                    error && !item?.size ? 'required' : ''
                }`}
                id='text'
                placeholder='Size'
                name='text'
                value={item.size}
                onChange={(e) => handleChange(e, item.identifier, 'SIZE')}
            />
        )
    }

    const renderQuantity = (item) => {
        return (
            <div className='input-group'>
                <input
                    type='text'
                    className={`max-w-[180px] ${
                        error && !item?.quantity ? 'required' : ''
                    }`}
                    id='text'
                    placeholder='Qty'
                    name='text'
                    value={item.quantity}
                    onKeyPress={validateNumber}
                    onChange={(e) =>
                        handleChange(e, item.identifier, 'QUANTITY')
                    }
                />
            </div>
        )
    }

    const renderComment = (item) => {
        return (
            <textarea
                className='h-11'
                value={item.comment}
                onChange={(e) => handleChange(e, item.identifier, 'COMMENT')}
            />
        )
    }

    const removeProduct = (sampleIdentifier) => {
        let cloneProducts = [...productList]
        cloneProducts = cloneProducts.filter(
            (sample) => sample.identifier !== sampleIdentifier
        )
        setProductList(cloneProducts)
    }

    const renderTableBody = () => {
        return productList?.map((item, idx) => {
            const fileType = getFileType(item?.designDocuments?.[0]?.docUrl)
            return (
                <tr key={`file_row_${item.designDocuments?.[0]?.id}`}>
                    {type !== 'ASK_QUOTATION_OR_SAMPLE' && (
                        <td>
                            <div className='flex flex-col gap-5'>
                                <span
                                    className='cursor-pointer'
                                    onClick={() =>
                                        removeProduct(item.identifier)
                                    }
                                    key={item?.identifier}
                                >
                                    <IconCollectionRemove />
                                </span>
                            </div>
                        </td>
                    )}
                    <td>
                        <div className='flex items-center'>
                            <div className='style-image w-10'>
                                <DesignImages designInfo={item} />
                            </div>
                            <div className='ml-2'>
                                {isSetItem(item) && (
                                    <p>
                                        <PackSetOrTag tagName='Set' />
                                    </p>
                                )}
                                {isPackItem(item) && (
                                    <p>
                                        <PackSetOrTag tagName='Pack' />
                                    </p>
                                )}
                                <Tooltip
                                    title={item?.name}
                                    placement={'top'}
                                    arrow
                                >
                                    <span>{getShortName(item?.name, 20)}</span>
                                </Tooltip>
                            </div>
                        </div>
                    </td>
                    <td>{renderSizes(item)}</td>
                    <td>{renderQuantity(item)}</td>
                    <td>{renderComment(item)}</td>
                </tr>
            )
        })
    }

    const renderTabView = () => {
        return productList?.map((item, idx) => {
            const fileType = getFileType(item?.designDocuments?.[0]?.docUrl)
            return (
                <div
                    className='single-row'
                    key={`file_row_${item.designDocuments?.[0]?.id}`}
                >
                    <table className='table'>
                        <tr>
                            {type !== 'ASK_QUOTATION_OR_SAMPLE' && (
                                <td
                                    rowSpan={4}
                                    className='w-[56px] max-w-[56px]'
                                >
                                    <div className='flex flex-col gap-5'>
                                        <span
                                            className='cursor-pointer'
                                            onClick={() =>
                                                removeProduct(item.identifier)
                                            }
                                            key={item?.identifier}
                                        >
                                            <IconCollectionRemove />
                                        </span>
                                    </div>
                                </td>
                            )}
                            <td className='heading'>Files</td>
                            <td>
                                <div className='flex items-center'>
                                    <div className='style-image w-10'>
                                        <DesignImages designInfo={item} />
                                    </div>
                                    <div className='ml-2'>
                                        {isSetItem(item) && (
                                            <p>
                                                <PackSetOrTag tagName='Set' />
                                            </p>
                                        )}
                                        {isPackItem(item) && (
                                            <p>
                                                <PackSetOrTag tagName='Pack' />
                                            </p>
                                        )}
                                        <Tooltip
                                            title={item?.name}
                                            placement={'top'}
                                            arrow
                                        >
                                            <span>
                                                {getShortName(item?.name, 20)}
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td className='heading'>Size*</td>
                            <td>{renderSizes(item)}</td>
                        </tr>

                        <tr>
                            <td className='heading'>Quantity*</td>
                            <td>{renderQuantity(item)}</td>
                        </tr>
                        <tr>
                            <td className='heading'>Comment</td>
                            <td>{renderComment(item)}</td>
                        </tr>
                    </table>
                </div>
            )
        })
    }

    return (
        <ModalContainer
            className='request-sample-modal right-sidebar'
            onCloseModal={onCLose}
        >
            <div className='common-popup'>
                <div className='common-popup-header d-flex justify-content-between'>
                    <div className='popup-tilte'>
                        <h2 className='text-xl lg:text-[28px] weight-700 mb-0'>
                            {type === 'ASK_QUOTATION_OR_SAMPLE'
                                ? 'Ask for quote/sample'
                                : 'Request for sample'}
                        </h2>
                        {type !== 'ASK_QUOTATION_OR_SAMPLE' && (
                            <p className='regular-12 gray-300'>
                                Send request to Nitex team for sample
                            </p>
                        )}
                    </div>
                    <div className='close-btn cursor-pointer' onClick={onCLose}>
                        <CloseIcon />
                    </div>
                </div>

                <div className='common-popup-body p-4'>
                    {type === 'ASK_QUOTATION_OR_SAMPLE' && (
                        <div>
                            <h3 className='text-base lg:text-xl weight-700'>
                                Step 2: Ask for sample
                            </h3>
                            <p className='regular-12 gray-300'>
                                Please share your design with the Nitex team for
                                sample
                            </p>
                        </div>
                    )}
                    <div className='max-w-[500px] mt-3'>
                        <div className='form-group'>
                            <label className='regular-14 gray_300 mb-2'>
                                Title*
                            </label>
                            <input
                                className={`${
                                    error && !title ? 'required' : ''
                                }`}
                                type='text'
                                name='title'
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value)
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label className='regular-14 gray_300 mb-2'>
                                Sample required date
                            </label>

                            <input
                                type='date'
                                name='date'
                                value={etdDate}
                                onChange={(e) => setEtdDate(e.target.value)}
                            />
                        </div>

                        <div className='form-group'>
                            <label className='regular-14 gray_300 mb-2'>
                                Instruction
                            </label>

                            <textarea
                                placeholder='Write in details'
                                className='h-20'
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                            ></textarea>
                        </div>
                    </div>

                    <table className='table ask-for-sample-table mt-8'>
                        <thead>
                            <tr>
                                {type !== 'ASK_QUOTATION_OR_SAMPLE' && (
                                    <th></th>
                                )}
                                <th>Style</th>
                                <th>Size*</th>
                                <th>Quantity*</th>
                                <th>Comment</th>
                            </tr>
                        </thead>
                        <tbody>{renderTableBody()}</tbody>
                    </table>

                    <div className='tab-view-table py-2'>
                        <div className='all-rows'>{renderTabView()}</div>
                    </div>

                    <button
                        disabled={productList?.length === 0 || globalLoader}
                        className='button primary mt-5'
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </ModalContainer>
    )
}

export default RequestForSample
